import {IGoogleMapInfoWindow, IGoogleMapMarker} from "@renta-apps/athenaeum-react-components";
import ProductAvailabilityDetails from "@/models/server/ProductAvailabilityDetails";
import Localizer from "@/localization/Localizer";
import DepotModel from "@/models/server/DepotModel";
import {getGeographicalCenter} from "@/helpers/CountriesHelper";

export const getAvailabilityMapInitialCenter = (depots: DepotModel[]): google.maps.LatLngLiteral => {
    const depotsWithCoordinates = depots.filter((depot) => depot.coordLat !== 0 && depot.coordLng !== 0);
    
    if (depotsWithCoordinates && depotsWithCoordinates.length > 0) {
        const minLat: number = depotsWithCoordinates.minValue((depot) => depot.coordLat);
        const maxLat: number = depotsWithCoordinates.maxValue((depot) => depot.coordLat);
        const minLng: number = depotsWithCoordinates.minValue((depot) => depot.coordLng);
        const maxLng: number = depotsWithCoordinates.maxValue((depot) => depot.coordLng);
        return {
            lat: (minLat + maxLat) / 2,
            lng: (minLng + maxLng) / 2,
        };
    }
    return getGeographicalCenter();
}

export const buildAvailabilityMapMarkers = (depots: DepotModel[], availabilities: ProductAvailabilityDetails[], isAdmin: boolean): IGoogleMapMarker[] => {
    return depots.map(depot => {

        const availability: ProductAvailabilityDetails = availabilities.find((availability) => (availability.location === depot.externalId))!;

        const isAvailable: boolean = (availability.availableCount > 0);

        const markerIconFile: string = (isAvailable)
            ? "available"
            : (isAdmin) && (availability.totalCount <= 0)
                ? "unavailable"
                : "orderable";

        const icon: string = `/images/location-${markerIconFile}.svg`;
        const iconImg = document.createElement('img');
        iconImg.src = icon;

        const position: google.maps.LatLngLiteral = {
            lat: depot.coordLat,
            lng: depot.coordLng
        };

        const locationHref = `/${Localizer.pageRoutesDepots}?selectedDepot=${availability.locationName}`;
        const locationLink = `<a href="${locationHref}">${depot.name}</a>`;

        const content: string = (isAdmin)
            ? (availability.totalCount > 0)
                ? `${locationLink} ${availability.availableCount} / ${availability.totalCount}`
                : locationLink
            : `${locationLink} (${(isAvailable)
                ? Localizer.shoppingCartPageAvailable
                : Localizer.productDetailsOrderable})`;

        const infoWindow: IGoogleMapInfoWindow = {
            content,
            position,
            pixelOffset: new google.maps.Size(0, -60),
        };

        const marker: IGoogleMapMarker = {
            content: iconImg,
            position,
            infoWindow,
        };

        return marker;
    });
}