import React from "react";
import {Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import InviteNewUserRequest from "@/models/server/Requests/InviteNewUserRequest";
import InviteUserRequest from "@/models/server/Requests/InviteUserRequest";
import InviteUserForm from "@/components/InviteUserForm/InviteUserForm";
import InviteUserPageDataResponse from "@/models/server/Responses/InviteUserPageDataResponse";
import RentaEasyController from "@/pages/RentaEasyController";

export interface IAddNewContactPersonModalProps {
    contractId: string,
    constructionSiteId?: string | null,

    inviteNewUserAsync(request: InviteNewUserRequest): Promise<void>;
}

export interface IAddNewContactPersonModalState {
    formData: InviteUserPageDataResponse | null,
}

export default class InviteNewUserModal extends BaseComponent<IAddNewContactPersonModalProps, IAddNewContactPersonModalState> {
    state: IAddNewContactPersonModalState = {
        formData: null,
    };

    private readonly _newUserModalRef: React.RefObject<Modal> = React.createRef();

    public async fetchDataAsync(): Promise<void> {
        const request: InviteUserRequest = {
            contractId: this.props.contractId,
            constructionSiteId: null,
        };

        const data: InviteUserPageDataResponse = await RentaEasyController.getInvitationPageData(request);

        this.setState({formData: data});
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public async openAsync(): Promise<void> {
        if (this._newUserModalRef.current) {
            await this._newUserModalRef.current.openAsync();
            await this.fetchDataAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this._newUserModalRef.current) {
            await this._newUserModalRef.current.closeAsync();
        }
    }

    private async inviteNewUserAsync(request: InviteNewUserRequest): Promise<void> {
        await this.props.inviteNewUserAsync(request);
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   ref={this._newUserModalRef}
                   title={Localizer.inviteUserModelTitle}
                   size={ModalSize.Large}
            >
                {
                    this.state.formData &&
                    (
                        <InviteUserForm contractId={this.props.contractId}
                                        data={this.state.formData}
                                        saveButtonText={Localizer.addUserSendInvite}
                                        onSave={async (request) => await this.inviteNewUserAsync(request as InviteNewUserRequest)}
                                        constructionSiteId={this.props.constructionSiteId}
                                        onCancel={async () => await this.closeAsync()}
                                        displayCancelButton
                                        singleColumn
                        />
                    )
                }
            </Modal>
        );
    }

}