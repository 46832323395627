import React from "react";
import {DateInput, Icon, IconStyle, Link, TextInput} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, PageRoute} from "@renta-apps/athenaeum-react-common";
import ProductModel from "@/models/server/ProductModel";
import ImageProvider from "@/providers/ImageProvider";
import ProductOrganizationContractPriceModel from "@/models/server/ProductOrganizationContractPriceModel";
import Prices from "@/components/Prices/Prices";
import AddToCartButton from "@/components/AddToCartButton/AddToCartButton";
import {assert} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "@/components/ProductCard/ProductCard.module.scss";
import PageDefinitions from "@/providers/PageDefinitions";
import AttachedProducts from "@/components/AttachedProducts/AttachedProducts";

export enum ProductCardStyle {
    /**
     * All sections are stacked on top of each other.
     */
    SingleColumn,

    /**
     * Icon-Image and Header-Prices -sections are next to each other, buttons and inputs below them.
     */
    TwoColumns,

    /**
     * Everything next to each other..
     */
    SingleRow,
}

export enum ProductCardAvailability {

    /**
     * The product should be displayed as available.
     */
    Available = 0,

    /**
     * The product should be displayed as unavailable.
     */
    Unavailable = 1,

    /**
     * The product availability data is being loaded.
     */
    Loading = 2
}

export interface IProductCardClassNames {
    availability?: string;
    mainContainer?: string;
    prices?: string;
}

interface IProductCardProps {

    /**
     * Displayed products count in Shopping cart.
     */
    count: number;

    /**
     * Style of the component.
     */
    style: ProductCardStyle;

    /**
     * Product displayed in the {@link ProductCard}.
     */
    product: ProductModel;

    /**
     * Currently applicable VAT.
     */
    vat: number;

    /**
     * How should product availability be displayed.
     *
     * @default undefined
     */
    availability?: ProductCardAvailability;

    /**
     * Classname of the components root.
     *
     * @default undefined
     */
    className?: string;

    /**
     * Classnames of the components different sub-components.
     *
     * @default undefined
     */
    classNames?: IProductCardClassNames;

    /**
     * Should a tooltip be displayed next to weekly prices.
     *
     * @default false
     */
    displayWeeklyPriceTooltip?: boolean;

    /**
     * @default undefined
     */
    maxPriceRows?: number;

    /**
     * @default false
     */
    readonly?: boolean;

    /**
     * Should an {@link AddToCartButton} be rendered which the user can edit the products count with.
     * If true, defining {@link onUpdateProductCount} is mandatory.
     *
     * @default false
     */
    displayAddToCartButton?: boolean;

    /**
     * Show {@link RemoveFromCartButton} to allow removowal of the product from the shopping cart. .
     * If true, defining {@link onUpdateProductCount} is mandatory.
     *
     * @default false
     */
    displayRemoveFromCartButton?: boolean;

    /**
     * Should an {@link AddToCartButton} be rendered which the user can edit the products count with.
     * @default false
     */
    displayOnlyAddToCartButtonIcon?: boolean;

    /**
     * Called when the value in the {@link AddToCartButton} changes.
     *
     * @default undefined
     */
    onUpdateProductCount?(productId: string, newCount: number, productName: string | null): Promise<void>;

    /**
     * @default false
     */
    displayDiscountText?: boolean;

    /**
     * @default undefined
     */
    discount?: ProductOrganizationContractPriceModel;

    /**
     * Should a Date-input for estimated return date be displayed.
     * If true, defining {@link onUpdateEstimatedReturnDate} is mandatory.
     *
     * @default false
     */
    displayEstimatedReturnDateInput?: boolean;

    /**
     * Date displayed in the estimated return date input.
     *
     * @default undefined
     */
    estimatedReturnDate?: Date;

    /**
     * Minimum value the user can select in the estimated return date input.
     *
     * @default undefined
     */
    estimatedReturnDateMin?: Date;

    /**
     * Maximum value the user can select in the estimated return date input.
     *
     * @default undefined
     */
    estimatedReturnDateMax?: Date;

    /**
     * Prices and their units in the same line.
     *
     * @default undefined
     */
    inlinePriceRows?: boolean;

    /**
     * Called when the value in the estimated return date input changes.
     *
     * @default undefined
     */
    onUpdateEstimatedReturnDate?(productId: string, estimatedReturnDate: Date): Promise<void>;

    displayAttachedProducts?: boolean;
}

export default class ProductCard extends BaseComponent<IProductCardProps> {

    // Getters

    private get className(): string {
        const styleList: (string | undefined)[] = [];

        switch (this.props.style) {
            case ProductCardStyle.SingleColumn:
                styleList.push(styles.singleColumn);
                break;
            case ProductCardStyle.TwoColumns:
                styleList.push(styles.twoColumns);
                break;
            case ProductCardStyle.SingleRow:
                styleList.push(styles.singleRow);
                break;
            default:
                throw new TypeError(`Invalid ProductCardLayout enum value '${this.props.style}'`);
        }

        return this.css(styles.productCard, this.props.className, ...styleList);
    }

    private get classNames(): IProductCardClassNames | undefined {
        return this.props.classNames;
    }

    private get product(): ProductModel {
        return this.props.product;
    }

    private get productName(): string {
        return assert(this.product.name, "productName").isString.isNotEmpty.isNotWhitespace.getValue;
    }

    private productPageLink(): PageRoute {
        return PageDefinitions.productDetails.route({id: this.product.url!});
    }

    private get readonly(): boolean {
        return (this.props.readonly === true);
    }

    // Sync-methods

    public static toLayout(value: unknown): ProductCardStyle {
        switch (value) {
            case ProductCardStyle.SingleColumn:
                return ProductCardStyle.SingleColumn;
            case ProductCardStyle.TwoColumns:
                return ProductCardStyle.TwoColumns;
            case ProductCardStyle.SingleRow:
                return ProductCardStyle.SingleRow;
            default:
                throw new TypeError(`Non-existing ProductCardLayout enum-value ${value}`);
        }
    }

    // Renders

    private renderAvailability(): React.ReactNode {
        let availabilityClass: string = "";
        let availabilityIcon: string = "";
        let availabilityText: string = "";

        switch (this.props.availability) {
            case ProductCardAvailability.Available:
                availabilityClass = styles.available;
                availabilityIcon = "check-circle";
                availabilityText = Localizer.shoppingCartPageAvailable;
                break;
            case ProductCardAvailability.Unavailable:
                availabilityClass = styles.unavailable;
                availabilityIcon = "circle";
                availabilityText = Localizer.shoppingCartPageProductAvailabilityConfirmation;
                break;
            case ProductCardAvailability.Loading:
                availabilityClass = styles.loading;
                availabilityIcon = "spinner";
                availabilityText = Localizer.shoppingCartPageLoadingAvailability;
                break;
            default:
                return null;
        }

        return (
            <div className={this.css(styles.availability, availabilityClass, this.classNames?.availability)}>

                <Icon name={availabilityIcon}
                      style={IconStyle.Solid}
                />

                <small>
                    {availabilityText}
                </small>

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div className={this.className} id={`product_card_root`} data-cy={`${this.product.externalId}`}>
                {
                    this.renderAvailability()
                }

                <div className={this.css(styles.mainContainer, this.classNames?.mainContainer)}>
                    <Link className={styles.infoIconImageContainer}
                          route={this.productPageLink()}
                    >
                        <img className={styles.productImage}
                             src={ImageProvider.getProductThumbnailSrc(this.product)}
                             alt={""}
                             onError={ImageProvider.onError()}
                        />
                    </Link>

                    <Link route={this.productPageLink()} className={styles.productName}>
                        <h3>
                            {this.productName}
                        </h3>
                    </Link>

                    <Prices className={this.css(styles.productPrices, this.classNames?.prices)}
                            inlinePriceRows={this.props.inlinePriceRows}
                            product={this.product}
                            vat={this.props.vat}
                            maxRows={this.props.maxPriceRows}
                            contractDiscount={this.props.discount}
                            displayDiscountText={this.props.displayDiscountText}
                            displayWeeklyPriceTooltip={this.props.displayWeeklyPriceTooltip}
                    />

                    {
                        (this.props.displayAddToCartButton)
                            ?
                            (
                                <AddToCartButton displayRemoveFromCartButton
                                                 count={this.props.count}
                                                 disabled={this.readonly}
                                                 className={styles.addToCartButton}
                                                 hideButtonText={this.props.displayOnlyAddToCartButtonIcon}
                                                 onFirstClick={async () => await this.props.onUpdateProductCount!(this.product.id,1, this.product.name)}
                                                 onCountChange={async (newCount: number) => await this.props.onUpdateProductCount!(this.product.id, newCount, this.product.name)}
                                />
                            )
                            :
                            (
                                <TextInput readonly
                                           className={styles.count}
                                           value={this.props.count.toString()}
                                />
                            )
                    }

                    {
                        (this.props.displayEstimatedReturnDateInput) &&
                        (
                            <DateInput inline
                                       readonly={this.readonly}
                                       className={styles.estimatedReturnDate}
                                       label={Localizer.orderEndTimeText}
                                       value={this.props.estimatedReturnDate}
                                       minDate={this.props.estimatedReturnDateMin}
                                       maxDate={this.props.estimatedReturnDateMax}
                                       onChange={async (date: Date) => await this.props.onUpdateEstimatedReturnDate!(this.product.id, date)}
                            />
                        )
                    }

                    {
                        (this.props.displayAttachedProducts && !!this.props.product.attachedProducts?.length) &&
                        (
                            <AttachedProducts singleColumn
                                              attachedProducts={this.props.product.attachedProducts}
                                              className={styles.attachedProducts}
                                              mainProductCount={this.props.count}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}