import {SendRequestTo} from "@/models/Enums";

export default class SupportRequestTypeModel {

    public supportRequestTypeId: string | null = null;

    public requestTypeTitle: string | null = null;

    public sendRequestTo: SendRequestTo = SendRequestTo.Admin;

    public isSupportRequestTypeModel: boolean = true;
}