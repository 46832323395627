import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "@/localization/Localizer";

describe('Desktop - Authenticated construction site details feature flags tests', () => {
    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.visit('/');
    });

    describe('co2 flag enabled tests', () => {
        before(() => {
            CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagCo2Enabled);
        });

        it('Should display all tabs when all flags are enabled', () => {

            onRedirect(() => {
                executeWithIntercept(() =>
                        cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                    [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
                );
            });

            pageData().common.waitTopNavRender();

            pageData().constructionSite.equipment.tab();
            pageData().constructionSite.invoices.tab();
            pageData().constructionSite.users.tab();
            pageData().constructionSite.serviceRequests.tab();
            pageData().constructionSite.environmentControl.tab();
            pageData().constructionSite.emissions.tab();
        });
    });

    describe('co2 flag disabled tests', () => {

        before(() => {
            CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagCo2Enabled);
            CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagEnvironmentControl);
        });

        it('Should not display environmental and emissions tabs when flags disabled', () => {


            onRedirect(() => {
                executeWithIntercept(() =>
                        cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                    [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
                );
            });
            pageData().common.waitTopNavRender();
            pageData().constructionSite.equipment.tab();
            pageData().constructionSite.invoices.tab();
            pageData().constructionSite.users.tab();
            pageData().constructionSite.serviceRequests.tab();
            pageData().constructionSite.environmentControl.tab().should('not.exist');
            pageData().constructionSite.emissions.tab().should('not.exist');

            cy.reload();
            pageData().common.waitTopNavRender();
        });

        after(() => {
            CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagCo2Enabled);
            CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagEnvironmentControl);
        });

    });

});