import Localizer from "@/localization/Localizer";

describe('Desktop - Logged in company user device tracking tests', () => {

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should open device tracking page without access', () => {

        cy.intercept('POST', '/api/Product/HasAccessToProduct', { fixture: 'hasAccessToProductFalse.json' });

        cy.visit('/device-tracking?trackingSerialNumber=&rentaId=31157&rentalObjectId=344377');

        cy.get('h1').contains(Localizer.deviceTrackingCannotBeAccessedTitle);

        cy.get('div').contains(Localizer.deviceTrackingCannotBeAccessedDescription);

        cy.get('div').contains(Localizer.deviceTrackingCannotBeAccessedWhatNext);

        cy.get('li').contains(Localizer.genericTryLoggingInAgain);

        cy.get('a').contains(Localizer.genericContactSupport);

        cy.get('#data_not_found').should('not.exist');
    });

    it('should open device tracking page with access', () => {

        cy.intercept('POST', '/api/Product/HasAccessToProduct', { fixture: 'hasAccessToProductTrue.json' });

        cy.visit('/device-tracking?trackingSerialNumber=&rentaId=31157&rentalObjectId=344377');

        cy.get('h1').should('not.contain', Localizer.deviceTrackingCannotBeAccessedTitle);

        cy.get('div').should('not.contain', Localizer.deviceTrackingCannotBeAccessedDescription);

        cy.get('div').should('not.contain', Localizer.deviceTrackingCannotBeAccessedWhatNext);

        cy.get('li').should('not.contain', Localizer.genericTryLoggingInAgain);

        cy.get('a').should('not.contain', Localizer.genericContactSupport);

        cy.get('#data_not_found').should('exist');
    });

    it('should open device tracking page and see some data', () => {

        cy.intercept('POST', '/api/Product/HasAccessToProduct', { fixture: 'hasAccessToProductTrue.json' });

        cy.intercept('POST', '/api/Product/FindProductByExternalId', { fixture: 'findProductByExternalId.json' });

        cy.intercept('POST', '/api/Locations/GetDeviceLocation', { fixture: 'getDeviceLocation.json' });

        cy.intercept('POST', '/api/Locations/GetDeviceFuelLevel', { fixture: 'getDeviceFuelLevel.json' });

        cy.visit('/device-tracking?trackingSerialNumber=&rentaId=31157&rentalObjectId=344377');

        cy.get('h1').should('not.contain', Localizer.deviceTrackingCannotBeAccessedTitle);

        cy.get('div').should('not.contain', Localizer.deviceTrackingCannotBeAccessedDescription);

        cy.get('div').should('not.contain', Localizer.deviceTrackingCannotBeAccessedWhatNext);

        cy.get('li').should('not.contain', Localizer.genericTryLoggingInAgain);

        cy.get('a').should('not.contain', Localizer.genericContactSupport);

        cy.get('#data_not_found').should('not.exist');
    });

});
