import {ConstructionSiteFilterRentedOrReturned, EquipmentSortByType, EquipmentStatus, RentType} from "@/models/Enums";

export default class EquipmentToolbarModel {

    public products: string[] = [];

    public rentType: RentType | null = null;

    public renters: string[] = [];

    public contracts: string[] = [];

    public depos: string[] = [];

    public rentedOrReturnedStart: Date | null = null;

    public rentedOrReturnedEnd: Date | null = null;

    public rentedOrReturnedAt: ConstructionSiteFilterRentedOrReturned | null = null;

    public status: EquipmentStatus | null = EquipmentStatus.Rented;

    public categories: string[] = [];

    public searchQuery: string = "";

    public rentaFuture: boolean | null = null;

    public sortBy: EquipmentSortByType = EquipmentSortByType.Category;

}