import React, { useEffect, useState } from 'react';
import styles from './ReturnGrid.module.scss';

import {Box, LoadingDecorator} from '@renta-apps/renta-react-components';
import Localizer from "@/localization/Localizer";
import {ReturnRequestModel} from '@/models/server/ReturnRequestModel';
import ReturnGridRow from './ReturnGridRow';
import {ReturnDetailsModel} from '@/models/server/ReturnDetailsModel';

interface IReturnRequestProps {
    returnRequests: ReturnRequestModel[];
    returnRequestDetails: (id: string) => Promise<ReturnDetailsModel>;
    returnRequestsDetails: (ids: string[]) => Promise<ReturnDetailsModel[]>;
    isLoading: boolean;
    gridRef: React.RefObject<HTMLDivElement>;
}

const ReturnRequestGrid: React.FC<IReturnRequestProps> = ({
    returnRequests,
    returnRequestDetails,
    returnRequestsDetails,
    isLoading,
    gridRef,
}) => {
    const [data, setData] = useState<ReturnRequestModel[]>(returnRequests);
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [returnDetailsMap, setReturnDetailsMap] = useState<{ [key: string]: ReturnDetailsModel | null }>({});

    useEffect(() => {
        setData(returnRequests);
        // Reset expanded items when data changes
        setExpandedItems(new Set());
    }, [returnRequests]);

    const handleAccordionButtonClick = async (id: string) => {
        setExpandedItems(prevExpandedItems => {
            const newExpandedItems = new Set(prevExpandedItems);
            if (newExpandedItems.has(id)) {
                newExpandedItems.delete(id);
            }
            else {
                newExpandedItems.add(id);
            }
            return newExpandedItems;
        });

        if (!returnDetailsMap[id]) {
            try {
                const details = await returnRequestDetails(id);
                setReturnDetailsMap(prevDetails => ({ ...prevDetails, [id]: details }));
            }
            catch (error) {
                console.error('Error loading return requests details:', error);
                setReturnDetailsMap(prevDetails => ({ ...prevDetails, [id]: null }));
            }
        }
    };

    const handleHeaderAccordionClick = async () => {
        const allExpanded = expandedItems.size === data.length;

        if (allExpanded) {
            // Collapse all items
            setExpandedItems(new Set());
        }
        else {
            // Expand all items
            const newExpandedItems = new Set(data.map(item => item.id));
            setExpandedItems(newExpandedItems);

            // Fetch return request details for all items that are not already in the map
            const fetchRequest = data
                .filter(({ id }) => !returnDetailsMap[id])
                .map(({ id }) => ( id ));

            if (!fetchRequest.length) {
                return;
            }

            try {
                const details = await returnRequestsDetails(fetchRequest);
                const detailsMap = details.reduce((map, detail) => {
                    map[detail.id] = detail;
                    return map;
                }, {} as { [key: string]: ReturnDetailsModel });
                setReturnDetailsMap(prevDetails => ({ ...prevDetails, ...detailsMap }));
            }
            catch (error) {
                console.error('Error loading return request details:', error);
                const detailsMap = fetchRequest.reduce((map, id) => {
                    map[id] = null;
                    return map;
                }, {} as { [key: string]: ReturnDetailsModel | null });
                setReturnDetailsMap(prevDetails => ({ ...prevDetails, ...detailsMap }));
            }
        }
    };

    return (
        <>
            <div className={styles.dataTable} ref={gridRef} data-cy="return-request-grid">
                {/* Header Row */}
                <div className={`${styles.gridRow} ${styles.dataRow} ${styles.header}`}>
                    <div className={styles.constructionSite}>
                        <div>{Localizer.genericConstructionSite}</div>
                    </div>
                    <div className={styles.company}>
                        {Localizer.genericCompany}
                    </div>
                    <div className={styles.pickupDate}>
                        {Localizer.fleetMonitoringPageGridPickUpTime}
                    </div>
                    <div className={styles.pickupTime}>
                    </div>
                    <div className={styles.amount}>
                        {Localizer.fleetMonitoringPageGridAmount}
                    </div>
                    <div className={styles.devices}>
                        {Localizer.fleetMonitoringPageGridDevices}
                    </div>
                    <div className={styles.depot}>
                        {Localizer.fleetMonitoringPageGridDepot}
                    </div>
                    <div className={styles.status}>
                        {Localizer.fleetMonitoringPageGridStatus}
                    </div>
                    <div className={`${styles.gridItem} ${styles.actions}`}>
                        <div className={styles.accordionButton}
                            onClick={() => handleHeaderAccordionClick()}>
                            <i className={`fas fa-angle-down ${(data.length > 0 && expandedItems.size === data.length) ? styles.rotated : ''}`}></i>
                        </div>
                    </div>
                </div>

                {/* Loading Rows */}
                {[...Array(4)].map((_, index) => (
                    <LoadingDecorator key={index} className={styles.loaderContainer} hideConentOnLoading={false} isLoading={isLoading} />
                ))}

                {/* Display information about no data */}
                {!isLoading && data.length === 0 &&
                    <Box display="flex" alignItems="center" justifyContent="center" className={styles.loaderContainer}>
                        {Localizer.fleetMonitoringPageGridEmpty}
                    </Box>
                }

                {/* Data Rows */}
                {!isLoading && data.map((item, index) => (
                    <ReturnGridRow
                        key={item.id}
                        index={index}
                        item={item}
                        expanded={expandedItems.has(item.id)}
                        returnRequestDetails={returnDetailsMap[item.id]}
                        onAccordionButtonClick={() => handleAccordionButtonClick(item.id)}
                    />
                ))}
            </div>
        </>
    );
};

export default ReturnRequestGrid;