import React from "react";
import {CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, Link, OneColumn, PageHeader, SelectListItem} from "@renta-apps/athenaeum-react-components";
import {OrderStatus} from "@/models/Enums";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import GetOrdersPagedListRequest from "@/models/server/Requests/GetOrdersPagedListRequest";
import {ActionType, BaseComponent, ch, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import EnumProvider from "@/providers/EnumProvider";
import OrderOverviewModel from "@/models/server/OrderOverviewModel";
import AdminDeposLiteModel from "@/models/server/AdminDeposLiteModel";
import Localizer from "@/localization/Localizer";

import styles from "./OrdersGrid.module.scss";
import AdminOrdersGridToolbar from "../../pages/AdminOrders/AdminOrdersGridToolBar/AdminOrdersGridToolbar";
import UserContext from "@/models/server/UserContext";
import PageDefinitions from "@/providers/PageDefinitions";
import DepotModel from "@/models/server/DepotModel";

interface IOrdersProps {
    privateOrders?: boolean,
    adminView: boolean,
    locations: DepotModel[],
    currentDepot: DepotModel | null,
    contractId?: string | null,
    constructionSiteId?: string | null,
    isReturns: boolean,
    orderStatus?: OrderStatus
    noTitle: boolean;
}

interface IOrdersState {
    orderStatesSelectList: SelectListItem[],
    currentDepot: DepotModel | null,
    orderState: OrderStatus | null,
    dataRequest: GetOrdersPagedListRequest | null,
}

export default class OrdersGrid extends BaseComponent<IOrdersProps, IOrdersState> {

    // These are only used in admin view
    static defaultProps: IOrdersProps = {
        adminView: false,
        locations: [],
        currentDepot: null,
        isReturns: false,
        noTitle: false
    };

    public state: IOrdersState = {
        orderStatesSelectList: [],
        currentDepot: null,
        orderState: null,
        dataRequest: null,
    };

    private _ordersGridRef: React.RefObject<Grid<OrderOverviewModel>> = React.createRef();

    private _ordersGridColumnActions: ColumnActionDefinition[] = [
        {
            name: "reject",
            title: Localizer.genericRejectLanguageItemName,
            icon: {name: "fa fa-thumbs-down", customStyle: {fontSize: "1.5rem"}},
            type: ActionType.Grey,
            right: true,
            confirm: (cell: CellModel<OrderOverviewModel>) => Localizer.get(Localizer.confirmationRejectOrderLanguageItemName),
            callback: async (cell, action) => await this.rejectOrderAsync(cell.model.id)
        },
        {
            name: "confirm",
            title: Localizer.genericAcceptLanguageItemName,
            icon: {name: "fa fa-thumbs-up", customStyle: {fontSize: "1.5rem"}},
            type: ActionType.Create,
            right: true,
            confirm: (cell: CellModel<OrderOverviewModel>) => Localizer.get(Localizer.confirmationConfirmOrderLanguageItemName),
            callback: async (cell, action) => await this.confirmOrderAsync(cell.model.id)
        }
    ];

    private _returnsGridColumnActions: ColumnActionDefinition[] = [
        {
            name: "delete",
            title: Localizer.formDeleteLanguageItemName,
            icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
            type: ActionType.Delete,
            alwaysAvailable: true,
            right: true,
            confirm: (cell: CellModel<OrderOverviewModel>) => Localizer.get(Localizer.confirmationDeleteReturnLanguageItemName),
            callback: async (cell, action) => await this.deleteReturnAsync(cell.model.id)
        },
    ];

    private readonly _ordersColumns: ColumnDefinition[] = [
        {
            header: Localizer.ordersDateLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => (d.orderedDate)),
            format: "g",
            sorting: true,
            minWidth: 50,
            maxWidth: 70,
            editable: false,
        },
        {
            header: Localizer.ordersSubscriberLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => (d.userEmail)),
            sorting: true,
            minWidth: 150,
            editable: false,
            className: styles.linkCellStyle,
            actions: [
                {
                    render: (cell: CellModel<OrderOverviewModel>) => this.renderOrderPageLink(cell.model.id, cell.model.userEmail!),
                }
            ]
        },
        {
            header: Localizer.ordersProductAmountLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => (d.productsCount)),
            sorting: true,
            minWidth: 150,
            editable: false
        },
        {
            header: Localizer.ordersStatusLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => (d.status)),
            editable: false,
            type: ColumnType.Enum,
            sorting: true,
            format: "OrderStatus",
            init: (cell: CellModel<OrderOverviewModel>) => this.initColumn(cell),
        },
        {
            header: Localizer.formDelete,
            minWidth: 25,
            visible: this.adminView,
            actions: [
                {
                    name: "delete",
                    title: Localizer.formDeleteLanguageItemName,
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    alwaysAvailable: this.adminView,
                    confirm: (cell: CellModel<OrderOverviewModel>) => Localizer.get(Localizer.confirmationDeleteOrderLanguageItemName),
                    callback: async (cell, action) => await this.deleteOrderAsync(cell.model.id)
                }
          ]
        },
        {
            minWidth: 50,
            visible: this.adminView,
            actions: [...this._ordersGridColumnActions],
            init: (cell: CellModel<OrderOverviewModel>) => OrdersGrid.checkCellStatus(cell)
        }
    ];

    private readonly _returnsColumns: ColumnDefinition[] = [
        {
            header: Localizer.ordersDateLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => d.orderedDate),
            format: "g",
            sorting: true,
            minWidth: 150,
            editable: false,
        },
        {
            header: Localizer.ordersSubscriberLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => d.userEmail),
            sorting: true,
            minWidth: 150,
            editable: false,
            className: (this.adminView) ? styles.linkCellStyle : "",
            actions: [
                {
                    render: (cell: CellModel<OrderOverviewModel>) => this.renderReturnPageLink(cell.model.id, cell.model.userEmail!, cell),
                }
            ]
        },
        {
            header: Localizer.ordersProductAmountLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => d.productsCount),
            sorting: true,
            minWidth: 150,
            editable: false
        },
        {
            header: Localizer.ordersStatusLanguageItemName,
            accessor: nameof<OrderOverviewModel>(d => d.status),
            editable: false,
            type: ColumnType.Enum,
            sorting: true,
            format: "OrderStatus",
            init: (cell: CellModel<OrderOverviewModel>) => this.initColumn(cell),
        },
        {
            minWidth: 50,
            visible: this.adminView,
            actions: [...this._returnsGridColumnActions]
        }
    ];

    private initColumn(cell: CellModel<OrderOverviewModel>): void {
        EnumProvider.getOrderStatusText(cell.model.status);
        cell.readonly = true;
    }

    private get adminView(): boolean {
        return (this.props.adminView);
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private get orderState(): OrderStatus {
        return this.state.orderState ?? this.initialOrderStatus;
    }

    public get initialOrderStatus(): OrderStatus {
        return this.props.orderStatus ?? OrderStatus.Unconfirmed;
    }

    private get currentDepot(): DepotModel | null {
        return this.state.currentDepot ?? this.props.currentDepot;
    }

    private get depotId(): string {
        return this.currentDepot?.id ?? "";
    }

    private static checkCellStatus(cell: CellModel<OrderOverviewModel>): void {
        if (cell.model.status !== OrderStatus.Unconfirmed) {
            cell.actions.filter(action => {
                return (action.action.name === "confirm") || (action.action.name === "reject");
            })
                .forEach(action => {
                    action.visible = false;
                });

        }
    }

    private async fetchOrderDataAsync(pageNumber: number,
                                      pageSize: number,
                                      sortColumnName: string | null,
                                      sortDirection: SortDirection | null
    ): Promise<IPagedList<OrderOverviewModel>> {

        if (this.adminView && (!this.currentDepot)) {
            await this.setState({currentDepot: this.props.currentDepot});
        }

        const request: GetOrdersPagedListRequest = {
            contractId: (!this.adminView && !this.props.privateOrders && this.props.contractId)
                ? (this.props.contractId ?? null)
                : null,
            constructionSiteId: this.props.constructionSiteId ?? null,
            depotId: (this.adminView) ? this.depotId : null,
            state: (this.adminView) ? this.orderState : OrderStatus.Unconfirmed,
            isAdminView: this.adminView,
            userId: (this.props.privateOrders) ? this.userContext.user!.id : null,
            sortColumnName,
            sortDirection,
            pageNumber,
            pageSize
        };

        await this.setState({
            dataRequest: request
        });

        if (this.props.isReturns) {
            return await this.postAsync(`/api/Product/GetReturnsPagedList`, request);
        }
        else {
            return await this.postAsync(`/api/Product/GetOrdersPagedList`, request);
        }
    }

    private async deleteOrderAsync(orderId: string): Promise<void> {
        await this.postAsync(`/api/Admin/DeleteOrder`, orderId);
        await this.refreshGridAsync();
    }

    private async deleteReturnAsync(orderId: string): Promise<void> {
        await this.postAsync(`/api/Admin/DeleteReturn`, orderId);
        await this.refreshGridAsync();
    }

    private async confirmOrderAsync(orderId: string): Promise<void> {
        await this.postAsync(`/api/Admin/ConfirmOrder`, orderId);
        await this.refreshGridAsync();
    }

    private async rejectOrderAsync(orderId: string): Promise<void> {
        await this.postAsync(`/api/Admin/RejectOrder`, orderId);
        await this.refreshGridAsync();
    }

    private async refreshGridAsync() {
        if (this._ordersGridRef.current) {
            await this._ordersGridRef.current.reloadAsync();
        }
    }

    private getOrderStatusName(item: string | number): string {
        const splittedStatusName = EnumProvider.getOrderStatusName(item as unknown as OrderStatus).split(".");
        return splittedStatusName[splittedStatusName.length - 1];
    }

    private async setCurrentLocation(currentLocation: DepotModel | null): Promise<void> {

        if (!currentLocation) {
            return;
        }

        const status = this.getOrderStatusName(this.orderState);

        const newRoute = this.getPage().route;

        newRoute.parameters = {depotId: currentLocation.id, state: status};

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(newRoute);

        await this.setState(({currentDepot: currentLocation}));

        await this.refreshGridAsync();
    }

    private async setOrderState(item: SelectListItem): Promise<void> {

        if (!item) {
            return;
        }

        let status = this.getOrderStatusName(item.value);

        const newRoute = this.getPage().route;
        newRoute.parameters = {depotId: this.depotId, state: status};
        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(newRoute);

        await this.setState({orderState: item?.value as unknown as OrderStatus});

        await this.refreshGridAsync();
    }

    private renderOrderPageLink(contractId: string, userEmail: string): React.ReactNode {
        return (
            <Link key={contractId}
                  route={this.orderPageLink(contractId)}
                  className={styles.link}
            >
                {userEmail}
            </Link>
        );
    }

    private renderReturnPageLink(contractId: string, userEmail: string, cell: CellModel<OrderOverviewModel>): React.ReactNode {
        return (this.adminView)
            ?
            (
                <Link key={contractId}
                      route={this.returnPageLink(contractId)}
                      className={styles.link}
                >
                    {userEmail}
                </Link>
            )
            : null;
    }

    private returnPageLink(contractId: string): PageRoute {
        return PageDefinitions.adminReturnDetails.route({id: contractId});
    }

    private orderPageLink(contractId: string): PageRoute {
        return PageDefinitions.order.route({id: contractId});
    }

    private renderTitle(): React.ReactNode {

        if (this.props.noTitle) {
            return;
        }

        const title: string | null = (this.props.isReturns)
            ? Localizer.breadCrumbReturns
            : (this.adminView)
                ? `${Localizer.ordersPageOrders}: ${this.currentDepot?.externalId} - ${this.currentDepot?.name})`
                : null;

        if (title) {
            return (
                <PageHeader title={title}/>
            );
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this._ordersGridRef.current) {
            this._ordersGridRef.current.model.sortDirection = SortDirection.Desc;
        }
    }

    public render(): React.ReactNode {

        return (
            <OneColumn className={this.css(styles.ordersGrid)}>

                {
                    this.renderTitle()
                }

                {
                    (this.adminView) &&

                    (
                        <AdminOrdersGridToolbar isReturnsGridToolbar={this.props.isReturns}
                                                locations={this.props.locations}
                                                currentLocation={this.currentDepot}
                                                orderState={this.orderState}
                                                onChangeState={async (item) => await this.setOrderState(item)}
                                                onChangeLocation={async (item) => await this.setCurrentLocation(item)}
                        />
                    )
                }

                {
                    ((this.adminView && this.currentDepot) || (!this.adminView)) &&

                    (
                        <Grid pagination
                              responsive
                              version2Styles
                              id={"ordersGrid"}
                              ref={this._ordersGridRef}
                              noDataText={Localizer.ordersPageNoOrders}
                              columns={(this.props.isReturns) ? this._returnsColumns : this._ordersColumns}
                              fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                                  await this.fetchOrderDataAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                        />
                    )
                }

            </OneColumn>
        );
    }

}