import React from "react";
import {Button, ButtonType, Dropdown, PageContainer, PageHeader, SelectListItem} from "@renta-apps/athenaeum-react-components";
import OrderDetailsResponse from "@/models/server/Responses/OrderDetailsResponse";
import {OrderStatus, SaveOrderRequestStatus} from "@/models/Enums";
import EnumProvider from "@/providers/EnumProvider";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import ProductCard, {ProductCardStyle} from "@/components/ProductCard/ProductCard";
import Localizer from "@/localization/Localizer";
import PriceHelper from "@/helpers/PriceHelper";
import RentaEasyController from "@/pages/RentaEasyController";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import DepotModel from "@/models/server/DepotModel";

import {AlertModel, AlertType, BasePageParameters} from "@renta-apps/athenaeum-react-common";
import styles from './OrderDetails.module.scss';

enum RequestState {
    NotSending = 0,
    Sending = 1,
    Success = 2,
}

interface IProductCount {
    id: string,
    count: number
}

interface IResponse {
    alertMessage: string,
    errorMessage: string
}

export interface IOrderParams extends BasePageParameters {
    adminRequest?: boolean;
    orderConfirmation?: boolean;
    inviteUserConfirmation?: IResponse | null;
}

interface IOrderState {
    order: OrderDetailsResponse | null;
    isLoading: boolean;
    filterText: string;
    unAvailableProductIds: string[];
    unavailableProductsLoaded: RequestState;
    editMode: boolean;
    selectedOrderStatus: OrderStatus | null;
    orderNumber: string | null;
    selectedDepotId: string | null;
    updatedProductCounts: IProductCount[];
    depots: DepotModel[];
}

export default class OrderDetails extends AuthorizedPage<IOrderParams, IOrderState> {

    public state: IOrderState = {
        isLoading: true,
        order: null,
        filterText: '',
        unAvailableProductIds: [],
        unavailableProductsLoaded: RequestState.NotSending,
        editMode: false,
        selectedOrderStatus: null,
        orderNumber: null,
        selectedDepotId: null,
        updatedProductCounts: [],
        depots: []
    };

    private get selectedOrder(): OrderDetailsResponse | null {
        return this.state.order;
    }

    private get confirmedByText(): string {
        return (
            (this.selectedOrder?.status !== OrderStatus.Rejected)
                ? Localizer.ordersPageConfirmedBy
                : Localizer.ordersPageRejectedBy
        );
    }

    private get confirmedAtText(): string {
        return (
            (this.selectedOrder?.status !== OrderStatus.Rejected)
                ? Localizer.ordersPageConfirmedAt
                : Localizer.ordersPageRejectedAt
        );
    }

    private get editMode(): boolean {
        return (this.isAdmin && this.state.editMode);
    }

    private get orderDate(): string {
        return (this.selectedOrder)
            ? Utility.format("{0:D}", Utility.toLocal(this.selectedOrder.orderDate))
            : '-';
    }

    private get displayName(): string {
        return (this.selectedOrder?.userEmail) ? `${this.selectedOrder?.userEmail} ${this.orderDate}` : this.orderDate;
    }

    protected get title(): string {
        return Localizer.breadCrumbOrder;
    }

    private async fetchOrderDetailsAsync(): Promise<OrderDetailsResponse | null> {
        let orderId: string | null = this.routeId;

        if (!orderId && this.parameters) {
            const params = this.parameters as { id?: string; };
            orderId = params.id ?? null;
        }

        if (!orderId) {
            return null;
        }

        const request = {
            id: orderId,
            adminRequest: this.isAdmin
        };

        return await this.postAsync(`/api/Product/OrderDetails`, request);
    }

    private async setEditMode(): Promise<void> {
        await this.setState({editMode: !this.state.editMode, updatedProductCounts: []});
    }

    private async handleProductCountChange(id: string, newCount: number) {

        const updatedCountIndex = this.state.updatedProductCounts.findIndex((product) => product.id === id);

        if (updatedCountIndex === -1) {
            const updatedCount: IProductCount = {id, count: newCount};
            await this.setState(({updatedProductCounts: this.state.updatedProductCounts.concat(updatedCount)}));
            return;
        }

        const copyOfState: IProductCount[] = Object.assign([], [...this.state.updatedProductCounts]);
        copyOfState[updatedCountIndex].count = newCount;

        await this.setState({updatedProductCounts: copyOfState});
    }

    private async setSelectedLocation(item: string | null) {
        await this.setState({selectedDepotId: item});
    }

    private async setSelectedOrderStatus(item: SelectListItem) {
        await this.setState({selectedOrderStatus: item?.value as unknown as OrderStatus ?? null});
    }

    private async handleSave(): Promise<void> {

        const request = {
            orderId: this.selectedOrder?.productsCartData!.orderId,
            selectedDepotId: this.state.selectedDepotId ?? this.selectedOrder?.depotId,
            selectedState: this.state.selectedOrderStatus ?? this.selectedOrder?.status,
            updatedProductCounts: this.state.updatedProductCounts ?? null
        };

        await this.setState({isLoading: true});

        const response: SaveOrderRequestStatus = await this.postAsync("/api/Product/SaveOrder", request);

        if (response === SaveOrderRequestStatus.Success) {
            const message = Utility.format(Localizer.resultOrderUpdated, this.displayName);
            await this.initializeAsync(message);
        }
        else {
            await this.initializeAsync();
        }
    }

    private getVisibleCount(product: ShoppingCartProductModel): number {
        const item = this.state.updatedProductCounts.find(item => item.id === product.product?.id);
        return item?.count ?? product.count;
    }

    public async initializeAsync(message?: string): Promise<void> {

        await super.initializeAsync();

        const order: OrderDetailsResponse | null = await this.fetchOrderDetailsAsync();

        let orderConfirmationText: string = "";

        if (this.props.parameters?.orderConfirmation) {
            if (order?.status === OrderStatus.Unconfirmed) {
                orderConfirmationText = Localizer.shoppingCartSentForApproval;
            } else {
                orderConfirmationText = Localizer.shoppingCartSentShort;
            }
        }
        
        if (this.props.parameters?.inviteUserConfirmation) {
            if (this.props.parameters?.inviteUserConfirmation.alertMessage === "") {
                orderConfirmationText = orderConfirmationText + "\\n " + this.props.parameters?.inviteUserConfirmation.errorMessage;
            }
            else {
                orderConfirmationText = orderConfirmationText + "\\n " + this.props.parameters?.inviteUserConfirmation.alertMessage;
            }
        }

        const alert = new AlertModel(orderConfirmationText, AlertType.Info, false, false);
        await this.alertAsync(alert);

        const depots: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        await this.setState({
            isLoading: false,
            order,
            orderNumber: order?.externalId ?? null,
            editMode: false,
            depots: depots,
        });

        if (message) {
            this.alertMessageAsync(message, true, true);
        }
    }

    public renderProducts(selectedOrder: OrderDetailsResponse): JSX.Element | null {
        const products: ShoppingCartProductModel[] = selectedOrder.productsCartData?.products ?? [];

        return (
            <div className={this.css(styles.orderProducts)}>

                <h3>{Localizer.orderProductsText}:</h3>

                {
                    <div className={this.css(styles.productCardContainer)}>
                        {
                            products.map((shoppingCartProduct: ShoppingCartProductModel, index: number) => (
                                <div key={shoppingCartProduct.product!.id + `-${index}`}
                                     className={this.css(styles.cardContainer)}
                                >
                                    <ProductCard readonly={!this.editMode}
                                                 displayAddToCartButton={this.editMode}
                                                 className={styles.productCard}
                                                 classNames={{
                                                     mainContainer: styles.productCardMainContainer
                                                 }}
                                                 style={ProductCardStyle.TwoColumns}
                                                 product={shoppingCartProduct.product!}
                                                 count={this.getVisibleCount(shoppingCartProduct)}
                                                 vat={PriceHelper.environmentVat}
                                                 onUpdateProductCount={async (productId, newCount) => {
                                                     await this.handleProductCountChange(productId, newCount);
                                                 }}
                                    />
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        );
    }

    public renderSelectedOrderDetails(selectedOrder: OrderDetailsResponse): JSX.Element | undefined {
        const startTime: string = (selectedOrder.startTime)
            ? Utility.format("{0:D}", Utility.toLocal(selectedOrder.startTime))
            : "-";

        const endTime: string = (selectedOrder.endTime)
            ? Utility.format("{0:D}", Utility.toLocal(selectedOrder.endTime))
            : "-";

        const dataStatus = Object.values(OrderStatus)[selectedOrder.status];

        return (
            <div className={styles.selectedOrderDetails}>
                <div>
                    <h3>{Localizer.orderInfoText}</h3>

                    {
                        (!this.editMode) &&
                        (
                            <div>
                                <b>{Localizer.orderStatusText}</b>

                                <span className={this.css(styles.statusText)} data-status={dataStatus}>
                                    {EnumProvider.getOrderStatusText(selectedOrder.status)}
                                </span>
                            </div>
                        )
                    }
                    {
                        (this.editMode) &&
                        (
                            <Dropdown id="changeState"
                                      className="py-2"
                                      label={Localizer.orderStatusText}
                                      nothingSelectedText={Localizer.orderDetailsChangeState}
                                      selectedItem={selectedOrder.status}
                                      items={EnumProvider.getOrderStatusItems()}
                                      onChange={async (_, value) => {
                                          await this.setSelectedOrderStatus(value as SelectListItem);
                                      }}
                            />
                        )
                    }

                    <div>
                        <b>{Localizer.orderPersonText}</b>
                        <span>{selectedOrder.orderedBy}</span>
                    </div>

                    <div>
                        <b>{Localizer.orderDoneText}</b>
                        <span>{this.orderDate}</span>
                    </div>

                    <div>
                        <b>{Localizer.orderStatusMessageText}</b>
                        <b> {selectedOrder.statusMessage}</b>
                    </div>

                    <div>
                        <b>{Localizer.orderIdText}</b>
                        <span>{selectedOrder.orderNumber}</span>
                    </div>

                    {
                        (this.isAdmin && selectedOrder.confirmedAt && selectedOrder.confirmedBy) &&
                        (
                            <>
                                <p>
                                    <span>
                                        <b>{this.confirmedByText}: </b>
                                        {selectedOrder.confirmedBy?.fullName} (<a href={`tel:${selectedOrder.confirmedBy?.phoneNumber}`}>{selectedOrder.confirmedBy?.phoneNumber}</a>, <a
                                        href={`mailto:${selectedOrder.confirmedBy.email}`}>{selectedOrder.confirmedBy.email}</a>)
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <b>{this.confirmedAtText}: </b>
                                        {`${new Date(selectedOrder.confirmedAt).format("dd.MM.yyyy")} ${new Date(selectedOrder.confirmedAt).format("HH:mm:ss")}`}
                                    </span>
                                </p>
                            </>
                        )
                    }

                </div>

                <div>
                    <h3>{Localizer.orderShippingAndCustomerInfo}</h3>

                    <div>
                        <b>{Localizer.orderClientText}</b>
                        <span>{selectedOrder.contactName}</span>
                    </div>

                    <div>
                        <b>{Localizer.orderSiteNameText}</b>
                        <span>{selectedOrder.constructionSiteDisplayName}</span>
                    </div>

                    <div>
                        <b>{Localizer.orderDeliveryMethodText}</b>
                        <span>{EnumProvider.getOrderTypeText(selectedOrder.orderType)}</span>
                    </div>

                    <br/>

                    <div>
                        <b>{Localizer.orderStartTimeText}</b>
                        <span>{startTime}</span>
                    </div>

                    <div>
                        <b>{Localizer.orderEndTimeText}</b>
                        <span>{endTime}</span>
                    </div>

                    <br/>

                    {
                        (this.editMode) &&
                        (
                            <Dropdown id="changeWarehouse"
                                      className="py-2"
                                      label={Localizer.orderLocationText}
                                      nothingSelectedText={Localizer.orderDetailsChangeWarehouse}
                                      selectedItem={selectedOrder.depotId}
                                      items={this.state.depots}
                                      onChange={async (_) => {
                                          await this.setSelectedLocation(_.selectedValue);
                                      }}
                            />
                        )
                    }
                    {
                        (!this.editMode) &&
                        (
                            <div>
                                <b>{Localizer.orderLocationText}</b>
                                <span>{selectedOrder.depotName}</span>
                            </div>
                        )
                    }

                    <div>
                        <b>{Localizer.orderAddressText}</b>
                        <span id={"order_details_address"}>{selectedOrder.address}</span>
                    </div>
                    <div>
                        <b>{Localizer.orderCityText}</b>
                        <span id={"order_details_city"}>{selectedOrder.city}</span>
                    </div>
                    <div>
                        <b>{Localizer.orderPostalCodeText}</b>
                        <span id={"order_details_postalCode"}>{selectedOrder.postalCode}</span>
                    </div>

                    <br/>

                    <div>
                        <b>{Localizer.orderFirstNameText}</b>
                        <span>{selectedOrder.orderedBy}</span>
                    </div>

                    <div>
                        <b>{Localizer.orderPhoneNumberText}</b>
                        <a href={`tel:${selectedOrder.userPhoneNumber}`}> {selectedOrder.userPhoneNumber}</a>
                    </div>

                    <div>
                        <b>{Localizer.orderEmailText}</b>
                        <a href={`mailto:${selectedOrder.userEmail}`}> {selectedOrder.userEmail}</a>
                    </div>
                </div>
            </div>
        );
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer hasWideHeader>
                <PageHeader title={this.title} wideHeader wideHeaderBackgroundImage="/images/renta-kuva-6-scaled.jpg"/>

                <div className="d-flex flex-column gap-3">
                    {
                        (this.isAdmin || this.editMode) && (
                            <div className="d-flex gap-2">
                                {
                                    (this.isAdmin) &&
                                    (
                                        <Button label={(this.state.editMode ? Localizer.formCancel : Localizer.catalogEditProductEditButton)}
                                                id="editModeButton"
                                                onClick={async () => await this.setEditMode()}
                                        />
                                    )
                                }
                                {
                                    (this.editMode) &&
                                    (
                                        <Button type={ButtonType.Orange}
                                                label={Localizer.genericSave}
                                                id="saveButton"
                                                onClick={async () => {
                                                    await this.handleSave();
                                                }}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        (this.selectedOrder) && (
                            this.renderSelectedOrderDetails(this.selectedOrder)
                        )
                    }
                    {
                        (this.selectedOrder) && (
                            this.renderProducts(this.selectedOrder)
                        )
                    }
                </div>
            </PageContainer>
        );
    }

}