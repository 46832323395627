import React from "react";
import Localizer from "@/localization/Localizer";
import TrackUnitBaseModel from "@/models/server/TrackUnitBaseModel";
import {AddressHelper, CellModel, ColumnDefinition, GoogleMap, Grid, IGoogleMapInfoWindow, IGoogleMapMarker, OneColumn, PageContainer, PageHeader, PageRow, TextInput} from "@renta-apps/athenaeum-react-components";
import {ArrayUtility, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import styles from "./AdminDeviceLocations.module.scss";
import {BasePageParameters, IBasePageProps, PageRoute} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import AdminPage from "@/models/base/AdminPage";

export interface IAdminDeviceLocationsParams extends BasePageParameters {
}

interface IAdminDeviceLocationsState {
    markers: IGoogleMapMarker[],
    products: TrackUnitBaseModel[],
    filteredProducts: TrackUnitBaseModel[],
    startLongitude: number,
    startLatitude: number,
    keyword: string | null
    pageNumberAfterFilterChange: number | null;
}

export default class AdminDeviceLocations extends AdminPage<IAdminDeviceLocationsParams, IAdminDeviceLocationsState> {

    public state: IAdminDeviceLocationsState = {
        markers: [],
        startLatitude: RentaEasyConstants.defaultLatitude,
        startLongitude: RentaEasyConstants.defaultLongitude,
        products: [],
        filteredProducts: [],
        keyword: null,
        pageNumberAfterFilterChange: null
    };

    private timeout: number;

    private readonly _devicesGrid: React.RefObject<Grid<TrackUnitBaseModel>> = React.createRef();
    private readonly _mapRef: React.RefObject<GoogleMap> = React.createRef();

    private readonly _devicesColumns: ColumnDefinition[] = [
        {
            header: Localizer.productTextLanguageItemName,
            accessor: nameof<TrackUnitBaseModel>(d => (d.productName)),
            sorting: true,
            route: (cell: CellModel<TrackUnitBaseModel>) => this.getRoute(cell.model)
        },
        {
            header: Localizer.productProductCategoryLanguageItemName,
            accessor: nameof<TrackUnitBaseModel>(d => (d.productGroupId)),
            sorting: true,
        },
        {
            header: Localizer.productNumberTextLanguageItemName,
            accessor: nameof<TrackUnitBaseModel>(d => (d.rentaId)),
            sorting: true
        },
        {
            header: Localizer.trackUnitIdTextLanguageItemName,
            accessor: nameof<TrackUnitBaseModel>(d => (d.serialNumber)),
            sorting: true
        },
        {
            header: Localizer.customerText,
            accessor: nameof<TrackUnitBaseModel>(d => (d.customerName)),
            sorting: true
        },
    ];

    constructor(props: IBasePageProps<IAdminDeviceLocationsParams>) {
        super(props);
        this.timeout = 0;
    }

    private get startLongitude(): number {
        return this.state.startLongitude;
    }

    private get products(): TrackUnitBaseModel[] {
        return this.state.products ?? [];
    }

    private get startLatitude(): number {
        return this.state.startLatitude;
    }

    private get availabilityMapInitialCenter(): google.maps.LatLngLiteral {
        return {
            lat: this.startLatitude,
            lng: this.startLongitude,
        };
    }

    private get devicesMarkers(): IGoogleMapMarker[] {

        return this.state
            .filteredProducts
            .filter(p => p.productName)
            .map(
                product => {

                    const position: google.maps.LatLngLiteral = {
                        lat: product.location!.latitude,
                        lng: product.location!.longitude
                    };

                    let content: string = `<b>${product.productName}</b>`;

                    if (product.rentaId) {
                        content += ` (${product.rentaId})`;
                    }

                    if (product.orderedBy) {
                        content += `<br/><b>${Localizer.constructionDetailsOrdered}</b>: ${product.orderedBy}`;
                    }

                    if (product.customerPhone) {
                        content += `<br/><b>${Localizer.genericPhoneNumber}</b>: ${product.customerPhone}`;
                    }

                    const infoWindow: IGoogleMapInfoWindow = {
                        content,
                        position,
                        pixelOffset: new google.maps.Size(0, -60),
                    };

                    const marker: IGoogleMapMarker = {
                        title: product.id,
                        position,
                        infoWindow,
                    };

                    return marker;
                });
    }

    protected get title(): string {
        return Localizer.breadCrumbDeviceTracking;
    }

    private getRoute(model: TrackUnitBaseModel): PageRoute {
       return PageDefinitions.deviceDetailsPage.route({
            params: {
                rentaId: model.rentaId!,
            }
        })
    }

    private async getTrackUnitDevices(pageNumber: number,
                                      pageSize: number,
                                      sortColumnName: string | null,
                                      sortDirection: SortDirection | null): Promise<IPagedList<TrackUnitBaseModel>> {

        let trackUnitDevices: TrackUnitBaseModel[] = this.state.products;

        if (this.state.keyword) {
            trackUnitDevices = trackUnitDevices
                .filter(
                    trackUnitDevice =>
                        Object.values(trackUnitDevice)
                            .some(
                                value =>
                                    (value?.toString()
                                        .toLowerCase()
                                        .includes(this.state.keyword!.toLowerCase()))));

            await this.setState({
                filteredProducts: trackUnitDevices
            });
        }

        if (sortColumnName) {
            trackUnitDevices = trackUnitDevices
                .sort(
                    ArrayUtility.sortByProperty(
                        sortColumnName,
                        (sortDirection === SortDirection.Asc)
                            ? SortDirection.Desc
                            : SortDirection.Asc));
        }

        const pageNumberToUse: number = this.state.pageNumberAfterFilterChange ?? pageNumber;

        if (this.state.pageNumberAfterFilterChange) {
            this.state.pageNumberAfterFilterChange = null;
        }

        return trackUnitDevices.toPagedList(pageNumberToUse, pageSize);
    }

    private async setKeyWordAsync(keyword: string): Promise<void> {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            this.state.keyword = keyword;
            this.state.pageNumberAfterFilterChange = 1;
            this._devicesGrid.current!.reloadAsync();
            if (this._mapRef.current) {
                this._mapRef.current.reRender();
            }
        }, 420);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const data: TrackUnitBaseModel[] = await this.postAsync("/api/Locations/ListTrackingDevices", null);

        await this.setState({
            products: data,
            filteredProducts: data,
            startLatitude: RentaEasyConstants.defaultLatitude,
            startLongitude: RentaEasyConstants.defaultLongitude,
        });
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.deviceTrackingPage}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />
                {
                    (this.products.length > 0 && AddressHelper.isGoogleApiRegistered) && (
                        <>
                            <OneColumn>
                                <GoogleMap clusterMarkers autoCloseInfoWindows
                                           ref={this._mapRef}
                                           height={500}
                                           initialCenter={this.availabilityMapInitialCenter}
                                           initialZoom={5}
                                           markers={this.devicesMarkers}
                                />
                            </OneColumn>

                            <PageRow>
                                <TextInput className={styles.searchBar}
                                           label={Localizer.ordersPageSearch}
                                           width={"200px"}
                                           value={this.state.keyword!}
                                           onChange={async (sender, value) => await this.setKeyWordAsync(value)}
                                />

                                <Grid responsive
                                      version2Styles
                                      pagination={10}
                                      ref={this._devicesGrid}
                                      columns={this._devicesColumns}
                                      noDataText={Localizer.componentDropdownNoData}
                                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                                          await this.getTrackUnitDevices(pageNumber, pageSize, sortColumnName, sortDirection)}
                                />
                            </PageRow>
                        </>
                    )
                }

                {
                    (this.products.length <= 0 && AddressHelper.isGoogleApiRegistered) && (
                        <GoogleMap clusterMarkers autoCloseInfoWindows
                                   ref={this._mapRef}
                                   height={500}
                                   initialCenter={this.availabilityMapInitialCenter}
                                   initialZoom={5}
                        />
                    )
                }
            </PageContainer>
        );
    }

}