import {IBaseGridProps, IBaseGridState} from "@/models/BaseGridParameters";
import {ActionType, BaseComponent, ch, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import React from "react";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Form,
    Grid,
    Icon,
    InlineType,
    Link,
    PageRow,
    TextInput,
    Tooltip,
} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "./CompaniesGrid.module.scss";
import RentaEasyController from "@/pages/RentaEasyController";
import ConstructionSitesPageParameters from "@/models/ConstructionSitesPageParameters";
import ListContractsPagedRequest from "@/models/server/Requests/ListContractsPagedRequest";
import {OrganizationContractLevel} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import {UserRolesHelper} from "@/helpers/UserRolesHelper";

interface ICompaniesGridProps extends IBaseGridProps {
    showFavorites?: boolean;
    showDeleted?: boolean;
    renderFor: PageRoute;

    getCompanies(pageNumber: number,
                 pageSize: number,
                 sortColumnName: string | null,
                 sortDirection: SortDirection | null,
                 searchTerm: string | null,
                 showOnlyFavorites: boolean | false,
                 showDeleted: boolean | false): Promise<IPagedList<OrganizationContractModel>>;
}

interface ICompaniesGridState extends IBaseGridState {
    showFavorites: boolean | false;
    showDeleted: boolean | false;
    editMode: boolean;
}

export default class CompaniesGrid extends BaseComponent<ICompaniesGridProps, ICompaniesGridState> {
    private readonly defaultPageSize: number = 25;

    public state: ICompaniesGridState = {
        editMode: false,
        showDeleted: this.props.showDeleted ?? false,
        showFavorites: this.props.showFavorites ?? false,
        keyword: this.props.searchKeyword ?? null,
        pageNumber: this.props.pageNumber === undefined ? 1 : Number(this.props.pageNumber),
        pageSize: this.props.pageSize === undefined ? this.defaultPageSize : Number(this.props.pageSize),
        sortColumnName: this.props.sortColumnName ?? null,
        sortDirection: this.props.sortDirection ?? null,
    };

    private get editMode(): boolean {
        return this.state.editMode;
    }

    private readonly _companiesGrid: React.RefObject<Grid<OrganizationContractModel>> = React.createRef();

    private readonly _companiesColumns: ColumnDefinition[] = [
        {
            minWidth: 50,
            maxWidth: 50,
            visible: (ch.getContext() as UserContext).isAdmin,
            actions: [
                {
                    name: "favorite",
                    type: ActionType.Muted,
                    right: true,
                    render: (cell: CellModel<OrganizationContractModel>, action: CellAction<OrganizationContractModel>) => this.renderStarIcon(cell, action)
                }
            ]
        },
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: nameof<OrganizationContractModel>(d => d.name),
            editable: this.editMode,
            route: !this.editMode ? (cell: CellModel<OrganizationContractModel>) =>PageDefinitions.contractDetails.route({
                params: {
                    id: cell.model.contractId,
                },
            }) : undefined,
            type: ColumnType.Text,
            sorting: true,
            minWidth: 90,
            init: (cell) => this.initNameCell(cell)
        },
        {
            header: Localizer.companiesOverviewCustomerNumbers,
            accessor: nameof<OrganizationContractModel>(d => d.contractId),
            editable: false,
            sorting: false,
            maxWidth: 200,
            minWidth: 100,
            className: styles.linkCellStyle,
            wordBreak: true,
            actions: [
                {
                    render: (cell: CellModel<OrganizationContractModel>) => this.renderContractIds(cell.model),
                }
            ],
        },
        {
            header: Localizer.companiesDetailVatIdLanguageItemName,
            accessor: nameof<OrganizationContractModel>(d => d.vatId),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            accessor: nameof<OrganizationContractModel>(d => d.organizationId),
            transform: () => Localizer.workSiteText,
            route: (cell: CellModel<OrganizationContractModel>) => PageDefinitions.constructionSites.route({params: this.selectedConstructionSiteContractId(cell.model)}),
            editable: false,
            minWidth: 90
        },
        {
            minWidth: 25,
            type: ColumnType.Icon,
            className: styles.halfWidth,
            init: (cell) => this.initContractOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSave,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processGridOperationAsync(cell, action)
                },
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far pen",
                    type: ActionType.Edit,
                    callback: async (cell, action) => await this.processGridOperationAsync(cell, action)
                }
            ]
        }
    ];

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this._companiesGrid.current) {
            this._companiesGrid.current.model.pageNumber = this.state.pageNumber;
        }
    }

    private initNameCell(cellModel: CellModel<any>) {
        cellModel.route = this.editMode ? null : cellModel.route;
        cellModel.readonly = !this.state.editMode;
    }

    private async processGridOperationAsync(cell: CellModel<OrganizationContractModel>, action: CellAction<OrganizationContractModel>): Promise<void> {
        const model: OrganizationContractModel = cell.model;

        if (action.action.name === "save" && model.name) {
            const response: OrganizationContractModel = await RentaEasyController.saveContractName({contractId: model.contractId, name: model.name}, this._companiesGrid.current);
            cell.row.model = response!;
            await this.setState({editMode: false});
            await cell.row.bindAsync();
        }

        if (action.action.name === "edit") {
            await this.setState({editMode: true});
        }
    }

    private async initContractOperationsAsync(cell: CellModel<OrganizationContractModel>): Promise<void> {
        const model: OrganizationContractModel = cell.row.model;

        const modified: boolean = cell.row.modified;
        const isValid: boolean = this.isValid(model);

        const saveAction: CellAction<OrganizationContractModel> = cell.actions[0];
        const editAction: CellAction<OrganizationContractModel> = cell.actions[1];

        saveAction.visible = (modified) && (isValid);
        editAction.visible = ((ch.getContext() as UserContext).isAdminWithAdminRole && !this.state.editMode);
    }

    private isValid(contract: OrganizationContractModel): boolean {
        return (!!contract.name);
    }

    private selectedConstructionSiteContractId(contract: OrganizationContractModel): ConstructionSitesPageParameters {
        const contractId: string | undefined = contract
            .children
            .filter(
                contract =>
                    !!contract.contractId)
            [0]
            ?.contractId;
        let isMaster: boolean = contract.children.length > 1;
        return {
            contractId: isMaster ? contract.contractId : contractId ?? "",
            isMasterContract: isMaster,
            selectedChildContractId: contractId,
        };
    }

    private renderContractIds(model: OrganizationContractModel, mainContractOnly?: boolean): React.ReactNode {
        const contracts: OrganizationContractModel[] = (mainContractOnly)
            ? (model.level === 1)
                ? [model]
                : []
            : model.children;

        const useSearchTerm = !!this.state.keyword && !mainContractOnly && !model.name?.toLowerCase().includes(this.state.keyword!.toLowerCase());
        const includesSearchTerm = (contractName: string | null) => {
            return useSearchTerm && contractName?.toLowerCase().includes(this.state.keyword!.toLowerCase());
        };

        return (
            <div key={model.contractId}
                 className={styles.contractLinks}
            >
                {
                    contracts
                        .map(
                            (contract, index) => {
                                const matchesSearchTerm = includesSearchTerm(contract.name);
                                const element = (
                                    <div key={contract.contractId}>
                                        <Link className={this.css(styles.linkCellStyle, matchesSearchTerm ? styles.underline : "")}
                                              route={PageDefinitions.contractDetails.route({
                                                  params: {
                                                      id: contract.contractId,
                                                  },
                                              })}
                                        >
                                            {
                                                (mainContractOnly)
                                                    ? contract.name
                                                    : contract.customerNumber
                                            }
                                        </Link>

                                        {
                                            (contracts.length > 0) && (index !== contracts.length - 1) &&
                                            (
                                                ", "
                                            )
                                        }
                                    </div>
                                );

                                return matchesSearchTerm ? <Tooltip key={contract.contractId} text={contract.name!}>{element}</Tooltip> : element;
                            }
                        )
                }
            </div>
        );
    }

    private async getCompaniesAsync(pageNumber: number,
                                    pageSize: number,
                                    sortColumnName: string | null,
                                    sortDirection: SortDirection | null,
    ): Promise<IPagedList<OrganizationContractModel>> {
        await this.changeUrl(null, null, null, pageNumber ,sortColumnName, sortDirection);

        const request: ListContractsPagedRequest = {
            level: OrganizationContractLevel.Company,
            sortColumnName: sortColumnName,
            sortDirection: sortDirection,
            searchTerm: this.state.keyword,
            pageSize: pageSize,
            pageNumber: pageNumber,
            showOnlyFavorites: this.state.showFavorites,
            showDeleted: this.state.showDeleted,
        };

        return await this.postAsync("/api/Companies/GetCompaniesPagedList", request);
    }

    private async changeUrl(
        search: string | null = null,
        showFavorites: boolean | null = null,
        showDeleted: boolean | null = null,
        page: number | null = null,
        sortColumnName: string | null = null,
        sortDirection: SortDirection | null = null,
    ): Promise<void> {
        const route: PageRoute = this.props.renderFor;
        const keyword = search ?? this.state.keyword;
        const pageNumber = page ?? this.state.pageNumber;
        const favorites = showFavorites ?? this.state.showFavorites;
        const deleted = showDeleted ?? this.state.showDeleted;
        const sortColumn = sortColumnName ?? this.state.sortColumnName;
        const sortDir = sortDirection ?? this.state.sortDirection;

        if (pageNumber === this.state.pageNumber && keyword === this.state.keyword && favorites === this.state.showFavorites && deleted === this.state.showDeleted
            && sortColumn === this.state.sortColumnName && sortDir === this.state.sortDirection) {
            return;
        }

        if (!keyword && pageNumber === 1 && !favorites && !deleted && sortDir === SortDirection.Asc && sortColumn === 'name') {
            route.parameters = null;
        } else {
            route.parameters = {
                ...(keyword ? {keyword} : {}),
                ...(pageNumber === 1 ? {} : {pageNumber}),
                ...(favorites ? {favorites} : {}),
                ...(deleted ? {deleted} : {}),
                ...(!sortColumn || sortColumn === 'name' ? {} : {sortColumn}),
                ...(sortDir === null || sortDir === SortDirection.Asc ? {} : {sortDir}),
            };
        }

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(route);
        this.setState({keyword, pageNumber, showFavorites: favorites, showDeleted: deleted, sortColumnName, sortDirection: sortDir});
    }

    private async filterCompaniesAsync(): Promise<void> {
        if (this._companiesGrid.current) {
            this._companiesGrid.current.model.pageNumber = 1;
            await this._companiesGrid.current!.reloadAsync();
        }
    }

    private async markFavoriteCompanyForUserAsync(cell: CellModel<OrganizationContractModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "favorite") {
            await this.markFavorite(cell.model.contractId, !cell.model.favorite);
        }

        cell.model.favorite = !cell.model.favorite;
    }

    private async markFavorite(contractId: string, favorite: boolean): Promise<void> {
        await this.postAsync(`/api/Companies/MarkUnmarkFavoriteCompaniesForUser?contractId=${contractId}&favorite=${favorite}`);
        await this._companiesGrid.current!.reloadAsync();
    }

    private renderStarIcon(cell: CellModel<OrganizationContractModel>, action: CellAction<OrganizationContractModel>): React.ReactNode {
        return <Icon name={cell.model.favorite ? "fas fa-star" : "far fa-star"}
                     key={`favorite_${cell.model.contractId}`}
                     className={styles.headerFavoriteIcon}
                     onClick={async () => await this.markFavoriteCompanyForUserAsync(cell, action)}
        />;
    }

    private async setShowOnlyFavoriteCompaniesFilterAsync(value: boolean): Promise<void> {
        await this.changeUrl(null, value);
        await this.filterCompaniesAsync();
    }

    private async setShowOnlyDeletedCompaniesFilterAsync(value: boolean): Promise<void> {
        await this.changeUrl(null, null, value);
        await this.filterCompaniesAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageRow className={styles.companiesGrid}>
                <Form inline
                      onSubmit={async () => await this.filterCompaniesAsync()}
                >
                    <TextInput id={"admin_companies_search"}
                               className={styles.searchBar}
                               label={Localizer.ordersPageSearch}
                               width={"200px"}
                               title={Localizer.companiesOverviewFindByNameOrCustomerNumber}
                               value={this.state.keyword!}
                               onChange={async (_, value) => await this.changeUrl(value, null)}
                    />

                    <Button submit
                            id={"admin_companies_search_submit"}
                            className={styles.searchButton}
                            label={Localizer.ordersPageSearch}
                            type={ButtonType.Orange}
                            icon={{name: "search"}}
                    />

                    <Checkbox inline
                              inlineType={InlineType.Right}
                              value={this.state.showFavorites}
                              label={Localizer.constructionSiteShowOnlyFavoriteConstructionSites}
                              onChange={async (_, value) => await this.setShowOnlyFavoriteCompaniesFilterAsync(value)}
                    />

                    {
                        UserRolesHelper.isAdmin() && (
                            <Checkbox inline
                                      id={"companies_show_deleted"}
                                      inlineType={InlineType.Right}
                                      value={this.state.showDeleted}
                                      label={Localizer.adminGridShowOnlyDeleted}
                                      onChange={async (_, value) => await this.setShowOnlyDeletedCompaniesFilterAsync(value)}
                            />
                        )
                    }
                </Form>

                <Grid responsive optimization version2Styles
                      id={"adminCompaniesGrid"}
                      pagination={this.defaultPageSize}
                      ref={this._companiesGrid}
                      columns={this._companiesColumns}
                      noDataText={Localizer.componentDropdownNoDataLanguageItemName}
                      defaultSortColumn={this.state.sortColumnName ?? "name"}
                      defaultSortDirection={this.state.sortDirection ?? SortDirection.Asc}
                      fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.getCompaniesAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                />
            </PageRow>
        );
    }
}