import {AttributeType} from "@/models/Enums";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export default class ProductAttributeModel {
    public id: string = RentaEasyConstants.emptyGuid;

    public key: string | null = null;

    public value: string | null = null;

    public type: AttributeType = AttributeType.Text;

    public documentId: number | null = null;
    
    public priority: number | null = null;
}