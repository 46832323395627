import React from "react";
import {CellModel, ColumnDefinition, ColumnType, Grid, GridModel, Modal} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import {ActionType} from "@renta-apps/athenaeum-react-common";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import UserContext from "@/models/server/UserContext";
import styles from "./PlanGrid.module.scss";
import PlanModel from "@/models/server/PlanModel";
import GetPlansRequest from "@/models/server/GetPlansRequest";
import PlansToolbarModel from "@/models/PlansToolbarModel";
import PlanPreview from "@/components/Plan/PlanPreview/PlanPreview";
import PlansToolbar from "@/components/Plan/PlanGrid/Toolbar/Toolbar";

interface IPlanGridProps {
    organizationContractId: string | null;
    userId: string | null;
}

interface IPlanGridState {
    filters: PlansToolbarModel;
    selectedPlan: PlanModel | null,
}

class PlanGrid extends BaseComponent<IPlanGridProps, IPlanGridState> {

    private readonly _planPreviewModal: React.RefObject<Modal> = React.createRef();
    private readonly _plansGrid: React.RefObject<Grid<PlanModel>> = React.createRef();

    public state: IPlanGridState = {
        selectedPlan: null,
        filters: new PlansToolbarModel()
    };

    public get PlansGrid(): GridModel<PlanModel> {
        return this._plansGrid.current!.model;
    }

    private readonly _plansColumns: ColumnDefinition[] = [
        {
            header: Localizer.servicesGridNameLanguageItemName,
            accessor: "name",
            editable: false,
            maxWidth: 100,
            init: (cell) => this.initPlanColumn(cell)
        },
        {
            header: Localizer.plansGridCompletedByLanguageItemName,
            accessor: "ordererEmail",
            editable: false,
            maxWidth: 100,
            init: (cell) => this.initPlanColumn(cell)
        },
        {
            header: Localizer.servicesGridSentAtLanguageItemName,
            accessor: "date",
            editable: false,
            type: ColumnType.Date,
            sorting: true,
            format: "D",
            maxWidth: 40,
            init: (cell) => this.initPlanColumn(cell),
        },
        {
            header: Localizer.servicesGridStatusLanguageItemName,
            accessor: "planStatus",
            sorting: true,
            type: ColumnType.Enum,
            format: "PlanStatus",
            maxWidth: 40,
            init: (cell) => this.initPlanColumn(cell),
            editable: false
        },
        {
            header: Localizer.servicesGridViewServiceLanguageItemName,
            minWidth: 25,
            type: ColumnType.Icon,
            className: styles.halfWidth,
            actions: [
                {
                    name: "preview",
                    icon: {name: "far search", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Blue,
                    callback: async (cell) => await this.openPlanPreviewAsync(cell)
                },
            ]
        },
        {
            header: Localizer.formDelete,
            minWidth: 25,
            type: ColumnType.Icon,
            className: styles.halfWidth,
            visible: this.userContext.isAdmin,
            actions: [
                {
                    name: "delete",
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    callback: async (cell) => await this.deletePlanPreviewAsync(cell)
                }
            ]
        }
    ];

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private initPlanColumn(cell: CellModel<PlanModel>): void {
        cell.readonly = true;
    }

    private toUtcSafeDate(date: Date): Date {
        const utcDate: Date = new Date();
        utcDate.setUTCFullYear(date.getFullYear());
        utcDate.setUTCMonth(date.getMonth());
        utcDate.setUTCDate(date.getDate());
        utcDate.setUTCHours(0, 0, 0, 0);
        return utcDate;
    }

    private async getPlansAsync(filters: PlansToolbarModel | null, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null)
        : Promise<IPagedList<PlanModel>> {

        let request: GetPlansRequest = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            organizationContractId: this.props.organizationContractId,
            constructionSiteId: null,
            planStatus: filters!.status,
            userId: this.props.userId,
            sortDirection: sortDirection,
            sortColumnName: sortColumnName,
            from: filters!.from !== null ? this.toUtcSafeDate(filters!.from!) : null,
            to: filters!.to !== null ? this.toUtcSafeDate(filters!.to!) : null,
            completedBy: filters!.completedBy ?? "",
            depotUserEmail: filters!.depotUserEmail ?? ""
        };

        return await this.postAsync("/api/Plans/GetPlans", request);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private async onFiltersChange(filters: PlansToolbarModel, clear: boolean): Promise<void> {
        if (clear) {
            await this.setState({filters});
        }
        else {
            await this.setState({filters});
        }

        await this.PlansGrid.reloadAsync();
    }

    private async deletePlanPreviewAsync(cell: CellModel<PlanModel>) {

        await this.postAsync("/api/Plans/DeletePlan", cell.model.id);
        await this._plansGrid.current!.rows.remove(cell.row);
        await this._plansGrid.current!.reRenderAsync();
    }

    private async openPlanPreviewAsync(cell: CellModel<PlanModel>) {
        await this.setState({selectedPlan: cell.model});
        await this._planPreviewModal.current!.openAsync();
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="row">
                    <PlansToolbar model={this.state.filters}
                                  onChange={async (model, clear) => await this.onFiltersChange(model, clear)}/>
                </div>

                <Grid id={"plans_grid"}
                      responsive
                      version2Styles
                      ref={this._plansGrid}
                      pagination={10}
                      columns={this._plansColumns}
                      noDataText={Localizer.servicesGridNoData}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.getPlansAsync(this.state.filters, pageNumber, pageSize, sortColumnName, sortDirection)}
                />

                {
                    this.state.selectedPlan && (
                        <Modal ref={this._planPreviewModal}
                               id={"planPreviewModal"}
                               title={` `} // Empty space by purpose. `` as title will render "..." (Default title in Modal component)
                               subtitle={``}
                        >
                            {
                                <PlanPreview model={this.state.selectedPlan!}
                                             editMode={false}
                                             previewMode={true}
                                />
                            }
                        </Modal>
                    )
                }
            </>
        );
    }
}

export default PlanGrid;