// @ts-ignore
import Localizer from "@/localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

context("(Desktop) Redirect after select role", () => {

    beforeEach(() => {
        cy.acceptCookies();
        cy.visitPage('/');
    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    it("Not logged user is taken into login screen -> Site details after login with access", () => {
        tests("Urho.Kekkonen@weare.fi", "wilska");
        pageData().common.waitTopNavRender();
        pageData().company.contractName().should('contain', 'TMI JUKKA WILSKA');

        cy.get('#ContractDetailsContainer')
            .should('exist')
            .find('li')
            .should('have.length.at.least', 6);

        cy.get('#sitesTab')
            .should('exist')
            .find('button')
            .should('have.length.at.least', 2);

        cy.get('#usersTab')
            .should('exist');

        cy.get('#ordersTab')
            .should('exist');

        cy.get('#returnsTab')
            .should('exist');

        cy.get('#servicesTab')
            .should('exist');

        cy.get('#emissionsTab')
            .should('exist');

        cy.get('#easyPlus')
            .should('exist');

    });

    it("Not logged user is taken into login screen -> Error after login with no access", () => {
        tests("cs.main@domain.fi", "unit test");

        cy.url().should('not.include', Localizer.pageRoutesConstructionSiteDetails);
    });


    function tests(username: string, company: string) {
        onRedirect(() =>
            onRedirect(() => {
                cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`);
            })
        );

        cy.url().should('include', 'redirectTo');

        pageData().common.waitTopNavRender();

        //Bit hacky :(
        cy.wait(2000);
        pageData().login.usernameInput().should("be.visible");
        pageData().login.passwordInput().should("be.visible");

        pageData().login.usernameInput().wait(0).focus().clear();


        pageData().login.usernameInput().type(username);
        pageData().login.passwordInput().type('Onet.xml1');

        onRedirect(() =>
            executeWithIntercept(() => pageData().login.loginButton().click(),
                [pageData().login.routes.login as RouteData]
            ));

        cy.wait(1000);

        onRedirect(() =>
            executeWithIntercept(() => cy.get('[data-cy=links-container]')
                    .contains(company, {matchCase: false})
                    .first()
                    .click(),
                [pageData().selectCompany.routes.selectCompany as RouteData])
        );
    }

});
