
export class ImageProcessorResponse {
    public isSuccessful: boolean;
    public src: string;

    private constructor(isSuccessful: boolean, src: string) {
        this.isSuccessful = isSuccessful;
        this.src = src;
    }

    public static ImageProcessorResponseSuccess(src: string): ImageProcessorResponse {
        return new ImageProcessorResponse(true, src);
    }

    public static ImageProcessorResponseFailure(): ImageProcessorResponse {
        return new ImageProcessorResponse(false, "");
    }
}

export class ImageProcessor {
    private readonly _maxWidth: number = 1920;
    private readonly _maxHeight: number = 1080;

    public shrinkImage = async (src: string): Promise<ImageProcessorResponse> => {
        return this.resizeImage(src, this._maxWidth, this._maxHeight);
    };

    private isResizeNeeded = (width: number, height: number): boolean => {
        return width >= this._maxWidth && height >= this._maxHeight;
    };

    private resizeImage = (src: string, maxWidth: number, maxHeight: number): Promise<ImageProcessorResponse> => {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = src;
            image.onload = () => {
                let width = image.width;
                let height = image.height;

                if (!this.isResizeNeeded(width, height)) {
                    resolve(ImageProcessorResponse.ImageProcessorResponseSuccess(src));
                }

                const canvas = document.createElement('canvas');
                // Calculate new dimensions based on max size
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                // Resize canvas and draw image on it
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(image, 0, 0, width, height);

                // Convert canvas to a new BASE64
                const url = canvas.toDataURL('image/jpg', 1.0);
                resolve(ImageProcessorResponse.ImageProcessorResponseSuccess(url));

            };
            image.onerror = () => {
                resolve(ImageProcessorResponse.ImageProcessorResponseFailure());
            };
        });
    };
}

export default new ImageProcessor();