import React from "react";
import styles from './ProductTechnicalInformationTable.module.scss';
import {TechnicalInfoRow} from "@/helpers/ProductInfoHelper";

interface ProductTechnicalInformationTableProps {
    className?: string;
    items: TechnicalInfoRow[];
}

const ProductTechnicalInformationTable = ({items, className}: ProductTechnicalInformationTableProps): JSX.Element => {
    const getItem = (item: TechnicalInfoRow): JSX.Element => {
        return (
            <td className="p-2 border-0 font-weight-bold">
                {
                    (item.isLink) ? (
                        <a href={item.value as string} target="_blank" rel="noreferrer">{item.key}</a>
                    ) : (item.value)
                }
            </td>
        );
    };

    return (
        <div className={className}>
            <table className="table table-striped">
                <tbody>
                {
                    items.map((item, index) => (
                        <tr key={item.key + index}>
                            <td className={["p-2 border-0", styles.titleCase].join(' ')}>{item.key}</td>
                            {
                                getItem(item)
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
};

export default ProductTechnicalInformationTable;