import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "@/localization/Localizer";
import {PageRoute} from "@renta-apps/athenaeum-react-common";

export default class BreadCrumbItem {

    public id: string = "";

    public pageParameter: string = "";

    public name: string = "";

    public page: string = "";
    
    public route: PageRoute | undefined = undefined;

    public constructor(page: string = "", pageName: string = "", route: PageRoute | undefined = undefined) {
        this.page = page;
        this.name = pageName ? pageName : page;
        this.route = route;
    }

    public get path(): string {
        return `/${this.page}/${this.pageParameter}`;
    }

    public static get favoriteCategory(): BreadCrumbItem {
        const breadCrumb = new BreadCrumbItem();
        breadCrumb.name = Localizer.genericFavorite;
        breadCrumb.pageParameter = RentaEasyConstants.favoritePageId;
        breadCrumb.id = RentaEasyConstants.favoritePageId;
        breadCrumb.page = Localizer.pageRoutesRent.toLowerCase();
        return breadCrumb;
    }

    public static get rentaFutureCategory(): BreadCrumbItem {
        const breadCrumb = new BreadCrumbItem();
        breadCrumb.name = Localizer.genericRentaFuture;
        breadCrumb.pageParameter = RentaEasyConstants.rentaFuturePageId;
        breadCrumb.id = RentaEasyConstants.rentaFuturePageId;
        breadCrumb.page = Localizer.pageRoutesRent.toLowerCase();
        return breadCrumb;
    }

    public static get favoriteCategoryPath(): string {
        return BreadCrumbItem.favoriteCategory.path;
    }
}