import React from "react";
import {ApiProvider, BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonContainer,
    ButtonType,
    Dropdown,
    DropdownRequiredType,
    EmailInput,
    Form,
    IStringInputModel,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem,
    TextAreaInput,
    TextInput,
} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import ContactSupportRequest from "@/models/server/ContactSupportRequest";
import UserModel from "@/models/server/UserModel";
import Localizer from "../../localization/Localizer";
import styles from "@/pages/ContactSupport/ContactSupport.module.scss";
import SupportRequestTypeModel from "@/models/server/SupportRequestTypeModel";
import DepotModel from "@/models/server/DepotModel";
import RentaEasyController from "@/pages/RentaEasyController";
import PageDefinitions from "@/providers/PageDefinitions";
import ButtonWithSpinner from "@/components/ButtonWithSpinner/ButtonWithSpinner";

export interface IContactSupportParams extends BasePageParameters {
    requestId?: string;
}

interface IContactSupportState {
    contactSupportRequest: ContactSupportRequest | null,
    email: string | null,
    phoneNumber: string | null,
    firstName: string | null,
    lastName: string | null,
    requestTypes: SelectListItem[],
    rentalOffices: DepotModel[],
    sendFailed: boolean;
    sendSucceeded: boolean;
    sending: boolean;
}

export default class ContactSupport extends AnonymousPage<IContactSupportParams, IContactSupportState> {

    // Fields

    public state: IContactSupportState = {
        contactSupportRequest: null,
        email: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        requestTypes: [],
        rentalOffices: [],
        sendFailed: false,
        sendSucceeded: false,
        sending: false,
    };

    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();

    // Properties

    private get requestId(): string | undefined {
        return this.props.parameters?.requestId;
    }

    private get contactSupportRequest(): ContactSupportRequest | null {
        return this.state.contactSupportRequest;
    }

    // Methods

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.getSupportRequestTypesAsync();

        if (!this.isLoggedIn) {
            await this.setDepotListAsync();
        }

        const user: UserModel | null = this.userContext.user;

        if (user) {
            this.setState({
                email: user.email,
                phoneNumber: user.phoneNumber,
                firstName: user.firstName,
                lastName: user.lastName,
            });
        }
    }

    private setContactSupportRequestAsync(contactSupportRequest: ContactSupportRequest): void {
        this.setState({contactSupportRequest});
    }

    private async setDepotListAsync(): Promise<void> {
        const rentalOffices: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        this.setState({
            rentalOffices
        });
    }

    private async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        this.setState({sendFailed: false, sendSucceeded: false, sending: true});
        const mappedRequestObject = {} as any;
        data.keys().map(key => {
            return mappedRequestObject[key] = data.getValue(key);
        });

        this.setContactSupportRequestAsync({...mappedRequestObject, requestId: this.requestId});

        const request = this.contactSupportRequest;

        try {
            await ApiProvider.postAsync(`/api/Users/ContactSupport`, request);
            this.setState({sendSucceeded: true, sending: false});
        } catch (e) {
            this.setState({sendFailed: true, sending: false});
        }
    }

    protected get title(): string {
        return Localizer.contactSupportPageTitle;
    }

    private async getSupportRequestTypesAsync(): Promise<void> {
        const data: SupportRequestTypeModel[] = await this.getAsync("/api/Application/GetAllSupportRequestTypes");

        const requestTypes = data
            .map(type => {
                const listItem: SelectListItem = new SelectListItem();
                listItem.text = type.requestTypeTitle!.toLowerCase();
                listItem.value = type.supportRequestTypeId!;
                return listItem;
            });

        this.setState({requestTypes: requestTypes});
    }

    public get isLoggedIn(): boolean {
        return this.userContext.user != null;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer id={styles.contactSupport} 
                           className={this.css(styles.contactSupport)}
                           fullHeight={this.state.sendSucceeded}
            >
                {this.state.sendSucceeded ? (
                    <PageRow className={styles.sendSucceeded}>
                        <div className={styles.sendSucceededContainer}>
                            <div className={styles.sendSucceededMessage}>{Localizer.contactSupportSendSucceededLine1}</div>
                            <div className={styles.sendSucceededMessage}>{Localizer.contactSupportSendSucceededLine2}</div>
                            <Button type={ButtonType.Orange}
                                    label={Localizer.contactSupportBackToFrontpage}
                                    onClick={async () => {
                                        await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
                                    }}
                            />
                        </div>
                    </PageRow>
                ) : (
                    <>
                        <PageHeader title={this.title}/>
                        <PageRow>
                            <Form id="form"
                                  ref={this.formRef}
                                  onSubmit={async (_, data) => await this.handleSubmitAsync(data)}
                            >
                                <Dropdown required
                                          id="supportRequestTypeId"
                                          requiredType={DropdownRequiredType.Manual}
                                          className={styles.dropdown}
                                          label={Localizer.supportRequestTypeRequestType}
                                          items={this.state.requestTypes}
                                          disabled={this.state.sending}
                                />
        
                                {
                                    (!this.isLoggedIn) &&
                                    <Dropdown required
                                              id="depotId"
                                              requiredType={DropdownRequiredType.Manual}
                                              className={styles.dropdown}
                                              label={Localizer.supportRequestTypeSelectDepot}
                                              items={this.state.rentalOffices}
                                              disabled={this.state.sending}
                                    />
                                }
        
                                <EmailInput required
                                            id="email"
                                            label={Localizer.genericEmail}
                                            value={this.state.email ?? ""}
                                            readonly={!!this.state.email || this.state.sending}
                                />
        
                                <TextInput id="phone"
                                           label={Localizer.genericPhoneNumber}
                                           value={this.state.phoneNumber ?? ""}
                                           readonly={this.state.sending}
                                />
        
                                <TextInput id="firstname"
                                           label={Localizer.genericFirstName}
                                           value={this.state.firstName ?? ""}
                                           readonly={this.state.sending}
                                />
        
                                <TextInput id="lastname"
                                           label={Localizer.genericLastName}
                                           value={this.state.lastName ?? ""}
                                           readonly={this.state.sending}
                                />
        
                                <TextAreaInput required
                                               id="message"
                                               label={Localizer.genericMessage}
                                               model={this.message} rows={6}
                                               readonly={this.state.sending}
                                />
        
                                <ButtonContainer>
                                    <div className={styles.buttonContainer}>
                                        {this.state.sending && <div>{Localizer.contactSupportSending}</div>}
                                        {this.state.sendFailed && <div>{Localizer.contactSupportSendingFailed}</div>}
                                        <ButtonWithSpinner submit
                                                           type={ButtonType.Orange}
                                                           label={Localizer.formSend}
                                                           spinning={this.state.sending}
                                        />
                                    </div>
                                </ButtonContainer>
                            </Form>
                        </PageRow>
                    </>
                )}
            </PageContainer>
        );
    }
}