import React from 'react';
import { PageContainer, PageHeader, PageRow, Link } from "@renta-apps/athenaeum-react-components";
import styles from './NoAccess.module.scss';
import Localizer from "@/localization/Localizer";
import PageDefinitions from '../../providers/PageDefinitions';

interface INoAccessProps {
    type: 'company' | 'constructionSite' | 'deviceTracking';
}

const NoAccess: React.FC<INoAccessProps> = ({ type }) => {
    let title = '';
    let description = '';
    let whatNext = '';
    let backgroundImage = '';
    let hasWideHeader = true;

    switch (type) {
        case 'company':
            title = Localizer.companyDetailsCannotBeAccessedTitle;
            description = Localizer.companyDetailsCannotBeAccessedDescription;
            whatNext = Localizer.companyDetailsCannotBeAccessedWhatNext;
            backgroundImage = "/images/renta-kuva-5-scaled.jpg";
            break;
        case 'constructionSite':
            title = Localizer.constructionSiteDetailsCannotBeAccessedTitle;
            description = Localizer.constructionSiteDetailsCannotBeAccessedDescription;
            whatNext = Localizer.constructionSiteDetailsCannotBeAccessedWhatNext;
            backgroundImage = "/images/renta-kuva-6-scaled.jpg";
            break;
        case 'deviceTracking':
            title = Localizer.deviceTrackingCannotBeAccessedTitle;
            description = Localizer.deviceTrackingCannotBeAccessedDescription;
            whatNext = Localizer.deviceTrackingCannotBeAccessedWhatNext;
            hasWideHeader = false;
            break;
        default:
            break;
    }

    const contactPageRoute = () => {
        return PageDefinitions.contactSupport.route();
    }

    return (
        <PageContainer hasWideHeader={hasWideHeader}>
            <PageHeader
                className={styles.pageHeader}
                title={title}
                wideHeader={hasWideHeader}
                wideHeaderBackgroundImage={backgroundImage}
            />
            <PageRow>{description}</PageRow>
            <PageRow>{whatNext}</PageRow>
            <PageRow>
                <ul>
                    <li>{Localizer.genericTryLoggingInAgain}</li>
                    <li>
                        <Link route={contactPageRoute()}>{Localizer.genericContactSupport}</Link>
                    </li>
                </ul>
            </PageRow>
        </PageContainer>
    );
};

export default NoAccess;