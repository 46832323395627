import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import PriceHelper, {ILocalizedPriceData} from "@/helpers/PriceHelper";
import ProductModel from "@/models/server/ProductModel";
import ProductOrganizationContractPriceModel from "@/models/server/ProductOrganizationContractPriceModel";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import Localizer from "@/localization/Localizer";

import styles from "./Prices.module.scss";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";
import { MinimumRentalDays } from "../../models/Enums";

interface IPriceProps {

    /**
     * Product which prices to calculate or display if it already has discounts attached.
     */
    product: ProductModel;

    /**
     * Currently applicable VAT.
     */
    vat: number;

    className?: string;

    /**
     * Discount of the Product.
     */
    contractDiscount?: ProductOrganizationContractPriceModel;

    /**
     * Should a text be displayed if the prices are discounted.
     */
    displayDiscountText?: boolean;

    /**
     * Should an {@link InlineTooltip} be displayed along with the possible weekly price.
     */
    displayWeeklyPriceTooltip?: boolean;

    /**
     * Prices and their units in the same line.
     */
    inlinePriceRows?: boolean;

    /**
     * Maximum amount of price-rows to display. Does not include the possible discount text.
     * @default {@link Number.MAX_SAFE_INTEGER}
     */
    maxRows?: number;

}

interface IPriceState {
}

export default class Prices extends BaseComponent<IPriceProps, IPriceState> {

    public state: IPriceState = {};

    private authenticatedUsersOnly = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceForAuthenticatedUsersOnly);
    private priceIsInformative = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceIsInformative);
    private showNetPriceOnly: boolean = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceNetOnly);

    private get maxRows(): number {
        if (this.priceIsInformative) {
            return 1;
        }
        return this.props.maxRows ?? Number.MAX_SAFE_INTEGER;
    }

    private get product(): ProductModel {
        return this.props.product;
    }

    private get vat(): number {
        return this.showNetPriceOnly ? 0 : this.props.vat;
    }

    private get localizedPrices(): ILocalizedPriceData[] {
        return PriceHelper.toLocalizedPrices(
            this.product,
            this.vat,
            this.props.contractDiscount
        );
    }

    public renderMinimumRentalDays(): React.ReactNode {
        return (
            this.product.minimumRentalDays != null && this.product.minimumRentalDays !== MinimumRentalDays.OneDay
                ? (
                    <div data-cy={"minimum_rental_days"} className={styles.minimumRentalDays}>
                        {Localizer.get(Localizer.productMinimumRentalDays, this.product.minimumRentalDays)}
                    </div>
                )
                : null
        );
    }


    public render(): React.ReactNode {
        const hasDiscounted = this.localizedPrices.some(price => price.isDiscounted);
        const prices: ILocalizedPriceData[] = this.localizedPrices
            .filter(price => hasDiscounted ? price.isDiscounted : true)
            .slice(0, this.maxRows);

        const inlinePriceRows = String(!!this.props.inlinePriceRows);

        return (
            <div className={this.css(this.props.className, styles.prices)}
                 data-inline-rows={inlinePriceRows}
                 id={"prices-container"}
            >
                {(hasDiscounted) && (this.props.displayDiscountText) && (
                    <div className={this.css(styles.discountTextRow)}>
                        <span className={styles.discountText}>
                            {Localizer.contractPrices}
                        </span>
                    </div>
                )}

                {(!this.isAuthenticated && this.authenticatedUsersOnly) && (
                    <div className={styles.priceForAuthenticatedUsersOnly}>
                        {Localizer.productDetailsPriceForAuthenticatedUsersOnly}
                    </div>
                )}
                
                {(this.isAuthenticated || !this.authenticatedUsersOnly) && (
                    <>
                        <div className={styles.priceRowsWrapper}>
                            {prices.map((price, index) =>
                                <div key={index + price.value}
                                     className={this.css(styles.priceRow)}
                                >
                                    {this.props.product.discountPercentage ? (
                                        <>
                                            <div data-cy={"original_price_value"}
                                                 className={this.css(styles.priceBeforeDiscountValue)}
                                                 data-length={String(prices.length)}
                                                 data-inline-rows={inlinePriceRows}
                                            >
                                                {price.value}
                                            </div>
                                            <div data-cy={"price_value"}
                                                 className={this.css(styles.priceValue)}
                                                 data-length={String(prices.length)}
                                                 data-inline-rows={inlinePriceRows}
                                            >
                                                {(Number(price.valueNoUnit) * (1.0 - this.props.product.discountPercentage / 100.0)).toFixed(2)}
                                            </div>
                                        </>
                                    ) : (
                                        <div data-cy={"price_value"}
                                             className={this.css(styles.priceValue)}
                                             data-length={String(prices.length)}
                                             data-inline-rows={inlinePriceRows}
                                        >
                                            {price.value}
                                        </div>
                                    )}

                                    {(this.props.inlinePriceRows && !price.hideUnit) && (
                                        <div className={styles.slashSeparator}>/</div>
                                    )}

                                    {!price.hideUnit && (
                                        <div className={styles.priceUnit}
                                             style={{opacity: (price.hideUnit) ? 0 : 1}}>
                                            <span>{price.unit}</span>

                                            <span className={styles.priceTooltip}>
                                             {(price.unit === PriceHelper.units.weekly(this.product.rentBasis, this.vat)) && (this.props.displayWeeklyPriceTooltip) && (
                                                 <InlineTooltip 
                                                     text={(this.product.rentBasis === 5)
                                                         ? Localizer.product5DaysPriceDescription
                                                         : Localizer.product7DaysPriceDescription
                                                     }
                                                 />
                                             )}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {
                                this.renderMinimumRentalDays()
                            }
                        </div>
                    </>
                )}
            </div>
        );
    }
}