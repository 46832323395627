import React from "react";
import {CarouselNavigation} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import ProductCarousel from "@/components/ProductCarousel/ProductCarousel";
import ProductCard, {ProductCardStyle} from "@/components/ProductCard/ProductCard";
import styles from './ProductAccessories.module.scss';

export interface IProductAccessoriesProps {
    readonly: boolean;
    onUpdateProductCount: (productId: string, newCount: number, productName: string | null) => Promise<void>;
    relatedProducts: ShoppingCartProductModel[];
    vat: number;
    navigation?: CarouselNavigation;
}

interface IProductAccessoriesState {

}

export default class ProductAccessories extends BaseComponent<IProductAccessoriesProps, IProductAccessoriesState> {

    // Inherited
    public state: IProductAccessoriesState = {};

    // Renders

    public render(): React.ReactNode {
        if (this.mobile) {
            return (
                <div className="d-flex flex-column gap-2">
                    {
                        this.props.relatedProducts.map((product, index) =>
                            <ProductCard displayAddToCartButton displayDiscountText displayOnlyAddToCartButtonIcon
                                         key={index + product.product!.id}
                                         count={product.count}
                                         maxPriceRows={1}
                                         product={product.product!}
                                         readonly={this.props.readonly}
                                         style={ProductCardStyle.SingleRow}
                                         vat={this.props.vat}
                                         onUpdateProductCount={(productId, newCount, productName) => this.props.onUpdateProductCount(productId, newCount, productName)}
                            />
                        )
                    }
                </div>
            );
        }
        return (
            <ProductCarousel products={this.props.relatedProducts}
                             navigation={CarouselNavigation.Outside}
                             vat={this.props.vat}
                             readonly={this.props.readonly}
                             onUpdateProductCount={this.props.onUpdateProductCount}
                             classNames={{
                                 carousel: styles.carousel
                             }}
            />
        );
    }

}