import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import FileApiModel from "@/models/server/FileApiModel";

export default class DepotModel {

    public id: string = RentaEasyConstants.emptyGuid;

    public  depotGroupId: string | null = null;

    public  depotGroupName: string | null = null;

    public externalId: number = 0;

    public additionalExternalIds: string | null = null;

    public name: string | null = null;

    public newImage: FileApiModel | null = null;

    public get normalizedName(): string {
        return this.name!
            .replace(" ", "")
            .replace("ä", "a")
            .replace("ö", "o")
            .replace("ø", "o")
            .replace("å", "a")
            .replace("æ", "a");
    }

    public tags: string[] = [];

    public address: string | null = null;

    public coordLat: number = 0;

    public coordLng: number = 0;

    public distance: number | null = null;

    public phonenumber: string | null = null;

    public email: string | null = null;

    public openingHours: string | null = null;

    public managerName: string | null = null;

    public managerEmail: string | null = null;

    public managerTelephone: string | null = null;

    public orderEmails: string | null = null;

    public companyAccessRequestForwardingEmails: string | null = null;

    public rentalOffice: boolean = false;

    public reviewLink: string | null = null;

    public navigationLink: string | null = null;

    public version: string | null = null;

    public readonly imageReference: string = "";

    public isInternal: boolean = false;

    public isDepotModel: boolean = true;
}