import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Mobile - Anonymous catalog tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);

        cy.viewport("iphone-6");

    });

    it('Open catalog page and navigate from browser back', () => {

        pageData().rent.visitWithIntercept()

        pageData().rent.firstCategoryItem()
            .then(($btn: any) => {
                cy.wrap($btn)
                    .invoke('text')
                    .then((parentCategoryText: string) => {

                        executeWithIntercept(() => $btn.click(),
                            [pageData().rent.routes.categoriesData as RouteData]);

                        cy.get('#categories-list').should('not.contain', $btn.text());
                        cy.go('back');
                        cy.get('#categories-list').should('contain', $btn.text());
                    });
            });

    });

    it('Open catalog page and navigate from toolbar', () => {
        pageData().rent.visitWithIntercept();

        pageData().rent.firstCategoryItem()
            .then(($btn: any) => {
                cy.wrap($btn)
                    .invoke('text')
                    .then((parentCategoryText: string) => {
                        cy.intercept('POST', '/api/Catalog/CategoriesData').as('categoriesData5');
                        $btn.click();
                        cy.wait("@categoriesData5").its('response.statusCode')
                            .should('equal', 200);
                        cy.get('#categories-list').should('not.contain', $btn.text());
                        cy.get('.fa-chevron-circle-left').click();
                        cy.get('#categories-list').should('contain', $btn.text());
                    });
            });

    });

    it('Open product page directly (intercept)', () => {

        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.intercept(
            {
                method: 'POST',
                path: '/api/ShoppingCart/SaveProductToCart',
            },
            {fixture: 'addToCartResponse.json'}
        );

        cy.intercept(
            {
                method: 'POST',
                path: '/api/Product/ProductDepotAvailability',
            },
            {
                statusCode: 200,
                body: true,
            }
        );

        cy.intercept('POST', '/api/Product/GetRelatedProducts').as("relatedProducts");

        cy.assertBreadCrumbLength(4);
        cy.get('#addToCart').click();
        cy.get('#productConfirmationModal', {timeout: 10000}).should('be.visible');
        cy.wait("@relatedProducts").its('response.statusCode')
            .should('equal', 200);

        cy.get('#productConfirmationModal').find('[data-dismiss]').first().trigger('click');

        cy.get('#productConfirmationModal', {timeout: 10000}).should('not.be.visible');

        //Assert vat 0 prices
        const productId: string = '83a700be-2e3b-45cc-ac8a-570a551760d4';
        //Assert vat 0 prices
        pageData().rent.product.dailyPrice(productId)
            .should('contain.text', Cypress.env("product2_daily_price_vat_0"));
        pageData().rent.product.monthlyPrice(productId)
            .should('contain.text', Cypress.env("product2_monthly_price_vat_0"));

        pageData().rent.product.vat24Link(productId).click({force: true});

        //Assert vat 24 prices
        pageData().rent.product.dailyPrice(productId).should('contain.text', Cypress.env("product2_daily_price_vat_24"));
        pageData().rent.product.monthlyPrice(productId).should('contain.text', Cypress.env("product2_monthly_price_vat_24"));


        pageData().rent.product.vat0Link(productId).click({force: true});


        //Assert vat 0 prices
        pageData().rent.product.dailyPrice(productId)
            .should('contain.text', Cypress.env("product2_daily_price_vat_0"));
        pageData().rent.product.monthlyPrice(productId)
            .should('contain.text', Cypress.env("product2_monthly_price_vat_0"));

        for (let n = 0; n < 5; n++) {
            pageData().rent.product.plusButton(productId).click();
        }
        cy.get('#addToCart').click();

    });
});