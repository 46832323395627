/**
 * Formats a date object into a localized date or date-time string.
 *
 * @param date - The Date object to format. If null or undefined, the function returns null.
 * @param dateOnly - A boolean flag indicating if only the date should be returned. 
 *                   If true, returns only the date part; if false, returns both date and time.
 *                   Default is true.
 * @returns A formatted date string if `date` is valid; otherwise, returns null.
 */
export const formatDate = (date: Date | null | undefined, dateOnly: boolean = true): string | null => {
    if (date === null || date === undefined) {
        return null;
    }

    return dateOnly ? date.toLocaleDateString() : date.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
};

/**
 * Formats a date object into a localized time string.
 *
 * @param date - The Date object to format. If null or undefined, the function returns null.
 * @returns A formatted time string if `date` is valid; otherwise, returns null.
 */
export const formatTime = (date: Date | null | undefined) => {
    if (date === null || date === undefined) {
        return null;
    }

    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

/**
 * Converts a JavaScript Date object to a localized string format that includes the date and time.
 *
 * The date part is formatted using the default locale, and the time is formatted to show only the hour and minute (no seconds).
 *
 * Example output: "mm.DD.yyyy HH:MM" (depending on the locale)
 *
 * @param {Date} datetime - The Date object to be formatted.
 * @returns {string} A string representing the localized date and time.
 */
export const getDateTimeInLocaleFormat = (datetime: Date): string => {
    const date = datetime.toLocaleDateString();
    const time = datetime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}); // Get the time part without seconds

    return `${date} ${time}`;
}

/**
 * Converts a JavaScript Date object to the local timezone by adjusting for the user's timezone offset.
 *
 * This function takes a date object and calculates the difference between UTC and the local timezone,
 * returning a new Date object that reflects the local date and time.
 *
 * Useful when working with dates in UTC or other timezones that need to be displayed in the local timezone.
 *
 * @param {Date} date - The Date object to be converted to local time.
 * @returns {Date} A new Date object adjusted to the local timezone.
 */
export const toLocalDate = (date: Date) => {
    const timeDiff = date.getTimezoneOffset() * 60000;
    return new Date(date.valueOf() + timeDiff);
};