import React from "react";
import ConstructionSitesList from "@/components/ConstructionSitesList/ConstructionSitesList";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import ConstructionSitesPageParameters from "@/models/ConstructionSitesPageParameters";
import Localizer from "@/localization/Localizer";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";

export interface IConstructionSitesParams extends BasePageParameters {
}

interface IConstructionSitesState {
}

export default class ConstructionSites extends AuthorizedPage<IConstructionSitesParams, IConstructionSitesState> {

    public state: IConstructionSitesState = {};

    private getConstructionSiteSelection(): ConstructionSitesPageParameters {
        return (this.parameters as ConstructionSitesPageParameters);
    }

    private getContractId(): string {
        return this.userContext.selectedContractId ?? "";
    }

    private isMasterContract(): boolean {
        if (this.parameters) {
            let selection: ConstructionSitesPageParameters = (this.parameters as ConstructionSitesPageParameters);
            return selection.isMasterContract;
        }

        return false;
    }

    // Async-methods
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get title(): string {
        return Localizer.constructionsites;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title}/>

                <ConstructionSitesList contractId={this.getContractId()}
                                       constructionSiteSelection={this.getConstructionSiteSelection()}
                                       masterContract={this.isMasterContract()}
                />
            </PageContainer>
        );
    }
}