import React from "react";
import {ServiceRequestStatus, ServiceType} from "@/models/Enums";
import ServiceRequestDefinitionInput from "@/models/server/ServiceRequestDefinitionInput";
import CustomServiceRequestModel from "@/models/server/CustomServiceRequestModel";
import ServiceCatalog from "@/components/Service/ServiceCatalog/ServiceCatalog";
import ServicesEditor from "@/components/Service/ServiceEditor/ServiceEditor";
import {ITabContainerClassNames, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType} from "@renta-apps/athenaeum-react-components";
import ServiceGrid from "@/components/Service/ServiceGrid/ServiceGrid";
import queryString, {ParsedQuery} from "query-string";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import { BasePageParameters, PageRouteProvider } from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import DeletedServices from "@/pages/Services/DeletedServices/DeletedServices";

import Localizer from "@/localization/Localizer";
import styles from "./Services.module.scss";

export interface IServicesParams extends BasePageParameters {
    /**
     * Id of the currently selected Service.
     */
    serviceRequestId?: string;
}

interface IServicesState {

    /**
     * Definition of the Service under inspection/editing.
     */
    selectedServiceRequest: CustomServiceRequestModel | null;

    selectedServiceRequestIsDeleted: boolean;

    /**
     * All existing non-deleted Service Request definitions.
     */
    existingDefinitions: CustomServiceRequestModel[];

    editMode: boolean;

    /**
     * Input under editing (displayed in right panel).
     */
    currentInput: ServiceRequestDefinitionInput | null;
}

export default class Services extends AuthorizedPage<IServicesParams, IServicesState> {

    public state: IServicesState = {
        selectedServiceRequest: null,
        selectedServiceRequestIsDeleted: false,
        currentInput: null,
        editMode: false,
        existingDefinitions: []
    };

    private readonly _serviceCatalogRef: React.RefObject<Tab> = React.createRef();

    // Boolean getters

    private get organizationContractId(): string | null {
        const parsed: ParsedQuery = queryString.parse(window.location.search);
        return (typeof parsed?.id === "string")
            ? parsed.id as string
            : null;
    };

    // Getters

    private get selectedServiceRequest(): CustomServiceRequestModel {
        return this.state.selectedServiceRequest!;
    }

    private get currentInput(): ServiceRequestDefinitionInput | null {
        return this.state.currentInput;
    }

    private get editMode(): boolean {
        return this.state.editMode;
    }

    public get hasSelectedServiceRequest(): boolean {
        return (!!this.state.selectedServiceRequest);
    }

    protected get title(): string {
        return Localizer.breadCrumbServices;
    }

    // Async-methods

    private async closeEditorAsync(): Promise<void> {
        await this.setState({
            selectedServiceRequest: null,
            editMode: false,
            currentInput: null
        });

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(PageDefinitions.services.route({
            params: null,
        }));

        await this.fetchDataAsync();
    }

    private async openEditorAsync(selectedServiceRequest: CustomServiceRequestModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedServiceRequest,
            selectedServiceRequestIsDeleted: isDeleted,
            editMode: true
        });
    }

    private async openDefinitionAsync(selectedServiceRequest: CustomServiceRequestModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedServiceRequest,
            selectedServiceRequestIsDeleted: isDeleted,
            editMode: false
        });

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(PageDefinitions.services.route({
            params: {
                serviceRequestId: selectedServiceRequest.id,
            }
        }));
    }

    private async createNewServiceDefinitionAsync(): Promise<void> {
        const newServiceDefinition: CustomServiceRequestModel = {
            id: "",
            status: ServiceRequestStatus.Received,
            orderNumber: 0,
            date: new Date(),
            ordererEmail: "",
            receivers: "",
            sendToUsersFavoriteDepot: false,
            name: "",
            serviceType: ServiceType.Custom,
            inputGroups: [],
            icon: "",
            isCustomServiceRequestModel: true,
            isVisible: false,
        };

        await this.setState({
            selectedServiceRequest: newServiceDefinition,
            editMode: true
        });
    }

    public async reloadServicesTabAsync(): Promise<void> {
        if (this._serviceCatalogRef.current) {
            await this.fetchDataAsync();
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.fetchDataAsync();
    }

    public async fetchDataAsync(): Promise<void> {

        let existingDefinitions: CustomServiceRequestModel[] = await this.postAsync(`/api/Services/GetServicesDefinitions`, null);

        const serviceDefinitionId = this.typedParameters?.serviceRequestId;

        if (serviceDefinitionId !== undefined) {
            const serviceDefinition = existingDefinitions.find(existingDefinition => existingDefinition.id === serviceDefinitionId);
            if (serviceDefinition !== undefined) {
                await this.openDefinitionAsync(serviceDefinition, false);
            }
        }

        // Non-activated service definitions are only visible to the admin.
        if (!this.isAdminWithAdminRole) {
            existingDefinitions = existingDefinitions.filter(x => x.isVisible);
        }

        await this.setState({
            existingDefinitions
        });
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: styles.navTabs,
        };
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer id={styles.services}
                           className={styles.services}
            >
                <PageHeader title={this.title}/>

                <TabContainer id="constructionSiteDetailsContainer"
                              renderType={TabRenderType.ActiveOnly}
                              headerStyleType={TabContainerHeaderStyleType.Underline}
                              className={styles.services}
                              classNames={this.tabContainerClassNames}
                >

                    <Tab id="serviceCatalog"
                         title={Localizer.servicesTabServicesList}
                         ref={this._serviceCatalogRef}
                         className={styles.navTabs}
                    >
                        <>

                            {
                                (!this.hasSelectedServiceRequest) &&
                                (
                                    <>
                                        <ServiceCatalog canEdit={this.isAdminWithAdminRole}
                                                        canCreateNew={this.isAdminWithAdminRole}
                                                        createNewButtonLabel={Localizer.serviceDefinitionsCreateNew}
                                                        customServiceModels={this.state.existingDefinitions}
                                                        openDefinitionAsync={async (model) => await this.openDefinitionAsync(model, false)}
                                                        openEditorAsync={async (model) => await this.openEditorAsync(model, false)}
                                                        createNewServiceDefinition={async () => await this.createNewServiceDefinitionAsync()}
                                        />
                                    </>
                                )
                            }

                            {
                                (this.hasSelectedServiceRequest) &&
                                (
                                    <ServicesEditor closeEditorAsync={async () => await this.closeEditorAsync()}
                                                    model={this.selectedServiceRequest!}
                                                    currentInput={this.currentInput}
                                                    editMode={this.editMode}
                                                    deleted={this.state.selectedServiceRequestIsDeleted}
                                    />
                                )
                            }
                        </>
                    </Tab>

                    {
                        (this.isAdmin) &&
                        (
                            <Tab id="sentRequests"
                                 title={Localizer.servicesTabServicesGrid}
                                 className={styles.navTabs}
                            >
                                <PageRow>
                                    <ServiceGrid organizationContractId={this.organizationContractId}
                                                 userId={null}
                                    />
                                </PageRow>
                            </Tab>
                        )
                    }

                    {
                        (this.isAdmin) &&
                        (
                            <Tab id="deletedRequests"
                                 title={Localizer.servicesTabDeletedServices}
                                 className={styles.navTabs}
                            >
                                <DeletedServices isAdmin={this.isAdmin}
                                                 reloadServices={async () => await this.reloadServicesTabAsync()}
                                />
                            </Tab>
                        )
                    }

                </TabContainer>
            </PageContainer>
        );
    }
}