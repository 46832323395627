import React from "react";
import {
    GlobalSearchContext,
    GlobalSearchForm,
    GlobalSearchOption,
    GlobalSearchResults,
    PageContainer,
    PageHeader,
    PageRow,
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import styles from "./GlobalSearch.module.scss";
import Localizer from "@/localization/Localizer";
import {GlobalSearchUtils} from "@/providers/GlobalSearchUtils";
import AnonymousPage from "@/models/base/AnonymousPage";

export interface IGlobalSearchParams extends BasePageParameters {
}

interface IGlobalSearchState {
    options?: GlobalSearchOption[];
}

export default class GlobalSearch extends AnonymousPage<IGlobalSearchParams, IGlobalSearchState> {

    public state: IGlobalSearchState = {options: []};

    static contextType = GlobalSearchContext;
    context!: React.ContextType<typeof GlobalSearchContext>;

    protected get title(): string {
        return Localizer.searchTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.fetchSearchOptionsAsync();
    }

    private async fetchSearchOptionsAsync(): Promise<void> {
        const searchProvidersResults = await GlobalSearchUtils.fetchSearchProvidersAsync();
        this.setState({options: searchProvidersResults.searchProviders});
        await this.reRenderAsync();
    }

    private async fetchSearchResultsAsync(searchTerm: string, searchProviders: string[]): Promise<void> {
        const resetContext = ({searchTerm: searchTerm, searchProviders: searchProviders, isLoading: true, results: {}, resultsLimit: 0});
        this.context.setContext(resetContext);
        const results = await GlobalSearchUtils.fetchSearchResultsAsync(searchTerm, searchProviders);
        const newContext = ({...results, searchTerm: searchTerm, searchProviders: searchProviders, isLoading: false});
        this.context.setContext(newContext);
    }

    public async onClearClicked(): Promise<void> {
        const newContext = {searchTerm: "", searchProviders: [], resultsLimit: 0, results: {}, isLoading: false};
        this.context?.setContext(newContext);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.globalSearch}>
                <PageHeader title={Localizer.searchTitle} className={styles.header} />
                <PageRow className={styles.globalSearchContent}>
                    <div className={styles.searchFormContainer}>
                        <GlobalSearchForm
                            searchProviders={this.context.searchOptions || []}
                            searchTerm={this.context.searchTerm}
                            selectedProviders={this.context.searchProviders ?? []}
                            onSearch={(searchTerm, providers) => this.fetchSearchResultsAsync(searchTerm, providers)}
                            onClear={() => this.onClearClicked()}
                        />
                    </div>
                    <div className={styles.searchResultsContainer}>
                        {this.state.options && <GlobalSearchResults
                            response={this.context}
                            searchProviders={this.context.searchOptions}
                            compact
                            onClicks={async (type, item) => await GlobalSearchUtils.handleSearchItemClick(type, item)}
                        />}
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}