export default class InviteUserPostRequest {

    public contractId: string = "";

    public usersList: InvitedUser[] = [];

    public accessToAllConstructionSites: boolean = false;

    public companyMainUserRole: boolean = false;

    public constructionSiteAccesses: IConstructionSiteAccess[] = [];
}

export class InvitedUser {
    public firstName: string = "";

    public lastName: string = "";

    public telephone: string = "";

    public email: string = "";
}

export interface IConstructionSiteAccess {
    access: boolean;
    directRentAccess: boolean;
    fullAccess: boolean;
    id: string;
    name: string;
    pricesVisible: boolean;
    isOpen: boolean;
    group?: string;
}