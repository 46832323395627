import React from "react";
import UsersGrid from "@/components/UsersGrid/UsersGrid";
import {FileModel, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import UserRoleModel from "@/models/server/UserRoleModel";
import GetUsersPagedListRequest from "@/models/server/Requests/GetUsersPagedListRequest";
import UserModel from "@/models/server/UserModel";
import {Button, ButtonType, Dropdown, EmailInput, Form, IStringInputModel, PageContainer, PageHeader, SelectListItem} from "@renta-apps/athenaeum-react-components";
import {Dictionary} from "typescript-collections";
import AddAdminUserRequest from "@/models/server/Requests/AddAdminUserRequest";
import Localizer from "@/localization/Localizer";
import InvitationsGrid from "@/components/InvitationsGrid/InvitationsGrid";
import PendingInvitationOverviewModel from "@/models/server/PendingInvitationOverviewModel";
import {BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";
import UsersGridToolbarModel from "@/components/UsersGrid/Toolbar/UsersGridToolbarModel";
import InvitationsGridToolbarModel from "@/components/InvitationsGrid/Toolbar/InvitationsGridToolbarModel";
import RentaEasyController from "@/pages/RentaEasyController";
import PendingInvitationsRequest from "@/models/server/Requests/PendingInvitationsRequest";
import GetUsersCsvFileRequest from "@/models/server/Requests/GetUsersCsvFileRequest";
import UsersGridToolbar from "@/components/UsersGrid/Toolbar/UsersGridToolbar";

import styles from './AdminUserManagement.module.scss';
import AdminPage from "@/models/base/AdminPage";

export interface IAdminUserManagementParams extends BasePageParameters {
}

interface IAdminUserManagementState {
    users: UserRoleModel[];
    userDebug: string | null;
    marketingListfilters: UsersGridToolbarModel;
}

export default class AdminUserManagement extends AdminPage<IAdminUserManagementParams, IAdminUserManagementState> {

    // Fields

    public state: IAdminUserManagementState = {
        users: [],
        userDebug: null,
        marketingListfilters: {
            keyword: "",
            roleNamesDropdown: [
                new SelectListItem("OrganizationMainUser,ConstructionSiteMainUser,ConstructionSiteUser", Localizer.adminUserManagementCorporateAccount),
                new SelectListItem("Yksityinen", Localizer.privatePerson)
            ],
            selectedRoleName: null,
            selectedDepotId: null,
            notProcessedUsers: false,
        }
    };
    private _email: IStringInputModel = {value: ""};
    private readonly _invitationsGridRef: React.RefObject<InvitationsGrid> = React.createRef();
    private readonly _usersGridRef: React.RefObject<UsersGrid> = React.createRef();
    private readonly _officeUserRoleName: string = "OfficeUser";

    // Properties

    private get adminRolesDropdownItems(): SelectListItem[] {
        const items: SelectListItem[] = [new SelectListItem(this._officeUserRoleName, this._officeUserRoleName)];

        if (this.isAdminWithAdminRole) {
            items.unshift(new SelectListItem("Admin", "Admin"));
        }

        return items;
    }

    private get operatorContractId(): string {
        return this.userContext.selectedContractId!;
    }

    // Methods

    private async deleteUserAsync(email: string): Promise<void> {
        await this.postAsync("/api/Users/DeleteUser", email);
        await this._invitationsGridRef.current!.reloadAsync();
    }

    private async getUserDebugDetails(email: string): Promise<void> {
        const debugJson: string = await this.postAsync("/api/Admin/GetUserForAdminDebug", email);
        await this.setState({userDebug: debugJson});
    }

    private async removeUserStrongAuthenticationAsync(email: string): Promise<void> {
        await this.postAsync("/api/Users/RemoveUserStrongAuthentication", email);
        await this._usersGridRef.current!.reloadAsync();
    }

    private async getPendingInvitationsAsync(filters: InvitationsGridToolbarModel | null,
                                             pagesNumber: number,
                                             pageSize: number,
                                             sortColumnName: string | null,
                                             sortDirection: SortDirection | null): Promise<IPagedList<PendingInvitationOverviewModel>> {

        const request: PendingInvitationsRequest = {
            contractId: this.operatorContractId,
            constructionSiteId: null,
            pageSize: pageSize,
            pageNumber: pagesNumber,
            sortDirection: sortDirection,
            sortColumnName: sortColumnName,
            keyWord: filters?.keyword ?? null,
            processed: filters?.processed ?? null,
        };

        return await RentaEasyController.getPendingInvitationsAsync(request, this);
    }

    private async fetchUsersAsync(pageNumber: number,
                                  pageSize: number,
                                  sortColumnName: string | null,
                                  sortDirection: SortDirection | null,
                                  filters: UsersGridToolbarModel | null): Promise<IPagedList<UserModel>> {

        const request: GetUsersPagedListRequest = {
            contractId: this.operatorContractId,
            sortColumnName,
            sortDirection,
            keyWord: (filters) ? filters.keyword : null,
            selectedRoleName: (filters) ? filters.selectedRoleName : null,
            selectedDepotId: (filters) ? filters.selectedDepotId : null,
            notProcessedUsers: (filters) ? filters.notProcessedUsers : false,
            pageNumber,
            pageSize
        };

        return await this.postAsync("/api/Companies/GetUsersPagedList", request);
    }

    private async inviteAdminUserAsync(data: Dictionary<string, any>) {
        const email: string = data.getValue("email");
        const selectedRole: string = data.getValue("role");

        if ((selectedRole === this._officeUserRoleName) && (!this.getContext().settings.officeUserWhitelistedDomains.some((domain: string) => email.endsWith(domain)))) {
            await this.alertErrorAsync(Localizer.adminUserManagementOfficeUserDomainError, true, true);
            return;
        }

        const request: AddAdminUserRequest = {
            email,
            roleName: selectedRole
        };

        this._email.value = "";

        await this.postAsync("/api/Users/InviteAdminUser", request);

        this.reloadGrids();
    }

    private async onResendUserInvitationAsync(invitationId: string): Promise<void> {
        await RentaEasyController.resendInvitationAsync(invitationId, this);
    }

    private async onDeletePendingInvitationAsync(dataItem: PendingInvitationOverviewModel): Promise<void> {
        await RentaEasyController.deletePendingInvitationAsync(dataItem.invitationId, this);
    }

    private async downloadMarketingListCsvAsync(model: UsersGridToolbarModel): Promise<void> {

        let request: GetUsersCsvFileRequest = {
            contractId: this.operatorContractId,
            selectedDepotId: model.selectedDepotId,
            selectedRoleName: model.selectedRoleName ?? "",
            isMarketingListRequest: true
        };

        let response: FileModel = await this.postAsync("/api/Admin/GetMarketingListCsv", request);

        ch.download(response);

    }

    private reloadGrids(): void {
        // No need to await
        this._invitationsGridRef.current!.reloadAsync();
        this._usersGridRef.current!.reloadAsync();
    }

    protected get title(): string {
        return Localizer.adminPageUserManagement;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.adminUserManagement}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />

                {
                    this.state.userDebug && (
                        <div className="d-flex flex-column gap-4" id={'user_debug_container'}>
                            <Button label={'Close user debug'}
                                    id={'close_user_debug'}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.setState({userDebug: null})}
                            />
                            <pre id={"user_debug_content"}>{this.state.userDebug}</pre>
                        </div>
                    )
                }

                <div className="d-flex flex-column gap-4">
                    <div>
                        <UsersGrid showPrivateUsers canManageUsers showDepotColumn showDeleteUserButton
                                   ref={this._usersGridRef}
                                   contractId={this.operatorContractId}
                                   fetchUsers={async (pageNumber, pageSize, sortColumnName, sortDirection, filters) =>
                                       await this.fetchUsersAsync(pageNumber, pageSize, sortColumnName, sortDirection, filters)}
                                   onUserDeleteAsync={async (email) => await this.deleteUserAsync(email)}
                                   onUserAuthenticationRemoveAsync={async (email) => await this.removeUserStrongAuthenticationAsync(email)}
                                   getUserDebugDetails={async (email) => await this.getUserDebugDetails(email)}
                        />
                    </div>

                    <div>
                        <div className={this.css("container", styles.generateMailingListUsersArea)}>

                            <h3>
                                {Localizer.adminUserManagementGenerateMailingList}
                            </h3>

                            <UsersGridToolbar hideSearch showDepotFilter hideNotProcessed
                                              customNothingSelectedText={`${Localizer.adminUserManagementCorporateAccount}/${Localizer.privatePerson}`}
                                              model={this.state.marketingListfilters}
                                              downloadUsersCsv={async (model) => await this.downloadMarketingListCsvAsync(model)}
                            />

                        </div>
                    </div>

                    <div>
                        <Form onSubmit={async (sender, data) => await this.inviteAdminUserAsync(data)}>
                            <h3>
                                {Localizer.adminUserManagementInviteUser}
                            </h3>

                            <EmailInput required
                                        id={"email"}
                                        label={Localizer.genericEmail}
                                        model={this._email}
                            />

                            <Dropdown required
                                      id={"role"}
                                      label={Localizer.genericRole}
                                      items={this.adminRolesDropdownItems}
                            />

                            <Button submit
                                    label={Localizer.genericSave}
                            />
                        </Form>
                    </div>

                    <div>
                        <div>
                            <h4>
                                {Localizer.adminUserManagementPendingInvitationsTitle}
                            </h4>
                        </div>

                        <div>
                            <InvitationsGrid ref={this._invitationsGridRef}
                                             getPendingInvitationsAsync={async (filters, pagesNumber: number, pageSize: number, sortColumnName, sortDirection) =>
                                                 await this.getPendingInvitationsAsync(filters, pagesNumber, pageSize, sortColumnName, sortDirection)}
                                             onDeletePendingInvitationAsync={async (dataItem: PendingInvitationOverviewModel) =>
                                                 await this.onDeletePendingInvitationAsync(dataItem)}
                                             onResendUserInvitationAsync={async (invitationId: string) =>
                                                 await this.onResendUserInvitationAsync(invitationId)}
                            />
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}