import React from "react";
import {
    Button,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownRequiredType,
    EmailInput,
    Form, Inline, InlineType,
    IStringInputModel, Modal, ModalSize,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PasswordInput,
    TextInput,
    TwoColumns,
    ValidationRow
} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import AnonymousPage from "@/models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import {BasePageParameters, PageRouteProvider, PasswordValidationRule} from "@renta-apps/athenaeum-react-common";
import RegisterRequest from "@/models/server/Requests/RegisterRequest";
import UnleashHelper from "@/helpers/UnleashHelper";
import {validatePhoneNumber} from "@/helpers/Validators";
import styles from "@/pages/Register/Register.module.scss";
import DepotModel from "@/models/server/DepotModel";
import RentaEasyController from "@/pages/RentaEasyController";
import UserModel from "@/models/server/UserModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import PageDefinitions from "@/providers/PageDefinitions";

export interface IRegisterParams extends BasePageParameters {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
}

class FormData {
    address: string | null = null;
    postalcode: string | null = null;
    city: string | null = null;
    firstname: string | null = null;
    lastname: string | null = null;
    telephone: string | null = null;
    email: string | null = null;
    vatId: string | null = null;
    agreementAccepted: boolean = false;
    registrationAccepted: boolean = false;
    selectedRentaOffice: string | null = null;
    marketingAccepted: boolean = false;
}

interface IRegisterState {
    rentalOfficeDropdown: DepotModel[],
    isSending: boolean,
    formData: FormData | null,
}

export default class Register extends AnonymousPage<IRegisterParams, IRegisterState> {

    state: IRegisterState = {
        rentalOfficeDropdown: [],
        isSending: false,
        formData: null,
    };

    private readonly _passwordConfirmationRef: React.RefObject<PasswordInput> = React.createRef();

    public password: IStringInputModel = {value: ""};
    public passwordConfirmation: IStringInputModel = {value: ""};

    private readonly _formRef: React.RefObject<any> = React.createRef();

    private passwordForConfirmation: string | null = null;

    private static get validationRows(): ValidationRow[] {
        return [
            new ValidationRow(PasswordValidationRule.UpperCaseCharacter, Localizer.passwordHelpTextUpperCase),
            new ValidationRow(PasswordValidationRule.LowerCaseCharacter, Localizer.passwordHelpTextLowerCase),
            new ValidationRow(PasswordValidationRule.NumberCharacter, Localizer.passwordHelpTextNumber),
            new ValidationRow(PasswordValidationRule.SpecialCharacter, Localizer.passwordHelpTextSpecialCharacter)
        ];
    }

    private get hasCompanyOrSiteRoles(): boolean {
        return this.userContext.user!.organizationRoles?.length > 0
            || this.userContext.user!.constructionSiteRoles?.length > 0;
    }

    private get hasUser():boolean {
        return !!this.userContext?.user;
    }

    private getUserRequest(data: Dictionary<string, any>): UserModel {
        const newUser: UserModel = new UserModel();
        newUser.email = this.userContext.username;
        newUser.vatId = data.getValue("vatId");
        newUser.favoriteDepotId = data.getValue("selectedRentaOffice");
        newUser.address = data.getValue("address");
        newUser.city = data.getValue("city");
        newUser.firstName = data.getValue("firstname");
        newUser.lastName = data.getValue("lastname");
        newUser.phoneNumber = data.getValue("telephone");
        newUser.postalCode = data.getValue("postalcode");
        newUser.registerAccepted = data.getValue("registrationAccepted");
        newUser.agreementAccepted = data.getValue("agreementAccepted");
        newUser.approveMarketing = data.getValue("marketingAccepted");
        newUser.id = RentaEasyConstants.emptyGuid;
        return newUser;
    }

    private getRegisterRequest(data: Dictionary<string, any>): RegisterRequest {
        const request: RegisterRequest = {
            username: data.getValue("email"),
            vatId: data.getValue("vatId"),
            password: data.getValue("password"),
            favoriteDepotId: data.getValue("selectedRentaOffice"),
            address: data.getValue("address"),
            city: data.getValue("city"),
            firstName: data.getValue("firstname"),
            lastName: data.getValue("lastname"),
            phoneNumber: data.getValue("telephone"),
            postalCode: data.getValue("postalcode"),
            registerAccepted: data.getValue("registrationAccepted"),
            agreementAccepted: data.getValue("agreementAccepted"),
            approveMarketing: data.getValue("marketingAccepted"),
        };

        return request;
    }

    public async fillBasicInfoAsync(data: Dictionary<string, any>): Promise<void> {
        const newUser = this.getUserRequest(data);

        await this.setState({isSending: true});

        await this.postAsync("/api/Users/SaveUser", newUser);

        if (this.hasCompanyOrSiteRoles) {
            await this.toFrontPageAsync();
        }
        else {
            await PageRouteProvider.redirectAsync(PageDefinitions.welcome.route());
        }

    }

    private async registerAsync(data: Dictionary<string, any>): Promise<void> {
        const request: RegisterRequest = this.getRegisterRequest(data);
        await this.postAsync("/api/Account/Register", request);
    }

    private async handleSubmitAsync(form: Form, data: Dictionary<string, any>): Promise<void> {
        // Old registration form support where user filled email and password before rest of the details
        if (this.userContext?.user && this.userContext?.user.hasPassword) {
            await this.fillBasicInfoAsync(data);
            return;
        }

        // New user was invited. They need to fill password and other details
        if (this.userContext?.user) {
            await this.setState({isSending: true});

            const request: RegisterRequest = this.getRegisterRequest(data);
            await this.postAsync("/api/Users/RegisterInvitedUser", request);

            await this.setState({isSending: false});
            await this.toFrontPageAsync();
            return;
        }

        // Completely new user using new (current) form
        await this.registerAsync(data);
    }

    private get isSending(): boolean {
        return this.state.isSending;
    }

    private get rentalOffices(): DepotModel[] {
        return this.state.rentalOfficeDropdown;
    }

    private get vatIdForUsersRequired(): boolean {
        return UnleashHelper.isEnabled(RentaEasyConstants.featureFlagVatIdForUsers);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({isSending: true});
        let user = this.userContext?.user;
        let formData: FormData | null = null;
        if (user) {
            formData = new FormData();
            formData.email = user.email;
            formData.vatId = user.vatId;
            formData.city = user.city;
            formData.address = user.address;
            formData.agreementAccepted = user.agreementAccepted;
            formData.firstname = user.firstName;
            formData.lastname = user.lastName;
            formData.selectedRentaOffice = user.favoriteDepotId;
            formData.telephone = user.phoneNumber;
            formData.postalcode = user.postalCode;
            formData.registrationAccepted = user.registerAccepted;
            formData.marketingAccepted = user.approveMarketing;
        }
        else if (this.typedParameters?.email) {
            formData = new FormData();
            formData.email = this.typedParameters.email;
            formData.firstname = this.typedParameters?.firstName ?? null;
            formData.lastname = this.typedParameters?.lastName ?? null;
        }
        const response: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        await this.setState({rentalOfficeDropdown: response, isSending: false, formData: formData});
    }

    protected get title(): string {
        return this.hasUser
            ? Localizer.myAccountTitle
            : Localizer.createAccountText;
    }

    private validatePasswordConfirmation = (passwordConfirmation: string | null): string | null => {
        let passwordsMatch = this.passwordForConfirmation === passwordConfirmation;
        return (!passwordConfirmation || !passwordsMatch) ? Localizer.resetPasswordPasswordsDontMatchMessage : null;
    };

    private registerAgreementValidator = (value: boolean | null): string | null => {
        return !value ? Localizer.registerAgreementNeedsToBeAccepted : null;
    };

    private registerValidator = (value: boolean | null): string | null => {
        return !value ? Localizer.registerRegisterNeedsToBeAccepted : null;
    };

    private async handlePasswordChange(password: string | null): Promise<void> {
        this.passwordForConfirmation = password;
        let foo = this._passwordConfirmationRef.current!;
        await foo.validateAsync();
    };

    public render(): React.ReactNode {
        return (
            <PageContainer hasWideHeader className={styles.registerPage}>

                <PageHeader title={this.title} wideHeader wideHeaderBackgroundImage="/images/renta-kuva-8-scaled.jpg"/>

                <PageRow>
                    <p className={styles.helpText}>
                        {Localizer.registerHelpTextRequired}
                    </p>
                    <Form id="registerForm"
                          ref={this._formRef}
                          onSubmit={async (form, data) => await this.handleSubmitAsync(form, data)}>

                        <TwoColumns>
                            <EmailInput required autoFocus={!this.typedParameters?.email}
                                        maxLength={200}
                                        id="email"
                                        label={Localizer.genericEmail}
                                        value={this.state.formData?.email ?? ""}
                                        readonly={!!this.userContext?.user}
                            />

                            {this.vatIdForUsersRequired &&
                                <TextInput required
                                           id="vatId"
                                           maxLength={200}
                                           label={Localizer.genericVatId}
                                           value={this.state.formData?.vatId ?? ""}
                                />
                            }
                        </TwoColumns>

                        {
                            (!this.userContext.user || !this.userContext.user.hasPassword) && (
                                <TwoColumns>
                                    <PasswordInput required autoFocus={!!this.typedParameters?.email}
                                                   maxLength={200}
                                                   id="password"
                                                   validLength={8}
                                                   label={Localizer.registerInputPassword}
                                                   liveValidator={Register.validationRows}
                                                   onChange={async (text) => this.handlePasswordChange(text.value)}
                                    />

                                    <PasswordInput required
                                                   maxLength={200}
                                                   id="passwordConfirmation"
                                                   ref={this._passwordConfirmationRef}
                                                   label={Localizer.registerInputPasswordConfirm}
                                                   validators={[this.validatePasswordConfirmation]}
                                    />
                                </TwoColumns>
                            )
                        }

                        <TwoColumns>
                            <TextInput required
                                       id="firstname"
                                       maxLength={200}
                                       label={Localizer.genericFirstName}
                                       value={this.state.formData?.firstname ?? ""}
                            />

                            <TextInput required
                                       id="lastname"
                                       maxLength={200}
                                       label={Localizer.genericLastName}
                                       value={this.state.formData?.lastname ?? ""}
                            />
                        </TwoColumns>

                        <OneColumn>
                            <TextInput required
                                       id="address"
                                       maxLength={200}
                                       label={Localizer.genericAddress}
                                       value={this.state.formData?.address ?? ""}
                            />
                        </OneColumn>

                        <TwoColumns>
                            <TextInput required
                                       maxLength={200}
                                       id="postalcode"
                                       label={Localizer.genericPostalCode}
                                       value={this.state.formData?.postalcode ?? ""}
                            />

                            <TextInput required
                                       maxLength={200}
                                       id="city"
                                       label={Localizer.genericCity}
                                       value={this.state.formData?.city ?? ""}
                            />
                        </TwoColumns>

                        <TwoColumns>
                            <TextInput required
                                       maxLength={200}
                                       id="telephone"
                                       label={Localizer.genericPhoneNumber}
                                       validators={[validatePhoneNumber]}
                                       placeholder={Localizer.genericPlaceholderPhoneNumber}
                                       value={this.state.formData?.telephone ?? ""}
                            />
                            <Dropdown required
                                      requiredType={DropdownRequiredType.Restricted}
                                      id="selectedRentaOffice"
                                      label={Localizer.myAccountClosestRentaOffice}
                                      items={this.rentalOffices}
                                      selectedItem={this.state.formData?.selectedRentaOffice ?? ""}
                            />
                        </TwoColumns>

                        <OneColumn className="mb-3">
                            <Inline>
                                <Checkbox required
                                          inline
                                          id="agreementAccepted"
                                          label={Localizer.myAccountIHaveRedAndAgree}
                                          inlineType={InlineType.Right}
                                          validators={[this.registerAgreementValidator]}
                                          value={this.state.formData?.agreementAccepted ?? false}
                                />

                                <Button toggleModal
                                        className={this.css(styles.textButton)}
                                        label={Localizer.myAccountPrivacyNotice}
                                        type={ButtonType.Text}
                                        dataTarget="privacyPolicyModal"
                                />
                            </Inline>

                            <Inline>
                                <Checkbox required
                                          inline
                                          id="registrationAccepted"
                                          label={Localizer.myAccountIHaveRedAndAgree}
                                          inlineType={InlineType.Right}
                                          validators={[this.registerValidator]}
                                          value={this.state.formData?.registrationAccepted ?? false}
                                />

                                <Button toggleModal
                                        className={this.css(styles.textButton)}
                                        label={Localizer.myAccountRegisterTitle}
                                        type={ButtonType.Text}
                                        dataTarget="agreementModal"
                                />
                            </Inline>

                            <Inline>
                                <Checkbox inline
                                          id="marketingAccepted"
                                          label={Localizer.myAccountApproveMarketing}
                                          inlineType={InlineType.Right}
                                          value={this.state.formData?.marketingAccepted ?? false}
                                />
                            </Inline>
                        </OneColumn>

                        <OneColumn>
                            <Button className={styles.registerButton}
                                    submit
                                    id="registerButton"
                                    type={ButtonType.Orange}
                                    label={this.hasUser ? Localizer.genericSave : Localizer.registerButton}
                                    disabled={this.isSending}
                            />
                        </OneColumn>

                    </Form>

                    <Modal id="agreementModal" info keepTextFormatting isBodyScrollable
                           title={Localizer.userAgreementTitle}
                           content={Localizer.userAgreementText}
                           size={ModalSize.ExtraLarge}
                    />

                </PageRow>
            </PageContainer>
        );
    }
}