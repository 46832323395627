import React from "react";
import {Grid, ITabContainerClassNames, PageContainer, PageHeader, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType} from "@renta-apps/athenaeum-react-components";
import Equipments from "./Equipments/Equipments";
import Localizer from "@/localization/Localizer";
import styles from "./ConstructionSiteDetails.module.scss";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ConstructionSiteServices from "@/pages/ConstructionSiteDetails/ConstructionSiteServices/ConstructionSiteServices";
import ConstructionSiteUsers from "@/pages/ConstructionSiteDetails/ConstructionSiteUsers/ConstructionSiteUsers";
import Invoices from "@/pages/ConstructionSiteDetails/Invoices/Invoices";
import {BasePageParameters, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ConstructionSiteEquipmentMap from "@/pages/ConstructionSiteDetails/ConstructionSiteEquipmentMap/ConstructionSiteEquipmentMap";
import EnvironmentControl from "@/pages/ConstructionSiteDetails/EnvironmentControl/EnvironmentControl";
import ConstructionSiteServiceRequestModel from "@/models/server/ConstructionSiteServiceRequestModel";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import ConstructionSiteEmissions from "@/pages/ConstructionSiteDetails/Emissions/ConstructionSiteEmissions";
import NoAccess from "@/components/NoAccess/NoAccess";

export interface IConstructionSiteDetailsParams extends BasePageParameters {

    /**
     * Id of the Construction Site to display.
     */
    id: string;

    selectedTab?: string;
}

interface IConstructionSiteDetailsState {
    constructionSite: ConstructionSiteModel | null;
    canManageUsers: boolean;
    hasAccess: boolean | null;
}

interface IConstructionSiteDetailsData {
    constructionSite: ConstructionSiteModel;
    canManageUsers: boolean;
    hasAccess: boolean;
}

class ConstructionSiteDetails extends AuthorizedPage<IConstructionSiteDetailsParams, IConstructionSiteDetailsState> {

    private readonly _environmentControlTab: React.RefObject<Tab> = React.createRef();
    private readonly _servicesGrid: React.RefObject<Grid<ConstructionSiteServiceRequestModel>> = React.createRef();

    public state: IConstructionSiteDetailsState = {
        constructionSite: null,
        canManageUsers: false,
        hasAccess: null,
    };

    private get constructionSite(): ConstructionSiteModel | null {
        return this.state.constructionSite;
    }

    private get canManageUsers(): boolean {
        return this.state.canManageUsers;
    }

    private get hasAccess(): boolean | null {
        return this.state.hasAccess;
    }

    private get canSeeInvoices(): boolean {
        const { isConstructionSiteMainUser, isSingleConstructionSiteMainUserUser, isCompanyMainUser, isAdmin } = this.userContext;
        return isConstructionSiteMainUser || isSingleConstructionSiteMainUserUser || isCompanyMainUser || isAdmin;
    }

    private get constructionSiteId(): string | undefined {
        return this.typedParameters?.id;
    }

    protected get title(): string {
        return Localizer.breadCrumbConstructionsiteDetails;
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: styles.tabContainerNavTabs,
            scrollableContainer: styles.scrollableContainer,

        };
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.constructionSiteId) {
            await this.toFrontPageAsync();
        }

        else {
            const data: IConstructionSiteDetailsData = await this.postAsync("/api/ConstructionSites/GetConstructionSiteDetails", this.constructionSiteId);

            if (this.isMounted) {
                await this.setState({
                    constructionSite: data.constructionSite,
                    canManageUsers: data.canManageUsers,
                    hasAccess: data.hasAccess,
                });
            }
        }
    }

    private async onServiceCreated(): Promise<void> {
        await this._servicesGrid.current?.reloadAsync();
    }

    private getCurrentRouteWithTabNameAdded(selectedTab: string): PageRoute {
        const route = PageRoute.clone(this.route);
        (route.parameters! as IConstructionSiteDetailsParams).selectedTab = selectedTab.toLowerCase();
        return route;
    }

    private isSelectedTabFromUrl(localizedString: string): boolean {
        return this.typedParameters?.selectedTab === localizedString.toLowerCase();
    }

    private async addTabNameToRoute(tabName: string): Promise<void> {
        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(this.getCurrentRouteWithTabNameAdded(tabName), false);
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized || !this.constructionSiteId) {
            return null;
        }

        if (this.hasAccess === false) {
            return <NoAccess type="constructionSite" />
        }

        const title: string = (this.constructionSite?.ownerAdditionalName) ?
            `${this.constructionSite.ownerName} (${this.constructionSite.ownerAdditionalName})` :
            this.constructionSite?.ownerName ?
                this.constructionSite.ownerName
                : this.title;

        return (

            <PageContainer hasWideHeader className={styles.constructionSiteDetails}>
                <PageHeader wideHeader
                            wideHeaderBackgroundImage="/images/renta-kuva-6-scaled.jpg"
                            className={styles.athenaeumPageContainerHeader}
                            title={title}
                            subtitle={this.constructionSite?.displayName ?? '-'}
                />

                {
                    this.renderContractPricesAlert()
                }

                <TabContainer id="constructionSiteDetailsContainer"
                              renderType={TabRenderType.ActiveOnly}
                              headerStyleType={TabContainerHeaderStyleType.Underline}
                              className={styles.constructionSiteDetailsTabs}
                              classNames={this.tabContainerClassNames}>

                    <Tab id="productsTab"
                         active={this.isSelectedTabFromUrl(Localizer.constructionSiteDetailsEquipmentTab)}
                         onSelect={() => this.addTabNameToRoute(Localizer.constructionSiteDetailsEquipmentTab)}
                         title={Localizer.constructionSiteDetailsEquipmentTab}>
                        <Equipments constructionSiteId={this.constructionSiteId}
                                    serviceCreated={async () => await this.onServiceCreated()}
                        />
                    </Tab>

                    <Tab id="productMapTab"
                         active={this.isSelectedTabFromUrl(Localizer.constructionSiteDetailsEquipmentMap)}
                         onSelect={() => this.addTabNameToRoute(Localizer.constructionSiteDetailsEquipmentMap)}
                         title={Localizer.constructionSiteDetailsEquipmentMap}
                    >
                        <ConstructionSiteEquipmentMap constructionSiteId={this.constructionSiteId!}/>
                    </Tab>

                    {
                        (this.canSeeInvoices) && (
                            <Tab id="invoicesTab"
                                 active={this.isSelectedTabFromUrl(Localizer.constructionSiteDetailsInvoiceTab)}
                                 onSelect={() => this.addTabNameToRoute(Localizer.constructionSiteDetailsInvoiceTab)}
                                 title={Localizer.constructionSiteDetailsInvoiceTab}>
                                <Invoices constructionSiteId={this.constructionSiteId!}/>
                            </Tab>
                        )
                    }

                    {
                        (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagEnvironmentControl)) && (
                            <Tab id="environmental"
                                 active={this.isSelectedTabFromUrl(Localizer.constructionSiteDetailsEnvironmentControlTab)}
                                 onSelect={() => this.addTabNameToRoute(Localizer.constructionSiteDetailsEnvironmentControlTab)}
                                 title={Localizer.constructionSiteDetailsEnvironmentControlTab}
                                 ref={this._environmentControlTab}>
                                <EnvironmentControl constructionSiteId={this.constructionSiteId!}/>
                            </Tab>
                        )
                    }

                    <Tab id="usersTab"
                         active={this.isSelectedTabFromUrl(Localizer.constructionSiteUsers)}
                         onSelect={() => this.addTabNameToRoute(Localizer.constructionSiteUsers)}
                         title={Localizer.constructionSiteUsers}>
                        {
                            (this.constructionSite) &&
                            (
                                <ConstructionSiteUsers constructionSiteId={this.constructionSiteId!}
                                                       constructionSiteOwnerId={this.constructionSite!.ownerId}
                                                       canManageUsers={this.canManageUsers}
                                />
                            )
                        }
                    </Tab>

                    <Tab id="serviceRequestsTab"
                         active={this.isSelectedTabFromUrl(Localizer.constructionSiteDetailsServicesTab)}
                         onSelect={() => this.addTabNameToRoute(Localizer.constructionSiteDetailsServicesTab)}
                         title={Localizer.constructionSiteDetailsServicesTab}>
                        {
                            (this.constructionSite) &&
                            (
                                <ConstructionSiteServices constructionSiteId={this.constructionSiteId!}
                                                          _servicesGrid={this._servicesGrid}
                                />
                            )
                        }
                    </Tab>

                    {
                        (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCo2Enabled)) && (
                            <Tab id="emissions2"
                                 active={this.isSelectedTabFromUrl("Co2 Emissions")}
                                 onSelect={() => this.addTabNameToRoute("Co2 Emissions")}
                                 title={Localizer.constructionSiteDetailsCo2Emissions}>
                                <ConstructionSiteEmissions constructionSiteId={this.constructionSiteId} />
                            </Tab>
                        )
                    }

                </TabContainer>

            </PageContainer>
        );
    }
}

export default ConstructionSiteDetails;