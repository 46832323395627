import React from "react";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import Localizer from "../../localization/Localizer";
import Catalog from "@/components/Catalog/Catalog";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";

import styles from "./Rent.module.scss";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export interface IRentParams extends BasePageParameters {

    /**
     * Search term.
     */
    keyword?: string;
    editMode?: boolean;
}

interface IRentState {
    /**
     * To Show on Header
     */
    breadCrumbItemValues: string[];
}

export default class Rent extends AnonymousPage<IRentParams, IRentState> {

    public state: IRentState = {
        breadCrumbItemValues: []
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.routeId === null) {
            await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(this.route);
        }
        if (this.routeId === RentaEasyConstants.favoritePageId) {
            await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(this.route);
        }
    }

    protected get title(): string {
        return Localizer.rentButtonText;
    }

    public get pageHeaderTitle(): string {
        if (this.state.breadCrumbItemValues.length === 0) {
            return Localizer.breadCrumbRent.toLowerCase();
        }
        return this.state.breadCrumbItemValues[this.state.breadCrumbItemValues.length - 1];
    }

    public render(): React.ReactNode {
        return (
            <PageContainer hasWideHeader className={styles.catalogContainer} >
                <PageHeader wideHeader
                            className="m-0 pt-0"
                            title={this.pageHeaderTitle}
                            wideHeaderBackgroundImage="/images/rent-page-background.svg"
                />

                <div>
                    {
                        this.renderContractPricesAlert()
                    }
                    <Catalog pricingToolMode={false}
                             searchTerm={this.typedParameters?.keyword}
                             editMode={this.isAdmin ? this.typedParameters?.editMode : false}
                             categoryId={this.props.routeId}
                             onBreadCrumbChange={async (items) => {
                                 await this.setState({breadCrumbItemValues: items.map(x => x.name)});
                             }}
                    />
                </div>
            </PageContainer>
        );
    }
}