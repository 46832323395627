import React from "react";
import {Icon} from "@renta-apps/athenaeum-react-components";
import {IIconProps} from "@renta-apps/athenaeum-react-components/src/components/Icon/Icon";

import styles from "./EasyRouteWidget.module.scss";

interface EasyRouteWidgetProps {

    text: string[];

    onClick?(): Promise<void>;

    leftIcon?: IIconProps | string;

    rightIcon?: IIconProps | string;

    lightStyle?: boolean;
}

const EasyRouteWidget = ({text, onClick, leftIcon, rightIcon, lightStyle}: EasyRouteWidgetProps): JSX.Element => {
    return (
        <div className={lightStyle ? styles.lightLinkWidgetContainer : styles.linkWidgetContainer}
             onClick={async () => onClick ? onClick() : Promise.resolve()}
        >
            <div className={styles.leftColumns}>
                <div className={styles.column}>
                  <span className={styles.icon}>
                      {
                          <Icon {...(leftIcon as IIconProps)} />

                      }
                  </span>
                </div>
                <div className={styles.column}>
                   <span className={styles.text}>
                       {
                           text.map((item, index) => {
                               const isFirst = index === 0;
                               const isLast = index === text.length - 1;
                               return (
                                   item && (
                                       <React.Fragment key={"role_text_line_" + index}>
                                           {

                                               <span className={isFirst || isLast ? "" : "light"}>{item.toUpperCase()}</span>
                                           }
                                           <br/>
                                       </React.Fragment>
                                   )

                               );
                           })
                       }
                   </span>
                </div>
            </div>
            <div className={styles.rightColumn}>
                <div className={styles.column}>
                  <span className={styles.icon}>
                   {
                       <Icon {...(rightIcon as IIconProps)} />
                   }
                  </span>
                </div>
            </div>
        </div>
    );
};

export default EasyRouteWidget;