import React from "react";
import {Button, ButtonContainer, ButtonType, EmailInput, Form, IStringInputModel, PageContainer, PageHeader, PageRow, PasswordInput, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import AdminChangePasswordRequest from "@/models/server/Requests/AdminChangePasswordRequest";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import AdminStatisticsResponse from "@/models/server/AdminStatisticsResponse";

export interface IAdminSiteManagementParams extends BasePageParameters {
}

interface IAdminSiteManagementState {
    pendingMigrations: string[];
    latestMigration: string | null;
}

/**
 * "Super-secret" Admin page for performing site-admin operations.
 */
export default class AdminSiteManagement extends AdminPage<IAdminSiteManagementParams, IAdminSiteManagementState> {

    public state: IAdminSiteManagementState = {
        pendingMigrations: [],
        latestMigration: null
    };

    private readonly email: IStringInputModel = {value: ""};
    private readonly password: IStringInputModel = {value: ""};
    private readonly secret: IStringInputModel = {value: ""};

    private async clearCacheAsync(): Promise<void> {
        await this.postAsync(`/api/Admin/ClearCache`, null);
    }

    private async clearUserAgreements(userAgreementType: string): Promise<void> {
        await this.postAsync(`/api/Admin/ClearUserAgreements`, userAgreementType);
    }

    private async resetPasswordAsync(formData: Dictionary<string, any>): Promise<void> {

        const email: string = formData.getValue(nameof(this.email));
        const password: string = formData.getValue(nameof(this.password));
        const secret: string = formData.getValue(nameof(this.secret));

        const request: AdminChangePasswordRequest = {
            email,
            password,
            secret,
        };

        await this.postAsync(`/api/Admin/ChangePassword`, request);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: AdminStatisticsResponse = await this.postAsync(`/api/Admin/AdminStatistics`, null);

        await this.setState({latestMigration: response.latestMigration, pendingMigrations: response.pendingMigrations});
    }

    protected get title(): string {
        return "";
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={"row"}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={`${Localizer.genericVersion}: ${this.userContext.version}`}
                />

                <div style={{display: "inline-block", marginLeft: "15px"}}>
                    <div>
                        <p>Latest migration:</p>
                        {
                            (this.state.latestMigration) && (
                                this.state.latestMigration
                            )
                        }
                    </div>
                    <div><p>Pending migrations:</p>
                        {
                            (this.state.pendingMigrations) && (
                                this.state.pendingMigrations.map(migration => {
                                    return (
                                        <div>
                                            {migration}
                                        </div>

                                    );
                                })
                            )
                        }
                    </div>
                </div>

                <PageRow className={"row"}>
                    <Button type={ButtonType.Orange}
                            label={Localizer.changePasswordClearCache}
                            confirm={Localizer.changePasswordClearCacheIfYouKnowWhatYoureDoing}
                            onClick={async () => await this.clearCacheAsync()}
                    />
                    <Button type={ButtonType.Orange}
                            label={Localizer.changePasswordResetPrivacyStatementAgreements}
                            confirm={Localizer.changePasswordResetPrivacyStatementAgreementsIfYouKnowWhatYoureDoing}
                            onClick={async () => await this.clearUserAgreements("PrivacyStatement")}
                    />
                    <Button type={ButtonType.Orange}
                            label={Localizer.changePasswordResetTermsOfServiceAgreements}
                            confirm={Localizer.changePasswordResetTermsOfServiceAgreementsIfYouKnowWhatYoureDoing}
                            onClick={async () => await this.clearUserAgreements("TermsOfService")}
                    />
                </PageRow>

                <PageRow>
                    <Form id="form"
                          onSubmit={async (_, data) => await this.resetPasswordAsync(data)}
                    >

                        <p>
                            {Localizer.changePasswordInsertEmailOrPhonenumber}
                        </p>

                        <p>
                            {Localizer.changePasswordSecretCodeRequired}
                        </p>

                        <TextInput required
                                   id={nameof(this.secret)}
                                   label={"Secret"}
                                   model={this.secret}
                        />

                        <EmailInput required
                                    id={nameof(this.email)}
                                    label={Localizer.genericEmail}
                                    model={this.email}
                        />

                        <PasswordInput required
                                       id={nameof(this.password)}
                                       label={Localizer.genericPassword}
                                       model={this.password}
                        />

                        <ButtonContainer>
                            <Button submit
                                    type={ButtonType.Orange}
                                    label={Localizer.formSend}
                            />
                        </ButtonContainer>

                    </Form>
                </PageRow>
            </PageContainer>

        );
    }

}