import AlarmModel from "./AlarmModel";
import {AlarmType, FuelSensorType} from "@/models/Enums";

export class DeviceModel {
    public rentaId: string = "";

    public rentaGroup: string | null = null;

    public rentaGroupId: string | null = null;

    public assetId: string | null = null;

    public trackUnitSerialNumber: string | null = null;

    public name: string | null = null;

    public isRented: boolean = false;

    public isReturnRequested: boolean = false;

    public configuredAlerts: AlarmModel[] = [];

    public fuelSensorType: FuelSensorType | null = null;

    public alertsCount: number | null = null;

    public idleDays: number | null = null;

    public batteryLevel: number | null = null;

    public gpsSignalQualityNumber: number | null = null;

    public fluidLevel: number | null = null;

    public supportedAlarmTypes: AlarmType[] = [];

    public supportsAlerts: boolean = false;

    public easyPlusRequiredToSeeTelematicValues: boolean = false;

    public isTrackingDevice: boolean = false;

    public contractNumber: string | null = null;

    public orderLineId: string | null = null;

    public static supportsTheftAlerts = (device: DeviceModel): boolean => {
        return device.supportedAlarmTypes.includes(AlarmType.TrackUnitMovementBasedTheft);
    }

    public static supportsIdleAlerts = (device: DeviceModel): boolean => {
        return device.supportedAlarmTypes.includes(AlarmType.DeviceUnusedFor3Days);
    }

    public static supportsFuelAlerts = (device: DeviceModel): boolean => {
        return device.supportedAlarmTypes.includes(AlarmType.TrackUnitFuelLevelGreaterThan)
            || device.supportedAlarmTypes.includes(AlarmType.TrackUnitFuelLevelLessThan)
            || device.supportedAlarmTypes.includes(AlarmType.PindoraFluidLevelLessThan);
    }

    public static supportsBatteryAlerts = (device: DeviceModel): boolean => {
        return device.supportedAlarmTypes.includes(AlarmType.TrackUnitBatteryVoltageGreaterThan) || device.supportedAlarmTypes.includes(AlarmType.TrackUnitBatteryVoltageLessThan);
    }
}