import {InvoiceType} from "@/models/Enums";

export default class InvoiceToolbarModel {
    public contracts: string[] = [];

    public invoiceType: InvoiceType | null = null;

    public invoiceStartDate: Date | null = null;

    public invoiceEndDate: Date | null = null;

    public searchQuery: string = "";

    public sortByDateAsc: boolean = false;

    public clear(): void {
        this.contracts = [];
        this.invoiceType = null;
        this.invoiceStartDate = null;
        this.invoiceEndDate = null;
        this.searchQuery = "";
    }
}