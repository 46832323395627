import {onRedirect, pageData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe("Desktop - anonymous register page tests", () => {

    const vatId: string = "54233531";
    const password: string = "Onet.xml1";
    const firstName: string = "Test";
    const lastname: string = "User";
    const address: string = "address";
    const postalcode: string = "postalcode";
    const city: string = "city";
    const telephone: string = "+1234567";

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);

    });

    it("User registers and confirms account", () => {
        Cypress.on('uncaught:exception', (err, runnable) => {
            return false
        })

        const email: string = `test${new Date().getTime()}@weare.fi`

        onRedirect(() => cy.visit(Localizer.pageRoutesRegister));

        pageData().common.waitTopNavRender();

        pageData().common.assertPageTitle(Localizer.createAccountText)

        cy.wait(300);

        fillInRegularRegistrationForm(email);

        submitRegistrationForm(email);

        onRedirect(() => cy.visit(Localizer.pageRoutesRegister));

        pageData().common.waitTopNavRender();

        pageData().common.assertPageTitle(Localizer.myAccountTitle)

        cy.get('a.athenaeum-link-link').contains(Localizer.logoutText).click()

    });

    it("should register Danish user if they provide VAT Id", () => {

        const email: string = `test${new Date().getTime()}@weare.fi`

        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagVatIdForUsers);

        onRedirect(() => cy.visit(Localizer.pageRoutesRegister));

        pageData().common.waitTopNavRender();

        cy.wait(200);

        fillInRegularRegistrationForm(email);

        pageData().register.inputs.vatId().clear().type(vatId);

        submitRegistrationForm(email);

        pageData().myAccount.inputs.vatId().should('have.value', vatId);
        cy.get('a.athenaeum-link-link').contains(Localizer.logoutText).click()

        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagVatIdForUsers);
    });

    function fillInRegularRegistrationForm(email: string) {

        pageData().register.inputs.email().clear().type(email);
        pageData().register.inputs.password().clear().type(password);
        pageData().register.inputs.passwordConfirmation().clear().type(password);
        pageData().register.inputs.firstname().clear().type(firstName);
        pageData().register.inputs.lastname().clear().type(lastname);
        pageData().register.inputs.address().clear().type(address);
        pageData().register.inputs.postalcode().clear().type(postalcode);
        pageData().register.inputs.city().clear().type(city);
        pageData().register.inputs.telephone().clear().type(telephone);

        DropdownHelper.openAndSelectDropdownItemByIndex("#selectedRentaOffice", 0);

        pageData().register.inputs.agreementAccepted().click();
        pageData().register.inputs.registrationAccepted().click();
    }

    function submitRegistrationForm(email: string) {

        cy.intercept(pageData().register.routes.register.method, pageData().register.routes.register.path).as("register");
        pageData().register.registerButton().click();

        cy.wait("@register").then(({ response }) => {

            if (response?.body?.value && response?.body?.value?.length > 0) {
                const tokenUrl = response?.body?.value;

                cy.intercept(pageData().common.routes.tokenLogin.method, pageData().common.routes.tokenLogin.path).as('tokenLogin');
                cy.visit(tokenUrl);
                cy.waitSuccess('@tokenLogin')

                cy.visit(Localizer.pageRoutesMyAccount);
                pageData().common.assertPageTitle(Localizer.myAccountTitle)

                pageData().myAccount.inputs.firstname().should('have.value', firstName);
                pageData().myAccount.inputs.lastname().should('have.value', lastname);
                pageData().myAccount.inputs.address().should('have.value', address);
                pageData().myAccount.inputs.postalcode().should('have.value', postalcode);
                pageData().myAccount.inputs.email().should('have.value', email);
            }
        });
    }
});
