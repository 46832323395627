import Localizer from "../../../localization/Localizer";

describe('Desktop - Logged in private user ordered order tests', () => {

    beforeEach(() => {
        cy.session(['private_user_session'], () => {
            cy.loginSpecificUser("private.user@domain.fi", "Onet.xml1")
        });
    });

    it('Open frontPage and verify proper widgets are visible', () => {

        cy.visit('/orderdetails/9D37996A-8E66-4FA1-9727-EA39125C8214?orderConfirmation=true');

        cy.get('div.athenaeum-alert-alert').contains(Localizer.shoppingCartSentShort);

        cy.get('#editModeButton').should('not.exist');

        cy.get('#saveButton').should('not.exist');

        cy.get('h3').contains(Localizer.orderInfoText);

        cy.get('span[data-status="Ordered"]').contains(Localizer.enumOrderStatusOrdered)
    });

});