import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import ProductModel from "@/models/server/ProductModel";
import CategoryModel from "@/models/server/CategoryModel";
import PricingToolDiscountModel from "@/models/server/Models/PricingTool/PricingToolDiscountModel";
import UpdateDiscountRequest from "@/models/server/Requests/PricingTool/UpdateDiscountRequest";
import {PricingToolDiscountStatus} from "@/models/Enums";
import UpdateDiscountStatusRequest from "@/models/server/Requests/PricingTool/UpdateDiscountStatusRequest";
import {OrganizationContractInfo} from "@/models/server/Models/PricingTool/OrganizationContractInfo";
import {ContractConstructionSite} from "@/models/server/Models/PricingTool/ContractConstructionSite";
import SingleDiscountRequest from "@/models/server/Requests/PricingTool/SingleDiscountRequest";
import DiscountStatusLog from "@/models/server/Models/PricingTool/DiscountStatusLog";
import SaveCategoryDiscountRequest from "@/models/server/Requests/PricingTool/SaveCategoryDiscountRequest";
import SaveProductDiscountRequest from "@/models/server/Requests/PricingTool/SaveProductDiscountRequest";

export const fetchContractItemsAsync = (): Promise<OrganizationContractInfo[]> => {
    return ApiProvider.postAsync<OrganizationContractInfo[]>("/api/PricingTool/ListOrganizationContracts", null, this);
};

export const fetchContractConstructionSitesItemsAsync = async (contractId: string): Promise<ContractConstructionSite[]> => {
    return ApiProvider.postAsync<ContractConstructionSite[]>("/api/PricingTool/ListContractConstructionSites", contractId, this);
};

export const fetchDiscountInfoAsync = async (discountId: string): Promise<PricingToolDiscountModel> => {
    const request: SingleDiscountRequest = new SingleDiscountRequest();
    request.discountId = discountId;
    return ApiProvider.postAsync<PricingToolDiscountModel>("/api/PricingTool/GetContractDiscount", request, this);
};

export const fetchDiscountStatusLogsAsync = async (discountId: string): Promise<DiscountStatusLog[]> => {
    const request: SingleDiscountRequest = new SingleDiscountRequest();
    request.discountId = discountId;
    return ApiProvider.postAsync<DiscountStatusLog[]>("/api/PricingTool/ListDiscountStatusLog", request, this);
};

export const updateDiscountAsync = async (discount: PricingToolDiscountModel): Promise<void> => {
    const request: UpdateDiscountRequest = {...discount} as UpdateDiscountRequest;
    request.overallDiscountPercentage = discount.overallDiscountPercentage || 0.0;
    return ApiProvider.postAsync<void>("/api/PricingTool/UpdateContractDiscount", request, this);
};

export const updateTemplateStatusAsync = async (discountId: string, status: PricingToolDiscountStatus): Promise<void> => {
    const request: UpdateDiscountStatusRequest = {discountId, status};
    return ApiProvider.postAsync<void>("/api/PricingTool/UpdateContractDiscountStatus", request, this);
}

export const updateCategoryDiscountPercentageAsync = async (category: CategoryModel, discountId: string, discountPercentage: number): Promise<number> => {
    const request: SaveCategoryDiscountRequest = new SaveCategoryDiscountRequest();
    request.discountId = discountId;
    request.categoryId = category.id;
    request.discountPercentage = discountPercentage;
    return ApiProvider.postAsync<number>("/api/PricingTool/UpdateCategoryDiscount", request, this);
};

export const updateProductDiscountPercentageAsync = async (product: ProductModel, discountId: string, discountPercentage: number): Promise<number> => {
    const request: SaveProductDiscountRequest = new SaveProductDiscountRequest();
    request.discountId = discountId;
    request.categoryId = product.category?.id ?? "";
    request.productId = product.id;
    request.discountPercentage = discountPercentage;
    return ApiProvider.postAsync<number>("/api/PricingTool/UpdateProductDiscount", request, this);
};
