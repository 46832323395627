import Localizer from "../../../localization/Localizer";
import CompanyNameModel from "@/models/CompanyNameModel";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";

describe('Desktop - Plan preview tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("juhani.sihvonen@weare.fi");
        });
        cy.visit(Localizer.pageRoutesPlans);
    });

    it('Opens plan preview, test mandatory checkbox', () => {
        cy.contains("Mandatory checkbox test").click();

        cy.get("#submitPlanDefinitionButton").should('be.disabled');

        cy.get("#plan_input_1").find("i").click();

        cy.get("#submitPlanDefinitionButton").should('not.be.disabled');

        cy.get("#plan_input_1").find("i").click();

        cy.get("#submitPlanDefinitionButton").should('be.disabled');

        cy.get("#plan_input_1").find("i").click();

        cy.get("#submitPlanDefinitionButton").should('not.be.disabled');
    });

    it('Opens plan preview, test mandatory checkbox and image uploads', () => {
        cy.contains("Mandatory fields test").click();

        cy.get("#submitPlanDefinitionButton").should('be.disabled');

        // checkbox
        cy.get("#plan_input_1").find("i").click();
        cy.get("#submitPlanDefinitionButton").should('be.disabled');
        
        // multiselect file upload
        cy.get('#plan_input_2 input[type=file]')
            .first()
            .selectFile([
                {
                    contents: 'cypress/fixtures/test_picture_large.png',
                    fileName: 'test_picture_large.png',
                    mimeType: 'data:image/png',
                    lastModified: Date.now(),
                },
                {
                    contents: 'cypress/fixtures/test_picture_small.png',
                    fileName: 'test_picture_small.png',
                    mimeType: 'data:image/png',
                    lastModified: Date.now(),
                }
                ], {force: true});

        cy.get('#plan_input_2 .athenaeum-file-input-selectedFile').should('have.length', 2);
        cy.get("#submitPlanDefinitionButton").should('be.disabled');

        // single file upload
        cy.get('#plan_input_3 input[type=file]')
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_small.png',
                fileName: 'test_picture_small.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

        cy.get("#submitPlanDefinitionButton").should('not.be.disabled');

        // remove one of the files from multiselect
        cy.get('#plan_input_2 .athenaeum-file-input-selectedFile').first().find("i").click();
        cy.get('#plan_input_2 .athenaeum-file-input-selectedFile').should('have.length', 1);

        cy.get("#submitPlanDefinitionButton").should('not.be.disabled');
    });

    it('Opens plan preview, fills inputs and sends', () => {
        cy.contains("Plan all inputs").click();

        const inputText1 = "Text for text";
        const inputText2 = "Text for text area";
        const inputText3 = "Text for text on second page";
        const inputNumber1 = "14";
        const inputNumber2 = "15";
        const inputNumber3 = "16";
        const inputNumber4 = "17";
        cy.get("#input_plan_input_1").type(inputText1);
        cy.get("#input_plan_input_2").type(inputText2);
        cy.get("#input_plan_input_3").click();
        cy.get(".react-datepicker__month")
            .contains(inputNumber1)
            .click();

        cy.get(".athenaeum-date-range-input-input").click();
        cy.get(".athenaeum-date-range-input-dateRangeInputExpanded")
            .contains(inputNumber2)
            .click();
        cy.get(".athenaeum-date-range-input-dateRangeInputExpanded")
            .contains(inputNumber3)
            .click();

        cy.get("#input_plan_input_5").clear().type(inputNumber4);
        //Normal dropdown on first page
        CypressDropdownHelper.openAndSelectDropdownItemByIndex(".athenaeum-dropdown-dropdown", 0);

        cy.get("#plan_input_7")
            .find("i")
            .click();
        cy.get("#AddressInput_within_location_plan_input_8")
            .type("Kauppurienkatu 5, 90100 Oulu");
        cy.wait(1000);
        cy.get("#AddressInput_within_location_plan_input_8")
            .type("{downarrow}{enter}");
        cy.wait(500);

        cy.get('input[type=file]')
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_large.png',
                fileName: 'test_picture_large.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

        cy.wait(500);

        cy.get("#nextGroupButton").click();

        cy.get("#input_plan_input_10").type(inputText3);

        cy.get("#input_plan_input_10").click();
        //Customer selector on second page
        CypressDropdownHelper.openAndSelectDropdownItemByIndex(".athenaeum-dropdown-dropdown", 0);

        cy.intercept("POST", "/api/Plans/CreatePlan", (req) => {
            expect(req.body.inputGroups.length).to.equal(2);
            const group1 = req.body.inputGroups[0];
            const group2 = req.body.inputGroups[1];
            const customer: CompanyNameModel = req.body.customer;
            expect(group1.inputs.length).to.equal(9);
            expect(group2.inputs.length).to.equal(2);

            expect(group1.inputs[0].selectedValue).to.equal(inputText1);
            expect(group1.inputs[1].selectedValue).to.equal(inputText2);
            expect(group1.inputs[2].selectedValue).to.contains(inputNumber1);
            expect(group1.inputs[3].selectedValue).to.contains(inputNumber2).and.to.contains(inputNumber3);
            expect(group1.inputs[4].selectedValue).to.equal(inputNumber4);
            expect(group1.inputs[5].selectedValue).to.equal("Value 0");
            expect(group1.inputs[6].selectedValue).to.equal("true");
            expect(group1.inputs[7].selectedValue).to.contains("Kauppurienkatu 5");
            expect(group2.inputs[0].selectedValue).to.equal(inputText3);
            expect(group2.inputs[1].selectedValue).to.equal("28494");
            expect(customer.customerNumber).to.equal("28494");
            expect(customer.name).to.equal("3E BUILDING OY");
        }).as("createPlanRequest");

        cy.get("#submitPlanDefinitionButton").click();

        cy.wait("@createPlanRequest", {timeout: 30_000})
            .its('response.statusCode')
            .should('equal', 200);
    });

    it('Opens plan preview and checks that conditions work', () => {
        cy.contains("Plan conditions test").click();

        cy.get("#plan_input_5")
            .should('not.exist');

        cy.get("#plan_input_1")
            .find("i")
            .click();

        cy.get("#plan_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#plan_input_2", 0);

        cy.get("#plan_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#plan_input_3", 1);

        cy.get("#plan_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#plan_input_4", 0);

        cy.get("#plan_input_5")
            .should('be.visible');

        cy.get("#plan_input_1")
            .find("i")
            .click();

        cy.get("#plan_input_5")
            .should('not.exist');

        cy.get("#plan_input_1")
            .find("i")
            .click();

        cy.get("#plan_input_5")
            .should('be.visible');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#plan_input_3", 0);

        cy.get("#plan_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#plan_input_3", 1);

        cy.get("#plan_input_5")
            .should('be.visible');
    });
});