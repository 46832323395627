import React from 'react';
import styles from './DeviceBottomSheet.module.scss';
import Localizer from "@/localization/Localizer";
import {ButtonType} from "@renta-apps/renta-react-components";
import EasyPlusFeatureButton from "@/components/EasyPlus/EasyPlusFeatureButton/EasyPlusFeatureButton";

interface IDeviceBottomSheetProps {
    isVisible: boolean;
    onAdjustAlertsClick: () => void;
    onRequestReturnClick: () => void;
}

const DeviceBottomSheet: React.FC<IDeviceBottomSheetProps> = ({ onAdjustAlertsClick, onRequestReturnClick, isVisible }) => {
    const buttonSheetContainerStyle = {
        transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.3s ease-out',
    };

    return (
        <div id="deviceBottomSheet" className={styles.deviceBottomSheetContainer} style={buttonSheetContainerStyle}>
            <div>{Localizer.fleetMonitoringPageBottomSheetForSelected}</div>
            <EasyPlusFeatureButton type={ButtonType.Primary} onClick={() => onAdjustAlertsClick()}>
                {Localizer.fleetMonitoringPageBottomSheetAdjustAlerts}
            </EasyPlusFeatureButton>
            {/*Juhana: 'So we can't have the request return button in the selection overlay yet.*/}
            {/*To have that, we need to have the ability to return devices from multiple construction sites.'*/}
            {/*<FeatureSwitch flagName={RentaEasyConstants.featureFlagRentalReturnRequest}>*/}
            {/*    <button onClick={() => onRequestReturnClick()}>{Localizer.fleetMonitoringPageBottomSheetRequestReturn}</button>*/}
            {/*</FeatureSwitch>*/}
        </div>
    );
};

export default DeviceBottomSheet;