import React from "react";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import {PageContainer, PageHeader, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType} from "@renta-apps/athenaeum-react-components";
import OrdersGrid from "@/components/OrdersGrid/OrdersGrid";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import Localizer from "@/localization/Localizer";

/**
 * @deprecated
 */
export interface IOrdersLegacyParams extends BasePageParameters {

    /**
     * Used to tell whether {@link id} is a Contract or a Construction Site id.
     * @deprecated Use the specific {@link IOrdersParams.constructionSiteId} and {@link IOrdersParams.contractId} in new code.
     */
    handler?: "ByConstructionsiteId";

    /**
     * Id of the Contract or Construction Site which orders should be displayed.
     * @deprecated Use {@link IOrdersParams.constructionSiteId} and {@link IOrdersParams.contractId} in new code.
     */
    id?: string;
}

export interface IOrdersParams extends BasePageParameters, IOrdersLegacyParams {

    /**
     * Id of the Contract which orders to display.
     */
    contractId?: string;

    /**
     *  Id of the Construction Site which orders to displayed.
     */
    constructionSiteId?: string;
}

interface IOrdersState {
    contractId: string | null;
    constructionSiteId: string | null;
}

export default class Orders extends AuthorizedPage<IOrdersParams, IOrdersState> {

    public state: IOrdersState = {
        contractId: null,
        constructionSiteId: null
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const params: IOrdersParams | null = this.typedParameters;

        let contractId: string | undefined;

        let constructionSiteIdId: string | undefined;

        // Legacy link
        if (params?.id) {

            if (params.handler === "ByConstructionsiteId") {
                constructionSiteIdId = params.id;
            }
            else {
                contractId = params.id;
            }
        }
        else if (params?.constructionSiteId || params?.contractId) {

            contractId = params?.contractId;
            constructionSiteIdId = params?.constructionSiteId;
        }
        else {
            await this.toFrontPageAsync();
        }

        await this.setState({
            contractId: contractId ?? null,
            constructionSiteId: constructionSiteIdId ?? null,
        });
    }

    protected get title(): string {
        return Localizer.ordersPageOrders;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer hasWideHeader>
                <PageHeader wideHeader
                            wideHeaderBackgroundImage="/images/renta-kuva-6-scaled.jpg"
                            title={this.title}/>

                <TabContainer headerStyleType={TabContainerHeaderStyleType.Underline} renderType={TabRenderType.ActiveOnly}>
                    <Tab id="orders" title={this.title}>
                        <OrdersGrid noTitle
                                    contractId={this.state.contractId}
                                    constructionSiteId={this.state.constructionSiteId}
                        />
                    </Tab>
                    <Tab id="returns" title={Localizer.breadCrumbReturns}>
                        <OrdersGrid noTitle
                                    isReturns
                                    contractId={this.state.contractId}
                                    constructionSiteId={this.state.constructionSiteId}
                        />
                    </Tab>
                </TabContainer>
            </PageContainer>
        );
    }

}