import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import EnvironmentSensor from "@/models/server/EnvironmentSensor";
import {EnvironmentDeviceType} from "@/models/Enums";

const UNITS: { [id: string] : number; } = {
    day   : 24 * 60 * 60 * 1000,
    hour  : 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
};

const SENSORS_ORDER: number[] = [1, 0, 5, 3];

export default class EnvironmentControlHelper {
    static orderSensors(device: EnvironmentDevice, order: number[] = SENSORS_ORDER): EnvironmentSensor[] {
        if (device.type !== EnvironmentDeviceType.Structure) {
            return device.sensors;
        }

        return [...(device.sensors ?? [])].sort((a: EnvironmentSensor, b: EnvironmentSensor) => {
            return order.indexOf(a.id) - order.indexOf(b.id);
        });
    }
    
    static getSensorRefreshTime(sensor: EnvironmentSensor, language: string, style: Intl.RelativeTimeFormatStyle = "long"): string {
        if (!sensor.timestamp) {
            return '';
        }

        const rtf = new Intl.RelativeTimeFormat(language, { style });
        const date = new Date(sensor.timestamp);
        const now = new Date();
        const diff = date.getTime() - now.getTime();

        for (const u in UNITS) {
            // 1-179 minutes, 3-71 hours, 3... days
            if (u === 'second' || Math.abs(diff) >= (u === 'minute' ? UNITS[u] : UNITS[u] * 3)) {
                return rtf.format(Math.ceil(diff / UNITS[u]), u as Intl.RelativeTimeFormatUnit);
            }
        }

        return '';
    }
    
    static getSensorUnit(sensor: EnvironmentSensor): string {
        const unitOfMeasurement = sensor.physicalQuantity === 'particulates' ? 'particulates' : (sensor.unitOfMeasurement ?? '');

        return EnvironmentSensor.transFormUnit(unitOfMeasurement);
    };
}