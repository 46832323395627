import Localizer from "@/localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";

describe('Desktop - Fleet Monitoring - Devices grid tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should change URL when fleet monitoring button is clicked and render empty grid', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' });

        cy.visit('/');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');

        cy.url()
            .should('include', Localizer.pageRoutesFleetMonitoring);

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // The grid is empty with a message
        DeviceGridHelper.getDeviceGrid()
            .should('contain', Localizer.fleetMonitoringPageGridEmpty);
    });

    it('should render grid correctly when there are devices', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // The grid has a header row and 11 data rows.
        DeviceGridHelper.getDeviceGrid()
            .children('div')
            .should('have.length',11);

        // Check header titles.
        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 0, 1)
            .should('contain', Localizer.fleetMonitoringPageGridDeviceName);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 1)
            .should('contain', Localizer.fleetMonitoringPageGridId);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 2)
            .should('contain', Localizer.fleetMonitoringPageGridAlerts);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 3)
            .should('contain', Localizer.fleetMonitoringPageGridIdle);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 4)
            .should('contain', Localizer.fleetMonitoringPageGridBattery);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 5)
            .should('contain', Localizer.fleetMonitoringPageGridFluid);

        // Check few values in the grid.
        DeviceGridHelper.getDeviceGridContentByRowAndCell(1, 0, 0)
            .find('div[data-cy="tooltipWrapper"]')
            .should('not.exist');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(2, 0, 0)
            .find('div[data-cy="tooltipWrapper"]')
            .find('i.fas.fa-house')
            .should('exist');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(5, 0, 0)
            .find('div[data-cy="tooltipWrapper"]')
            .find('i.fas.fa-truck')
            .should('exist');

        DeviceGridHelper.getDeviceGridMeasurementValue(4, 'alerts')
            .should('contain', 20);

        DeviceGridHelper.getDeviceGridMeasurementValue(5, 'idle')
            .should('contain', '13d');

        // Check if there are three battery level rectangles.
        DeviceGridHelper.getDeviceGridMeasurementValue(6, 'battery')
            .children('div[class*="rectangleLevel"]')
            .should('have.length', 3);

        // Check if one of the three rectangles is filled.
        DeviceGridHelper.getDeviceGridMeasurementValue(6, 'battery')
            .children('div[class*="filled"]')
            .should('have.length', 1);

        // Check if device names are capitalized correctly.
        DeviceGridHelper.getDeviceGridContentByRowAndCell(1, 0, 1)
            .should('contain', 'Sähkötrukki Hyundai');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(7, 0, 1)
            .should('contain', 'Akkukuukulkija 13,0M Manitou 150 AETJ-C');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(8, 0, 1)
            .should('contain', 'Maantiivistäjä Wacker Dpu 6555 HE 495KG');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(10, 0, 1)
            .should('contain', '96840 Genie Z-60FE');
    });

    it('should display device bottom sheet when a checkbox is clicked', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Check if the bottom sheet is initially hidden
        DeviceGridHelper.getDeviceBottomSheet()
            .should('not.be.visible');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        // Check if the bottom sheet is now visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('be.visible');

        DeviceGridHelper.getDeviceBottomSheet()
            .children('div')
            .should('have.length', 1)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetForSelected);

        DeviceGridHelper.getDeviceBottomSheet()
            .find('button')
            .should('have.length', 1);

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts);

        // DeviceGridHelper.getDeviceBottomSheet()
        //     .children('button')
        //     .eq(1)
        //     .should('contain.text', Localizer.fleetMonitoringPageBottomSheetRequestReturn);

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        // Click on a checkbox and see if the bottom sheet is not visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('not.be.visible');
    });

    it('should select all/none of equipment with a single click on the header row', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Verify that the header checkbox is not in the checked state.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .verifyDataValue('false');

        // Click on all row checkboxes.
        DeviceGridHelper.getDeviceGridCheckbox(1)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(3)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(5)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(6)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(7)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(9)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(10)
            .click();

        // Verify that the header checkbox got checked state.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .verifyDataValue('true');

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(0)
            .verifyDataValue('false');

        // Check the header checkbox and verify if the one unchecked checkbox got checked.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .verifyDataValue('true');

        // Uncheck the header checkbox and verify if all row checkboxes got unchecked.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(1)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(2)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(3)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(5)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(6)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(7)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(9)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(10)
            .verifyDataValue('false');
    });

    it('should expand a row and display full additional data except return information', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', { fixture: 'getDeviceDetails.json' });

        cy.visit('/fleet-monitoring');

        const testedRow = 3;
        const date = new Date(2019, 10, 4);
        const alert1Date = new Date(2024, 8, 6);
        const alert2Date = new Date(2024, 8, 5);

        DeviceGridHelper.getDeviceGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.genericConstructionSite);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Lager');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.genericCompany);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Stoltz Entreprenør AS');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridRentalStartDate);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', date.toLocaleDateString());

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridActivityHours);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.get(Localizer.fleetMonitoringPageGridActivityHoursValue, 172));

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridProductGroup);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Lift');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridDepot);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Helsinki');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridOrderedBy);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Alexi Laiho');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnRequestedBy);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnRequestedAt);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridRequestedReturnTime);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnAddress);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnContactInformation);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridLatestAlerts);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.enumAlarmTypeTrackUnitBatteryVoltageLessThan);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.enumAlarmTypeTrackUnitMovementBasedTheft);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', alert1Date.toLocaleDateString([], {year: 'numeric', month: '2-digit', day: '2-digit'}));

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', alert2Date.toLocaleDateString([], {year: 'numeric', month: '2-digit', day: '2-digit'}));
    });

    it('should expand a row and display return information', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', { fixture: 'getDeviceDetails.json' });

        cy.visit('/fleet-monitoring');

        const testedRow = 5;
        const returnRequestedDate = new Date(2024, 9, 2);
        const requestedReturnDate = new Date(2024, 9, 3);

        DeviceGridHelper.getDeviceGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnRequestedAt);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', returnRequestedDate.toLocaleDateString([], {year: 'numeric', month: '2-digit', day: '2-digit'}));

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridRequestedReturnTime);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', requestedReturnDate.toLocaleDateString([], {year: 'numeric', month: '2-digit', day: '2-digit'}));

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnAddress);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Sir Matt Busby Way 1');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'M16 0RA');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Manchester');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnRequestedBy);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Sir Alex Ferguson');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'test1234@mail.com');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', '+12345678');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnRequestedOnBehalf);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Sir Bobby Charlton');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'test1243@mail.com');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', '+12348765');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnContactInformation);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'test4321@mail.com');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', '+87654321');
    });

    it('should expand a row and display empty additional data', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', { fixture: 'getDeviceDetailsEmpty.json' });

        cy.visit('/fleet-monitoring');

        const testedRow = 4;

        DeviceGridHelper.getDeviceGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridProductGroup);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridDepot);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.genericConstructionSite);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.genericCompany);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridRentalStartDate);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridActivityHours);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridOrderedBy);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnRequestedBy);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnRequestedAt);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridRequestedReturnTime);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnAddress);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnContactInformation);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridLatestAlerts);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .find('span:contains("-")')
            .should('have.length', 2);
    });

    it('should expand all/none rows with a single click on the header row', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Verify that the header arrow is directed down (some or none rows are expanded).
        DeviceGridHelper.getDeviceGridAngleIcon(0)
            .should('not.have.class', 'rotated');

        // Click on all arrows (expand all rows).
        DeviceGridHelper.getDeviceGridAngleIcon(1)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(2)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(3)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(4)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(5)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(6)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(7)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(8)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(9)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(10)
            .click();

        // Verify that the header arrow is directed up now (all rows are expanded).
        DeviceGridHelper.getDeviceGridAngleIcon(0)
            .invoke('attr', 'class')
            .then((classList) => {
                expect(classList).to.contain('rotated');
            });

        // Click the header arrow and verify if all arrows are down too (all rows are closed).
        DeviceGridHelper.getDeviceGridAngleIcon(0)
            .click();
    });
});