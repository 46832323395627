import React from "react";

import styles from "./NewsEditor.module.scss";
import ReactMarkdown from "react-markdown";
import Localizer from "@/localization/Localizer";

const NewsEditorHelp = () => {

    const exampleCode = "*italic*  \n**bold**  \n***bold italic***  \nsome `code` example  \n" +
        "***  \nuse double [space][space]  \nat the end of line  \nto add line break  \n***  \n" +
        "[Renta FI](https://www.rentaeasy.fi)  \n![Image](https://rentaeasy.fi/favicon.ico)";
    
    return (
        <div className={styles.helpContent}>
            <div>
                <div className={styles.helpContentRow}>
                    {Localizer.newsEditorHelpHeader}
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentCode}>
                        <pre>
                            {exampleCode}
                        </pre>
                    </div>
                    <div className={styles.helpContentRendered}>
                        <ReactMarkdown  >
                            {exampleCode}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsEditorHelp;