import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, ButtonType, Checkbox, Icon, LoadingDecorator, RentaPlusDecorator, RentaPlusDecoratorPosition, ValueIndicator} from "@renta-apps/renta-react-components";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import Localizer from "@/localization/Localizer";
import {DeviceReturnGridModel} from "./RequestReturnModal";
import styles from "./RequestReturnModal.module.scss";
import gridStyles from "../../DeviceGrid/DeviceGrid.module.scss";
import {capitalizeWords} from "@/helpers/StringHelper";
import {EditAmountOverlay} from "@/pages/FleetMonitoring/Modals/RequestReturnModal/EditAmountOverlay";

type DevicesTableProps = {
    isEasyPlusUser: boolean;
    devices: DeviceReturnGridModel[] | null;
    onCheckboxChange: (device: DeviceReturnGridModel, value: boolean) => void;
    onReturnAmountChange: (rentaId: string, number: number) => void;
    onHeaderCheckboxChange: (value: boolean) => void;
}

// cannot use the const exported from the component library, because of TS error 'TS1362: CHECKBOX_PARTIAL cannot be used as a value because it was exported using export type'
const CHECKBOX_PARTIAL = "partial";
const ROW_HEIGHT = 54;

const DevicesTable: React.FC<DevicesTableProps> = ({devices, isEasyPlusUser, onCheckboxChange, onReturnAmountChange, onHeaderCheckboxChange}) => {
    const [scrollBarWidth, setScrollBarWidth] = useState(0);
    const [overlayTop, setOverlayTop] = useState(0);
    const [editOverlayDevice, setEditOverlayDevice] = useState<DeviceReturnGridModel | null>(null);
    const _dataRowsRef = useRef<HTMLDivElement>(null);

    const headerCheckboxState = useMemo(() => {
        const selectedCount = devices?.filter(d => d.selected).length ?? 0;

        return selectedCount === 0 ? false : selectedCount === devices!.length ? true : CHECKBOX_PARTIAL;
    }, [devices]);

    useEffect(() => {
        if (_dataRowsRef.current) {
            // without the timeout, the div size is 0, don't know why
            setTimeout(() => {
                if (_dataRowsRef.current) {
                    setScrollBarWidth(_dataRowsRef.current.offsetWidth - _dataRowsRef.current.clientWidth);
                }
            }, 1);
        }
    }, [_dataRowsRef, devices]);

    const onEditAmountClick = async (index: number, device: DeviceReturnGridModel) => {
        if (_dataRowsRef.current) {
            // the top position of the overlay is calculated from the top of the header row,
            // and the scrollable area does not include the header row,
            // otherwise the top part of the overlay would be cut off for the first row
            const rowStart = index * ROW_HEIGHT; // +1 because of the header row
            const rowEnd = rowStart + ROW_HEIGHT;
            const {scrollHeight, scrollTop, clientHeight} = _dataRowsRef.current;
            let overlayTop = rowEnd - scrollTop - 10;

            // if the row is not fully visible, scroll to it
            if (scrollHeight > clientHeight && (rowEnd > (scrollTop + clientHeight) || rowStart < scrollTop)) {
                const scrollTo = rowStart < scrollTop ? rowStart : rowEnd - clientHeight;
                overlayTop -= (scrollTo - scrollTop);
                _dataRowsRef.current.scrollTo(0, scrollTo);
                // without the timeout, the overlay is shown before the scroll is finished, which hides the overlay immediately, because we hide overlay on scroll
                await new Promise(resolve => setTimeout(resolve, 5));
            }

            setOverlayTop(overlayTop);
        }

        setEditOverlayDevice(device);
    };

    const onEditAmountClose = (amount: number) => {
        onReturnAmountChange(editOverlayDevice!.rentaId, amount);
        setEditOverlayDevice(null);
    };

    return (
        <ContentBox title={Localizer.returnRequestModalDevicesHeader}
                    subTitle={Localizer.returnRequestModalDevicesSubheader}
                    className={styles.tableContainer}
        >
            <div className={`${gridStyles.dataTable} ${styles.dataTable} ${headerCheckboxState === false ? styles.error : ""}`}
                 style={{'--scrollbar-width': `${scrollBarWidth}px`} as React.CSSProperties}
            >
                <div className={`${gridStyles.gridRow} ${gridStyles.dataRow} ${gridStyles.header} ${styles.gridRow} ${styles.header}`}>
                    <div className={styles.checkboxCell} data-cy="return-modal-header-checkbox">
                        <Checkbox checked={headerCheckboxState}
                                  onChange={onHeaderCheckboxChange}
                        />
                    </div>
                    <div>{Localizer.fleetMonitoringPageGridDeviceName}</div>
                    <div>{Localizer.fleetMonitoringPageGridId}</div>
                    <div>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridIdle}
                        </RentaPlusDecorator>
                    </div>
                    <div>{Localizer.returnRequestModalDevicesTableAmount}</div>
                </div>
                {[...Array(4)].map((_, index) => (
                    <LoadingDecorator key={index} className={gridStyles.loaderContainer} hideConentOnLoading={false} isLoading={devices === null} />
                ))}
                <div ref={_dataRowsRef} className={styles.dataRows} onScroll={() => setEditOverlayDevice(null)}>
                    {devices?.map((device, index) => (
                        <div key={index} className={`${gridStyles.gridRow} ${gridStyles.dataRow} ${styles.gridRow} ${index % 2 === 0 ? "" : gridStyles.odd}`}>
                            <div className={styles.checkboxCell} data-cy={`return-modal-checkbox-row-${index}`}>
                                <Checkbox checked={device.selected}
                                          onChange={(value: boolean) => onCheckboxChange(device, value)}
                                />
                            </div>
                            <div className={gridStyles.ellipsisText}>{device.deviceName ? capitalizeWords(device.deviceName) : '-'}</div>
                            <div data-cy={`return-modal-renta-id-row-${index}`}>{device.rentaId}</div>
                            <div className={styles.idleValue}>
                                <ValueIndicator size="extra-small" type="idle" value={isEasyPlusUser ? device.idleDays : null}/>
                            </div>
                            <div data-cy={`return-modal-amount-row-${index}`} className={styles.amountValue}>
                                <Button onClick={() => onEditAmountClick(index, device)}
                                        className={styles.editAmountButton}
                                        type={ButtonType.Link}
                                        disabled={!device.selected || device.rentedAmount === 1}
                                >
                                    <Icon name="fa-pen-to-square" size={20}/>
                                </Button>
                                {device.returnAmount}/{device.rentedAmount}
                            </div>
                        </div>
                    ))}
                </div>
                {editOverlayDevice !== null && (
                    <EditAmountOverlay amount={editOverlayDevice.returnAmount}
                                       maxAmount={editOverlayDevice.rentedAmount}
                                       onClose={onEditAmountClose}
                                       topPosition={overlayTop}
                    />
                )}
            </div>
        </ContentBox>
    );
};

export default DevicesTable;