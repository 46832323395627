import React from "react";
import styles from "./ProductAvailabilityIcon.module.scss";

export enum ProductAvailabilityIconType {
    Available = "Available",
    Orderable = "Orderable",
    Maintenance = "Maintenance",
    ReturnInspection = "ReturnInspection",
    Unavailable = "Unavailable"
}

export const ProductAvailabilityIcon = ({type, count}: { type: ProductAvailabilityIconType, count: number | null }): JSX.Element => {

    const isCircle = count === null || count < 10;
    const circleClassName = isCircle ? "rounded-circle" : "";
    const classNames = ["d-flex justify-content-center align-items-center text-white", circleClassName, styles.ProductAvailabilityIcon].join(' ');
    return (
        <div style={styles}
             className={classNames}
             data-cy={`availability-container-${type}`}
             data-product-availability-type={type}
             data-is-circle={String(isCircle)}
        >{count}</div>
    );
};
