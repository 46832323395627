import Localizer from "../../../localization/Localizer";
import { executeWithIntercept, pageData, RouteData } from "@/helpers/CypressHelper";

context("Desktop - Authenticated Invite User tests", () => {

    const randomHash = Date.now().getHashCode().toString();
    const firstName = 'Tom';
    const lastName = 'Thompson';
    const email = `${randomHash}@renta.com`;
    const phone = '+442012345678';

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        executeWithIntercept(() => cy.visit(`${Localizer.pageRoutesInviteUser}?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409`),
            [pageData().inviteUser.routes.getInvitationPageData as RouteData]
        );
    });

    it('As admin go to invite user page, click the add button without any data', () => {

        addUserToListAndAssert(0);

        cy.get('.alert-warning')
            .should('contain', Localizer.inviteUserInfoMissing);
    });

    it('As admin go to invite user page, try to invite a user with incorrect email address ', () => {

        fillInvitationData(firstName, lastName, 'tom@renta', phone);

        addUserToListAndAssert(0);
    });

    it('As admin go to invite user page, try to invite a user with incorrect phone number ', () => {

        fillInvitationData(firstName, lastName, email, '446324141232');

        addUserToListAndAssert(0);
    });

    it('As admin go to invite user page, try to invite a user with correct data ', () => {

        fillInvitationData(firstName, lastName, email, phone);

        addUserToListAndAssert(1);

        pageData().inviteUser.userList.rows()
            .find('div')
            .contains(`${firstName} ${lastName}`)

        pageData().inviteUser.userList.rows()
            .find('div')
            .contains(email)

        pageData().inviteUser.userList.rows()
            .find('i.fa-times-circle')
            .should('exist');

        pageData().inviteUser.buttons.addUserButton()
            .should('exist')
            .trigger('click');

        cy.get('.alert-success')
            .should('contain', Localizer.get(Localizer.inviteUserInvitationSent, email));
    });

    function addUserToListAndAssert(expectedUsers: number) {

        pageData().inviteUser.buttons.addUserToListButton()
            .should('exist')
            .trigger('click');

        pageData().inviteUser.userList.rows()
            .should('have.length', expectedUsers);
    }

    function fillInvitationData(firstName: string, lastName: string, emailAddress: string, phoneNumber: string) {

        pageData().inviteUser.inputs.firstname()
            .type(firstName);

        pageData().inviteUser.inputs.lastname()
            .type(lastName);

        pageData().inviteUser.inputs.email()
            .type(emailAddress);

        pageData().inviteUser.inputs.telephone()
            .type(phoneNumber);
    }

});