import {Link} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "@/pages/ShoppingCart/ShoppingCartEmpty/ShoppingCartEmpty.module.scss";
import Localizer from "@/localization/Localizer";

const ShoppingCartEmpty = () => {
    
    return (
        <div className={styles.pageContainer}>
            <p>{Localizer.shoppingCartPageEmptyNoProducts}</p>
            <p>
                <Link route={PageDefinitions.rent.route()}>{Localizer.genericClickHere}</Link>
                &nbsp;{Localizer.shoppingCartPageEmptyNavigateToCatalog}
            </p>
        </div>
    );
}

export default ShoppingCartEmpty;