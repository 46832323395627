import React from "react";
import {ArrayUtility, IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, Form, Icon, IconSize, OneColumn, PageContainer, Pagination, SelectListItem, Spinner, TextInput} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {InvoiceModel} from "@/models/server/InvoiceModel";
import Localizer from "@/localization/Localizer";

import styles from "./Invoices.module.scss";
import InvoiceToolbarModel from "@/pages/ConstructionSiteDetails/InvoiceToolbar/InvoiceToolbarModel";
import InvoiceToolbar from "@/pages/ConstructionSiteDetails/InvoiceToolbar/InvoiceToolbar";
import InvoiceRow from "@/pages/ConstructionSiteDetails/Invoices/InvoiceRow/InvoiceRow";
import {InvoiceType} from "@/models/Enums";
import LoadingOrDataNotFound from "@/components/LoadingOrDataNotFound/LoadingOrDataNotFound";

export interface IInvoiceFiltersData {
    contracts: SelectListItem[];
    invoiceTypes: SelectListItem[];
}

interface IInvoicePageData {
    constructionSiteId: string;
    invoices: InvoiceModel[];
    invoiceFiltersData?: IInvoiceFiltersData;
    supportAttachment: boolean;
}

interface IInvoicesProps {
    constructionSiteId: string;
}

interface IInvoicesState {
    constructionSiteId: string;
    model: InvoiceToolbarModel;
    invoices: InvoiceModel[];
    filteredInvoices: InvoiceModel[];
    invoiceFiltersData: IInvoiceFiltersData | null;
    isLoading: boolean;
    supportAttachment: boolean;
    pageNumber: number;
    pageSize: number;
    invoiceRowsToShow: IPagedList<InvoiceModel> | null;
}

class Invoices extends BaseComponent<IInvoicesProps, IInvoicesState> {

    public state: IInvoicesState = {
        constructionSiteId: "",
        model: new InvoiceToolbarModel(),
        invoices: [],
        filteredInvoices: [],
        invoiceFiltersData: null,
        isLoading: true,
        supportAttachment: false,
        pageNumber: 1,
        pageSize: 10,
        invoiceRowsToShow: null
    };

    private get pageNumber(): number {
        return this.state.pageNumber;
    }

    private get pageSize(): number {
        return this.state.pageSize;
    }

    private get invoiceRowsToShow(): IPagedList<InvoiceModel> {
        return this.state.invoiceRowsToShow!;
    }

    private async changePageAsync(pageNumber: number, pageSize: number): Promise<void> {

        await this.setState({pageSize: pageSize, pageNumber: pageNumber});

        await this.initializeAsync();
    }

    private async getInvoicesAsync(): Promise<IInvoicePageData> {
        const data: IInvoicePageData = await this.postAsync("/api/ConstructionSites/GetInvoices", this.props.constructionSiteId);
        Utility.restoreDate(data);
        return data;
    }

    private createInvoiceFilters(invoices: InvoiceModel[]): IInvoiceFiltersData {
        const contractNumberFilter: SelectListItem[] = invoices
            .selectMany(row => row.rows)
            .distinct(item => item.leaseNumber)
            .map(item => item.leaseNumber)
            .map(item => {
                const listItem: SelectListItem = new SelectListItem();
                listItem.text = item;
                listItem.value = item;
                return listItem;
            });

        const invoiceTypeFilters: SelectListItem[] = [];

        const listItem: SelectListItem = new SelectListItem();
        listItem.text = Localizer.constructionSiteDetailsInvoiceTypeFilterNormalOption;
        listItem.value = InvoiceType.Normal.toString();

        invoiceTypeFilters.push(listItem);

        const creditListItem: SelectListItem = new SelectListItem();
        creditListItem.text = Localizer.constructionSiteDetailsInvoiceTypeFilterCreditOption;
        creditListItem.value = InvoiceType.Credit.toString();

        invoiceTypeFilters.push(creditListItem);

        return {
            contracts: contractNumberFilter,
            invoiceTypes: invoiceTypeFilters,
        } as IInvoiceFiltersData;
    }

    private get supportAttachments(): boolean {
        return this.state.supportAttachment;
    }

    private get invoiceFiltersData(): IInvoiceFiltersData | null {
        return this.state.invoiceFiltersData;
    }

    private async sortByDateAsync(): Promise<void> {
        this.filters.sortByDateAsc = !this.filters.sortByDateAsc;
        await this.filterInvoicesAsync(this.filters);
    }

    private async setSearchQueryAsync(value: string): Promise<void> {
        if (this.filters.searchQuery !== value) {
            this.filters.searchQuery = value;
            await this.filterInvoicesAsync(this.filters);
        }
    }

    private async filterInvoicesAsync(model: InvoiceToolbarModel): Promise<void> {
        const filteredInvoices: InvoiceModel[] = this.getFilteredInvoices(this.state.invoices, model);
        await this.setState({filteredInvoices, model});
    }

    private async clearFiltersAsync(): Promise<void> {
        let filters = this.filters;
        filters.clear();
        let invoiceFilters = this.invoiceFiltersData;
        invoiceFilters?.invoiceTypes.forEach(invoiceType => invoiceType.selected = false);
        await this.setState({model: filters, invoiceFiltersData: invoiceFilters});
        await this.reRenderAsync();
        await this.filterInvoicesAsync(this.filters);
    }

    private get filters(): InvoiceToolbarModel {
        return this.state.model;
    }

    private get filtersApplied(): boolean {
        return this.filters.contracts.length > 0
            || this.filters.invoiceType !== null
            || this.filters.invoiceStartDate !== null
            || this.filters.invoiceEndDate !== null
            || this.filters.searchQuery.length > 0;
    }

    private getFilteredInvoices(invoices: InvoiceModel[], filters: InvoiceToolbarModel): InvoiceModel[] {
        let filteredInvoices = invoices;

        if (filters.searchQuery) {
            filteredInvoices = filteredInvoices
                .filter(item => Object.values(item)
                    .some(value => value.toString().toLowerCase().includes(filters.searchQuery.toLowerCase())));
        }

        if (filters.contracts.length) {
            filteredInvoices = filteredInvoices.filter(item => filters.contracts
                .some(contract => item.rows
                    .some(leaseNumber => leaseNumber.leaseNumber === contract)));
        }

        if (filters.invoiceType !== null) {
            filteredInvoices = filteredInvoices.filter(item => item.invoiceType === filters.invoiceType);
        }

        if (filters.invoiceStartDate !== null) {
            filteredInvoices = filteredInvoices.filter(item => item.invoiceDate >= filters.invoiceStartDate!);
        }

        if (filters.invoiceEndDate !== null) {
            filteredInvoices = filteredInvoices.filter(item => item.invoiceDate <= filters.invoiceEndDate!);
        }

        const sortDirection: SortDirection = (filters.sortByDateAsc) ? SortDirection.Asc : SortDirection.Desc;

        filteredInvoices = filteredInvoices.sort(ArrayUtility.sortByProperty("invoiceDate", sortDirection));

        const pagedInvoices = filteredInvoices.toPagedList(this.pageNumber, this.pageSize);
        filteredInvoices = pagedInvoices.items;

        this.setState({invoiceRowsToShow: pagedInvoices});

        return filteredInvoices;
    }

    public async initializeAsync(): Promise<void> {
        const invoicesPageData: IInvoicePageData = await this.getInvoicesAsync();
        const invoices: InvoiceModel[] = invoicesPageData.invoices;
        const supportAttachment: boolean = invoicesPageData.supportAttachment;
        const invoiceFiltersData: IInvoiceFiltersData = this.createInvoiceFilters(invoices);
        const constructionSiteId: string = invoicesPageData.constructionSiteId;
        const filteredInvoices: InvoiceModel[] = this.getFilteredInvoices(invoices, this.state.model);

        await this.setState({invoices, filteredInvoices, invoiceFiltersData, isLoading: false, supportAttachment, constructionSiteId});
    }

    render(): React.ReactNode {
        return (
            <PageContainer className={styles.invoices}>

                {
                    this.state.isLoading && <Spinner global/>
                }

                <InvoiceToolbar model={this.state.model}
                                invoiceFiltersData={this.invoiceFiltersData || undefined}
                                onChange={async (model: InvoiceToolbarModel) => await this.filterInvoicesAsync(model)}
                />

                <Form id={"filtersContainer"} className={styles.filtersContainer}>

                    <div id={"filters"} className={styles.filters}>

                        <TextInput id={"invoices_search_input"}
                                   label={`${Localizer.searchWordText}:`}
                                   className={this.css(styles.search)}
                                   value={this.state.model.searchQuery}
                                   onChange={async (_, value: string) => await this.setSearchQueryAsync(value)}/>

                        <div className={styles.sortButtonWrapper}>

                            <Button id={"invoices_sort_button"}
                                    className={this.css(styles.sortButton, (this.mobile) && styles.mobileButtons)}
                                    icon={{name: "calendar", size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    title={Localizer.constructionSiteDetailsInvoicesSortByDate}
                                    onClick={async () => await this.sortByDateAsync()}
                            />

                            <Icon className={this.css(styles.sortButtonIcon, this.filters.sortByDateAsc && styles.sortButtonIconRotate)} name="long-arrow-up"/>

                        </div>

                        {
                            (this.filtersApplied) &&
                            (
                                <Button className={styles.clearFilters}
                                        id="invoices_clear_filters_button"
                                        type={ButtonType.Orange}
                                        label={Localizer.constructionSiteDetailsClearFilters}
                                        onClick={async () => await this.clearFiltersAsync()}/>
                            )
                        }

                    </div>

                </Form>

                {
                    (this.state.filteredInvoices.length > 0)
                        ?
                        (
                            <OneColumn id="all_invoice_rows">
                                {
                                    this.state.filteredInvoices.map((invoice, index: number) =>
                                        <InvoiceRow key={`invoiceRow_${index}`}
                                                    item={invoice}
                                                    constructionSiteId={this.state.constructionSiteId}
                                                    supportAttachment={this.supportAttachments}/>)
                                }
                                {
                                    (this.invoiceRowsToShow.items.length > 0) && (
                                        <Pagination
                                            pageSize={this.pageSize}
                                            itemCount={this.invoiceRowsToShow.items.length}
                                            totalItemCount={this.invoiceRowsToShow.totalItemCount}
                                            pageNumber={this.pageNumber}
                                            onChange={async (sender, pageNumber, pageSize) => await this.changePageAsync(pageNumber, pageSize)}
                                        />
                                    )
                                }
                            </OneColumn>
                        )
                        : <div className={styles.noInvoicesText}>
                            <LoadingOrDataNotFound isLoading={this.state.isLoading}
                                                   noDataText={Localizer.constructionSiteDetailsInvoicesNotFound}
                            />
                        </div>
                }

            </PageContainer>
        );
    }
}

export default Invoices;