import React from "react";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {CellAction, CellModel, ColumnDefinition, ColumnType, Grid, Icon, IconSize, ImageModal, PageContainer} from "@renta-apps/athenaeum-react-components";
import {ActionType, AlertModel, BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import ConstructionSiteServiceRequestModel from "@/models/server/ConstructionSiteServiceRequestModel";
import Localizer from "@/localization/Localizer";
import ToolbarModel from "@/models/ToolbarModel";
import GetServicesRequest from "@/models/server/GetServicesRequest";
import UserContext from "@/models/server/UserContext";
import styles from "@/pages/AdminConstructionSitesOverview/ConstructionSitesGrid/ConstructionSiteGrid.module.scss";
import FileApiModel from "@/models/server/FileApiModel";
import FullScreenImageGallery from "@/components/FullScreenImageGallery/FullScreenImageGallery";

interface IConstructionSiteServicesProps {
    constructionSiteId: string,
    _servicesGrid: React.RefObject<Grid<ConstructionSiteServiceRequestModel>>;
}

interface IConstructionSiteServicesState {
    constructionSiteId: string;
    isLoading: boolean;
    alertModel: AlertModel | null;
    fullScreenImages: FileApiModel[];
}

class ConstructionSiteServices extends BaseComponent<IConstructionSiteServicesProps, IConstructionSiteServicesState> {

    public state: IConstructionSiteServicesState = {
        constructionSiteId: "",
        isLoading: true,
        alertModel: null,
        fullScreenImages: [],
    };

    private readonly _previewServiceRequestPictureRef: React.RefObject<ImageModal> = React.createRef();

    private readonly _servicesColumns: ColumnDefinition[] = [
        {
            header: Localizer.servicesGridServiceTypeHeaderLanguageItemName,
            accessor: "serviceType",
            editable: false,
            type: ColumnType.Enum,
            format: "ServiceType",
            sorting: true,
            init: (cell) => this.initServiceColumn(cell)
        },
        {
            header: Localizer.servicesGridDeviceHeaderLanguageItemName,
            accessor: "deviceName",
            editable: false,
            init: (cell) => this.initServiceColumn(cell)
        },
        {
            header: Localizer.servicesGridDeviceNumberHeaderLanguageItemName,
            accessor: "deviceExternalId",
            editable: false,
            init: (cell) => this.initServiceColumn(cell)
        },
        {
            header: Localizer.servicesGridOrdererLanguageItemName,
            accessor: "ordererEmail",
            editable: false,
            init: (cell) => this.initServiceColumn(cell)
        },
        {
            header: Localizer.servicesGridDateHeaderLanguageItemName,
            accessor: "date",
            editable: false,
            sorting: true,
            type: ColumnType.Date,
            format: "D",
            init: (cell) => this.initServiceColumn(cell),
        },
        {
            header: Localizer.servicesGridStatusLanguageItemName,
            accessor: "status",
            type: ColumnType.Enum,
            sorting: true,
            format: "ServiceRequestStatus",
            init: (cell) => this.initServiceColumn(cell),
            editable: false
        },
        {
            header: Localizer.servicesGridAttachedImageLanguageItemName,
            type: ColumnType.Icon,
            actions: [
                {
                    name: "showImage",
                    type: ActionType.Info,
                    render: (cell: CellModel<ConstructionSiteServiceRequestModel>, action: CellAction<ConstructionSiteServiceRequestModel>) => this.renderImageIcon(cell, action)
                }
            ]
        },
        {
            minWidth: 50,
            init: (cell) => this.initActions(cell),
            actions: [
                {
                    name: "delete",
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    callback: async (cell) => await this.deleteServicePreviewAsync(cell)
                }
            ]
        }
    ];

    // Getters
    private get alertModel(): AlertModel | null {
        return this.state.alertModel;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private initActions(cell: CellModel<ConstructionSiteServiceRequestModel>) {
        const deleteAction: CellAction<ConstructionSiteServiceRequestModel> = cell.actions[0];

        deleteAction.visible = (this.userContext.isAdmin);
    }

    private async deleteServicePreviewAsync(cell: CellModel<ConstructionSiteServiceRequestModel>) {
        await this.postAsync("/api/Services/DeleteServiceRequests", cell.model.id);

        await this.props._servicesGrid.current!.rows.remove(cell.row);

        await this.props._servicesGrid.current!.reloadAsync();
    }

    private initServiceColumn(cell: CellModel<ConstructionSiteServiceRequestModel>): void {
        cell.readonly = true;
    }

    private async getServicesAsync(filters: ToolbarModel | null, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null)
        : Promise<IPagedList<ConstructionSiteServiceRequestModel>> {

        let request: GetServicesRequest = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            organizationContractId: null,
            constructionSiteId: this.props.constructionSiteId,
            status: filters ? filters!.status : null,
            userId: null,
            sortDirection: sortDirection,
            sortColumnName: sortColumnName,
            from: null,
            to: null
        };

        return await this.postAsync("/api/Services/GetServices", request);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.setState({isLoading: false});
    }

    private renderImageIcon(cell: CellModel<ConstructionSiteServiceRequestModel>, action: CellAction<ConstructionSiteServiceRequestModel>): JSX.Element {
        return <Icon key={`${this.id}_previewIcon`} name={cell.model.images?.length ? "fa-solid fa-image" : ""} size={IconSize.Large} className={styles.iconSize}
                     onClick={async () => await this.setFullScreenImagesAsync(cell.model.images ?? [])}
        />;
    }

    private async setFullScreenImagesAsync(images: FileApiModel[]): Promise<void> {
        await this.setState({
            fullScreenImages: images,
        });
    }

    private get displayingFullScreenImages(): boolean {
        return (this.state.fullScreenImages.length > 0);
    }

    private renderFullScreenImagesCarousel(): React.ReactNode {
        if (!this.displayingFullScreenImages) {
            return;
        }
        return (
            <FullScreenImageGallery
                images={this.state.fullScreenImages}
                imagesIndex={0}
                onClose={async () => await this.setFullScreenImagesAsync([])}
            />
        );
    }

    render(): React.ReactNode {
        return (
            <PageContainer>
                <Grid responsive
                      pagination
                      version2Styles
                      ref={this.props._servicesGrid}
                      columns={this._servicesColumns}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.getServicesAsync(null, pageNumber, pageSize, sortColumnName, sortDirection)}
                      noDataText={Localizer.servicesGridNoData}
                />

                {
                    this.renderFullScreenImagesCarousel()
                }
            </PageContainer>
        );
    }
}

export default ConstructionSiteServices;