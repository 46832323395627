import React from "react";
import {Link} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, PageRoute} from "@renta-apps/athenaeum-react-common";
import ProductModel from "@/models/server/ProductModel";
import ImageProvider from "@/providers/ImageProvider";
import Prices from "@/components/Prices/Prices";
import {assert} from "@renta-apps/athenaeum-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import PriceHelper, {ProductPriceType} from "@/helpers/PriceHelper";
import ProductVatButtons from "@/components/ProductVatButtons/ProductVatButtons";
import { ProductType } from "@/models/Enums";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";
import styles from "@/components/AttachedProducts/AttachedProductCard.module.scss";
import Localizer from "@/localization/Localizer";

interface IAttachedProductCardState {
    vat: number;
}

interface IAttachedProductCardProps {
    product: ProductModel;
    className?: string;
    mainProductCount: number;
}

export default class AttachedProductCard extends BaseComponent<IAttachedProductCardProps, IAttachedProductCardState> {
    public state: IAttachedProductCardState = {
        vat: PriceHelper.userVat,
    };

    private authenticatedUsersOnly = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceForAuthenticatedUsersOnly);

    // Getters
    private get product(): ProductModel {
        return this.props.product;
    }

    private get vat(): number {
        return this.state.vat;
    }

    private get productName(): string {
        return assert(this.product.name, "productName").isString.isNotEmpty.isNotWhitespace.getValue;
    }

    private get productPageLink(): PageRoute {
        return PageDefinitions.productDetails.route({id: this.product.url!});
    }

    private get hasDiscountedPrices(): boolean {
        return (this.product.productType === ProductType.Rental && this.product.customerDailyPrice !== undefined && this.product.customerDailyPrice !== null) ||
            (this.product.productType === ProductType.Sales && this.product.customerUnitPrice !== undefined && this.product.customerUnitPrice !== null);
    }

    public render(): React.ReactNode {
        const total = this.product.productType === ProductType.Rental ?
            PriceHelper.sumAttached([this.props.product], this.vat, this.props.mainProductCount, ProductPriceType.Daily, null) :
            PriceHelper.sumAttached([this.props.product], this.vat, this.props.mainProductCount, ProductPriceType.Unit, null);

        return (
            <div className={styles.productCard} id="attached_product_card_root" data-cy={`${this.product.externalId}`}>
                <div className={styles.mainContainer}>
                    <Link route={this.productPageLink} className={styles.productName}>
                        <h3>
                            {this.productName}
                        </h3>
                    </Link>

                    <Link className={styles.imageContainer}
                          route={this.productPageLink}
                    >
                        <img className={styles.productImage}
                             src={ImageProvider.getProductThumbnailSrc(this.product)}
                             alt={""}
                             onError={ImageProvider.onError()}
                        />
                    </Link>
                    
                    <div className={styles.productPricesContainer}>
                        <Prices className={styles.productPrices}
                                inlinePriceRows={true}
                                product={this.product}
                                vat={this.vat}
                                maxRows={1}
                        />
                        <ProductVatButtons
                            environmentVatValue={PriceHelper.environmentVat}
                            isZeroVatSelected={this.vat === 0}
                            isEnvVatSelected={this.vat === PriceHelper.environmentVat}
                            disableEnvVatButton={this.hasDiscountedPrices}
                            onVatChangeAsync={async value => this.setState({ vat: value })}
                        />
                    </div>
                    <div className={styles.attachedSummary}>
                        <div>
                            {Localizer.attachedProductsProductAmount}: {this.product.quantity! * this.props.mainProductCount}
                        </div>
                        <div>
                            {Localizer.attachedProductsProductCost}:
                        </div>
                        <div>
                            {(!this.isAuthenticated && this.authenticatedUsersOnly) &&
                                <div>{Localizer.productDetailsPriceForAuthenticatedUsersOnly}</div>
                            }
                            {(this.isAuthenticated || !this.authenticatedUsersOnly) &&
                                <>
                                    {
                                        this.product.productType === ProductType.Rental ?
                                            (
                                                <>
                                                    <span className={styles.totalPrice}>
                                                         {PriceHelper.toLocalizedValue({ value: total, type: ProductPriceType.Daily, rentbasis: null })}
                                                    </span> / {PriceHelper.units.daily(this.vat)}
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <span className={styles.totalPrice}>
                                                         {PriceHelper.toLocalizedValue({ value: total, type: ProductPriceType.Unit, rentbasis: null })}
                                                    </span> ({PriceHelper.getVatString(this.vat)})
                                                </>
                                            )
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}