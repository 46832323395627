import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Desktop - Private user product search tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);

        cy.visit('frontpage');
    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    function searchProductAndValidateUrl(searchTerm: string, productId: string) {
        pageData().rent.productLink(productId).click();

        cy.go('back');

        cy.url().should('contain', `keyword=${searchTerm}`);

        cy.get(`#product_${productId}`);
    }

    const searchTerm: string = 'nivelpuominostin';
    const productId: string = '83a700be-2e3b-45cc-ac8a-570a551760d4';

    it('Navigates to product page by catalog search, goes back and validates search was retained', () => {

        onRedirect(() =>
            pageData().rent.visitWithIntercept()
        );

        cy.assertBreadCrumbLength(2);

        executeWithIntercept(() => pageData().rent.searchInput().type(`${searchTerm}{enter}`),
            [pageData().rent.routes.categoriesData as RouteData]);

        searchProductAndValidateUrl(searchTerm, productId);
    });

    it('Navigates to product page by top nav search, opens products one by one', () => {

        pageData().common.topNavSearchButton().click();

        pageData().common.topNavSearchInput().type(`${searchTerm}{enter}`);

        cy.get('.athenaeum-global-search-results-globalSearchResultsProvider:nth-child(1) .athenaeum-global-search-results-item-globaSearchResultsItem:nth-child(1) > div').should('contain.text', searchTerm);
        cy.get('.athenaeum-global-search-results-globalSearchResultsProvider:nth-child(1) .athenaeum-global-search-results-item-globaSearchResultsItem:nth-child(2) > div').should('contain.text', searchTerm);
        cy.get('.athenaeum-global-search-results-globalSearchResultsProvider:nth-child(1) .athenaeum-global-search-results-item-globaSearchResultsItem:nth-child(3) > div').should('contain.text', searchTerm);

        cy.get('.athenaeum-global-search-results-globalSearchResultsProvider:nth-child(1) .athenaeum-global-search-results-item-globaSearchResultsItem:nth-child(1)').click();
        cy.url().should('contain', searchTerm);

        cy.get('.athenaeum-global-search-results-globalSearchResultsProvider:nth-child(1) .athenaeum-global-search-results-item-globaSearchResultsItem:nth-child(2)').click();
        cy.url().should('contain', searchTerm);

        cy.get('.athenaeum-global-search-results-globalSearchResultsProvider:nth-child(1) .athenaeum-global-search-results-item-globaSearchResultsItem:nth-child(3)').click();
        cy.url().should('contain', searchTerm);
    });

});