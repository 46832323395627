import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";

describe("Desktop - Shopping cart tests", () => {
    const postalCode: string = "01370";
    const address: string = "Rillitie 8";
    const city: string = "Vantaa";
    
    it("Adds product to cart and send order with immutable details", () => {
        arrangeUserContext("Urho.Kekkonen@weare.fi", "tmi jukka wilska");

        addProductToCart();
        assertProductConfirmationModal();
        fillOrder();
        sendOrder();
        assertOrderDetails();
    });
    
    it("Presents subCompanies, when the chosen organization is on level < 2", () => {
        arrangeUserContext("org.main@domain.fi", "Unit test2 Oy");

        addProductToCart();
        assertProductConfirmationModal();
        redirectToShoppingCart();
        
        // verify whether subcompanies do exist
        pageData().rent.shoppingCart.subCompanyDropDown().should("exist");
        
        // select different contract (from lvl 2)
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#company-selection", 2);
        
        // verify whether subcompanies do not exist
        pageData().rent.shoppingCart.subCompanyDropDown().should("not.exist");
    });
    
    function arrangeUserContext(email: string, company: string): void {
        cy.session(["company_role_session" + company], () => {
            cy.loginAndSelectCompanyRole(email, company);
        });
        cy.init("Desktop");
        pageData().rent.visitWithIntercept();
    }
    
    function assertProductConfirmationModal() {
        pageData().rent.confirmationModal.goToCartButton().should("not.be.disabled");
        pageData().rent.confirmationModal.goToCartButton().should("contain", Localizer.productModalGoToCart);
        pageData().rent.confirmationModal.goToCartButton().should("have.class", "athenaeum-button-color_orange");
        pageData().rent.confirmationModal.continueShoppingButton().should("contain", Localizer.productModalContinueShopping);
    }

    function sendOrder() {
        executeWithIntercept(() =>
                pageData().shoppingCart.confirmationDialog()
                    .click(),
            [pageData().shoppingCart.routes.sendOrder as RouteData]);
    }

    function assertOrderDetails() {
        cy.get("h3").should("contain", Localizer.orderInfoText);
        cy.get(".athenaeum-alert-message").contains(Localizer.shoppingCartSentShort);
        cy.get("#order_details_address").should("have.text", address);
        cy.get("#order_details_city").should("have.text", city);
        cy.get("#order_details_postalCode").should("have.text", postalCode);
    }
    
    function redirectToShoppingCart(): void {
        onRedirect(() => executeWithIntercept(() => cy.visit("/ShoppingCart"),
            [
                pageData().common.routes.depots as RouteData,
                pageData().constructionSites.routes.sitesForContract as RouteData
            ]));

        cy.wait(2000);
    }

    function fillOrder() {
        redirectToShoppingCart();

        pageData().shoppingCart.address().focus().type(address);
        pageData().shoppingCart.postalCode().focus().type(postalCode);
        pageData().shoppingCart.city().focus().type(city);

        // Select the first available delivery type
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#shopping_cart_dropdown_delivery_type", 0);

        // Select first available day
        pageData().shoppingCart.dateRangeInput()
            .click()
            .find(".athenaeum-date-range-input-isToday")
            .then(($btn: any) => {
                if ($btn.hasClass('athenaeum-date-range-input-gridDayDisabled')) {
                    pageData().shoppingCart.dateRangeInput()
                        .find(".athenaeum-date-range-input-monthViewGridDay")
                        .not(".athenaeum-date-range-input-isToday")
                        .not(".athenaeum-date-range-input-gridDayDisabled")
                        .not(".athenaeum-date-range-input-isOutOfRange")
                        .first()
                        .click();
                }
                else {
                    $btn.click();
                }
            });

        //Select next month if current month has only single not disabled day left.
        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay")
            .not(".athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .children()
            .then((children) => {
                if (children.length === 1) {
                    cy.get(".athenaeum-date-range-input-monthAction")
                        .last()
                        .click();
                }
            });

        cy.get(".athenaeum-date-range-input-disabledDayHint").should('not.exist');

        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay.athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .first()
            .click();

        cy.get(".athenaeum-date-range-input-disabledDayHint")
            .find("label")
            .should("contain.text", Localizer.shoppingCartPageCannotRentOrReturnOnAWeekend);

        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay")
            .not(".athenaeum-date-range-input-isToday")
            .not(".athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .first()
            .click();

        const comment: string = "Renting this construction equipment was a game-changer for our project. " +
            "The excavator we got from this company was in perfect condition, saving us the hassle of maintenance. " +
            "It boosted our productivity, completing tasks swiftly and efficiently. " +
            "Plus, the customer service was outstanding—they went above and beyond to ensure we had what we needed. " +
            "Renting over buying was definitely the right choice for us!";

        cy.get("#shopping_cart_input_comment").type(comment);
        
        cy.get("#shopping_cart_submit").realClick();
    }

    function addProductToCart() {
        //Wait that categories are rendered.
        pageData().rent.firstCategoryItem();

        executeWithIntercept(() => cy.get("a").contains("Työkoneet").click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        //Wait for render
        pageData().rent.firstCategoryItem();

        executeWithIntercept(() => cy.get("a").contains("Pyöräkuormaajat")
                .click(),
            [pageData().rent.routes.categoriesData as RouteData]);


        executeWithIntercept(() => {
                cy.get("[id^=product_]").first().find("#addToCart").click();
                pageData().rent.confirmationModal.goToCartButton().should("be.disabled");
            },
            [pageData().rent.routes.saveProductToCart as RouteData]);
    }
});