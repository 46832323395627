import React, {useEffect, useMemo, useState} from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {ch} from "@renta-apps/athenaeum-react-common";
import {BorderType, CellModel, ColumnDefinition, Grid, GridOddType} from "@renta-apps/athenaeum-react-components";
import UserContext from "@/models/server/UserContext";
import UserModel from "@/models/server/UserModel";
import styles from "@/components/EasyPlus/EasyPlusUserManagement/EasyPlusUserManagement.module.scss";
import Localizer from "@/localization/Localizer";
import BaseRole from "@/models/server/BaseRole";
import {getUsersPagedList} from "@/services/CompanyService";
import {activateEasyPlusForUser, cancelInvitationToEasyPlus, deactivateEasyPlusForUser, inviteUserToEasyPlus, readEasyPlusTerms, termsReadingExists} from "@/services/EasyPlusService";
import EasyPlusConfirmationModal from "@/components/EasyPlus/EasyPlusConfirmationModal/EasyPlusConfirmationModal";
import {toLocalDate} from "@/helpers/DateHelper";
import {Button, ButtonType, Icon, Switch, SwitchOption} from "@renta-apps/renta-react-components";
import EasyPlusUserInvoiceReferenceModal from "@/components/EasyPlus/EasyPlusUserInvoiceReferenceModal/EasyPlusUserInvoiceReferenceModal";

interface IEasyPlusUserManagementProps {
    contractId: string;
    easyPlusValidTo: Date | null;
    easyPlusAvailable: boolean;
    language: string
    easyPlusSubscriptionActivated(): Promise<void>;
    easyPlusSubscriptionDeActivated(): Promise<void>;
}

interface IEasyPlusUser {
    id: string;
    fullName: string | null;
    role: string;
    easyPlusStatus: string;
    easyPlusEnabled: EasyPLusEnabled;
    model: UserModel;
    expirationDate: Date | null;
    invoiceReference: string | null;
}

enum EasyPLusEnabled {
    False,
    Invited,
    True,
}

const EasyPlusUserManagement: React.FC<IEasyPlusUserManagementProps> = ({contractId, easyPlusValidTo, easyPlusSubscriptionDeActivated, easyPlusSubscriptionActivated, language, easyPlusAvailable}) => {
    const [allUsers, setAllUsers] = useState<IEasyPlusUser[]>([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isInvoiceReferenceModalOpen, setIsInvoiceReferenceModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IEasyPlusUser | null>(null);
    const [easyPlusTermsRead, setEasyPlusTermsRead] = useState(false);

    const isAdmin = useMemo(() => {
        return (ch.getContext() as UserContext)?.isAdmin ?? false;
    }, []);

    useEffect(() => {
        getContractUsers().catch();
        getTermsReadingExists().catch();
    }, []);

    useEffect(() => {
        setAllUsers(prev => prev.map(user => ({
            ...user,
            role: getRoleName(user.model),
            easyPlusStatus: getEasyPlusStatus(user.model),
        })));
    }, [language]);

    const easyPlusUsersColumns: ColumnDefinition[] = useMemo(() => {
        return [
            {
                header: Localizer.genericNameLanguageItemName,
                accessor: "fullName",
                editable: false,
                minWidth: 90,
                actions: [],
                render: (cell: CellModel<any>) => renderNameAndRole(cell.model),
            },
            {
                header: Localizer.easyPlusInvoiceReferenceLanguageItemName,
                accessor: "invoiceReference",
                editable: false,
                minWidth: 90,
                className: styles.invoiceReferenceColumn,
                actions: [],

                render: (cell: CellModel<any>) => renderInvoiceReference(cell.model),
            },
            {
                header: Localizer.easyPlusManagementUsersGridStatusHeaderLanguageItemName,
                accessor: "easyPlusStatus",
                editable: false,
                minWidth: 90,
                actions: [],
                render: (cell: CellModel<any>) => renderStatus(cell.model),
            },
            {
                header: Localizer.easyPlusManagementUsersGridActiveHeaderLanguageItemName,
                accessor: "email",
                render: (cell: CellModel<IEasyPlusUser>) => renderToggleSwitch(cell.model),
                editable: false,
                minWidth: 80,
                actions: []
            },
        ];
    }, []);

    const renderNameAndRole = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <div className={styles.nameAndRole}>
                <div className={styles.name}>{user.fullName}</div>
                <div>{user.role}</div>
            </div>
        );
    };

    const renderInvoiceReference = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <div className={styles.invoiceReference}>
                <Button className={styles.invoiceReferenceIcon}
                        type={ButtonType.Link}
                        disabled={!easyPlusAvailable}
                        onClick={() => editInvoiceReference(user)
                }>
                    <Icon name="fa-pen-to-square" size={20} />
                </Button>
                <div>{user.invoiceReference || "-"}</div>
            </div>
        );
    };

    const renderStatus = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <div className={styles.easyPlusStatus}>
                <div>{user.easyPlusStatus}</div>
                {user.expirationDate ? <div>{toLocalDate(user.expirationDate!).toLocaleDateString()}</div> : null}
            </div>
        );
    }

    const renderToggleSwitch = (user: IEasyPlusUser): React.ReactNode => {

        let options: SwitchOption[] = [
            { value: EasyPLusEnabled.False, color: '#FFA57B', borderColor: '#FE5000' },
            { value: EasyPLusEnabled.True, color: '#80E296', borderColor: '#28A745' }
        ];

        if (isAdmin) {
            let disabledActiveState = user.expirationDate == null;
            // If user's subscription is still active but expiring, then Renta Employee can enable it.

            options = [
                { value: EasyPLusEnabled.False, color: '#FFA57B', borderColor: '#FE5000' },
                { value: EasyPLusEnabled.Invited, color: '#F9EAA9', borderColor: '#D1AE0F', disabled: !disabledActiveState },
                { value: EasyPLusEnabled.True, color: '#80E296', borderColor: '#28A745', disabled: disabledActiveState },
            ]
        }

        return (
            <Switch options={options}
                    initialValue={user.easyPlusEnabled}
                    readonly={easyPlusValidTo !== null}
                    onChange={async (value: EasyPLusEnabled) => await changeEasyPlusForUser(value, user)}
            />
        );
    };

    const editInvoiceReference = (user: IEasyPlusUser) => {
        setSelectedUser(user);
        setIsInvoiceReferenceModalOpen(true);
    };

    const changeEasyPlusForUser = async (enabled: EasyPLusEnabled, user: IEasyPlusUser): Promise<void> => {
        if (enabled === EasyPLusEnabled.True && user.expirationDate != null && isAdmin) {
            await activateEasyPlusForUser(user.id, contractId);
            await easyPlusSubscriptionActivated();
            await getContractUsers();

            return;
        } else if (enabled === EasyPLusEnabled.True) {
            setSelectedUser(user);
            setIsConfirmationModalOpen(true);

            return;
        } else if (enabled === EasyPLusEnabled.Invited) {
            await inviteUserToEasyPlus(user.id, contractId);
            await getContractUsers();

            return;
        } else if (enabled === EasyPLusEnabled.False && user.easyPlusEnabled === EasyPLusEnabled.Invited) {
            await cancelInvitationToEasyPlus(user.id, contractId);
            await getContractUsers();

            return;
        }

        await deactivateEasyPlusForUser(user.id, contractId);
        await easyPlusSubscriptionDeActivated();
        await getContractUsers();
    };

    const onConfirmationModalClose = async (confirmed: boolean): Promise<void> => {
        setIsConfirmationModalOpen(false);
        if (confirmed && selectedUser) {
            await activateEasyPlusForUser(selectedUser.id, contractId);
            await easyPlusSubscriptionActivated();
            await getContractUsers();
        }
        setSelectedUser(null);
    };

    const onInvoiceReferenceModalClose = async (saved: boolean): Promise<void> => {
        setIsInvoiceReferenceModalOpen(false);
        setSelectedUser(null);
        if (saved) {
            await getContractUsers();
        }
    };

    const isEasyPlusEnabledAndNotExpiringOrInvited = (role: BaseRole): EasyPLusEnabled => {
        if (role.easyPlusEnabled && !role.easyPlusValidTo) {
            return EasyPLusEnabled.True
        } else if (role.easyPlusInvited && isAdmin) {
            return EasyPLusEnabled.Invited;
        }

        return EasyPLusEnabled.False;
    };

    const isEasyPlusEnabledAndNotExpired = (role: BaseRole) => {
        return role.easyPlusEnabled && (!role.easyPlusValidTo || role.easyPlusValidTo > new Date());
    };

    const isEasyPlusInvited = (role: BaseRole) => {
        return role.easyPlusInvited;
    };

    const getActiveText = (date: Date | null) => {
        if (date) {
            return Localizer.easyPlusManagementUsersGridActiveUntil;
        }
        return Localizer.easyPlusManagementUsersGridActive;
    };

    const getEasyPlusStatus = (model: UserModel): string => {
        if (model.organizationRoles.length > 0 && isEasyPlusEnabledAndNotExpired(model.organizationRoles[0])) {
            return getActiveText(model.organizationRoles[0].easyPlusValidTo);
        }
        if (model.constructionSiteRoles.length > 0 && isEasyPlusEnabledAndNotExpired(model.constructionSiteRoles[0])) {
            return getActiveText(model.constructionSiteRoles[0].easyPlusValidTo);
        }
        if (model.organizationRoles.length > 0 && isEasyPlusInvited(model.organizationRoles[0])) {
            return Localizer.easyPlusManagementUsersGridInvited;
        }
        if (model.constructionSiteRoles.length > 0 && isEasyPlusInvited(model.constructionSiteRoles[0])) {
            return Localizer.easyPlusManagementUsersGridInvited;
        }

        return Localizer.easyPlusManagementUsersGridNotActive;
    };

    const getRoleName =(model: UserModel): string => {
        if (model.constructionSiteRoles.length > 0) {
            return Localizer.get(model.constructionSiteRoles[0]?.roleName) ?? "";
        }
        return Localizer.get(model.organizationRoles[0]?.roleName) ?? "";
    };

    const getContractUsers = async (): Promise<void> => {
        const users: IPagedList<UserModel> = await getUsersPagedList(contractId);

        setAllUsers(users.items.map(user => {
            const role = user.organizationRoles.length > 0
                ? user.organizationRoles[0]
                : user.constructionSiteRoles[0];
            return {
                id: user.id,
                fullName: user.fullName,
                role: getRoleName(user),
                easyPlusStatus: getEasyPlusStatus(user),
                easyPlusEnabled: isEasyPlusEnabledAndNotExpiringOrInvited(role),
                model: user,
                expirationDate: role.easyPlusValidTo,
                invoiceReference: role.easyPlusInvoiceReference,
            };
        }));
    };

    const getTermsReadingExists = async (): Promise<void> => {
        const read: boolean = await termsReadingExists(contractId);
        setEasyPlusTermsRead(read);
    };

    const handleTermsRead = async () => {
        await readEasyPlusTerms(contractId)
        setEasyPlusTermsRead(true);
    };

    return (
        <div className={styles.easyPlusUserManagement}>
            <div className={styles.easyPlusTableTitle}>{Localizer.easyPlusManagementActiveUsers}</div>
            <Grid responsive
                  version2Styles
                  id={"easy_plus_users_grid"}
                  className={styles.easyPlusUsersGrid}
                  columns={easyPlusUsersColumns}
                  data={allUsers}
                  odd={GridOddType.None}
                  borderType={BorderType.Default}
                  noDataText=""
            />
            <EasyPlusConfirmationModal isOpen={isConfirmationModalOpen}
                                       onClose={onConfirmationModalClose}
                                       onTermsRead={handleTermsRead}
                                       userFullName={selectedUser?.fullName ?? ""}
                                       termsRead={easyPlusTermsRead}
            />
            {selectedUser && (
                <EasyPlusUserInvoiceReferenceModal isOpen={isInvoiceReferenceModalOpen}
                                                   invoiceReference={selectedUser.invoiceReference ?? null}
                                                   userId={selectedUser.id ?? ""}
                                                   contractId={contractId}
                                                   onClose={onInvoiceReferenceModalClose}
                />
            )}
        </div>
    );
};

export default EasyPlusUserManagement;