import Localizer from "../../../../localization/Localizer";

describe('Desktop - Admin user can open construction site invoices and invoices are loaded and invoice details are expandable', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    function AssertConstructionSiteInvoicesContent(): void {

        // Invoice header translation and the invoice loads checks
        cy.get("h3")
            .should('contain', Localizer.constructionSiteDetailsFilterResults);

        cy.get("#input_invoices_search_input")
            .first()
            .should('have.class', 'athenaeum-text-input-textInput form-control');

        cy.get("#invoices_sort_button")
            .first()
            .click();

        cy.get("#invoice_header_invoice_number")
            .first()
            .should('contain', Localizer.invoiceHeaderInvoiceNumber);

        cy.get("#invoice_header_due_date")
            .first()
            .should('contain', Localizer.invoiceHeaderInvoiceDueDate);

        cy.get("#invoice_header_type")
            .first()
            .should('contain', Localizer.invoiceHeaderInvoiceType);

        cy.get("#invoice_header_total_amount")
            .first()
            .should('contain', Localizer.invoiceHeaderInvoiceTotalAmount);

        cy.get("#invoice_header_date")
            .first()
            .should('contain', Localizer.invoiceHeaderInvoiceDate);

        // Invoice details could be expanded and there is a table under it
        cy.get("#all_invoice_rows")
            .find(".athenaeum-accordion-icon")
            .eq(1)
            .click()
            .get("#table_construction_site_invoices_row_details")
            .find("tr")
            .should("have.length.above", 1);
    }

    it('Invoices page opens and invoice are loaded and are expandable', () => {
        cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`);
        cy.intercept(
            {
                method: 'POST',
                path: '/api/ConstructionSites/GetInvoices',
            },
            {fixture: 'invoices.json'}
        );

        cy.get("#tab_invoicesTab").click();

        AssertConstructionSiteInvoicesContent();
    });

});
