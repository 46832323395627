import {Button, ButtonType, Checkbox, Form, InlineType, Modal, NumberInput, NumberInputBehaviour, OneColumn, TwoColumns} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect} from "react";
import {Dictionary} from "typescript-collections";
import {updateProductDiscountPercentageAsync} from "@/services/PricingToolService";
import ProductModel from "@/models/server/ProductModel";
import PriceHelper, {ILocalizedPriceData, ProductPriceType} from "@/helpers/PriceHelper";
import ProductVatButtons from "@/components/ProductVatButtons/ProductVatButtons";
import styles from "./ChangeDiscountModal.module.scss";

type CategoryDiscountModalProps = {
    isOpen: boolean;
    item: ProductModel;
    vat: number;
    discountId?: string | null;
    onClose(success: boolean): void;
}

const ChangeDiscountModal: React.FC<CategoryDiscountModalProps> = (props: CategoryDiscountModalProps) => {

    const [localizedPrices, setLocalizedPrices] = React.useState<ILocalizedPriceData[]>([]);
    
    const [discountPercentage, setDiscountPercentage] = React.useState<number>(props.item.discountPercentage ?? 0);
    const [priceAfterDiscount, setPriceAfterDiscount] = React.useState<number>(0);
    
    const [isCalculateChecked, setIsCalculateChecked] = React.useState<boolean>(false);
    const [includeVat, setIncludeVat] = React.useState<boolean>(false);
    
    const _modalRef: React.RefObject<Modal> = React.createRef();
    
    const changeDiscountAsync = async (data: Dictionary<string, any>) => {
        if (!props.discountId) {
            console.warn("No discount id");
            return;
        }
        const updated = await updateProductDiscountPercentageAsync(props.item, props.discountId, discountPercentage);
        props.onClose(!!updated);
    }

    const getLocalizedPrices = (withVat: boolean): ILocalizedPriceData[] => {
        const prices = PriceHelper.toLocalizedPrices(props.item, withVat ? props.vat : 0, null);
        const dailyPriceOnly = prices.filter(price => price.type === ProductPriceType.Daily)
        setLocalizedPrices(dailyPriceOnly);
        return dailyPriceOnly;
    }
    
    const calculateDiscount = (originalPrice: number, newPrice: number): void => {
        const newDiscountPercentage: number = (originalPrice - newPrice) / originalPrice * 100.0;
        setDiscountPercentage(Math.round(newDiscountPercentage * 100) / 100);
    }
    
    const calculatePriceAfterDiscount = (originalPrice: number, discountPercentage: number): void => {
        const newPriceAfterDiscount: number = originalPrice - (originalPrice * discountPercentage / 100.0);
        setPriceAfterDiscount(Math.round(newPriceAfterDiscount * 100) / 100);
    }
    
    const cleanup = (): void => {
        setDiscountPercentage(props.item.discountPercentage ?? 0);
    }

    useEffect(() => {
        if (props.isOpen) {
            const prices = getLocalizedPrices(includeVat);
            setDiscountPercentage(props.item.discountPercentage ?? 0);
            calculatePriceAfterDiscount(Number(prices[0].valueNoUnit), props.item.discountPercentage ?? 0);
            _modalRef.current?.openAsync();
        } else {
            cleanup();
            _modalRef.current?.closeAsync();
        }
    }, [props.isOpen]);

    return (
        props.isOpen ? (
            <Modal ref={_modalRef}
                   title={Localizer.pricingToolEditDiscount}
                   onClose={async () => props.onClose(false)}
                   id="changeDiscountModal"
            >
                <Form id="createCategory" onSubmit={async (_, data) => changeDiscountAsync(data)}>
                    <OneColumn>
                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  label={Localizer.pricingToolCalculateDiscountBaseOnExpectedPrice}
                                  value={isCalculateChecked}
                                  onChange={async (_, value ) => {
                                      setIsCalculateChecked(!isCalculateChecked);
                                      getLocalizedPrices(includeVat);
                                  }}
                                  id="calculateDiscountCheckbox"
                        />
                        
                        {localizedPrices.map((price, index) => (
                            <div key={index + price.type}
                                 className={styles.priceRow}
                            >
                                <TwoColumns>
                                    <div className={styles.label}></div>
                                    <div className={styles.value}>{price.value}</div>
                                </TwoColumns>

                                <TwoColumns>
                                    <div className={styles.label}>{Localizer.pricingToolDiscountPercentage}</div>
                                    <NumberInput required
                                                 className={`renta-easy-number-input ${styles.numberInputMargin}`}
                                                 min={0}
                                                 max={100}
                                                 step={0.1}
                                                 value={discountPercentage}
                                                 behaviour={NumberInputBehaviour.Restricted}
                                                 increaseIcon="plus"
                                                 decreaseIcon="minus"
                                                 name="discountPercentage"
                                                 id="discountPercentage"
                                                 onChange={async (_, percentage) => {
                                                     setDiscountPercentage(percentage);
                                                     calculatePriceAfterDiscount(Number(price.valueNoUnit), percentage);
                                                 }}
                                                 disabled={isCalculateChecked}
                                    />
                                </TwoColumns>
                                
                                <TwoColumns>
                                    <div className={styles.label}>{Localizer.pricingToolPriceAfterDiscount}</div>
                                    <NumberInput required
                                                 className={`renta-easy-number-input ${styles.numberInputMargin}`}
                                                 min={0}
                                                 max={Number(price.valueNoUnit)}
                                                 step={0.01}
                                                 value={priceAfterDiscount}
                                                 behaviour={NumberInputBehaviour.Restricted}
                                                 increaseIcon="plus"
                                                 decreaseIcon="minus"
                                                 name="discountExpectedPrice"
                                                 id="discountExpectedPrice"
                                                 onChange={async (_, newPrice) => {
                                                     setPriceAfterDiscount(newPrice);
                                                     calculateDiscount(Number(price.valueNoUnit), newPrice);
                                                 }}
                                                 disabled={!isCalculateChecked}
                                    />
                                </TwoColumns>
                            </div>
                        ))}
                        
                        <TwoColumns>
                            <div className={styles.label}></div>
                            <div className={styles.value}>
                                <ProductVatButtons
                                    environmentVatValue={PriceHelper.environmentVat}
                                    isZeroVatSelected={!includeVat}
                                    isEnvVatSelected={includeVat}
                                    onVatChangeAsync={async (value) => {
                                        setIncludeVat(!!value);
                                        const prices = getLocalizedPrices(!!value);
                                        calculatePriceAfterDiscount(Number(prices[0].valueNoUnit), discountPercentage);
                                    }}
                                />
                            </div>
                        </TwoColumns>
        
                        <Button submit block
                                id="overallSaveButton"
                                label={Localizer.genericSave}
                                type={ButtonType.Orange}
                                className={styles.submitButton}
                        />
                    </OneColumn>
                </Form>
            </Modal>
        ) : null
    );
}

export default ChangeDiscountModal;