import Localizer from "../../../localization/Localizer";

describe('Desktop - Anonymous product details tests', () => {

    const responseContainer = {
        "value": {
            "id": null,
            "name": "Brukermanual Ausa D450 AHG (NO).pdf",
            "size": 0,
            "type": "application/pdf",
            "src": "data:application/pdf;base64,JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg==",
            "fileType": 2,
            "reference": null,
            "priority": 0,
            "isFileModel": true
        },
        "context": null,
        "newVersionAvailable": false,
        "redirect": null,
        "error": null,
        "alert": null,
        "unauthorized": false,
        "isResponseContainer": true
    }

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
        cy.intercept('POST', 'api/Product/ProductData', {fixture: 'product'}).as('data');
        cy.intercept('POST', 'api/Product/GetProductAvailability', {fixture: 'productAvailability'}).as('data');
        cy.visit('tuote/mock-url');
        cy.waitSuccess('@data');
    });

    it('Copy share link to clipboard ', () => {

        cy.get('#share-link-button').click();

        //Note: importing localizer makes the test runner start a bit slower
        cy.get('.athenaeum-page-container-container').contains(Localizer.productDetailsShareLinkUrlCopied);

        //TODO check why this doesn't work in CI/CD (electron)
        // cy.window()
        //     .its('navigator.clipboard')
        //     .invoke('readText')
        //     .should('eq', Cypress.config().baseUrl + '/tuote/mock-url');
    });

    it('Open availability information and navigate to depot page ', () => {
        cy.get('#tab_productDetailsAvailabilityTab').click();

        cy.get('[data-cy=depot_link]').first().click();

        cy.url().should('include', Localizer.pageRoutesDepots);

    });

    it('should verify technical information', () => {

        cy.get('#tab_productDetailsTechnicalInfoTab').click();

        cy.get('#productDetailsTechnicalInfoTab').should('exist');

        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .should('have.length', 2);

        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-header h3')
            .should('contain.text', 'Manitou ME 318 S2 (TRIPLEX 4500 mm)');

        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-header h3')
            .should('contain.text', 'Toyota Traigo 8FBEKT18 (FSW 6500 mm)');

        let accordion = 0;

        // Verify first technical information.
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(accordion)
            .click();

        assertTable(accordion, 0, 0, 'Løftekapasitet, kg (maks)');

        assertTable(accordion, 0, 1, '1800 kg');

        assertTable(accordion, 1, 0, 'Mål (LxBxH)');

        assertTable(accordion, 1, 1, '2970 x 1060 x 2020 mm');

        // Verify documents table.
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(accordion)
            .find('#tab_technicalDocumentationTab')
            .click();

        assertTable(accordion, 1, 0, 'Datablad Manitou ME 318 S2');

        assertTable(accordion, 2, 0, 'Brukermanual Manitou ME 318 S2 Norsk');

        accordion = 1;

        // Verify second technical information.
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(accordion)
            .click();

        assertTable(accordion, 0, 0, 'Drivkilde, type');

        assertTable(accordion, 0, 1, 'Batteri');

        assertTable(accordion, 1, 0, 'Gaffellengde');

        assertTable(accordion, 1, 1, '1000 mm');

        // Verify documents table.
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(accordion)
            .find('#tab_technicalDocumentationTab')
            .click();

        assertTable(accordion, 1, 0, 'Datablad Toyota 8FBE 1,5 - 2,0 t ENG');
    });

    it('should preview a document', () => {

        cy.intercept('POST', '/api/Product/GetDocumentStream', { statusCode: 200, body: responseContainer }).as('DownloadRequest');

        cy.get('#tab_productDetailsTechnicalInfoTab').click();

        // Open the first accordion
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(0)
            .click();

        // View the documents tab
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(0)
            .find('#tab_technicalDocumentationTab')
            .click();

        cy.get('#viewModelDocument')
            .click();

        cy.get('h5.modal-title')
            .should('include.text', 'Manitou ME 318 S2 (TRIPLEX 4500 mm)');

        cy.get('h6.modal-title')
            .should('include.text', 'Datablad Manitou ME 318 S2');

        cy.get('.athenaeum-document-preview-modal-documentPreviewModal')
            .find('i.fa-download').should('exist');

        cy.get('.athenaeum-document-preview-modal-documentPreviewModal')
            .find('i.fa-xmark').should('exist');
    });

    it('should download a file successfully', () => {

        cy.intercept('POST', '/api/Product/GetDocumentStream', { statusCode: 200, body: responseContainer }).as('DownloadRequest');

        cy.get('#tab_productDetailsTechnicalInfoTab').click();

        // Open the first accordion
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(0)
            .click();

        // View the documents tab
        cy.get('#productDetailsTechnicalInfoTab')
            .find('div.athenaeum-accordion-accordion')
            .eq(0)
            .find('#tab_technicalDocumentationTab')
            .click();

        cy.get('#downloadModelDocument')
            .click();

        cy.wait('@DownloadRequest').then(({ request }) => {
            expect(request.body).to.have.property('documentId', 95918);
            expect(request.body).to.have.property('fileId', null);
            expect(request.body).to.have.property('productId', '6ab319f1-58b1-4349-aaa4-d6e8d5849e32');
            expect(request.body).to.have.property('productInfoId', '79070e88-7b0d-480b-83f8-5e4f9ae63e1e');
        });
    });

    function assertTable(table: number, row: number, column: number, value: string) {
        cy.get('table')
            .eq(table)
            .find('tr')
            .eq(row)
            .find('td')
            .eq(column)
            .should('have.text', value);
    }
});