import React from "react";
import {Icon, PageContainer, PageHeader, PageRow, WidgetContainer
} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "../../providers/PageDefinitions";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import Localizer from "../../localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import styles from './Welcome.module.scss';

export interface IWelcomeParams extends BasePageParameters {
}

export default class Welcome extends AuthorizedPage<IWelcomeParams> {

    private companyTranslations = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCompanyTranslations);

    protected get title(): string {
        return Localizer.welcomePageTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private applyForCompanyAccessButtonText(): string {
        return this.companyTranslations
            ? Localizer.welcomePageSetUpCompanyAccessButton
            : Localizer.welcomePageApplyForCompanyAccessButton;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer hasWideHeader 
                           className={this.css("text-align-center", styles.welcomePage)}>
                <PageHeader wideHeader
                            title={""}
                            className={this.css(styles.topBanner)}>
                    <header className={styles.header}>
                        <img className={styles.headerImage}
                             src={'../images/renta-worker.png'} alt={"Reta worker"}
                        />
                        <div>
                            <div className={styles.headerText}>{Localizer.welcomePageHeaderWelcomeText}</div>
                            <img className={styles.headerLogo}
                                 src={'../images/renta_new.png'} alt={"Renta Easy"}/>
                        </div>
                    </header>
                </PageHeader>
                <WidgetContainer>
                    <PageRow className={styles.fullWidth}>
                        <div className={styles.boldWelcomeTextHeader}>
                            {this.companyTranslations
                                ? Localizer.welcomePageCompanyAccessText1
                                : Localizer.welcomePageWelcomeText1}
                        </div>
                    </PageRow>
                    <PageRow className={styles.fullWidth}>
                        <div className={styles.welcomeText}>
                            {this.companyTranslations
                                ? Localizer.welcomePageCompanyAccessText2
                                : Localizer.welcomePageWelcomeText2}
                        </div>
                    </PageRow>
                    <PageRow className={styles.fullWidth}>
                        <div className={styles.welcomeText}>
                            {this.companyTranslations
                                ? Localizer.welcomePageCompanyAccessText3
                                : Localizer.welcomePageWelcomeText3}
                        </div>
                    </PageRow>
                    <PageRow className={styles.fullWidth}>
                        <div className={this.css("row", styles.actionsContainer)}>
                            <button className={this.css("btn btn-block", styles.applyForCompanyAccessButton)}
                                    id={"apply_for_company_access_button"}
                                    onClick={async () => {
                                        await PageRouteProvider.redirectAsync(PageDefinitions.applyCompanyAccount.route());
                                    }}
                            >
                                <img className={styles.buttonIcon}
                                    src={'../images/business-account-icon.svg'}
                                    alt={this.applyForCompanyAccessButtonText()} />
                                <span className={styles.buttonSpan}>
                                    {this.applyForCompanyAccessButtonText()}
                                </span>
                                <Icon name={"far chevron-right"} />
                            </button>
                        </div>  
                        <div className={this.css("row", styles.actionsContainer)}>
                            <button className={this.css("btn btn-block btn-light", styles.continueAsPrivateButton)}
                                    id={"continue_as_private_button"}
                                    onClick={async () => {
                                        await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
                                    }}
                            >
                                <img className={styles.buttonIcon}
                                     src={'../images/private-user-icon.svg'}
                                     alt={Localizer.welcomePageContinueAsPrivateUser}/>
                                <span className={styles.buttonSpan}>
                                        {Localizer.welcomePageContinueAsPrivateUser}
                                    </span>
                                <Icon name={"far chevron-right"} />
                            </button>
                        </div>
                    </PageRow>
                </WidgetContainer>

            </PageContainer>
        );
    }
}