import React, {useEffect, useMemo, useRef, useState} from "react";
import {ch} from "@renta-apps/athenaeum-react-common";
import EnvironmentFloorPlan from "@/models/server/EnvironmentFloorPlan";
import UserContext from "@/models/server/UserContext";
import EnvironmentFloorPlanDevice from "@/models/server/EnvironmentFloorPlanDevice";

import styles from "./FloorPlanView.module.scss";
import {EnvironmentDeviceType} from "@/models/Enums";
import SensorBubble, {Direction} from "./SensorBubble/SensorBubble";
import Localizer from "@/localization/Localizer";
import {AvoidOverlap} from "@/lib/avoid-overlap/avoid-overlap";
import {select, selectAll} from 'd3-selection';
import {useResize} from "@/helpers/Hooks";

interface IFloorPlanViewProps {
    floorPlan: EnvironmentFloorPlan | null;
    className?: string;
    selectedDeviceId?: string;
    maxHeight?: number;
    scale?: number;

    onSensorClick?(deviceId: string, sensors: number[]): Promise<void>;
    onDeviceSelected?(deviceId: string): void;
    onDeviceUnselected?(): void;
}

interface SensorData {
    device: EnvironmentFloorPlanDevice,
    dx: number,
    dy: number,
    zIndex: number,
    highlighted: boolean,
    transparent: boolean;
    arrowDirection: Direction;
}

const canvasHeight = 500;
const canvasHeightMobile = 300;
const startingZIndex = 500;
const avoidOverlap = new AvoidOverlap();

const FloorPlanView: React.FC<IFloorPlanViewProps> = (props: IFloorPlanViewProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const {windowWidth} = useResize();
    const [bubbles, setBubbles] = useState<SensorData[]>([]);
    const [floorPlanImageWidth, setFloorPlanImageWidth] = useState(0);
    const [mobile, setMobile] = useState(ch.mobile);

    const {ioLivingLargeImagePrefix, ioLivingHugeImagePrefix, ioLivingBaseUrl} = useMemo(() => {
        const userContext = ch.getContext() as UserContext;
        return {
            ioLivingLargeImagePrefix: userContext.settings.ioLivingLargeImagePrefix,
            ioLivingHugeImagePrefix: userContext.settings.ioLivingHugeImagePrefix,
            ioLivingBaseUrl: userContext.settings.ioLivingBaseUrl,
        }
    }, []);

    useEffect(() => {
        setMobile(ch.mobile);
        drawFloorPlan();
    }, [props.floorPlan, props.maxHeight, windowWidth]);

    useEffect(() => {
        if (props.selectedDeviceId) {
            setBubbles(prev => prev.map(bubble => bubble.device.id === props.selectedDeviceId
                ? {...bubble, transparent: false, highlighted: true}
                : {...bubble, transparent: true, highlighted: false}
            ));
        } else {
            setBubbles(prev => prev.map(bubble => ({...bubble, transparent: false, highlighted: false})));
        }
    }, [props.selectedDeviceId]);

    useEffect(() => {
        const rotateZIndex = () => {
            setBubbles(prev => {
                const operation = prev[0].zIndex > startingZIndex ? (index: number) => startingZIndex - index : (index: number) => startingZIndex + index;

                return prev.map((bubble, index) => ({...bubble, zIndex: operation(index + 1)}));
            });
        };

        if (!bubbles?.length) {
            return;
        }

        const interval = setInterval(() => {
            rotateZIndex();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [bubbles?.length]);

    const drawFloorPlan = () => {
        if (!canvasRef.current || !props.floorPlan) {
            return;
        }

        setBubbles([]);
        const imageSize = mobile ? ioLivingLargeImagePrefix : ioLivingHugeImagePrefix;
        const imageUrl = `${ioLivingBaseUrl}${imageSize}`;
        const floorPlanImage = new Image();
        const current = canvasRef.current;
        if (!current) {
            return;
        }
        const canvasContext = current.getContext("2d")!;

        current.style.width = '100%';
        current.style.height = '100%';

        current.width = current!.offsetWidth!;

        const {floorPlan} = props;
        floorPlanImage.src = `${imageUrl}/${floorPlan!.file}`;

        floorPlanImage.onload = () => {
            const ct: HTMLElement = document.getElementById('measure')!;
            ct.appendChild(floorPlanImage);
            const wrh: number = floorPlanImage.width / floorPlanImage.height;

            setFloorPlanImageWidth(canvasContext.canvas.width);
            let newWidth: number = canvasContext.canvas.width;
            let newHeight: number = newWidth / wrh;
            if (newHeight > canvasContext.canvas.height) {
                newHeight = canvasContext.canvas.height;
                newWidth = newHeight * wrh;
            }

            // draw the floorplan on the center of the canvas
            const moveX = (canvasContext.canvas.width - newWidth) / 2;
            const moveY = (canvasContext.canvas.height - newHeight) / 2;
            ct.removeChild(floorPlanImage);
            canvasContext.drawImage(floorPlanImage, moveX, moveY, newWidth, newHeight);
            const bubbles: SensorData[] = [];
            let zIndex = startingZIndex;
            floorPlan!.floorPlanDevices.forEach((device: EnvironmentFloorPlanDevice) => {
                const x: number = (device.x / 100 * newWidth) + moveX;
                const y: number = (device.y / 100 * newHeight) + moveY;

                if (device.type === EnvironmentDeviceType.Gateway) {
                    const markerImage = new Image();
                    markerImage.src = '/images/Renta-IOT-gateway-icon.svg';
                    const imageWidth: number = 43;
                    const imageHeight: number = 58;

                    markerImage.onload = function () {
                        const dx: number = x - (imageWidth / 2);
                        const dy: number = y - imageHeight;

                        canvasContext.drawImage(markerImage, dx, dy, imageWidth, imageHeight);
                    };
                }
                else {
                    bubbles.push({
                        device: device,
                        dx: x,
                        dy: y,
                        highlighted: false,
                        transparent: false,
                        arrowDirection: 'down',
                        zIndex: ++zIndex,
                    });
                }
            });

            setBubbles(bubbles);

            // the bubbles need to be rendered before we start collision detection
            setTimeout(() => {
                handleCollisions();
            }, 50);
        };
    }

    const handleCollisions = () => {
        const sensors = selectAll('.bubble');
        const scaleValue = props.scale ?? 1;

        avoidOverlap.run(
            canvasRef.current!,
            {
                nodes: sensors.nodes() as Element[],
                maxDistance: {
                    x: width => (width / 2 - 9) / scaleValue,
                    y: height => (height + 12) / scaleValue,
                },
            },
            {
                render: (node: any, dx: any, dy: any) => {
                    const selected = select(node);

                    const getNumberFromStyle = (style: string): number => {
                        return +selected.style(style).match(/([0-9\-.]+)/g)![0];
                    }

                    const setStyle = (style: string, value: number) => {
                        selected.style(style, `${value}px`)
                        return +selected.style(style).match(/([0-9\-.]+)/g)![0];
                    }

                    if (dx !== 0) {
                        const scaledDx = dx / scaleValue;
                        const x = getNumberFromStyle('left');
                        const moveX = getNumberFromStyle('--moveX');

                        setStyle('left', x + scaledDx);
                        setStyle('--moveX', moveX - scaledDx);
                    }
                    if (dy && dy !== 0) {
                        const y = getNumberFromStyle('top');
                        const id = selected.attr('id');

                        setStyle('top', y + (dy / scaleValue));
                        setBubbles(prev => prev.map(b => b.device.id === id ? {...b, arrowDirection: 'up'} : b));
                    }
                },
            }
        );
    };

    const sensorClick = async (deviceDetail: EnvironmentFloorPlanDevice): Promise<void> => {
        if (props.onSensorClick && deviceDetail.sensors.length > 0) {
            await props.onSensorClick(deviceDetail.id!, deviceDetail.sensors.map(a => a.id));
        }
    }

    const sensorMouseEnter = (deviceDetail: EnvironmentFloorPlanDevice): void => {
        props.onDeviceSelected?.(deviceDetail.id!);
    }

    const sensorMouseLeave = (): void => {
        props.onDeviceUnselected?.();
    }

    return (
        <div style={{position: 'relative'}} className="floor-plan">
            <canvas ref={canvasRef}
                    id="canvas"
                    height={mobile ? canvasHeightMobile : (props.maxHeight ?? canvasHeight)}
            />
            <div id="measure" className={styles.measure}></div>
            {bubbles.map((bubble: SensorData) => {
                return (
                    <SensorBubble key={bubble.device.id}
                                  device={bubble.device}
                                  dx={bubble.dx}
                                  dy={bubble.dy}
                                  zIndex={bubble.zIndex}
                                  floorPlanImageWidth={floorPlanImageWidth}
                                  highlighted={bubble.highlighted}
                                  transparent={bubble.transparent}
                                  language={Localizer.language}
                                  onSensorClick={() => sensorClick(bubble.device)}
                                  onMouseEnter={() => sensorMouseEnter(bubble.device)}
                                  onMouseLeave={() => sensorMouseLeave()}
                                  arrowDirection={bubble.arrowDirection}
                    />
                );
            })}
        </div>
    );
};

export default FloorPlanView;