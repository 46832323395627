import React, {MouseEvent} from "react";
import {Icon, IconSize, Link} from "@renta-apps/athenaeum-react-components";
import CategoryModel from "@/models/server/CategoryModel";
import ImageProvider from "@/providers/ImageProvider";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "./CategoryItem.module.scss";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export interface CategoryItemProps {
    item: CategoryModel;
    index: number;
    className?: string;
    onOpenClicked(category: CategoryModel, event?: React.MouseEvent): Promise<void>;
}

const CategoryItem: React.FC<CategoryItemProps> = (props: CategoryItemProps) => {

    const onItemLinkClicked = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        await props.onOpenClicked(props.item, e);
    }
    
    return (
        <Link route={PageDefinitions.rent.route({id: props.item.url})}
              className={`${styles.itemWrapper}`}
        >
            <div className={styles.item}
                 onClick={async (e: MouseEvent<any>) => await onItemLinkClicked(e)}
            >
                {/* Hidden category items are only displayed for admins*/}
                {props.item.hidden && (
                    <Icon name={"eye-slash"}
                          size={IconSize.X3}
                          className={styles.hiddenIcon}
                    />
                )}
                
                <div className={`${styles.categoryItem}`}>
                    {(!!props.item.imageReference) && (
                        <img src={ImageProvider.getCategoryThumbnailSrc(props.item)}
                             alt={""}
                             onError={({currentTarget}: any) => {
                                 currentTarget.onerror = null;
                                 currentTarget.src = "/images/placeholder.jpg";
                             }}
                        />
                    )}
                    {(!props.item.imageReference) && (
                        <div className={styles.imagePlaceholder}></div>
                    )}
        
                    <div className={styles.content}>
                        <h2 className={(props.item.name.length > RentaEasyConstants.categoryNameFontSizeThreshold) ? styles.long : undefined}>{props.item.name}</h2>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CategoryItem;