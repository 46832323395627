import React from "react";
import {
    AddressHelper,
    Button,
    ButtonType,
    GlobalSearch,
    GlobalSearchContext,
    GlobalSearchOption,
    GlobalSearchResponse,
    GlobalSearchSetContext,
    ICookieConsentProps,
    IShoppingCart,
    Layout,
    Modal,
    ModalSize
} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch, UserInteractionDataStorage, ApiProvider, PageRouteProvider, ApplicationContext} from "@renta-apps/athenaeum-react-common";
import RentaEasyController from "@/pages/RentaEasyController";
import FlagProvider from "@unleash/proxy-client-react";
import UnleashHelper from "@/helpers/UnleashHelper";
import ShoppingCartModel from "@/models/server/ShoppingCartModel";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {GlobalSearchUtils} from "@/providers/GlobalSearchUtils";
import {ILayoutProps} from "@renta-apps/athenaeum-react-components/src/components/Layout/Layout";
import InfoScreen from "@/pages/InfoScreen/InfoScreen";
import {HtmlHeadHelper} from "@/helpers/HtmlHeadHelper";

interface TAppProps {}

interface TAppState {
    searchProviders: GlobalSearchOption[];
    globalSearchContext: GlobalSearchResponse;
    isGlobalSearchEnabled: boolean;
    isCookieConsentForceOpen: boolean;
}

class App extends BaseComponent<TAppProps, TAppState> {

    private _privacyPolicyModal: React.RefObject<Modal> = React.createRef();

    state: TAppState = {
        searchProviders: [],
        globalSearchContext: {searchTerm: "", searchProviders: [], resultsLimit: 0, results: {}, isLoading: false},
        isGlobalSearchEnabled: false,
        isCookieConsentForceOpen: false,
    };

    public async onDidMountAsync(): Promise<void> {
        this.setState({isGlobalSearchEnabled: UnleashHelper.isEnabled(RentaEasyConstants.featureFlagGlobalSearch)});

        if (this.state.isGlobalSearchEnabled) {
            const searchProvidersResults = await GlobalSearchUtils.fetchSearchProvidersAsync();
            await this.setState({searchProviders: searchProvidersResults.searchProviders});
        }

        ch.getLayout().reloadTopNavAsync();
    }

    public onContextChangeAsync(oldContext?: ApplicationContext | null, newContext?: ApplicationContext | null): void {
        const sameContextState = Boolean(oldContext) !== Boolean(newContext);
        const sameLanguage: boolean = !!oldContext && !!newContext
            && 'language' in oldContext && 'language' in newContext
            && (oldContext?.language !== newContext?.language);
        const sameRole: boolean = !!oldContext && !!newContext
            && 'currentRoleName' in oldContext && 'currentRoleName' in newContext
            && (oldContext?.currentRoleName !== newContext?.currentRoleName);

        if (sameContextState && sameLanguage && sameRole) {
            return;
        }

        this.onDidMountAsync();
    }

    private setGlobalSearchContext(newContext: GlobalSearchSetContext): void {
        this.setState({globalSearchContext: {...this.state.globalSearchContext, ...newContext}});
    }

    private async handleSearch(searchTerm: string, searchProviders: string[]): Promise<void> {
        const resetContext = ({searchTerm: searchTerm, searchProviders: searchProviders, isLoading: true, resultsLimit: 0, results: {}})
        this.setGlobalSearchContext(resetContext);
        const results = await GlobalSearchUtils.fetchSearchResultsAsync(searchTerm, searchProviders);
        const newContext = ({...results, isLoading: false})
        this.setGlobalSearchContext(newContext);
    }

    private async handleClearSearch(): Promise<void> {
        const newContext = {searchTerm: "", searchProviders: [], resultsLimit: 0, results: {}, isLoading: false};
        this.setGlobalSearchContext(newContext);
    }

    private async fetchShoppingCartAsync(): Promise<IShoppingCart> {
        const shoppingCart: ShoppingCartModel = await RentaEasyController.getShoppingCartAsync(this);

        return {
            productsCount: shoppingCart.products?.sum(product => product.count) ?? 0,
            route: PageDefinitions.shoppingCart.route(),
        };
    }

    private get cookieConsentDescription(): React.ReactNode | undefined {
        return Localizer.cookieConsentContentFirstParagraph || Localizer.cookieConsentContentSecondParagraph ?
            (<div>
                {this.cookieConsentDescriptionFirstParagraph}
                {this.cookieConsentDescriptionSecondParagraph}
            </div>)
            : undefined;
    }

    private get cookieConsentDescriptionFirstParagraph(): React.ReactNode | undefined {
        return Localizer.cookieConsentContentFirstParagraph ?
            (<p>{Localizer.cookieConsentContentFirstParagraph}</p>) : undefined
    }

    private get cookieConsentDescriptionSecondParagraph(): React.ReactNode | undefined {
        return Localizer.cookieConsentContentSecondParagraph ?
            (<p>{Localizer.cookieConsentContentSecondParagraph} {this.cookiePolicyLink}</p>) : undefined
    }

    private get cookiePolicyLink(): React.ReactNode | undefined {
        return Localizer.cookieConsentContentPrivacyPolicyLinkName ? (
            <Button
                id="privacyPolicyButton"
                toggleModal
                label={Localizer.cookieConsentContentPrivacyPolicyLinkName}
                type={ButtonType.Text}
                dataTarget="privacyPolicyModal"
                onClick={async () => await this._privacyPolicyModal.current!.openAsync()}
            />) : undefined;
    }

    private get cookieContent(): ICookieConsentProps {
        const context: ApplicationContext | null = ch.findContext();

        return {
            cookieName: RentaEasyConstants.consentCookieName,
            title: Localizer.cookieConsentTitle,
            description: this.cookieConsentDescription,
            acceptAllButtonText: Localizer.cookieConsentAcceptAllButtonLabel,
            acceptOnlyNecessaryButtonText: Localizer.cookieConsentAcceptOnlyNecessaryButtonLabel,
            acceptAllConsentValue: context!.settings.cookieConsentAcceptAllConsentValue,
            acceptOnlyNecessaryConsentValue: context!.settings.cookieConsentAcceptOnlyNecessaryConsentValue,
            optionalCookiesNames: context!.settings.cookieConsentOptionalCookiesNames,
            forceOpen: this.state.isCookieConsentForceOpen
        }
    }
    public async tokenLoginAsync(token: string): Promise<any> {
        await ApiProvider.invokeWithForcedSpinnerAsync(() =>
            ApiProvider.postAsync("/api/Application/TokenLogin", token));
    }

    public render(): React.ReactNode {
        let layoutProps: ILayoutProps = {};
        const isApplicationContextAvailable = RentaEasyController.isApplicationContextAvailable;

        if (isApplicationContextAvailable && ch.findContext()?.currentPage?.name !== nameof(InfoScreen) && !UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCookiebot)) {
            layoutProps = {
                cookieConsent: () => this.cookieContent
            };
        }

        return (
            <FlagProvider unleashClient={UnleashHelper.client} startClient={false}>
                <GlobalSearchContext.Provider
                    value={{
                        ...this.state.globalSearchContext,
                        searchOptions: this.state.searchProviders,
                        setContext: (newContext: GlobalSearchSetContext) => this.setGlobalSearchContext(newContext),
                    }}
                >
                    {HtmlHeadHelper.renderHtml(Localizer.language)}
                    <Layout useRouting useAlternativeFooter
                            noFooter={this.mobile}
                            topNavLogo={"/images/renta_new.png"}
                            footerLogo={"/images/renta_new.png"}
                            searchPlaceHolder={() => Localizer.categoriesSearchByName}
                            footerLinks={() => RentaEasyController.footerLinks}
                            footerSocialNetworkLinks={() => RentaEasyController.footerSocialNetworkLinks}
                            languages={() => RentaEasyController.languages}
                            onSearchClick={!this.state.isGlobalSearchEnabled ? (async (keyword) => {
                                await PageRouteProvider.redirectAsync(PageDefinitions.rent.route({
                                    params: {
                                        keyword: keyword
                                    }
                                }));
                            }) : undefined}
                            searchElement={(this.state.isGlobalSearchEnabled && RentaEasyController.isRedirectOutsideOfRegistrationFormPossible) ? (
                                <GlobalSearch
                                    searchPlaceHolder={Localizer.topNavSearchPlaceHolder}
                                    onSearch={async (searchTerm, searchProviders) => await this.handleSearch(searchTerm, searchProviders)}
                                    onSearchClear={async () => await this.handleClearSearch()}
                                    onResultsItemClick={async (type, item) => await GlobalSearchUtils.handleSearchItemClick(type, item)}
                                    onResultsMoreClick={async () => await GlobalSearchUtils.handleSearchResultsMoreClick()}
                                    previewResultsLimit={3}
                                    showResultsPreview
                                />
                            ) : undefined}
                            onLogoClick={async () => {
                                if (RentaEasyController.isRedirectOutsideOfRegistrationFormPossible) {
                                    await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
                                }
                            }}
                            fetchShoppingCartAsync={async () => await this.fetchShoppingCartAsync()}
                            onShoppingCartClick={async () => {
                                if (RentaEasyController.isRedirectOutsideOfRegistrationFormPossible) {
                                    await PageRouteProvider.redirectAsync(PageDefinitions.shoppingCart.route());
                                }
                            }}
                            tokenLogin={async (_, token) => {await this.tokenLoginAsync(token)}}
                            onDidMount={async (sender) => await this.onDidMountAsync()}
                            onContextChanged={async (oldContext, newContext) => await this.onContextChangeAsync(oldContext, newContext)}
                            {...layoutProps}
                            onCookieConsentLabelClick={() => this.setState({isCookieConsentForceOpen: true})}
                            onCookieConsentClick={(acceptedType) => {
                                this.setState({isCookieConsentForceOpen: false});
                                RentaEasyController.loadOptionalScripts(acceptedType);
                            }}
                    />
                </GlobalSearchContext.Provider>
                <Modal info keepTextFormatting isBodyScrollable
                       id="privacyPolicyModal"
                       ref={this._privacyPolicyModal }
                       title={Localizer.privacyNoticeTitle}
                       content={Localizer.privacyNoticeText}
                       size={ModalSize.ExtraLarge}
                />
            </FlagProvider>
        );
    }
}

//Register Unleash
ch.registerInitializeCallback(async () => await UnleashHelper.initialize());

// Register initialize events
ch.registerInitializeCallback(async () => AddressHelper.loadGoogleLibrary());
ch.registerInitializeCallback(async () => await RentaEasyController.loadNewGoogleTagManagerScriptAsync());

ch.registerInitializeCallback(async () => await RentaEasyController.initializeAsync());


// Register authorize events
ch.registerAuthorizeCallback(async () => await UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(async () => await RentaEasyController.authorizeAsync());

export default App;