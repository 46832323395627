import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import {AlertModel, BasePageParameters} from "@renta-apps/athenaeum-react-common";
import Equipments from "@/pages/ConstructionSiteDetails/Equipments/Equipments";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/Equipment/Equipment.module.scss";

/**
 * @deprecated
 */
export interface IEquipmentLegacyParams extends BasePageParameters {

    /**
     * Id of the Contract which equipment to show.
     * @deprecated Use {@link IEquipmentParams.contractId} in new code.
     */
    id?: string;
}

export interface IEquipmentParams extends BasePageParameters, IEquipmentLegacyParams {

    /**
     * Id of the Contract which equipment to show.
     */
    contractId?: string | null;

    /**
     * "EquipmentOnly" when showing equipment that are not on any site. If "false" will show all equipment for customer including
     * those that are on some construction site.
     */
    equipmentOnly?: boolean;
}

interface IEquipmentState {
    alertModel: AlertModel | null,
    contractId: string | null,
    equipmentOnly: boolean,
}

export default class Equipment extends AuthorizedPage<IEquipmentParams, IEquipmentState> {

    public state: IEquipmentState = {
        alertModel: null,
        contractId: null,
        equipmentOnly: true,
    };

    protected get title(): string {
        return Localizer.genericProducts;
    }

    public get equipmentOnly(): boolean {
        return this.state.equipmentOnly;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const parameters: IEquipmentParams | null = this.typedParameters;

        const contractId: string | null | undefined = parameters?.contractId
            ?? parameters?.id
            ?? this.userContext?.selectedContractId;

        if (!contractId) {
            await this.toFrontPageAsync();
            return;
        }

        const equipmentOnly: boolean = parameters?.equipmentOnly ?? true;

        await this.setState({
            equipmentOnly,
            contractId,
        });
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title}
                            subtitle={(this.equipmentOnly)
                                ? Localizer.equipmentDescription
                                : Localizer.equipmentOrganizationDescription}
                />

                <div className={this.css(styles.equipment)}>
                    {
                        (this.state.contractId) &&
                        (
                            <Equipments noConstructionSite
                                        equipmentOnly={this.equipmentOnly}
                                        contractId={this.state.contractId}
                            />
                        )
                    }
                </div>
            </PageContainer>
        );
    }
}