import React from "react";
import Localizer from "@/localization/Localizer";
import {AddressHelper, GoogleMap, IGoogleMapInfoWindow, IGoogleMapMarker, OneColumn, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import AdminPage from "@/models/base/AdminPage";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import ConstructionSitesGrid from "@/pages/AdminConstructionSitesOverview/ConstructionSitesGrid/ConstructionSitesGrid";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import ListConstructionSitesPagedRequest from "@/models/server/Requests/ListConstructionSitesPagedRequest";
import PageDefinitions from "@/providers/PageDefinitions";
import {BaseGridPageParameters} from "@/models/BaseGridParameters";

export interface IAdminConstructionSitesMapParams extends BaseGridPageParameters {
    favorites?: boolean;
}

interface IAdminConstructionSitesMapState {
    markers: IGoogleMapMarker[],
    constructionSites: ConstructionSiteModel[],
    filteredConstructionSites: ConstructionSiteModel[]
    startLongitude: number,
    startLatitude: number,
}

export default class AdminConstructionSitesMap extends AdminPage<IAdminConstructionSitesMapParams, IAdminConstructionSitesMapState> {

    public state: IAdminConstructionSitesMapState = {
        markers: [],
        startLatitude: 0,
        startLongitude: 0,
        constructionSites: [],
        filteredConstructionSites: []
    };

    private readonly _mapRef: React.RefObject<GoogleMap> = React.createRef();

    private get startLongitude(): number {
        return this.state.startLongitude;
    }

    private get sites(): ConstructionSiteModel[] {
        return this.state.constructionSites ?? [];
    }

    private get startLatitude(): number {
        return this.state.startLatitude;
    }

    private get mapInitialCenter(): google.maps.LatLngLiteral {
        return {
            lat: this.startLatitude,
            lng: this.startLongitude,
        };
    }

    private get siteMarkers(): IGoogleMapMarker[] {

        return this.state
            .filteredConstructionSites
            .filter(site => site.location)
            .map(
                site => {

                    //Add small fraction to lat/long so markers with exact same location are visible (google map issue)
                    const position: google.maps.LatLngLiteral = {
                        lat: site.location!.lat + (Math.random() - .5) / 1500,
                        lng: site.location!.lon + (Math.random() - .5) / 1500
                    };

                    let content: string = `<a href="/constructionsitedetails?id=${site.id}"><b>${site.name}</b></a>`;

                    if (site.externalReference) {
                        content += ` (${site.externalReference})`;
                    }

                    if (site.ownerName) {
                        content += `<br/>${site.ownerName}`;
                    }

                    const infoWindow: IGoogleMapInfoWindow = {
                        content,
                        position,
                        pixelOffset: new google.maps.Size(0, -60),
                    };

                    const marker: IGoogleMapMarker = {
                        title: site.id,
                        position,
                        infoWindow,
                    };

                    return marker;
                });
    }

    protected get title(): string {
        return Localizer.adminConstructionSitesMapTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const data: ConstructionSiteModel[] = await this.postAsync("/api/ConstructionSites/Locations", null);

        await this.setState({
            constructionSites: data,
            filteredConstructionSites: data,
            startLatitude: RentaEasyConstants.defaultLatitude,
            startLongitude: RentaEasyConstants.defaultLongitude,
        });
    }

    private async getConstructionSitesAsync(pageNumber: number,
                                            pageSize: number,
                                            sortColumnName: string | null,
                                            sortDirection: SortDirection | null,
                                            searchTerm: string | null,
                                            showOnlyFavorites: boolean | false
    ): Promise<IPagedList<ConstructionSiteModel>> {

        const request: ListConstructionSitesPagedRequest = {
            sortColumnName: sortColumnName,
            sortDirection: sortDirection,
            searchTerm: searchTerm,
            pageSize: pageSize,
            pageNumber: pageNumber,
            showOnlyFavorites: showOnlyFavorites
        };

        let items: IPagedList<ConstructionSiteModel> = await this.postAsync("/api/ConstructionSites/GetPagedConstructionSites", request);

        if (searchTerm && searchTerm !== "") {
            await this.setState({
                filteredConstructionSites: items.items
            });
        }
        else {
            await this.setState({
                filteredConstructionSites: this.state.constructionSites
            });
        }

        return items;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title}
                            subtitle={Localizer.adminConstructionSitesMapSubtitle}
                />

                <>
                    <OneColumn>
                        {
                            (this.startLatitude > 0 && AddressHelper.isGoogleApiRegistered) && (
                                <GoogleMap clusterMarkers autoCloseInfoWindows
                                           ref={this._mapRef}
                                           height={500}
                                           initialCenter={this.mapInitialCenter}
                                           initialZoom={5}
                                           markers={this.siteMarkers}
                                />)
                        }
                    </OneColumn>
                    <ConstructionSitesGrid
                        getConstructionSites={async (pageNumber, pageSize, sortColumnName, sortDirection, searchTerm, showOnlyFavorites) =>
                            await this.getConstructionSitesAsync(pageNumber, pageSize, sortColumnName, sortDirection, searchTerm, showOnlyFavorites)}
                        searchKeyword={this.typedParameters?.keyword}
                        pageNumber={this.typedParameters?.pageNumber}
                        pageSize={this.typedParameters?.pageSize}
                        showFavorites={this.typedParameters?.favorites}
                        sortDirection={this.typedParameters?.sortDir}
                        sortColumnName={this.typedParameters?.sortColumn}
                        renderFor={PageDefinitions.adminConstructionSitesMap.route()}
                    />
                </>
            </PageContainer>
        );
    }

}