import React from "react";
import {PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import Localizer from "../../localization/Localizer";
import {Link, PageContainer} from "@renta-apps/athenaeum-react-components";

import styles from "./NotFound.module.scss";
import NotFoundGame from "@/components/404/NotFoundGame";

export default class NotFound extends AnonymousPage {

    private get contactPageRoute(): PageRoute {
        return PageDefinitions.contactSupport.route({
            params: {},
        });
    }

    private get frontPageRoute(): PageRoute {
        return PageDefinitions.frontPage.route();
    }

    protected get title(): string {
        return Localizer.notFoundTitle;
    }

    public async initializeAsync(): Promise<void> {

        await super.initializeAsync();

        const route: PageRoute = this.route;

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(route);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.error}>

                <div className={styles.errorPageContent}>
                    <div className={styles.mainImageContainer}>
                        {
                            (this.mobile) && (
                                <img className={styles.topImage} src={"/images/404-top.svg"} alt={""}/>
                            )
                        }
                        {
                            (!this.mobile) && (
                                <NotFoundGame mainSvgImageUrl={"/images/404-top-no-truck.svg"}
                                              truckDown={"/images/Truck_bottom.svg"}
                                              truckLeft={"/images/Truck_left.svg"}
                                              truckRight={"/images/Truck_right.svg"}
                                              truckUp={"/images/Truck_top.svg"}
                                              obstacleImageUrl={"/images/renta-worker.png"}
                                />
                            )
                        }

                    </div>

                    <img className={styles.oopsSign} src={"/images/Oops-sign.svg"} alt={""}/>

                    <div className={styles.footer}>
                        <p>
                            {Localizer.notFoundKeepsHappening}&nbsp;
                            <Link route={this.contactPageRoute}>
                                {Localizer.notFoundContactSupport}
                            </Link>
                        </p>
                        <p>
                            {Localizer.notFoundMeanWhile}&nbsp;
                            <Link route={this.frontPageRoute}>
                                {Localizer.notFoundFrontPage}
                            </Link>
                        </p>
                    </div>
                </div>
            </PageContainer>
        );
    }
}