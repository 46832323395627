import CreatePlanDefinitionRequest from "@/models/server/CreatePlanDefinitionRequest";

export default class SavePlanDefinitionRequest extends CreatePlanDefinitionRequest {

    public constructor(request: CreatePlanDefinitionRequest) {
        super();
        this.planType = request.planType;
        this.icon = request.icon;
        this.inputGroups = request.inputGroups;
        this.isDeleted = request.isDeleted;
        this.name = request.name;
        this.receivers = request.receivers;
        this.sendToUsersFavoriteDepot = request.sendToUsersFavoriteDepot;
    }

    public id: string = "";
}