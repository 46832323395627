import React from "react";
import {Button, ButtonContainer, ButtonType, EmailInput, Form, IStringInputModel, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import Localizer from "../../localization/Localizer";

import styles from "./ForgotPassword.module.scss";

export interface IForgotPasswordParams extends BasePageParameters {
}

export default class ForgotPassword extends AnonymousPage<IForgotPasswordParams> {

    public _emailInput: IStringInputModel = {value: ""};
    public _formRef: React.RefObject<any> = React.createRef();

    private async handleSubmitAsync(): Promise<void> {
        const request = {
            username: this._emailInput.value
        };

        await this.postAsync(`/api/Application/ForgotPassword`, request);

    }

    private async redirectToLoginPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.login.route());
    }

    protected get title(): string {
        return Localizer.forgotPasswordTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.forgotPassword)}>
                <PageHeader title={this.title}/>

                <div className={"col-md-6"}>
                    <p className={styles.helpText}>
                        {
                            Localizer.forgotPasswordHelpText
                        }
                    </p>

                    <p className={styles.supportLink}>
                        <a href={"/contactpage"}>
                            {
                                Localizer.contactCustomerService
                            }
                        </a>
                    </p>

                    <Form ref={this._formRef}
                          onSubmit={async () => await this.handleSubmitAsync()}
                    >

                        <EmailInput required
                                    id={"email"}
                                    label={Localizer.genericEmail}
                                    model={this._emailInput}
                        />

                        <ButtonContainer>

                            <Button submit
                                    type={ButtonType.Orange}
                                    label={Localizer.forgotPasswordSubmitButton}
                            />

                            <Button type={ButtonType.Default}
                                    label={Localizer.loginLogin}
                                    onClick={() => this.redirectToLoginPage()}
                            />

                        </ButtonContainer>

                    </Form>
                </div>

            </PageContainer>
        );
    }
}