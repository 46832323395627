import { SortDirection } from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";

describe('Desktop - Fleet Monitoring - Return requests filters tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should display view controls', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        // The filter has five visible elements.
        DeviceGridHelper.getViewControl()
            .children('div')
            .filter(':visible')
            .should('have.length', 5);

        DeviceGridHelper.getViewControlFilter(3)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsConstructionSite);

        DeviceGridHelper.getViewControlFilter(1)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsSortBy);

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        pageData().fleetMonitoring.filters.sortByFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 5)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByPickupTime);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByStatus);
                        break;
                    case 2:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByCompany);
                        break;
                    case 3:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByConstructionSite);
                        break;
                    case 4:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByDepot);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        DeviceGridHelper.getViewControlFilter(4)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsSortOrder);

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

        pageData().fleetMonitoring.filters.sortOrderFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 2)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.enumSortDirectionDesc);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.enumSortDirectionAsc);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

    });

    it('should call API when sort by filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        cy.wait('@postRequest', {timeout: 20_000}).then(({ request }) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortColumnName', 'PickupTime');

            // Select the Company option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 2);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequest').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortColumnName', 'CustomerName');

                cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?pageNumber=1&pageSize=25&sortBy=CustomerName&sortOrder=Asc`);
            });
        });

    });

    it('should call API when sort order filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        cy.wait('@postRequest', {timeout: 20_000}).then(({ request }) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortDirection', SortDirection.Asc);

            // Select the Ascending option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 0);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequest').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortDirection', SortDirection.Desc);

                cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?pageNumber=1&pageSize=25&sortBy=PickupTime&sortOrder=Desc`);
            });
        });

    });

    it('should call API when construction site filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' }).as('postRequest');

        cy.intercept('GET', '/api/ConstructionSites/SearchConstructionSiteNames?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409&searchLimit=50&searchTerm=test').as('searchConstructionSites');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        // First call to get devices
        cy.wait('@postRequest', { timeout: 20_000 }).then(({ request }) => {
            expect(request.body).to.have.property('contractId', '31eaa6c0-42fa-40c2-a891-bcd16c367409');
            expect(request.body).to.have.property('constructionSiteId', null);

            // Select a construction site
            pageData().fleetMonitoring.filters.constructionSiteFilter.input()
                .type('test');
            cy.waitSuccess('@searchConstructionSites');

            DeviceGridHelper.getViewControlFilter(3)
                .children('div')
                .eq(0)
                .children('div')
                .eq(1)
                .children('div')
                .eq(5)
                .click();

            // The request after the first construction site filter change
            cy.wait('@postRequest').then(({ request }) => {
                expect(request.body).to.have.nested.property('constructionSites[0].id', '1'); // external id
                expect(request.body).to.have.nested.property('constructionSites[0].name', 'TESTITILAUS RENTA EASY');

                // Double check the options are set
                pageData().fleetMonitoring.filters.constructionSiteFilter.input()
                    .should('have.value', 'TESTITILAUS RENTA EASY');

                // Deselect the construction site
                pageData().fleetMonitoring.filters.constructionSiteFilter.input()
                    .clear().type('{enter}');

                // The request after the second construction site filter change
                cy.wait('@postRequest').then(({ request }) => {
                    expect(request.body).to.have.property('constructionSites', null);

                    // No option is selected
                    pageData().fleetMonitoring.filters.constructionSiteFilter.input()
                        .should('be.empty');
                });
            });
        });
    });

    it('should set input fields with the default values correctly', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t&constructionSite=TESTITILAUS%20RENTA%20EASY&constructionSiteId=f7422461-9c46-416d-b500-7ab6ae1ee591&sortBy=Status&sortOrder=Asc');

        cy.wait('@postRequest').then(() => {
            pageData().fleetMonitoring.filters.constructionSiteFilter.input()
                .should('have.value', 'TESTITILAUS RENTA EASY');

            pageData().fleetMonitoring.filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByStatus);

            pageData().fleetMonitoring.filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            cy.visit('/fleet-monitoring?constructionSite=TESTITILAUS%20RENTA%20EASY%201&constructionSiteId=68240177-21ea-4d88-863e-fa924c8005df&sortOrder=Desc&sortBy=Depot&selectedTab=palautuspyynn%C3%B6t');

            cy.wait('@postRequest').then(() => {
                pageData().fleetMonitoring.filters.constructionSiteFilter.input()
                    .should('have.value', 'TESTITILAUS RENTA EASY 1');

                pageData().fleetMonitoring.filters.sortByFilter.title()
                    .should('have.text', Localizer.fleetMonitoringPageFiltersSortByDepot);

                pageData().fleetMonitoring.filters.sortOrderFilter.title()
                    .should('have.text', Localizer.enumSortDirectionDesc);
            });
        });
    });

    it('should remember user\'s previous selections when the user returns to fleet monitoring', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' }).as('postRequest');

        cy.intercept('GET', '/api/ConstructionSites/SearchConstructionSiteNames?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409&searchLimit=50&searchTerm=test').as('searchConstructionSites');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        // Select a construction site
        pageData().fleetMonitoring.filters.constructionSiteFilter.input()
            .type('test');
        cy.waitSuccess('@searchConstructionSites');

        DeviceGridHelper.getViewControlFilter(3)
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(5)
            .click();

        // Select the ConstructionSiteName option from the dropdown
        DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 3);

        // Select the Ascending option from the dropdown
        DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 1);

        // Double-check the options are set
        pageData().fleetMonitoring.filters.constructionSiteFilter.input()
            .should('have.value', 'TESTITILAUS RENTA EASY');

        pageData().fleetMonitoring.filters.sortByFilter.title()
            .should('have.text', Localizer.fleetMonitoringPageFiltersSortByConstructionSite);

        pageData().fleetMonitoring.filters.sortOrderFilter.title()
            .should('have.text', Localizer.enumSortDirectionAsc);

        // Leave the page
        cy.visit('/rent');

        // Go back to the page
        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t')

        // Options are remembered
        pageData().fleetMonitoring.filters.constructionSiteFilter.input()
            .should('have.value', 'TESTITILAUS RENTA EASY');

        pageData().fleetMonitoring.filters.sortByFilter.title()
            .should('have.text', Localizer.fleetMonitoringPageFiltersSortByConstructionSite);

        pageData().fleetMonitoring.filters.sortOrderFilter.title()
            .should('have.text', Localizer.enumSortDirectionAsc);
    });
});