import Localizer from "../../../localization/Localizer";

describe('Desktop - Anonymous attached products tests', () => {
    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
        cy.intercept('POST', 'api/Product/ProductData', { fixture: 'productWithAttachedProducts' }).as('data');
        cy.intercept('POST', 'api/Product/GetProductAvailability', { fixture: 'productWithAttachedProductsAvailability' }).as('data');
        cy.intercept('POST', 'api/Product/GetRelatedProducts', { fixture: 'relatedProductsEmpty.json' }).as('data');
        cy.visit('tuote/mock-url');
        cy.waitSuccess('@data');
    });

    it('Should display all relevant data', () => {
        cy.get('#attached_products_container')
            .should('exist')
            .click();

        cy.contains('div', `${Localizer.attachedProductsTotalAmount}: 3`).should('exist');
        
        cy.get('[data-cy="29-1605"]').should('exist');
        cy.get('[data-cy="29-1606"]').should('exist');
        cy.get('[data-cy="29-1607"]').should('exist');
    });
});