import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Checkbox, Dropdown, Form, InlineType, TextInput,} from "@renta-apps/athenaeum-react-components";
import {InputType, PlanType} from "@/models/Enums";
import PlanPreview from "../PlanPreview/PlanPreview";
import RentaEasyController from "@/pages/RentaEasyController";
import DisplayCondition from "@/models/server/DisplayCondition";
import Localizer from "@/localization/Localizer";
import styles from "./PlanEditor.module.scss";
import PlanDefinitionInput from "@/models/server/PlanDefinitionInput";
import PlanModel from "@/models/server/PlanModel";
import PlanInputGroup from "@/models/server/PlanInputGroup";
import CreatePlanDefinitionRequest from "@/models/server/CreatePlanDefinitionRequest";
import SavePlanDefinitionRequest from "@/models/server/SavePlanDefinitionRequest";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import {validateMultipleEmails} from "@/helpers/Validators";

enum PropertyType {
    Label,
    Multiselect,
    DropDownInput,
    Mandatory
}

interface IPlanEditorProps {
    editMode: boolean;
    currentInput: PlanDefinitionInput | null;
    model: PlanModel | null;

    closeEditorAsync(): Promise<void>;

    /**
     * Is the {@link model} deleted.
     */
    deleted?: boolean;
}

interface IPlanEditorState {
    currentInput: PlanDefinitionInput | null;
    model: PlanModel | null;
    currentInputGroup: PlanInputGroup | null;
}

export default class PlanEditor extends BaseComponent<IPlanEditorProps, IPlanEditorState> {

    public state: IPlanEditorState = {
        currentInput: this.props.currentInput,
        model: this.props.model,
        currentInputGroup: this.props.model?.inputGroups?.[this.props.model?.inputGroups?.length - 1] ?? null
    };

    private leftPanelRef: Element | null = null;
    private readonly _dropDownSelectionEditorInputRef: React.RefObject<TextInput> = React.createRef();

    // Boolean getters

    public get editMode(): boolean {
        return this.props.editMode;
    }

    private get deleted(): boolean {
        return (!!this.props.deleted);
    }

    private get groupingEnabled(): boolean {
        return RentaEasyController.planEditorInputGroupingEnabled;
    }

    // Getters

    private get inputGroups(): PlanInputGroup[] {
        return this.model.inputGroups ?? [];
    }

    private get isNewDefinition(): boolean {
        return !this.inputGroups.length;
    }

    private get lastInputGroup(): PlanInputGroup | null {
        return this.inputGroups[this.inputGroups.length - 1] ?? null;
    }

    private get defaultGroup(): PlanInputGroup | null {
        return this.model.inputGroups?.length ? this.model.inputGroups[0] : null;
    }

    private get defaultGroupInputs(): PlanDefinitionInput[] {
        return this.defaultGroup?.inputs ?? [];
    }

    private get inputs(): PlanDefinitionInput[] {
        return this.defaultGroupInputs ?? [];
    }

    private get model(): PlanModel {
        return this.state.model!;
    }

    private get currentInput(): PlanDefinitionInput | null {
        return this.state.currentInput;
    }

    private get currentInputGroup(): PlanInputGroup | null {
        return this.state.currentInputGroup;
    }

    private get customerSelectorAlreadyAdded(): boolean {
        return this.getAllInputs()
            .map(input => input.inputType)
            .includes(InputType.CustomerSelect) ?? false;
    }

    private get inputToModify(): PlanDefinitionInput {
        return (this.groupingEnabled)
            ? this.currentInputGroup?.inputs.filter(a => a.id === this.currentInput!.id)[0]!
            : this.inputs.filter(a => a.id === this.currentInput!.id)[0];
    }

    private findInputGroup(groupId: number): PlanInputGroup | null {
        return this.inputGroups?.find(group => group.groupId === groupId) ?? null;
    }

    private getDefaultGroup(): PlanInputGroup {
        return this.findInputGroup(0)!;
    }

    private notEmptyGroups(inputGroups: PlanInputGroup[]): PlanInputGroup[] {
        return inputGroups.filter(group => group.inputs.length > 0);
    }

    // Async-methods

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private static async updateInputModels(
        value: string | boolean | number,
        propertyType: PropertyType,
        inputGroups: PlanInputGroup[],
        currentInputGroup: PlanInputGroup,
        currentInput: PlanDefinitionInput,
        dropdownOptionIndex?: number,
    ): Promise<{ inputGroups: PlanInputGroup[], currentInputGroup: PlanInputGroup, currentInput: PlanDefinitionInput }> {
        const inputGroupIndex = inputGroups.findIndex(x => x.groupId === currentInputGroup.groupId) ?? null;

        if (inputGroupIndex === null) {
            return {currentInputGroup, inputGroups, currentInput};
        }

        const inputIndex = inputGroups[inputGroupIndex].inputs.findIndex(input => input.id === currentInput.id) ?? null;

        if (inputIndex === null) {
            return {currentInputGroup, inputGroups, currentInput};
        }

        if ((propertyType === PropertyType.Label) && (typeof value === "string")) {
            inputGroups[inputGroupIndex].inputs[inputIndex].label = value;
        }
        if ((propertyType === PropertyType.Multiselect) && (typeof value === "boolean")) {
            inputGroups[inputGroupIndex].inputs[inputIndex].multiselect = value;
        }
        if ((propertyType === PropertyType.Mandatory) && (typeof value === "boolean")) {
            inputGroups[inputGroupIndex].inputs[inputIndex].isMandatory = value;
        }
        if ((propertyType === PropertyType.DropDownInput) && (typeof value === "string") && (dropdownOptionIndex)) {
            inputGroups[inputGroupIndex].inputs[inputIndex].inputValues[dropdownOptionIndex] = value;
        }

        return {currentInputGroup, inputGroups, currentInput};
    }

    public async editInputPropertiesAsync(value: string | boolean | number, propertyType: PropertyType, index?: number): Promise<void> {
        const {currentInputGroup, inputGroups, currentInput} = await PlanEditor.updateInputModels(
            value,
            propertyType,
            this.inputGroups,
            this.currentInputGroup!,
            this.currentInput!,
            index
        );
        this.setState({
            model: {
                ...this.model,
                inputGroups
            }, currentInput: currentInput, currentInputGroup
        });
    }

    public async removeCurrentInputValuesAsync(index: number): Promise<void> {
        const inputValueToChange: PlanDefinitionInput = this.inputToModify;
        this.updateDisplayConditionsValueRemoved(index, this.currentInput!.id!);
        inputValueToChange.inputValues.splice(index, 1);

        await this.reRenderAsync();
    }

    private updateDisplayConditionsValueRemoved(index: number, modifiedInputId: string): void {
        this.getAllInputs().forEach(input => {
            let conditionToRemove: DisplayCondition | null = null;
            input.displayConditions.forEach(condition => {
                if (modifiedInputId === condition?.inputId) {
                    if (index.toString() === condition?.inputValueId) {
                        conditionToRemove = condition;
                    }
                    else if (index < Number(condition!.inputValueId ?? -1)) {
                        condition!.inputValueId = (Number(condition!.inputValueId) - 1).toString();
                    }
                }
            });
            if (conditionToRemove) {
                input.displayConditions.remove(conditionToRemove);
            }
        });
    }

    private updateDisplayConditionsInputRemoved(deletedInputIds: string[]): void {
        this.getAllInputs().forEach(input => {
            const conditionsToRemove = input.displayConditions.filter(condition =>
                deletedInputIds.some(id => id === condition?.inputId));
            input.displayConditions.remove(conditionsToRemove);
        });
    }

    public async removeCurrentDisplayConditionAsync(index: number): Promise<void> {
        const inputValueToChange: PlanDefinitionInput = this.getAllInputs().filter(i => i.id === this.currentInput!.id)[0];
        inputValueToChange.displayConditions?.splice(index, 1);

        await this.reRenderAsync();
    }

    private getPossibleDisplayConditionInputs(): PlanDefinitionInput[] {
        const inputs = this.getAllInputs();
        return inputs.filter(
            input => input.id !== this.currentInput!.id &&
                (input.inputType === InputType.Dropdown || input.inputType === InputType.Checkbox));
    }

    private getAllInputs(): PlanDefinitionInput[] {
        return this.inputGroups.map(group => group.inputs).flat();
    }

    private getSelectedDisplayConditionInput(inputId: string | null | undefined): PlanDefinitionInput | null {
        if (!inputId) {
            return null;
        }
        const index = this.getPossibleDisplayConditionInputs().find(i => i.id === inputId);
        return index ?? null;
    }

    private getSelectedDisplayConditionInputValue(inputId: string | null | undefined, inputValueId: string | null | undefined): string | null {
        if (!inputId || !inputValueId) {
            return null;
        }
        const inputValues = this.getSelectedDisplayConditionInput(inputId);
        if (inputValues && inputValues.inputValues?.length >= Number(inputValueId)) {
            return inputValues.inputValues[Number(inputValueId)];
        }
        return null;
    }

    private async setDisplayConditionInputSelectedAsync(condition: DisplayCondition | null, item: PlanDefinitionInput | null): Promise<void> {
        condition!.inputId = item?.id ?? "";
        // Initializing checkbox value, otherwise it will be ""
        if (InputType.Checkbox === item?.inputType && "true" !== condition!.inputValue) {
            condition!.inputValue = "false";
        }
        await this.reRenderAsync();
    }

    private getDisplayConditionType(condition: DisplayCondition | null): InputType | null {
        if (!condition) {
            return null;
        }
        const conditions = this.getPossibleDisplayConditionInputs();
        const input = conditions.find(i => i.id === condition.inputId);
        return input?.inputType ?? null;
    }

    private async displayConditionItemSelectedAsync(condition: DisplayCondition, item: string): Promise<any> {
        const type = this.getDisplayConditionType(condition);
        if (InputType.Checkbox === type) {
            condition.inputValue = item;
            condition.inputValueId = null;
        }
        else if (InputType.Dropdown === type) {
            condition.inputValue = null;
            condition.inputValueId = this.getSelectedDisplayConditionInput(condition.inputId)?.inputValues?.findIndex(
                i => i === item).toString() ?? "";
        }
    }

    private async addConditionToCurrentInputValueAsync(): Promise<void> {
        const input = this.currentInput!;
        if (input.displayConditions === null) {
            input.displayConditions = [];
        }
        input.displayConditions.push(new DisplayCondition());
        await this.reRenderAsync();
    }

    private async setCurrentInputGroup(group: PlanInputGroup): Promise<void> {
        this.setState({currentInputGroup: group});
    }

    public async createDefaultGroupAsync(): Promise<void> {
        const defaultGroup = {
            groupHeader: "",
            groupId: 0,
            inputs: []
        } as PlanInputGroup;

        this.setState({model: {...this.model, inputGroups: [defaultGroup]}});
    }

    public async addInputToInputGroupAsync(type: InputType): Promise<void> {
        let groupToAddTo: PlanInputGroup;

        // New definition, add default group
        if (this.isNewDefinition) {
            await this.createDefaultGroupAsync();
            groupToAddTo = this.getDefaultGroup();
            await this.setCurrentInputGroup(groupToAddTo);
        }
        // Existing definition
        else {
            groupToAddTo = this.currentInputGroup!;
        }
        
        const inputType: PlanDefinitionInput = {
            inputType: type,
            id: `input_${crypto.randomUUID()}`,
            inputValue: "",
            inputValues: [],
            selectedValue: "",
            selectedValues: [],
            multiselect: false,
            label: "",
            isMandatory: false,
            IsPlanDefinitionItem: true,
            displayConditions: [],
            selectedImage: null,
            selectedImages: [],
            hasSingleImageInput: false,
            hasMultipleImageInput: false,
            singleSavedImageReference: "",
            multipleSavedImageReference: []
        };

        groupToAddTo.inputs.push(inputType);

        if (!this.groupingEnabled) {
            // Use only default group, always first and only item in the list
            this.inputGroups[0] = groupToAddTo;
        }
        else {
            const groupIndex = this.inputGroups.findIndex(group => group.groupId === groupToAddTo.groupId);
            this.inputGroups[groupIndex] = groupToAddTo;
        }

        await this.reRenderAsync();
    }

    public async addNewGroup(): Promise<void> {

        // Don't add another group if previous is still empty
        if (!this.lastInputGroup?.inputs.length) {
            return;
        }

        const lastGroupId = Math.max(...this.inputGroups.map((group) => group.groupId));

        const newGroup = {
            groupHeader: "",
            groupId: lastGroupId + 1 ?? 0,
            inputs: []
        } as PlanInputGroup;

        this.model.inputGroups?.push(newGroup);

        await this.cancelInputEditingAsync();
        await this.setCurrentInputGroup(newGroup);

        await this.reRenderAsync();
    }

    private async toggleDefinitionIsDeletedAsync(): Promise<void> {
        await this.postAsync(`/api/Plans/SetPlanDefinitionIsDeleted?definitionId=${this.model.id}`, !this.deleted);
        await this.closeEditorAsync();
    }

    private async closeEditorAsync(): Promise<void> {
        await this.props.closeEditorAsync();
    }

    public async setHeadLineAsync(value: string): Promise<void> {
        this.model.name = value;
        await this.reRenderAsync();
    }

    public async setIconAsync(value: string): Promise<void> {
        this.model.icon = value;
        await this.reRenderAsync();
    }

    public async setReceiverAsync(value: string): Promise<void> {
        this.model.receivers = value;
        await this.reRenderAsync();
    }

    public async setSendToUsersFavoriteDepotAsync(value: boolean): Promise<void> {
        this.model.sendToUsersFavoriteDepot = value;
        await this.reRenderAsync();
    }

    public async cancelInputEditingAsync(deletedInputIds?: string[]): Promise<void> {
        if (deletedInputIds) {
            this.updateDisplayConditionsInputRemoved(deletedInputIds);
        }
        this.setState({currentInput: null});
    }

    private removeInvalidConditions(inputs: PlanDefinitionInput[]): PlanDefinitionInput[] {
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].displayConditions) {
                let conditionsToRemove: DisplayCondition[] = [];
                for (let j = 0; j < inputs[i].displayConditions.length; j++) {
                    const condition = inputs[i].displayConditions[j];
                    if (!condition || !condition.inputId || condition.inputId === ""
                        || (!condition.inputValueId && !condition.inputValue)
                        || (!condition.inputValue && "" === condition.inputValueId)) {
                        conditionsToRemove.push(condition!);
                    }
                }
                conditionsToRemove.forEach(condition => inputs[i].displayConditions.remove(condition));
            }
        }
        return inputs;
    }

    private async createDefinitionAsync(): Promise<void> {

        const inputGroups = this.notEmptyGroups(this.inputGroups);
        inputGroups.forEach(group => group.inputs = this.removeInvalidConditions(group.inputs));

        const createRequest: CreatePlanDefinitionRequest = {
            sendToUsersFavoriteDepot: this.model.sendToUsersFavoriteDepot,
            name: this.model!.name ?? "",
            isDeleted: false,
            planType: PlanType.Custom,
            inputGroups: inputGroups,
            icon: this.model!.icon,
            receivers: this.model.receivers
        };

        if (!this.model!.id) {
            await this.postAsync("/api/Plans/CreatePlanDefinition", createRequest);
            await this.props.closeEditorAsync();
        }
        else {
            let saveRequest: SavePlanDefinitionRequest = new SavePlanDefinitionRequest(createRequest);
            saveRequest.id = this.model.id;

            await this.postAsync("/api/Plans/SavePlanDefinition", saveRequest);

            await this.props.closeEditorAsync();
        }
    }

    public async addValueToCurrentInputValuesAsync(): Promise<void> {
        const newValue: string = "new value";
        this.currentInput!.inputValues.push(newValue);

        await this.reRenderAsync();
    }

    private async renderInputEditorAsync(definitionInput: PlanDefinitionInput, group: PlanInputGroup | null): Promise<void> {
        this.setState({currentInput: definitionInput, currentInputGroup: group});
    }

    // Renders

    private renderLeftPanel(): React.ReactNode {
        return (
            <div
                ref={ref => {
                    this.leftPanelRef = ref;
                }}
            >
                <Form id="form" onSubmit={async (_, _data) => await this.createDefinitionAsync()}>

                    <TextInput id="definitionHeadline"
                               required
                               label={Localizer.planDefinitionsEditHeadline}
                               value={this.model.name ?? ""}
                               onChange={async (_input, value) => await this.setHeadLineAsync(value)}
                    />

                    <TextInput id="definitionIcon"
                               label={Localizer.serviceDefinitionsEditIcon}
                               value={this.model.icon ?? ""}
                               onChange={async (_input, value) => await this.setIconAsync(value)}
                    />

                    <Checkbox id="definitionSendToReceiversCheckbox"
                              label={Localizer.serviceDefinitionsEditSendToReceiversFavoriteDepot}
                              value={this.model.sendToUsersFavoriteDepot}
                              onChange={async (_sender, checked) => await this.setSendToUsersFavoriteDepotAsync(checked)}
                              inline inlineType={InlineType.Right}
                    />

                    {
                        (!this.model.sendToUsersFavoriteDepot) &&
                        <InlineTooltip className={styles.weeklyPriceTooltip}
                                       text={Localizer.planDefinitionsEmptyReceiverTooltipInfo}
                        />
                    }

                    <TextInput id="definitionsEmails"
                               hidden={this.model.sendToUsersFavoriteDepot}
                               label={Localizer.serviceDefinitionsEditReceiver}
                               validators={[validateMultipleEmails]}
                               value={this.model.receivers ?? ""}
                               onChange={async (_input, value) => await this.setReceiverAsync(value)}
                    />

                    {
                        Localizer.serviceDefinitionsEditAddInputLabel
                    }

                    <div className={styles.inputButtonsContainer}>

                        <Button id="addTextInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeText}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.Text)}
                        />

                        <Button id="addTextAreaInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeTextArea}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.TextArea)}
                        />

                        <Button id="addDateInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeDate}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.Date)}
                        />

                        <Button id="addDateRangeInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeDateRange}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.DateRange)}
                        />

                        <Button id="addNumberInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeNumber}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.Number)}
                        />

                        <Button id="addDropdownInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeDropdown}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.Dropdown)}
                        />

                        <Button id="addCheckboxInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeCheckbox}
                                onClick={async () => await this.addInputToInputGroupAsync(InputType.Checkbox)}
                        />

                        <Button id="addLocationInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeLocationPicker}
                                onClick={async () => this.addInputToInputGroupAsync(InputType.LocationPicker)}
                        />

                        <Button id="addFileInput"
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeFileInput}
                                onClick={async () => this.addInputToInputGroupAsync(InputType.FileInput)}
                        />

                        <Button id="addCustomerSelectInput"
                                disabled={this.customerSelectorAlreadyAdded}
                                type={ButtonType.Orange}
                                label={Localizer.enumInputTypeCustomerSelect}
                                onClick={async () => this.addInputToInputGroupAsync(InputType.CustomerSelect)}
                        />

                        {(this.groupingEnabled) &&
                            (
                                <Button id="addPageSeparatorInput"
                                        type={ButtonType.Orange}
                                        label={Localizer.serviceEditorPageSeparator}
                                        onClick={async () => this.addNewGroup()}
                                />
                            )
                        }

                    </div>

                    <div className={styles.inputButtonsSaveCancelContainer}>

                        <Button label={Localizer.formCancel}
                                onClick={async () => this.props.closeEditorAsync()}
                        />

                        <Button id="definitionDeleteButton"
                                label={(this.deleted) ? Localizer.genericRestore : Localizer.formDelete}
                                type={(this.deleted) ? ButtonType.Default : ButtonType.Danger}
                                onClick={async () => this.toggleDefinitionIsDeletedAsync()}
                                disabled={!this.model.id}
                        />

                        <Button id="definitionSubmitButton"
                                submit
                                type={ButtonType.Orange}
                                label={(this.model.id) ? Localizer.formSave : Localizer.genericCreate}
                        />

                    </div>
                </Form>
            </div>
        );
    }

    private renderInputEditor(): React.ReactNode {
        return (
            <div data-cy={"InputSettingsContainer"}>
                <h3>{Localizer.serviceDefinitionsEditEditTitle}</h3>
                {
                    this.currentInput && (
                        this.renderCurrentInputEditor()
                    )
                }
            </div>
        );
    }

    private renderDisplayConditionsEditor(): React.ReactNode {
        return (
            <div>
                {Localizer.serviceDefinitionsEditDisplayConditions}
                {
                    this.currentInput && (
                        this.renderCurrentDisplayConditionsEditor()
                    )
                }
            </div>
        );
    }

    private renderCurrentInputEditor(): React.ReactNode {
        return (
            <React.Fragment>
                {
                    this.renderLabelEditor()
                }

                {
                    ((this.currentInput!.inputType === InputType.Dropdown) || (this.currentInput!.inputType === InputType.FileInput)) &&
                    this.renderMultiSelectOptionAsync()
                }
                {
                    (this.currentInput!.inputType === InputType.Dropdown) &&
                    this.renderDropdownEditor()
                }
                {
                    this.renderDisplayConditionsEditor()
                }

                <ButtonContainer className={styles.inputEditorButtonContainer}>
                    <Button label={Localizer.genericClose}
                            onClick={async () => await this.cancelInputEditingAsync()}

                    />
                </ButtonContainer>
            </React.Fragment>
        );
    }

    private renderLabelEditor(): React.ReactNode {
        return (
            <React.Fragment>
                <div>
                    <TextInput id={"editInputLabel"}
                               label={Localizer.serviceDefinitionsEditLabel}
                               value={this.currentInput?.label ?? ""}
                               onChange={async (_item, value) => await this.editInputPropertiesAsync(value, PropertyType.Label)}
                    />
                </div>
                <div>
                    <Checkbox inline inlineType={InlineType.Right}
                              label={Localizer.serviceDefinitionsEditMandatoryLabel}
                              value={this.currentInput!.isMandatory}
                              onChange={async (_item, value) => await this.editInputPropertiesAsync(value, PropertyType.Mandatory)}
                    />
                </div>

            </React.Fragment>
        );
    }

    private renderMultiSelectOptionAsync(): React.ReactNode {
        return (
            <React.Fragment>
                <div>
                    <Checkbox inline inlineType={InlineType.Right}
                              label={Localizer.serviceDefinitionsEditMultiselectLabel}
                              value={this.currentInput!.multiselect}
                              onChange={async (_item, value) => await this.editInputPropertiesAsync(value, PropertyType.Multiselect)}
                    />
                </div>
            </React.Fragment>
        );
    }

    private renderDropdownEditor(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    (this.currentInput?.inputValues) &&
                    (
                        this.currentInput.inputValues.map((inputValue, index) =>
                            (
                                <div key={index}
                                     className={styles.dropDownSection}
                                >

                                    <TextInput id={"valueEditor-" + index}
                                               ref={this._dropDownSelectionEditorInputRef}
                                               label={`${Localizer.serviceDefinitionsEditSection} ${index}`}
                                               value={inputValue ?? ""}
                                               onChange={async (_item, value) => await this.editInputPropertiesAsync(value, PropertyType.DropDownInput, index)}
                                               data-cy={"ValueEditor"}
                                    />

                                    <Button id={"valueDeleteButton-" + index}
                                            icon={{name: "trash"}}
                                            type={ButtonType.Orange}
                                            onClick={async () => await this.removeCurrentInputValuesAsync(index)}
                                            data-cy={"ValueDeleteButton"}
                                    />

                                </div>
                            )
                        )
                    )
                }

                <div data-cy={"AddNewSection"}>
                    <Button id={"addNewSection-" + this.currentInput?.id ?? ""}
                            label={Localizer.serviceDefinitionsEditAddSection}
                            icon={{name: "plus"}}
                            type={ButtonType.Orange}
                            onClick={async () => await this.addValueToCurrentInputValuesAsync()}
                    />
                </div>

            </React.Fragment>
        );
    }

    private renderCurrentDisplayConditionsEditor(): React.ReactNode {
        return (
            <React.Fragment>
                {
                    (this.currentInput?.displayConditions) &&
                    (
                        this.currentInput.displayConditions.map((displayCondition, index) =>
                            (
                                <div key={"displayConditionSelector" + index}
                                     className={styles.displayConditionEditor}>
                                    <Dropdown id={"conditionDropdown-" + index}
                                              label={`${Localizer.serviceDefinitionsEditCondition} ${index}`}
                                              items={this.getPossibleDisplayConditionInputs()}
                                              selectedItem={this.getSelectedDisplayConditionInput(displayCondition?.inputId)}
                                              onChange={async (_sender, item: PlanDefinitionInput | null) => {
                                                  await this.setDisplayConditionInputSelectedAsync(displayCondition, item);
                                              }}
                                    />
                                    <div key={"displayConditionSelector " + index}>
                                        {this.renderCurrentDisplayConditionsSelector(displayCondition, index)}
                                    </div>
                                    <Button id={"conditionDeleteButton-" + index}
                                            icon={{name: "trash"}}
                                            type={ButtonType.Orange}
                                            onClick={async () => await this.removeCurrentDisplayConditionAsync(index)}
                                    />
                                    <hr/>
                                </div>
                            )
                        )
                    )
                }

                <div>
                    <Button id={"conditionAddButton"}
                            label={Localizer.serviceDefinitionsEditAddCondition}
                            icon={{name: "plus"}}
                            type={ButtonType.Orange}
                            onClick={async () => await this.addConditionToCurrentInputValueAsync()}
                    />
                </div>

            </React.Fragment>
        );
    }

    private renderCurrentDisplayConditionsSelector(condition: DisplayCondition | null, index: number): React.ReactNode {
        const definitionInput = this.getSelectedDisplayConditionInput(condition?.inputId);
        return (
            <React.Fragment>
                {
                    (condition && definitionInput && InputType.Dropdown === this.getDisplayConditionType(condition)) &&
                    <Dropdown id={"displayConditionsSelector-" + index}
                              selectedItem={this.getSelectedDisplayConditionInputValue(condition.inputId, condition.inputValueId)}
                              items={definitionInput!.inputValues}
                              label={definitionInput.label ?? "no label"}
                              onChange={async (_sender, item: string) =>
                                  await this.displayConditionItemSelectedAsync(condition, item)}
                    />
                }
                {
                    (condition && definitionInput && InputType.Checkbox === this.getDisplayConditionType(condition)) &&
                    <Checkbox inline inlineType={InlineType.Right}
                              value={condition.inputValue === "true"}
                              id={"displayConditionsSelector-" + index}
                              label={definitionInput.label ?? "no label"}
                              onChange={async (_cb, value) =>
                                  await this.displayConditionItemSelectedAsync(condition, value ? "true" : "false")}
                    />
                }
            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css("row", styles.planContainer)}>
                <div className={"col-md-3"} style={this.editMode ? {borderRight: "1px solid"} : {}}>
                    {
                        (this.editMode) &&
                        (
                            this.renderLeftPanel()
                        )
                    }
                </div>

                <div className={"col-md-6 overflow-auto"} style={{maxHeight: this.leftPanelRef?.clientHeight}}>
                    {
                        <PlanPreview model={this.model}
                                     editMode={this.editMode}
                                     deleted={this.deleted}
                                     groupingEnabled={this.groupingEnabled}
                                     selectedGroup={this.currentInputGroup}
                                     setSelectedInputGroupAsync={async (group) => await this.setCurrentInputGroup(group)}
                                     closePreviewAsync={async () => await this.closeEditorAsync()}
                                     openInputEditorAsync={async (value, group) => await this.renderInputEditorAsync(value, group)}
                                     closeInputEditorAsync={async (deletedInputIds) => await this.cancelInputEditingAsync(deletedInputIds)}
                        />
                    }
                </div>

                <div className={"col-md-3"}
                     style={(this.editMode)
                         ? {borderLeft: "1px solid"}
                         : {}}
                >
                    {
                        (this.currentInput && this.editMode) && (
                            this.renderInputEditor()
                        )
                    }
                </div>

            </div>
        );
    }
}