import CategoryModel from "@/models/server/CategoryModel";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import Localizer from "@/localization/Localizer";
import ProductModel from "@/models/server/ProductModel";

export class BreadCrumbHelper {
    private static convertCategoryModelToBreadCrumbItem = (item: CategoryModel, pricingTool: boolean): BreadCrumbItem => {
        const breadCrumb = new BreadCrumbItem();
        breadCrumb.id = item.id!;
        breadCrumb.name = item.name;
        breadCrumb.page = pricingTool
            ? "PricingTool"
            : Localizer.breadCrumbRent.toLowerCase();

        breadCrumb.pageParameter = (item.url)
            ? item.url
            : item.id!;
        return breadCrumb;
    };

    private static convertProductModelToBreadCrumbItem = (item: ProductModel | null, pricingTool: boolean): BreadCrumbItem[] => {
        if (!item) {
            return [];
        }
        const breadCrumb = new BreadCrumbItem();
        breadCrumb.id = item.id!;
        breadCrumb.name = item.name ?? "";
        breadCrumb.page = pricingTool
            ? "PricingTool"
            : Localizer.breadCrumbRent.toLowerCase();

        breadCrumb.pageParameter = (item.url)
            ? item.url
            : item.id!;
        return [breadCrumb];
    };

    public static convertCategoryToBreadCrumbItems(currentCategory: CategoryModel | null, isFavorites: boolean, isRentaFuture: boolean, pricingTool: boolean): BreadCrumbItem[] {

        /* Self invoked function to group related functionality */
        const rentBreadCrumbItem = ((pricingTool: boolean) => {
            const page = pricingTool ? "PricingTool" : Localizer.breadCrumbRent;
            const rentBreadCrumbItem = new BreadCrumbItem(page);
            rentBreadCrumbItem.page = page.toLowerCase();
            return rentBreadCrumbItem;
        })(pricingTool);

        if (!currentCategory) {
            return [rentBreadCrumbItem];
        }

        if (isRentaFuture) {
            return [rentBreadCrumbItem, BreadCrumbItem.rentaFutureCategory];
        }

        if (isFavorites) {
            return [rentBreadCrumbItem, BreadCrumbItem.favoriteCategory];
        }

        //  If we are at root level or category doesn't exist.
        if (!currentCategory) {
            return [rentBreadCrumbItem];
        }

        /* Self invoked function to group related functionality */
        const categoryBreadCrumbs: BreadCrumbItem[] = ((item: CategoryModel | null, pricingTool: boolean): BreadCrumbItem[] => {
            const getCategoryBreadCrumbsRecursive = (item: CategoryModel | null, items: BreadCrumbItem[] | null): BreadCrumbItem[] => {
                if ((!items) && (!item)) {
                    return [];
                }

                if (!item) {
                    return items ?? [];
                }

                /* Self invoked function to group related functionality - Converts CategoryModel to BreadCrumb */
                const breadCrumb = BreadCrumbHelper.convertCategoryModelToBreadCrumbItem(item, pricingTool);

                if (!items) {
                    return getCategoryBreadCrumbsRecursive(item.parent, [breadCrumb]);
                }

                return getCategoryBreadCrumbsRecursive(item.parent, [breadCrumb, ...items]);
            };
            return getCategoryBreadCrumbsRecursive(item, null);
        })(currentCategory, pricingTool);

        return [rentBreadCrumbItem, ...categoryBreadCrumbs];
    }

    public static convertProductToBreadCrumbItems(product: ProductModel | null, isFavorites: boolean, isRentaFuture: boolean, pricingTool: boolean): BreadCrumbItem[] {
        return BreadCrumbHelper.convertCategoryToBreadCrumbItems(product?.category ?? null, isFavorites, isRentaFuture, pricingTool);
    }
}
