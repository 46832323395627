import React, {ForwardedRef, forwardRef} from "react";
import styles from "./Panel.module.scss";

type PanelProps = {
    children: React.ReactNode;
    className?: string;
};

const Panel: React.ForwardRefExoticComponent<PanelProps & React.RefAttributes<HTMLDivElement>> =
    forwardRef<HTMLDivElement, PanelProps>(({className, children}: PanelProps, ref: ForwardedRef<HTMLDivElement>) => {
        return (
            <div className={`${styles.container} ${className ?? ''}`} ref={ref}>
                {children}
            </div>
        );
    }
);

export default Panel;