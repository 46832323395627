import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {AlertModel, ch} from "@renta-apps/athenaeum-react-common";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, Checkbox, DateInput, Dropdown, IconSize, OneColumn, SelectListItem, TextAreaInput, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";

import {ScheduleAction} from "@/models/Enums";
import {ScheduleModel} from "@/models/server/ScheduleModel";

import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import styles from "@/components/Schedules/Schedules.module.scss";

interface ISchedulesProps {

}

interface ISchedulesState {
    alertModel: AlertModel | null;
    schedules: ScheduleModel[];
    isLoading: boolean;
}

export default class Schedules extends BaseComponent<ISchedulesProps, ISchedulesState> {

    public state: ISchedulesState = {
        schedules: [],
        alertModel: null,
        isLoading: false
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const schedules: ScheduleModel[] = await this.postAsync("/api/Admin/Schedules");

        this.setState({schedules: schedules});
    }

    public get schedules(): ScheduleModel[] {
        return this.state.schedules;
    }

    private async deleteScheduleAsync(data: ScheduleModel): Promise<void> {

        const url: string = `/api/Admin/DeleteSchedule`;

        await this.postAsync(url, data.id);

    }

    private async saveScheduleAsync(data: ScheduleModel): Promise<void> {

        this.setState({isLoading: true});
        const url: string = `/api/Admin/SaveSchedule`;

        const response: ScheduleModel = await this.postAsync(url, data);

        data.id = response.id;

        this.setState({isLoading: false});
    }

    private async deleteAsync(item: ScheduleModel, index: number) {
        this.schedules.splice(index, 1);

        if (item.id) {
            await this.deleteScheduleAsync(item);
        }
        await this.reRenderAsync();
    }

    private setTitle(item: ScheduleModel, value: string): void {
        item.title = value;
    }

    private setData(item: ScheduleModel, value: string): void {
        item.data = value;
    }

    private setRule(item: ScheduleModel, value: string): void {
        item.rule = value;
    }

    private setEnabled(item: ScheduleModel, input: boolean): void {
        item.enabled = input;
    }

    private async setAction(item: ScheduleModel, value: SelectListItem) {
        const bb: ScheduleAction = value.value as any as ScheduleAction;

        item.action = bb;
    }

    private async addAsync(index: number, after: boolean = true): Promise<void> {
        let item: ScheduleModel = {
            id: null,
            title: "",
            enabled: true,
            rule: "",
            data: "",
            action: ScheduleAction.ConstructionSiteEquipmentList,
            lastOccurrence: Utility.date(),
            isScheduleModel: true

        };

        let newIndex: number = (after) ? index + 1 : index;
        this.schedules.splice(newIndex, 0, item);

        await this.reRenderAsync();
    }

    private renderSchedule(item: ScheduleModel, index: number): React.ReactNode {
        return (
            <React.Fragment key={ch.getId()}>
                <tr key={index}>
                    <td>
                        <span>
                            <TwoColumns>
                            <TextInput value={item.title!}
                                       label={"Title"}
                                       onChange={async (input, value) => await this.setTitle(item, value)}
                            />

                            <TextInput label={"Rule"}
                                       required={!item.id}
                                       value={item.rule}
                                       id={`rule_${index}`}
                                       onChange={async (input, value) => await this.setRule(item, value)}
                            />
                            </TwoColumns>
                            <OneColumn>
                            <TextAreaInput label={"Data"}
                                           required={!item.id}
                                           value={item.data}
                                           id={`data_${index}`}
                                           onChange={async (input, value) => await this.setData(item, value)}
                            />
                            </OneColumn>
                             <OneColumn>
                            <Checkbox label={"Enabled"}
                                      className={styles.checkbox}
                                      value={item.enabled}
                                      onChange={async (input, value) => await this.setEnabled(item, value)}
                            />
                             </OneColumn>

                            <TwoColumns>
                              <Dropdown disabled={!!item.id}
                                        label={`Action`}
                                        items={EnumProvider.getScheduleActionItems()}
                                        selectedItem={EnumProvider.getScheduleActionItem(item.action!)}
                                        onChange={async (sender, value) => await this.setAction(item, value!)}
                              />

                            <DateInput readonly showTime
                                       value={item.lastOccurrence!}
                                       label={"Last occurrence"}

                            />
                           </TwoColumns>
                            <div className={styles.buttonContainer}>
                                <Button type={ButtonType.Primary}
                                        icon={{name: "save", size: IconSize.Normal}}
                                        label={Localizer.genericSave}
                                        onClick={async () => await this.saveScheduleAsync(item)}
                                />

                               <Button type={ButtonType.Default}
                                       icon={{name: "trash", size: IconSize.Normal}}
                                       label={Localizer.formDelete}
                                       onClick={async () => await this.deleteAsync(item, index)}
                               />
                               </div>

                        </span>
                    </td>
                    <td>
                    </td>

                </tr>
            </React.Fragment>
        );
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.NewsEditor}>

                <Button type={ButtonType.Orange}
                        label={"Add schedule"}
                        onClick={async () => await this.addAsync(0, false)}
                />
                <table className={styles.table}>
                    <tbody>
                    {
                        this.schedules.map((item, index) => this.renderSchedule(item, index))
                    }
                    </tbody>
                </table>
            </div>

        );
    }

}