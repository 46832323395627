import React from "react";
import {
    Button, ButtonType,
    Dropdown, DropdownOrderBy, Form, IconSize,
    Modal, ModalSize,
    PageContainer,
    PageHeader,
    PageRow, SelectListItem, TextInput,
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import Localizer from "@/localization/Localizer";
import styles from "./PricingToolDiscounts.module.scss";
import DiscountTemplateInfo from "@/models/server/Models/PricingTool/DiscountTemplateInfo";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {OrganizationContractInfo} from "@/models/server/Models/PricingTool/OrganizationContractInfo";
import {ContractConstructionSite} from "@/models/server/Models/PricingTool/ContractConstructionSite";
import {PricingToolDiscountStatus} from "@/models/Enums";
import CreateDiscountTemplateRequest from "@/models/server/Requests/PricingTool/CreateDiscountTemplateRequest";
import PageDefinitions, {IPageRouteParams} from "@/providers/PageDefinitions";
import ListDiscountsRequest from "@/models/server/Requests/PricingTool/ListDiscountsRequest";
import SingleDiscountRequest from "@/models/server/Requests/PricingTool/SingleDiscountRequest";
import {IPricingToolDiscountParams} from "@/pages/PricingToolDiscount/PricingToolDiscount";


export interface IPricingToolDiscountsParams extends BasePageParameters {
}

interface IDiscountsState {
    organizationContracts: SelectListItem[];
    contractConstructionSites: SelectListItem[];
    discounts: DiscountTemplateInfo[];
    templates: DiscountTemplateInfo[];
    selectedContract: SelectListItem | null;
    selectedConstructionSite: SelectListItem | null;
    selectedDiscount: SelectListItem | null;
    selectedTemplate: SelectListItem | null;
    newDiscountName: string;
}

export default class PricingToolDiscounts extends AdminPage<IPricingToolDiscountsParams, IDiscountsState> {

    /***
     * PricingToolDiscounts is very similar to PricingToolTemplates. It can and probably should be merged into one component.
     * Maybe it can be done along with moving to functional components.
     */
    
    public state: IDiscountsState = {
        organizationContracts: [],
        contractConstructionSites: [],
        discounts: [],
        templates: [],
        selectedContract: null,
        selectedConstructionSite: null,
        selectedDiscount: null,
        selectedTemplate: null,
        newDiscountName: "",
    };

    protected get title(): string {
        return Localizer.pricingToolPricingToolHeader;
    }

    private readonly _addModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _deleteModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _duplicateModalRef: React.RefObject<Modal> = React.createRef();

    protected get breadCrumbs(): BreadCrumbItem[] {
        const salesToolsBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbSalesTools, PageDefinitions.salesTools.route());
        const pricingToolBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbPricingTool, PageDefinitions.pricingTool.route());
        return [salesToolsBreadcrumb, pricingToolBreadcrumb,];
    }

    public async initializeAsync(): Promise<void> {
        await this.getContractItemsAsync();
        await this.getTemplatesAsync();
        await super.initializeAsync();
    }

    private async getContractItemsAsync(): Promise<void> {
        const contracts: OrganizationContractInfo[] = await this.postAsync("/api/PricingTool/ListOrganizationContracts");
        await this.setState({organizationContracts: contracts ? contracts.map(contract => {
                return new SelectListItem(contract.contractId, contract.contractName);
            }) : []});
        await this.reRenderAsync();
    }

    private async getContractConstructionSitesItemsAsync(contractId: string): Promise<void> {
        const constructionSites: ContractConstructionSite[] = await this.postAsync("/api/PricingTool/ListContractConstructionSites", contractId);

        this.setState({contractConstructionSites: constructionSites ? constructionSites.map(site => {
                return new SelectListItem(site.constructionSiteId, site.constructionSiteName);
            }) : []});
        await this.reRenderAsync();
    }

    private async getDiscountsAsync(): Promise<void> {
        const request: ListDiscountsRequest = new ListDiscountsRequest();
        request.organizationContractId = this.state.selectedContract?.value || null;
        request.constructionSiteId = this.state.selectedConstructionSite?.value || null;
        const discountsList: DiscountTemplateInfo[] = await this.postAsync("/api/PricingTool/ListContractDiscounts", request);
        if (discountsList) {
            await this.setState({
                discounts: discountsList,
                selectedDiscount: null,
            });
        }
    }

    private async getTemplatesAsync(): Promise<void> {
        const request: ListDiscountsRequest = new ListDiscountsRequest();
        request.status = PricingToolDiscountStatus.Active;
        const templatesList: DiscountTemplateInfo[] = await this.postAsync("/api/PricingTool/ListDiscountTemplates", request);
        if (templatesList) {
            await this.setState({
                templates: templatesList,
                selectedTemplate: null,
            });
        }
    }

    private async deleteDiscountAsync(discountId: string): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = discountId;
        await this.postAsync("/api/PricingTool/DeleteContractDiscount", request);
    }

    private async addDiscountAsync(newDiscountName: string, organizationContractId: string, construnctionSiteId: string | undefined = undefined, sourceDiscountId: string | undefined = undefined): Promise<void> {
        const request: CreateDiscountTemplateRequest = new CreateDiscountTemplateRequest();
        request.discountName = newDiscountName;
        request.organizationContractId = organizationContractId;
        request.constructionSiteId = construnctionSiteId;
        request.sourceDiscountId = sourceDiscountId;
        await this.postAsync("/api/PricingTool/CreateContractDiscount", request);
    }

    private async duplicateDiscountAsync(newDiscountName: string, organizationContractId: string, construnctionSiteId: string | undefined = undefined, sourceDiscountId: string | undefined = undefined): Promise<void> {
        const request: CreateDiscountTemplateRequest = new CreateDiscountTemplateRequest();
        request.discountName = newDiscountName;
        request.organizationContractId = organizationContractId;
        request.constructionSiteId = construnctionSiteId;
        request.sourceDiscountId = sourceDiscountId;
        await this.postAsync("/api/PricingTool/CreateContractDiscount", request);
    }

    private async setSelectedContractAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedContract: item});
        if (item?.value) {
            this.setState({selectedDiscount: null});
            this.setState({selectedConstructionSite: null});
            await this.getContractConstructionSitesItemsAsync(item.value);
            await this.getDiscountsAsync()
        }
    }

    private async setSelectedConstructionSiteAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedConstructionSite: item});
        await this.getDiscountsAsync();
    }
    private async setSelectedDiscountAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedDiscount: item});
    }

    private async setSelectedTemplateAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedTemplate: item});
    }

    private async openEditDiscountAsync(): Promise<any> {
        if (this.state.selectedDiscount?.value) {
            const route = PageDefinitions.pricingToolDiscount.route({params: {discountId: this.state.selectedDiscount?.value}} as IPageRouteParams<IPricingToolDiscountParams>);  
            await PageRouteProvider.redirectAsync(route);
        }
    }
    
    private async openDeleteDiscountAsync(): Promise<any> {
        this._deleteModalRef.current?.openAsync();
    }

    private async handleDeleteDiscountSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.selectedDiscount?.value) {
            await this.deleteDiscountAsync(this.state.selectedDiscount.value);
        }
        await this.getDiscountsAsync();
        this._deleteModalRef.current?.closeAsync();
    }

    private async openDuplicateDiscountAsync(): Promise<any> {
        this._duplicateModalRef.current?.openAsync();
    }

    private async handleDuplicateDiscountSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.selectedDiscount?.value && this.state.selectedContract?.value) {
            await this.duplicateDiscountAsync(this.state.newDiscountName, this.state.selectedContract?.value, this.state.selectedConstructionSite?.value, this.state.selectedDiscount.value);
        }
        this.setState({newDiscountName: ""});
        this._duplicateModalRef.current?.closeAsync();
        await this.getDiscountsAsync();
    }

    private async openAddNewDiscountAsync(): Promise<any> {
        this.setState({newDiscountName: ""});
        this._addModalRef.current?.openAsync();
    }

    private async handleAddNewDiscountSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.newDiscountName && this.state.selectedContract?.value) {
            await this.addDiscountAsync(this.state.newDiscountName, this.state.selectedContract.value, this.state.selectedConstructionSite?.value, this.state.selectedTemplate?.value);
        }
        this.setState({newDiscountName: ""});
        this.setState({selectedTemplate: null});
        this._addModalRef.current?.closeAsync();
        await this.getDiscountsAsync();
    }
    
    public renderComponents(): React.ReactNode {
        return (
            <div className={styles.menuContainer}>
                <Dropdown id="SelectContractDD"
                          className={styles.dropdown}
                          label={Localizer.pricingToolSelectOrganizationContract}
                          minWidth="100px"
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.state.selectedContract}
                          items={this.state.organizationContracts}
                          onChange={async (sender, item) => await this.setSelectedContractAsync(item)}
                />
                <Dropdown id="SelectConstructionSiteDD"
                          className={styles.dropdown}
                          label={Localizer.pricingToolSelectConstructionSite}
                          minWidth="100px"
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.state.selectedConstructionSite}
                          items={this.state.contractConstructionSites}
                          disabled={!this.state.selectedContract}
                          onChange={async (sender, item) => await this.setSelectedConstructionSiteAsync(item)}
                />
                <Dropdown id="SelectDiscountDD"
                          className={styles.dropdown}
                          label={Localizer.pricingToolSelectDiscount}
                          minWidth="100px"
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.state.selectedDiscount}
                          items={this.state.discounts.map(discount => {
                              return new SelectListItem(discount.discountId, discount.discountName, PricingToolDiscountStatus[discount.status]);
                          }) ?? []}
                          disabled={!this.state.selectedContract}
                          onChange={async (sender, item) => await this.setSelectedDiscountAsync(item)}
                />
                <Button id="EditDiscountBtn"
                        className={styles.button}
                        label={Localizer.pricingToolEditDiscount}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedDiscount}
                        onClick={async () => await this.openEditDiscountAsync()}
                        icon={{name: "file-pen", size: IconSize.X2}}
                />
                
                {/* TODO: 
                    - Request Approval ?
                    - Approve ?
                */}
                <Button id="DeleteDiscountBtn"
                        className={styles.button}
                        label={Localizer.pricingToolDeleteDiscount}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedDiscount}
                        onClick={async () => await this.openDeleteDiscountAsync()}
                        icon={{name: "file-xmark", size: IconSize.X2}}
                />
                <Button id="DuplicateDiscountBtn"
                        className={styles.button}
                        label={Localizer.pricingToolDuplicateDiscount}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedDiscount}
                        onClick={async () => await this.openDuplicateDiscountAsync()}
                        icon={{name: "copy", size: IconSize.X2}}
                />
                <Button id="AddNewDiscountBtn"
                        className={styles.button}
                        label={Localizer.pricingToolCreateDiscount}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedContract}
                        onClick={async () => await this.openAddNewDiscountAsync()}
                        icon={{name: "file-plus", size: IconSize.X2}}
                />
            </div>
        );
    }

    public renderModals(): React.ReactNode {
        return (
            <div>
                <Modal info keepTextFormatting
                       title={Localizer.pricingToolCreateDiscount}
                       ref={this._addModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="AddNewDiscountModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <Form onSubmit={async () => await this.handleAddNewDiscountSubmitAsync(true)} >
                            <TextInput id="pricingToolNewDiscountName"
                                       required
                                       label={Localizer.pricingToolNewDiscountName}
                                       value={this.state.newDiscountName ?? ""}
                                       onChange={async (input, value) => this.setState({newDiscountName: value})}
                            />
                            <Dropdown id="SelectTemplateDD"
                                      className={styles.dropdown}
                                          label={Localizer.pricingToolSelectSourceTemplate}
                                      minWidth="100px"
                                      orderBy={DropdownOrderBy.None}
                                      selectedItem={this.state.selectedTemplate}
                                      items={this.state.templates.map(template => {
                                          return new SelectListItem(template.discountId, template.discountName, PricingToolDiscountStatus[template.status]);
                                      }) ?? []}
                                      onChange={async (sender, item) => await this.setSelectedTemplateAsync(item)}
                            />
                            <div className={styles.modalButtons}>
                                <Button
                                    label={Localizer.formCancel}
                                    type={ButtonType.Light}
                                    onClick={async () => await this.handleAddNewDiscountSubmitAsync(false)}
                                />
                                <Button id="confirmaAddDiscountButton"
                                        submit
                                        label={Localizer.formSave}
                                        type={ButtonType.Orange}
                                />
                            </div>
                        </Form>
                    </div>
                </Modal>
                
                <Modal info keepTextFormatting
                       title={Localizer.pricingToolDuplicateDiscount}
                       ref={this._duplicateModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="DuplicateDiscountModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <Form onSubmit={async () => await this.handleDuplicateDiscountSubmitAsync(true)} >
                            <TextInput id="pricingToolNewDiscountName"
                                       required
                                       label={Localizer.pricingToolNewDiscountName}
                                       value={this.state.newDiscountName ?? ""}
                                       onChange={async (input, value) => this.setState({newDiscountName: value})}
                            />
                            <div className={styles.modalButtons}>
                                <Button
                                    label={Localizer.formCancel}
                                    type={ButtonType.Light}
                                    onClick={async () => await this.handleDuplicateDiscountSubmitAsync(false)}
                                />
                                <Button id="confirmDuplicateDiscountButton"
                                        submit
                                        label={Localizer.formSave}
                                        type={ButtonType.Orange}
                                />
                            </div>
                        </Form>
                    </div>
                </Modal>
                
                <Modal info keepTextFormatting
                       title={Localizer.pricingToolDeleteDiscount}
                       ref={this._deleteModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="DeleteDiscountModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <h6>
                            {Localizer.pricingToolDeleteDiscountConfirmation}
                        </h6>
                        <div className={styles.modalButtons}>
                            <Button
                                label={Localizer.formCancel}
                                type={ButtonType.Light}
                                onClick={async () => await this.handleDeleteDiscountSubmitAsync(false)}
                            />
                            <Button id="confirmDeleteDiscountButton"
                                    label={Localizer.formDelete}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.handleDeleteDiscountSubmitAsync(true)}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.pricingTool}>
                <BreadCrumb items={this.breadCrumbs} />

                <PageHeader title={Localizer.pricingToolDiscounts} className={styles.header} />

                <PageRow>
                    <div className="col">
                        <div>
                            {this.renderComponents()}
                        </div>
                    </div>
                </PageRow>

                {this.renderModals()}
            </PageContainer>
        );
    }
}