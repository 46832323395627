// Add export to fix compilation error TS1208
import Localizer from "@/localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Chainable = Cypress.Chainable;

export {};


describe('Desktop - My account tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);

        cy.visit(Cypress.env('login_url'));

        cy.url().should('include', Localizer.pageRoutesLogin);
        pageData().common.waitTopNavRender();
    });

    it('Login page contains correct elements', () => {
        cy.get('h1').should('contain', Localizer.loginTitle);
        cy.get('h2').should('contain', Localizer.loginHelpText);

        pageData().login.loginButton();
        pageData().login.usernameInput();
        pageData().login.passwordInput();
    });

    it('Login with correct credentials', () => {
        cy.get('h1').should('contain', Localizer.loginTitle);
        cy.get('h2').should('contain', Localizer.loginHelpText);

        cy.wait(500);
        pageData().login.usernameInput().focus().type("juhani.sihvonen@weare.fi");
        pageData().login.passwordInput().focus().type("Onet.xml1");

        executeWithIntercept(() => pageData().login.loginButton().click(),
            [pageData().login.routes.login as RouteData]
        );
    });

    it('Should not display microsoft login when disabled', () => {
        assertVisibleButton(pageData().login.microsoftLoginButton(), RentaEasyConstants.featureFlagMicrosoftLogin);
        cy.reload();
        pageData().common.waitTopNavRender();

    });

    it('Should not display google login when disabled', () => {
        assertVisibleButton(pageData().login.googleLoginButton(), RentaEasyConstants.featureFlagGoogleLogin);
        cy.reload();
        pageData().common.waitTopNavRender();
    });

    it('Should display strong auth login when disabled', () => {
        pageData().login.strongAuthLoginButton().should('not.exist');

        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagSsoLogin);

        cy.reload();

        pageData().login.strongAuthLoginButton().should('be.visible');

        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagSsoLogin);
        cy.reload();
        pageData().common.waitTopNavRender();
    });


    it('Login with wrong credentials - shows warning', () => {
        cy.get('h1').should('contain', Localizer.loginTitle);
        cy.get('h2').should('contain', Localizer.loginHelpText);

        pageData().login.usernameInput().focus().type("juhani.sihvonen@weare.fi");
        pageData().login.passwordInput().focus().type("wrong credentials");
        pageData().login.loginButton().click();

        cy.get('.athenaeum-alert-message')
            .should('contain', Localizer.loginErrorInLogin);
    });

    function assertVisibleButton(button: Chainable<JQuery<HTMLElement>>, featureFlag: string) {
        button.should('be.visible');

        CypressFeatureFlagService.disable(featureFlag);

        cy.reload();

        button.should('not.exist');
        CypressFeatureFlagService.enable(featureFlag);
    }
});