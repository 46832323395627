export default class EnvironmentSensor {

    public id: number = 0;

    public physicalQuantity: string | null = null;

    public unitOfMeasurement: string | null = null;

    public min: number = 0;

    public max: number = 0;

    public latestValue: number | null = null;

    public timestamp: Date | null = null;

    static transFormUnit(cellValue: string): string {
        switch (cellValue) {
            case "celsius":
                return "°C";
            case "percentage":
                return "%RH";
            case "particulates":
                return "µg/m³";
            default:
                return cellValue;
        }
    }
}