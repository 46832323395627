import React, { useState, useEffect } from 'react';
import styles from './Slider.module.scss';  // Import the SCSS module

interface CustomSliderProps {
    min: number;
    max: number;
    step: number;
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
    unit: string;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
                                                       min, max, step, value, onChange, disabled = false, unit
                                                   }) => {
    const [dragging, setDragging] = useState(false);

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(Number(event.target.value));
    };

    const handleMouseDown = () => {
        if (!disabled) {
            setDragging(true);
        }
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!disabled) {
            setDragging(true);
            event.preventDefault();
        }
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (dragging) {
            const sliderElement = document.getElementById('slider') as HTMLInputElement;
            const rect = sliderElement.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            const percentage = offsetX / rect.width;
            const newValue = Math.round((percentage * (max - min) + min) / step) * step;

            // Ensure the new value is within the slider range
            if (newValue >= min && newValue <= max) {
                onChange(newValue);
            }
        }
    };

    const handleTouchMove = (event: TouchEvent) => {
        if (dragging && event.touches.length === 1) {
            event.preventDefault();
            const sliderElement = document.getElementById('slider') as HTMLInputElement;
            const rect = sliderElement.getBoundingClientRect();
            const offsetX = event.touches[0].clientX - rect.left;
            const percentage = offsetX / rect.width;
            const newValue = Math.round((percentage * (max - min) + min) / step) * step;

            // Ensure the new value is within the slider range
            if (newValue >= min && newValue <= max) {
                onChange(newValue);
            }
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleTouchEnd = () => {
        setDragging(false);
    };


    // Calculate percentage position of the slider knob
    const getKnobPosition = () => {
        return ((value - min) / (max - min)) * 100;
    };

    const calculateTranslateX = (position: number) => {
        const minPos = 0;
        const maxPos = 100;
        const minTranslateX = -30;
        const maxTranslateX = -70;

        // Linear interpolation formula
        const translateX = minTranslateX + ((position - minPos) / (maxPos - minPos)) * (maxTranslateX - minTranslateX);

        return `${translateX}%`;
    };

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('touchmove', handleTouchMove, { passive: false });
            window.addEventListener('touchend', handleTouchEnd);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [dragging]);

    return (
        <div className={styles.sliderWrapper}>
            <div
                className={styles.sliderTooltip}
                style={{
                    left: `calc(${getKnobPosition()}%)`,
                    transform: `translateX(${calculateTranslateX(getKnobPosition())})`,
                }}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}>
                <div className={styles.tooltipValue}>
                    {value}{unit}
                </div>
                <div className={styles.tooltipArrow}></div>
            </div>
            <input
                type="range"
                id="slider"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleSliderChange}
                className={styles.slider}
                disabled={disabled}
            />

            <div className={styles.sliderLabels}>
                <span>{min}%</span>
                <span>{max}%</span>
            </div>
        </div>
    );
};

export default CustomSlider;