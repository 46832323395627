import React from "react";
import {ServerError, BasePageParameters, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import Localizer from "../../localization/Localizer";
import {Link, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import RentaEasyController from "@/pages/RentaEasyController";

import styles from "./Error.module.scss";

export interface IErrorPageParameters extends BasePageParameters {
    error: ServerError | null;
}

interface IErrorPageState {
    error: ServerError | null;
}

export default class ErrorPage extends AnonymousPage<IErrorPageParameters, IErrorPageState> {

    public state: IErrorPageState = {
        error: null,
    };

    private get contactPageRoute(): PageRoute {
        return PageDefinitions.contactSupport.route({
            params: {
                requestId: this.error.requestId,
            }
        });
    }

    private get error(): ServerError {
        return this.state.error!;
    }

    protected get title(): string {
        return Localizer.errorPageTitle;
    }
    
    private get isProduction(): boolean {
        return RentaEasyController.isProduction()
    }

    public async initializeAsync(): Promise<void> {

        // because the parameters are removed so that they won't be displayed in the URL, if the user navigates away and then browses back to the Error-page they will always be directed to the login page.

        if (!this.typedParameters?.error) {
            await this.toFrontPageAsync();
            return;
        }

        await super.initializeAsync();

        await this.setState({
            error: this.typedParameters.error,
        });

        const route: PageRoute = this.route;
        route.parameters = null;

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(route);
    }

    public render(): React.ReactNode {
        if (!this.error) {
            return null;
        }

        return (
            <PageContainer className={styles.error}>
                <PageHeader title={Localizer.errorPageTitle}
                            subtitle={Localizer.errorPageErrorMessage}
                />

                <PageRow>
                    <p>
                        {Localizer.errorPageErrorMessage}
                        &nbsp;
                        <Link route={this.contactPageRoute}>
                            {Localizer.genericContactSupport}
                        </Link>
                    </p>

                    {
                        (this.error.debugDetails && !this.isProduction) &&
                        (
                            <p className={styles.debugDetails}>
                                {this.error.debugDetails}
                            </p>
                        )
                    }
                </PageRow>
            </PageContainer>
        );
    }
}