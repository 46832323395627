import {useEffect, useState} from "react";

type WindowSize = {
    windowWidth: number;
    windowHeight: number;
};

export const useResize = (timeoutInMs: number = 500): WindowSize => {
    const [windowSize, setWindowSize] = useState({windowWidth: window.innerWidth, windowHeight: window.innerHeight});

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        const handleResize = () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setWindowSize({windowWidth: window.innerWidth, windowHeight: window.innerHeight});
            }, timeoutInMs);
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [timeoutInMs]);

    return windowSize;
}