import {ServiceRequestStatus} from "@/models/Enums";

export default class ToolbarModel {

    public from: Date | null = null;

    public to: Date | null = null;

    public status: ServiceRequestStatus | null = null;

}