import React from "react";
import {BaseAsyncComponent, ch, IBaseAsyncComponentState, IBaseComponent, PageRoute} from "@renta-apps/athenaeum-react-common";
import {IconSize, RouteWidget} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import UserContext from "@/models/server/UserContext";

interface ICustomerSitesInfoProps {
    route: PageRoute;

    fetchData(sender: IBaseComponent): Promise<ICustomerSitesWidgetData>;

}

export interface ICustomerSitesWidgetData {
    selectedSiteOrContractName: string;
    constructionSitesCount: string;
    constructionSitesOpen: string;
}

interface ICustomerSitesWidgetState extends IBaseAsyncComponentState<ICustomerSitesWidgetData> {
}

export default class CustomerSitesWidget extends BaseAsyncComponent<ICustomerSitesInfoProps, ICustomerSitesWidgetState, {}> {

    public state: ICustomerSitesWidgetState = {
        data: null,
        isLoading: false,
    };

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    protected async fetchDataAsync(): Promise<ICustomerSitesWidgetData> {
        return this.props.fetchData(this);
    }

    public get customerSitesInfo(): string {
        if (!this.state.data) {
            return "";
        }

        const roleName: string = Localizer.get(this.userContext.currentRoleName);
        const selectionName: string = this.state.data.selectedSiteOrContractName;
        const siteCount: string = this.state.data.constructionSitesCount;
        const sitesOpen: string = this.state.data.constructionSitesOpen;

        return `${selectionName} ${roleName} ${Localizer.indexWorksites} ${siteCount}, ${Localizer.indexActive} ${sitesOpen}`;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public render(): React.ReactNode {
        return (
            <RouteWidget wide
                         route={this.props.route}
                         label={Localizer.inventoryManagementText}
                         icon={{name: "tools", size: IconSize.X3}}
                         description={this.customerSitesInfo}
            />
        );
    }

}