import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, IconSize,} from "@renta-apps/athenaeum-react-components";

import Localizer from "@/localization/Localizer";
import styles from "./ServicesCatalog.module.scss";
import CustomServiceRequestModel from "@/models/server/CustomServiceRequestModel";

interface IServiceCatalogProps {
    customServiceModels: CustomServiceRequestModel[];

    openDefinitionAsync(model: CustomServiceRequestModel): Promise<void>;

    canEdit?: boolean;

    /**
     * Required if {@link canEdit} is true.
     */
    openEditorAsync?(model: CustomServiceRequestModel): Promise<void>;

    canCreateNew?: boolean;

    /**
     * Required if {@link canCreateNew} is true.
     */
    createNewServiceDefinition?(): Promise<void>;

    createNewButtonLabel: string;
}

interface IServiceCatalogState {
}

export default class ServiceCatalog extends BaseComponent<IServiceCatalogProps, IServiceCatalogState> {

    public state: IServiceCatalogState = {};

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.servicesList} id="service_list">
                {
                    (this.props.canEdit && this.props.canCreateNew) &&
                    (
                        <Button id="createNewServiceDefinitionButton"
                                label={this.props.createNewButtonLabel}
                                onClick={async () => await this.props.createNewServiceDefinition!()}
                        />
                    )
                }
                {
                    (this.props.customServiceModels) &&
                    (
                        this.props.customServiceModels.map((serviceRequest, index) => (
                            <span key={(serviceRequest.name ?? "") + index} data-test-id={`service_${serviceRequest.name}`}>

                                <Button className={styles.serviceButton}
                                        label={serviceRequest?.name ?? ""}
                                        onClick={async () => await this.props.openDefinitionAsync(serviceRequest)}
                                        icon={{name: serviceRequest?.icon ?? "", size: IconSize.X3}}
                                        type={ButtonType.Orange}
                                />

                                {
                                    (this.props.canEdit) &&
                                    (
                                        <Button id={"serviceDefinitionsEdit-" + serviceRequest?.id}
                                                label={Localizer.serviceDefinitionsEdit}
                                                onClick={async () => await this.props.openEditorAsync!(serviceRequest)}
                                                icon={{name: "pen"}}
                                                type={ButtonType.Orange}
                                        />
                                    )
                                }

                            </span>)
                        )
                    )
                }

            </div>
        );
    }
}