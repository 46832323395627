import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import styles from "./Signal.module.scss";
import Localizer from "@/localization/Localizer";

interface ISignalProps {
    gpsSignalQuality: string;
}

const Signal: React.FC<ISignalProps> = ({gpsSignalQuality}): React.ReactElement => {

    const signalQuality: any = (gpsSignalQuality.toLowerCase() === "good")
        ? styles.signalQuality100
        : (gpsSignalQuality.toLowerCase() === "ok")
            ? styles.signalQuality60
            : styles.signalQuality20;

    return (

        <div className={styles.container}>
            {
                (
                    <div className="flex flex-direction-column align-items-center">

                        <div className={styles.qualityText}>
                            {Localizer.productLocationGpsQuality.toUpperCase()}
                        </div>

                        <div className={styles.strengthValue}>
                            {gpsSignalQuality.toUpperCase()}
                        </div>

                        <div className={Utility.css(styles.signalQuality, signalQuality)}>
                        </div>

                    </div>
                )
            }
        </div>
    );
};

export default Signal;