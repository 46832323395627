import React, {useLayoutEffect, useMemo, useRef, useState} from "react";
import styles from "./SensorBubble.module.scss";
import EnvironmentFloorPlanDevice from "@/models/server/EnvironmentFloorPlanDevice";
import Localizer from "@/localization/Localizer";
import EnvironmentSensor from "@/models/server/EnvironmentSensor";
import {Icon} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import EnvironmentControlHelper from "@/helpers/EnvironmentControlHelper";

type SensorBubbleProps = {
    device: EnvironmentFloorPlanDevice,
    dx: number,
    dy: number,
    floorPlanImageWidth: number,
    language: string,
    highlighted: boolean,
    transparent: boolean,
    arrowDirection: Direction,
    zIndex: number,
    onSensorClick?(): Promise<void>;
    onMouseEnter?(): void;
    onMouseLeave?(): void;
};

export type Direction = 'up' | 'down';

const SensorBubble: React.FC<SensorBubbleProps> = (props: SensorBubbleProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [dx, setDx] = useState(0);
    const [moveX, setMoveX] = useState(0);
    const [dy, setDy] = useState(0);

    useLayoutEffect(() => {
        if (ref.current) {
            const x = props.dx - (ref.current.offsetWidth / 2);
            if (x < 0) {
                setMoveX(Math.ceil(-x));
            } else if (x + ref.current.offsetWidth > props.floorPlanImageWidth) {
                setMoveX(Math.floor(props.floorPlanImageWidth - (x + ref.current.offsetWidth)));
            }
            setDx(x);
            // 6 - bottom arrow height, not included in the offset height
            setDy(props.dy - (ref.current.offsetHeight + 6));
        }
    }, [props.dx, props.dy, props.floorPlanImageWidth]);
    
    const sensors = useMemo(() => {
        return EnvironmentControlHelper.orderSensors(props.device);
    }, [props.device]);
    
    const refreshTime = useMemo(() => {
        if (!props.device.sensors?.length) {
            return '';
        }

        const sensor = props.device.sensors[0];
        return EnvironmentControlHelper.getSensorRefreshTime(sensor, props.language);
    }, [props.device, props.language]);
    
    const getIcon = (sensor: EnvironmentSensor): string => {
        // 0 - structure temperature, 3 - structure humidity
        return [0, 3].includes(sensor.id) ? 'fas fa-block-brick' : 'fas fa-wind'
    };
    
    const getValue = (sensor: EnvironmentSensor): string => {
        return `${sensor.latestValue?.toFixed(1)} ${EnvironmentControlHelper.getSensorUnit(sensor)}`;
    };

    return (
        <div id={props.device.id!}
             className={Utility.css(
                 styles.sensor,
                 props.highlighted ? styles.highlighted : null,
                 props.transparent ? styles.transparent : null,
                 'bubble',
                 props.arrowDirection === 'down' ? 'arrowDown' : 'arrowUp')}
             style={{
                 top: dy,
                 left: dx + moveX,
                 '--moveX': `${-moveX}px`,
                 zIndex: props.highlighted ? 999 : props.zIndex,
             } as React.CSSProperties}
             ref={ref}
             onClick={props.onSensorClick}
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}
        >
            {props.device.name?.length && <div className={styles.sensorName}>{props.device.name}</div>}
            {sensors?.length ? (
                <>
                    <div className={styles.measurements}>
                        {
                            sensors.map((sensor: EnvironmentSensor) => {
                                return (
                                    <div key={sensor.id} className={styles.measurement}>
                                        <Icon name={getIcon(sensor)}/>
                                        <div>{getValue(sensor)}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className={styles.refreshTime}>{refreshTime}</div>
                </>
            ) : (
                <div>{Localizer.dropdownNoData}</div>
            )}
        </div>
    );
}

export default SensorBubble;