import React from "react";
import {AlertModel, BaseComponent, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";

import {Button, ButtonType} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import UserConstructionSiteOptionsModel from "@/models/server/UserConstructionSiteOptionsModel";
import ChangeConstructionSiteVisibilityRequest from "@/models/server/Requests/ChangeConstructionSiteVisibilityRequest";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import RentaEasyController from "@/pages/RentaEasyController";
import styles from "./ConstructionSitesList.module.scss";
import UserContext from "@/models/server/UserContext";
import PageDefinitions from "@/providers/PageDefinitions";
import ConstructionSitesPageParameters from "@/models/ConstructionSitesPageParameters";
import {IEquipmentParams} from "@/pages/Equipment/Equipment";
import ConstructionSitesListItem from "@/components/ConstructionSitesList/ConstrunctionSitesListItem/ConstructionSitesListItem";
import ConstructionSitesListPagination from "@/components/ConstructionSitesList/ConstrunctionSitesListPagination/ConstructionSitesListPagination";
import ConstructionSitesListToolbarModel from "@/components/ConstructionSitesList/ConstructionSitesListToolbar/ConstructionSitesListToolbarModel";
import ConstructionSitesListToolbar, {GET_USER_DATA_STORAGE_KEY} from "@/components/ConstructionSitesList/ConstructionSitesListToolbar/ConstructionSitesListToolbar";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";

interface IConstructionSitesListData {
    sites: ConstructionSiteModel[];
    contracts: OrganizationContractModel[];
}

interface IConstructionSitesListProps {
    contractId: string | null;
    masterContract: boolean;
    constructionSiteSelection?: ConstructionSitesPageParameters;
}

interface IConstructionSitesListState {
    alertModel: AlertModel | null;
    allSites: ConstructionSiteModel[];
    constructionSitesToShow: IPagedList<ConstructionSiteModel> | null;
    userHiddenSites: string[];
    filters: ConstructionSitesListToolbarModel | null;
    isLoading: boolean;
    pageNumber: number;
    pageSize: number;
    currentContractId: string | null;
    contracts: OrganizationContractModel[];
}

export default class ConstructionSitesList extends BaseComponent<IConstructionSitesListProps, IConstructionSitesListState> {

    public state: IConstructionSitesListState = {
        alertModel: null,
        allSites: [],
        constructionSitesToShow: null,
        userHiddenSites: [],
        filters: null,
        isLoading: true,
        pageNumber: 1,
        pageSize: 10,
        currentContractId: null,
        contracts: [],
    };

    // Getters

    private get constructionSitesToShow(): IPagedList<ConstructionSiteModel> {
        return this.state.constructionSitesToShow!;
    }

    private get allSites(): ConstructionSiteModel[] {
        return this.state.allSites;
    }

    private get userHiddenSites(): string[] {
        return this.state.userHiddenSites;
    }

    private get pageNumber(): number {
        return this.state.pageNumber;
    }

    public get isAdmin(): boolean {
        return this.userContext.isAdmin;
    }

    private get pageSize(): number {
        return this.state.pageSize;
    }

    private get filters(): ConstructionSitesListToolbarModel {
        return this.state.filters!;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private hideUserHiddenConstructionSites() {
        this.allSites.forEach(site => {
            if (this.userHiddenSites.some(item => item === site.id)) {
                site.hiddenFromUser = true;
            }
        });
    }

    private async fetchConstructionSiteAsync(contractId: string, initial: boolean = false): Promise<void> {
        let constructionSites: ConstructionSiteModel[];
        let contracts: OrganizationContractModel[];

        let filters: ConstructionSitesListToolbarModel = new ConstructionSitesListToolbarModel();
        filters = UserInteractionDataStorage.get(GET_USER_DATA_STORAGE_KEY(this.props.contractId), filters, DataStorageType.Page)

        if (this.props.masterContract) {
            const data: IConstructionSitesListData = await this.postAsync('/api/ConstructionSites/GetContractsAndConstructionSites', {contractId, selectedChildContract: filters.constructionSiteOwnerId});
    
            constructionSites = data.sites;
            contracts = data.contracts;
        } else {
            constructionSites = await RentaEasyController.getContractsConstructionSitesAsync(contractId, this);
            contracts = [];
        }
        
        await this.getUserHiddenConstructionsSitesAsync();

        filters.contractId = this.props.contractId;
        
        if (initial) {
            if (this.props.masterContract && this.props.contractId === contractId) {
                this.setState({contracts});
                
                if (contracts.length > 0 && !filters.constructionSiteOwnerId) {
                    filters.constructionSiteOwnerId = contracts[0].contractId;
                }
            }
        }
        
        const sitesToShow: IPagedList<ConstructionSiteModel> = constructionSites.filter(item =>
            (item.isOpen === filters.showAllSites) &&
            ((filters.showHiddenSites) || (!filters.showHiddenSites && !item.hiddenFromUser)) &&
            (
                Object.values(item).some(value => value?.toString().toLowerCase().includes(filters.keyword.toLowerCase()))
                || item.inventoryItemDisplayNumbers.includes(filters.keyword)
            )
        ).toPagedList(this.pageNumber, this.pageSize);

        this.setState({
            allSites: constructionSites,
            constructionSitesToShow: sitesToShow,
            filters,
            currentContractId: filters.constructionSiteOwnerId
        });
    }

    private async openCompanyEquipmentPageAsync(equipmentOnly: boolean): Promise<void> {
        let details: IEquipmentParams = {
            contractId: this.state.currentContractId ?? this.props.contractId,
            equipmentOnly: equipmentOnly,
        };

        await PageRouteProvider.redirectAsync(PageDefinitions.equipment.route({params: details}));
    }

    private async changeSiteVisibilityAsync(site: ConstructionSiteModel, hidden: boolean): Promise<void> {
        const request: ChangeConstructionSiteVisibilityRequest = {
            hide: hidden,
            constructionSiteId: site.id
        };

        site.hiddenFromUser = hidden;

        await this.postAsync("/api/ConstructionSites/ChangeConstructionSiteVisibility", request);

        await this.filterSitesAsync(this.filters);
    }

    private async getUserHiddenConstructionsSitesAsync(): Promise<void> {
        const hiddenSites: UserConstructionSiteOptionsModel[] = await this.postAsync("/api/ConstructionSites/GetUserHiddenConstructionSiteIds");

        const siteIds: string[] = [];
        hiddenSites.forEach(site => {
            if (site.constructionSiteId) {
                siteIds.push(site.constructionSiteId);
            }
        });
        this.setState({userHiddenSites: siteIds});

        this.hideUserHiddenConstructionSites();
    }

    private async sendSummaryAsync(constructionSiteId: string): Promise<void> {
        this.setState({isLoading: true});

        await this.postAsync("/api/ConstructionSites/SendSummary", constructionSiteId);

        this.setState({isLoading: false});
    }

    private async changePageAsync(pageNumber: number, pageSize: number): Promise<void> {
        await this.filterSitesAsync(this.filters, pageNumber, pageSize);
    }

    private async filterSitesAsync(model: ConstructionSitesListToolbarModel, newPageNumber?: number, newPageSize?: number): Promise<void> {
        if (model.constructionSiteOwnerId && model.constructionSiteOwnerId !== this.state.currentContractId) {
            this.setState({isLoading: true});

            await this.fetchConstructionSiteAsync(model.constructionSiteOwnerId);

            this.setState({isLoading: false, currentContractId: model.constructionSiteOwnerId});
        }

        await this.getUserHiddenConstructionsSitesAsync();

        const activeSites: ConstructionSiteModel[] = (model.keyword)
            ? this.state.allSites.filter(item =>
                (item.isOpen === model.showAllSites) &&
                ((model.showHiddenSites) || (!model.showHiddenSites && !item.hiddenFromUser)) &&
                (
                    Object.values(item)
                        .some(value => value?.toString().toLowerCase().includes(model.keyword.toLowerCase()))
                    || item.inventoryItemDisplayNumbers.includes(model.keyword)
                )
            )
            : this.state.allSites.filter(item =>
                (item.isOpen === model.showAllSites) &&
                ((model.showHiddenSites) || (!model.showHiddenSites && !item.hiddenFromUser))
            );

        const pageNumber = newPageNumber ?? this.pageNumber;
        const pageSize = newPageSize ?? this.pageSize;
        const pagedSites: IPagedList<ConstructionSiteModel> = activeSites.toPagedList(pageNumber, pageSize);

        this.setState({constructionSitesToShow: pagedSites, pageNumber, pageSize, filters: model});

        await this.reRenderAsync();
    }

    // Async-methods
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.fetchDataAsync();

        this.setState({isLoading: false});
    }

    public async fetchDataAsync(): Promise<void> {

        if (this.props.constructionSiteSelection) {
            const contractSelection: ConstructionSitesPageParameters = this.props.constructionSiteSelection;

            const contractId: string = (contractSelection.isMasterContract && contractSelection.selectedChildContractId)
                ? contractSelection.selectedChildContractId!
                : contractSelection.contractId!;

            await this.fetchConstructionSiteAsync(contractId, true);
        }
        else if (this.props.contractId) {
            const contractId: string = this.props.contractId ?? this.userContext.selectedContractId;

            await this.fetchConstructionSiteAsync(contractId, true);
        }
        else {
            await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
        }
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.main}>
                <div className={styles.constructionSiteList}>

                    <div className={this.css("row", styles.equipmentButtonContainer)}>
                        <div className={this.css(styles.equipmentButtonColumn)}>
                            <Button className={styles.allEquipmentButton}
                                    label={Localizer.contractDetailsModelShowEquipmentButton}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.openCompanyEquipmentPageAsync(true)}
                            />
                            <InlineTooltip className={this.css(styles.equipmentButtonInfo)}
                                           text={Localizer.equipmentDescription}
                            />
                        </div>

                        <div className={this.css(styles.equipmentButtonColumn)}>
                            <Button className={styles.allEquipmentButton}
                                    label={Localizer.contractDetailsModelShowAllOrganizationEquipmentButton}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.openCompanyEquipmentPageAsync(false)}
                            />
                            <InlineTooltip className={this.css(styles.equipmentButtonInfo)}
                                           text={Localizer.equipmentOrganizationDescription}
                            />
                        </div>
                    </div>

                    {(this.filters) && (
                        <ConstructionSitesListToolbar
                            model={this.filters}
                            contracts={this.state.contracts}
                            onChange={async (model: ConstructionSitesListToolbarModel) => await this.filterSitesAsync(model, 1)}
                        />
                    )}

                    {(this.constructionSitesToShow) &&
                        <ConstructionSitesListPagination
                            pageSize={this.pageSize}
                            pageNumber={this.pageNumber}
                            itemCount={this.constructionSitesToShow.items.length}
                            totalItemCount={this.constructionSitesToShow.totalItemCount}
                            allSitesCount={this.allSites.length}
                            changePageAsync={async (pageNumber, pageSize) => await this.changePageAsync(pageNumber, pageSize)}
                        />
                    }

                    {(this.constructionSitesToShow) &&
                        (this.constructionSitesToShow.items.map((constructionSite, index) => (
                            <ConstructionSitesListItem
                                constructionSiteModel={constructionSite}
                                index={index}
                                userContext={this.userContext}
                                constructionSiteSelection={this.props.constructionSiteSelection}
                                contractId={this.props.contractId}
                                changeSiteVisibilityAsync={async (constructionSiteModel: ConstructionSiteModel, checked: boolean) =>
                                this.changeSiteVisibilityAsync(constructionSiteModel, checked)}
                                sendSummaryAsync={async (id: string) => this.sendSummaryAsync(id)}
                            />
                        )))
                    }

                    {((this.constructionSitesToShow?.items.length ?? 0) >= 10) &&
                        <ConstructionSitesListPagination
                            pageSize={this.pageSize}
                            pageNumber={this.pageNumber}
                            itemCount={this.constructionSitesToShow.items.length}
                            totalItemCount={this.constructionSitesToShow.totalItemCount}
                            allSitesCount={this.allSites.length}
                            changePageAsync={async (pageNumber, pageSize) => await this.changePageAsync(pageNumber, pageSize)}
                        />
                    }
                </div>
            </div>
        );
    }
}