import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import ProductCard, {ProductCardStyle} from "@/components/ProductCard/ProductCard";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import {Carousel, CarouselNavigation} from "@renta-apps/athenaeum-react-components";

import styles from "@/components/ProductCarousel/ProductCarousel.module.scss";

export interface IProductCarouselClassNames {
    carousel?: string;
}

interface IProductCarouselProps {
    readonly: boolean;
    onUpdateProductCount: (productId: string, newCount: number, productName: string | null) => Promise<void>;
    products: ShoppingCartProductModel[];
    vat: number;
    navigation?: CarouselNavigation;
    /**
     * Classnames of the components different sub-components.
     *
     * @default undefined
     */
    classNames?: IProductCarouselClassNames;
}

interface IProductCarouselState {
}

export default class ProductCarousel extends BaseComponent<IProductCarouselProps, IProductCarouselState> {

    public state: IProductCarouselState = {};

    private get hasProducts(): boolean {
        return (this.props.products?.length > 0);
    }

    private get products(): ShoppingCartProductModel[] {
        return this.props.products;
    }

    private get navigation(): CarouselNavigation {
        return Carousel.toNavigation(this.props.navigation);
    }

    private get classNames(): IProductCarouselClassNames | undefined {
        return this.props.classNames;
    }

    public render(): React.ReactNode {
        return (
            (this.hasProducts) &&
            (
                <div className={styles.productCarousel}>
                    {
                        <Carousel slidesPerView={"auto"}
                                  spaceBetweenSlides={12}
                                  navigation={this.navigation}
                                  className={this.css(this.classNames?.carousel)}
                        >
                            {
                                this.products.map((product, index) =>
                                    <ProductCard displayAddToCartButton displayDiscountText
                                                 key={index + product.product!.id}
                                                 className={styles.productCard}
                                                 classNames={{
                                                     mainContainer: styles.productCardMainContainer,
                                                     prices: styles.productCardPrices
                                                 }}
                                                 count={product.count}
                                                 maxPriceRows={1}
                                                 product={product.product!}
                                                 readonly={this.props.readonly}
                                                 style={ProductCardStyle.SingleColumn}
                                                 vat={this.props.vat}
                                                 onUpdateProductCount={async (productId, newCount, productName) => await this.props.onUpdateProductCount(productId, newCount, productName)}
                                    />
                                )
                            }
                        </Carousel>
                    }
                </div>
            )
        );
    }
}