import React from "react";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import Localizer from "@/localization/Localizer";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";
import {TextArea} from "@renta-apps/renta-react-components";

type InternalNotesProps = {
    internalNotes: string | null;
    onChange: (internalNotes: string) => void;
}

const InternalNotes: React.FC<InternalNotesProps> = ({internalNotes, onChange}) => {
    return (
        <ContentBox title={Localizer.returnRequestModalInternalNotesHeader} subTitle={Localizer.returnRequestModalInternalNotesSubheader}>
            <div className={styles.textAreaContainer} data-cy="return-modal-internal-notes">
                <TextArea label={Localizer.returnRequestModalInternalNotes}
                          value={internalNotes ?? ''}
                          className={styles.inputField}
                          onChange={(value: string) => onChange(value)}
                          disabled={internalNotes === null}
                          inputProperties={{maxLength: 250}}
                          guideText={`${internalNotes?.length ?? 0}/250`}
                />
            </div>
        </ContentBox>
    );
}

export default InternalNotes;