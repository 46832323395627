import React from "react";
import Localizer from "@/localization/Localizer";
import styles from "./DeviceUsageChart.module.scss";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {DeviceUsage} from "@/models/ProductLocation/Models/DeviceUsage";
import {getDateTimeInLocaleFormat} from "@/helpers/DateHelper";

interface ITooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface IDeviceUsageChartProps {
    usageData: DeviceUsage[] | null | undefined;
}

const DeviceUsageChart: React.FC<IDeviceUsageChartProps> = ({usageData}) => {

    const getTimeString = (value: number): string => {
        const n = new Date(0, 0);
        n.setSeconds(+value * 60 * 60);
        return n.toTimeString().slice(0, 8);
    };

    const convertHourToTimeFormat = (hour: number) => {
        const hours = Math.floor(hour); // Get the whole number of hours
        const minutesFraction = hour - hours; // Get the fractional part of the hour

        if (minutesFraction === 0) {
            return `${hours}h`; // If no fractional part, return just the hours
        }

        // Convert minutes to .5 format (for 30 minutes)
        const fractionalHours = Math.round(minutesFraction * 2) / 2; // This ensures .5 steps (i.e. 0.5 or 1.0)
        return `${hours + fractionalHours}h`; // Combine the integer part and the fractional part
    };

    const customTooltip = ({active, payload, label}: ITooltipProps) => {
        if (active) {
            return (

                (payload && payload[0]) &&
                (

                    <div className={styles.chartTooltip}>

                        <div className={styles.label}>{getTimeString(payload[0].value as number)}</div>

                        <div className={styles.value}>
                            {label}
                        </div>
                    </div>
                )

            )
                ;
        }
        return null;
    };

    const barChartModel = usageData
        ? usageData.map((item: any) => ({label: item.label, [Localizer.productLocationXAxel]: item.hours}))
        : undefined;

    return (
        <div>
            {(barChartModel) && (
                <ResponsiveContainer width={"100%"} height={400} style={{background: 'white'}}>
                    <BarChart width={500}
                              height={300}
                              data={barChartModel}
                              margin={{top: 10, right: 0, left: 0, bottom: 20}}
                              className={styles.rechartsBarChart}>
                        <CartesianGrid strokeDasharray="3 3"
                                       fill="white"
                                       format={"0.0"}
                        />
                        <XAxis
                            dataKey="label"
                            interval={"equidistantPreserveStart"}
                            tick={({x, y, payload}) => (
                                <text x={x}
                                      y={y + 20} // Adjust vertical position if needed
                                      textAnchor="middle"
                                      fontWeight="700">
                                    {payload.value.toLocaleString()}
                                </text>
                            )}
                        />
                        <YAxis
                            tickFormatter={timeStr => convertHourToTimeFormat(timeStr)}
                            tick={({x, y, payload}) => (
                                <text
                                    x={x - 20} // Adjust the horizontal offset as needed
                                    y={y}
                                    textAnchor="middle"
                                    fontWeight="700" // Set the text to bold
                                >
                                    {convertHourToTimeFormat(payload.value)}
                                </text>
                            )}
                            style={{marginTop: 10}}
                            width={50}
                            tickCount={4}
                        />
                        <Tooltip content={customTooltip}/>
                        <Bar dataKey={Localizer.productLocationXAxel} fill="#FFA57B"/>
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default DeviceUsageChart;