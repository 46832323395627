import React, {useEffect, useState} from "react";
import {Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {Button, ButtonSize, ButtonType, Icon, Input} from "@renta-apps/renta-react-components";
import {saveUserInvoiceReference} from "@/services/EasyPlusService";
import styles from "./EasyPlusUserInvoiceReferenceModal.module.scss";

interface EasyPlusUserInvoiceReferenceModalProps {
    isOpen: boolean;
    invoiceReference: string | null;
    userId: string;
    contractId: string;
    onClose(saved: boolean): Promise<void>;
}

const EasyPlusUserInvoiceReferenceModal: React.FC<EasyPlusUserInvoiceReferenceModalProps> = ({isOpen, userId, contractId, invoiceReference: currentInvoiceReference, onClose}) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();
    
    const [invoiceReference, setInvoiceReference] = useState<string | null>(currentInvoiceReference);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);
    
    const saveInvoiceReference = async (): Promise<void> => {
        setSaving(true);
        try {
            await saveUserInvoiceReference(userId, contractId, invoiceReference === '' ? null : invoiceReference);
        } catch (e) {
            console.error('Error saving user invoice reference:', e);
        } finally {
            setSaving(false);
        }
        await onClose(true);
    };

    return isOpen ? (
        <Modal id="easy-plus-user-invoice-reference-modal"
               ref={_modalRef}
               preventEsc preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.easyPlusInvoiceReferenceModalTitle}
               className={styles.modal}
               onClose={async () => await onClose(false)}
        >
            <div className={styles.modalContent}>
                <div className={styles.invoiceReference}>
                    <div>{Localizer.easyPlusInvoiceReferenceModalDescription}</div>
                    <div>
                        <Input label={Localizer.easyPlusInvoiceReference}
                               value={invoiceReference ?? ''}
                               onChange={(value: string) => setInvoiceReference(value)}
                        />
                    </div>
                </div>
                {saving ? (
                    <div className={styles.modalButtons}>
                        <Icon name="fa-solid fa-circle-notch fa-spin" size={24} color="#fe5000" />
                        {Localizer.fleetMonitoringPageSubscribeToAlertsModalPleaseWait}
                    </div>
                ) : (
                    <div className={styles.modalButtons}>
                        <Button onClick={() => onClose(false)}
                                size={ButtonSize.Default}
                                type={ButtonType.Secondary}
                                className={styles.button}
                        >
                            {Localizer.formCancel}
                        </Button>
                        <Button onClick={() => saveInvoiceReference()}
                                size={ButtonSize.Default}
                                type={ButtonType.Primary}
                                className={styles.button}
                                id="save-invoice-reference-button"
                                disabled={invoiceReference === currentInvoiceReference}
                        >
                            {Localizer.formSave}
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    ) : null;
}

export default EasyPlusUserInvoiceReferenceModal;