import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import ProductAvailabilityDetails from "@/models/server/ProductAvailabilityDetails";
import ProductAvailabilityModel from "@/models/server/ProductAvailabilityModel";
import {ProductAvailabilityIcon, ProductAvailabilityIconType} from "./ProductAvailabilityIcon/ProductAvailabilityIcon";
import Localizer from "@/localization/Localizer";
import styles from "./ProductAvailability.module.scss";

export interface IProductAvailabilityParams {
    className?: string;
    isAdmin: boolean;
    availabilities: ProductAvailabilityDetails[];
}

interface IProductAvailabilityState {

}

export default class ProductAvailability extends BaseComponent<IProductAvailabilityParams, IProductAvailabilityState> {

    // Fields

    public state: IProductAvailabilityState = {};

    // Properties

    private showMassProductCounts(availability: ProductAvailabilityDetails): boolean {
        return ((
                availability.available.some(productAvailability => productAvailability?.isMassProduct)
                || availability.rented.some(productAvailability => productAvailability?.isMassProduct)
            )
            ?? false);
    }

    private renderIconOrEmpty(available: ProductAvailabilityModel[] | number, type: ProductAvailabilityIconType): React.ReactNode {

        const count: number = (Array.isArray(available))
            ? available.reduce((a, b) => a + b.count, 0)    // sum of products from each element of the array
            : available;

        if (count > 0) {
            return (
                <ProductAvailabilityIcon type={type}
                                         count={count}
                />
            );
        }
    }

    private static getLinkToLocation(availability: ProductAvailabilityDetails): string {
        return `/${Localizer.pageRoutesDepots}?selectedDepot=${availability.locationName}`;
    }

    public render(): React.ReactNode {
        const availabilities: ProductAvailabilityDetails[] = this.props.availabilities ?? [];
        const isAdmin = this.props.isAdmin;
        const types = [
            {
                label: Localizer.shoppingCartPageAvailable,
                type: ProductAvailabilityIconType.Available
            },
            {
                label: (isAdmin)
                    ? Localizer.productDetailsAllInRent
                    : Localizer.productDetailsOrderable,
                type: ProductAvailabilityIconType.Orderable

            }
        ].concat(isAdmin ? [
            {
                label: Localizer.productDetailsAvailabilityInMaintenance,
                type: ProductAvailabilityIconType.Maintenance
            },
            {
                label: Localizer.productDetailsAvailabilityWaitingInspection,
                type: ProductAvailabilityIconType.ReturnInspection
            },
            {
                label: Localizer.genericUnavailable,
                type: ProductAvailabilityIconType.Unavailable
            },
        ] : []);

        return (
            <div className={this.css("row m-0 p-0", this.props.className)}>
                <div className="col-12 m-0 row gap-2 align-items-center p-2 border-bottom">
                    <div className="d-flex p-2 align-items-center justify-content-center text-uppercase font-weight-bold flex flex-column">
                        <small><b>{Localizer.productDetailsAvailabilityInformation}</b></small>
                    </div>
                    {
                        types.map(({label, type}, index) => (
                            <div key={index} className={this.css("d-flex gap-2 p-2 align-items-center col-auto", styles.availabilityLocationPreview)}>
                                <ProductAvailabilityIcon type={type} count={null}/>
                                <span style={{whiteSpace: "nowrap"}}>{label}</span>
                            </div>
                        ))
                    }
                </div>
                <div id={"productAvailabilityValuesContainer"} className={this.css("col-12 m-0 p-2 py-4", styles.availabilityLocationsWrapper)}>
                    {
                        availabilities.map((availability: any, index: number) => {
                                const availableCount = (this.showMassProductCounts(availability))
                                    ? availability.availableMassProducts ?? 0
                                    : availability.availableCount;
                                return (
                                    <div key={availability.location.toString() + index}
                                         className={this.css("col-6 col-sm-6 col-lg-4 p-0", styles.availabilityLocation)}
                                    >
                                        <div className={styles.availabilityLocationIcons}>
                                            {
                                                (!isAdmin) && (
                                                    <ProductAvailabilityIcon
                                                        type={(availableCount > 0)
                                                            ? ProductAvailabilityIconType.Available
                                                            : ProductAvailabilityIconType.Orderable}
                                                        count={null}
                                                    />
                                                )
                                            }
                                            {
                                                (isAdmin) && (
                                                    <>
                                                        {
                                                            (availability.totalCount <= 0) && (
                                                                <ProductAvailabilityIcon type={ProductAvailabilityIconType.Unavailable} count={null}/>

                                                            )
                                                        }

                                                        {
                                                            this.renderIconOrEmpty(availability.waitingForReturnInspection, ProductAvailabilityIconType.ReturnInspection)
                                                        }
                                                        {
                                                            this.renderIconOrEmpty(availability.inMaintenance, ProductAvailabilityIconType.Maintenance)
                                                        }
                                                        {
                                                            this.renderIconOrEmpty(availability.rented, ProductAvailabilityIconType.Orderable)
                                                        }
                                                        {
                                                            this.renderIconOrEmpty(availableCount, ProductAvailabilityIconType.Available)
                                                        }
                                                    </>

                                                )

                                            }
                                        </div>

                                        {
                                            (!this.props.isAdmin) && (
                                                <a href={ProductAvailability.getLinkToLocation(availability)}
                                                   data-cy={"depot_link"}
                                                   className={styles.availabilityLocationName}>
                                                    {
                                                        availability.locationName
                                                    }
                                                </a>
                                            )
                                        }

                                        {
                                            (this.props.isAdmin) &&
                                            (
                                                <div className={styles.availabilityLocationName}>
                                                    <a role="button"
                                                       data-cy={"depot_link"}
                                                       data-toggle="collapse"
                                                       href={`#collapse_${availability.location}`}
                                                       aria-expanded="false"
                                                       aria-controls={`collapse_${availability.location}`}
                                                    >
                                                        {
                                                            availability.locationName
                                                        }
                                                    </a>
                                                </div>
                                            )
                                        }
                                    </div>
                                );
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}