import React from "react";
import {BasePageParameters, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "@/models/base/AnonymousPage";
import {PageContainer, PageRow} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import InfoScreenDataResponse from "@/models/server/InfoScreenDataResponse";
import {InfoScreenType} from "@/models/Enums";
import EnvironmentControlInfoScreen from "./EnvironmentControl/EnvironmentControlInfoScreen";
import styles from "./InfoScreen.module.scss";

export interface IInfoScreenParams extends BasePageParameters {
}

interface IInfoScreenState {
    constructionSiteId: string;
    constructionSiteName: string;
    constructionSiteOwnerName: string;
    data: any;
    type: InfoScreenType | null;
}

export default class InfoScreen extends AnonymousPage<IInfoScreenParams, IInfoScreenState> {
    public state: IInfoScreenState = {
        constructionSiteId: '',
        constructionSiteName: '',
        constructionSiteOwnerName: '',
        data: null,
        type: null,
    };

    async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        await this.loadDataAsync();
    }
    
    async componentWillUnmount(): Promise<void> {
        await ch.getLayout().setFooterVisibilityAsync(true);
        await ch.getLayout().setHeaderVisibilityAsync(true);
    }

    protected get title(): string {
        return "InfoScreen";
    }
    
    private async loadDataAsync(): Promise<void> {
        if (!this.props.routeId?.length) {
            console.warn({id: this.props.routeId});
            await PageRouteProvider.redirectAsync(PageDefinitions.notFound.route(), true);
            return;
        }

        const response = await this.getAsync<InfoScreenDataResponse>(`/api/InfoScreens/GetData?url=${this.props.routeId}`);
        if (!response) {
            await PageRouteProvider.redirectAsync(PageDefinitions.notFound.route(), true);
            return;
        }

        await ch.getLayout().setFooterVisibilityAsync(false);
        await ch.getLayout().setHeaderVisibilityAsync(false);

        this.setState({
            constructionSiteId: response.constructionSite?.id ?? '',
            constructionSiteName: response.constructionSite?.name ?? '',
            constructionSiteOwnerName: response.constructionSite?.ownerName ?? '',
            data: response.infoScreenData,
            type: response.type,
        });
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.infoScreen} fullHeight fullWidth>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <img src="/images/Logo.svg" alt="Logo" />
                    </div>
                    <div className={styles.constructionSiteName}>
                        {`${this.state.constructionSiteOwnerName} - ${this.state.constructionSiteName}`}
                    </div>
                </div>
                <PageRow className={styles.infoScreenContent}>
                    {(this.state.data && this.state.type === InfoScreenType.EnvironmentControl) && (
                        <EnvironmentControlInfoScreen data={this.state.data}
                                                      constructionSiteId={this.state.constructionSiteId}
                        />
                    )}
                </PageRow>
            </PageContainer>
        );
    }
}