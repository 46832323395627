import React, {useEffect, useState} from "react";
import {Checkbox, Input} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import { FetchContactInformationModel} from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";

type ContactInformationProps = {
    onFetchContactChange: (customerContactInformationModel: FetchContactInformationModel) => void;
    initialEmail: string;
    initialPhone: string;
    adminMode: boolean;
    forceFreeText: boolean;
};

const ContactInformation: React.FC<ContactInformationProps> = ({
    adminMode,
    onFetchContactChange,
    forceFreeText,
    initialEmail,
    initialPhone,
}) => {
    const [useFreeTextUser, setUseFreeTextUser] = useState<boolean>(false);

    const [contactUserEmail, setContactUserEmail] = useState<string>("");
    const [contactUserPhone, setContactUserPhone] = useState<string>("");

    useEffect(() => {
        onFetchContactChange({
            contactPersonEmail: contactUserEmail,
            contactPersonPhone: contactUserPhone,
        });

        if (forceFreeText) {
            setUseFreeTextUser(forceFreeText);
        }

        if (!useFreeTextUser) {
            setContactUserEmail(initialEmail);
            setContactUserPhone(initialPhone);
        }

    }, [forceFreeText, contactUserEmail, contactUserPhone, onFetchContactChange, initialPhone, initialEmail]);

    const handleRadioChange = (checked: boolean) => {
        setUseFreeTextUser(checked);
        if (checked) {
            setContactUserEmail("");
            setContactUserPhone("");
        } else {
            setContactUserEmail(initialEmail);
            setContactUserPhone(initialPhone);
        }
    };

    return (
        <ContentBox title={Localizer.returnRequestModalContactInformationHeader} subTitle={Localizer.returnRequestModalContactInformationSubheader}>
            <div className={styles.contactInformationContainer} data-cy="return-modal-contact-information">
                <div className={styles.radioButtons} data-cy="return-modal-contact-info-radio-buttons">
                    <Checkbox
                        label={adminMode ? Localizer.returnRequestModalUseCustomerContactInfo : Localizer.returnRequestModalContactMe}
                        checked={!useFreeTextUser}
                        className={styles.radioCheckbox}
                        disabled={forceFreeText}
                        onChange={() => handleRadioChange(false)}
                    />
                    <Checkbox
                        label={Localizer.returnRequestModalFillContactInformation}
                        checked={useFreeTextUser || forceFreeText}
                        className={styles.radioCheckbox}
                        disabled={forceFreeText}
                        onChange={() => handleRadioChange(true)}
                    />
                </div>
                <Input
                    type="email"
                    label={Localizer.genericContactEmail}
                    value={contactUserEmail}
                    className={`${styles.contactEmail} ${styles.inputField}`}
                    onChange={(value: string) => setContactUserEmail(value)}
                    disabled={!useFreeTextUser && !forceFreeText}
                    inputProperties={{
                        required: true,
                        name: "contactUserEmail",
                    }}
                />
                <Input
                    type="tel"
                    label={Localizer.genericContactPhone}
                    value={contactUserPhone}
                    className={`${styles.contactPhone} ${styles.inputField}`}
                    onChange={(value: string) => setContactUserPhone(value)}
                    disabled={!useFreeTextUser && !forceFreeText}
                    inputProperties={{
                        required: true,
                        pattern: "\\+([0-9]{6,})",
                        name: "contactUserPhone",
                    }}
                />
            </div>
        </ContentBox>
    );
};

export default ContactInformation;