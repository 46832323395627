import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import React from "react";
import {Button, ButtonType, DateRangeInput, Dropdown, DropdownOrderBy, IconSize, SelectListItem} from "@renta-apps/athenaeum-react-components";
import EnumProvider from "@/providers/EnumProvider";
import {PlanStatus} from "@/models/Enums";
import styles from "./Toolbar.module.scss";
import Localizer from "@/localization/Localizer";
import PlansToolbarModel from "@/models/PlansToolbarModel";
import DepotUserModel from "@/models/server/DepotUserModel";
import UserContext from "@/models/server/UserContext";

interface IToolbarProps {
    model: PlansToolbarModel;

    onChange?(model: PlansToolbarModel, clear: boolean): Promise<void>;
}

interface IToolbarState {
    model: PlansToolbarModel;
    completedByFilter: SelectListItem[];
    depots: SelectListItem[];
}

export default class PlansToolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    public state: IToolbarState = {
        model: this.props.model,
        completedByFilter: [],
        depots: []
    };

    public async initializeAsync(): Promise<void> {
        await this.getPlanCompletedEmails();
        await this.getPlanDepots();
        await super.initializeAsync();
    }

    private async processOnChangeAsync(invoke: boolean = false, clear: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model, clear);
        }
    }

    private async setStatusAsync(status: PlanStatus | number | null) {
        this.state.model.status = status;
        await this.processOnChangeAsync(true);
    }

    private async setCompletedByAsync(email: string | "") {
        this.state.model.completedBy = email;
        await this.processOnChangeAsync(true);
    }

    private async setDepotUserEmailAsync(email: string | "") {
        this.state.model.depotUserEmail = email;
        await this.processOnChangeAsync(true);
    }

    private async setFromToAsync(from: Date | null, to: Date | null) {
        this.state.model.to = to;
        this.state.model.from = from;
        await this.processOnChangeAsync(true);
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new PlansToolbarModel();

        await this.processOnChangeAsync(true);
    }

    public get currentUserEmail(): string {
        return ((ch.getContext() as UserContext).user?.email ?? "");
    }

    private async getPlanCompletedEmails(): Promise<void> {
        const emails: string[] = await this.postAsync("/api/Plans/GetPlanCompletedByEmails");

        const completedByFilter = emails
            .map(email => {
                const listItem: SelectListItem = new SelectListItem();
                listItem.text = email.toLowerCase();
                listItem.value = email;
                return listItem;
            });

        await this.setState({completedByFilter: completedByFilter});
    }

    private async getPlanDepots(): Promise<void> {
        const users: DepotUserModel[] = await this.postAsync("/api/Plans/GetUserDepots");

        const depotFilter = users
            .map(user => {
                const listItem: SelectListItem = new SelectListItem();
                listItem.text = user!.favoriteDepotName!;
                listItem.value = user!.email!;
                return listItem;
            });

        const completedByFilter = this.state.completedByFilter.flatMap(email => email.text);

        if (completedByFilter.includes(this.currentUserEmail)) {
            this.state.model.completedBy = this.currentUserEmail;
        }

        await this.setState({depots: depotFilter});
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.toolBarContainer}>
                <Dropdown id={"completedPlanStatusFilter"}
                          className={styles.statusDropdown}
                          label={Localizer.servicesGridStatus}
                          minWidth="170px"
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.state.model.status}
                          items={EnumProvider.getServiceRequestStatusItems()}
                          onChange={async (sender, item) => await this.setStatusAsync(item?.value as any)}
                />

                <DateRangeInput id={"completedPlanDateFilter"}
                                sameDay
                                className={styles.dateRangeInput}
                                label={Localizer.servicesFiltersDate}
                                model={{value: [this.state.model.from, this.state.model.to]}}
                                onChange={async ([start, end]) => await this.setFromToAsync(start, end)}
                />

                <Dropdown id={"completedPlanCompletedByFilter"}
                          className={styles.statusDropdown}
                          label={Localizer.plansGridCompletedByLanguageItemName}
                          minWidth="250px"
                          orderBy={DropdownOrderBy.Name}
                          selectedItem={this.state.model.completedBy}
                          items={this.state.completedByFilter}
                          onChange={async (sender, item) => await this.setCompletedByAsync(item?.value as string)}
                />

                <Dropdown id={"completedPlanDepotFilter"}
                          className={styles.statusDropdown}
                          label={Localizer.locationRentalDepotsLanguageItemName}
                          minWidth="150px"
                          orderBy={DropdownOrderBy.Name}
                          selectedItem={this.state.model.depotUserEmail}
                          items={this.state.depots}
                          onChange={async (sender, item) => await this.setDepotUserEmailAsync(item?.value as string)}
                />

                <div className={styles.buttonContainer}>
                    <Button small
                            id={"clearFilters"}
                            title={Localizer.componentSignatureWidgetClear}
                            icon={{name: "far history", size: IconSize.Large}}
                            type={ButtonType.Info}
                            onClick={async () => await this.clearAsync()}
                    />
                </div>
            </div>
        )
    }

}