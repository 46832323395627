export class ConstructionSiteInfo {

    public id: string = "";

    public name: string | null = null;

    public externalId: string | null = null;

    public externalReference: string | null = null;

    public isConstructionSiteInfo: boolean = true;
}