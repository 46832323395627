import React, {lazy} from 'react';
import {ChartData} from "@/components/Charts/Models/IDoughnutData";
import Localizer from "@/localization/Localizer";
import {checkCategoryNameConsistency} from "@/helpers/ChartDataHelper";
import {EmissionFilterTimeFrame} from "@/models/Enums";

const ReactECharts = lazy(() => import('echarts-for-react'));

interface Props {
    data: ChartData[];
    timeFrame: EmissionFilterTimeFrame;
}

const StackedBarChart: React.FC<Props> = ({data, timeFrame}) => {
    // Extract the day-wise aggregated value for each bar
    const temp2: { key: string; displayName: string; values: string[] }[] = [];

    const days: string[] = [];

    const eChartsRef = React.useRef(null as any);

    const checkProductGroupNumberConsistency = (data: ChartData[]): boolean => {
        if (data.length === 0) {
            return false; // No items in the array
        }

        const firstProductGroupNumber = data[0].emissionData[0]?.productGroup;
        if (!firstProductGroupNumber) {
            return false; // No productData in the first item
        }

        for (const chartItem of data) {
            for (const productDataItem of chartItem.emissionData) {
                if (productDataItem.productGroup !== firstProductGroupNumber) {
                    return false; // productGroupNumber inconsistency found
                }
            }
        }

        return true; // All productGroupNumbers are consistent
    };

    const traverseData = (chartData: ChartData[]) => {
        let categories = chartData as ChartData[];

        if (categories.length) {
            const isProductsOnlyFromSingleGroup: boolean = checkProductGroupNumberConsistency(categories);
            const isCategoriesFromSingleCategory: boolean = checkCategoryNameConsistency(categories);
            const dateFormat = new Intl.DateTimeFormat(Localizer.language, {
                year: 'numeric',
                month: '2-digit',
                day: timeFrame === EmissionFilterTimeFrame.Monthly ? undefined : '2-digit',
            });

            categories.forEach(bar => {
                if (!bar.value) {
                    return;
                }

                let grouped = bar.emissionData.groupBy(item => item.date.toLocaleDateString());

                for (const a of grouped) {
                    const date = dateFormat.format(a[0].date);
                    if (days.indexOf(date) === -1) {
                        days.push(date);
                    }
                }

                if (isProductsOnlyFromSingleGroup) {
                    let grouped = bar.emissionData.groupBy(item => item.rentaId);
                    for (const p of grouped) {
                        let item = {key: p[0].rentaId, displayName: p[0].rentaName, values: p.map(item => item.emissionValues.sum(a => a.value).toFixed(1))};
                        temp2.push(item);

                    }

                }
                else if (isCategoriesFromSingleCategory) {
                    let grouped = bar.emissionData.groupBy(item => item.productGroup);

                    for (const p of grouped) {
                        let item = {key: p[0].productGroup, displayName: p[0].rentaName, values: p.map(item => item.emissionValues.sum(a => a.value).toFixed(1))};
                        temp2.push(item);

                    }
                }
                else {
                    let item = {key: bar.name, displayName: bar.name, values: grouped.flatMap(item => item.sum(a => a.emissionValues.sum(x => x.value)).toFixed(1))};
                    temp2.push(item);
                }
            });
        }
    };

    traverseData(data);
    let data2 = temp2.map(item => {
        return (
            {
                type: 'bar',
                stack: 'x',
                name: item.displayName,
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: item.values
            }
        );

    });
    
    const legendHeight = Math.max(Math.ceil(data2.length / 2) * 24, 50);

    // Configure the chart options
    const options = {
        tooltip: {
            valueFormatter: (value: any) => `${value} ${Localizer.emissionsCalculatedEmissionsTotalUnit}`,
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            bottom: 5,
            top: 250,
            orient: 'vertical',
            height: legendHeight,
        },
        grid: {
            containLabel: true,
            y: 30,
            height: 210,
        },
        xAxis: {
            type: 'category',
            data: days
        },
        yAxis: {
            type: 'value'
        },
        notMerge: true,
        series: data2
    };

    if (eChartsRef && eChartsRef.current) {
        eChartsRef.current?.getEchartsInstance().setOption(options, true);
    }

    return <ReactECharts
        option={options}
        ref={eChartsRef}
        style={{height: `${255 + legendHeight}px`}}
    />;
};

export default StackedBarChart;
