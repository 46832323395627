import React, {useState, useEffect} from "react";
import styles from "@/pages/ProductDetails/ProductDetails.module.scss";
import Localizer from "@/localization/Localizer";
import {Accordion, Carousel, CarouselNavigation, CarouselPagination, DocumentPreviewModal, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";
import ProductInfoModel from "@/models/server/ProductInfoModel";
import {sortProductAttributes, sortProductInfoAttributes} from "@/helpers/ProductInfoHelper";
import {AttributeType, FileType} from "@/models/Enums";
import ImageProvider from "@/providers/ImageProvider";
import FileApiModel from "@/models/server/FileApiModel";
import ProductDetailsEditTechnicalInformation from "@/pages/ProductDetails/ProductDetailsEditTechnicalInformation/ProductDetailsEditTechnicalInformation";
import {assert, FileModel} from "@renta-apps/athenaeum-toolkit";
import ProductTechnicalInformationTable from "@/pages/ProductDetails/ProductTechnicalInformationTable/ProductTechnicalInformationTable";
import {ch, DocumentPreviewModel, DocumentPreviewSize, TabBadgeShape} from "@renta-apps/athenaeum-react-common";
import ProductModelDocuments from "@/pages/ProductDetails/ProductDetailsEditTechnicalInformation/ProductModelDocuments";
import ProductAttributeModel from "@/models/server/ProductAttributeModel";
import productDetailsServices from "@/services/ProductDetailsService";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import RentaEasyController from "@/pages/RentaEasyController";
import CreatePreSignedUrlRequest from "@/models/server/Requests/CreatePreSignedUrlRequest";

type ProductDetailsTechnicalInformationProps = {
    product: ProductModel;
    editedProduct: ProductModel | null;
    description: string;
    isMobile: boolean;
    isEditing: boolean;
    isLoading: boolean;
    categoryKeys: CategoryAttributeKeyModel[] | [];
    
    onChange: () => Promise<void>;
    setDescriptionAsync: (text: string) => Promise<void>;
    emptyProductInfoModel: () => ProductInfoModel;
    alertErrorAsync: (message: string, showCloseButton: boolean, showCancelButton: boolean) => Promise<void>;
    setFullScreenImagesAsync: (images: FileApiModel[], index: number) => Promise<void>;
}

const ProductDetailsTechnicalInformation: React.FC<ProductDetailsTechnicalInformationProps> = (props: ProductDetailsTechnicalInformationProps) => {

    const _productModelInfoAccordionRef: React.RefObject<Accordion> = React.createRef();
    const _productModelDocumentPreviewModalRef: React.RefObject<DocumentPreviewModal> = React.createRef();
    
    /**
     * File to be uploaded to the product model info.
     */
    const [productModelFile, setProductModelFile] = useState<FileModel|null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [productModelsImages, setProductModelsImages] = useState<ProductModel[]>([]);

    const setToggle = (expanded: boolean): void => {
        if (expanded) {
            _productModelInfoAccordionRef.current?.recalculateContentHeight();
            setProductModelFile(null);
        }
    }
    
    const onModelImageSelectionAsync = async (info: ProductInfoModel, image: FileApiModel, currentSelected: boolean): Promise<void> => {
        // calculation of the images state  (after the selection)
        const newImages = productModelsImages.map((productModel) => {
            if (productModel.id === info.id) {
                return {
                    ...productModel,
                    externalImages: productModel.externalImages?.map((ei) => {
                        if (ei.id === image.id) {
                            return {
                                ...ei,
                                isSelected: !ei.isSelected
                            };
                        }
                        return ei;
                    })
                } as ProductModel;
            }
            return productModel;
        });
        
        setProductModelsImages(newImages);
        
        // remove/add proper images to productInfo (selected model)
        if (currentSelected) {
            info.files = info.files.filter(file => file.id !== image.id);
        }
        else {
            props.editedProduct?.infos?.forEach((otherInfo) => {
                const index: number = otherInfo.files.findIndex((file) => (file.id === image.id));
                if (index >= 0) {
                    otherInfo.files.removeAt(index);
                }
            });
            info.files.push(image);
        }
    }
    
    const isImageSelected = (productInfoId: string, selectedImage: FileApiModel): boolean => {
        return productModelsImages.find((productModel) => productModel.id === productInfoId)?.externalImages?.find(ei => ei.id === selectedImage.id)?.isSelected ?? false;
    }

    const onModelInfoNameChangeAsync = async (info: ProductInfoModel, name: string): Promise<void> => {
        info.name = name;
        await handleEmptyModelInfosAsync();
    }

    const onModelInfoFieldChangeAsync = async (attribute: ProductAttributeModel, value: string): Promise<void> => {
        attribute.value = value;
        await handleEmptyModelInfosAsync();
    }

    const handleEmptyModelInfosAsync = async (): Promise<void> => {
        const emptyInfos: ProductInfoModel[] = props.editedProduct!.infos!.filter((info) =>
            !info.name && info.attributes.every((attribute) =>
                !attribute.value
            ));

        if (emptyInfos.length > 1) {
            for (let i = 0; i < emptyInfos.length - 1; i++) {
                props.editedProduct?.infos!.remove(emptyInfos[i]);
            }
        }
    }

    const addEmptyInfoAsync = async (): Promise<void> => {
        const addEmptyInfo: boolean = props.editedProduct!.infos!.every((info) =>
            info.name || info.attributes.some((attribute) =>
                    attribute.value
            ));

        if (addEmptyInfo) {
            props.editedProduct?.infos!.push(props.emptyProductInfoModel());
        }
    }

    const saveChangesForSingleInfoModel = async (infoModel: ProductInfoModel, productId: string): Promise<void> => {

        const allInfosWithValuesHaveNames: boolean =
            (((infoModel.attributes.some((attribute) =>
                            assert(attribute.value)
                                .isString
                                .isNotEmpty
                                .isNotWhitespace
                                .getIsSuccess))
                    || infoModel.files?.length > 0)
                &&
                assert(infoModel.name)
                    .isString
                    .isNotEmpty
                    .isNotWhitespace
                    .getIsSuccess)
            ?? true;

        const allInfosWithNamesHaveValues: boolean =
            ((assert(infoModel.name)
                    .isString
                    .isNotEmpty
                    .isNotWhitespace
                    .getIsSuccess)
                &&
                infoModel
                    .attributes
                    .some((attribute) =>
                        assert(attribute.value)
                            .isString
                            .isNotEmpty
                            .isNotWhitespace
                            .getIsSuccess))
            ?? true;
    
        if (!allInfosWithValuesHaveNames) {
            await props.alertErrorAsync(Localizer.productDetailsErrorInfoWithValuesMustHaveName, true, true);
            return;
        }
    
        if (!allInfosWithNamesHaveValues) {
            await props.alertErrorAsync(Localizer.productDetailsErrorInfoMustHaveValues, true, true);
            return;
        }
    
        await productDetailsServices.saveProductInfoAsync(productId, infoModel);

        await props.onChange();
    }

    const onFileChangeAsync = async (value: FileModel): Promise<void> => {
        if (value) {
            setProductModelFile(value);
        }
    }

    const uploadMachineModelDocumentsAsync = async (productInfoModel: ProductInfoModel): Promise<void> => {
        if (props.product && productModelFile && RentaEasyConstants.productModelDocumentTypes.includes(productModelFile.type)) {
            if (productModelFile.size > RentaEasyConstants.maxMachineDocumentUploadSizeinBytes) {
                const errorMessage = Localizer.get(Localizer.productDetailsExpectedSizeOfUploadedModelDocuments, RentaEasyConstants.maxMachineDocumentUploadSizeinBytes / (1024 * 1024));
                await props.alertErrorAsync(errorMessage, true, true);
                return;
            }
            
            setIsUploading(true);

            const uploadRequest: CreatePreSignedUrlRequest = {
                productInfoId: productInfoModel.id,
                contentType: productModelFile.type,
            };
            const uploadResult = await RentaEasyController.uploadPdfAsync(productModelFile.src, uploadRequest);
            
            if (uploadResult) {
                await productDetailsServices.uploadMachineModelDocumentAsync(props.product.id, productInfoModel.id, uploadResult.reference, productModelFile.name);
            }
            
            setProductModelFile(null);
            setIsUploading(false);
            
            await props.onChange();
            
            if (!uploadResult) {
                await props.alertErrorAsync(Localizer.productDetailsExpectedFormatOfUploadedModelDocuments, true, true);
            }
        } else {
            await props.alertErrorAsync(Localizer.productDetailsExpectedFormatOfUploadedModelDocuments, true, true);
        }
    }

    const viewProductModelDocumentAsync = async (fileApiModel: FileApiModel, productInfoModel: ProductInfoModel): Promise<void> => {

        const fileModel: FileModel = fileApiModel.documentId
            // norway api file
            ? await productDetailsServices.fetchDocumentAsync(props.product?.id, fileApiModel, productInfoModel.id)
            // easy
            : await productDetailsServices.fetchProductModelDocumentAsync(props.product?.id, fileApiModel, productInfoModel.id);
        
        if (fileModel) {
            if (fileApiModel.documentId) {
                const documentPreviewModal: DocumentPreviewModal = _productModelDocumentPreviewModalRef.current!;
                const model = new DocumentPreviewModel();

                model.title = productInfoModel.name;
                model.subtitle = fileApiModel.name;
                model.document = fileModel;
                model.size = DocumentPreviewSize.Large;

                await documentPreviewModal.openAsync(model);
            } else {
                const url: string = createPdfUrl(fileModel.name);
                await ch.documentPreviewFromUrlAsync(url);
            }
        }
    }

    const downloadProductModelDocumentAsync = async (fileApiModel: FileApiModel, productInfoModelId: string): Promise<void> => {
        
        const fileModel: FileModel = fileApiModel.documentId
            // norway api file
            ? await productDetailsServices.fetchDocumentAsync(props.product?.id, fileApiModel, productInfoModelId)
            // easy
            : await productDetailsServices.fetchProductModelDocumentAsync(props.product?.id, fileApiModel, productInfoModelId);

        if (fileModel) {
            if (fileApiModel.documentId) {
                ch.download(fileModel);
            } else {
                downloadFile(createPdfUrl(fileModel.name));
            }
        }
    }

    const createPdfUrl = (pdfFileName: string): string => {
        //TODO this could come from FE API already
        return `/files/pdf/${pdfFileName}.pdf`;
    }

    const downloadFile = (src: string) => {
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = src;
        link.target = "_self";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    const deleteProductModelDocumentAsync = async (productInfo: ProductInfoModel, fileApiModel: FileApiModel): Promise<void> => {
        await productDetailsServices.deleteProductModelDocumentAsync(fileApiModel.id);

        await props.onChange();
    }

    const onModelInfoDeleteAsync = async (infoModel: ProductInfoModel, productId: string): Promise<void> => {
        await productDetailsServices.deleteProductInfoAsync(productId, infoModel);

        await props.onChange();
    }
    
    const getModelImages = (productInfo: ProductInfoModel): FileApiModel[] => {
        const images = props.editedProduct?.externalImages?.filter((image) => (assert(image.id).isString.isNotEmpty.isNotWhitespace.getIsSuccess) && (image.fileType === FileType.MainPicture || image.fileType === FileType.Picture)) ?? [];

        return images.map((image) => ({
            ...image, isSelected: productInfo.files.some((file) => file.id === image.id)
        }));
    }

    const getProductInfoImages = (productInfo: ProductInfoModel): any => {
        return (productInfo.files.length > 0)
            ? productInfo.files
                .filter(file => file.fileType !== FileType.Pdf)
                .map(image => ({src: ImageProvider.getImageAddress(image), alt: image.name ?? ""}))
            : [{src: "/images/placeholder.jpg", alt: ""}];
    }

    const getProductInfoDocuments = (productInfo: ProductInfoModel): any => {
        return productInfo.attributes
            .filter(attribute => attribute.type !== AttributeType.Text && attribute.key === "Document" && attribute.documentId)
            .map((x) => {
                const api = new FileApiModel();
                api.documentId = x.documentId;
                api.name = x.value ?? (productInfo.name + ".pdf");
                return api;
            });
    }

    const getProductInfoPDFs = (productInfo: ProductInfoModel, documents: FileApiModel[]): any => {
        const pdfs = productInfo.files.length > 0
            ? productInfo.files.filter(file => file.fileType === FileType.Pdf)
            : [];

        return pdfs.concat(documents);
    }
    
    useEffect(() => {
        const productModelsImages = ((props.isEditing) ? 
            (props.editedProduct?.infos ?? []) : 
            (props.product?.infos ?? [])
        ).map((productInfo, _index) => ({
                id: productInfo.id,
                externalImages: getModelImages(productInfo)
            } as ProductModel)
        );

        setProductModelsImages(productModelsImages);
    }, [props.isEditing, props.product]);
    
    return (
        <>
            <div className={styles.technicalInformation}>
                <div className={styles.technicalInformationNotice}>
                    {Localizer.productDetailsProductsAreExamples.toUpperCase()}
                </div>
                
                <div className={styles.technicalInformationAccordions}>
                    {((props.isEditing) ? (props.editedProduct?.infos ?? []) : (props.product?.infos ?? [])).map((productInfo, index) => {
                        const attributes = sortProductInfoAttributes(productInfo, props.categoryKeys);
                        const images: { src: string, alt: string; }[] = getProductInfoImages(productInfo);
                        const apiDocuments: FileApiModel[] = getProductInfoDocuments(productInfo);
                        const pdfs: FileApiModel[] = getProductInfoPDFs(productInfo, apiDocuments);
                        
                        return (
                            <Accordion ref={_productModelInfoAccordionRef}
                                       header={productInfo.name}
                                       autoCollapse={false}
                                       key={productInfo.id! + index}
                                       maxHeightOffset={1000}
                                       classNames={{
                                           accordion: styles.technicalInformationAccordion,
                                           headerContainer: styles.technicalInformationAccordionHeaderContainer,
                                           header: styles.technicalInformationAccordionHeader,
                                           separator: styles.technicalInformationAccordionSeparator,
                                           content: styles.technicalInformationAccordionContent,
                                           contentContainer: styles.technicalInformationAccordionContentContainer,
                                       }}
                                       onToggle={async (_, expanded) => {
                                           setToggle(expanded);
                                       }}
                            >
                                <TabContainer id="productTechnicalDetailsTabs"
                                              renderType={TabRenderType.ActiveOnly}
                                              headerStyleType={TabContainerHeaderStyleType.Underline}
                                              className={styles.technicalInformationTabs}
                                >
                                    <Tab
                                        id="TechnicalInfoTab"
                                        title={Localizer.productDetailsTechnicalInfo}
                                        onSelect={async () => await _productModelInfoAccordionRef.current?.recalculateContentHeight()}
                                    >
                                        <div className={styles.technicalInformationProduct}>
                                            {(props.isEditing && props.editedProduct) && (
                                                <ProductDetailsEditTechnicalInformation
                                                    isSpinning={props.isLoading}
                                                    index={index}
                                                    modelName={productInfo.name ?? ""}
                                                    images={productModelsImages.find(pm => pm.id === productInfo.id)?.externalImages ?? []}
                                                    attributes={sortProductAttributes(productInfo.attributes, props.categoryKeys)}
                                                    getImageSource={(image) => ImageProvider.getImageThumbnailAddress(image)}
                                                    isImageSelected={(selectedImage) => isImageSelected(productInfo.id, selectedImage)}
                                                    isLastElement={() => (index == props.editedProduct!.infos!.length - 1)}
                                                    onImageClick={async (image, isSelected) => await onModelImageSelectionAsync(productInfo, image, isSelected)}
                                                    onModelInfoNameChangeAsync={async (value) => await onModelInfoNameChangeAsync(productInfo, value)}
                                                    onModelInfoFieldChangeAsync={async (attribute, value) => await onModelInfoFieldChangeAsync(attribute, value)}
                                                    onSaveChangesForSingleInfoModelClickAsync={async () => await saveChangesForSingleInfoModel(productInfo, props.product?.id)}
                                                    onDeleteModelInfoClickAsync={async () => await onModelInfoDeleteAsync(productInfo, props.product?.id)}
                                                    onAddEmptyInfoClickAsync={async () => await addEmptyInfoAsync()}
                                                />
                                            )}
                                            {(!props.isEditing) && (
                                                <div className={styles.productDetailsTechnicalInformation}>
                                                    <div className={styles.productTechnicalInformationImage}>
                                                        <Carousel className={`${styles.productTechnicalInformationImageCarousel}`} 
                                                                  slidesPerView={1}
                                                                  navigation={CarouselNavigation.None}
                                                                  pagination={CarouselPagination.BottomOutside}
                                                        >
                                                            {images.map(({src, alt}, index) => (
                                                                <a key={index}
                                                                   onClick={async () => await props.setFullScreenImagesAsync(productInfo.files
                                                                       .filter(file => file.fileType !== FileType.Pdf), index)}
                                                                >
                                                                    <img src={src} alt={alt}
                                                                         onError={ImageProvider.onError()}
                                                                    />
                                                                </a>
                                                            ))}
                                                        </Carousel>
                                                    </div>
                                                    <ProductTechnicalInformationTable
                                                        className={styles.productTechnicalInformationTable}
                                                        items={attributes}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
    
                                    <Tab id="technicalDocumentationTab"
                                         title={Localizer.constructionsiteEquipmentDocuments}
                                         badge={pdfs.length?.toString() ?? "0"}
                                         tabBadgeShape={TabBadgeShape.Square}
                                         badgeBackgroundColor={"rgb(254, 80, 0)"}
                                         onSelect={async () => await _productModelInfoAccordionRef.current?.recalculateContentHeight()}
                                    >
                                        <div>
                                            <ProductModelDocuments
                                                index={index}
                                                isSpinning={props.isLoading}
                                                isUploading={isUploading}
                                                productInfo={productInfo}
                                                pdfs={[...pdfs]}
                                                editing={props.isEditing}
                                                classNames={{hover: styles.hover, uploadButton: styles.button, documentTableClass: styles.productTypeDocumentsTable}}
                                                newProductModelFile={productModelFile}
                                                isMobile={props.isMobile}
                                                viewProductModelDocumentAsync={async (fileApiModel: FileApiModel, productInfoModel: ProductInfoModel) => await viewProductModelDocumentAsync(fileApiModel, productInfoModel)}
                                                downLoadProductModelDocumentAsync={async (fileApiModel: FileApiModel, productInfoModelId: string) => await downloadProductModelDocumentAsync(fileApiModel, productInfoModelId)}
                                                deleteProductModelDocumentAsync={async (productInfo: ProductInfoModel, fileApiModel: FileApiModel) => await deleteProductModelDocumentAsync(productInfo, fileApiModel)}
                                                uploadMachineModelDocumentsAsync={async (productInfoModel: ProductInfoModel) => await uploadMachineModelDocumentsAsync(productInfoModel)}
                                                onFileChangeAsync={async (value: FileModel) => await onFileChangeAsync(value)}
                                            />
                                        </div>
                                    </Tab>
                                </TabContainer>
                            </Accordion>
                        )})
                    }
                </div>
            </div>

            <DocumentPreviewModal
                ref={_productModelDocumentPreviewModalRef}
            />
        </>
    );
}

export default ProductDetailsTechnicalInformation;