import React from "react";
import Localizer from "@/localization/Localizer";
import {Link} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import ProductAvailabilityModel from "@/models/server/ProductAvailabilityModel";

type ProductDetailsAvailabilityInfoProps = {
    availability: ProductAvailabilityModel[];
    text: string;
    externalId: string;
}

const ProductDetailsAvailabilityInfo: React.FC<ProductDetailsAvailabilityInfoProps> = (props: ProductDetailsAvailabilityInfoProps) => {

    const renderRentedRow = (details: ProductAvailabilityModel): string => {
        let content: string = " ";
        if (details.orderedBy) {
            content += `${details.orderedBy} /`;
        }
        if (details.contractNumber) {
            content += `${details.contractNumber} /`;
        }
        if (details.clientCompany) {
            content += `${details.clientCompany} /`;
        }
        if (details.constructionsitePhone) {
            content += `${details.constructionsitePhone}`;
        }
        return content;
    };

    return (
        (props.availability?.length) ? (
            <tbody>
            <tr>
                <th rowSpan={props.availability!.length + 1}
                    scope="row"
                >
                    {props.text}
                </th>
            </tr>

            {props.availability?.map((details, index) => (
                <tr key={(details?.rentaId?.toString() ?? "") + index}>
                    <td>
                        {details.count} {Localizer.orderAmountText} {details.productModel} {details.rentaId} / {details.productName} {renderRentedRow(details)}

                        <Link route={PageDefinitions.deviceDetailsPage.route({
                            params: {
                                rentaId: details.rentaId!,
                            }
                        })}
                        >
                            {
                                (details.trackunitSerialNumber)
                                    ? Localizer.equipmentLocationAndDetails
                                    : Localizer.equipmentDetails
                            }
                        </Link>
                    </td>
                </tr>
            ))}
            </tbody>
        ) : null
    );
};

export default ProductDetailsAvailabilityInfo;