import React from "react";
import {Button, ButtonType} from "@renta-apps/athenaeum-react-components";
import styles from "./ProductVatButtons.module.scss";
import Localizer from "@/localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

interface ProductVatButtonProps {
    environmentVatValue: number;
    isZeroVatSelected: boolean;
    isEnvVatSelected: boolean;
    disableZeroVatButton?: boolean;
    disableEnvVatButton?: boolean;
    zeroVatButtonIdPostfix?: string;
    envVatButtonIdPostfix?: string;
    onVatChangeAsync: (vat: number) => Promise<void>;
}

const ProductVatButtons = ({isZeroVatSelected, isEnvVatSelected, environmentVatValue, onVatChangeAsync, zeroVatButtonIdPostfix, envVatButtonIdPostfix, disableEnvVatButton, disableZeroVatButton}: ProductVatButtonProps): JSX.Element | null => {
        
    const showNetPriceOnly: boolean = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceNetOnly);
    
    return (
        (!showNetPriceOnly) ? (
            <div
                className={styles.wrapper}
                data-is-zero-vat-selected={String(isZeroVatSelected)}
                data-is-env-vat-selected={String(isEnvVatSelected)}
            >
                <Button
                    id={zeroVatButtonIdPostfix ? `vat_0_price${zeroVatButtonIdPostfix}` : 'vat_0_price'}
                    type={ButtonType.Orange}
                    className={styles.underlineButton}
                    disabled={disableZeroVatButton}
                    title={Localizer.productDetailsVat0}
                    label={Localizer.productDetailsVat0}
                    onClick={async () => await onVatChangeAsync(0)}
                />
                <Button
                    id={envVatButtonIdPostfix ? `vat_price${envVatButtonIdPostfix}` : 'vat_price'}
                    type={ButtonType.Orange}
                    className={styles.underlineButton}
                    disabled={disableEnvVatButton}
                    title={Localizer.productDetailsVatValue.format(environmentVatValue)}
                    label={Localizer.productDetailsVatValue.format(environmentVatValue)}
                    onClick={async () => await onVatChangeAsync(environmentVatValue)}
                />
            </div>
        ) : null
    );
};

export default ProductVatButtons;