import React from "react";
import {BaseComponent, BasePageParameters} from "@renta-apps/athenaeum-react-common";
import PlanModel from "@/models/server/PlanModel";
import PlanDefinitionInput from "@/models/server/PlanDefinitionInput";
import PlanCatalog from "@/components/Plan/PlanCatalog/PlanCatalog";
import PlanEditor from "@/components/Plan/PlanEditor/PlanEditor";

export interface IDeletedPlansParams extends BasePageParameters {
    isAdmin: boolean;
}

interface IDeletedPlansState {

    /**
     * Definition of the Plan under inspection/editing.
     */
    selectedPlan: PlanModel | null;

    selectedPlanIsDeleted: boolean;

    /**
     * All existing deleted Plans definitions.
     * Only filled for Admins.
     */
    deletedDefinitions: PlanModel[];

    editMode: boolean;

    /**
     * Input under editing (displayed in right panel).
     */
    currentInput: PlanDefinitionInput | null;
}

export default class DeletedPlans extends BaseComponent<IDeletedPlansParams, IDeletedPlansState> {

    public state: IDeletedPlansState = {
        selectedPlan: null,
        selectedPlanIsDeleted: false,
        currentInput: null,
        editMode: false,
        deletedDefinitions: [],
    };

    // Getters

    private get selectedPlan(): PlanModel {
        return this.state.selectedPlan!;
    }

    private get currentInput(): PlanDefinitionInput | null {
        return this.state.currentInput;
    }

    private get editMode(): boolean {
        return this.state.editMode;
    }

    public get hasSelectedPlan(): boolean {
        return (!!this.state.selectedPlan);
    }

    // Async-methods

    private async closeEditorAsync(): Promise<void> {
        await this.setState({
            selectedPlan: null,
            editMode: false,
            currentInput: null
        });

        await this.fetchDataAsync();
    }

    private async openEditorAsync(selectedPlan: PlanModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedPlan,
            selectedPlanIsDeleted: isDeleted,
            editMode: true
        });
    }

    private async openDefinitionAsync(selectedPlan: PlanModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedPlan,
            selectedPlanIsDeleted: isDeleted,
            editMode: false
        });
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.fetchDataAsync();
    }

    public async fetchDataAsync(): Promise<void> {
        let deletedDefinitions: PlanModel[] = [];

        if (this.props.isAdmin) {
            deletedDefinitions = await this.postAsync(`/api/Plans/GetDeletedPlanDefinitions`, null);
        }

        await this.setState({
            deletedDefinitions
        });
    }

    public render(): React.ReactNode {

        return (
            <>
                {
                    (this.props.isAdmin) && (!this.hasSelectedPlan) &&
                    (
                        <>
                            <PlanCatalog canEdit
                                         canCreateNew={false}
                                         planModels={this.state.deletedDefinitions}
                                         openEditorAsync={async (model) => await this.openEditorAsync(model, true)}
                                         openDefinitionAsync={async (model) => await this.openDefinitionAsync(model, true)}
                            />
                        </>
                    )
                }
                {
                    (this.hasSelectedPlan) &&
                    (
                        <PlanEditor closeEditorAsync={async () => await this.closeEditorAsync()}
                                    model={this.selectedPlan!}
                                    currentInput={this.currentInput}
                                    editMode={this.editMode}
                                    deleted={this.state.selectedPlanIsDeleted}
                        />
                    )
                }
            </>
        );
    }
}