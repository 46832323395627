import React from "react";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize, OneColumn} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import ImageProvider from "@/providers/ImageProvider";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import ProductModel from "@/models/server/ProductModel";
import ProductAccessories from "@/components/ProductConfirmationModal/ProductAccessories/ProductAccessories";
import AddToCartButton from "@/components/AddToCartButton/AddToCartButton";
import Localizer from "@/localization/Localizer";
import styles from "./ProductConfirmationModal.module.scss";

export interface IProductConfirmationModalProps {

    readonly: boolean;

    /**
     * Currently applicable VAT.
     */
    vat: number;

    /**
     * Called when the {@link ProductConfirmationModal} is opened and when user wants to change related products counts.
     */
    onUpdateProductCount(productId: string, newCount: number, productName: string | null): Promise<void>;

    className?: string;

    /**
     * Should a {@link NumberInput} be displayed with which the user can change the products count in shopping cart.
     */
    displayNumberInput?: boolean;

    /**
     * Should related products be displayed.
     * @default true
     */
    displayRelatedProducts?: boolean;

    id?: string;
}

export enum ProductConfirmationResult {
    Cancel,

    Continue,

    GoToShoppingCart
}

interface IProductConfirmationModalState {
    isSendingRequest: boolean,
}

interface IProductConfirmationData {
    product: ShoppingCartProductModel;
    amount: number;
}

export default class ProductConfirmationModal extends BaseComponent<IProductConfirmationModalProps, IProductConfirmationModalState> {

    // Inherited

    public state: IProductConfirmationModalState = {
        isSendingRequest: false,
    };

    protected getEndpoint(): string {
        return "/categories?handler=GetRelatedProducts";
    }

    // Fields

    private readonly _modalRef: React.RefObject<Modal<IProductConfirmationData>> = React.createRef();
    private _confirmationResult: ((result: ProductConfirmationResult) => void) | null = null;
    private _result: ProductConfirmationResult = ProductConfirmationResult.Cancel;

    // Boolean-getters

    private get hasData(): boolean {
        return (!!this.modal) && (!!this.modalData);
    }

    private get hasProduct(): boolean {
        return (this.hasData) && (!!this.shoppingCartProduct) && (!!this.product);
    }

    private get hasImage(): boolean {
        return (this.hasProduct) && (!!this.shoppingCartProduct.product!.imageReference) && (!!this.shoppingCartProduct.product!.imageReference.reference);
    }

    private get hasRelatedProducts(): boolean {
        return (this.hasData) && (this.shoppingCartProduct) && (this.relatedShoppingCartProducts) && (this.relatedShoppingCartProducts.length > 0);
    }

    private get displayRelatedProducts(): boolean {
        return (this.props.displayRelatedProducts !== false);
    }

    // Getters

    private get modal(): Modal<IProductConfirmationData> {
        return this._modalRef.current!;
    }

    private get modalData(): IProductConfirmationData {
        return this.modal.data!;
    }

    private get shoppingCartProduct(): ShoppingCartProductModel {
        return this.modalData.product;
    }

    private get initialAmount(): number {
        return this.modalData.amount;
    }

    private get product(): ProductModel {
        return this.shoppingCartProduct.product!;
    }

    private get relatedShoppingCartProducts(): ShoppingCartProductModel[] {
        return this.shoppingCartProduct.relatedProducts;
    }

    private get imageSrc(): string {
        return ImageProvider.getProductThumbnailSrc(this.shoppingCartProduct.product!);
    }

    private get subtitle(): string {

        const name: string = (this.hasData) && (this.shoppingCartProduct.product?.name)
            ? this.shoppingCartProduct.product.name
            : "";

        const amount: string = (this.hasData)
            ? Localizer.productConfirmationModalSubtitle.format(this.initialAmount)
            : "";

        return [name, amount].join(" ");
    }

    // Async-methods

    private async onModalOpenAsync(): Promise<void> {
        this._result = ProductConfirmationResult.Cancel;
        await this.reRenderAsync();
    }

    private async onModalCloseAsync(): Promise<void> {
        if (this._confirmationResult) {
            this._confirmationResult(this._result);
            this._confirmationResult = null;
        }
    }

    private async goToShoppingCartAsync(): Promise<void> {
        await this.closeAsync(ProductConfirmationResult.GoToShoppingCart);
    }

    private async continueAsync(): Promise<void> {
        await this.closeAsync(ProductConfirmationResult.Continue);
    }

    public async closeAsync(result: ProductConfirmationResult = ProductConfirmationResult.Cancel): Promise<void> {
        if (result !== ProductConfirmationResult.Cancel) {
            if (this.props.readonly) {
                return;
            }
        }

        this._result = result;

        await this.modal.closeAsync();
    }

    public async confirmAsync(product: ShoppingCartProductModel, amount: number): Promise<ProductConfirmationResult> {

        await this.modal.openAsync({product, amount});

        await this.setState({ isSendingRequest: true });

        await this.props.onUpdateProductCount(this.product.id, this.modalData.amount, this.product.name);

        await this.setState({ isSendingRequest: false });

        return new Promise((contirmationResult) => {
            this._confirmationResult = contirmationResult;
        });
    }

    // Renders

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   ref={this._modalRef}
                   className={this.css(this.props.className, styles.productConfirmationModal)}
                   size={ModalSize.Auto}
                   contentClassName={styles.maxWidth}
                   title={Localizer.catalogProductAddedToCart}
                   onOpen={() => this.onModalOpenAsync()}
                   onClose={() => this.onModalCloseAsync()}
            >
                <p>{this.subtitle}</p>

                {
                    (this.hasProduct) &&
                    (
                        <div>

                            {
                                (this.hasImage) &&
                                (
                                    <div className={this.css(styles.imgContent, styles.imageContent)}
                                         style={{backgroundImage: `url(${this.imageSrc})`}}
                                    >
                                        <img className={styles.imgProduct}
                                             src={this.imageSrc}
                                             alt={this.product.name ?? undefined}
                                        />
                                    </div>
                                )
                            }

                            {
                                (this.props.displayNumberInput) &&
                                (
                                    <AddToCartButton className={styles.addToCartButton}
                                                     count={this.shoppingCartProduct.count}
                                                     disabled={this.props.readonly}
                                                     onFirstClick={async () => {
                                                     }}
                                                     onCountChange={async (newCount) => await this.props.onUpdateProductCount(this.product.id, newCount, this.product.name)}
                                    />
                                )
                            }

                            {
                                (this.displayRelatedProducts) && (this.hasRelatedProducts) &&
                                (
                                    <OneColumn>

                                        <h3>{Localizer.productDetailsAccessories}</h3>

                                        <div>
                                            <ProductAccessories relatedProducts={this.shoppingCartProduct.relatedProducts}
                                                                vat={this.props.vat}
                                                                readonly={this.props.readonly}
                                                                onUpdateProductCount={this.props.onUpdateProductCount}
                                            />
                                        </div>

                                    </OneColumn>
                                )
                            }

                            <ButtonContainer className={styles.buttons}>

                                <Button id="product-modal-go-to-cart"
                                        type={ButtonType.Orange}
                                        label={Localizer.productModalGoToCart}
                                        disabled={this.state.isSendingRequest}
                                        onClick={() => this.goToShoppingCartAsync()}
                                />

                                <Button id="product-modal-continue-shopping"
                                        type={ButtonType.Blue}
                                        label={Localizer.productModalContinueShopping}
                                        onClick={() => this.continueAsync()}
                                />

                            </ButtonContainer>

                        </div>
                    )
                }

            </Modal>
        );
    }

}