import React from "react";
import styles from "@/pages/ProductDetails/ProductDetails.module.scss";

type ProductDetailsLayoutProps = {
    isMobile: boolean;
    mainEl: React.ReactNode;
    sideEl: React.ReactNode;
}

const ProductDetailsLayout: React.FC<ProductDetailsLayoutProps> = (props: ProductDetailsLayoutProps) => {
    
    return (
        props.isMobile ? (
            <div className={styles.productDetailsMobileTemplate}>
                {props.mainEl}
                {props.sideEl}
            </div>
        ) : (
            <div className={styles.productDetailsDesktopTemplate}>
                <div className={styles.productDetailsDesktopTemplateMainSection}>
                    {props.mainEl}
                </div>
                <div className={styles.productDetailsDesktopTemplateSideSection}>
                    {props.sideEl}
                </div>
            </div>
        )
    );
}

export default ProductDetailsLayout;