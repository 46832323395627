import {BaseTransformProvider, TFormat} from "@renta-apps/athenaeum-toolkit";
import {SelectListGroup, SelectListItem} from "@renta-apps/athenaeum-react-components";
import BaseRole from "@/models/server/BaseRole";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import {ContractUserModel} from "@/models/server/ContractUserModel";
import Localizer from "@/localization/Localizer";
import {ConstructionSiteRoleModel} from "@/models/server/ConstructionSiteRoleModel";
import UserModel from "@/models/server/UserModel";
import {PageRoute} from "@renta-apps/athenaeum-react-common";
import DepotModel from "@/models/server/DepotModel";
import SupportRequestTypeModel from "@/models/server/SupportRequestTypeModel";
import ConstructionSiteUserModel from "@/models/server/ConstructionSiteUserModel";
import {ContractConstructionSiteUserModel} from "@/models/server/ContractConstructionSiteUserModel";
import { ConstructionSiteInfo } from "../models/server/ConstructionSiteInfo";

class TransformProvider extends BaseTransformProvider {

    public constructor() {
        super();
    }

    public pageRouteToString(pageRoute: PageRoute | null): string {
        return (pageRoute !== undefined) && (pageRoute !== null)
            ? (pageRoute.index !== undefined) && (pageRoute.index !== null)
                ? `${pageRoute.name} ${pageRoute.index}`
                : pageRoute.name
            : "";
    }

    public toString(item: any, format: TFormat | null | undefined = null): string {

        if ((item === undefined) || (item === null)) {
            return "";
        }

        if ((item instanceof UserModel) || (item.isUser === true)) {
            return this.userToString(item as UserModel);
        }

        if ((item.constructor.name === PageRoute.name) || (item.isPageRoute)) {
            return this.pageRouteToString(item as PageRoute);
        }

        return super.toString(item, format);
    }

    public toSelectListItem(item: any): SelectListItem {

        if ((item instanceof SelectListItem) || (item.isSelectListItem)) {
            return item as SelectListItem;
        }

        if ((item instanceof BaseRole) || (item.isBaseRole)) {
            return this.toBaseRoleListItem(item as BaseRole);
        }

        if ((item instanceof UserModel) || (item.isUserModel)) {
            return this.toUserModelListItem(item as UserModel);
        }

        if ((item instanceof ConstructionSiteUserModel) || (item.isConstructionSiteUserModel)) {
            return this.toConstructionSiteUserModelListItem(item as ConstructionSiteUserModel);
        }

        if ((item instanceof ConstructionSiteModel) || (item.isConstructionSiteModel)) {
            return this.toConstructionSiteModelListItem(item as ConstructionSiteModel);
        }

        if ((item instanceof ConstructionSiteInfo) || (item.isConstructionSiteInfo)) {
            return this.toConstructionSiteInfoListItem(item as ConstructionSiteInfo);
        }

        if ((item instanceof ContractUserModel) || (item.isContractUserModel)) {
            return this.toContractUserModelListItem(item as ContractUserModel);
        }

        if ((item instanceof ContractConstructionSiteUserModel) || (item.isContractUserModel)) {
            return this.toContractConstructionSiteUserModelListItem(item as ContractConstructionSiteUserModel);
        }

        if ((item instanceof DepotModel) || (item.isDepotModel)) {
            return this.toLocationModelListItem(item as DepotModel);
        }

        if ((item instanceof OrganizationContractModel) || (item.isOrganizationContractModel)) {
            return this.toOrganizationContractModelListItem(item as OrganizationContractModel);
        }

        if ((item instanceof SupportRequestTypeModel || item.isSupportRequestTypeModel)) {
            return this.toSupportRequestTypeModelListItem(item as SupportRequestTypeModel);
        }

        return super.toSelectListItem(item) as SelectListItem;
    }

    public constructionSiteRoleToString(constructionSiteModel: ConstructionSiteRoleModel): string {
        return constructionSiteModel.constructionSiteNameDisplayName;

    }

    public constructionSiteToString(constructionSiteModel: ConstructionSiteModel): string {
        return constructionSiteModel.displayName!;

    }

    public organizationContractToString(organizationContract: OrganizationContractModel): string {
        return (organizationContract.customerNumber)
            ? `${organizationContract.name} (${organizationContract.customerNumber})`
            : organizationContract.name ?? "";

    }

    public userToString(item: UserModel): string {
        const firstname: string = (item.firstName) ? item.firstName : "";
        const lastName: string = (item.lastName) ? item.lastName : "";
        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return item.username;
    }

    public userModelToString(item: UserModel): string {
        const firstname: string = (item.firstName) ? item.firstName : "";
        const lastName: string = (item.lastName) ? item.lastName : "";
        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return item.email!;
    }

    public toBaseRoleListItem(item: BaseRole): SelectListItem {
        const selectListItem = new SelectListItem();
        selectListItem.ref = item;
        selectListItem.group = new SelectListGroup();
        selectListItem.group.name = item.group || "";
        selectListItem.value = item.id;
        selectListItem.text = "Unknown role";

        if (item.entityName) {
            selectListItem.text = (item.entityAdditionalName)
                ? `${item.entityName}  (${item.entityAdditionalName})`
                : item.entityName;
        }
        else {
            if (item.entityAdditionalName) {
                selectListItem.text = item.entityAdditionalName;
            }
        }

        selectListItem.subtext = Localizer.get(item.roleName);

        selectListItem.selected = (item.isSelected === true);

        return selectListItem;
    }

    public toConstructionSiteModelListItem(constructionSite: ConstructionSiteModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = constructionSite;
        selectListItem.value = constructionSite.id;
        selectListItem.text = constructionSite.name ?? Localizer.genericNameUnknown;
        selectListItem.subtext = constructionSite.externalReference ?? "";

        return selectListItem;
    }

    public toConstructionSiteInfoListItem(constructionSite: ConstructionSiteInfo): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = constructionSite;
        selectListItem.value = constructionSite.id;
        selectListItem.text = constructionSite.name ?? Localizer.genericNameUnknown;
        selectListItem.subtext = constructionSite.externalReference ?? "";

        return selectListItem;
    }

    public toUserModelListItem(contractUser: UserModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = contractUser;
        selectListItem.value = contractUser.id;
        selectListItem.text = ((contractUser.firstName) && (contractUser.lastName))
            ? `${contractUser.firstName} ${contractUser.lastName}`
            :  contractUser.email ?? Localizer.genericNameUnknown;

        return selectListItem;
    }

    public toConstructionSiteUserModelListItem(contractUser: ConstructionSiteUserModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = contractUser;
        selectListItem.value = contractUser.userId!;
        selectListItem.text = ((contractUser.firstName) && (contractUser.lastName))
            ? `${contractUser.firstName} ${contractUser.lastName}`
            :  contractUser.email ?? Localizer.genericNameUnknown;
        selectListItem.subtext = contractUser.roleName ?? "";
        return selectListItem;
    }

    public toContractUserModelListItem(contractUser: ContractUserModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = contractUser;
        selectListItem.value = contractUser.userId;
        selectListItem.text = ((contractUser.firstName) && (contractUser.lastName))
            ? `${contractUser.firstName} ${contractUser.lastName}`
            :  contractUser.email ?? Localizer.genericNameUnknown;
        selectListItem.subtext = contractUser.roleName ?? "";

        return selectListItem;
    }

    public toContractConstructionSiteUserModelListItem(contractUser: ContractConstructionSiteUserModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = contractUser;
        selectListItem.value = contractUser.userId;
        selectListItem.text = ((contractUser.firstName) && (contractUser.lastName))
            ? `${contractUser.firstName} ${contractUser.lastName}`
            :  contractUser.email ?? Localizer.genericNameUnknown;
        selectListItem.subtext = contractUser.roleName ?? "";

        return selectListItem;
    }

    public toLocationModelListItem(location: DepotModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = location;
        selectListItem.value = location.id;
        selectListItem.text = location.name!;

        return selectListItem;
    }

    public toSupportRequestTypeModelListItem(type: SupportRequestTypeModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = type;
        selectListItem.value = type.sendRequestTo.toString()!;
        selectListItem.text = type.requestTypeTitle!;

        return selectListItem;
    }

    public toOrganizationContractModelListItem(organizationContract: OrganizationContractModel): SelectListItem {
        const selectListItem: SelectListItem = new SelectListItem();
        selectListItem.ref = organizationContract;
        selectListItem.value = organizationContract.contractId;
        selectListItem.text = `${organizationContract.name}` ?? Localizer.genericNameUnknown;

        selectListItem.text = organizationContract.name ?
            organizationContract.customerNumber ?
                `${organizationContract.name} (${organizationContract.customerNumber})`
                : organizationContract.name
            : Localizer.genericNameUnknown;

        selectListItem.subtext = organizationContract.additionalName ?? "";

        return selectListItem;
    }

    public toCapitalizedInitialLetter(strng: string): string {
        return strng.charAt(0).toUpperCase() + strng.slice(1).toLowerCase();
    }

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite === true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }
}

export default new TransformProvider();