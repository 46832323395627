import ProductInfoModel from "@/models/server/ProductInfoModel";
import ProductAttributeModel from "@/models/server/ProductAttributeModel";
import {AttributeType} from "@/models/Enums";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";

export interface TechnicalInfoRow {
    key: string;
    value: string;
    isLink?: boolean;
    documentId?: number | null;
}

export const sortProductAttributes = (attributes: ProductAttributeModel[] | null, categoryKeys: CategoryAttributeKeyModel[] | null): ProductAttributeModel[] => {
    if (!attributes?.length || !categoryKeys?.length) {
        return [];
    }

    return getSortedProductAttributes(attributes, categoryKeys);
}

export const sortProductInfoAttributes = (productInfo: ProductInfoModel, categoryKeys: CategoryAttributeKeyModel[]): TechnicalInfoRow[] => {
    if (!productInfo?.attributes?.length) {
        return [];
    }
    
    let attributes = productInfo.attributes
        .filter(attribute => !!attribute.value);

    attributes = getSortedProductAttributes(attributes, categoryKeys);
    
    const textTechnicalInfoRow = attributes
        .filter(attribute =>
            attribute.type === AttributeType.Text &&
            attribute.key !== "Document" &&
            attribute.key !== "Model description")
        .map(x => ({key: x.key ?? '', value: x.value ?? '', isLink: false}));

    const urlTechnicalInfoRow = attributes.filter(attribute => attribute.type !== AttributeType.Text && attribute.key !== "Document")
        .map(x => ({key: x.key ?? '', value: x.value ?? '', isLink: true, documentId: x.documentId}));

    const modelDescriptionRow = attributes
        .filter(attribute =>
            attribute.type === AttributeType.Text &&
            attribute.key === "Model description")
        .map(x => ({key: x.key ?? '', value: x.value ?? '', isLink: false}));
    
    return [...textTechnicalInfoRow, ...urlTechnicalInfoRow, ...modelDescriptionRow];
};

function getSortedProductAttributes(attributes: ProductAttributeModel[], categoryKeys: CategoryAttributeKeyModel[]): ProductAttributeModel[] {
    attributes.forEach(attribute => {
        const categoryKey = categoryKeys.find(ck => ck?.name === attribute?.key);
        attribute.priority = categoryKey ? categoryKey.priority : null;
    });

    // the attributes are sorted by priority asc, then by key asc
    // the attributes with null priorities are moved to the end
    // the last on the list are attributes with null priorities and null keys
    return attributes.sort((attribute1: ProductAttributeModel, attribute2: ProductAttributeModel) => {
        if(attribute1.priority === null && attribute2.priority) {return 1;}
        if(attribute1.priority && attribute2.priority === null) {return -1;}
        if(attribute1.priority === null && attribute2.priority === null) {return 0;}
        if(attribute1.priority! < attribute2.priority!) {return -1;}
        if(attribute1.priority! > attribute2.priority!) {return 1;}
        if(attribute1.key === null && attribute2.key) {return 1;}
        if(attribute1.key && attribute2.key === null) {return -1;}
        if(attribute1.key === null && attribute2.key === null) {return 0;}
        if(attribute1.key! < attribute2.key!) {return -1;}
        if(attribute1.key! > attribute2.key!) {return 1;}
        return 0;
    });
}