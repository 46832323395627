import React, {useEffect, useState, lazy} from 'react';
import {ChartData} from "@/components/Charts/Models/IDoughnutData";
import Localizer from "@/localization/Localizer";
import {getNextData} from "@/helpers/ChartDataHelper";

const ReactECharts = lazy(() => import('echarts-for-react'));

interface Props {
    data: ChartData[];

    onDataChange(data: ChartData[]): void;

    onSetPrevious(data: ChartData[]): void;
}

const DoughnutChart: React.FC<Props> = ({data, onDataChange, onSetPrevious}) => {
    const [currentData, setCurrentData] = useState(data);

    const eChartsRef = React.useRef(null as any);

    useEffect(() => {
        setCurrentData(data);
    }, [currentData, data]);

    const handleChartClick = (params: any) => {
        const clickedCategory: ChartData = params.data;
        onSetPrevious(data);
        
        const nextData = getNextData(clickedCategory);
        onDataChange(nextData);
        setCurrentData(nextData);
    };

    // Configure the chart options
    const options = {
        notMerge: true,
        tooltip: {
            trigger: 'item',
            valueFormatter: (value: any) => `${value.toFixed(1)} ${Localizer.emissionsCalculatedEmissionsTotalUnit}`,
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'], // Outer and inner radius values
                data: currentData, // Use the current data for rendering
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false
                },
                emphasis: {
                    itemStyle: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                }
            }
        ]
    };

    if (eChartsRef && eChartsRef.current) {
        eChartsRef.current?.getEchartsInstance().setOption(options, true);
    }

    return (

        <ReactECharts
            key={data[0]?.id}
            option={options}
            style={{height: '300px'}}
            ref={eChartsRef}
            onEvents={{
                click: handleChartClick // Attach click event handler
            }}
        />

    );
};

export default DoughnutChart;
