import DepotModel from "@/models/server/DepotModel";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import { ConstructionSiteInfo } from "./ConstructionSiteInfo";

export class ConstructionSiteModel extends ConstructionSiteInfo {

    public hiddenFromUser: boolean = false;

    public ownerId: string = "";

    public opened: Date = new Date();

    public products: number = 0;

    public totalProducts: number = 0;

    public isOpen: boolean = false;

    public isDeleted: boolean = false;

    public customerNumber: string | null = null;

    public ownerName: string | null = null;

    public ownerAdditionalName: string | null = null;

    public displayName: string | null = null;

    public rentDate: Date | null = null;

    public renter: string | null = null;

    public dailyPrice: number | null = null;

    public monthlyPrice: number | null = null;

    public address: string | null = null;

    public postalCode: string | null = null;

    public city: string | null = null;

    public inventoryItemDisplayNumbers: string[] = [];

    public depot: DepotModel | null = null;

    public location: GeoLocation | null = null;

    // TODO: create class
    //public users: ConstructionSiteUserModel[] = [];

    public externalAddress: string | null = null;

    public customerId: string | null = null;

    /**
     * Is the Company a user favorite.
     * This is set per-user, there is no global value.
     */
    public favorite: boolean = false;

    public isConstructionSiteModel: boolean = true;

}