import React from "react";
import ReactDOM from "react-dom";

// TODO: fix this - for yet unknown reason, application is not loading correctly with createRoot
// import React, { StrictMode } from "react";
// import { createRoot } from 'react-dom/client';

import App from "@/App";


/* FA (Font Awesome Pro) */
import "./lib/fa/fa";
/* Bootstrap 4 */
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
/* JQuery required by bootstrap */
import "jquery";
/* Popper.js required by bootstrap */
import "popper.js";
/* App styles */
import "./base.scss";

import "@/athenaeum.overrides.scss";
import "@/bootstrap.overrides.scss";

ReactDOM.render(<App/>, document.getElementById("root"));

// TODO: fix this - for yet unknown reason, application is not loading correctly with createRoot
// const container = document.getElementById('root');
// const root = createRoot(container!); // createRoot(container!) if you use TypeScript
// root.render(<App />); // try also <StrictMode><App /></StrictMode>