import { IFeatureToggle } from "@/models/FeatureToggle";
import { IFeatureToggleResponse } from "@/models/server/Responses/FeatureToggleResponse";
import {ApiProvider} from "@renta-apps/athenaeum-react-common";

export default class LocalFeatureToggleClient {
    private _toggles: IFeatureToggle[];
    private readonly _interval: number = 30000;
    private _timerRef?: NodeJS.Timer;

    constructor() {
        this._toggles = [];
    }

    private async fetchToggles() {
        const response: IFeatureToggleResponse = await ApiProvider.getAsync<IFeatureToggleResponse>(
            "/api/LocalFeatureFlag/FeatureFlags",
            null,
        );
        this._toggles = response.flags;
    }

    public async start(): Promise<void> {
        await this.fetchToggles();

        if (this._interval > 0) {
            this._timerRef = setInterval(() => this.fetchToggles(), this._interval);
        }
    }

    public isEnabled(flagName: string) {
        const flag = this.findFlag(flagName);
        return flag?.enabled ?? false;
    }

    public update(flagName: string, enabled: boolean): void {
        const flag = this.findFlag(flagName);
        if (flag) {
            flag.enabled = enabled;
        }
    }
    
    private findFlag(flagName: string): IFeatureToggle | undefined {
        return this._toggles.find(p => p.flagName === flagName.toUpperCase());
    }
}