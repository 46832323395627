import React from 'react';
import { BaseAsyncComponent, IBaseAsyncComponentState, IBaseComponent, PageRoute } from "@renta-apps/athenaeum-react-common";
import { IconSize, RouteWidget } from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import Frontpage from '@/pages/Frontpage/Frontpage';

interface IFleetInfoProps {
    route: PageRoute;

    fetchData(sender: IBaseComponent): Promise<IFleetWidgetData>;
}

export interface IFleetWidgetData {
    equipmentsMaxIdleInDays: number | null;
    alarmCountSinceYesterday: number | null;
}

interface IFleetWidgetState extends IBaseAsyncComponentState<IFleetWidgetData> {
}

export default class FleetInfoWidget extends BaseAsyncComponent<IFleetInfoProps, IFleetWidgetState, {}> {

    public state: IFleetWidgetState = {
        data: null,
        isLoading: false,
    };

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    protected async fetchDataAsync(): Promise<IFleetWidgetData> {
        return this.props.fetchData(this);
    }

    public get fleetInfo(): string {

        if (!this.state.data) {
            return "";
        }

        let alarmCountSinceYesterdayDescription = '';
        let equipmentsMaxIdleInDaysDescription = '';

        if (this.state.data.equipmentsMaxIdleInDays) {
            equipmentsMaxIdleInDaysDescription = Localizer.get(Localizer.fleetMonitoringButtonDescriptionEquipmentIdle, this.state.data.equipmentsMaxIdleInDays);
        }
        if (this.state.data.alarmCountSinceYesterday) {
            alarmCountSinceYesterdayDescription = Localizer.get(Localizer.fleetMonitoringButtonDescriptionSinceYesterday, this.state.data.alarmCountSinceYesterday);
        }

        // Check if both descriptions have values
        if (equipmentsMaxIdleInDaysDescription && alarmCountSinceYesterdayDescription) {
            return `${equipmentsMaxIdleInDaysDescription} | ${alarmCountSinceYesterdayDescription}`;
        } else {
            // If only one description has a value, return it without a trailing pipe.
            return equipmentsMaxIdleInDaysDescription || alarmCountSinceYesterdayDescription;
        }
    }

    public render(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                id={"fleet_monitoring_widget"}
                classNames={Frontpage.linkWidgetClassNames}
                route={this.props.route}
                label={Localizer.fleetMonitoringButtonText}
                description={this.fleetInfo}
                icon={{ name: "monitor-waveform", size: IconSize.X3 }}
            />
        );
    }
}
