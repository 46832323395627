import React from "react";
import styles from "@/pages/ProductDetails/ProductDetails.module.scss";
import Localizer from "@/localization/Localizer";
import {OneColumn, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import ReactMarkdown from "react-markdown";
import ProductModel from "@/models/server/ProductModel";

type ProductDetailsDescriptionProps = {
    product: ProductModel | null;
    editedProduct: ProductModel | null;
    description: string;
    isEditing: boolean;
    isLoading: boolean;

    setDescriptionAsync: (text: string) => Promise<void>;
}

const ProductDetailsDescription: React.FC<ProductDetailsDescriptionProps> = (props: ProductDetailsDescriptionProps) => {
    
    if ((!props.product?.showDescription || !props.description) && !props.isEditing) {
        return null;
    }

    // Renta personnel seem to use Windows in editing the descriptions, meaning there is double the amount of newline-characters compared to actual line changes.
    // Or maybe that's just how line-changes are supposed to be encoded on the Web.
    let rows: number = props.description.search(/\n/) / 2;
    if (rows < 10) {
        rows = 10;
    }
    
    return (
        <OneColumn className={styles.description}>
            {(props.isEditing && props.editedProduct) ?
                (<TextAreaInput value={props.editedProduct.description ?? undefined}
                       label={Localizer.genericDescription}
                       rows={rows}
                       readonly={props.isLoading}
                       onChange={async (_, value) => await props.setDescriptionAsync(value)}
                />)
                :
                (<ReactMarkdown>
                    {props.description}
                </ReactMarkdown>)
            }
        </OneColumn>
    );
}

export default ProductDetailsDescription;