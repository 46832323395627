import Localizer from "../../../localization/Localizer";
import {onRedirect, pageData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";

describe('Desktop - Admin user can open user management', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.visit('/');
    });

    function AssertUserManagementPageContent(): void {
        cy.get("h1")
            .should('contain', Localizer.adminPageRentaAdminManager);

        cy.get("#table_users_grid")
            .find("tr")
            .should("have.length.above", 1);

        cy.get("h3")
            .first()
            .should('contain', Localizer.adminUserManagementGenerateMailingList);

        cy.get("h3")
            .eq(1)
            .should('contain', Localizer.adminUserManagementInviteUser);

        cy.get("h4")
            .should('contain', Localizer.adminUserManagementPendingInvitationsTitle);

    }

    it('Page opens from menu navigation', () => {

        onRedirect(() => cy.visit(Localizer.pageRoutesAdmin));

        pageData().admin.links.users().click();

        AssertUserManagementPageContent();

    });

    it('Page opens from url', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        AssertUserManagementPageContent();
    });

    it('User debug info', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        AssertUserManagementPageContent();

        GridHelper.getGridContentByRowAndCell("#table_users_grid", 0, 6).click()

        cy.get("#user_debug_container");

        cy.get("#user_debug_content")
            .invoke('text')
            .then((text) => {
                expect(text.length).to.above(0);
            });
        cy.get("#close_user_debug").click();

        cy.get("#user_debug_container").should('not.exist');

    });
});

export default {};
