/**
 *  General use type for component callback results, to be returned by functions like onSubmit, onDelete, etc.
 */


export enum CallbackResultType {
    SUCCESS = "success",
    ERROR = "error",
    INVALID = "invalid", // for form validation
}

/**
 * status: general operation status
 * message: optional message to be displayed to the user
 * details: optional details to be displayed to the user - like field validation errors
 */
export type CallbackResult = {
    status: CallbackResultType;
    message?: string;
    details?: {[key: string]: string};
};