import React from "react";
import {
    Button,
    ButtonType, Form, IconSize, Modal, ModalSize, NumberInput,
    PageContainer,
    PageHeader,
    PageRow, TextInput,
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import Localizer from "@/localization/Localizer";
import styles from "./PricingToolTemplate.module.scss";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import PageDefinitions, {IPageRouteParams} from "@/providers/PageDefinitions";
import SingleDiscountRequest from "@/models/server/Requests/PricingTool/SingleDiscountRequest";
import PricingToolDiscountModel from "@/models/server/Models/PricingTool/PricingToolDiscountModel";
import UpdateDiscountRequest from "@/models/server/Requests/PricingTool/UpdateDiscountRequest";
import DiscountStatusLog from "@/models/server/Models/PricingTool/DiscountStatusLog";
import {PricingToolDiscountStatus} from "@/models/Enums";
import UpdateDiscountStatusRequest from "@/models/server/Requests/PricingTool/UpdateDiscountStatusRequest";
import {IPricingToolCatalogParams} from "@/pages/PricingToolCatalog/PricingToolCatalog";
import PricingToolDiscountDetails from "@/components/PricingTool/PricingToolDisountDetails/PricingToolDisountDetails";
import PricingToolStatusLog from "@/components/PricingTool/PricingToolStatusLog/PricingToolStatusLog";


export interface IPricingToolTemplateParams extends BasePageParameters {
    discountId: string;
}

interface IDiscountState {
    selectedTemplate: PricingToolDiscountModel | undefined;
    newTemplateDetails: PricingToolDiscountModel | undefined;
    statusLog: DiscountStatusLog[];
}

export default class PricingToolTemplate extends AdminPage<IPricingToolTemplateParams, IDiscountState> {

    /***
     * PricingToolTemplate is very similar to PricingToolDiscount. It can and probably should be merged into one component.
     * Maybe it can be done along with moving to functional components.
     */
    
    public state: IDiscountState = {
        selectedTemplate: undefined,
        newTemplateDetails: undefined,
        statusLog: []
    };

    protected get title(): string {
        return Localizer.admin;
    }

    private readonly _editModalRef: React.RefObject<Modal> = React.createRef();

    protected get breadCrumbs(): BreadCrumbItem[] {
        const salesToolsBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbSalesTools, PageDefinitions.salesTools.route());
        const pricingToolBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbPricingTool, PageDefinitions.pricingTool.route());
        const templatesBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbPricingToolTemplates, PageDefinitions.pricingToolTemplates.route());
        return [salesToolsBreadcrumb, pricingToolBreadcrumb, templatesBreadcrumb,];
    }

    public async initializeAsync(): Promise<void> {
        await this.getTemplateInfoAsync();
        await this.getTemplateStatusLogsAsync();
        await super.initializeAsync();
    }

    private async getTemplateInfoAsync(): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = this.props.parameters?.discountId;
        if (!request.discountId) {
            return;
        }
        const template: PricingToolDiscountModel = await this.postAsync("/api/PricingTool/GetDiscountTemplate", request);
        if (template) {
            await this.setState({
                selectedTemplate: template,
            });
        }
    }

    private async getTemplateStatusLogsAsync(): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = this.props.parameters?.discountId;
        const statusLogs: DiscountStatusLog[] = await this.postAsync("/api/PricingTool/ListDiscountStatusLog", request);
        if (statusLogs) {
            await this.setState({
                statusLog: statusLogs,
            });
        }
    }

    private async updateTemplateAsync(discount: PricingToolDiscountModel): Promise<void> {
        const request: UpdateDiscountRequest = {...discount} as UpdateDiscountRequest;
        request.overallDiscountPercentage = discount.overallDiscountPercentage || 0.0;
        await this.postAsync("/api/PricingTool/UpdateDiscountTemplate", request);
    }

    private async updateTemplateStatusAsync(discountId: string, status: PricingToolDiscountStatus): Promise<void> {
        const request: UpdateDiscountStatusRequest = {discountId, status};
        await this.postAsync("/api/PricingTool/UpdateContractDiscountStatus", request);
    }

    private async openEditModalAsync(): Promise<any> {
        if (this.state.selectedTemplate) {
            this.setState({newTemplateDetails: {...this.state.selectedTemplate}});
            this._editModalRef.current?.openAsync();
        }
    }

    private async handleEditSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.newTemplateDetails?.discountId) {
            await this.updateTemplateAsync(this.state.newTemplateDetails);
            await this.getTemplateInfoAsync();
            await this.getTemplateStatusLogsAsync();
        }
        this.setState({newTemplateDetails: undefined});
        this._editModalRef.current?.closeAsync()
    }

    private async openCatalogModalAsync(): Promise<any> {
        if (this.state.selectedTemplate?.discountId) {
            const route = PageDefinitions.pricingToolCatalog.route({params: {discountId: this.state.selectedTemplate?.discountId, discountType: 'template'}} as IPageRouteParams<IPricingToolCatalogParams>);
            await PageRouteProvider.redirectAsync(route);
        }
    }

    private async handlePublishSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.selectedTemplate?.discountId) {
            await this.updateTemplateStatusAsync(this.state.selectedTemplate.discountId, PricingToolDiscountStatus.Active);
            await this.getTemplateInfoAsync();
            await this.getTemplateStatusLogsAsync();
        }
    }

    public renderComponents(): React.ReactNode {
        return (
            <div>
                <div className={styles.columns} >
                    <PricingToolDiscountDetails type={"template"} discount={this.state.selectedTemplate} />
                    <PricingToolStatusLog statusLog={this.state.statusLog} />
                </div>
                <div className={styles.footerButtonsContaner} >
                    <Button id="TemplateEditBtn"
                            className={styles.button}
                            label={Localizer.genericEdit}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedTemplate}
                            onClick={async () => await this.openEditModalAsync()}
                            icon={{name: "file-pen", size: IconSize.Normal}}
                    />
                    {/* 
                        TODO: add request approval / approve button 
                    */}
                    <Button id="PublishTemplateBtn"
                            className={styles.button}
                            label={Localizer.genericPublish}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedTemplate || this.state.selectedTemplate.status === PricingToolDiscountStatus.Active}
                            onClick={async () => await this.handlePublishSubmitAsync(true)}
                            icon={{name: "file-arrow-up", size: IconSize.Normal}}
                    />
                    <Button id="TemplateProductsBtn"
                            className={styles.button}
                            label={Localizer.pricingToolTemplateProducts}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedTemplate}
                            onClick={async () => await this.openCatalogModalAsync()}
                            icon={{name: "table-list", size: IconSize.Normal}}
                    />
                </div>
            </div>
        );
    }

    public renderModals(): React.ReactNode {
        return (
            <div>
                <Modal info keepTextFormatting
                       title={Localizer.pricingToolEditTemplateHeader}
                       ref={this._editModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <Form onSubmit={async () => await this.handleEditSubmitAsync(true)} >
                            <div className={styles.row} >
                                <TextInput id="pricingToolTemplateName"
                                           required
                                           label={Localizer.pricingToolDiscountName}
                                           value={this.state.newTemplateDetails?.discountName ?? ""}
                                           onChange={async (input, value: string) =>
                                               this.setState({newTemplateDetails: {...this.state.newTemplateDetails, discountName: value} as PricingToolDiscountModel})
                                           }
                                />
                            </div>
                            <div className={styles.row} >
                                <NumberInput id="pricingToolTemplatDiscount"
                                             min={0}
                                             max={100}
                                             step={0.1}
                                             required
                                             readonly={!this.state.selectedTemplate}
                                             label={Localizer.pricingToolOverallDiscount}
                                             value={this.state.newTemplateDetails?.overallDiscountPercentage}
                                             onChange={async (input, value: number) =>
                                                 this.setState({newTemplateDetails: {...this.state.newTemplateDetails, overallDiscountPercentage: value} as PricingToolDiscountModel})
                                             }
                                />
                            </div>
    
                            <div className={styles.modalButtons}>
                                <Button
                                    label={Localizer.formCancel}
                                    type={ButtonType.Light}
                                    onClick={async () => await this.handleEditSubmitAsync(false)}
                                />
                                <Button id="confirmaAddTemplateButton"
                                        label={Localizer.formSave}
                                        type={ButtonType.Orange}
                                        submit
                                />
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.pricingTool}>
                <BreadCrumb items={this.breadCrumbs} />

                <PageHeader title={Localizer.pricingToolEditTemplateHeader} className={styles.header} />

                <PageRow>
                    <div className="col">
                        <div>
                            {(this.renderComponents())}
                        </div>
                    </div>
                </PageRow>

                {this.renderModals()}
            </PageContainer>
        );
    }
}