import React from "react";

import styles from "./CategoryItemEdit.module.scss";
import {Button, ButtonType, Checkbox, IconSize, InlineType} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

const CategoryEditHelp = () => {

    return (
        <div className={styles.helpContent}>
            <div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Button type={ButtonType.Default}
                                icon={{name: "arrow-up", size: IconSize.Normal}}
                        />
                        <div className={styles.priority}> 5 </div>
                        <Button type={ButtonType.Default}
                                icon={{name: "arrow-down", size: IconSize.Normal}}
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpPriority}
                    </div>
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Button label={""}
                                icon={{name: "camera", size: IconSize.Normal}}
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpImage}
                    </div>
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Button type={ButtonType.Danger}
                                icon={{name: "trash", size: IconSize.Normal}}
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpDelete}
                    </div>
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Button type={ButtonType.Success}
                                icon={{name: "arrow-right", size: IconSize.Normal}}
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpOpen}
                    </div>
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  label={Localizer.categoryManagementHide}
                                  value={false}
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpHide}
                    </div>
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Button type={ButtonType.Orange}
                                icon={{name: "save", size: IconSize.Normal}}
                                className={styles.saveCategoryButton}
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpSave}
                    </div>
                </div>
                <div className={styles.helpContentRow}>
                    <div className={styles.helpContentIcon}>
                        <Button type={ButtonType.Orange}
                                icon={{name: "exchange", size: IconSize.Normal}}
                                
                        />
                    </div>
                    <div className={styles.helpContentText}>
                        {Localizer.catalogEditCategoryHelpChangeParent}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryEditHelp;