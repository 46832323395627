import React from "react";
import {ActionType, BaseAsyncComponent, IBaseAsyncComponentState} from "@renta-apps/athenaeum-react-common";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {CellAction, CellModel, ColumnDefinition, ColumnType, Grid} from "@renta-apps/athenaeum-react-components";
import AlarmModel from "@/models/server/AlarmModel";
import Localizer from "@/localization/Localizer";

interface IAlarmsGridProps {
    fetchAlarms(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<AlarmModel>>;

    deleteAlarm?(id: string): Promise<void>;
}

interface IAlarmsGridState extends IBaseAsyncComponentState<IPagedList<AlarmModel> | null> {
    isLoading: boolean;
}

export default class AlarmsGrid extends BaseAsyncComponent<IAlarmsGridProps, IAlarmsGridState, {}> {

    public state: IAlarmsGridState = {
        data: null,
        isLoading: false,

    };

    protected getEndpoint(): string {
        return "";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private async fetchAlarmsAsync(pageNumber: number,
                                   pageSize: number,
                                   sortColumnName: string | null,
                                   sortDirection: SortDirection | null): Promise<IPagedList<AlarmModel>> {

        return await this.props.fetchAlarms(pageNumber, pageSize, sortColumnName, sortDirection);

    }

    private readonly _alarmsGrid: React.RefObject<Grid<AlarmModel>> = React.createRef();

    private readonly _alarmColumns: ColumnDefinition[] = [
        {
            header: Localizer.alarmsGridNameLanguageItemName,
            sorting: true,
            accessor: nameof<AlarmModel>(alarm => alarm.name),
            editable: false,
        },
        {
            header: Localizer.genericEmail,
            sorting: true,
            accessor: "user.email",
            editable: false,
        },
        {
            header: Localizer.alarmsGridTypeLanguageItemName,
            sorting: true,
            type: ColumnType.Enum,
            format: "AlarmType",
            init: (cell: CellModel<AlarmModel>) => this.initColumn(cell),
            accessor: nameof<AlarmModel>(user => user.alarmType),
            editable: false,
        },
        {
            header: Localizer.alarmsGridLastOccurrenceLanguageItemName,
            sorting: true,
            format: "g",
            accessor: nameof<AlarmModel>(user => user.latestOccurrence),
            editable: false,
        },
        {
            minWidth: 25,
            type: ColumnType.Icon,
            init: (cell) => this.initActions(cell),
            actions: [
                {
                    name: "delete",
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    confirm: (cell: CellModel<AlarmModel>) => Localizer.get(Localizer.alarmsDeleteConfirmLanguageItemName, cell.model.name),
                    callback: async (cell) => await this.deleteAlarmAsync(cell)
                }
            ]
        }
    ];

    private initActions(cell: CellModel<AlarmModel>) {
        const deleteAction: CellAction<AlarmModel> = cell.actions[0];

        deleteAction.visible = this.props.deleteAlarm !== undefined;
    }

    private async deleteAlarmAsync(cell: CellModel<AlarmModel>) {

        if (this.props.deleteAlarm) {
            await this.props.deleteAlarm(cell.model.id);
        }

        if(this._alarmsGrid.current){
            this._alarmsGrid.current!.rows.remove(cell.row);

            await this._alarmsGrid.current!.reRenderAsync();
        }

    }

    private initColumn(cell: CellModel<AlarmModel>): void {
        cell.readonly = true;
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <Grid responsive version2Styles
                      id={"alarms_grid"}
                      pagination={10}
                      ref={this._alarmsGrid}
                      columns={this._alarmColumns}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.fetchAlarmsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                />

            </React.Fragment>
        );
    }

}