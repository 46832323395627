import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Fleet Monitoring - Return requests grid navigation tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should open construction site page when click on the construction site name', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(4, 0)
            .find('[data-cy="constructionSiteLink"]')
            .first()
            .click();

        cy.url().should('include', `${Localizer.pageRoutesConstructionSiteDetails}?id=543a4878-9501-423d-93cf-1fda42cdc61c`);
    });

    it('should open company page when click on the company name', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(2, 1)
            .find('[data-cy="customerLink"]')
            .first()
            .click();

        cy.url().should('include', `${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`);
    });

    it('should open device details page when click on product name', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetReturnDetails', { fixture: 'getReturnDetails.json' });

        cy.intercept('POST', '/api/Fleet/GetDeviceExtendedDetails', { fixture: 'getDeviceExtendedDetails.json' });

        cy.intercept('POST', '/api/Product/GetMachineDocuments', { fixture: 'machineDocumentsData.json' });

        cy.intercept('POST', '/api/Locations/GetDeviceUsage', { fixture: 'getDeviceUsage.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        const testedRow = 2;

        DeviceGridHelper.getReturnRequestsGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .find('[data-cy="deviceLink"]')
            .first()
            .click();

        cy.url().should('include', Localizer.pageRoutesDeviceDetailsPage);
        cy.url().should('include', `rentaId=15322`);
    });

    it('should open product details page when click on product name', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetReturnDetails', { fixture: 'getReturnDetails.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        const testedRow = 4;

        DeviceGridHelper.getReturnRequestsGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .find('[data-cy="productLink"]')
            .first()
            .click();

        cy.url().should('include', Localizer.pageRoutesProductDetails);
        cy.url().should('include', `kuormaaja-300`);
    });
});