import React from "react";
import {
    Button,
    ButtonType,
    DateInput,
    Dropdown,
    DropdownOrderBy,
    Form,
    IconSize,
    Modal,
    ModalSize,
    NumberInput,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem,
    TextInput,
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import Localizer from "@/localization/Localizer";
import styles from "./PricingToolDiscount.module.scss";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import PageDefinitions, {IPageRouteParams} from "@/providers/PageDefinitions";
import SingleDiscountRequest from "@/models/server/Requests/PricingTool/SingleDiscountRequest";
import PricingToolDiscountModel from "@/models/server/Models/PricingTool/PricingToolDiscountModel";
import UpdateDiscountRequest from "@/models/server/Requests/PricingTool/UpdateDiscountRequest";
import DiscountStatusLog from "@/models/server/Models/PricingTool/DiscountStatusLog";
import {PricingToolDiscountStatus} from "@/models/Enums";
import UpdateDiscountStatusRequest from "@/models/server/Requests/PricingTool/UpdateDiscountStatusRequest";
import {IPricingToolCatalogParams} from "@/pages/PricingToolCatalog/PricingToolCatalog";
import {OrganizationContractInfo} from "@/models/server/Models/PricingTool/OrganizationContractInfo";
import {ContractConstructionSite} from "@/models/server/Models/PricingTool/ContractConstructionSite";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ExportDiscountRequest from "@/models/server/Requests/PricingTool/ExportDiscountRequest";
import PricingToolDiscountDetails from "@/components/PricingTool/PricingToolDisountDetails/PricingToolDisountDetails";
import PricingToolStatusLog from "@/components/PricingTool/PricingToolStatusLog/PricingToolStatusLog";


export interface IPricingToolDiscountParams extends BasePageParameters {
    discountId: string;
}

interface IDiscountState {
    organizationContracts: SelectListItem[];
    contractConstructionSites: SelectListItem[];
    selectedContract: SelectListItem | undefined;
    selectedConstructionSite: SelectListItem | undefined;
    selectedDiscount: PricingToolDiscountModel | undefined;
    newDiscountDetails: PricingToolDiscountModel | undefined;
    statusLog: DiscountStatusLog[];
}

export default class PricingToolDiscount extends AdminPage<IPricingToolDiscountParams, IDiscountState> {

    /***
     * PricingToolDiscount is very similar to PricingToolTemplate. It can and probably should be merged into one component.
     * Maybe it can be done along with moving to functional components.
     */

    public state: IDiscountState = {
        organizationContracts: [],
        contractConstructionSites: [],
        selectedContract: undefined,
        selectedConstructionSite: undefined,
        selectedDiscount: undefined,
        newDiscountDetails: undefined,
        statusLog: []
    };

    protected get title(): string {
        return Localizer.admin;
    }

    private readonly _editModalRef: React.RefObject<Modal> = React.createRef();

    protected get breadCrumbs(): BreadCrumbItem[] {
        const salesToolsBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbSalesTools, PageDefinitions.salesTools.route());
        const pricingToolBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbPricingTool, PageDefinitions.pricingTool.route());
        const discountsBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbPricingToolDiscounts, PageDefinitions.pricingToolDiscounts.route());
        return [salesToolsBreadcrumb, pricingToolBreadcrumb, discountsBreadcrumb,];
    }

    public async initializeAsync(): Promise<void> {
        await this.getDiscountInfoAsync();
        await this.getDiscountStatusLogsAsync();
        await this.getContractItemsAsync();
        await super.initializeAsync();
    }

    private async getContractItemsAsync(): Promise<void> {
        const contracts: OrganizationContractInfo[] = await this.postAsync("/api/PricingTool/ListOrganizationContracts");
        await this.setState({organizationContracts: contracts ? contracts.map(contract => {
                return new SelectListItem(contract.contractId, contract.contractName);
            }) : []});
        await this.reRenderAsync();
    }

    private async getContractConstructionSitesItemsAsync(contractId: string): Promise<void> {
        const constructionSites: ContractConstructionSite[] = await this.postAsync("/api/PricingTool/ListContractConstructionSites", contractId);

        this.setState({contractConstructionSites: constructionSites ? constructionSites.map(site => {
                return new SelectListItem(site.constructionSiteId, site.constructionSiteName);
            }) : []});
        await this.reRenderAsync();
    }

    private async getDiscountInfoAsync(): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = this.props.parameters?.discountId;
        if (!request.discountId) {
            return;
        }
        const discount: PricingToolDiscountModel = await this.postAsync("/api/PricingTool/GetContractDiscount", request);
        if (discount) {
            this.setState({
                selectedDiscount: discount,
            });
        }
    }

    private async getDiscountStatusLogsAsync(): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = this.props.parameters?.discountId;
        const statusLogs: DiscountStatusLog[] = await this.postAsync("/api/PricingTool/ListDiscountStatusLog", request);
        if (statusLogs) {
            this.setState({
                statusLog: statusLogs,
            });
        }
    }

    private async setSelectedContractAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedContract: item || undefined});
        if (item?.value) {
            this.setState({selectedConstructionSite: undefined});
            await this.getContractConstructionSitesItemsAsync(item.value);
            if (this.state.newDiscountDetails) {
                this.setState({newDiscountDetails: {...this.state.newDiscountDetails, organizationContractId: item?.value, constructionSiteId: undefined}});
            }
        }
    }

    private async setSelectedConstructionSiteAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedConstructionSite: item || undefined});
        if (this.state.newDiscountDetails) {
            this.setState({newDiscountDetails: {...this.state.newDiscountDetails, constructionSiteId: item?.value}});
        }
    }

    private async updateDiscountAsync(discount: PricingToolDiscountModel): Promise<void> {
        const request: UpdateDiscountRequest = {...discount} as UpdateDiscountRequest;
        request.overallDiscountPercentage = discount.overallDiscountPercentage || 0.0;
        await this.postAsync("/api/PricingTool/UpdateContractDiscount", request);
    }

    private async updateTemplateStatusAsync(discountId: string, status: PricingToolDiscountStatus): Promise<void> {
        const request: UpdateDiscountStatusRequest = {discountId, status};
        await this.postAsync("/api/PricingTool/UpdateContractDiscountStatus", request);
    }

    private async openEditModalAsync(): Promise<any> {
        if (this.state.selectedDiscount) {
            this.setState({newDiscountDetails: {...this.state.selectedDiscount}});
            this._editModalRef.current?.openAsync();
        }
    }

    private async handleEditSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.newDiscountDetails?.discountId) {
            await this.updateDiscountAsync(this.state.newDiscountDetails);
            await this.getDiscountInfoAsync();
            await this.getDiscountStatusLogsAsync();
        }
        this.setState({newDiscountDetails: undefined});
        this._editModalRef.current?.closeAsync()
    }

    private async openCatalogModalAsync(): Promise<any> {
        if (this.state.selectedDiscount?.discountId) {
            const route = PageDefinitions.pricingToolCatalog.route({
                params: {discountId: this.state.selectedDiscount?.discountId, discountType: 'discount', categoryId: ''} ,
            } as IPageRouteParams<IPricingToolCatalogParams>);
            await PageRouteProvider.redirectAsync(route);
        }
    }

    private async openExportDiscountAsync(): Promise<any> {
        if (this.state.selectedDiscount?.discountId) {
            const request: ExportDiscountRequest = {
                discountId: this.state.selectedDiscount?.discountId,
                skipZeroDiscounts: true,
            };
            const excelFile: FileModel = await this.postAsync("/api/PricingTool/GetDiscountExcelExportFile", request);
            ch.download(excelFile);
        }
    }

    private async openSendDiscountAsync(): Promise<any> {
        if (this.state.selectedDiscount?.discountId) {
            const request: ExportDiscountRequest = {
                discountId: this.state.selectedDiscount?.discountId,
                skipZeroDiscounts: true,
            };
            await this.postAsync("/api/PricingTool/SendDiscountDataEmail", request);
        }
    }

    private async handlePublishSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.selectedDiscount?.discountId) {
            await this.updateTemplateStatusAsync(this.state.selectedDiscount.discountId, PricingToolDiscountStatus.Active);
            await this.getDiscountInfoAsync();
            await this.getDiscountStatusLogsAsync();
        }
    }

    public renderComponents(): React.ReactNode {
        return (
            <div>
                <div className={styles.columns} >
                    <PricingToolDiscountDetails type={"discount"} discount={this.state.selectedDiscount} />
                    <PricingToolStatusLog statusLog={this.state.statusLog} />
                </div>
                <div className={styles.footerButtonsContaner} >
                    <Button id="EditDiscountBtn"
                            className={styles.button}
                            label={Localizer.genericEdit}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedDiscount}
                            onClick={async () => await this.openEditModalAsync()}
                            icon={{name: "file-pen", size: IconSize.Normal}}
                    />
                    <Button id="ExportDiscountBtn"
                            className={styles.button}
                            label={Localizer.genericExport}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedDiscount}
                            onClick={async () => await this.openExportDiscountAsync()}
                            icon={{name: "file-arrow-down", size: IconSize.Normal}}
                    />
                    <Button id="SendDiscountBtn"
                            className={styles.button}
                            label={Localizer.genericSendAsEmail}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedDiscount}
                            onClick={async () => await this.openSendDiscountAsync()}
                            icon={{name: "envelope", size: IconSize.Normal}}
                    />
                    {/*
                        TODO: add request approval / approve button
                        TODO: add delete button?
                    */}
                    <Button id="PublishDiscountBtn"
                            className={styles.button}
                            label={Localizer.genericPublish}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedDiscount || this.state.selectedDiscount.status === PricingToolDiscountStatus.Active}
                            onClick={async () => await this.handlePublishSubmitAsync(true)}
                            icon={{name: "file-arrow-up", size: IconSize.Normal}}
                    />
                    <Button id="DiscountProductsBtn"
                            className={styles.button}
                            label={Localizer.pricingToolDiscountProducts}
                            type={ButtonType.Orange}
                            disabled={!this.state.selectedDiscount}
                            onClick={async () => await this.openCatalogModalAsync()}
                            icon={{name: "table-list", size: IconSize.Normal}}
                    />
                </div>
            </div>
        );
    }

    public renderModals(): React.ReactNode {
        return (
            <div>
                <Modal info keepTextFormatting
                       title={Localizer.pricingToolEditDiscountHeader}
                       ref={this._editModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="EditDiscountModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <Form onSubmit={async () => await this.handleEditSubmitAsync(true)} >
                            <div className={styles.row} >
                                <Dropdown id="SelectContractDD"
                                          className={styles.dropdown}
                                          label={Localizer.pricingToolSelectOrganizationContract}
                                          minWidth="100px"
                                          orderBy={DropdownOrderBy.None}
                                          selectedItem={this.state.newDiscountDetails?.organizationContractId}
                                          items={this.state.organizationContracts}
                                          onChange={async (sender, item) => await this.setSelectedContractAsync(item)}
                                />
                            </div>
                            <div className={styles.row} >
                                <Dropdown id="SelectConstructionSiteDD"
                                          className={styles.dropdown}
                                          label={Localizer.pricingToolSelectConstructionSite}
                                          minWidth="100px"
                                          orderBy={DropdownOrderBy.None}
                                          selectedItem={this.state.newDiscountDetails?.constructionSiteId}
                                          items={this.state.contractConstructionSites}
                                          disabled={!this.state.selectedContract}
                                          onChange={async (sender, item) => await this.setSelectedConstructionSiteAsync(item)}
                                />
                            </div>
                            <div className={styles.row} >
                                <TextInput id="pricingToolDiscountName"
                                           required
                                           label={Localizer.pricingToolDiscountName}
                                           value={this.state.newDiscountDetails?.discountName ?? ""}
                                           onChange={async (_input: TextInput, value: string) =>
                                               this.setState({newDiscountDetails: {...this.state.newDiscountDetails, discountName: value} as PricingToolDiscountModel})
                                            }
                                />
                            </div>
                            <div className={styles.row} >
                                <NumberInput id="pricingToolTemplatDiscount"
                                             min={0}
                                             max={100}
                                             step={0.1}
                                             required
                                             readonly={!this.state.selectedDiscount}
                                             label={Localizer.pricingToolOverallDiscount}
                                             value={this.state.newDiscountDetails?.overallDiscountPercentage}
                                             onChange={async (input, value: number) =>
                                                 this.setState({newDiscountDetails: {...this.state.newDiscountDetails, overallDiscountPercentage: value} as PricingToolDiscountModel})
                                             }
                                />
                            </div>
                            <div className={styles.row} >
                                <DateInput id="pricingToolTemplatDiscount"
                                           required
                                           label={Localizer.pricingToolTimeStart}
                                           value={this.state.newDiscountDetails?.discountTimeLimitStart}
                                           maxDate={this.state.newDiscountDetails?.discountTimeLimitEnd}
                                           onChange={async (value: Date) =>
                                               this.setState({newDiscountDetails: {...this.state.newDiscountDetails, discountTimeLimitStart: value} as PricingToolDiscountModel})
                                           }
                                />
                            </div>
                            <div className={styles.row} >
                                <div className={styles.valueColumn} >
                                    <DateInput id="pricingToolTemplatDiscount"
                                               required
                                               minDate={this.state.newDiscountDetails?.discountTimeLimitStart}
                                               label={Localizer.pricingToolTimeEnd}
                                               value={this.state.newDiscountDetails?.discountTimeLimitEnd}
                                               onChange={async (value: Date) =>
                                                   this.setState({newDiscountDetails: {...this.state.newDiscountDetails, discountTimeLimitEnd: value} as PricingToolDiscountModel})
                                               }
                                    />
                                </div>
                            </div>

                            <div className={styles.modalButtons}>
                                <Button
                                    label={Localizer.formCancel}
                                    type={ButtonType.Light}
                                    onClick={async () => await this.handleEditSubmitAsync(false)}
                                />
                                <Button id="confirmaAddTemplateButton"
                                        label={Localizer.formSave}
                                        type={ButtonType.Orange}
                                        submit
                                />
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.pricingTool}>
                <BreadCrumb items={this.breadCrumbs} />

                <PageHeader title={Localizer.pricingToolEditDiscountHeader} className={styles.header} />

                <PageRow>
                    <div className="col">
                        <div>
                            {(this.renderComponents())}
                        </div>
                    </div>
                </PageRow>

                {this.renderModals()}
            </PageContainer>
        );
    }
}