import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {IDropdownItem, Pagination} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {ReturnRequestModel} from "@/models/server/ReturnRequestModel";
import {ReturnDetailsModel} from '@/models/server/ReturnDetailsModel';
import {getReturnDetailsAsync, getReturnsDetailsAsync, getReturnsPagedListAsync} from "@/services/FleetService";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";

import ViewControl, {FleetMonitoringFilters} from "./ViewControl/ViewControl";
import ReturnRequestGrid from "./ReturnGrid/ReturnGrid";
import {FleetMonitoringFiltersAndPagination} from "./FleetMonitoringContainer";

interface IReturnRequestsProps {
    urlParams?: FleetMonitoringFiltersAndPagination;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    onFilterParamsChange: (filters: FleetMonitoringFiltersAndPagination) => void;
}

const ReturnRequests: React.FC<IReturnRequestsProps> = (props: IReturnRequestsProps) => {
    const defaultPageNumber = 1;
    const defaultPageSize = 25;

    // Store user filter selections.
    const [paginationPageNumber, setPaginationPageNumber] = useState<number>(defaultPageNumber);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSize);
    const [sortAndFilters, setSortAndFilters] = useState<FleetMonitoringFilters | undefined>(undefined);

    // Other state variables.
    const [filteredReturnRequests, setFilteredReturnRequests] = useState<ReturnRequestModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [totalItemCount, setTotalItemCount] = useState<number>(1);

    const dataGridRef = useRef<HTMLDivElement>(null);

    const USER_DATA_STORAGE_KEY = useMemo(() => {
        return `ReturnRequestFilters-${props.userRoleContractId}-${props.userRoleConstructionSiteId}`;
    }, [props.userRoleContractId, props.userRoleConstructionSiteId]);

    useEffect(() => {
        let initialParams = props.urlParams;
        if (!initialParams) {
            initialParams = UserInteractionDataStorage.get(USER_DATA_STORAGE_KEY, new FleetMonitoringFiltersAndPagination(), DataStorageType.Page);
        }

        const initialFilters = {
            constructionSite: initialParams!.constructionSite,
            constructionSiteId: initialParams!.constructionSiteId,
            deviceName: initialParams!.deviceName,
            productGroup: initialParams!.productGroup,
            productGroupId: initialParams!.productGroupId,
            statusFilterValue: initialParams!.statusFilterValue,
            company: initialParams!.company,
            companyId: initialParams!.companyId,
            depotId: initialParams!.depotId,
            sortBy: initialParams!.sortBy ?? 'PickupTime',
            sortOrder: initialParams!.sortOrder ?? 'Asc'
        }
        const pageNumber = initialParams!.pageNumber === undefined ? defaultPageNumber : Number(initialParams!.pageNumber);
        const pageSize = initialParams!.pageSize === undefined ? defaultPageSize : Number(initialParams!.pageSize);

        setSortAndFilters(initialFilters);
        setPaginationPageNumber(pageNumber);
        setPaginationPageSize(pageSize);

        loadReturnRequests(initialFilters, pageNumber, pageSize, false).catch();
    }, []);

    const sortByItems: IDropdownItem[] = [
        {
            name: Localizer.fleetMonitoringPageFiltersSortByPickupTime,
            value: "PickupTime",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByStatus,
            value: "Status",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByCompany,
            value: "CustomerName",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByConstructionSite,
            value: "ConstructionSiteName",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByDepot,
            value: "Depot",
        },
    ];

    const handleOnFilter = (filters: FleetMonitoringFilters) => {
        if (JSON.stringify(filters) === JSON.stringify(sortAndFilters)) {
            return;
        }

        setSortAndFilters(filters);
        setPaginationPageNumber(1);
        saveUserSelection(filters, 1, paginationPageSize);
        loadReturnRequests(filters, 1, paginationPageSize, false).catch();
    }

    const handleOnPageNumberChange = (pageNumber: number) => {
        setPaginationPageNumber(pageNumber);
        saveUserSelection(sortAndFilters!, pageNumber, paginationPageSize);
        loadReturnRequests(sortAndFilters!, pageNumber, paginationPageSize, true).catch();
    };

    const handleOnPageSizeChange = (pageSize: number) => {
        setPaginationPageSize(pageSize);
        saveUserSelection(sortAndFilters!, paginationPageNumber, pageSize);
        loadReturnRequests(sortAndFilters!, paginationPageNumber, pageSize, true).catch();
    };

    const loadReturnRequests = async (filters: FleetMonitoringFilters, pageNumber: number, pageSize: number, scrollToTop: boolean) => {
        setIsLoading(true);

        try {
            const filteredReturnRequests: IPagedList<ReturnRequestModel> = await getReturnsPagedListAsync(
                pageNumber,
                pageSize,
                (props.userRoleIsAdmin || !props.userRoleContractId) ? undefined : props.userRoleContractId,
                (props.userRoleIsAdmin || !props.userRoleConstructionSiteId) ? undefined : props.userRoleConstructionSiteId,
                filters.sortBy,
                SortDirection[filters.sortOrder as keyof typeof SortDirection],
                filters.constructionSite,
                filters.constructionSiteId,
                filters.companyId,
                filters.deviceName,
                filters.productGroup,
                filters.productGroupId,
                filters.company,
                filters.statusFilterValue,
                filters.depotId
            );
            setFilteredReturnRequests(filteredReturnRequests.items);

            // Extract pagination properties.
            const { totalItemCount } = filteredReturnRequests;
            setTotalItemCount(totalItemCount);

        } catch (error) {
            console.error('Error loading devices:', error);
        } finally {
            setIsLoading(false);
            if (scrollToTop && dataGridRef.current) {
                // 45 - height of the top nav bar
                window.scrollTo({ top: dataGridRef.current.offsetTop - 45, behavior: 'smooth' });
            }
        }
    };

    const loadReturnRequestDetails = async (id: string): Promise<ReturnDetailsModel> => {
        try {
            return await getReturnDetailsAsync(id);
        } catch (error) {
            console.error('Error loading return request details:', error);
            throw error;
        }
    };

    const loadReturnRequestsDetails = async (ids: string[]): Promise<ReturnDetailsModel[]> => {
        try {
            return await getReturnsDetailsAsync(ids);
        } catch (error) {
            console.error('Error loading return requests details:', error);
            throw error;
        }
    };

    const saveUserSelection = (filters: FleetMonitoringFilters, pageNumber: number, pageSize: number) => {
        const params = new FleetMonitoringFiltersAndPagination(
            filters.constructionSite,
            filters.constructionSiteId,
            filters.deviceName,
            filters.productGroup,
            filters.productGroupId,
            filters.statusFilterValue,
            filters.company,
            filters.companyId,
            filters.depotId,
            pageNumber,
            pageSize,
            filters.sortBy,
            filters.sortOrder
        );
        props.onFilterParamsChange(params);

        UserInteractionDataStorage.set(USER_DATA_STORAGE_KEY, params, DataStorageType.Page);
    };

    return (
        <div className={styles.devices}>
            <div id="container" className={styles.container}>
                {sortAndFilters && (
                    <ViewControl
                        userRoleConstructionSiteId={props.userRoleConstructionSiteId}
                        userRoleContractId={props.userRoleContractId}
                        userRoleIsAdmin={props.userRoleIsAdmin}
                        onFilterAndSort={handleOnFilter}
                        filters={sortAndFilters}
                        sortByItems={sortByItems}
                    />
                )}

                <ReturnRequestGrid
                    returnRequests={filteredReturnRequests}
                    returnRequestDetails={(id: string) => loadReturnRequestDetails(id)}
                    returnRequestsDetails={(ids: string[]) => loadReturnRequestsDetails(ids)}
                    isLoading={isLoading}
                    gridRef={dataGridRef}
                />

                <Pagination
                    pageNumber={paginationPageNumber}
                    pageSize={paginationPageSize}
                    totalItemCount={totalItemCount}
                    onPageNumberChange={(pageNumber: number) => handleOnPageNumberChange(pageNumber)}
                    onPageSizeChange={(pageSize: number) => handleOnPageSizeChange(pageSize)}
                />
            </div>
        </div>
    );
};

export default ReturnRequests;