import React, {useEffect} from "react";
import CreateConstructionSiteServiceRequestRequest from "@/models/server/Requests/CreateConstructionSiteServiceRequestRequest";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import UserModel from "@/models/server/UserModel";
import styles from "@/pages/ConstructionSiteDetails/Equipments/Equipments.module.scss";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";
import {ServiceType} from "@/models/Enums";
import ImageProvider from "@/providers/ImageProvider";
import {
    Button,
    ButtonType,
    Icon,
    IconSize,
    ImageInput,
    IStringInputModel,
    Modal,
    OneColumn,
    Spinner,
    TextAreaInput,
} from "@renta-apps/athenaeum-react-components";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import {createConstructionSiteServiceRequest} from "@/services/ConstructionSiteService";

export type ServiceRequestModalProps = {
    modalRef: React.RefObject<Modal>,
    serviceRequest: CreateConstructionSiteServiceRequestRequest,
    serviceCreated?: () => Promise<void>,
    constructionSite: ConstructionSiteModel,
    user: UserModel | null,
}

const ServiceRequestModal: React.FC<ServiceRequestModalProps> = (props: ServiceRequestModalProps) => {

    const [isSendingRequest, setIsSendingRequest] = React.useState<boolean>(false);
    const [serviceRequestMessage, setServiceRequestMessage] = React.useState('');
    const [serviceRequest, setServiceRequest] = React.useState<CreateConstructionSiteServiceRequestRequest | null>(null);
    
    const createServiceRequestAsync = async (): Promise<void> => {
        if (!props.serviceRequest) {
            return;
        }

        setIsSendingRequest(true);

        await createConstructionSiteServiceRequest({ ...serviceRequest, message: serviceRequestMessage } as CreateConstructionSiteServiceRequestRequest);

        if (props.serviceCreated) {
            await props.serviceCreated();
        }
    
        setServiceRequest(null);
        if (props.modalRef.current) {
            await props.modalRef.current.closeAsync();
        }
    
        setServiceRequestMessage('');
    
        setIsSendingRequest(false);
    }

    const setImageToServiceRequestAsync = async (files: FileModel[]): Promise<void> => {
        if ((files?.length ?? 0) <= 0) {
            setServiceRequest({...serviceRequest, images: null} as CreateConstructionSiteServiceRequestRequest);
        }
        else {
            setServiceRequest({...serviceRequest, images: files.map(ImageProvider.convertCLImageToEasyImage)} as CreateConstructionSiteServiceRequestRequest);
        }
    }

    const serviceIcon = (serviceType: ServiceType): string => {
        switch (serviceType) {
            case ServiceType.Refuel:
                return "gas-pump";
            case ServiceType.Charging:
                return "battery-bolt";
            case ServiceType.Maintenance:
                return "cogs";
            default:
                return "cogs";
        }
    }

    const serviceTitle = (serviceType: ServiceType): string => {
        switch (serviceType) {
            case ServiceType.Refuel:
                return Localizer.enumServiceTypeRefuel;
            case ServiceType.Charging:
                return Localizer.enumServiceTypeCharging;
            case ServiceType.Maintenance:
                return Localizer.enumServiceTypeMaintenance;
            default:
                return Localizer.enumServiceTypeCustom;
        }
    }

    useEffect(() => {
        setServiceRequest(props.serviceRequest);
    }, [props.serviceRequest]);
    
    return <Modal hideCloseButton
                  ref={props.modalRef}
                  title={" "}
    >
        {(isSendingRequest) && (
            <Spinner global/>
        )}
        
        {(!!props.serviceRequest) && (
                <OneColumn>
                    <div className={styles.serviceModalTitle}>
                        <Icon name={serviceIcon(props.serviceRequest.type)}
                              size={IconSize.X3}
                              className={styles.serviceModalIcon}
                        />

                        <h2>
                            {serviceTitle(props.serviceRequest.type)}
                        </h2>

                        <Icon name={serviceIcon(props.serviceRequest.type)}
                              size={IconSize.X3}
                              className={styles.serviceModalIcon}
                              customStyle={{visibility: "hidden"}}
                        />
                    </div>

                    <div className={styles.serviceModalContent}>
                        <div>
                            {Utility.now().toLocaleDateString()}
                        </div>

                        <div>
                            {Localizer.serviceRequestConfirmationInfo}
                        </div>

                        <div>
                            {props.serviceRequest.deviceName}
                        </div>

                        <div>
                            <b>
                                {Localizer.get(Localizer.serviceRequestConfirmationConstructionSite, props.constructionSite.displayName)}
                            </b>
                        </div>

                        <div>
                            {TransformProvider.userToString(props.user!)}
                        </div>

                        {(props.serviceRequest.type === ServiceType.Maintenance) && (
                            <div className={styles.imageWrapper}>
                                <ImageInput pictures={(props.serviceRequest.images)
                                    ? props.serviceRequest.images.map(ImageProvider.convertEasyImageToCLImage)
                                    : null}
                                            convertImage={async (file) => file}
                                            selectionToolbar={{deleteButton: true, uploadButton: true, takePictureButton: true}}
                                            onChange={async (_image, files) => await setImageToServiceRequestAsync(files)}
                                            multi={true}
                                />
                            </div>
                        )}

                        <TextAreaInput required
                                       id={"serviceRequestMessage"}
                                       className={styles.serviceCommentTextArea}
                                       value={serviceRequestMessage}
                                       onChange={async (_input, value) => setServiceRequestMessage(value)}
                                       placeholder={Localizer.serviceRequestConfirmationMessagePlaceholder}
                        />
                    </div>

                    <div>
                        <Button className={styles.modalButtons}
                                type={ButtonType.Default}
                                label={Localizer.formCancel}
                                id={"serviceRequestModalClose"}
                                onClick={async () => await props.modalRef.current?.closeAsync()}
                        />

                        <Button className={`${styles.modalButtons} ${styles.right}`}
                                type={ButtonType.Orange}
                                label={Localizer.genericSend}
                                id={"serviceRequestModalCreate"}
                                disabled={isSendingRequest}
                                onClick={() => createServiceRequestAsync()}
                        />
                    </div>
                </OneColumn>
            )
        }
    </Modal>;
}

export default ServiceRequestModal;