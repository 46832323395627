import Localizer from "../../../localization/Localizer";
import {onRedirect, pageData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";

// @ts-ignore
describe('Desktop - Admin alarms page tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('Page opens from menu navigation', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdmin));

        pageData().admin.links.alarms().click();

        AssertAdminAlarmsPageContent();
    });

    it('Page opens from url', () => {
        onRedirect(() => cy.visit('/AdminAlarms'));

        AssertAdminAlarmsPageContent();
    });

    function AssertAdminAlarmsPageContent(): void {
        cy.get("h1")
            .should('contain', Localizer.alarmsPageTitle);

        GridHelper.getGridVisibleRows("#table_alarms_grid").should("have.length.above", 1);
        GridHelper.getGridCell('#table_alarms_grid', "Unit test alarm", 1);
        GridHelper.getGridCell('#table_alarms_grid', Localizer.enumAlarmTypeDeviceStarts, 2);


    }
});