import Localizer from "../../../localization/Localizer";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import { onRedirect, pageData } from "@/helpers/CypressHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

// @ts-ignore
describe('Desktop - Freshly registered user welcome page tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session('private_user_session', cy.login, {});
        cy.visit(Localizer.pageRoutesWelcome);
    });

    it('Should display the default translations of the page', () => {

        cy.get('div').should('contain.text', Localizer.welcomePageWelcomeText1);
        cy.get('div').should('contain.text', Localizer.welcomePageWelcomeText2);
        cy.get('div').should('contain.text', Localizer.welcomePageWelcomeText3);

        pageData().welcome.buttons.applyForCompanyAccess()
            .should('exist')
            .should('include.text', Localizer.welcomePageApplyForCompanyAccessButton);

        pageData().welcome.buttons.continueAsPrivate()
            .should('exist')
            .should('include.text', Localizer.welcomePageContinueAsPrivateUser);
    });

    it('Should display the company translations of the page', () => {

        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagCompanyTranslations);

        cy.reload();

        cy.get('div').should('contain.text', Localizer.welcomePageCompanyAccessText1);
        cy.get('div').should('contain.text', Localizer.welcomePageCompanyAccessText2);
        cy.get('div').should('contain.text', Localizer.welcomePageCompanyAccessText3);

        pageData().welcome.buttons.applyForCompanyAccess()
            .should('exist')
            .should('include.text', Localizer.welcomePageSetUpCompanyAccessButton);

        pageData().welcome.buttons.continueAsPrivate()
            .should('exist')
            .should('include.text', Localizer.welcomePageContinueAsPrivateUser);

        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagCompanyTranslations);
        cy.reload();
        pageData().common.waitTopNavRender();
    });

    it('Open welcome page and click continue as private', () => {
        onRedirect(() => cy.get('#continue_as_private_button').click());
        cy.url().should('not.include', Localizer.pageRoutesApplyCompanyAccount);
    });

    it('Open welcome page and click apply for company account', () => {
        onRedirect(() => cy.get('#apply_for_company_access_button').click());
        cy.url().should('include', Localizer.pageRoutesApplyCompanyAccount);
    });
});