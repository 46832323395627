import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Desktop - Anonymous catalog product tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);

        interceptCommonRequests();

    });

    it('Open product page and verify hidden daily price and monthly price offer', () => {
        pageData().rent.visitWithIntercept('SubCategoryOfSubCategoryWithKeys')

        cy.assertBreadCrumbLength(5);

        const productId: string = 'b19678a6-caeb-4997-9507-f17dbbc62fdf';

        // Assert only offer visible
        pageData().rent.product.monthlyPrice(productId)
            .should('contain', Localizer.genericOffer);
    });

    it('Open product page and navigate back from breadcrumbs', () => {

        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.assertBreadCrumbLength(4);

        executeWithIntercept(() => cy.clickBreadCrumb(2),
            [pageData().rent.routes.categoriesData as RouteData]);

        cy.assertBreadCrumbLength(3);

        onRedirect(() => cy.clickBreadCrumb(0));

        //Assert frontpage visible
        pageData().frontPage.widgets.rent();

    });

    it('Open product page and navigate back from breadcrumbs (skip parent)', () => {

        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.assertBreadCrumbLength(4);

        executeWithIntercept(() => cy.clickBreadCrumb(1),
            [pageData().rent.routes.categoriesData as RouteData]);

        cy.assertBreadCrumbLength(2);

        onRedirect(() => cy.clickBreadCrumb(0));

        //Assert frontpage visible
        pageData().frontPage.widgets.rent();
    });

    it('Open product page and add to basket', () => {
        cy.intercept(
            {
                method: 'POST',
                path: '/api/ShoppingCart/SaveProductToCart',
            },
            {fixture: 'addToCartResponse.json'}
        );
        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.assertBreadCrumbLength(4);

        cy.get('#addToCart').click();

        cy.get('#productConfirmationModal', {timeout: 10000}).should('be.visible');

        cy.get('#productConfirmationModal').find('[data-dismiss]').first().click({force: true});

        cy.assertProductInfoModal('83a700be-2e3b-45cc-ac8a-570a551760d4');
        assertVatPrices();

        for (let n = 0; n < 5; n++) {
            cy.get('#product_83a700be-2e3b-45cc-ac8a-570a551760d4').find('.fa-plus').click();
        }
        cy.get('#addToCart').trigger('click');

        //TODO
        // cy.get('#productConfirmationModal', { timeout: 10000 }).should('be.visible');
        //
        // cy.get('#product-modal-go-to-cart');
        // cy.contains('6');
        //
        // cy.get('#product-modal-continue-shopping').click();

    });

    it('Open product page and verify prices', () => {

        cy.intercept(
            {
                method: 'POST',
                path: '/api/Product/ProductData'
            },
            {fixture: 'product.json'}
        );

        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.assertBreadCrumbLength(4);

        cy.assertProductInfoModal('83a700be-2e3b-45cc-ac8a-570a551760d4');
        assertVatPrices();

        pageData().rent.productLink('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .click();

        assertVatPrices(true);

    });

    it('Open product page and verify minimal rental days', () => {

        pageData().rent.visitWithIntercept('SubCategoryOfSubCategoryWithKeys')

        const productId: string = 'c834ceea-2764-47d5-ac5d-b5e7777f8c22';

        pageData().rent.product.monthlyPrice(productId)
            .should('contain', Localizer.genericOffer);

        pageData().rent.product.minimumRentalDays(productId)
            .should('contain', Localizer.productMinimumRentalDays);
    });

    
    it('Open favorites page, redirect to the page not found', () => {
        onRedirect(() =>
            executeWithIntercept(() =>
                    pageData().rent.visitInternal('favorites'),
                [
                    pageData().rent.routes.categoriesData as RouteData,
                ]
            ));
        // anonymous user should be redirected to the page not found
        cy.url().should('include', 'NotFound');
    });

    function interceptCommonRequests() {
        cy.intercept('POST', '/api/Product/ProductDepotAvailability', {statusCode: 200, body: true});
    }

    function assertVatPrices(detailsPage?: boolean) {

        if (detailsPage) {
            //Assert vat 0 prices
            cy.get('#prices-container')
                .invoke('text')
                .then((text: string) => {
                    if (text.includes(Cypress.env("product2_daily_price_vat_0"))
                        || text.includes(Cypress.env("product2_daily_price_vat_0").replace('.', ','))) {
                    }
                    else {
                        throw new Error("Cannot find correct vat-0 dailyPrice " + Cypress.env("product2_daily_price_vat_0"));
                    }
                });

            cy.get('#vat_price').click();

            //Assert vat 24 prices
            cy.get('#prices-container').invoke('text').then((text: string) => {
                if (text.includes(Cypress.env("product2_daily_price_vat_24"))
                    || text.includes(Cypress.env("product2_daily_price_vat_24").replace('.', ','))) {
                }
                else {
                    throw new Error("Cannot find correct price daily 24");
                }
            });
        }
        else {
            const productId: string = '83a700be-2e3b-45cc-ac8a-570a551760d4';
            //Assert vat 0 prices
            pageData().rent.product.dailyPrice(productId)
                .should('contain.text', Cypress.env("product2_daily_price_vat_0"));
            pageData().rent.product.monthlyPrice(productId)
                .should('contain.text', Cypress.env("product2_monthly_price_vat_0"));

            pageData().rent.product.vat24Link(productId).click({force: true});

            //Assert vat 24 prices
            pageData().rent.product.dailyPrice(productId).should('contain.text', Cypress.env("product2_daily_price_vat_24"));
            pageData().rent.product.monthlyPrice(productId).should('contain.text', Cypress.env("product2_monthly_price_vat_24"));

            pageData().rent.product.vat0Link(productId).click({force: true});

            //Assert vat 0 prices
            pageData().rent.product.dailyPrice(productId)
                .should('contain.text', Cypress.env("product2_daily_price_vat_0"));
            pageData().rent.product.monthlyPrice(productId)
                .should('contain.text', Cypress.env("product2_monthly_price_vat_0"));
        }
    }
});