import {ch} from "@renta-apps/athenaeum-react-common";

export default class RentaEasyConstants {

    // noinspection JSUnusedLocalSymbols - This class is "static", and should not be instantiated.
    private constructor() {
    }

    public static readonly applicationName: string = "RentaEasy";

    public static readonly bootstrapBreakdownWidth: number = 992;

    public static readonly favoritePageId = "favorites";

    public static readonly rentaFuturePageId = "renta-future";

    // Use legacy name for cookie so we don't ask for consent twice for those who have already accepted
    public static readonly consentCookieName = ".AspNet.Consent";

    // 1 MB
    public static readonly maxDepotUploadSizeinBytes: number = 1 * 1024 * 1024;

    // 4 MB
    public static readonly maxImageUploadSizeinBytes: number = 4 * 1024 * 1024;

    // 10 MB
    public static readonly maxFileUploadSizeInBytes: number = 10 * 1024 * 1024;

    // 15 MB
    public static readonly imageMaxSize: number = 15 * 1024 * 1024;

    // 51 MB
    public static readonly maxMachineDocumentUploadSizeinBytes: number = 51 * 1024 * 1024;

    public static readonly attachedProductsMaxAmount: number = 100;

    public static readonly depotFileTypes: string[] = ["image/jpeg"];

    public static readonly imageFileTypes: string[] = ["image/gif", "image/jpeg", "image/png"];

    public static readonly productModelDocumentTypes: string[] = ["application/pdf"];

    public static readonly emptyGuid: string = "00000000-0000-0000-0000-000000000000";

    public static readonly cookiesSelectedConstructionSiteId: string = "SelectedConstructionSiteId";

    public static readonly cookiesSelectedContractId: string = "SelectedCompanyId";

    public static readonly cookiesSignicatNameIdentifier: string = "SignicatNameIdentifier";

    public static readonly constructionSiteUser: string = "ConstructionSiteUser";

    public static readonly constructionSiteMainUser: string = "ConstructionSiteMainUser";

    public static readonly organizationMainUser: string = "OrganizationMainUser";

    public static readonly organizationUser: string = "OrganizationUser";

    public static readonly adminUser: string = "Admin";

    public static readonly officeUser: string = "OfficeUser";

    public static readonly privateUser: string = "Yksityinen";

    public static readonly shoppingCartCommentMaxLength: number = 512;

    public static readonly rentalDayStartHoursInMillis: number = 1000 * 60 * 60 * 7;

    public static readonly rentalDayEndHoursInMillis: number = 1000 * 60 * 60 * 20;

    public static readonly customerRoleNames: string[] = [
        RentaEasyConstants.constructionSiteUser,
        RentaEasyConstants.constructionSiteMainUser,
        RentaEasyConstants.organizationUser,
        RentaEasyConstants.organizationMainUser
    ];

    // Feature flags
    // when introducing a new flag remember to add it to the list of flags in the InMemoryFeatureFlagService class
    public static featureFlagAlarmsDeviceStart: string = "ALARMS_DEVICE_START";

    public static featureFlagAlarms: string = "ALARMS";

    public static featureFlagPlus: string = "PLUS";

    public static featureFlagEnvironmentControl: string = "ENVIRONMENT_CONTROL";

    public static featureFlagFleetMonitoring: string = "FLEET_MONITORING";

    public static featureFlagCustomServices: string = "CUSTOM_SERVICES";

    public static featureFlagSsoLogin: string = "SSO_LOGIN";

    public static featureFlagSsoTestMode: string = "SSO_TEST_MODE";

    public static featureFlagPricingTool: string = "PRICING_TOOL";

    public static featureFlagPlansFrontPageLink: string = "PLAN_LINK";

    public static featureFlagCo2TransportEnabled: string = "CO2_TRANSPORT_ENABLED";

    public static featureFlagCo2Enabled: string = "CO2ENABLED";

    public static featureFlagFeatureCo2RequiresPlus: string = "FEATURE_CO2_REQUIRES_PLUS";

    public static featureFlagMicrosoftLogin: string = "MICROSOFT_LOGIN";

    public static featureFlagGoogleLogin: string = "GOOGLE_LOGIN";

    public static featureFlagSalesToolsFrontPageLink: string = "SALES_TOOLS_FRONT_PAGE_LINK";

    public static featureFlagProjectPrices: string = "PROJECT_PRICES";

    public static featureFlagNotifications: string = "NOTIFICATIONS";

    public static featureFlagDeviceDocuments: string = "DEVICE_DOCUMENTS";

    public static featureFlagRentaFutureCategory: string = "RENTA_FUTURE_CATEGORY";

    public static featureFlagDevicePowerLevelNotifications: string = "DEVICE_POWER_LEVEL_NOTIFICATIONS";

    public static featureFlagProjectPricesForEndUsers: string = "PROJECT_PRICES_FOR_END_USERS";

    public static featureFlagPriceForAuthenticatedUsersOnly: string = "PRICE_FOR_AUTHENTICATED_USERS_ONLY";

    public static featureFlagPriceIsInformative: string = "PRICE_IS_INFORMATIVE";

    public static featureFlagPriceNetOnly: string = "PRICE_NET_ONLY";

    public static featureFlagSalesItems: string = "SALES_ITEMS";

    public static featureFlagGlobalSearch: string = "GLOBAL_SEARCH";

    public static featureFlagOrganizationRolesDirectAssignment: string = "ORGANIZATION_ROLES_DIRECT_ASSIGNMENT";

    public static featureFlagShoppingCartCompanyAccessRequired: string = "SHOPPING_CART_COMPANY_ACCESS_REQUIRED";

    public static featureFlagVatIdForUsers: string = "VAT_ID_FOR_USERS";

    public static featureFlagCompanyTranslations: string = "COMPANY_TRANSLATIONS";

    public static featureFlagCookiebot: string = "COOKIEBOT";

    public static featureFlagDeviceDetails: string = "DEVICE_DETAILS";

    public static featureFlagDepotAdditionalIdentifiers: string = "DEPOT_ADDITIONAL_IDENTIFIERS";

    public static featureFlagRentalReturnRequest: string = "RENTAL_RETURN_REQUEST";

    public static featureFlagReturnRequestsList: string = "RETURN_REQUESTS_LIST";

    public static featureFlagNewConstructionSitePage: string = "NEW_CONSTRUCTION_SITE_PAGE";

    public static categoryNameFontSizeThreshold: number = 30;

    public static get defaultLatitude(): number {
        switch (ch.country) {
            case "fi":
                return 60.192059;
            case "dk":
            case "da":
                return 55.613896;
            case "no":
            case "nb":
            case "nor":
                return 60.450172;
            case "pl":
                return 52.1029824;
            case "se":
            case "sv":
                return 59.0041252;
            default:
                return 60.192059;
        }
    };

    public static get defaultLongitude(): number {
        switch (ch.country) {
            case "fi":
                return 24.945831;
            case "dk":
            case "da":
                return 9.540399;
            case "no":
            case "nb":
            case "nor":
                return 8.2435016;
            case "pl":
                return 19.1338044;
            case "se":
            case "sv":
                return 14.5168652;
            default:
                return 24.945831;
        }
    };

    // Current environments country code in upper case
    public static get countryCodeUpper(): string {
        switch (true) {
            case ch.isFinland:
                return "FI";
            case ch.isDenmark:
                return "DK";
            case ch.isNorway:
                return "NO";
            case ch.isPoland:
                return "PL";
            case ch.isSweden:
                return "SE";
            default:
                throw new Error("Invalid country");
        }
    }
}