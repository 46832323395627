import React from "react";
import Localizer from "@/localization/Localizer";
import styles from "./EasyPlusInfoPanel.module.scss";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";

interface EasyPlusInfoPanelProps {
    easyPlusEnabled: boolean;
}

const EasyPlusInfoPanel: React.FC<EasyPlusInfoPanelProps> = ({ easyPlusEnabled }) => {
    return (
        <div className={styles.newEasyPlusSubscription}>
            <div className={styles.easyPlusInfo}>
                {!easyPlusEnabled && (
                    <div className={styles.easyPlusInfoTitle} data-cy="easyPlusInfoTitle">
                        {Localizer.easyPlusInfoPanelTitle}
                    </div>
                )}
                <div className={styles.easyPlusInfoContent}>
                    <div>
                        <div className={styles.boldText} data-cy="easyPlusInfoPanelLine1">
                            {Localizer.easyPlusInfoPanelLine1}
                        </div>
                        <div data-cy="easyPlusInfoPanelLine2">{Localizer.easyPlusInfoPanelLine2}</div>
                    </div>
                    <div>
                        <div data-cy="easyPlusInfoPanelLine3">{Localizer.easyPlusInfoPanelLine3}</div>
                        <EasyPlusLink>{Localizer.easyPlusInfoPanelReadMore}</EasyPlusLink>
                    </div>
                    {!easyPlusEnabled && (
                        <div className={styles.boldText}>{Localizer.easyPlusInfoPanelActivate}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EasyPlusInfoPanel;