import {Dictionary} from "typescript-collections";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {ch, IBasePage} from "@renta-apps/athenaeum-react-common";

export enum DataStorageType {
    Session,

    Page,

    Route
}

class UserInteractionDataStorage {

    private readonly _data: Dictionary<string, any> = new Dictionary<string, any>();
    private _initialized: boolean = false;
    private _key: string | null = null;

    private get key(): string {
        return this._key || (this._key = `$${RentaEasyConstants.applicationName}.${ch.getSessionId()}.userInteractionDataStorage`);
    }

    private get data(): Dictionary<string, any> {
        this.initialize();
        return this._data;
    }

    private initialize(): void {
        if (!this._initialized) {
            this._initialized = true;

            if (window.localStorage) {
                const json: string | null = window.localStorage.getItem(this.key);
                if (json) {
                    const data = JSON.parse(json);
                    const destination = this._data as any;
                    destination.table = data.table;
                    destination.nElements = data.nElements;
                }
            } else {
                console.error('LocalStorage is not available during initialization.');
            }
        }
    }

    private save(): void {
        if (window.localStorage) {
            const json: string = JSON.stringify(this.data);
            window.localStorage.setItem(this.key, json);
        } else {
            console.error('LocalStorage is not available during save.');
        }
    }

    private getKey(type: DataStorageType, id: string): string {
        const page: IBasePage | null = ch.findPage();
        return ((page == null) || (type === DataStorageType.Session))
            ? id
            : (type === DataStorageType.Route)
                ? `${page.routeName}:${page.routeIndex}:${page.routeId}:${id}`
                : `${page.routeName}:${id}`;
    }

    public set(id: string, value: any, type: DataStorageType = DataStorageType.Page): void {
        const key: string = this.getKey(type, id);
        this.data.setValue(key, value);
        this.save();
    }

    public get(id: string, defaultValue: any | null = null, type: DataStorageType = DataStorageType.Page): any | null {
        const key: string = this.getKey(type, id);
        return this.data.getValue(key) || defaultValue;
    }

    public async onAuthorize(): Promise<void> {
        this._initialized = false;
        this._key = null;
    }
}

export default new UserInteractionDataStorage();