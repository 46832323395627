import React from "react";
import {AlertModel, AlertType, ApplicationContext, BasePage, BasePageParameters, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import Localizer from "@/localization/Localizer";
import {Alert} from "@renta-apps/athenaeum-react-components";
import RentaEasyController from "@/pages/RentaEasyController";
import PageDefinitions from "@/providers/PageDefinitions";
import {CustomHtmlHeaders, HtmlHeadHelper} from "@/helpers/HtmlHeadHelper";

export default abstract class AnonymousPage<TParams extends BasePageParameters = {}, TState = {}>
    extends BasePage<TParams, TState, ApplicationContext> {

    // Properties

    /**
     * Title of the page. Used in {@link getTitle}, and can be used in the main header as well.
     */
    protected abstract get title(): string;

    /**
     * {@link BasePage.parameters} with strong typing.
     */
    protected get typedParameters(): TParams | null {
        return this.parameters as TParams;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get isAdmin(): boolean {
        return this.userContext.isAdmin;
    }

    public get isAdminWithAdminRole(): boolean {
        return this.userContext.isAdminWithAdminRole;
    }

    public get automaticUrlChange(): boolean {
        return true;
    }

    // Methods

    /**
     * Returns Alert with title to inform about showing selected customer prices.
     * {@link callback} Optional parameter to be used as callback after alert calls backend to clear ordering contract.
     * */
    protected renderContractPricesAlert(callback?: Function): React.ReactNode | null {

        if (!this.userContext.temporaryContractIdForPrices && !this.userContext.temporaryConstructionSiteIdForPrices) {
            return null;
        }

        const alert = new AlertModel();

        alert.message = (this.userContext.temporaryContractNameForPrices)
            ? `${Localizer.categoriesShowCustomers} ${this.userContext.temporaryContractNameForPrices} ${Localizer.genericPrices}`
            : `${Localizer.genericConstructionSite} ${this.userContext.temporaryConstructionSiteNameForPrices} ${Localizer.genericPrices}`;

        alert.alertType = AlertType.Warning;
        alert.dismissible = true;
        alert.flyout = false;
        alert.autoClose = false;

        if (callback) {
            return (
                <Alert model={alert}
                       onClose={async () => await RentaEasyController.clearOperatingForContract(this).then(await callback())}
                />
            );
        }

        return (
            <Alert model={alert}
                   onClose={async () => await RentaEasyController.clearOperatingForContract(this)}
            />
        );
    }

    /**
     * Redirect to the front page.
     */
    protected async toFrontPageAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route(), true, true);
    }

    public isSpinning(): boolean {
        return super.isSpinning() || ch.getLayout().isSpinning();
    }

    /**
     * {@link title} with "Renta Easy - " added to the start. Set to the document title on page load.
     */
    public getTitle(): string {
        return `Renta Easy - ${this.title}`;
    }

    protected renderHead(title: string, keywords: string = "", description: string = "", customHeaders: CustomHtmlHeaders = {}): React.ReactNode {
        return HtmlHeadHelper.renderHead(title, keywords, description, customHeaders);
    }
}