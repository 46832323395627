import React from "react";
import { BasePageParameters } from "@renta-apps/athenaeum-react-common";
import { PageContainer, PageHeader, PageRow } from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import Localizer from "../../localization/Localizer";

import styles from "./Offline.module.scss";

export interface IOfflineParameters extends BasePageParameters {
}

export default class Offline extends AnonymousPage<IOfflineParameters> {

    protected get title(): string {
        return Localizer.offlinePageTitle;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer>
                <PageHeader title={this.title} />

                <PageRow className={styles.message}>
                    {Localizer.offlinePageMessage}
                </PageRow>
            </PageContainer>
        );
    }
}
