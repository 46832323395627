import React, { useState } from "react";
import EasyPlusConfirmationModal from "@/components/EasyPlus/EasyPlusConfirmationModal/EasyPlusConfirmationModal";
import { activateEasyPlusForUser, readEasyPlusTerms } from "@/services/EasyPlusService";

interface IEasyPlusProps {
	contractId: string;
	userId: string;
	onConfirmationModalClose(): Promise<void>;
}

const EasyPlus: React.FC<IEasyPlusProps> = (props: IEasyPlusProps) => {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(true);
	const [easyPlusTermsRead, setEasyPlusTermsRead] = useState(false);

	const handleTermsRead = async () => {
		await readEasyPlusTerms(props.contractId)
		setEasyPlusTermsRead(true);
	};

	const onConfirmationModalClose = async (confirmed: boolean): Promise<void> => {
		setIsConfirmationModalOpen(false);
		if (confirmed) {
			await activateEasyPlusForUser(props.userId, props.contractId);
			await props.onConfirmationModalClose();
		}
	};

	return (
		<EasyPlusConfirmationModal isOpen={isConfirmationModalOpen}
			onClose={onConfirmationModalClose}
			onTermsRead={handleTermsRead}
			userFullName={""}
			termsRead={easyPlusTermsRead}
		/>
	);
};

export default EasyPlus;