import React, {FC} from "react";
import {Checkbox, Dropdown, DropdownOrderBy, InlineType, JustifyContent, Switch, TextInput, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";
import ConstructionSitesListToolbarModel from "./ConstructionSitesListToolbarModel";
import styles from "./ConstructionSitesListToolbar.module.scss";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";

interface IConstructionSitesListToolbarProps {
    model: ConstructionSitesListToolbarModel;
    contracts: OrganizationContractModel[];
    onChange?(model: ConstructionSitesListToolbarModel, clear: boolean): Promise<void>;
}

export const GET_USER_DATA_STORAGE_KEY = (contractId: string | null) => `ConstructionSitesListToolbar-${contractId}`;

const ConstructionSitesListToolbar: FC<IConstructionSitesListToolbarProps> = (props: IConstructionSitesListToolbarProps) => {

    const [model, setModel] = React.useState<ConstructionSitesListToolbarModel>(props.model);
    
    const updateState = async (model: ConstructionSitesListToolbarModel) => {
        setModel(model);
        
        // [TODO] 17.06.2024: this part was added to remove deprecated contracts property from objects stored in local storage
        // next time somebody will look at this code, please consider removing "delete model.contracts" part as it is probably not needed anymore
        if ("contracts" in model) {
            delete model.contracts;
        }
        
        UserInteractionDataStorage.set(GET_USER_DATA_STORAGE_KEY(model.contractId), model, DataStorageType.Page);
        
        if (props.onChange) {
            await props.onChange(model, false);
        }
    }

    return (
        <ToolbarContainer className={styles.toolbarContainer}>
            <ToolbarRow justify={JustifyContent.Start} className={`${styles.toolBarRow} ${styles.toolbarVisibilityButtons}`}>
                <div className={styles.toolbarItemContainer}>
                    <TextInput inline
                               label={Localizer.constructionSitesPageFilterLabel}
                               width={"200px"}
                               value={model.keyword}
                               onChange={async (_sender, value) => 
                                   await updateState({...model, keyword: value})
                               }
                    />
                    <InlineTooltip className={styles.searchInfo}
                                   text={Localizer.constructionSitesPageFilterHelp}
                    />
                </div>

                <Switch inline
                        id={"showActiveSitesSwitch"}
                        label={Localizer.contractDetailsModelCurrentConstructionsites}
                        inlineType={InlineType.Right}
                        value={model.showAllSites}
                        onChange={async (_sender, checked) => 
                            await updateState({...model, showAllSites: checked})
                        }
                />
                <Checkbox inline
                          inlineType={InlineType.Right}
                          value={model.showHiddenSites}
                          onChange={async (_sender, checked) => 
                              await updateState({...model, showHiddenSites: checked})
                          }
                          label={Localizer.contractDetailsShowHidden}
                />
            </ToolbarRow>
            
            {props.contracts?.length > 0 && (
                <ToolbarRow justify={JustifyContent.Start} className={`${styles.toolBarRow} ${styles.dropdownRow}`}>
                    <Dropdown className={styles.companyDropdown}
                              orderBy={DropdownOrderBy.None}
                              label={Localizer.shoppingCartPageSelectCompany}
                              selectedItem={model.constructionSiteOwnerId}
                              items={props.contracts}
                              onChange={async (_sender, item) => 
                                  await updateState({...model, constructionSiteOwnerId: item?.contractId ?? null})
                              }
                    />

                </ToolbarRow>
            )}
        </ToolbarContainer>
    );
}

export default ConstructionSitesListToolbar;