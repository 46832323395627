import Localizer from "../../../localization/Localizer";
import {InputType} from "@/models/Enums";
import {RouteData, executeWithIntercept, pageData, onRedirect} from "@/helpers/CypressHelper";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";

describe('Desktop - Service editor tests', () => {
    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("juhani.sihvonen@weare.fi");
        });
        onRedirect(() => cy.visit(Localizer.pageRoutesServices));
    });

    //  Variables
    const headline = `CypressTestHeadline-${Date.now()}`;

    //  Helpers
    const addDropdown = () => cy.get("#addDropdownInput").click();
    const addCheckboxInput = () => cy.get("#addCheckboxInput").click();
    const addTextInput = () => cy.get("#addTextInput").click();

    const updatePageName = (name: string) => cy.get("div#groupHeaderTextInput").eq(0).type(name);
    const clickEditButtonOnAddedInput = (inputIndex: number) => pageData().serviceRequest.getEditInputButtons(inputIndex).eq(0).trigger("click");
    const clickDeleteButtonOnAddedInput = (inputIndex: number) => pageData().serviceRequest.getEditInputButtons(inputIndex).eq(1).click();

    const typeSelectedInputLabel = (label: string) => cy.get("#editInputLabel").find("input").type(label).blur();
    const clickAddNewSection = (inputIndex: number) => cy.get(`[data-cy=InputSettingsContainer] [data-cy=AddNewSection]`).children().eq(0).trigger('click');
    const cleanAndTypeOptionValue = (index: number, text: string) => {
        cy.get(`#valueEditor-${index}`).find("input").clear();
        cy.get(`#valueEditor-${index}`).find("input").type(text).blur();
    };
    const clickOptionDelete = (optionIndex: number) => cy.get(`#valueDeleteButton-${optionIndex}`).click();

    const clickAddCondition = () => cy.get("#conditionAddButton").click();
    const changeConditionDropdownSelectedType = (conditionIndex: number, optionIndex: number) => {
        CypressDropdownHelper.openAndSelectDropdownItemByIndex(`#conditionDropdown-${conditionIndex}`, optionIndex);
    };
    const changeConditionDropdownSelectedOption = (conditionIndex: number, optionIndex: number) => {
        CypressDropdownHelper.openAndSelectDropdownItemByIndex(`#displayConditionsSelector-${conditionIndex}`, optionIndex);
    };
    const clickConditionCheckbox = (conditionIndex: number) => cy.get(`#displayConditionsSelector-${conditionIndex}`)
        .find(".athenaeum-checkbox-checkbox").click();

    const clickConditionDelete = (conditionIndex: number) => cy.get(`#conditionDeleteButton-${conditionIndex}`).click();

    const validateInputLabel = (inputIndex: number, label: string) => pageData().serviceRequest.getElementByIndex(inputIndex).contains(label);

    const validateDropdownOptions = (conditionIndex: number, options: string[]) => {
        CypressDropdownHelper.validateDropdownOptions(`#conditionDropdown-${conditionIndex}`, options);
    };

    it('Opens service editor and creates a new one with all elements', () => {
        createNewServiceDefinition("CypressTestHeadline");

        cy.get("#addTextInput").click();
        cy.get("#addTextAreaInput").click();
        cy.get("#addDateInput").click();
        cy.get("#addDateRangeInput").click();
        cy.get("#addNumberInput").click();
        cy.get("#addDropdownInput").click();
        cy.get("#addCheckboxInput").click();
        cy.get("#addLocationInput").click();
        cy.get("#addFileInput").click();
        cy.get("div#groupHeaderTextInput").eq(0).type("Page name 1");
        cy.get("#addPageSeparatorInput").click();
        cy.get("#addTextInput").click();

        cy.get("div#groupHeaderTextInput").eq(1).type("Page name 2");

        cy.intercept("POST", "/api/Services/CreateServiceRequestDefinition", (req) => {
            expect(req.body.name).to.equal("CypressTestHeadline");
            expect(req.body.icon).to.equal("pen");
            expect(req.body.receivers).to.equal("email@email.com");
            expect(req.body.isVisible).to.equal(false);

            expect(req.body.inputGroups.length).to.equal(2);
            const group1 = req.body.inputGroups[0];
            const group2 = req.body.inputGroups[1];

            expect(group1.groupHeader).to.equal("Page name 1");
            expect(group1.inputs.length).to.equal(9);
            expect(group2.inputs.length).to.equal(1);

            expect(group1.inputs[0].inputType).to.equal(InputType.Text);
            expect(group1.inputs[1].inputType).to.equal(InputType.TextArea);
            expect(group1.inputs[2].inputType).to.equal(InputType.Date);
            expect(group1.inputs[3].inputType).to.equal(InputType.DateRange);
            expect(group1.inputs[4].inputType).to.equal(InputType.Number);
            expect(group1.inputs[5].inputType).to.equal(InputType.Dropdown);
            expect(group1.inputs[6].inputType).to.equal(InputType.Checkbox);
            expect(group1.inputs[7].inputType).to.equal(InputType.LocationPicker);
            expect(group1.inputs[8].inputType).to.equal(InputType.FileInput);

            expect(group2.inputs[0].inputType).to.equal(InputType.Text);
            expect(group2.groupHeader).to.equal("Page name 2");
        }).as("createDefinition");

        cy.get("#definitionSubmitButton").click();

        cy.wait("@createDefinition").its('response.statusCode').should('equal', 200);
    });

    it('Opens service editor and creates two text input and edits labels', () => {
        //  Starting point
        createNewServiceDefinition(headline);

        // Index 0
        addTextInput();
        //  Edit TextInput
        clickEditButtonOnAddedInput(0);
        //  Update Label
        typeSelectedInputLabel("JustALabel");
        //  Validate Preview Label
        validateInputLabel(0, "JustALabel");

        // Index 1
        addTextInput();
        //  Edit TextInput
        clickEditButtonOnAddedInput(1);
        //  Update Label
        typeSelectedInputLabel("AnotherLabel");
        //  Validate Preview Label
        validateInputLabel(1, "AnotherLabel");

        //  Just to make sure second input didn't break first one.
        //  Validate Preview Label
        validateInputLabel(0, "JustALabel");
    });

    it('Opens service editor and creates two checkboxes and checks if they appear on Condition dropdown', () => {
        //  Starting point
        createNewServiceDefinition(headline);

        // Index 0
        addCheckboxInput();
        //  Edit
        clickEditButtonOnAddedInput(0);
        //  Update Label
        typeSelectedInputLabel("CheckboxOne");

        // Index 1
        addCheckboxInput();
        //  Edit
        clickEditButtonOnAddedInput(1);
        //  Update Label
        typeSelectedInputLabel("CheckboxTwo");

        // Index 2
        addDropdown();
        //  Edit
        clickEditButtonOnAddedInput(2);
        //  Update Label
        typeSelectedInputLabel("DropdownOne");
        //  Validate Preview Label
        validateInputLabel(2, "DropdownOne");
        // First Condition : CheckboxOne
        clickAddCondition();
        validateDropdownOptions(0, ["CheckboxOne", "CheckboxTwo"]);
    });

    it('Opens service editor and creates a new one with conditions', () => {
        //  Starting point
        createNewServiceDefinition(headline);
        // Index 0
        addDropdown();
        // Index 1
        addCheckboxInput();
        // Index 2
        addTextInput();

        updatePageName("Page name 1");

        //  Edit Dropdown
        clickEditButtonOnAddedInput(0);

        typeSelectedInputLabel("Dropdown");

        //  For Dropdown
        cy.wait(100);
        clickAddNewSection(0);
        cy.wait(100);
        clickAddNewSection(0);
        cy.wait(100);

        // option 1
        cleanAndTypeOptionValue(0, "Value 0");
        // option 2
        cleanAndTypeOptionValue(1, "Value 1");

        //  Edit Checkbox
        clickEditButtonOnAddedInput(1);
        typeSelectedInputLabel("Checkbox");

        //  Edit TextInput
        clickEditButtonOnAddedInput(2);
        typeSelectedInputLabel("Label");

        // First Condition : Dropdown
        clickAddCondition();
        changeConditionDropdownSelectedType(0, 1);
        // First option
        changeConditionDropdownSelectedOption(0, 0);

        // Second Condition : Checkbox
        clickAddCondition();
        changeConditionDropdownSelectedType(1, 0);
        clickConditionCheckbox(1);

        // Third Condition : Checkbox
        clickAddCondition();
        changeConditionDropdownSelectedType(2, 0);
        clickConditionDelete(2);

        cy.intercept("POST", "/api/Services/CreateServiceRequestDefinition", (req) => {
            expect(req.body.name).to.equal(headline);
            expect(req.body.icon).to.equal("pen");
            expect(req.body.receivers).to.equal("email@email.com");
            expect(req.body.isVisible).to.equal(false);

            expect(req.body.inputGroups.length).to.equal(1);
            const group1 = req.body.inputGroups[0];
            expect(group1.groupHeader).to.equal("Page name 1");
            expect(group1.inputs.length).to.equal(3);

            const textInput = group1.inputs[2];

            expect(group1.inputs[0].inputType).to.equal(InputType.Dropdown);
            expect(group1.inputs[1].inputType).to.equal(InputType.Checkbox);
            expect(textInput.inputType).to.equal(InputType.Text);

            expect(textInput.displayConditions.length).to.equal(2);
            const dropdownCondition = textInput.displayConditions[0];
            const checkboxCondition = textInput.displayConditions[1];

            expect(dropdownCondition.inputValue).to.equal(null);
            expect(dropdownCondition.inputValueId).to.equal("0");

            expect(checkboxCondition.inputValue).to.equal("true");
            expect(checkboxCondition.inputValueId).to.equal(null);
        }).as("createDefinition");

        cy.get("#definitionSubmitButton").click();

        cy.wait("@createDefinition").then(({response}) => {
            cy.log(response?.body?.id);
            expect(response?.statusCode, 'status code').to.eq(200);
        });
    });

    it('Opens service editor and removes conditions', () => {
        openExistingServiceDefinition(headline);

        //  Edit Dropdown input
        clickEditButtonOnAddedInput(0);
        clickAddNewSection(0);
        //  Deleting option one should delete all depending conditions.
        clickOptionDelete(0);

        //  Add two more inputs and delete
        addDropdown();
        addDropdown();
        clickDeleteButtonOnAddedInput(4);
        clickDeleteButtonOnAddedInput(3);

        cy.intercept("POST", "/api/Services/SaveServiceRequestDefinition", (req) => {
            expect(req.body.inputGroups.length).to.equal(1);
            const group1 = req.body.inputGroups[0];
            expect(group1.inputs.length).to.equal(3);

            const checkboxInput = group1.inputs[1];
            const textInput = group1.inputs[2];
            expect(group1.inputs[0].inputType).to.equal(InputType.Dropdown);
            expect(checkboxInput.inputType).to.equal(InputType.Checkbox);
            expect(textInput.inputType).to.equal(InputType.Text);
            expect(textInput.displayConditions.length).to.equal(1);

            const checkboxCondition = textInput.displayConditions[0];

            expect(checkboxCondition.inputValue).to.equal("true");
            expect(checkboxCondition.inputValueId).to.equal(null);
        }).as("saveDefinition");

        cy.get("#definitionSubmitButton").click();

        cy.wait("@saveDefinition").its('response.statusCode').should('equal', 200);
    });

    it('Opens service editor and toggle visibility field', () => {
        openExistingServiceDefinition(headline);

        // Click the switch toggle to enable visibility.
        cy.get('#definitionVisible .athenaeum-switch-zone').last().click();

        cy.intercept("POST", "/api/Services/SaveServiceRequestDefinition", (req) => {
            expect(req.body.isVisible).to.equal(true);
        }).as("saveDefinition");

        cy.get("#definitionSubmitButton").click();

        cy.wait("@saveDefinition").its('response.statusCode').should('equal', 200);
    });

    it('Opens service editor and delete it', () => {
        openExistingServiceDefinition(headline);

        executeWithIntercept(() => pageData().serviceRequest.deleteButton().click(),
            [pageData().serviceRequest.routes.delete as RouteData]);
    });

    function openExistingServiceDefinition(name: string) {
        cy.get(`[data-test-id=service_${name}]`).children().eq(1).click();
    }

    function createNewServiceDefinition(headline: string) {
        cy.get("#createNewServiceDefinitionButton").click();
        cy.get("#definitionHeadline").type(headline);
        cy.get("#definitionIcon").type("pen");
        cy.get("#definitionsEmails").type("email@email.com");
    }
});