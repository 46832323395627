import React, {useEffect, useMemo, useState} from "react";
import Panel from "@/components/EnvironmentControl/Panel/Panel";
import SensorsGrid from "@/components/EnvironmentControl/SensorsGrid/SensorsGrid";
import Localizer from "@/localization/Localizer";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import styles from "./EnvironmentControlInfoScreen.module.scss";

type WideScreenWithoutFloorPlanProps = {
    devices: EnvironmentDevice[];
    gridsCount: number;
    gridsPerScreen: number;
    sensorsPerGrid: number;
    maintainNumberOfColumns?: boolean;
    onGridPagingChanged: (page: number, gridsPerScreen: number, hideInfo: boolean) => void;
};

const SensorsGridPaging: React.FC<WideScreenWithoutFloorPlanProps> = (props: WideScreenWithoutFloorPlanProps) => {
    const [page, setPage] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);
    const {totalPages, totalColumns} = useMemo(() => {
        const pages = Math.ceil(props.gridsCount / props.gridsPerScreen);

        // for the environments with floor plan, we need to make sure that the number of columns is the same for all pages, otherwise the layout will be broken
        return {
            totalPages: pages,
            totalColumns: (props.maintainNumberOfColumns && pages > 1) ? props.gridsPerScreen * pages : props.gridsCount,
        };
    }, [props.gridsCount, props.gridsPerScreen]);

    useEffect(() => {
        setPage(0);
        props.onGridPagingChanged(page, props.gridsPerScreen, totalPages <= 1);
    }, [props.gridsCount, props.gridsPerScreen]);

    useEffect(() => {
        if (totalPages <= 1) {
            return;
        }

        const interval = setInterval(() => {
            setFadeOut(true);
            setTimeout(() => {
                setFadeOut(false);
                setPage(prev => (prev + 1) % totalPages);
            }, 400);
        }, 30000);

        return () => clearInterval(interval);
    }, [totalPages]);
    
    useEffect(() => {
        if (totalPages <= 1) {
            return;
        }

        props.onGridPagingChanged(page, props.gridsPerScreen, false);
    }, [page, totalPages]);

    return (
        <>
            {props.gridsCount > 0 && [...Array(totalColumns).keys()].skip(page * props.gridsPerScreen).take(props.gridsPerScreen).map(index => (
                <Panel className={`${styles.sensorsContainer} ${fadeOut ? styles.fadeOut : ''}`} key={`panel-${index}`}>
                    <SensorsGrid devices={props.devices}
                                 language={Localizer.language}
                                 transform={sensors => sensors.skip(index * props.sensorsPerGrid).take(props.sensorsPerGrid)}
                    />
                </Panel>
            ))}
        </>
    );
}

export default SensorsGridPaging;