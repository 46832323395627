import { pageData } from "@/helpers/CypressHelper";

describe("Desktop - Admin ProductAvailability tests", () => {

    beforeEach(() => {
        cy.session(["admin_role_session"], cy.loginAndSelectAdminRole, {});

    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    it("Opens ProductDetails with defined availability and verifies the correctness of the availability statuses", () => {

        cy.intercept('POST', 'api/Product/GetProductAvailability', {fixture: 'availability_3_rented_1_available'}).as('data');

        // navigate to the product with defined availability
        cy.visit("/product/ajettava-nivelpuominostin-lavakorkeus-13-15-m-akku");

        // navigate to the Availability tab
        pageData().rent.product.availabity.tab().click();

        // check number of ordered items
        pageData().rent.product.availabity.availabilityBall('Orderable')
            .should("have.text", "3");

        // check number of available items
        pageData().rent.product.availabity.availabilityBall('Available')
            .should("have.text", "1");

    });

    it('Should display carousel arrow buttons when there is more than one image', () => {

        cy.visit("/product/ajettava-nivelpuominostin-lavakorkeus-13-15-m-akku");

        pageData().product.swiper().should('exist');

        pageData().product.navigation().should('have.length', 2);

    });

    it('Should display carousel arrow buttons when there is only a single image', () => {

        cy.visit("/product/nifty-lift");

        pageData().product.swiper().should('exist');

        pageData().product.navigation().should('not.exist');

        cy.reload();
        pageData().common.waitTopNavRender();
    });

});