import React from "react";
import OrdersGrid from "@/components/OrdersGrid/OrdersGrid";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";

export interface IPrivateOrdersParams extends BasePageParameters {
}

interface IPrivateOrdersState {
}

export default class PrivateOrders extends AuthorizedPage<IPrivateOrdersParams, IPrivateOrdersState> {

    public state: IPrivateOrdersState = {};

    protected get title(): string {
        return Localizer.ordersPageOrders;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title}/>

                <OrdersGrid privateOrders/>
            </PageContainer>
        );
    }

}