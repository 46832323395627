import {ApiProvider} from "@renta-apps/athenaeum-react-common";

import OrderDetails from "@/models/server/OrderDetails";
import AskForQuoteRequest from "@/models/server/Requests/AskForQuoteRequest";
import {IResponse} from "@/pages/ShoppingCart/ShoppingCart";
import InviteUserPostRequest, {IConstructionSiteAccess, InvitedUser} from "@/models/server/InviteUserPostRequest";

const shoppingCartService = {
    
    sendOrderToEmailAsync: async (order: OrderDetails): Promise<void> => {
        return ApiProvider.postAsync(`/api/ShoppingCart/SendOrderToEmail`, order);
    },

    askForQuoteAsync: async (order: OrderDetails, contractId: string | null): Promise<void> => {
        const request: AskForQuoteRequest = {
            order, 
            contractId,
        };
        
        return ApiProvider.postAsync(`/api/ShoppingCart/SendQuoteRequest`, request);
    },

    sendOrderAsync: async (order: OrderDetails): Promise<string | null> => {
        return ApiProvider.postAsync<string | null>(`/api/ShoppingCart/SendOrder`, order);
    },

    inviteUsersAsync: async (users: InvitedUser[], contractId: string, accessToAllConstructionSites: boolean, companyMainUserRole: boolean, constructionSiteAccesses: IConstructionSiteAccess[]): Promise<IResponse> => {
        const inviteUserRequest: InviteUserPostRequest = {
            usersList: users,
            contractId: contractId,
            accessToAllConstructionSites: accessToAllConstructionSites,
            companyMainUserRole: companyMainUserRole,
            constructionSiteAccesses: constructionSiteAccesses,
        }

        return ApiProvider.postAsync<IResponse>(`/api/Users/InviteUser`, inviteUserRequest);
    },
}

export default shoppingCartService;
