import {ActionType, BaseComponent, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import React from "react";
import {Button, ButtonType, CellAction, CellModel, Checkbox, ColumnDefinition, ColumnType, Form, Grid, Icon, TextInput} from "@renta-apps/athenaeum-react-components";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import styles from "./ConstructionSiteGrid.module.scss";
import {IBaseGridProps, IBaseGridState} from "@/models/BaseGridParameters";

interface IConstructionSitesGridProps extends IBaseGridProps {
    showFavorites?: boolean;
    renderFor: PageRoute;

    getConstructionSites(pageNumber: number,
                         pageSize: number,
                         sortColumnName: string | null,
                         sortDirection: SortDirection | null,
                         searchTerm: string | null,
                         showOnlyFavorites: boolean | false): Promise<IPagedList<ConstructionSiteModel>>;
}

interface IConstructionSitesState extends IBaseGridState {
    showFavorites: boolean | false;
}

export default class ConstructionSitesGrid extends BaseComponent<IConstructionSitesGridProps, IConstructionSitesState> {
    private readonly defaultPageSize: number = 25;

    public state: IConstructionSitesState = {
        showFavorites: this.props.showFavorites ?? false,
        keyword: this.props.searchKeyword ?? null,
        pageNumber: this.props.pageNumber === undefined ? 1 : Number(this.props.pageNumber),
        pageSize: this.props.pageSize === undefined ? this.defaultPageSize : Number(this.props.pageSize),
        sortColumnName: this.props.sortColumnName ?? null,
        sortDirection: this.props.sortDirection ?? null,
    };
    private readonly _constructionSitesGrid: React.RefObject<Grid<ConstructionSiteModel>> = React.createRef();

    private readonly _constructionSiteColumns: ColumnDefinition[] = [
        {
            minWidth: 50,
            maxWidth: 50,
            actions: [
                {
                    name: "favorite",
                    type: ActionType.Muted,
                    right: true,
                    render: (cell: CellModel<ConstructionSiteModel>, action: CellAction<ConstructionSiteModel>) => this.renderStarIcon(cell, action),
                }
            ]
        },
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: nameof<ConstructionSiteModel>(d => d.displayName),
            sorting: true,
            minWidth: 90,
            wordBreak: true,
            transform: (cell: CellModel<ConstructionSiteModel>) => (cell.model.displayName && cell.model.displayName.length > 0) ? cell.model.displayName : Localizer.genericNameUnknown,
            route: (cell: CellModel<ConstructionSiteModel>) => PageDefinitions.constructionSite.route({params: {id: cell.model.id}})
        },
        {
            header: Localizer.rolesGroupCompany,
            accessor: nameof<ConstructionSiteModel>(d => d.ownerName),
            sorting: true,
            minWidth: 200,
            wordBreak: true,
            route: (cell: CellModel<ConstructionSiteModel>) => PageDefinitions.contractDetails.route({params: {id: cell.model.ownerId}})
        },
        {
            header: Localizer.companyDetailsCustomerId,
            accessor: nameof<ConstructionSiteModel>(d => d.customerNumber),
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.companyDetailsCompanyExtraReference,
            accessor: nameof<ConstructionSiteModel>(d => d.ownerAdditionalName),
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.constructionSiteOpened,
            accessor: nameof<ConstructionSiteModel>(d => d.opened),
            sorting: true,
            isDefaultSorting: true,
            minWidth: 90,
            type: ColumnType.Date,
            format: "D",
            init: (cell: CellModel<ConstructionSiteModel>) => this.initDateCell(cell)
        },
    ];
    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this._constructionSitesGrid.current) {
            this._constructionSitesGrid.current.model.pageNumber = this.state.pageNumber;
            this._constructionSitesGrid.current.model.pageSize = this.state.pageSize;
        }
    }

    private initDateCell(cell: CellModel<ConstructionSiteModel>): void {
        cell.readonly = true;
    }

    private async changeUrl(
        search: string | null = null,
        showFavorites: boolean | null = null,
        page: number | null = null,
        size: number | null = null,
        sortColumnName: string | null = null,
        sortDirection: SortDirection | null = null,
    ): Promise<void> {
        const route: PageRoute = this.props.renderFor;
        const keyword = search ?? this.state.keyword;
        const pageNumber = page ?? this.state.pageNumber;
        const pageSize = size ?? this.state.pageSize;

        const favorites = showFavorites ?? this.state.showFavorites;
        const sortColumn = sortColumnName ?? this.state.sortColumnName;
        const sortDir = sortDirection ?? this.state.sortDirection;

        if (pageNumber === this.state.pageNumber && keyword === this.state.keyword && favorites === this.state.showFavorites
            && sortColumn === this.state.sortColumnName && sortDir === this.state.sortDirection && pageSize === this.state.pageSize) {
            return;
        }

        if (!keyword && pageNumber === 1 && !favorites && sortDir === SortDirection.Desc && sortColumn === 'opened') {
            route.parameters = null;
        } else {
            route.parameters = {
                ...(keyword ? {keyword} : {}),
                ...(pageNumber === 1 ? {} : {pageNumber}),
                ...(pageSize === this.defaultPageSize ? {} : {pageSize}),
                ...(favorites ? {favorites} : {}),
                ...(!sortColumn || sortColumn === 'opened' ? {} : {sortColumn}),
                ...(sortDir === null || sortDir === SortDirection.Desc ? {} : {sortDir}),
            };
        }

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(route);
        this.setState({keyword, pageNumber, pageSize, showFavorites: favorites, sortColumnName, sortDirection: sortDir});
    }

    private async filterConstructionSitesAsync(): Promise<void> {
        if (this._constructionSitesGrid.current) {
            this._constructionSitesGrid.current.model.pageNumber = 1;
            this._constructionSitesGrid.current.model.pageSize = this.defaultPageSize;
            await this._constructionSitesGrid.current!.reloadAsync();
        }
    }

    private async markFavoriteConstructionSiteForUserAsync(cell: CellModel<ConstructionSiteModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "favorite") {
            await this.markFavorite(cell.model.id, !cell.model.favorite);
        }

        cell.model.favorite = !cell.model.favorite;
    }

    private async markFavorite(constructionSiteId: string, favorite: boolean): Promise<void> {
        await this.postAsync(`/api/ConstructionSites/MarkUnmarkFavoriteConstructionSiteForUser?constructionSiteId=${constructionSiteId}&favorite=${favorite}`);
        await this._constructionSitesGrid.current!.reloadAsync();
    }

    private renderStarIcon(cell: CellModel<ConstructionSiteModel>, action: CellAction<ConstructionSiteModel>): React.ReactNode {
        return <Icon key={`favorite_${cell.model.id}`}
                     name={cell.model.favorite ? "fas fa-star" : "far fa-star"}
                     className={styles.headerFavoriteIcon}
                     onClick={async () => await this.markFavoriteConstructionSiteForUserAsync(cell, action)}
        />;
    }

    private async setShowOnlyFavoriteSitesFilterAsync(value: boolean): Promise<void> {
        await this.changeUrl(null, value);
        await this.filterConstructionSitesAsync();
    }

    private async fetchDataAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ConstructionSiteModel>> {
        await this.changeUrl(null, null, pageNumber, pageSize, sortColumnName, sortDirection);
        return await this.props.getConstructionSites(pageNumber, pageSize, sortColumnName, sortDirection, this.state.keyword, this.state.showFavorites)
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.constructionSitesGrid}>
                <Form inline
                      onSubmit={async () => await this.filterConstructionSitesAsync()}
                >
                    <TextInput id={"constructionSiteSearchInput"}
                               className={styles.searchBar}
                               label={Localizer.ordersPageSearch}
                               width={"200px"}
                               title={Localizer.companiesOverviewFindByNameOrCustomerNumber}
                               value={this.state.keyword!}
                               onChange={async (_, value) => await this.changeUrl(value, null, 1)}
                    />
                    <Button submit
                            id={"constructionSiteSearchButton"}
                            className={styles.searchButton}
                            label={Localizer.ordersPageSearch}
                            type={ButtonType.Orange}
                            icon={{name: "search"}}
                    />

                    <Checkbox inline
                              value={this.state.showFavorites}
                              label={Localizer.companiesOverviewShowOnlyFavoriteCompanies}
                              onChange={async (_, value) => await this.setShowOnlyFavoriteSitesFilterAsync(value)}
                    />
                </Form>
                <Grid responsive
                      version2Styles
                      id={"adminConstructionSiteOverviewTable"}
                      pagination={this.defaultPageSize}
                      ref={this._constructionSitesGrid}
                      columns={this._constructionSiteColumns}
                      noDataText={Localizer.componentDropdownNoDataLanguageItemName}
                      defaultSortColumn={this.state.sortColumnName ?? "opened"}
                      defaultSortDirection={this.state.sortDirection ?? SortDirection.Desc}
                      fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.fetchDataAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                />
            </div>
        );
    }
}