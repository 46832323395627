import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import ProductModel from "@/models/server/ProductModel";
import {Accordion, Icon} from "@renta-apps/athenaeum-react-components";
import AttachedProductCard from "@/components/AttachedProducts/AttachedProductCard";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import PriceHelper, {ProductPriceType} from "@/helpers/PriceHelper";

import styles from "@/components/AttachedProducts/AttachedProducts.module.scss";
import {ProductType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";

interface IAttachedProductState {
    vat: number;
}

export interface IAttachedProductsProps {
    attachedProducts: ProductModel[];
    className?: string | null;
    singleColumn?: boolean;
    mainProductCount: number;
}

export default class AttachedProducts extends BaseComponent<IAttachedProductsProps, IAttachedProductState> {
    private authenticatedUsersOnly = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceForAuthenticatedUsersOnly);
    public state: IAttachedProductState = {
        vat: PriceHelper.userVat,
    };

    private get vat(): number {
        return this.state.vat;
    }
    
    private renderHeader(): React.ReactNode {
        const rentalProducts = this.props.attachedProducts.filter(product => product.productType === ProductType.Rental);
        const salesProducts = this.props.attachedProducts.filter(product => product.productType === ProductType.Sales);
        const sumRental = rentalProducts.length ?
            PriceHelper.sumAttached(rentalProducts, this.vat, this.props.mainProductCount, ProductPriceType.Daily, null) :
            0;
        const sumSales = salesProducts.length ?
            PriceHelper.sumAttached(salesProducts, this.vat, this.props.mainProductCount, ProductPriceType.Unit, null) :
            0;

        return (
            <div className={this.css(styles.attachedProductsHeader, (this.mobile || this.props.singleColumn) ? styles.singleColumn : null)}>
                <div className={this.css(styles.firstRow, styles.headerElement)}>
                    <h2>{Localizer.attachedProductsTitle}</h2>
                    <InlineTooltip
                        text={Localizer.attachedProductsTooltip}
                        icon={<Icon name={"fas question-circle"}/>}
                        className={styles.tooltipIcon}
                        onClick={(event) => event.stopPropagation()}
                    />
                </div>
                <div className={styles.headerElement}>
                    {Localizer.attachedProductsTotalAmount}: {this.props.attachedProducts.sum(item => item.quantity!) * this.props.mainProductCount}
                </div>
                {(this.isAuthenticated || !this.authenticatedUsersOnly) &&
                    <div className={styles.totalPrice}>
                        {Localizer.attachedProductsTotalCost}:
                        <div>
                            {(sumRental > 0) &&
                                (
                                    <div>
                                        <span className={styles.price}>
                                             {PriceHelper.toLocalizedValue({ value: sumRental, type: ProductPriceType.Daily, rentbasis: null })}
                                        </span> / {PriceHelper.units.daily(this.vat)}
                                    </div>
                                )
                            }
                            {(sumSales > 0) &&
                                (
                                    <div>
                                        <span className={styles.price}>
                                             {PriceHelper.toLocalizedValue({ value: sumSales, type: ProductPriceType.Unit, rentbasis: null })}
                                        </span> ({PriceHelper.getVatString(this.vat)})
                                    </div>
                                )
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
    
    public render() {
        if (!this.props.attachedProducts?.filter(product => product.id !== RentaEasyConstants.emptyGuid).length) {
            return null;
        }

        return (
            <Accordion autoCollapse={false}
                       className={this.css("new-accordion-style-v2", styles.attachedProductsAccordion, this.props.className)}
                       header={this.renderHeader()}
            >
                <div className={this.css(styles.attachedProductsContainer, (this.mobile || this.props.singleColumn) ? styles.singleColumn : null)}>
                    {this.props.attachedProducts.map((product, index) => (
                        <AttachedProductCard product={product}
                                             key={index + product.id}
                                             mainProductCount={this.props.mainProductCount}
                        />
                    ))}
                </div>
            </Accordion>
        )
    }
}