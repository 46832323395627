import Emissions from "@/components/Emissions/Emissions";
import {EmissionFilterTimeFrame, EmissionType} from "@/models/Enums";
import GetEquipmentEmissionData from "@/models/server/Requests/GetEquipmentEmissionData";

interface IEmissionsProps {
    contractId: string;
}

export default class ContractEmissions extends Emissions<IEmissionsProps> {
    protected getEndpoint(): string {
        return "/api/Emissions/GetEquipmentEmissionData";
    }

    protected getExcelEndpoint(): string {
        return "/api/Emissions/GetEquipmentEmissionDataExcel";
    }

    protected getRequest(
        dateRangeFrom: Date | null,
        dateRangeTo: Date | null,
        emissionType: EmissionType | null,
        emissionFilterTimeFrame: EmissionFilterTimeFrame,
    ): GetEquipmentEmissionData {
        return {
            constructionSiteId: null,
            contractId: this.props.contractId,
            dateRangeFrom,
            dateRangeTo,
            emissionType,
            emissionFilterTimeFrame,
        };
    }
}