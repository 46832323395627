import React, {useMemo} from "react";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import styles from "./EnvironmentControlInfoScreen.module.scss";
import SensorsGridPaging from "@/pages/InfoScreen/EnvironmentControl/SensorsGridPaging";

type WideScreenWithoutFloorPlanProps = {
    devices: EnvironmentDevice[];
    sensorsCount: number;
    sensorsPerGrid: number;
    containerWidth: number;
    gridsCount: number;
    onGridPagingChanged: (page: number, gridsPerScreen: number, hideInfo: boolean) => void;
};

const sensorGridWidth = 376; // 376 = 360 (width) + 16 (gap between grids)

const WideScreenWithoutFloorPlan: React.FC<WideScreenWithoutFloorPlanProps> = (props: WideScreenWithoutFloorPlanProps) => {
    const gridsPerScreen = useMemo(() => {
        if (props.sensorsCount <= 0) {
            return 0;
        }

        // 16 = gap between grids. There are more grids than gaps, so let's add one gap to the container width, so that the calculations are correct
        return Math.floor((props.containerWidth + 16) / sensorGridWidth);
    }, [props.sensorsCount, props.containerWidth]);

    return (
        <div className={styles.wideContainer} id="wideContainer">
            <div className={styles.wideContainerWithoutFloorPlanContent}>
                <SensorsGridPaging devices={props.devices}
                                   gridsCount={props.gridsCount}
                                   gridsPerScreen={gridsPerScreen}
                                   sensorsPerGrid={props.sensorsPerGrid}
                                   onGridPagingChanged={props.onGridPagingChanged}
                />
            </div>
        </div>
    );
}

export default WideScreenWithoutFloorPlan;