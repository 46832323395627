import {OrganizationContractLevel} from "@/models/Enums";
import {ContractUserModel} from "@/models/server/ContractUserModel";

export class OrganizationContractModel {
    public contractId: string = "";

    public organizationId: string = "";

    public level: OrganizationContractLevel = OrganizationContractLevel.SubCompany;

    public parentContractId: string | null = null;

    public isDeleted: boolean = false;

    public name: string | null = null;

    public additionalName: string | null = null;

    public vatId: string | null = null;

    public customerNumber: string | null = null;

    public orderConfirmationEmail: string | null = null;

    public customerId: string | null = null;

    public users: ContractUserModel[] = [];

    public children: OrganizationContractModel[] = [];

    public isOrganizationContractModel: boolean = true;

    /**
     * Is the Company a user favorite.
     * This is set per-user, there is no global value.
     */
    public favorite: boolean = false;
}