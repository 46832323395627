import UnleashHelper from "@/helpers/UnleashHelper";

const UpdateFeatureFlagUrl = "/api/LocalFeatureFlag/UpdateFeatureFlag";

class CypressFeatureFlagService {
    public enable(featureFlag : string) {
        cy.request("POST", UpdateFeatureFlagUrl, {
            featureFlagName: featureFlag,
            status: true,
        });
        
        UnleashHelper.update(featureFlag, true);
    }

    public disable(featureFlag : string) {
        cy.request("POST", UpdateFeatureFlagUrl, {
            featureFlagName: featureFlag,
            status: false,
        });

        UnleashHelper.update(featureFlag, false);
    }
}

export default new CypressFeatureFlagService();