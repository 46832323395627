import { pageData } from "../../../helpers/CypressHelper";
import Localizer from "../../../localization/Localizer";

// Add export to fix compilation error TS1208
export {};

describe('Desktop - Logged in company user frontpage tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('Open frontPage and verify proper widgets are visible', () => {

        cy.visit('/');

        pageData().frontPage.widgets.rent().should('exist');
        pageData().frontPage.widgets.fleetMonitoring().should('exist');
        pageData().frontPage.widgets.depot().should('exist');

        cy.get('#selected_role_widget').should('exist');
        cy.get('#apply_company_access_widget').should('not.exist');
        cy.get('#private_orders_widget').should('not.exist');
        cy.get('#service_widget').should('exist');
    });

    it('Should have favicon.ico and apple touch icon in head element', () => {

        cy.visit('/');

        cy.get('head').find('link[rel="shortcut icon"]').should('have.attr', 'href', '/favicon.ico');
        cy.get('head').find('link[rel="apple-touch-icon"]').should('have.attr', 'href', '/renta-logo-touch.png');
    });

    it('Should display FleetInfoWidget correctly when alarmCountSinceYesterday and equipmentsMaxIdleInDays have values', () => {

        cy.intercept('POST', 'api/Fleet/ReadFleetInfo', { fixture: 'fleetInfoAllData.json' });

        cy.visit('/');

        cy.get('span').should('contain', Localizer.fleetMonitoringButtonText);
        
        cy.get('span').should('contain', `${Localizer.get(Localizer.fleetMonitoringButtonDescriptionEquipmentIdle, 3)} | ${Localizer.get(Localizer.fleetMonitoringButtonDescriptionSinceYesterday, 2)}`);
    });

    it('Should display FleetInfoWidget correctly when only alarmCountSinceYesterday has a value', () => {

        cy.intercept('POST', 'api/Fleet/ReadFleetInfo', { fixture: 'fleetInfoPartialData.json' });

        cy.visit('/');

        cy.get('span').should('contain', Localizer.fleetMonitoringButtonText);

        cy.get('span').should('contain', Localizer.get(Localizer.fleetMonitoringButtonDescriptionSinceYesterday, 2));
    });

});