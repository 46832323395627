import React from "react";
import {Accordion, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, IconSize, SelectListItem} from "@renta-apps/athenaeum-react-components";

import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {TogglerPosition} from "@/models/Enums";

import Localizer from "@/localization/Localizer";

import styles from "./InvoiceToolbar.module.scss";
import InvoiceToolbarModel from "@/pages/ConstructionSiteDetails/InvoiceToolbar/InvoiceToolbarModel";
import {IInvoiceFiltersData} from "@/pages/ConstructionSiteDetails/Invoices/Invoices";

interface IInvoiceToolbarProps {
    model: InvoiceToolbarModel;
    invoiceFiltersData?: IInvoiceFiltersData;

    onChange(filters: InvoiceToolbarModel): Promise<void>;
}

interface IToolbarState {
}

export default class InvoiceToolbar extends BaseComponent<IInvoiceToolbarProps, IToolbarState> {

    private async processOnChangeAsync(): Promise<void> {
        await this.props.onChange(this.model);
    }

    private async onContractsChangeHandlerAsync(items: SelectListItem[]): Promise<void> {
        this.model.contracts = items.map(item => item.value);

        await this.processOnChangeAsync();
    }

    private async onInvoiceTypeChangeHandlerAsync(item: SelectListItem | null): Promise<void> {
        this.model.invoiceType = item ? Number.parseInt(item.value) : null;

        await this.processOnChangeAsync();
    }

    private async onStartDateChangeHandlerAsync(invoiceStartDate: Date | null): Promise<void> {
        this.model.invoiceStartDate = invoiceStartDate;

        await this.processOnChangeAsync();
    }

    private async onEndDateChangeHandlerAsync(invoiceEndDate: Date | null): Promise<void> {
        this.model.invoiceEndDate = invoiceEndDate;

        await this.processOnChangeAsync();
    }

    private get invoiceFiltersData(): IInvoiceFiltersData | null {
        return this.props.invoiceFiltersData || null;
    }

    private get contractItems(): SelectListItem[] {
        return this.invoiceFiltersData ? this.invoiceFiltersData.contracts : [];
    }

    private get invoiceTypeItems(): SelectListItem[] {
        return this.invoiceFiltersData ? this.invoiceFiltersData.invoiceTypes : [];
    }

    public get model(): InvoiceToolbarModel {
        return this.props.model;
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.invoiceToolbar}>

                <Accordion header={Localizer.constructionSiteDetailsFilterResults}
                           togglerPosition={TogglerPosition.Header}
                           togglerIcon={"angle-down"}
                           togglerSize={IconSize.X3}
                           className="new-accordion-style-v2-with-overflow new-accordion-style-v2-expansionInfo"
                >

                    <Form>

                        <div className="row">

                            <Dropdown noSubtext noWrap multiple
                                      className="col-12 col-sm-12 col-md-12 col-lg-3"
                                      align={DropdownAlign.Left}
                                      autoCollapse={false}
                                      id="invoices_contracts_filter"
                                      label={Localizer.constructionSiteDetailsContractFilterLabel}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.contractItems}
                                      selectedItems={this.model.contracts}
                                      nothingSelectedText={Localizer.constructionSiteDetailsContractFilterNothingSelectedText}
                                      onChange={async (sender) => await this.onContractsChangeHandlerAsync(sender.selectedItems)}
                            />

                            <Dropdown noSubtext noWrap
                                      className="col-12 col-sm-12 col-md-12 col-lg-3"
                                      align={DropdownAlign.Left}
                                      label={Localizer.constructionSiteDetailsInvoicesInvoiceTypeFilter}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.invoiceTypeItems}
                                      selectedItem={(this.model.invoiceType !== null) ? this.model.invoiceType.toString() : undefined}
                                      onChange={async (sender, item) => await this.onInvoiceTypeChangeHandlerAsync(item)}
                            />

                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                <DateInput popup
                                           id="invoices_start_date_filter"
                                           name="invoiceStartDate"
                                           label={Localizer.constructionSiteDetailsInvoicesStartDateFilter}
                                           value={this.model.invoiceStartDate || undefined}
                                           onChange={async (value) => await this.onStartDateChangeHandlerAsync(value)}
                                />
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                <DateInput popup
                                           id="invoices_end_date_filter"
                                           name="invoiceEndDate"
                                           label={Localizer.constructionSiteDetailsInvoicesStartEndFilter}
                                           value={this.model.invoiceEndDate || undefined}
                                           onChange={async (value) => await this.onEndDateChangeHandlerAsync(value)}
                                />
                            </div>
                        </div>

                    </Form>

                </Accordion>

            </div>
        );
    }
};