import React from "react";
import {IconSize, PageContainer, PageHeader, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import {OrganizationContractRoleModel} from "@/models/server/OrganizationContractRoleModel";
import {ConstructionSiteRoleModel} from "@/models/server/ConstructionSiteRoleModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import BaseRole from "@/models/server/BaseRole";
import SelectCompanyRequest from "@/models/server/Requests/SelectCompanyRequest";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "../../localization/Localizer";
import {BasePageParameters, PageRoute, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";

import styles from "./SelectCompany.module.scss";
import RentaEasyController from "@/pages/RentaEasyController";
import EasyRouteWidget from "@/components/EasyRouteWidget/EasyRouteWidget";

export interface ISelectCompanyParams extends BasePageParameters {
    redirectTo?: PageRoute;
}

interface ISelectCompanyState {
    selectedContractId: string | null;
    selectedConstructionSiteId: string | null;
}

enum RoleType {
    Private = 0,
    Company = 1,
    ConstructionSite = 2,
}

export default class SelectCompany extends AuthorizedPage<ISelectCompanyParams, ISelectCompanyState> {

    // Fields

    public state: ISelectCompanyState = {
        selectedContractId: null,
        selectedConstructionSiteId: null,
    };

    // Properties

    private _constructionSiteRoles: BaseRole[] | null = null;

    private get constructionSiteRoles(): BaseRole[] {

        if (!this._constructionSiteRoles) {

            const constructionSiteRoles: ConstructionSiteRoleModel[] = this.userContext.user!.constructionSiteRoles;
            constructionSiteRoles.order(constructionSiteRole => constructionSiteRole.constructionSiteNameDisplayName);

            this._constructionSiteRoles = constructionSiteRoles
                .map(
                    constructionSiteRole => {
                        const baseRole = new BaseRole();

                        baseRole.id = constructionSiteRole.constructionSiteId;
                        baseRole.entityAdditionalName = constructionSiteRole.constructionSiteExternalReference;
                        baseRole.entityName = constructionSiteRole.constructionSiteName || "";
                        baseRole.roleName = constructionSiteRole.roleName;
                        baseRole.group = Localizer.rolesGroupConstructionSite;
                        baseRole.isSelected = (this.userContext.selectedConstructionSiteId === constructionSiteRole.id);

                        return baseRole;
                    }
                );
        }

        return this._constructionSiteRoles;
    }

    private _contractRoles: BaseRole[] | null = null;

    private get contractRoles(): BaseRole[] {

        if (!this._contractRoles) {

            const contractRoles: OrganizationContractRoleModel[] = this.userContext.user!.organizationRoles;
            contractRoles.order(contractRole => contractRole.roleLevel, contractRole => contractRole.contract?.name);

            this._contractRoles = contractRoles
                .map(
                    contractRole => {
                        const baseRole = new BaseRole();

                        baseRole.id = contractRole.contractId;
                        baseRole.entityAdditionalName = contractRole.contract!.additionalName;
                        baseRole.entityName = contractRole.contract!.name || "";
                        baseRole.roleName = contractRole.roleName;
                        baseRole.group = Localizer.rolesGroupCompany;
                        baseRole.isSelected = (this.userContext.selectedContractId === contractRole.id);

                        return baseRole;
                    }
                );
        }

        return this._contractRoles;
    }


    protected get title(): string {
        return Localizer.selectCompanyPageSelectCompany;
    }

    // Methods

    private async continueAsRoleAsync(role: RoleType): Promise<void> {

        const selectedCompanyId: string | null = (role === RoleType.Company)
            ? this.state.selectedContractId
            : null;

        const selectedConstructionSiteId: string | null = (role === RoleType.ConstructionSite)
            ? this.state.selectedConstructionSiteId
            : null;

        const request: SelectCompanyRequest = {
            selectedCompanyId,
            selectedConstructionSiteId,
        };

        await this.postSelectionAsync(request);
    }

    private async selectCompanyRoleAsync(role: BaseRole): Promise<void> {
        const request: SelectCompanyRequest = {
            selectedCompanyId: role.id,
            selectedConstructionSiteId: null,
        };

        await this.postSelectionAsync(request);
    }

    private async selectConstructionSiteRoleAsync(role: BaseRole): Promise<void> {
        const request: SelectCompanyRequest = {
            selectedCompanyId: null,
            selectedConstructionSiteId: role.id,
        };

        await this.postSelectionAsync(request);
    }

    private async postSelectionAsync(request: SelectCompanyRequest): Promise<void> {

        // Update selected identifiers in localStorage
        if (request.selectedCompanyId && UserInteractionDataStorage.get(RentaEasyConstants.cookiesSelectedContractId) !== request.selectedCompanyId) {
            UserInteractionDataStorage.set(RentaEasyConstants.cookiesSelectedContractId, request.selectedCompanyId);
        }

        if (request.selectedConstructionSiteId && UserInteractionDataStorage.get(RentaEasyConstants.cookiesSelectedConstructionSiteId) !== request.selectedConstructionSiteId) {
            UserInteractionDataStorage.set(RentaEasyConstants.cookiesSelectedConstructionSiteId, request.selectedConstructionSiteId);
        }

        // Sets user scope
        await this.postAsync("/api/Application/SelectCompany", request);

        if (RentaEasyController.constructionSitePricesEnabledForEndUsers() && request.selectedConstructionSiteId) {
            await RentaEasyController.setOperatingForConstructionSite(request.selectedConstructionSiteId, this);
        }

        const redirectTo: PageRoute = (this.typedParameters?.redirectTo)
            ? this.typedParameters.redirectTo
            : PageDefinitions.frontPage.route();


        await PageRouteProvider.redirectAsync(redirectTo);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const selectedContractId: string | null = this.userContext.selectedContractId ?? UserInteractionDataStorage.get(RentaEasyConstants.cookiesSelectedContractId);
        const selectedConstructionSiteId: string | null = this.userContext.selectedConstructionSiteId ?? UserInteractionDataStorage.get(RentaEasyConstants.cookiesSelectedConstructionSiteId);

        await this.setState({
            selectedContractId,
            selectedConstructionSiteId,
        });
    }

    public render(): React.ReactNode {
        // See AuthorizedPage.initializeAsync
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={this.css(styles.selectCompany, "gap-2")} hasWideHeader>
                <PageHeader title={this.title.toUpperCase()} wideHeader wideHeaderBackgroundImage="/images/offices/lahti.jpg"/>

                <div className={styles.actionsContainer} data-cy={`links-container`}>
                    <h4 className={styles.h4}>
                        {Localizer.selectCompanyContinueAs}
                    </h4>

                    <WidgetContainer className={styles.widgetContainer}>
                        {
                            this.contractRoles
                                .map((item, index: number) => {
                                    return (
                                        <EasyRouteWidget key={`company_role_widget_${index}`}
                                                         text={[item.entityName!, item.entityAdditionalName!, Localizer.get(item.roleName)]}
                                                         leftIcon={{name: "user-helmet-safety", size: IconSize.X3}}
                                                         rightIcon={{name: "angle-right", size: IconSize.X3}}
                                                         onClick={async () => await this.selectCompanyRoleAsync(item)}
                                        />
                                    );
                                })
                        }
                        {
                            this.constructionSiteRoles
                                .map((item, index) => {
                                    return (
                                        <EasyRouteWidget key={`site_role_widget_${index}`} text={[item.entityName!, item.entityAdditionalName!, Localizer.get(item.roleName)]}
                                                         leftIcon={{name: "user-helmet-safety", size: IconSize.X3}}
                                                         rightIcon={{name: "angle-right", size: IconSize.X3}}
                                                         onClick={async () => await this.selectConstructionSiteRoleAsync(item)}
                                        />

                                    );
                                })
                        }

                        <div className={styles.lineWithText}>
                            <div className={styles.line}></div>
                            <div className={styles.text}>{Localizer.selectCompanyPageOr}</div>
                            <div className={styles.line}></div>
                        </div>
                        <EasyRouteWidget key={"private_role_widget"}
                                         lightStyle text={[Localizer.selectCompanyPageContinueAsPrivate]}
                                         leftIcon={{name: "fa-light fa-user", size: IconSize.X3}}
                                         rightIcon={{name: "angle-right", size: IconSize.X3}}
                                         onClick={async () => await this.continueAsRoleAsync(RoleType.Private)}
                        />
                    </WidgetContainer>
                </div>
            </PageContainer>
        );
    }
}