export default class ChangePasswordRequest {

    /**
     * Users current password in cleartext.
     */
    public currentPassword: string;

    /**
     * Users new password in cleartext.
     */
    public newPassword: string;

    constructor(newPassword: string, currentPassword: string) {
        this.newPassword = newPassword;
        this.currentPassword = currentPassword;
    }
}