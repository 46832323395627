import React, {useState, useEffect, useRef} from "react";
import Localizer from "@/localization/Localizer";
import EnvironmentFloorPlan from "@/models/server/EnvironmentFloorPlan";
import FloorPlanView from "./FloorPlanView/FloorPlanView";
import SensorsTable from "./SensorsTable/SensorsTable";
import SensorsGrid from "./SensorsGrid/SensorsGrid";
import styles from "./EnvironmentControlSensorValues.module.scss";
import Panel from "@/components/EnvironmentControl/Panel/Panel";
import {useResize} from "@/helpers/Hooks";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";

type EnvironmentControlSensorValuesProps = {
    floorPlan?: EnvironmentFloorPlan | null;
    devices: EnvironmentDevice[];
    dynamicHeight?: boolean;
    scale?: number;
    onSensorClick?(deviceId: string, sensors: number[]): Promise<void>;
};

const floorPlanMinWidth = 575;
const floorPlanMinHeight = 500;
const tableMinWidth = 768;

const EnvironmentControlSensorValues: React.FC<EnvironmentControlSensorValuesProps> = (props: EnvironmentControlSensorValuesProps) => {
    const {windowWidth, windowHeight} = useResize();
    const [selectedSensor, setSelectedSensor] = useState<{deviceId: string, sensorId?: number} | null>(null);
    const [floorPlanHeight, setFloorPlanHeight] = useState<number | undefined>(undefined);
    const [previousWindowHeight, setPreviousWindowHeight] = useState<number | undefined>(undefined);
    const floorPlanPanelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!props.dynamicHeight || !floorPlanPanelRef.current || !props.floorPlan) {
            setFloorPlanHeight(undefined);
            return;
        }

        if (floorPlanHeight && previousWindowHeight && windowHeight < previousWindowHeight) {
            const heightDifference = previousWindowHeight - windowHeight;
            setFloorPlanHeight(prev => Math.max(prev! - heightDifference, floorPlanMinHeight));
            setPreviousWindowHeight(windowHeight);
            return;
        }

        const gridPanelHeight = floorPlanPanelRef.current.clientHeight;
        // 40 - padding
        setFloorPlanHeight(Math.max(gridPanelHeight - 40, floorPlanMinHeight));
        setPreviousWindowHeight(windowHeight);
    }, [floorPlanPanelRef, windowHeight, props.dynamicHeight]);

    const deviceSelected = (deviceId: string, sensorId?: number) => {
        setSelectedSensor({deviceId: deviceId, sensorId: sensorId});
    }

    const deviceUnselected = () => {
        setSelectedSensor(null);
    }

    return (<>
        {((windowWidth > floorPlanMinWidth) && !!props.floorPlan) && (
            <Panel className={props.dynamicHeight ? styles.floorPlanPanel : undefined} ref={floorPlanPanelRef}>
                {(!props.dynamicHeight || !!floorPlanHeight) && (
                    <FloorPlanView floorPlan={props.floorPlan}
                                   selectedDeviceId={selectedSensor?.deviceId}
                                   onSensorClick={props.onSensorClick}
                                   onDeviceSelected={(deviceId) => deviceSelected(deviceId)}
                                   onDeviceUnselected={() => deviceUnselected()}
                                   maxHeight={floorPlanHeight}
                                   scale={props.scale}
                    />
                )}
            </Panel>
        )}
        <Panel className={styles.gridPanel}>
            <div className={styles.gridHeader}>
                {Localizer.environmentControlSensorGridSectionHeader}
            </div>
            {windowWidth < tableMinWidth ? (
                <SensorsGrid devices={props.floorPlan?.floorPlanDevices ?? props.devices}
                             language={Localizer.language}
                             onSensorClick={props.onSensorClick}
                />
            ) : (
                <SensorsTable devices={props.floorPlan?.floorPlanDevices ?? props.devices}
                              language={Localizer.language}
                              selectedSensor={selectedSensor}
                              onSensorClick={props.onSensorClick}
                              onDeviceSelected={(deviceId, sensorId) => deviceSelected(deviceId, sensorId)}
                              onDeviceUnselected={() => deviceUnselected()}
                />
            )}
        </Panel>
    </>);
}

export default EnvironmentControlSensorValues;