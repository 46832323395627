import FileApiModel from "@/models/server/FileApiModel";

export default class CategoryModel {
    public name: string = "";

    public url: string = "";

    public parent: CategoryModel | null = null;

    public id: string | null = null;

    public isLast: boolean = false;

    public hidden: boolean = false;

    public files: FileApiModel[] = [];

    public priority: number = 0;

    public imageReference: string = "";

    public editDiscount: boolean = false;

    public discountPercentage: number = 0;
}