import React from "react";
import Panel from "@/components/EnvironmentControl/Panel/Panel";
import FloorPlanView from "@/components/EnvironmentControl/FloorPlanView/FloorPlanView";
import EnvironmentFloorPlan from "@/models/server/EnvironmentFloorPlan";
import styles from "./EnvironmentControlInfoScreen.module.scss";
import SensorsGridPaging from "@/pages/InfoScreen/EnvironmentControl/SensorsGridPaging";

type WideScreenWithFloorPlanProps = {
    floorPlan: EnvironmentFloorPlan;
    floorPlanContainerHeight: number;
    scale: number;
    sensorsCount?: number;
    sensorsPerGrid: number;
    gridsCount: number;
    onGridPagingChanged: (page: number, gridsPerScreen: number, hideInfo: boolean) => void;
};

const WideScreenWithFloorPlan: React.FC<WideScreenWithFloorPlanProps> = (props: WideScreenWithFloorPlanProps) => {
    return (
        <div className={styles.wideContainer} id="wideContainer">
            <div className={styles.wideContainerContent}>
                <Panel className={styles.floorPlanContainer}>
                    <FloorPlanView floorPlan={props.floorPlan}
                                   maxHeight={props.floorPlanContainerHeight}
                                   scale={props.scale}
                    />
                </Panel>
                {props.sensorsCount !== undefined && props.sensorsCount > 0 && (
                    <SensorsGridPaging devices={props.floorPlan.floorPlanDevices}
                                       gridsCount={props.gridsCount}
                                       gridsPerScreen={2}
                                       sensorsPerGrid={props.sensorsPerGrid}
                                       onGridPagingChanged={props.onGridPagingChanged}
                                       maintainNumberOfColumns
                    />
                )}
            </div>
        </div>
    );
}

export default WideScreenWithFloorPlan;