import React from "react";
import {CellAction, CellModel, ColumnDefinition, DocumentPreviewModal, Grid, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import styles from "./EquipmentDocument.module.scss";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ProductModel from "@/models/server/ProductModel";
import RentaEasyController from "@/pages/RentaEasyController";
import Localizer from "@/localization/Localizer";
import {ActionType, ApiProvider, BasePageParameters, ch, DocumentPreviewModel, DocumentPreviewSize} from "@renta-apps/athenaeum-react-common";
import LogDocumentEvent from "@/models/server/LogDocumentEvent";
import GetMachineDocumentsRequest from "@/models/server/Requests/GetMachineDocumentsRequest";
import { logDocumentEvent } from "@/services/ConstructionSiteService";
import FileInfoModel from "@/models/server/FileInfoModel";
import {getFileModel} from "@/services/FileService";

export interface IEquipmentDocumentsParams extends BasePageParameters {
    rentalObjectId: string;
    rentaId: string;
}

interface IEquipmentDocumentsState {
    productModel: ProductModel | null,
    files: FileInfoModel[] | null,
}

export default class EquipmentDocuments extends AuthorizedPage<IEquipmentDocumentsParams, IEquipmentDocumentsState> {

    public state: IEquipmentDocumentsState = {
        productModel: null,
        files: null,
    };

    private readonly _equipmentDocumentsGrid: React.RefObject<Grid<FileInfoModel>> = React.createRef();
    private readonly _equipmentDocumentPreviewModalRef: React.RefObject<DocumentPreviewModal> = React.createRef();

    private getGridColumnDefinitions() : ColumnDefinition[] {
        let equipmentDocumentsGridColumns: ColumnDefinition[] = [
            {
                header: Localizer.constructionsiteEquipmentDocumentsFileName,
                accessor: "name",
                editable: false,
                sorting: true,
                minWidth: 300,
            },
            {
                header: Localizer.constructionsiteEquipmentDocumentsFormat,
                minWidth: 50,
                accessor: "type",
                editable: false,
                sorting: true,
                transform: (cell: CellModel<FileInfoModel>) => EquipmentDocuments.transformDocumentType(cell.model.type!),
            },
        ];
        if (!this.mobile){
            equipmentDocumentsGridColumns.push({
                header: Localizer.constructionsiteEquipmentDocumentsView,
                minWidth: 50,
                visible: true,
                actions: [
                    {
                        name: "view",
                        icon: {name: "far search", customStyle: {fontSize: "1.5rem"}},
                        type: ActionType.Info,
                        callback: async (cell, action) => await this.viewDocumentAsync(cell, action)
                    }
                ]
            });
        }
        equipmentDocumentsGridColumns.push({
            header: Localizer.constructionsiteEquipmentDocumentsDownload,
            minWidth: 100,
            visible: true,
            actions: [
                {
                    name: "download",
                    icon: {name: "far download", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Info,
                    callback: async (cell, action) => await this.downloadDocumentAsync(cell, action)
                }
            ]
        });

        return equipmentDocumentsGridColumns;
    }

    private static transformDocumentType(documentType: string) {
        return documentType?.split("/")[1];
    }

    private static transformDocumentSize(documentSize: number) {
        return `${documentSize.toFixed(1)} MB`;
    }

    private async viewDocumentAsync(cell: CellModel<FileInfoModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "view") {

            const documentPreviewModal: DocumentPreviewModal = this._equipmentDocumentPreviewModalRef.current!;
            const model = new DocumentPreviewModel();

            const fileModel = await getFileModel(cell.model);

            model.title = this.subTitle;
            model.document = fileModel;
            model.size = DocumentPreviewSize.Large;

            await this.logEventAsync(cell.model);

            await documentPreviewModal.openAsync(model);
        }
    }

    private async downloadDocumentAsync(cell: CellModel<FileInfoModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "download") {

            await this.logEventAsync(cell.model);

            const fileModel = await getFileModel(cell.model);

            ch.download(fileModel);
        }
    }

    private async logEventAsync(model: FileInfoModel): Promise<void> {
        const parameters: IEquipmentDocumentsParams | null = this.typedParameters;

        const request: LogDocumentEvent = {
            fileId: model.documentId?.toString() ?? model.url ?? "Unknown",
            fileName: model.name,
            rentaId: parameters?.rentaId ?? null,
            rentalObjectNumber: this.state.productModel?.rentalObjectNumber ?? null
        };
        await logDocumentEvent(request);
    }

    protected get title(): string {
        return Localizer.constructionsiteEquipmentDocuments;
    }

    protected get subTitle(): string {
        return this.state.productModel?.name!;
    }

    private async fetchEquipmentDocumentsAsync(): Promise<FileInfoModel[]> {

        const parameters: IEquipmentDocumentsParams | null = this.typedParameters;

        const request: GetMachineDocumentsRequest = {
            rentaId: parameters?.rentaId ?? "",
        }

        return await this.postAsync(`/api/Product/GetMachineDocuments`, request);
    }

    public async initializeAsync(): Promise<void> {

        const productModel: ProductModel = await RentaEasyController.getProductDetailsAsync(this.typedParameters!.rentalObjectId);

        const files: FileInfoModel[] = await this.fetchEquipmentDocumentsAsync();

        await this.setState({productModel, files});
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.productLocation}>
                <PageHeader title={this.title}
                            subtitle={this.subTitle}
                />

                <PageRow>

                    <Grid version2Styles responsive
                          id="requestTypeTable"
                          ref={this._equipmentDocumentsGrid}
                          columns={this.getGridColumnDefinitions()}
                          noDataText={Localizer.dropdownNoData}
                          data={this.state.files}
                    />
                </PageRow>
                <>
                    <DocumentPreviewModal
                        ref={this._equipmentDocumentPreviewModalRef}
                    />
                </>
            </PageContainer>
        );
    }
}