import {RentType} from "@/models/Enums";

export default class SummaryRow {
    public count: number = 0;

    public name: string = "";

    public productNumber: string = "";

    public returnDate: Date | null = null;

    public price?: number = 0;

    public type?: RentType = RentType.Daily;

    public rentedBy: string | null = null;

    public rentalObjectId: string | null = null;

    constructor(count: number, name: string, productNumber: string, returnDate: Date | null, renter: string, rentalObjectId: string, price?: number, type?: RentType) {
        this.count = count;
        this.name = name;
        this.productNumber = productNumber;
        this.returnDate = returnDate;
        this.price = price;
        this.type = type;
        this.rentedBy = renter;
        this.rentalObjectId = rentalObjectId;
    }
}