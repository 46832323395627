import React from "react";
import {PageContainer, PageHeader, PageRow, PasswordForm, PasswordFormType} from "@renta-apps/athenaeum-react-components";
import ChangePasswordRequest from "@/models/server/Requests/ChangePasswordRequest";
import SetPasswordRequest from "@/models/server/Requests/SetPasswordRequest";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";

export default abstract class BasePasswordPage<TParams extends BasePageParameters>
    extends AuthorizedPage<TParams> {

    protected get passwordType(): PasswordFormType {
        return (this.user.hasPassword)
            ? PasswordFormType.ChangePassword
            : PasswordFormType.SetPassword;
    }

    private async changePasswordAsync(password: string, oldPassword: string): Promise<void> {
        if (this.passwordType === PasswordFormType.SetPassword) {
            const request = new SetPasswordRequest(password);
            await this.postAsync("/api/Application/SetPassword", request);
        }
        else {
            const request = new ChangePasswordRequest(password, oldPassword);
            await this.postAsync("/api/Application/ChangePassword", request);
        }
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title}/>

                <PageRow>
                    <div className="col-lg-6">
                        <PasswordForm type={this.passwordType}
                                      onSubmit={async (newPassword, oldPassword) => await this.changePasswordAsync(newPassword, oldPassword)}
                        />
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}