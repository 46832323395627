import React from "react";
import ServiceRequestDefinitionInput from "@/models/server/ServiceRequestDefinitionInput";
import CustomServiceRequestModel from "@/models/server/CustomServiceRequestModel";
import ServiceCatalog from "@/components/Service/ServiceCatalog/ServiceCatalog";
import ServicesEditor from "@/components/Service/ServiceEditor/ServiceEditor";
import {BaseComponent, BasePageParameters} from "@renta-apps/athenaeum-react-common";

import Localizer from "@/localization/Localizer";

export interface IDeletedServicesParams extends BasePageParameters {
    isAdmin: boolean,

    reloadServices(): Promise<void>;
}

interface IDeletedServicesState {

    /**
     * Definition of the Service under inspection/editing.
     */
    selectedServiceRequest: CustomServiceRequestModel | null;

    selectedServiceRequestIsDeleted: boolean;

    /**
     * All existing deleted Service Request definitions.
     * Only filled for Admins.
     */
    deletedDefinitions: CustomServiceRequestModel[];

    editMode: boolean;

    /**
     * Input under editing (displayed in right panel).
     */
    currentInput: ServiceRequestDefinitionInput | null;
}

export default class DeletedServices extends BaseComponent<IDeletedServicesParams, IDeletedServicesState> {

    public state: IDeletedServicesState = {
        selectedServiceRequest: null,
        selectedServiceRequestIsDeleted: false,
        currentInput: null,
        editMode: false,
        deletedDefinitions: [],
    };

    // Getters

    private get selectedServiceRequest(): CustomServiceRequestModel {
        return this.state.selectedServiceRequest!;
    }

    private get currentInput(): ServiceRequestDefinitionInput | null {
        return this.state.currentInput;
    }

    private get editMode(): boolean {
        return this.state.editMode;
    }

    public get hasSelectedServiceRequest(): boolean {
        return (!!this.state.selectedServiceRequest);
    }

    protected get title(): string {
        return Localizer.breadCrumbServices;
    }

    // Async-methods

    private async closeEditorAsync(reload: boolean): Promise<void> {
        await this.setState({
            selectedServiceRequest: null,
            editMode: false,
            currentInput: null
        });

        await this.fetchDataAsync();
        if (reload) {
            await this.props.reloadServices();
        }
    }

    private async openEditorAsync(selectedServiceRequest: CustomServiceRequestModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedServiceRequest,
            selectedServiceRequestIsDeleted: isDeleted,
            editMode: true
        });
    }

    private async openDefinitionAsync(selectedServiceRequest: CustomServiceRequestModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedServiceRequest,
            selectedServiceRequestIsDeleted: isDeleted,
            editMode: false
        });
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.fetchDataAsync();
    }

    public async fetchDataAsync(): Promise<void> {
        let deletedDefinitions: CustomServiceRequestModel[] = [];

        if (this.props.isAdmin) {
            deletedDefinitions = await this.postAsync(`/api/Services/GetDeletedServiceDefinitions`, null);
        }

        await this.setState({
            deletedDefinitions
        });
    }

    public render(): React.ReactNode {

        return (
            <>
                {
                    (this.props.isAdmin) && (!this.hasSelectedServiceRequest) &&
                    (
                        <>
                            <ServiceCatalog canEdit
                                            canCreateNew={false}
                                            createNewButtonLabel={""}
                                            customServiceModels={this.state.deletedDefinitions}
                                            openEditorAsync={async (model) => await this.openEditorAsync(model, true)}
                                            openDefinitionAsync={async (model) => await this.openDefinitionAsync(model, true)}
                            />
                        </>
                    )
                }
                {
                    (this.hasSelectedServiceRequest) &&
                    (
                        <ServicesEditor closeEditorAsync={async (reload) => await this.closeEditorAsync(reload)}
                                        model={this.selectedServiceRequest!}
                                        currentInput={this.currentInput}
                                        editMode={this.editMode}
                                        deleted={this.state.selectedServiceRequestIsDeleted}
                        />
                    )
                }
            </>
        );
    }
}