import React from "react";
import {Accordion, Button, ButtonType, CellModel, ColumnDefinition, ColumnType, Grid, IconSize, PageRow, ThreeColumns, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {InvoiceModel} from "@/models/server/InvoiceModel";
import {InvoiceRowType, InvoiceType, TogglerPosition} from "@/models/Enums";
import {InvoiceRowModel} from "@/models/server/InvoiceRowModel";
import GetInvoiceDocumentRequest from "@/models/server/GetInvoiceDocumentRequest";
import UserContext from "@/models/server/UserContext";
import PriceHelper from "@/helpers/PriceHelper";
import Localizer from "@/localization/Localizer";
import styles from "./InvoiceRow.module.scss";

export interface IInvoiceRowProps {
    item: InvoiceModel;
    supportAttachment: boolean;
    className?: string;
    constructionSiteId: string;
}

interface IInvoiceRowState {
    invoiceRowExpanded: boolean;
}

export default class InvoiceRow extends BaseComponent<IInvoiceRowProps, IInvoiceRowState> {

    public state: IInvoiceRowState = {
        invoiceRowExpanded: false
    };

    public get invoice(): InvoiceModel {
        return this.props.item;
    }

    private get supportAttachments(): boolean {
        return this.props.supportAttachment;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private readonly _invoiceColumns: ColumnDefinition[] = [
        {
            header: Localizer.invoiceRowDeviceIdLanguageItemName,
            accessor: "inventoryItemId",
            editable: false
        },
        {
            header: Localizer.invoiceRowDescriptionLanguageItemName,
            accessor: "name",
            editable: false
        },
        {
            header: Localizer.invoiceRowStartLanguageItemName,
            accessor: "rentedFrom",
            editable: false,
            transform: (cell, value) => (value) ? `${Utility.toDateString(value)}` : "",

        },
        {
            header: Localizer.invoiceRowEndLanguageItemName,
            accessor: "rentedTo",
            editable: false,
            transform: (cell, value) => (value) ? `${Utility.toDateString(value)}` : "",
        },
        {
            header: Localizer.invoiceRowRentalDaysLanguageItemName,
            accessor: "rentalDays",
            editable: false,
            init: (cell) => this.initInvoiceRow(cell),
        },
        {
            header: Localizer.invoiceRowQuantityLanguageItemName,
            accessor: "quantity",
            editable: false,
            init: (cell) => this.initInvoiceRow(cell),
        },
        {
            header: Localizer.invoiceRowUnitPriceLanguageItemName,
            accessor: "unitPrice",
            editable: false,
            init: (cell) => this.initInvoiceRow(cell),
        },
        {
            header: Localizer.invoiceRowDiscountPercentageLanguageItemName,
            accessor: "discountPercent",
            editable: false,
            transform: (cell, value) => (value) ? `${value} %` : "",
            init: (cell) => this.initInvoiceRow(cell),
        },
        {
            header: Localizer.invoiceRowNetAmountLanguageItemName,
            accessor: "netAmount",
            type: ColumnType.Number,
            editable: false,
            init: (cell) => this.initInvoiceRow(cell),
            settings: {
                step: 0.01
            },
        }
    ];

    private readonly _accordionRef: React.RefObject<Accordion> = React.createRef();

    private async previewAndDownloadInvoicePdf(invoice: InvoiceModel): Promise<void> {
        const request: GetInvoiceDocumentRequest = {
            constructionSiteId: this.props.constructionSiteId,
            invoiceNumber: invoice.invoiceNumber,
            invoicePdfLink: invoice.invoicePdfLink
        };
        await ch.documentPreviewAsync("/api/ConstructionSites/GetInvoicePdf", request);
    }

    private initInvoiceRow(cell: CellModel<InvoiceRowModel>): void {
        cell.readonly = true;
        cell.readonly = true;
        if (cell.model.rowType === InvoiceRowType.Info) {
            cell.visible = false;
        }
    }

    private renderInvoiceHeader(invoice: InvoiceModel): React.ReactNode {

        return (
            <div className="col-md-12">

                <TwoColumns className={styles.noBottomMargin}>

                    <TwoColumns className={styles.noBottomMargin}>

                        <div id="invoice_header_invoice_number" className={styles.invoiceHeaderRow}>
                            {Localizer.invoiceHeaderInvoiceNumber}:
                        </div>

                        <div className={this.css(styles.invoiceHeaderRow, styles.dinProMediumStyle)}>
                            {invoice.invoiceNumber}
                        </div>

                    </TwoColumns>

                    <TwoColumns className={styles.noBottomMargin}>

                        <div id="invoice_header_due_date" className={styles.invoiceHeaderRow}>
                            {Localizer.invoiceHeaderInvoiceDueDate}:
                        </div>

                        <div className={this.css(styles.invoiceHeaderRow, styles.dinProMediumStyle)}>
                            {Utility.toDateString(invoice.invoiceDueDate)}
                        </div>

                    </TwoColumns>

                </TwoColumns>

                <TwoColumns className={styles.noBottomMargin}>

                    <TwoColumns className={styles.noBottomMargin}>

                        <div id="invoice_header_type" className={styles.invoiceHeaderRow}>
                            {Localizer.invoiceHeaderInvoiceType}:
                        </div>

                        <div className={this.css(styles.invoiceHeaderRow, styles.dinProMediumStyle)}>
                            {invoice.invoiceType === InvoiceType.Credit
                                ? `${Localizer.invoiceRowInvoiceTypeCredit}`
                                : `${Localizer.invoiceRowInvoiceTypeNormal}`}
                        </div>

                    </TwoColumns>

                    <TwoColumns className={styles.noBottomMargin}>

                        <div id="invoice_header_total_amount" className={styles.invoiceHeaderRow}>
                            {Localizer.invoiceHeaderInvoiceTotalAmount}:
                        </div>

                        <div className={this.css(styles.invoiceHeaderRow, styles.dinProMediumStyle)}>
                            {Utility.formatValue(invoice.totalAmount, "0.00")} {PriceHelper.environmentCurrencyCode}
                        </div>

                    </TwoColumns>

                </TwoColumns>

                <TwoColumns className={styles.noBottomMargin}>

                    <TwoColumns className={styles.noBottomMargin}>

                        <div id="invoice_header_date" className={styles.invoiceHeaderRow}>
                            {Localizer.invoiceHeaderInvoiceDate}:
                        </div>

                        <div className={this.css(styles.invoiceHeaderRow, styles.dinProMediumStyle)}>
                            {Utility.toDateString(invoice.invoiceDate)}
                        </div>

                    </TwoColumns>

                    <TwoColumns className={styles.noBottomMargin}/>

                </TwoColumns>

                <TwoColumns className={styles.noBottomMargin}>

                    <TwoColumns className={styles.noBottomMargin}>

                        <div className={styles.invoiceHeaderRow}>
                            {Localizer.invoiceHeaderInvoiceReference}:
                        </div>

                        <div className={this.css(styles.invoiceHeaderRow, styles.dinProMediumStyle)}>
                            {invoice.customerReference}
                        </div>

                    </TwoColumns>

                    <TwoColumns className={styles.noBottomMargin}/>

                </TwoColumns>

            </div>
        );
    }

    private renderInvoiceDetails(invoice: InvoiceModel): React.ReactNode {

        return (
            <div>
                <div className="row">

                    <div className={this.css(styles.dinProMediumStyle, "col-md-6")}>
                        <h4>
                            {
                                Localizer.get(Localizer.invoiceRowTitle, invoice.invoiceNumber)
                            }
                        </h4>
                    </div>

                    <div className="col-md-6 text-right">
                        {
                            (this.supportAttachments) &&
                            (
                                <Button label={Localizer.formInputFilePreview}
                                        className={"textRight"}
                                        type={ButtonType.Primary}
                                        icon={{name: "fa fa-download", size: IconSize.Large}}
                                        onClick={async () => await this.previewAndDownloadInvoicePdf(invoice)}
                                />
                            )
                        }
                    </div>

                </div>

                <Grid responsive
                      version2Styles
                      id={"construction_site_invoices_row_details"}
                      columns={this._invoiceColumns}
                      className={styles.customFontGrid}
                      data={invoice.rows}
                      noDataText={Localizer.invoiceRowNoData}
                      onRowToggle={async () => await this._accordionRef.current?.recalculateContentHeight()}
                />

                <div className={styles.invoiceRowsSummary}>
                    <ThreeColumns>

                        <div>
                            <div>
                                {
                                    Localizer.invoiceRowWithoutTaxAmount
                                }
                            </div>

                            <div className={styles.dinProMediumStyle}>
                                {
                                    Utility.formatValue(invoice.netAmount, "0.00")
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                {
                                    Localizer.invoiceRowTaxAmount
                                }
                            </div>

                            <div className={styles.dinProMediumStyle}>
                                {
                                    Utility.formatValue(invoice.taxAmount, "0.00")
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                {
                                    Localizer.get(Localizer.invoiceRowTotalAmount, PriceHelper.environmentCurrencyCode)
                                }
                            </div>

                            <div className={styles.dinProMediumStyle}>
                                {
                                    Utility.formatValue(invoice.totalAmount, "0.00")
                                }
                            </div>
                        </div>

                    </ThreeColumns>
                </div>
            </div>
        );
    }

    private setToggle(expanded: boolean): void {
        if (expanded && !this.state.invoiceRowExpanded) {
            this.setState({invoiceRowExpanded: true});
            setTimeout(async () => await this._accordionRef.current!.recalculateContentHeight(), 20);
        }
    }

    render(): React.ReactNode {
        return (
            <PageRow className={styles.invoiceDetailsRow}>
                <Accordion toggler
                           ref={this._accordionRef}
                           togglerPosition={TogglerPosition.Bottom}
                           togglerIcon={"angle-down"}
                           togglerSize={IconSize.X3}
                           classNames={{accordion: styles.accordion, toggler: styles.toggler, header: styles.accordionHeader, contentContainer: styles.accordionContent}}
                           header={this.renderInvoiceHeader(this.invoice)}
                           maxHeightOffset={100}
                           onToggle={async (_, expanded) => {
                               this.setToggle(expanded);
                           }}
                >

                    {
                        this.renderInvoiceDetails(this.invoice)
                    }

                </Accordion>

            </PageRow>

        );
    }
};