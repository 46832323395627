import React, {lazy} from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {DateRangeInput} from "@renta-apps/athenaeum-react-components";

import EnvironmentSensorReading from "@/models/server/EnvironmentSensorReading";

import styles from "@/components/EnvironmentSensorChart/EnvironmentSensorChart.module.scss";
import Localizer from "@/localization/Localizer";
import {EChartsOption} from "echarts";

const ReactEcharts = lazy(() => import('echarts-for-react'));

interface IEnvironmentSensorChartProps {
    sensorData: EnvironmentSensorReading[];

    multiValueSensor: boolean;

    sensorName: string | null;
    deviceId: string | null;
    unit: string;
    sensorId: number;

    fromLimit: Date | null;

    toLimit: Date | null;

    className?: string;

    onDateRangeChangeAsync(deviceId: string, sensorId: number, from: Date, to: Date): Promise<void>;
}

enum TimeFilterType {
    Day,
    Week,
    Month,
    Custom
}

interface IEnvironmentSensorChartState {
    activeFilter: TimeFilterType;
    shoDateRangeInput: boolean;
    dateRangeFrom: Date | null;
    dateRangeTo: Date | null;
}

export default class EnvironmentSensorChart extends BaseComponent<IEnvironmentSensorChartProps, IEnvironmentSensorChartState> {

    public state: IEnvironmentSensorChartState = {
        activeFilter: TimeFilterType.Day,
        shoDateRangeInput: false,
        dateRangeFrom: new Date().addDays(-7),
        dateRangeTo: new Date()

    };

    private get getMultiValueOptionsForParticleMass(): EChartsOption {

        return {
            tooltip: {
                valueFormatter: (value: any) => `${value} µg/m³`,
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'time',
                axisLabel: {
                    formatter: (this.state.activeFilter === TimeFilterType.Day)
                        ? '{HH}:{mm}'
                        : '{MM}-{dd}',
                    rotate: this.mobile ? 45 : 0
                },
            },
            legend: {
                data: ['pm01', 'pm03', 'pm05', 'pm1', 'pm5', 'pm10', 'pm25']
            },
            yAxis:
                {
                    type: 'value',
                },
            series: [
                {
                    name: "pm01",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm01 ? data.values.pm01 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pm03",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm03 ? data.values.pm03 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pm05",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm05 ? data.values.pm05 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pm1",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm1 ? data.values.pm1 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pm5",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm5 ? data.values.pm5 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pm10",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm10 ? data.values.pm10 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pm25",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pm25 ? data.values.pm25 : ""]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },

            ]
        };
    }

    private get getMultiValueOptionsForParticleCounts(): EChartsOption {

        return {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'time',
                axisLabel: {
                    formatter: (this.state.activeFilter === TimeFilterType.Day)
                        ? '{HH}:{mm}'
                        : '{MM}-{dd}',
                    rotate: this.mobile ? 45 : 0
                },
            },
            legend: {
                data: ['pc01', 'pc03', 'pc05', 'pc1', 'pc5', 'pc10', 'pc25']
            },
            yAxis:
                {
                    type: 'value',
                },
            series: [
                {
                    name: "pc01",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc01 ? data.values.pc01 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pc03",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc03 ? data.values.pc03 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pc05",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc05 ? data.values.pc05 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pc1",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc1 ? data.values.pc1 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pc5",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc5 ? data.values.pc5 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pc10",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc10 ? data.values.pc10 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: "pc25",
                    data: this.props.sensorData.map(data => [data.timestamp, data.values?.pc25 ? data.values.pc25 : 0]),
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                },

            ]
        };
    }

    private get getSingleValueOptions(): EChartsOption {
        return {
            tooltip: {
                trigger: 'axis',
                formatter: (this.state.activeFilter === TimeFilterType.Day)
                    ? function (param: any) {
                        param = param[0];
                        return (
                            `${(param.data[0] as Date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})} <br/>
                         <b>${param.data[1]}</b>`);
                    } :
                    function (param: any) {
                        param = param[0];
                        return (
                            `${param.data[0].toLocaleDateString([], {hour: '2-digit', minute: '2-digit'})} <br/>
                         <b>${param.data[1]}</b>`);
                    }
            },
            xAxis: {
                type: 'time',
                axisLabel: {
                    formatter: (this.state.activeFilter === TimeFilterType.Day)
                        ? '{HH}:{mm}'
                        : '{MM}-{dd}',
                    rotate: this.mobile ? 45 : 0
                }
            },

            yAxis: {
                min: this.props.sensorData.length > 0 ?
                    Math.round(this.props.sensorData.min(a => a.value!).value! - 2)
                    : 0,
                max: this.props.sensorData.length > 0
                    ? Math.round(this.props.sensorData.max(a => a.value!).value! + 2)
                    : 0,
                position: 'right'
            },
            series: [
                {
                    data: this.props.sensorData.map(data => [data.timestamp, data.value!]),
                    smooth: true,
                    showSymbol: false,
                    type: 'line',
                    lineStyle: {
                        width: 3,
                        color: "#369FF8"
                    }
                }
            ]
        };
    }

    private async onDateRangeChangeAsync(activeFilterType: TimeFilterType, from: Date | null, to: Date | null): Promise<void> {

        if (activeFilterType !== TimeFilterType.Custom) {
            await this.setState({shoDateRangeInput: false});
        }
        else {
            await this.setState({dateRangeFrom: from, dateRangeTo: to});

            await this.setState({shoDateRangeInput: true});

        }
        await this.setState({activeFilter: activeFilterType});

        if (from && to) {
            await this.props.onDateRangeChangeAsync(this.props.deviceId!, this.props.sensorId, from, to);

        }
    }

    private renderButtons(): React.ReactNode {
        return (
            <React.Fragment>
                {
                    this.createButton(TimeFilterType.Day, new Date(), new Date(), Localizer.environmentSensorChart1Day)
                }
                {
                    this.createButton(TimeFilterType.Week, new Date().addDays(-7), new Date(), Localizer.environmentSensorChartWeek)
                }
                {
                    this.createButton(TimeFilterType.Month, new Date().addDays(-31), new Date(), Localizer.environmentSensorChartMonth)
                }
                {
                    this.createButton(TimeFilterType.Custom, new Date().addDays(-7), new Date(), Localizer.environmentSensorChartCustom)
                }
            </React.Fragment>
        );
    }

    private createButton(index: TimeFilterType, from: Date, to: Date, label: string): React.ReactNode {
        return (
            <button className={this.css(styles.pillButton, this.state.activeFilter === index ? styles.pillButtonActive : "")}
                    onClick={async (sender) => await this.onDateRangeChangeAsync(index, from, to)}
            >{label.toUpperCase()}
            </button>
        );
    }

    private getLastReadingAt(): string {
        if (this.props.sensorData.length > 0) {
            return this.props.sensorData[this.props.sensorData.length - 1].timestamp.toLocaleString();
        }
        return "-";
    }

    private minValue(): string {
        if (this.props.sensorData.length > 0) {
            if (!this.props.multiValueSensor) {
                return this.props.sensorData.min(item => item.value!).value!.toString();
            }
        }
        return "-";
    }

    private maxValue(): string {
        if (this.props.sensorData.length > 0) {
            if (!this.props.multiValueSensor) {
                return this.props.sensorData.max(item => item.value!).value!.toString();
            }
        }
        return "-";
    }

    private latestValue(): string {
        if (this.props.sensorData.length > 0) {
            if (!this.props.multiValueSensor) {
                return this.props.sensorData[this.props.sensorData.length - 1].value!.toString();
            }

        }
        return "-";
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.temperatureChart}>
                <div className={styles.leftPanel}>
                    <div>
                        <h5>{this.props.sensorName?.toUpperCase()}</h5>
                    </div>
                    <div className={styles.buttonContainer}>
                        {
                            this.renderButtons()
                        }
                        {
                            (this.state.shoDateRangeInput) && (
                                <DateRangeInput sameDay clickToEdit
                                                icon={{name: "far calendar", customStyle: {color: "#fe5000"}}}
                                                className={styles.dateRangeInput}
                                                model={{value: [this.state.dateRangeFrom, this.state.dateRangeTo]}}
                                                onChange={async ([start, end]) => await this.onDateRangeChangeAsync(TimeFilterType.Custom, start, end)}
                                />
                            )
                        }

                    </div>
                    {
                        (!this.props.multiValueSensor) && (
                            <div>
                                <h5>{Localizer.environmentSensorChartLastReading.toUpperCase()}</h5>
                                <div className={styles.temperatureDisplay}>
                                    <div className={styles.leftHalf}>

                                        <h2>{this.latestValue()} {this.props.unit}</h2>
                                    </div>
                                    <div className={styles.minMaxContainer}>
                                        <div className={styles.halfContainer}>
                                            <span>{Localizer.environmentSensorChartMax.toUpperCase()}</span>
                                            <h6>{this.maxValue()} {this.props.unit}</h6>
                                        </div>
                                        <div className={styles.halfContainer}>
                                            <span>{Localizer.environmentSensorChartMin.toUpperCase()}</span>
                                            <h6>{this.minValue()} {this.props.unit}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className={styles.lastReadingAt}>
                        {`${Localizer.environmentSensorChartLastReading} ${this.getLastReadingAt()}`}
                    </div>

                </div>
                <div className={styles.chartContainer} id={"emission-chart-container"}>
                    {
                        (this.props.sensorData.length > 0) && (
                            <>
                                {
                                    (this.props.multiValueSensor) && (
                                        <>
                                            <ReactEcharts option={this.getMultiValueOptionsForParticleMass}
                                                          style={{width: '100%'}}
                                                          opts={{renderer: 'canvas'}}
                                            />
                                        </>

                                    )
                                }
                                {
                                    (!this.props.multiValueSensor) && (
                                        <ReactEcharts option={this.getSingleValueOptions}
                                                      style={{width: '100%'}}
                                                      opts={{renderer: 'canvas'}}
                                        />
                                    )
                                }
                            </>

                        )
                    }

                </div>
            </div>
        );
    }
}