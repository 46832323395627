import React from "react";
import AdminPage from "@/models/base/AdminPage";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    ColumnDefinition, ColumnType,
    Dropdown, DropdownOrderBy, DropdownRequiredType, DropdownSelectType,
    Form,
    Grid,
    PageContainer,
    PageHeader,
    TextInput,
    ThreeColumns
} from "@renta-apps/athenaeum-react-components";
import styles from "./AdminAddSupportRequestTypes.module.scss";
import SupportRequestTypeModel from "@/models/server/SupportRequestTypeModel";
import enumProvider from "@/providers/EnumProvider";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import Localizer from "@/localization/Localizer";
import {ActionType, BasePageParameters} from "@renta-apps/athenaeum-react-common";

export interface IAdminAddSupportRequestTypesParams extends BasePageParameters {
}

interface IAdminAddSupportRequestTypesState {
    requestTypes: SupportRequestTypeModel[];
}

export default class AdminAddSupportRequestTypes extends AdminPage<IAdminAddSupportRequestTypesParams, IAdminAddSupportRequestTypesState> {

    state: IAdminAddSupportRequestTypesState = {
        requestTypes: [],
    };

    public addSupportTypeFormRef: React.RefObject<any> = React.createRef();
    private readonly _requestOptionsGrid: React.RefObject<Grid<SupportRequestTypeModel>> = React.createRef();

    private readonly __requestOptionsGridColumns: ColumnDefinition[] = [
        {
            header: Localizer.supportRequestTypeTitle,
            accessor: "requestTypeTitle",
            editable: false,
            sorting: true,
            minWidth: 150,
        },
        {
            minWidth: 150,
            header: Localizer.supportRequestTypeSendRequestTo,
            accessor: "sendRequestTo",
            type: ColumnType.Enum,
            format: "SendRequestTo",
            editable: false,
            sorting: true,
        },
        {
            minWidth: 50,
            visible: true,
            actions: [
                {
                    name: "delete",
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.deleteSupportRequestOptionAsync(cell, action)
                }
            ]
        },
    ];

    private async deleteSupportRequestOptionAsync(cell: CellModel<SupportRequestTypeModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "delete") {

            await this.postAsync(`/api/Application/DeleteSupportRequestType`, cell.model.supportRequestTypeId);

            await cell.grid.deleteAsync(cell.row.index);
        }
    }

    async initializeAsync(): Promise<void> {
        await this.getSupportRequestTypesAsync();
        return super.initializeAsync();
    }

    protected get title(): string {
        return Localizer.supportRequestTypeAddSupportRequestType;
    }

    private async saveSupportRequestTypeAsync(data: Dictionary<string, any>): Promise<void> {

        const request = new SupportRequestTypeModel();
        request.requestTypeTitle = data.getValue("optionTitle");
        request.sendRequestTo = data.getValue("sendRequestTo");

        await this.postAsync(`/api/Application/SaveSupportRequestType`, request);

        await this.getSupportRequestTypesAsync();
    }

    private async getSupportRequestTypesAsync(): Promise<void> {

        const data: SupportRequestTypeModel[] = await this.getAsync("/api/Application/GetAllSupportRequestTypes");

        this.setState({requestTypes: data});
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.addSupportRequestTypes}>
                <PageHeader title={Localizer.supportRequestTypeSupportRequestTypes}
                            subtitle={this.title}
                />

                <Form id="supportTypesForm"
                      ref={this.addSupportTypeFormRef}
                      onSubmit={async (_, data) => await this.saveSupportRequestTypeAsync(data)}
                >
                    {
                        <ThreeColumns>
                            <TextInput required
                                       id="optionTitle"
                                       className={styles.textBox}
                                       label={Localizer.supportRequestTypeTitle}
                            />

                            <Dropdown required
                                      requiredType={DropdownRequiredType.Restricted}
                                      selectType={DropdownSelectType.Checkbox}
                                      id="sendRequestTo"
                                      orderBy={DropdownOrderBy.None}
                                      className={styles.dropdown}
                                      label={Localizer.supportRequestTypeSendRequestTo}
                                      items={enumProvider.getSendRequestToItems()}
                            />

                            <Button submit
                                    id="saveRequestType"
                                    className={styles.button}
                                    label={Localizer.genericSave}
                                    type={ButtonType.Orange}
                                    icon={{name: "save"}}
                            />
                        </ThreeColumns>
                    }
                </Form>

                <Grid version2Styles responsive
                      id="requestTypeTable"
                      ref={this._requestOptionsGrid}
                      columns={this.__requestOptionsGridColumns}
                      noDataText={Localizer.dropdownNoData}
                      data={this.state.requestTypes}
                />

            </PageContainer>

        );
    }
}