import PageDefinitions from "../../providers/PageDefinitions";
import UserModel from "@/models/server/UserModel";
import {ApiProvider, BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "@/models/base/AnonymousPage";

// 15 minutes
const ContextReloadInterval: number = 15 * 60000;

export default abstract class AuthorizedPage<TParams extends BasePageParameters = {}, TState = {}>
    extends AnonymousPage<TParams, TState> {

    protected get user(): UserModel {
        return this.userContext.user!;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.isAuthorized) {
            const userContext = this.userContext;
            if (userContext?.user?.id && (!userContext.dateTime || new Date(userContext.dateTime) < new Date((new Date()).getTime() - ContextReloadInterval))) {
                await ApiProvider.postAsync("/api/Application/ReloadUserContext", this.getPage().route, this);
            }
        } else {
            // NOTE: this does NOT prevent the execution of the inheritors render, which can cause unauthorized access errors when trying to access pages with components which load data from Admin endpoints.

            await PageRouteProvider.redirectAsync(PageDefinitions.login.route({params: {redirectTo: this.getPage().route}}),
                true,
                true);
        }
    }
}