import React from "react";
import styles from "@/components/PricingTool/PricingToolStatusLog/PricingToolStatusLog.module.scss";
import Localizer from "@/localization/Localizer";
import PricingToolDiscountModel from "@/models/server/Models/PricingTool/PricingToolDiscountModel";

type PricingToolDiscountDetailsProps = {
    type: 'discount' | 'template';
    discount?: PricingToolDiscountModel;
};

const PricingToolDiscountDetails: React.FC<PricingToolDiscountDetailsProps> = ({ type, discount }) => {
    return (
        <div id="discountDetailsContainer">
            {type === 'discount' && (
                <>
                    <div className={styles.row} id={"organizationContractName"}>
                        <div className={styles.labelColumn} >
                            {Localizer.genericOrganizationContract}
                        </div>
                        <div className={styles.valueColumn} >
                            {discount?.organizationContract?.name || "-"}
                        </div>
                    </div>
                    <div className={styles.row} id={"constructionSiteName"}>
                        <div className={styles.labelColumn} >
                            {Localizer.genericConstructionSite}
                        </div>
                        <div className={styles.valueColumn} >
                            {discount?.constructionSite?.name || "-"}
                        </div>
                    </div>
                </>
            )}
            <div className={styles.row} id={"discountName"}>
                <div className={styles.labelColumn} >
                    {Localizer.pricingToolDiscountName}
                </div>
                <div className={styles.valueColumn} >
                    {discount?.discountName || "-"}
                </div>
            </div>
            <div className={styles.row}  id={"overallDiscuntPercentage"}>
                <div className={styles.labelColumn}>
                    {Localizer.pricingToolOverallDiscount}
                </div>
                <div className={styles.valueColumn} >
                    {(discount?.overallDiscountPercentage ?? 0) + " %"}
                </div>
            </div>
            {type === 'discount' && (
                <>
                    <div className={styles.row} id={"discountTimeLimitStart"} >
                        <div className={styles.labelColumn}>
                            {Localizer.pricingToolTimeStart}
                        </div>
                        <div className={styles.valueColumn} >
                            {discount?.discountTimeLimitStart ? discount?.discountTimeLimitStart.toLocaleDateString() : "-"}
                        </div>
                    </div>
                    <div className={styles.row} id={"discountTimeLimitEnd"} >
                        <div className={styles.labelColumn}>
                            {Localizer.pricingToolTimeEnd}
                        </div>
                        <div className={styles.valueColumn} >
                            {discount?.discountTimeLimitEnd ? discount?.discountTimeLimitEnd.toLocaleDateString() : "-"}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default PricingToolDiscountDetails;