import Localizer from "../../../localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";

// @ts-ignore
describe('Desktop - Authenticated construction sites map tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('Opens admin construction sites map page, tests that construction sites grid search works and is preserved on back navigation', () => {
        pageData().common.googleMapAPI().as('mapsAPI');
        cy.intercept('POST', '/api/ConstructionSites/Locations').as('locations');
        cy.intercept('POST', '/api/ConstructionSites/GetPagedConstructionSites').as('pagedSites');

        cy.visit(Localizer.pageRoutesAdminConstructionSitesMap);
        pageData().common.waitTopNavRender();

        cy.waitMultipleSuccess(['@mapsAPI', '@locations', '@pagedSites'])

        cy.testConstructionSitesGridSearchPreservation('test');
    });

    it('Opens admin construction sites map page with grid parameters in URL on correct page with correct sorting', () => {
        pageData().common.googleMapAPI().as('mapsAPI');
        cy.intercept('POST', '/api/ConstructionSites/GetPagedConstructionSites').as('pagedSites');
        cy.intercept('POST', '/api/ConstructionSites/Locations').as('locations');

        cy.visit(`${Localizer.pageRoutesAdminConstructionSitesMap}?pageSize=3&pageNumber=3&sortColumn=ownerName&sortDir=0`);
        pageData().common.waitTopNavRender();

        cy.waitMultipleSuccess(['@mapsAPI', '@locations', '@pagedSites'])

        cy.get('th[data-sort-direction="0"]')
            .find('div > span')
            .should('have.text', Localizer.rolesGroupCompany);

        cy.wait(500)

        cy.get('.page-item.active')
            .find('a')
            .should('have.text', '3');
    });
});