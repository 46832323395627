import React, {useState, useEffect, useRef} from 'react';
import {ch} from "@renta-apps/athenaeum-react-common";

interface NotFoundGameProps {
    mainSvgImageUrl: string;
    obstacleImageUrl: string;
    truckLeft: string;
    truckRight: string;
    truckUp: string;
    truckDown: string;
}

interface Obstacle {
    x: number;
    y: number;
}

interface BorderObstacle {
    x: number;
    y: number;
    width: number;
    height: number;
}


enum Direction {
    None = 'None',
    Up = 'Up',
    Down = 'Down',
    Left = 'Left',
    Right = 'Right',
}


const NotFoundGame: React.FC<NotFoundGameProps> = ({mainSvgImageUrl, obstacleImageUrl, truckLeft, truckRight, truckUp, truckDown}) => {
    const objectStartPosition = {
        x: 60,
        y: 260,
    };
    const [objectPosition, setObjectPosition] = useState(objectStartPosition);
    const [gameStarted, setGameStarted] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [direction, setDirection] = useState(Direction.None);
    const [currentTruck, setCurrentTruck] = useState(truckRight);
    const [truckDimensions, setTruckDimensions] = useState({width: 85, height: 85});
    const [borderObstacles, setBorderObstacles] = useState<BorderObstacle[]>([]);
    const [obstacles, setObstacles] = useState<Obstacle[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const animationFrameRef = useRef<number | null>(null);
    const [hitCounter, setHitCounter] = useState(0);


    const staticObstacles = [
        {x: 140, y: 100},
        {x: 200, y: 200},
        {x: 340, y: 230},
        {x: 400, y: 400},
        {x: 550, y: 200},
        {x: 750, y: 100},

        // Add more static obstacle positions here
    ];

    const staticBorderObstacles = [
        {x: 0, y: 50, width: 960, height: 10},
        {x: 0, y: 490, width: 960, height: 10},
        {x: 950, y: 0, width: 10, height: 500},
        {x: 0, y: 0, width: 10, height: 500},
        {x: 440, y: 170, width: 80, height: 200},
        {x: 160, y: 210, width: 30, height: 40},
        {x: 730, y: 220, width: 30, height: 40},
        {x: 650, y: 360, width: 130, height: 150},
        {x: 300, y: 100, width: 30, height: 150},
        {x: 290, y: 360, width: 30, height: 150},
    ];
    function resetGame(): void {
        setGameStarted(true);
        setBorderObstacles(staticBorderObstacles);
        setObstacles(staticObstacles);
    }

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            const key = event.key;

            if (!gameStarted && key === 'Enter') {
                resetGame();

            }

            if (gameStarted) {
                if (key === 'ArrowUp' && direction !== Direction.Down) {
                    setDirection(Direction.Up);
                    setCurrentTruck(truckUp);
                    setTruckDimensions({width: 55, height: 75});
                }
                else if (key === 'ArrowDown' && direction !== Direction.Up) {
                    setDirection(Direction.Down);
                    setCurrentTruck(truckDown);
                    setTruckDimensions({width: 55, height: 75});
                }
                else if (key === 'ArrowLeft' && direction !== Direction.Right) {
                    setDirection(Direction.Left);
                    setCurrentTruck(truckLeft);
                    setTruckDimensions({width: 85, height: 85});
                }
                else if (key === 'ArrowRight' && direction !== Direction.Left) {
                    setDirection(Direction.Right);
                    setCurrentTruck(truckRight);
                    setTruckDimensions({width: 85, height: 85});
                }
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            const key = event.key;

            if (gameStarted) {
                if (
                    (key === 'ArrowUp' && direction === Direction.Up) ||
                    (key === 'ArrowDown' && direction === Direction.Down) ||
                    (key === 'ArrowLeft' && direction === Direction.Left) ||
                    (key === 'ArrowRight' && direction === Direction.Right)
                ) {
                    setDirection(Direction.None);
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [gameStarted, direction]);

    useEffect(() => {
        const updateObjectPosition = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const containerHeight = containerRef.current.offsetHeight;

                setObjectPosition((prevPosition) => {
                    const objectX = prevPosition.x;
                    const objectY = prevPosition.y;
                    const speed = 5; // Adjust the speed as desired

                    let newX = objectX;
                    let newY = objectY;

                    if (direction === Direction.Up && newY > 0 && newY - speed >= 0) {
                        newY -= speed;
                    }
                    else if (direction === Direction.Down && newY < containerHeight - 50 && newY + speed <= containerHeight - 50) {
                        newY += speed;
                    }
                    else if (direction === Direction.Left && newX > 0 && newX - speed >= 0) {
                        newX -= speed;
                    }
                    else if (direction === Direction.Right && newX < containerWidth - 50 && newX + speed <= containerWidth - 50) {
                        newX += speed;
                    }

                    const newObjectPosition = {x: newX, y: newY};

                    // Check for collisions with each obstacle
                    const newObstacles = obstacles.filter(obstacle => {
                        const truckSize = truckDimensions.width; // Assuming the truck is square
                        const obstacleSize = 50;

                        if (
                            newObjectPosition.x < obstacle.x + obstacleSize &&
                            newObjectPosition.x + truckSize > obstacle.x &&
                            newObjectPosition.y < obstacle.y + obstacleSize &&
                            newObjectPosition.y + truckSize > obstacle.y
                        ) {
                            setHitCounter(hitCounter + 1);
                            // A collision has occurred
                            if (hitCounter + 1 === staticObstacles.length) {
                                setGameOver(true);
                            }
                            return false;
                        }

                        return true;
                    });

                    borderObstacles.forEach(obstacle => {
                        const truckSize = truckDimensions.width;

                        if (
                            newObjectPosition.x < obstacle.x + obstacle.width &&
                            newObjectPosition.x + truckSize > obstacle.x &&
                            newObjectPosition.y < obstacle.y + obstacle.height &&
                            newObjectPosition.y + truckSize > obstacle.y
                        ) {
                            setObjectPosition(objectStartPosition);
                            resetGame();
                        }
                    });

                    setObstacles(newObstacles);

                    return newObjectPosition;
                });
            }

            animationFrameRef.current = requestAnimationFrame(updateObjectPosition);
        };

        if (gameStarted && direction !== Direction.None) {
            animationFrameRef.current = requestAnimationFrame(updateObjectPosition);
        }

        if (gameOver) {
            ch.flyoutMessageAsync(
                "Hurray!"
            ).then(value => {
                ch.refresh();
            });

        }

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [gameStarted, gameOver, direction, obstacles, borderObstacles]);

    return (
        <div className="game-container" ref={containerRef}>
            {
                <div style={{visibility: gameStarted ? "visible" : "hidden"}} className="hit-counter">Hits: {hitCounter}</div>
            }
            <svg width="960" height="500">
                <image xlinkHref={mainSvgImageUrl} width="960" height="500"/>
                <image
                    xlinkHref={currentTruck}
                    x={objectPosition.x}
                    y={objectPosition.y}
                    width={truckDimensions.width}
                    height={truckDimensions.height}
                />
                {gameStarted && obstacles.map((obstacle, index) => (
                    <image
                        key={index}
                        xlinkHref={obstacleImageUrl}
                        x={obstacle.x}
                        y={obstacle.y}
                        width="50"
                        height="50"
                    />
                ))}

                {gameStarted && borderObstacles.map((obstacle, index) => (
                    <rect
                        key={index}
                        x={obstacle.x}
                        y={obstacle.y}
                        width={obstacle.width}
                        height={obstacle.height}
                        fill={ch.debug ? "red" : "transparent"}
                    />
                ))}

            </svg>
        </div>
    );
};

export default NotFoundGame;
