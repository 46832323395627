import {pageData} from "@/helpers/CypressHelper";

describe('Desktop - Anonymous catalog HTML headers tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
        
    });

    it('Open catalog page and check page headers', () => {

        pageData().rent.visitInternal()
        
        cy.get('head meta[name="language"]').should('have.attr', 'content', 'fi');
        cy.get('head title').should('contain.text', 'Renta Easy - Vuokraa');
        cy.get('head meta[name="title"]').should('have.attr', 'content', 'Renta Easy - Vuokraa');
        cy.get('head meta[name="keywords"]').should('have.attr', 'content', 'Työkoneet, CategoryWithSubCategory, Nostimet, Työmaa tarvikkeet');
        
        pageData().rent.firstCategoryItem().click();

        cy.get('head meta[name="language"]').should('have.attr', 'content', 'fi');
        cy.get('head title').should('contain.text', 'Renta Easy - Vuokraa - Työkoneet');
        cy.get('head meta[name="title"]').should('have.attr', 'content', 'Renta Easy - Vuokraa - Työkoneet');
        cy.get('head meta[name="keywords"]').should('have.attr', 'content', 'Työkoneet, Pyöräkuormaajat, Maantiivistäjät, Vaihtolavat, Rakennuslaitteet, Kaivukoneet');
        cy.get('head link[rel="canonical"]').invoke('attr', 'href').should('contain', '/vuokraa/tyokoneet');

        pageData().rent.firstCategoryItem().click();

        cy.get('head meta[name="language"]').should('have.attr', 'content', 'fi');
        cy.get('head title').should('contain.text', 'Renta Easy - Vuokraa - Pyöräkuormaajat');
        cy.get('head meta[name="title"]').should('have.attr', 'content', 'Renta Easy - Vuokraa - Pyöräkuormaajat');
        cy.get('head meta[name="keywords"]').should('have.attr', 'content', 'Pyöräkuormaajat, Kuormaaja 300s, Pyöräkuormaaja 450D, Pyöräkuormaaja 120D, Pyöräkuormaaja 430D, Kuormaaja 200s, Kuormaaja 100s, Kuormaaja 400s');
        cy.get('head link[rel="canonical"]').invoke('attr', 'href').should('contain', '/vuokraa/pyorakuormaajat');
        
        pageData().rent.getProductLink(0).click();

        cy.get('head meta[name="language"]').should('have.attr', 'content', 'fi');
        cy.get('head title').should('contain.text', 'Renta Easy - Kuormaaja 300s');
        cy.get('head meta[name="title"]').should('have.attr', 'content', 'Renta Easy - Kuormaaja 300s');
        cy.get('head meta[name="keywords"]').should('have.attr', 'content', 'Pyöräkuormaajat, Työkoneet, Kuormaaja 300s');
        cy.get('head link[rel="canonical"]').invoke('attr', 'href').should('contain', '/tuote/kuormaaja-300');
        cy.get('head meta[name="description"]').should('have.attr', 'content', "Iso kuormaaja");
    });
    
});