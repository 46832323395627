import React from "react";
import styles from "./RequestReturnModal.module.scss";

type ContentBoxProps = {
    title: string;
    subTitle: string;
    className?: string;
    children: React.ReactNode;
}

const ContentBox: React.FC<ContentBoxProps> = ({title, subTitle, children, className}) => {
    return (
        <div className={`${styles.contentBox} ${className ?? ""}`}>
            <div className={styles.content}>
                <div className={styles.headerSection}>
                    <div className={styles.header}>{title}</div>
                    <div className={styles.subheader}>{subTitle}</div>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default ContentBox;
