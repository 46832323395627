import Localizer from "@/localization/Localizer";
import { onRedirect } from "@/helpers/CypressHelper";

describe('Desktop - Authenticated construction site details tests', () => {

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

        cy.visit('/');
    });

    it('Should display page with construction site access friendly error', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee592`));

        cy.get('h1')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedTitle);

        cy.get('div')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedDescription);

        cy.get('div')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedWhatNext);

        cy.get('li')
            .should('contain', Localizer.genericTryLoggingInAgain);

        cy.get('a')
            .should('contain', Localizer.genericContactSupport);

    });

    it('Should display page with construction site details', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`));

        cy.get('h1')
            .should('contain', 'TMI JUKKA WILSKA');

        cy.get('h2')
            .should('contain', 'TESTITILAUS RENTA EASY');
    });

});