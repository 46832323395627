import {ServiceType} from "../Enums";
import InputGroup from "@/models/server/InputGroup";

export default class CreateServiceRequestDefinitionRequest {

    public serviceType: ServiceType = ServiceType.Custom;

    public name: string = "";

    public icon: string | null = null;

    public receivers: string | null = null;

    public sendToUsersFavoriteDepot: boolean = false;

    public inputGroups: InputGroup[] = [];

    public isDeleted: boolean = false;

    public isVisible: boolean = false;
}