export default class Cloner {

    // noinspection JSUnusedLocalSymbols - This class is "static", and should not be instantiated.
    private constructor() {
    }

    /**
     * Create a deep clone of a value.
     * @param obj Value to clone.
     */
    public static clone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }
}