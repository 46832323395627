describe("Health check tests", () => {
  it("All controllers should be possible to resolve from dependency injection", () => {
    cy.request({
      method: "GET",
      url: "/api/Health/DependencyInjection",
      failOnStatusCode: false,
    }).then((response) => {
      expect(response.status).to.eq(200);
      expect(response.body?.error ?? null).to.eq(null);
    });
  });
});