import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

export const handleConstructionSiteLinkClick = (constructionSiteId: string | null | undefined) => {
    if (!constructionSiteId) {
        return;
    }
    PageRouteProvider.redirectAsync(PageDefinitions.constructionSite.route({
        params: {
            id: constructionSiteId
        }
    }));
};

export const handleCompanyLinkClick = (contractId: string | null | undefined) => {
    if (!contractId) {
        return;
    }
    PageRouteProvider.redirectAsync(PageDefinitions.contractDetails.route({
        params: {
            id: contractId
        }
    }));
};

export const handleDeviceDetailsLinkClick = async (rentaId: string) => {
    await PageRouteProvider.redirectAsync(PageDefinitions.deviceDetailsPage.route({
        params: {
            rentaId: rentaId,
        }
    })
    );
};

export const handleProductLinkClick = async (url: string) => {
    await PageRouteProvider.redirectAsync(PageDefinitions.productDetails.route({
        id: url,
    }));
};