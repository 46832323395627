import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import {IBaseWidgetClassNames, IconSize, PageContainer, RouteWidget, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import styles from "@/pages/Frontpage/Frontpage.module.scss";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

export interface ISalesToolsParams extends BasePageParameters {
}

interface IISalesToolsState {

}

export default class SalesTools extends AnonymousPage<ISalesToolsParams, IISalesToolsState> {

    public state: IISalesToolsState = {};

    private static get linkWidgetClassNames(): IBaseWidgetClassNames {
        return {
            description: styles.linkWidgetDescription
        };
    }

    private renderSalesToolLinks(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderPlansLink()}
                {this.renderPricingToolLink()}
            </React.Fragment>
        );
    }

    private renderPlansLink(): React.ReactNode {

        if (!this.isAdmin) {
            return;
        }

        return (
            <FeatureSwitch flagName={RentaEasyConstants.featureFlagPlansFrontPageLink}>
                <RouteWidget minimized wide
                             classNames={SalesTools.linkWidgetClassNames}
                             route={PageDefinitions.plans.route()}
                             label={Localizer.breadCrumbPlans}
                             icon={{name: "calendar", size: IconSize.X3}}
                />
            </FeatureSwitch>

        );
    }

    private renderPricingToolLink(): React.ReactNode {

        if (!this.isAdmin) {
            return;
        }

        return (

            <FeatureSwitch flagName={RentaEasyConstants.featureFlagPricingTool}>
                <RouteWidget minimized wide
                             classNames={SalesTools.linkWidgetClassNames}
                             route={PageDefinitions.pricingTool.route()}
                             label={Localizer.breadCrumbPricingTool}
                             icon={{name: "euro-sign", size: IconSize.X3}}
                />
            </FeatureSwitch>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.frontpage} alertClassName={styles.alert}>

                <WidgetContainer className={styles.widgetContainer}>

                    {
                        (
                            <React.Fragment>
                                {
                                    (this.isAdmin) &&
                                    (
                                        this.renderSalesToolLinks()
                                    )
                                }
                            </React.Fragment>
                        )
                    }

                </WidgetContainer>

            </PageContainer>
        );
    }

    protected get title(): string {
        return "";
    }
}