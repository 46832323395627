import React, {useEffect, useState} from "react";
import {Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import styles from "./EasyPlusConfirmationModal.module.scss";
import {Button, ButtonSize, ButtonType, Checkbox} from "@renta-apps/renta-react-components";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";
import ReactMarkdown from "react-markdown";

interface EasyPlusConfirmationModalProps {
    isOpen: boolean;
    userFullName: string;
    onClose(confirmed: boolean): Promise<void>;
    onTermsRead(): Promise<void>;
    termsRead: boolean;
}

const EasyPlusConfirmationModal: React.FC<EasyPlusConfirmationModalProps> = ({isOpen, userFullName, onClose, onTermsRead, termsRead}) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();
    const _termsRef: React.RefObject<HTMLDivElement> = React.createRef();

    const [isAccepted, setIsAccepted] = useState(false);
    const [scrolledDown, setScrolledDown] = useState(termsRead);

    useEffect(() => {
        if (isOpen && _termsRef.current) {
            setTimeout(() => {
                if (_termsRef.current && _termsRef.current.clientHeight === _termsRef.current.scrollHeight) {
                    setScrolledDown(true);
                }
            }, 50);
        }
    }, [_termsRef, isOpen]);

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);

    const close = async (confirmed: boolean) => {
        setIsAccepted(false);
        setScrolledDown(termsRead);
        await onClose(confirmed);
    };

    const handleTermsScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (scrolledDown || termsRead) {
            return;
        }

        const target = event.target as HTMLDivElement;
        if (target.scrollHeight - target.scrollTop - target.clientHeight <= 1) {
            setScrolledDown(true);
            onTermsRead().catch();
        }
    };

    return isOpen ? (
        <Modal id="easy-plus-confirmation-modal"
               ref={_modalRef}
               preventEsc preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.componentConfirmationDialogAreYouSure}
               className={styles.modal}
               onClose={async () => await close(false)}
        >
            <div className={styles.easyPlusConfirmation}>
                <div className={styles.easyPlusConfirmationContent}>
                    <div>
                        {Localizer.get(Localizer.easyPlusConfirmationDialogAreYouSure, userFullName)}
                        &nbsp;
                        <EasyPlusLink>{Localizer.easyPlusInfoPanelReadMore}</EasyPlusLink>
                    </div>
                    <div className={styles.easyPlusTerms}
                         onScroll={(event) => handleTermsScroll(event)}
                         ref={_termsRef}
                         data-cy="easy-plus-terms"
                    >
                        <div>
                            <ol className={styles.termsList}>
                                <ReactMarkdown>
                                    {Localizer.easyPlusConfirmationDialogTermsAndCondtionsContent}
                                </ReactMarkdown>
                            </ol>
                        </div>
                    </div>
                    <div>
                        {(!termsRead && !scrolledDown) && (
                            <div className={styles.scrollDown}>
                                {Localizer.easyPlusConfirmationDialogScrollToEnd}
                            </div>
                        )}
                        <Checkbox id="accept-checkbox"
                                  className={styles.acceptCheckbox}
                                  checked={isAccepted}
                                  onChange={(value: boolean) => setIsAccepted(value)}
                                  label={Localizer.easyPlusConfirmationDialogAcceptTermsAndCondtions}
                                  disabled={!termsRead && !scrolledDown}
                        />
                    </div>
                </div>
                <div className={styles.easyPlusConfirmationButtons}>
                    <Button onClick={() => close(false)}
                            size={ButtonSize.Default}
                            type={ButtonType.Secondary}
                            className={styles.button}
                    >
                        {Localizer.formCancel}
                    </Button>
                    <Button onClick={() => close(true)}
                            size={ButtonSize.Default}
                            type={ButtonType.Primary}
                            className={styles.button}
                            disabled={!isAccepted}
                            id="activate-easy-plus-button"
                    >
                        {Localizer.easyPlusConfirmationDialogActivate}
                    </Button>
                </div>
            </div>
        </Modal>
    ) : null;
}

export default EasyPlusConfirmationModal;