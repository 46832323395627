import React from "react";
import {ComponentHelper} from "@renta-apps/athenaeum-react-common";
import {Helmet} from "react-helmet";

export type CustomHtmlHeaders = {
    [key: string]: {
        type: "script" | "link" | "meta",
        value: string
    }
}

export class HtmlHeadHelper {
    
    public static getTitle = (title?: string): string => {
        return `Renta Easy - ${title}`;
    }

    public static renderHead = (title: string, keywords: string = "", description: string = "", customHeaders: CustomHtmlHeaders = {}): React.ReactNode => {
        const country = ComponentHelper.country ?? "";
        return (
            <Helmet>
                {(country.length) && (
                    <meta name={"language"} content={country}/>
                )}
                {(title.length) && (
                    <title>{HtmlHeadHelper.getTitle(title)}</title>
                )}
                {(title.length) && (
                    <meta name="title" content={HtmlHeadHelper.getTitle(title)}/>
                )}
                {(description.length) && (
                    <meta name="description" content={description}/>
                )}
                {(keywords.length) && (
                    <meta name="keywords" content={keywords}/>
                )}
                {Object.keys(customHeaders).map(key => {
                    switch (customHeaders[key].type) {
                        case "script":
                            return (<script className={key} type="application/ld+json" key={key}>{customHeaders[key].value}</script>)
                        case "link":
                            return (<link rel={key} href={customHeaders[key].value} key={key}></link>)
                        case "meta":
                            return (<meta name={key} content={customHeaders[key].value} key={key}/>)
                        default:
                            return (<></>)
                    }
                })}
            </Helmet>
        );
    }
    
    public static renderHtml = (lang: string): React.ReactNode => {
        return (
            <Helmet>
                <html lang={lang} />
            </Helmet>
        );
    };
}