import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";

describe('Desktop - Fleet Monitoring - Filters tests - product group', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('should display product group search input', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.productGroups?.[0].id === '77667') {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getViewControlFilter(3)
            .find('label')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsProductGroup);

        DeviceGridHelper.getViewControlFilter(3)
            .find('#filter-by-product-group')
            .should('not.be.disabled');

        DeviceGridHelper.getViewControlFilter(3)
            .find('#filter-by-product-group')
            .type('vaihtolava');

        DeviceGridHelper.getViewControlFilter(3)
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(2)
            .click();

        cy.wait('@postRequestFiltered').then(({ request }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.nested.property('productGroups[0].id', '77667');
            expect(request.body).to.have.nested.property('productGroups[0].name', 'Vaihtolava 25m (77667)');
            
            DeviceGridHelper.getViewControlFilter(3)
                .find('#filter-by-product-group')
                .should('have.value', 'Vaihtolava 25m (77667)');
        });
    });

    it('should set product group input with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?productGroup=vaihtolava');

        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.getViewControlFilter(3)
                .find('#filter-by-product-group')
                .should('have.value', 'vaihtolava');
        });
    });
});