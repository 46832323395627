import Localizer from "@/localization/Localizer";
import {ch} from "@renta-apps/athenaeum-react-common";

export default class LocalizationHelper {
    // noinspection JSUnusedLocalSymbols - This class is "static", and should not be instantiated.
    private constructor() {
    }

    public static readonly languageCodes = {
        danish: "da",
        english: "en",
        finnish: "fi",
        norwegian: "nb",
        polish: "pl",
        swedish: "sv",
    };

    public static readonly countryCodes = {
        denmark: "dk",
        finland: "fi",
        norway: "no",
        poland: "pl",
        sweden: "sv",
    };

    /**
     * Default language of the current environment.
     */
    public static get environmentDefaultLanguage(): string {
        switch (ch.country) {
            case this.countryCodes.finland:
                return this.languageCodes.finnish;
            case this.countryCodes.sweden:
                return this.languageCodes.swedish;
            case this.countryCodes.norway:
                return this.languageCodes.norwegian;
            case this.countryCodes.denmark:
                return this.languageCodes.danish;
            case this.countryCodes.poland:
                return this.languageCodes.polish;
            default:
                throw new Error(`Unsupported country "${ch.country}"`);
        }
    }

    /**
     * Currently selected language with appended country code.
     * @example fi-fi, da-dk, no-nb
     */
    public static get currentLocale(): string {
        switch (Localizer.language) {
            case this.languageCodes.danish:
                return "da-dk";
            case this.languageCodes.english:
                return "en-gb";
            case this.languageCodes.norwegian:
                return "nb-no";
            case this.languageCodes.polish:
                return "pl-pl";
            case this.languageCodes.swedish:
                return "sv-se";
            default:
                return "fi-fi";
        }
    }

    public static get currentCountryCode(): string {
        switch (ch.country) {
            case this.languageCodes.finnish:
            case this.countryCodes.finland:
                return "fi";

            case this.languageCodes.swedish:
            case this.countryCodes.sweden:
            case "se":
                return "se";

            case "nor":
            case this.languageCodes.norwegian:
            case this.countryCodes.norway:
                return "no";

            case this.languageCodes.danish:
            case this.countryCodes.denmark:
                return "dk";

            case this.languageCodes.polish:
            case this.countryCodes.poland:
                return "pl";
            default:
                throw new Error(`Unsupported country "${ch.country}"`);
        }
    }
}