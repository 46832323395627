import React, {FC} from "react";
import CategoryModel from "@/models/server/CategoryModel";
import {Link} from "@renta-apps/athenaeum-react-components";
import styles from "./../CategoryItem/CategoryItem.module.scss";

export interface CategoryItemProps {
    item: CategoryModel;
    className?: string;
}

const RentaFutureCategoryItem: FC<CategoryItemProps> = (props: CategoryItemProps) => {

    return (
        <Link>
            <div className={styles.item}>
                <div className={`${styles.rentaFutureCategoryItem}  ${props.className}`}>
                    <div className={styles.categoryImg}>
                        <div className={styles.icon}>
                            <img src={'../images/renta-future.png'} alt={"Renta Future"}/>
                        </div>
                    </div>
        
                    <div className={styles.content}>
                            <h2>{props.item.name}</h2>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default RentaFutureCategoryItem;