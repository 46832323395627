// inspired by avoid-overlap https://github.com/kevinschaul/avoid-overlap

import RBush from 'rbush';
import uniqWith from 'lodash.uniqwith';
import {select} from "d3-selection";

export interface Bounds {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface Body {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    initial: Bounds;
    initialCenterX: number;
    movedX?: number;
    movedY?: number;
    node: Element;
    maxMoveX?: number;
    maxMoveY?: number;
}

interface CollisionCandidate {
    a: Body;
    b: Body;
}

export type LabelGroup = {
    nodes: Element[];
    maxDistance?: MaxDistance;
};

type MaxDistance = {
    x: (width: number) => number;
    y: (height: number) => number;
};

type Options = {
    render: Function;
};

const getRelativeBounds = (child: Bounds, parent: Bounds): Bounds => {
    return {
        x: child.x - parent.x,
        y: child.y - parent.y,
        width: child.width,
        height: child.height,
    };
};

const first = <T>(
    array: T[],
    callback: (item: T, index: number) => unknown
): any => {
    for (let i = 0, l = array.length; i < l; i++) {
        const value = callback(array[i], i);
        if (value) {
            return value;
        }
    }

    return null;
};

const all = <T>(
    array: T[],
    callback: (item: T, index: number) => unknown
): any => {
    let ret: any[] = [];
    for (let i = 0, l = array.length; i < l; i++) {
        const value = callback(array[i], i);
        if (value) {
            ret.push(value);
        }
    }

    return ret;
};

const checkOne = (tree: RBush<Body>, body: Body) => {
    const bodies = tree.search(body);

    const checkCollision = (candidate: Body): CollisionCandidate | undefined => {
        if (candidate !== body) {
            return {
                a: body,
                b: candidate,
            };
        }
    };

    return first(bodies, checkCollision);
};

const getCollisions = (tree: RBush<Body>): CollisionCandidate[] => {
    const bodies = tree.all();
    const check = (body: Body) => {
        return checkOne(tree, body);
    };

    const collisions = all(bodies, check);

    return uniqWith(
        collisions,
        (a: CollisionCandidate, b: CollisionCandidate) => {
            return a.a === b.a || a.a === b.b;
        }
    );
};

const updateTree = (tree: RBush<Body>, body: Body, x: number, y: number, movedX?: number, movedY?: number) => {
    const newBody = {
        ...body,
        minX: x,
        minY: y,
        maxX: x + (body.maxX - body.minX),
        maxY: y + (body.maxY - body.minY),
    };
    if (movedX) {
        newBody.movedX = (body.movedX ?? 0) + movedX;
    }
    if (movedY) {
        newBody.movedY = (body.movedY ?? 0) + movedY;
    }

    // Remove the old body, comparing the nodes so that other changes to the body properties will not appear as different bodies
    tree.remove(body, (a: any, b: any) => a.node === b.node);

    tree.insert(newBody);
    return newBody;
};

export class AvoidOverlap {
    run(parent: Element, labelGroup: LabelGroup, options: Options) {
        const tree: RBush<Body> = new RBush();
        const parentBounds = parent.getBoundingClientRect();

        labelGroup.nodes.forEach((node) => {
            const bounds = getRelativeBounds(
                node.getBoundingClientRect(),
                parentBounds
            );
            const {x, y, width, height} = bounds;
            const movedX = -select(node).style('--moveX').match(/([0-9\-.]+)/g)![0];

            tree.insert({
                minX: x,
                minY: y,
                maxX: x + width,
                maxY: y + height + 6,
                maxMoveX: labelGroup.maxDistance?.x(width),
                maxMoveY: labelGroup.maxDistance?.y(height),
                initial: bounds,
                initialCenterX: x + width / 2,
                movedX,
                node,
            });
        });

        const canMoveX = (body: Body, x: number): boolean => {
            const {movedX, minX, maxX, maxMoveX} = body;
            if (maxMoveX && Math.abs((movedX ?? 0) + x) > maxMoveX) {
                return false;
            }
            if (minX + x < 0 || maxX + x > parentBounds.width) {
                return false;
            }
            
            return true;
        };

        const canMoveDown = (body: Body): boolean => {
            const {movedY, maxY, maxMoveY} = body;
            if (!maxMoveY || (movedY && movedY !== 0)) {
                return false;
            }
            if (maxY + maxMoveY > parentBounds.height) {
                return false;
            }
            
            return true;
        };
        
        const updateBodyOnTree = (body: Body, x: number, y: number, diffX: number, diffY: number) => {
            updateTree(tree, body, x, y, diffX, diffY);
        };
        
        const moveEvenly = (bodyA: Body, bodyB: Body): boolean => {
            const [left, right] = bodyA.minX < bodyB.minX ? [bodyA, bodyB] : [bodyB, bodyA];
            const distanceBetweenCenters = right.initialCenterX - left.initialCenterX;
            if (distanceBetweenCenters <= 20) {
                return false;
            }
            const collisionDistance = left.maxX - right.minX + 2;
            const halfDistance = collisionDistance / 2;
            if (!canMoveX(left, -halfDistance) || !canMoveX(right, halfDistance)) {
                return false;
            }
            updateBodyOnTree(left, left.minX - halfDistance, left.minY, -halfDistance, 0);
            updateBodyOnTree(right, right.minX + halfDistance, right.minY, halfDistance, 0);

            return true;
        };
        
        const moveMaxLeft = (bodyA: Body, bodyB: Body): boolean => {
            const [left, right] = bodyA.minX < bodyB.minX ? [bodyA, bodyB] : [bodyB, bodyA];
            const distanceBetweenCenters = right.initialCenterX - left.initialCenterX;
            if (distanceBetweenCenters <= 20) {
                return false;
            }
            const collisionDistance = left.maxX - right.minX + 2;
            const moveLeft = Math.min(collisionDistance, left.maxMoveX ?? Infinity);
            const moveRight = collisionDistance - moveLeft;
            if (!canMoveX(left, -moveLeft) || (moveRight > 0 && !canMoveX(right, moveRight))) {
                return false;
            }
            updateBodyOnTree(left, left.minX - moveLeft, left.minY, -moveLeft, 0);
            if (moveRight > 0) {
                updateBodyOnTree(right, right.minX + moveRight, right.minY, moveRight, 0);
            }

            return true;
        };
        
        const moveMaxRight = (bodyA: Body, bodyB: Body): boolean => {
            const [left, right] = bodyA.minX < bodyB.minX ? [bodyA, bodyB] : [bodyB, bodyA];
            const distanceBetweenCenters = right.initialCenterX - left.initialCenterX;
            if (distanceBetweenCenters <= 20) {
                return false;
            }
            const collisionDistance = left.maxX - right.minX + 2;
            const moveRight = Math.min(collisionDistance, right.maxMoveX ?? Infinity);
            const moveLeft = collisionDistance - moveRight;
            if (!canMoveX(right, moveRight) || (moveLeft > 0 && !canMoveX(left, -moveLeft))) {
                return false;
            }
            updateBodyOnTree(right, right.minX + moveRight, right.minY, moveRight, 0);
            if (moveLeft > 0) {
                updateBodyOnTree(left, left.minX - moveLeft, left.minY, -moveLeft, 0);
            }
            
            return true;
        };

        const moveDown = (bodyA: Body, bodyB: Body): boolean => {
            const bottom = bodyA.minY < bodyB.minY ? bodyB : bodyA;
            const move = bottom.maxMoveY;
            if (!canMoveDown(bottom)) {
                return false;
            }
            updateBodyOnTree(bottom, bottom.minX, bottom.minY + move!, 0, move!);

            return true;
        };
        
        const choices = [moveEvenly, moveMaxLeft, moveMaxRight, moveDown];

        const handleCollision = (response: CollisionCandidate) => {
            for (const choice of choices) {
                const moved = choice(response.a, response.b);
                if (moved) {
                    break;
                }
            }
        };

        let attempts = 0;
        let collisions = getCollisions(tree);
        while (collisions.length && attempts < 200) {
            handleCollision(collisions[Math.floor(Math.random() * collisions.length)]);
            collisions = getCollisions(tree);
            attempts++;
        }
        
        tree.all().forEach((body) => {
            options.render(body.node, body.movedX, body.movedY);
        });
    }
}