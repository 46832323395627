import CreateServiceRequestDefinitionRequest from "@/models/server/CreateServiceRequestDefinitionRequest";

export default class SaveServiceRequestDefinitionRequest extends CreateServiceRequestDefinitionRequest {

    public constructor(request: CreateServiceRequestDefinitionRequest) {
        super();
        this.serviceType = request.serviceType;
        this.icon = request.icon;
        this.inputGroups = request.inputGroups;
        this.isDeleted = request.isDeleted;
        this.name = request.name;
        this.receivers = request.receivers;
        this.sendToUsersFavoriteDepot = request.sendToUsersFavoriteDepot;
        this.isVisible = request.isVisible;
    }

    public id: string = "";
}