import {PlanType} from "../Enums";
import PlanInputGroup from "@/models/server/PlanInputGroup";

export default class CreatePlanDefinitionRequest {

    public planType: PlanType = PlanType.Custom;

    public name: string = "";

    public icon: string | null = null;

    public receivers: string | null = null;

    public sendToUsersFavoriteDepot: boolean = false;

    public inputGroups: PlanInputGroup[] = [];

    public isDeleted: boolean = false;
}