import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";

describe('Desktop - user roles can be changed directly from the admin user management page', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
        cy.visit(Localizer.pageRoutesAdminUserManagement)
    });

    function changeUserRole(indexOfSelectedOption: number, numberOfRolesToChoose: number, isApiCalled: boolean = true) {
        // click the edit role button
        cy.get('[class*=fa-pen-to-square]')
            .click();

        // verify number of available roles
        CypressDropdownHelper.validateNumberOfDropdownOptions("#changeUserRoleDropdown", numberOfRolesToChoose);
        
        // choose the last role
        CypressDropdownHelper.selectDropdownItemByIndex("#changeUserRoleDropdown", indexOfSelectedOption);
        
        if (isApiCalled) {
            executeWithIntercept(() =>
                // submit the role change
                pageData().admin.users.changeUserRoleModalButton().click(),
                [
                    pageData().admin.users.routes.changeUserRole as RouteData
                ]);
        } else {
            // click the submit button won't trigger the api call (the role has been chosen)
            pageData().admin.users.changeUserRoleModalButton().click();
        }
    }
    
    function assertProperRoleIsPresented(expectedRoleName: string) {
        // verify whether the role name has not been updated in grid
        pageData().admin.users.userRoleNameGridCell()
            .should('contain', expectedRoleName);
    }
    
    it('changes the user role to the selected one', () => {
        // expand the user role row
        pageData().admin.users.usersRolesGridFirstRowAction()
            .click();

        changeUserRole(1, 2);

        assertProperRoleIsPresented(Localizer.organizationMainUser);

        // verify whether proper alert is presented
        cy.get('.athenaeum-alert-message')
            .should('contain', Localizer.changeUserRoleMessageSuccessfulChange);

        // revoke the initial role
        changeUserRole(0, 2);
    });

    it('does not change the user role if the same role (to the current one) is selected in drop down', () => {
        // expand the user role row
        pageData().admin.users.usersRolesGridFirstRowAction()
            .click();

        changeUserRole(0, 2, false);

        assertProperRoleIsPresented(Localizer.roleNamesConstructionSiteMainUser);
    });

    it('does not change the user role if the user wants to change the role assigned to her/him', () => {
        // expand the user role row
        pageData().admin.users.usersRolesGridThirdRowAction()
            .click();

        changeUserRole(0, 3, false);

        assertProperRoleIsPresented(Localizer.roleNamesAdmin);
    });
});

export default {};
