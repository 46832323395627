import React from "react";
import {
    Button, ButtonType, Dropdown, DropdownOrderBy, Form, IconSize, Modal, ModalSize,
    PageContainer,
    PageHeader,
    PageRow, SelectListItem, TextInput,
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import Localizer from "@/localization/Localizer";
import styles from "./PricingToolTemplates.module.scss";
import DiscountTemplateInfo from "@/models/server/Models/PricingTool/DiscountTemplateInfo";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import {PricingToolDiscountStatus} from "@/models/Enums";
import CreateDiscountTemplateRequest from "@/models/server/Requests/PricingTool/CreateDiscountTemplateRequest";
import PageDefinitions, {IPageRouteParams} from "@/providers/PageDefinitions";
import ListDiscountsRequest from "@/models/server/Requests/PricingTool/ListDiscountsRequest";
import SingleDiscountRequest from "@/models/server/Requests/PricingTool/SingleDiscountRequest";
import {IPricingToolTemplateParams} from "@/pages/PricingToolTemplate/PricingToolTemplate";


export interface IPricingToolTemplatesParams extends BasePageParameters {
}

interface ITemplatesState {
    discountTemplates: DiscountTemplateInfo[];
    selectedTemplate: SelectListItem | null;
    newTemplateName: string;
}

export default class PricingToolTemplates extends AdminPage<IPricingToolTemplatesParams, ITemplatesState> {

    /***
     * PricingToolTemplates is very similar to PricingToolDiscounts. It can and probably should be merged into one component.
     * Maybe it can be done along with moving to functional components.
     */
    
    public state: ITemplatesState = {
        discountTemplates: [],
        selectedTemplate: null,
        newTemplateName: "",
    };

    protected get title(): string {
        return Localizer.pricingToolPricingToolHeader;
    }

    private readonly _addModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _deleteModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _duplicateModalRef: React.RefObject<Modal> = React.createRef();
    
    protected get breadCrumbs(): BreadCrumbItem[] {
        const salesToolsBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbSalesTools, PageDefinitions.salesTools.route());
        const pricingToolBreadcrumb = new BreadCrumbItem("", Localizer.breadCrumbPricingTool, PageDefinitions.pricingTool.route());
        return [salesToolsBreadcrumb, pricingToolBreadcrumb,];
    }

    public async initializeAsync(): Promise<void> {
        await this.getTemplatesAsync();
        await super.initializeAsync();
    }
    
    private async getTemplatesAsync(): Promise<void> {
        const request: ListDiscountsRequest = new ListDiscountsRequest();

        const templatesList: DiscountTemplateInfo[] = await this.postAsync("/api/PricingTool/ListDiscountTemplates", request);

        if (templatesList) {
            await this.setState({
                discountTemplates: templatesList,
                selectedTemplate: null,
            });
        }
    }

    private async deleteTemplateAsync(templateId: string): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = templateId;
        await this.postAsync("/api/PricingTool/DeleteDiscountTemplate", request);
    }

    private async addTemplateAsync(newTemplateName: string): Promise<void> {
        const request: CreateDiscountTemplateRequest = new CreateDiscountTemplateRequest();
        request.discountName = newTemplateName;
        request.sourceDiscountId = null;
        await this.postAsync("/api/PricingTool/CreateDiscountTemplate", request);
    }
    
    private async duplicateTemplateAsync(templateId: string, newTemplateName: string): Promise<void> {
        const request: CreateDiscountTemplateRequest = new CreateDiscountTemplateRequest();
        request.discountName = newTemplateName;
        request.sourceDiscountId = templateId;
        await this.postAsync("/api/PricingTool/CreateDiscountTemplate", request);
    }
    
    private async setSelectedTemplateAsync(item: SelectListItem | null): Promise<void> {
        this.setState({selectedTemplate: item});
    }

    private async openEditTemplateAsync(): Promise<any> {
        if (this.state.selectedTemplate?.value) {
            const route = PageDefinitions.pricingToolTemplate.route({params: {discountId: this.state.selectedTemplate?.value}} as IPageRouteParams<IPricingToolTemplateParams>);
            await PageRouteProvider.redirectAsync(route);
        }
    }

    private async openDeleteTemplateAsync(): Promise<any> {
        this._deleteModalRef.current?.openAsync();
    }

    private async handleDeleteTemplateSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.selectedTemplate?.value) {
            await this.deleteTemplateAsync(this.state.selectedTemplate.value);
        }
        this._deleteModalRef.current?.closeAsync();
        await this.getTemplatesAsync();
    }

    private async openDuplicateTemplateAsync(): Promise<any> {
        this._duplicateModalRef.current?.openAsync();
    }

    private async handleDuplicateTemplateSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.selectedTemplate?.value) {
            await this.duplicateTemplateAsync(this.state.selectedTemplate.value, this.state.newTemplateName);
        }
        this.setState({newTemplateName: ""});
        this._duplicateModalRef.current?.closeAsync();
        await this.getTemplatesAsync();

    }

    private async openAddNewTemplateAsync(): Promise<any> {
        this.setState({newTemplateName: ""});
        this._addModalRef.current?.openAsync();
    }

    private async handleAddNewTemplateSubmitAsync(confirmed: boolean): Promise<any> {
        if (confirmed && this.state.newTemplateName) {
            await this.addTemplateAsync(this.state.newTemplateName);
        }
        this.setState({newTemplateName: ""});
        this._addModalRef.current?.closeAsync();
        await this.getTemplatesAsync();

    }


    public renderComponents(): React.ReactNode {
        return (
            <div className={styles.menuContainer}>
                <Dropdown id="SelectTemplateDD"
                          className={styles.dropdown}
                          label={Localizer.pricingToolSelectTemplate}
                          minWidth="100px"
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.state.selectedTemplate}
                          items={this.state.discountTemplates.map(template => {
                              return new SelectListItem(template.discountId, template.discountName, PricingToolDiscountStatus[template.status]);
                          }) ?? []}
                          onChange={async (sender, item) => await this.setSelectedTemplateAsync(item)}
                />
                <Button id="EditTemplateBtn"
                        className={styles.button}
                        label={Localizer.pricingToolEditTemplate}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedTemplate}
                        onClick={async () => await this.openEditTemplateAsync()}
                        icon={{name: "file-pen", size: IconSize.X2}}
                />
                {/* TODO: 
                    - Request Approval ?
                    - Approve ?
                */}
                <Button id="DeleteTemplateBtn"
                        className={styles.button}
                        label={Localizer.pricingToolDeleteTemplate}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedTemplate}
                        onClick={async () => await this.openDeleteTemplateAsync()}
                        icon={{name: "file-xmark", size: IconSize.X2}}
                />
                <Button id="DuplicateTemplateBtn"
                        className={styles.button}
                        label={Localizer.pricingToolDuplicateTemplate}
                        type={ButtonType.Orange}
                        disabled={!this.state.selectedTemplate}
                        onClick={async () => await this.openDuplicateTemplateAsync()}
                        icon={{name: "copy", size: IconSize.X2}}
                />
                <Button id="AddNewTemplateBtn" 
                        className={styles.button}
                        label={Localizer.pricingToolCreateTemplate}
                        type={ButtonType.Orange}
                        onClick={async () => await this.openAddNewTemplateAsync()}
                        icon={{name: "file-plus", size: IconSize.X2}}
                />
            </div>
        );
    }
    
    public renderModals(): React.ReactNode {
        return (
            <div>
                <Modal info keepTextFormatting
                       title={Localizer.pricingToolCreateTemplate}
                       ref={this._addModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="addNewTemplateModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <Form onSubmit={async () => await this.handleAddNewTemplateSubmitAsync(true)} >
                            <TextInput id="pricingToolNewTemplateName"
                                       required
                                       label={Localizer.pricingToolNewTemplateName}
                                       value={this.state.newTemplateName ?? ""}
                                       onChange={async (input, value) => this.setState({newTemplateName: value})}
                            />
                            <div className={styles.modalButtons}>
                                <Button
                                    label={Localizer.formCancel}
                                    type={ButtonType.Light}
                                    onClick={async () => await this.handleAddNewTemplateSubmitAsync(false)}
                                />
                                <Button id="confirmaAddTemplateButton"
                                        submit
                                        label={Localizer.formSave}
                                        type={ButtonType.Orange}
                                />
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal info keepTextFormatting
                       title={Localizer.pricingToolDuplicateTemplate}
                       ref={this._duplicateModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="duplicateTemplateModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <Form onSubmit={async () => await this.handleDuplicateTemplateSubmitAsync(true)} >
                            <TextInput id="pricingToolNewTemplateName"
                                       required
                                       label={Localizer.pricingToolNewTemplateName}
                                       value={this.state.newTemplateName ?? ""}
                                       onChange={async (input, value) => this.setState({newTemplateName: value})}
                            />
                            <div className={styles.modalButtons}>
                                <Button
                                    label={Localizer.formCancel}
                                    type={ButtonType.Light}
                                    onClick={async () => await this.handleDuplicateTemplateSubmitAsync(false)}
                                />
                                <Button id="confirmDuplicateTemplateButton"
                                        submit
                                        label={Localizer.formSave}
                                        type={ButtonType.Orange}
                                />
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal info keepTextFormatting
                       title={Localizer.pricingToolDeleteTemplate}
                       ref={this._deleteModalRef}
                       size={ModalSize.Default}
                       className={styles.modal}
                       id="deleteTemplateModal"
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <h6>
                            {Localizer.pricingToolDeleteTemplateConfirmation}
                        </h6>
                        <div className={styles.modalButtons}>
                            <Button
                                label={Localizer.formCancel}
                                type={ButtonType.Light}
                                onClick={async () => await this.handleDeleteTemplateSubmitAsync(false)}
                            />
                            <Button id="confirmDeleteTemplateButton"
                                    label={Localizer.formDelete}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.handleDeleteTemplateSubmitAsync(true)}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.pricingTool}>
                <BreadCrumb items={this.breadCrumbs} />

                <PageHeader title={Localizer.pricingToolTemplates} className={styles.header} />

                <PageRow>
                    <div className="col">
                        <div>
                            {this.renderComponents()}
                        </div>
                    </div>
                </PageRow>

                {this.renderModals()}
            </PageContainer>
        );
    }
}