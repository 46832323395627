import React, {useEffect, useContext, useMemo, useState} from "react";

import {Box, LoadingDecorator, Text} from "@renta-apps/renta-react-components";
import {AddressHelper, GoogleMap} from "@renta-apps/athenaeum-react-components";

import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";

import styles from "../DeviceDetails.module.scss";
import Localizer from "@/localization/Localizer";
import {getDateTimeInLocaleFormat} from "@/helpers/DateHelper";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";

interface IDeviceDetailsLocationProps {
    isLoading: boolean;
}

const DeviceDetailsLocation: React.FC<IDeviceDetailsLocationProps> = (props: IDeviceDetailsLocationProps) => {

    const context = useContext(DeviceDetailsContext);

    const [googleApiRegistered, setGoogleApiRegistered] = useState<boolean>(AddressHelper.isGoogleApiRegistered);

    const [deviceDetails, setDeviceDetails] = useState(context.deviceDetails);


    // Effect to check for Google API registration every 100ms for up to 5 seconds
    useEffect(() => {
        let timePassed = 0;
        const intervalId = setInterval(() => {
            if (AddressHelper.isGoogleApiRegistered) {
                setGoogleApiRegistered(true);
                clearInterval(intervalId); // Stop checking once the API is registered
            } else {
                timePassed += 100; // Add 100ms on each check
                if (timePassed >= 5000) { // Stop after 5 seconds (5000ms)
                    clearInterval(intervalId);
                }
            }
        }, 100); // Check every 100ms

        // Cleanup: clear the interval if the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (context.deviceDetails) {
            setDeviceDetails(context.deviceDetails);
        }
    }, [context.deviceDetails]);


    const easyPlusEnabled = useMemo(() => {
        return (ch.getContext() as UserContext)?.isEasyPlusUser ?? false;
    }, []);

    useEffect(() => {

    }, []);

    return (
        <div className={`${styles.deviceDetailsLocation} ${styles.moduleWrapper}`}>
            <div className={styles.deviceLocationTitle}>
                <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                    {Localizer.deviceDetailsLocationTitle}
                </Text>
                {
                    (context.deviceDetails?.location?.datetime !== null &&
                        context.deviceDetails?.location?.datetime !== undefined) && (
                        <div className={styles.lastUpdatedText} data-cy={"lastUpdated"}>
                            {
                                `(${Localizer.get(Localizer.deviceDetailsLocationLastUpdated, getDateTimeInLocaleFormat(context.deviceDetails?.location?.datetime!))})`
                            }
                        </div>
                    )
                }
            </div>

            <Box className={styles.moduleContainer}>
                <LoadingDecorator className={styles.moduleEmptyContainer} hideConentOnLoading={true} isLoading={props.isLoading || !googleApiRegistered}>
                    <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"deviceLocation"}>
                        {(googleApiRegistered) && (deviceDetails?.location) ? (
                            (context.deviceDetails?.easyPlusRequiredToSeeTelematicValues && !easyPlusEnabled)
                                ? (
                                    <Box className={`${styles.moduleEmptyContainer} ${styles.moduleContentContainerHalfScreenWidth}`} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                        <span data-cy={"locationRequireEasyPlus"}>{Localizer.deviceDetailsLocationHiltiTagRequireEasyPlus}</span>
                                        <EasyPlusLink>{Localizer.easyPlusInfoPanelReadMore}</EasyPlusLink>
                                    </Box>
                                )
                                : (
                                    <GoogleMap autoCloseInfoWindows
                                               height={252}
                                               initialCenter={{
                                                   lat: deviceDetails?.location.latitude ?? 0,
                                                   lng: deviceDetails?.location.longitude ?? 0
                                               }}
                                               initialZoom={16}
                                               markers={[
                                                   {
                                                       position: {
                                                           lat: deviceDetails?.location.latitude ?? 0,
                                                           lng: deviceDetails?.location.longitude ?? 0
                                                       }
                                                   }
                                               ]}
                                    />
                                )
                        ) : (
                            <Box p={16}>
                                <Text tag="span" weight="bold">{Localizer.deviceDetailsLocationNoLocation}</Text>
                            </Box>
                        )}
                    </Box>
                </LoadingDecorator>
            </Box>
        </div>
    );
};

export default DeviceDetailsLocation;