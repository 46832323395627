import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

context("Desktop - Authenticated private user favorite products tests", () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session(['private_user_session'], () => {
            cy.loginAndSelectPrivateRole("juhani.sihvonen@weare.fi");
        });
    });

    it('Open catalog favorites page and navigate back from browser', () => {
        cy.visit('/');
        //Assert frontpage visible
        pageData().frontPage.widgets.rent();

        cy.intercept(
            {
                method: 'POST',
                path: '/api/Product/ProductDepotAvailability',
            },
            {
                statusCode: 200,
                body: true,
            }
        );

        executeWithIntercept(
            () => pageData().frontPage.widgets.favorite().click(),
            [pageData().rent.routes.categoriesData as RouteData]
        );

        cy.assertBreadCrumbLength(3);

        executeWithIntercept(
            () => onRedirect(() => pageData().rent.productLink('83a700be-2e3b-45cc-ac8a-570a551760d4')
                .click()),
            [pageData().rent.routes.productsData as RouteData]
        );

        cy.get("#share-link-button");

        executeWithIntercept(
            () => cy.go('back'),
            [pageData().rent.routes.categoriesData as RouteData]
        );

        cy.assertBreadCrumbLength(3);

        cy.get('#product_83a700be-2e3b-45cc-ac8a-570a551760d4');
    });

    function AddToFavorites(productId: string): void {
        executeWithIntercept(
            () => cy.get(`#favorite_product_${productId}`).click(),
            [pageData().rent.routes.addToFavorites as RouteData]
        );
    }

    function RemoveFromFavorites(productId: string): void {
        executeWithIntercept(
            () => cy.get(`#favorite_product_${productId}`).click(),
            [pageData().rent.routes.deleteFromFavorites as RouteData]
        );
    }

    it('Open catalog page and add to favorites', () => {

        cy.intercept('POST', '/api/Product/ProductDepotAvailability').as('productDepotAvailability');

        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'));

        cy.waitSuccess('@productDepotAvailability');

        const favoriteIcon = '#favorite_product_6ab319f1-58b1-4349-aaa4-d6e8d5849e32';
        const notFavorite = 'color: black; font-weight: 400;';
        const favorite = 'color: rgb(255, 192, 64); font-weight: 800;';

        //First time it should not be in favorites
        cy.get(favoriteIcon).should('have.attr', 'style', notFavorite);

        AddToFavorites("6ab319f1-58b1-4349-aaa4-d6e8d5849e32");

        cy.wait(2000);

        cy.get(favoriteIcon).should('have.attr', 'style', favorite);

        RemoveFromFavorites("6ab319f1-58b1-4349-aaa4-d6e8d5849e32");

        cy.get(favoriteIcon).should('have.attr', 'style', notFavorite);

    });

});
