import Localizer from "@/localization/Localizer";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";
import {pageData} from "@/helpers/CypressHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe('Desktop - Fleet Monitoring - Tabs tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should display two tabs', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' });

        cy.visit('/fleet-monitoring');

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        pageData().fleetMonitoring.tabs.tab()
            .eq(0)
            .should('contain', Localizer.fleetMonitoringPageTabDevices);

        pageData().fleetMonitoring.tabs.tab()
            .eq(1)
            .should('contain', Localizer.fleetMonitoringPageTabReturnRequests);

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        pageData().fleetMonitoring.tabs.tab()
            .eq(1)
            .click();

        cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?selectedTab=palautuspyynn%C3%B6t`);
    });

    it('should display only Fleet Monitoring tab', () => {
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagReturnRequestsList);

        cy.visit('/fleet-monitoring');

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        pageData().fleetMonitoring.tabs.tab()
            .should('not.exist');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');
    });

    after(() => {
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagReturnRequestsList);
    });

});