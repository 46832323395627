import Localizer from "@/localization/Localizer";
import {Button, ButtonType, NumberInput, NumberInputBehaviour} from "@renta-apps/athenaeum-react-components";
import React from "react";

import styles from "./AddToCartButton.module.scss";

export interface IAddToCartButtonProps {

    /**
     * if count > 0, show "remove" button next to the button.
     */
    displayRemoveFromCartButton?: boolean;
    
    /**
     * If zero, a Button will be displayed.
     * If greater than zero, a NumberInput will be displayed.
     */
    count: number;
    /**
     * if count is zero and button is shown it will hide the text and icon will be left.
     */
    hideButtonText?: boolean;

    /**
     * Called when the {@link AddToCartButton} is clicked with a {@link count} of zero.
     * This changes the Button-layout to the NumberInput-layout.
     */
    onFirstClick(): Promise<void>;

    /**
     * Called when the user clicks the {@link AddToCartButton} in its Button-state, its plus or minus buttons in its NumberInput state, or sets the value of the NumberInput.
     */
    onCountChange(newCount: number, isFirstClick: boolean): Promise<void>;

    className?: string;

    /**
     * Can the user click the button or change the count.
     */
    disabled?: boolean;
}

const AddToCartButton: React.FC<IAddToCartButtonProps> = (props: IAddToCartButtonProps) => {    
    
    const changeCountAsync = async (newCount: number): Promise<void> => {
        if (newCount <= 0)
            return;
        
        const firstClick: boolean = (props.count === 0) && (newCount === 1);

        if (firstClick) {
            await props.onFirstClick();
        }
        if (!firstClick && newCount !== props.count) {
            await props.onCountChange(newCount, firstClick);
        }
    }

    const deleteFromShoppingCartAsync = async (): Promise<void> => {
        await props.onCountChange(0, false);
    }
    
    return (
        <div className={`${props.className} ${styles.addToCartButton}`}>

            {(props.count <= 0) && (
                <Button className={styles.button}
                        label={props.hideButtonText ? "" : Localizer.addToCartText}
                        icon={{name: "shopping-cart"}}
                        type={ButtonType.Orange}
                        disabled={props.disabled}
                        onClick={async () => await changeCountAsync(1)}
                />
            )}

            {(props.count > 0) && (
                <>
                    <NumberInput className={`renta-easy-number-input ${styles.numberInput}`}
                                 min={1}
                                 max={100000}
                                 value={props.count}
                                 behaviour={NumberInputBehaviour.Restricted}
                                 increaseIcon="plus"
                                 decreaseIcon="minus"
                                 readonly={props.disabled}
                                 onChange={async (_, newCount) => await changeCountAsync(newCount)}
                                 disableButtonsWhenCannotChangeValue
                    />
                    {props.displayRemoveFromCartButton &&
                        <Button type={ButtonType.Danger} 
                                icon={{name: "trash"}} 
                                title={Localizer.shoppingCartPageRemoveProductTitle}
                                onClick={async () => await deleteFromShoppingCartAsync()}
                                confirm={Localizer.shoppingCartPageRemoveProductConfirm}
                                className={styles.removeButton}
                        />
                    }
                </>
            )}
        </div>
    );
}

export default AddToCartButton;