import {Button, ButtonType, Form, Icon, IconSize, Modal, OneColumn, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect, useState} from "react";
import {Dictionary} from "typescript-collections";
import {addCategoryAttributeKeyAsync, deleteCategoryAttributeKeyAsync, fetchCategoryAttributeKeysAsync, moveProductPriorityDownAsync, moveProductPriorityUpAsync} from "@/services/CatalogService";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";
import styles from "@/components/Catalog/Catalog.module.scss";
import {
    moveCategoryAttributePriorityUpAsync,
    moveCategoryAttributePriorityDownAsync,
} from "@/services/CatalogService";
import Catalog from "@/components/Catalog/Catalog";

type EditAttributesModalProps = {
    isOpen: boolean;
    currentCategoryId: string;
    onClose(success: boolean): void;
}

const EditAttributesModal: React.FC<EditAttributesModalProps> = (props: EditAttributesModalProps) => {

    const _modalRef: React.RefObject<Modal> = React.createRef();

    const [newCategoryAttribute, setNewCategoryAttribute] = useState<string>("");
    const [selectedCategoryAttributes, setSelectedCategoryAttributes] 
        = useState<CategoryAttributeKeyModel[]>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    
    const getCategoryAttributeKeys = async (): Promise<void> => {
        const attributeKeys: CategoryAttributeKeyModel[] = await fetchCategoryAttributeKeysAsync(props.currentCategoryId); 
        setSelectedCategoryAttributes(attributeKeys);
    }
    
    const addCategoryKey = async (data: Dictionary<string, any>): Promise<void> => {
        const key: string = data.getValue("newAttributeName");
        const categoryId: string | null = props.currentCategoryId;
    
        if (!categoryId) {
            return;
        }
        
        const addedKey = await addCategoryAttributeKeyAsync(categoryId, key);
        
        props.onClose(!!addedKey);
    }
    
    const deleteCategoryKeyAsync = async (attributeId: string, categoryId: string): Promise<void> => {
        await deleteCategoryAttributeKeyAsync(categoryId, attributeId);
        getCategoryAttributeKeys();
    }

    const downAsync = async (item: CategoryAttributeKeyModel): Promise<void> => {
        if ((item.id !== undefined) && (item.priority !== undefined) && (item.priority + 1 <= maxCategoryAttributePriority())) {
            await moveCategoryAttributePriorityDownAsync(item.id!);
            await getCategoryAttributeKeys();
        }
    }

    const upAsync = async (item: CategoryAttributeKeyModel): Promise<void> => {
        if ((item.id !== undefined) && (item.priority !== undefined) && (item.priority - 1 >= Catalog.MIN_ITEM_PRIORITY)) {
            await moveCategoryAttributePriorityUpAsync(item.id!);
            await getCategoryAttributeKeys();
        }
    }

    const canMoveUp = (categoryAttribute?: CategoryAttributeKeyModel | null): boolean => {
        if (categoryAttribute)
            return (categoryAttribute.priority !== undefined && (categoryAttribute.priority > Catalog.MIN_ITEM_PRIORITY));
        return false;
    }

    const canMoveDown = (categoryAttribute?: CategoryAttributeKeyModel | null): boolean => {
        if (categoryAttribute)
            return (categoryAttribute.priority !== undefined && (categoryAttribute.priority < maxCategoryAttributePriority()));
        return false;
    }

    const maxCategoryAttributePriority = (): number => {
        return selectedCategoryAttributes.length && selectedCategoryAttributes.length > 0 ? selectedCategoryAttributes.length - 1 : Catalog.MAX_ITEM_PRIORITY;
    }

    const cleanup = (): void => {
        setNewCategoryAttribute("");
    }

    useEffect(() => {
        if (props.isOpen) {
            _modalRef.current?.openAsync();
        } else {
            cleanup();
            _modalRef.current?.closeAsync();
        }
    }, [props.isOpen, _modalRef]);
    
    useEffect(() => {
        if (!newCategoryAttribute || newCategoryAttribute.trim().length === 0) {
            setIsFormValid(false);
        } else {
            const loweredValue = newCategoryAttribute.toLowerCase();
            const currentKeys = selectedCategoryAttributes.map(a => a.name.toLowerCase());
            setIsFormValid(currentKeys.indexOf(loweredValue) === -1);

        }
    }, [newCategoryAttribute]);

    return (
        props.isOpen ? (
            <Modal ref={_modalRef}
                   onOpen={async () => await getCategoryAttributeKeys()}
                   onClose={async () => {
                       setSelectedCategoryAttributes([]);
                       props.onClose(false);
                   }}
                   title={Localizer.catalogEditAttributes}
            >
                <Form id="editAttributes" onSubmit={async (_, data) => addCategoryKey(data)}>
                    <OneColumn>
                        {(selectedCategoryAttributes.length > 0) && (
                            <table 
                                className={`table table-hover ${styles.productPriorityTable}`}>
                                <thead>
                                    <tr>
                                        <th 
                                            className="border-top-0 border-bottom-0" 
                                            scope="col" 
                                            colSpan={2}>{Localizer.catalogExistingAttributes}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {selectedCategoryAttributes.map((attr) => (
                                    <tr key={attr.id}>
                                        <td 
                                            className="attribute_name col-md-9 align-middle">{attr.name}</td>
                                        <td 
                                            className="col-md-2">
                                            <div className={styles.productPrioritySettings}>
                                                <Button type={ButtonType.Default}
                                                        className={"move-up"}
                                                        icon={{name: "arrow-up", size: IconSize.ExtraSmall}}
                                                        onClick={async () => upAsync(attr)}
                                                        disabled={!canMoveUp(attr)}
                                                />
                                                <div className={styles.priority}>
                                                    {attr.priority}
                                                </div>
                                                <Button type={ButtonType.Default}
                                                        className={"move-down"}
                                                        icon={{name: "arrow-down", size: IconSize.ExtraSmall}}
                                                        onClick={async () => downAsync(attr)}
                                                        disabled={!canMoveDown(attr)}
                                                />
                                            </div>
                                        </td>
                                        <td 
                                            className="col-md-1 align-middle">
                                            <Icon 
                                                  name={"far trash-alt"} 
                                                  size={IconSize.Large}
                                                  className={`remove_attribute ${styles.deleteCategoryKeyIcon}`}
                                                  onClick={async () => deleteCategoryKeyAsync(attr.id!, attr.categoryId!)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}

                        <TextInput required
                                   id="newAttributeName"
                                   label={Localizer.catalogNewAttributeName}
                                   value={newCategoryAttribute}
                                   onChange={async (_, value) => setNewCategoryAttribute(value)}
                                   className="mt-4"
                        />
                    </OneColumn>
                    <Button submit block
                            id="new_attribute_submit_button"
                            type={ButtonType.Orange}
                            label={Localizer.genericSave}
                            disabled={!isFormValid}
                    />
                </Form>
            </Modal>
        ) : null
    );
}

export default EditAttributesModal;