import React, {useEffect, useState} from 'react';
import {Box, Checkbox, LoadingDecorator, RentaPlusDecorator, RentaPlusDecoratorPosition} from '@renta-apps/renta-react-components';
import Localizer from "@/localization/Localizer";
import {DeviceModel} from "@/models/server/DeviceModel";
import {DeviceDetails} from '@/models/server/DeviceDetails';
import styles from './DeviceGrid.module.scss';
import DeviceDetailsRequestModel from "@/models/server/Requests/DeviceDetailsRequestModel";
import {requestContact} from "@/services/EasyPlusService";
import EasyPlusInfoModal from "@/components/EasyPlus/EasyPlusInfoModal/EasyPlusInfoModal";
import DeviceGridRow from "@/pages/FleetMonitoring/DeviceGrid/DeviceGridRow";
import RequestReturnModal from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal";

interface IDeviceGridProps {
    devices: DeviceModel[];
    deviceDetails: (item: DeviceModel) => Promise<DeviceDetails>;
    devicesDetails: (devices: DeviceDetailsRequestModel[]) => Promise<DeviceDetails[]>;
    onGearButtonClick: (deviceId: string) => void;
    onDebugClick?: (assetId: string, rentaId: string) => Promise<void>;
    onRowCheckboxClick: (selectedDevicesIds: string[]) => void;
    isEasyPlusUser: boolean;
    isLoading: boolean;
    gridRef: React.RefObject<HTMLDivElement>;
}

const DeviceGrid: React.FC<IDeviceGridProps> = ({
    devices,
    deviceDetails,
    devicesDetails,
    onDebugClick,
    onGearButtonClick,
    onRowCheckboxClick,
    isEasyPlusUser,
    isLoading,
    gridRef,
}) => {
    const [data, setData] = useState<DeviceModel[]>(devices);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [returnItem, setReturnItem] = useState<{rentaId: string, constructionSiteId?: string | null, contractId?: string | null} | null>(null);
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [deviceDetailsMap, setDeviceDetailsMap] = useState<{ [key: string]: DeviceDetails | null }>({});

    const [isEasyPlusModalOpen, setIsEasyPlusModalOpen] = useState(false);
    const [isRequestReturnModalOpen, setIsRequestReturnModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setData(devices);
        // Reset expanded items when data changes
        setExpandedItems(new Set());

        // Reset selected devices when every the filters or pagination changes
        setSelectedItems([])
    }, [devices]);

    const getItemKey = (item: {rentaId: string, contractNumber: string | null, orderLineId: string | null}) => `${item.rentaId}_${item.contractNumber}_${item.orderLineId}`;

    const handleAccordionButtonClick = async (item: DeviceModel) => {
        const key = getItemKey(item);
        setExpandedItems(prevExpandedItems => {
            const newExpandedItems = new Set(prevExpandedItems);
            if (newExpandedItems.has(key)) {
                newExpandedItems.delete(key);
            }
            else {
                newExpandedItems.add(key);
            }
            return newExpandedItems;
        });

        if (!deviceDetailsMap[key]) {
            try {
                const details = await deviceDetails(item);
                setDeviceDetailsMap(prevDetails => ({...prevDetails, [key]: details}));
            }
            catch (error) {
                console.error('Error loading device details:', error);
                setDeviceDetailsMap(prevDetails => ({...prevDetails, [key]: null}));
            }
        }
    };

    const handleHeaderAccordionClick = async () => {
        const allExpanded = expandedItems.size === data.length;

        if (allExpanded) {
            // Collapse all items
            setExpandedItems(new Set());
        }
        else {
            // Expand all items
            const newExpandedItems = new Set(data.map(getItemKey));
            setExpandedItems(newExpandedItems);

            // Fetch device details for all items that are not already in the map
            const fetchRequest = data
                .filter((item) => !deviceDetailsMap[getItemKey(item)])
                .map(({assetId, rentaId, contractNumber, orderLineId}) => ({assetId, rentaId, contractNumber, orderLineId}));

            if (!fetchRequest.length) {
                return;
            }

            try {
                const details = await devicesDetails(fetchRequest);
                const detailsMap = details.reduce((map, detail) => {
                    map[getItemKey(detail)] = detail;
                    return map;
                }, {} as { [key: string]: DeviceDetails });
                setDeviceDetailsMap(prevDetails => ({...prevDetails, ...detailsMap}));
            }
            catch (error) {
                console.error('Error loading devices details:', error);
                const detailsMap = fetchRequest.reduce((map, detail) => {
                    map[getItemKey(detail)] = null;
                    return map;
                }, {} as { [key: string]: DeviceDetails | null });
                setDeviceDetailsMap(prevDetails => ({...prevDetails, ...detailsMap}));
            }
        }
    };

    const handleHeaderCheckboxClick = () => {
        // If all checkboxes are selected, deselect them. Otherwise, select all checkboxes.
        const selectedIds: string[] = selectedItems.length === data.length ? [] : data.map(row => row.rentaId);

        // Update parent component what items are selected.
        onRowCheckboxClick(selectedIds);

        setSelectedItems(selectedIds);
    };

    const handleRowCheckboxClick = (itemId: string) => {
        const selectedIds: string[] = selectedItems.includes(itemId)
            ? selectedItems.filter(id => id !== itemId)
            : [...selectedItems, itemId];

        onRowCheckboxClick(selectedIds);

        setSelectedItems(selectedIds);
    };

    const handleItemReturnButtonClick = (rentaId: string, itemKey: string): void => {
        setIsRequestReturnModalOpen(true);
        const details = deviceDetailsMap[itemKey];
        setReturnItem({
            rentaId: rentaId,
            constructionSiteId: details?.constructionSiteId,
            contractId: details?.customerId,
        });
    };

    const handleReturnModalClose = (): void => {
        setIsRequestReturnModalOpen(false);
        setReturnItem(null);
    };

    return (
        <>
            <div className={styles.dataTable} ref={gridRef} data-cy="fleet-monitoring-grid">
                {/* Header Row */}
                <div className={`${styles.gridRow} ${styles.dataRow} ${styles.header}`}>
                    <div className={styles.name}>
                        <div className={styles.checkboxCell}>
                            <Checkbox
                                checked={data.length > 0 && selectedItems.length === data.length}
                                onChange={handleHeaderCheckboxClick}
                            />
                        </div>
                        <div>{Localizer.fleetMonitoringPageGridDeviceName}</div>
                    </div>
                    <div className={styles.rentaId}>{Localizer.fleetMonitoringPageGridId}</div>
                    <div className={styles.alerts}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridAlerts}
                        </RentaPlusDecorator>
                    </div>
                    <div className={styles.idle}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridIdle}
                        </RentaPlusDecorator>
                    </div>
                    <div className={styles.battery}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridBattery}
                        </RentaPlusDecorator>
                    </div>
                    <div className={styles.fluid}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridFuel}
                        </RentaPlusDecorator>
                    </div>
                    <div className={`${styles.gridItem} ${styles.actions}`}>
                        <div className={styles.accordionButton}
                                onClick={() => handleHeaderAccordionClick()}>
                            <i className={`fas fa-angle-down ${(data.length > 0 && expandedItems.size === data.length) ? styles.rotated : ''}`}></i>
                        </div>
                    </div>
                </div>

                {/* Loading Rows */}
                {[...Array(4)].map((_, index) => (
                    <LoadingDecorator key={index} className={styles.loaderContainer} hideConentOnLoading={false} isLoading={isLoading} />
                ))}

                {/* Display information about no data */}
                {!isLoading && data.length === 0 &&
                    <Box display="flex" alignItems="center" justifyContent="center" className={styles.loaderContainer}>
                        {Localizer.fleetMonitoringPageGridEmpty}
                    </Box>
                }

                {/* Data Rows */}
                {!isLoading && data.map((item, index) => {
                    const itemKey = getItemKey(item);
                    return (
                        <DeviceGridRow key={itemKey}
                                       index={index}
                                       item={item}
                                       selectedItems={selectedItems}
                                       expanded={expandedItems.has(itemKey)}
                                       deviceDetails={deviceDetailsMap[itemKey]}
                                       easyPlusUser={isEasyPlusUser}
                                       onRowCheckboxClick={() => handleRowCheckboxClick(item.rentaId)}
                                       onAccordionButtonClick={() => handleAccordionButtonClick(item)}
                                       onGearButtonClick={() => onGearButtonClick(item.rentaId)}
                                       onReturnButtonClick={() => handleItemReturnButtonClick(item.rentaId, itemKey)}
                                       onDebugClick={onDebugClick}
                        />
                    );
                })}
            </div>

            <EasyPlusInfoModal isOpen={isEasyPlusModalOpen}
                               onClose={() => setIsEasyPlusModalOpen(false)}
                               onContactMeClick={async () => await requestContact()}
            />

            <RequestReturnModal isOpen={isRequestReturnModalOpen}
                                onClose={handleReturnModalClose}
                                isEasyPlusUser={isEasyPlusUser}
                                selectedDevices={returnItem ? [returnItem] : null}
            />
        </>
    );
};

export default DeviceGrid;