import Chainable = Cypress.Chainable;

export default class GridHelper {

    /**
     * @param gridId A Grid identifier.
     * @param cellContent Unique content of the Grid Cell which Row to get.
     * @param exactMatch Should the {@link cellContent} completely match to an expected value.
     * @returns The Grid Row which contains the {@link cellContent}.
     */
    public static getGridRowByCellContent(gridId: string, cellContent: string, exactMatch: boolean = true): Chainable<JQuery<HTMLTableRowElement>> {
        return (exactMatch)
            ? cy.get(gridId).contains(new RegExp("^" + cellContent + "$", "g")).parents('tr')
            : cy.get(gridId).contains(cellContent).parents('tr');
    }

    /**
     *
     * @param gridId The grid identifier. A certain grid where a cell will be searched by {@link cellContent} and {@link column}.
     * @param cellContent Unique content of a Grid Cell to get.
     * @param columnNumber is a column where content is expected.
     * @param exactMatch Should the {@link cellContent} completely match to an expected value.
     * @returns The Grid Cell of the given Grid {@link column} of the Grid Row which contains the {@link cellContent}.
     */
    public static getGridCell(
        gridId: string,
        cellContent: string,
        columnNumber: number,
        exactMatch: boolean = true,
    ): Chainable<JQuery<HTMLTableCellElement>> {
        return this.getGridRowByCellContent(gridId, cellContent, exactMatch)
            .find(`> :nth-child(${columnNumber})`);
    }

    public static getGridVisibleRows(gridId: string): Chainable<JQuery<HTMLTableRowElement>> {
        return cy.get(gridId).find('tbody:first-of-type')
            .not('athenaeum-grid-details')
            .find('tr');
    }

    public static getGridContentByRowAndCell(gridId: string, row: number, cell: number): Chainable<JQuery<HTMLElement>> {
        return cy.get(gridId)
            .find("tbody:first-of-type")
            .find("tr")
            .eq(row)
            .find("td")
            .eq(cell);
    }
}