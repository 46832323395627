import React from "react";
import {ActionType, AlertModel, ch} from "@renta-apps/athenaeum-react-common";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {OrganizationContractRoleModel} from "@/models/server/OrganizationContractRoleModel";
import {ConstructionSiteRoleModel} from "../../../models/server/ConstructionSiteRoleModel";
import {CellAction, CellModel, ColumnDefinition, Grid, Icon, SelectListItem} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import styles from "@/components/UsersGrid/UsersGrid.module.scss";
import DeleteRoleRequest from "@/models/server/Requests/DeleteRoleRequest";

import EnumProvider from "@/providers/EnumProvider";
import {ConstructionSitePermission, OrganizationContractLevel} from "@/models/Enums";
import TransformProvider from "@/providers/TransformProvider";
import SetConstructionSitePermissionsRequest from "@/models/server/Requests/SetConstructionSitePermissionsRequest";
import UserContext from "@/models/server/UserContext";
import ChangeUserRoleModal from "@/components/UsersGrid/DetailsPanel/ChangeUserRoleModal";
import ChangeUserRoleRequest from "@/models/server/Requests/ChangeUserRoleRequest";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {UserRolesHelper} from "@/helpers/UserRolesHelper";
import UnleashHelper from "@/helpers/UnleashHelper";

interface IAdminUserManagementProps {
    organizationRoles: OrganizationContractRoleModel[];
    constructionSiteRoles: ConstructionSiteRoleModel[];
    canManageUsers: boolean;
    className?: string;
    userRoles?: SelectListItem[] | null;

    onRoleDeleteAsync?(alert: AlertModel): Promise<void>;
}

interface IAdminUserManagementState {
    isLoading: boolean;
    isUserRoleEditionModalOpen: boolean;
    userRoleEditionModel: OrganizationContractRoleModel | null;
    filteredUserRoles: SelectListItem[];
}

export default class DetailsPanel extends BaseComponent<IAdminUserManagementProps, IAdminUserManagementState> {

    public state: IAdminUserManagementState = {
        isLoading: false,
        isUserRoleEditionModalOpen: false,
        userRoleEditionModel: null,
        filteredUserRoles: []
    };

    private readonly _userSiteRolesGrid: React.RefObject<Grid<ConstructionSiteRoleModel>> = React.createRef();
    private readonly _userContractRolesGrid: React.RefObject<Grid<OrganizationContractRoleModel>> = React.createRef();

    private readonly _userContractRoleColumns: ColumnDefinition[] = [
        {
            header: `${Localizer.genericCompanyLanguageItemName}`,
            accessor: "contractId",
            maxWidth: 25,
            transform: (cell: CellModel<OrganizationContractRoleModel>) => this.renderContractDetails(cell.model),
            editable: false,
        },
        {
            header: `${Localizer.genericRoleLanguageItemName}`,
            accessor: "roleName",
            className: "role-name",
            maxWidth: 25,
            transform: (cell: CellModel<OrganizationContractRoleModel>) => Localizer.get(cell.model.roleName),
            editable: false,
        },
        {
            header: `${Localizer.genericEditLanguageItemName}`,
            minWidth: 25,
            visible: UnleashHelper.isEnabled(RentaEasyConstants.featureFlagOrganizationRolesDirectAssignment) && this.props.canManageUsers,
            init: (cell: CellModel<OrganizationContractRoleModel>) => this.initRoleChangeCell(cell),
            actions: [
                {
                    name: "edit",
                    title: `${Localizer.changeUserRoleEditIconLabel}`,
                    icon: {name: "pen-to-square", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Edit,
                    callback: async (cell: CellModel<OrganizationContractRoleModel>) => await this.openUserRoleEditionModalAsync(cell!)
                }
            ]
        },
        {
            header: `${Localizer.formDeleteLanguageItemName}`,
            minWidth: 25,
            visible: this.props.canManageUsers,
            init: (cell: CellModel<OrganizationContractRoleModel>) => this.initRoleRemoveCell(cell),
            actions: [
                {
                    name: "delete",
                    title: Localizer.genericDeleteRoleLanguageItemName,
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    confirm: (cell: CellModel<OrganizationContractRoleModel>) => Localizer.get(Localizer.confirmationDeleteUserRole, cell.model.roleName, cell.model.userEmail),
                    callback: async (cell, action) => await this.processContractOperationAsync(cell, action)
                }
            ]
        },
    ];

    private initRoleRemoveCell(cell: CellModel<OrganizationContractRoleModel>): void {
        if (cell.model.contract?.level === OrganizationContractLevel.Operator) {
            if (!this.userContext.isAdminWithAdminRole) {
                cell.visible = false;
            }
        }
    }

    private initRoleChangeCell(cell: CellModel<OrganizationContractRoleModel>): void {
        if (cell.model.contract?.level === OrganizationContractLevel.Operator) {
            if (!this.userContext.isAdminWithAdminRole) {
                cell.visible = false;
            }
        }
    }

    private async openUserRoleEditionModalAsync(cell: CellModel<OrganizationContractRoleModel>): Promise<void> {
        this.setState({isUserRoleEditionModalOpen: true, userRoleEditionModel: cell.model, filteredUserRoles: this.filterOutAvailableUserRolesToChange(cell.model.roleName!)});
    }

    private async handleUserRoleChange(isUserRoleChanged: boolean, selectedUserRoleName?: string): Promise<void> {
        if (isUserRoleChanged && this.state.userRoleEditionModel) {
            // the same role chosen from dropDown, nothing should be changed
            if (selectedUserRoleName && selectedUserRoleName === this.state.userRoleEditionModel.roleName) {
                this.setState({isUserRoleEditionModalOpen: false});
                return;
            }
            
            const request: ChangeUserRoleRequest = {
                userEmail: this.state.userRoleEditionModel!.userEmail,
                contractId: this.state.userRoleEditionModel!.contractId,
                userRoleNameToAdd: selectedUserRoleName!,
                userRoleNameToDelete: this.state.userRoleEditionModel!.roleName
            };

            const isSuccessful: boolean = await this.postAsync("/api/companies/ChangeRole", request);

            if (isSuccessful) {
                this._userContractRolesGrid.current!.props.data?.forEach((value, index) => {
                    if (value.contractId === this.state.userRoleEditionModel!.contractId) {
                        value.roleName = selectedUserRoleName!;
                    }
                });
            }
            
            this.setState({isUserRoleEditionModalOpen: false, userRoleEditionModel: null});
        } else {
            this.setState({isUserRoleEditionModalOpen: false});
        }
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private readonly _userSiteRoleColumns: ColumnDefinition[] = [
        {
            header: `${Localizer.genericCompanyLanguageItemName}`,
            accessor: "contract",
            maxWidth: this.mobile ? 10 : 25,
            transform: (cell: CellModel<ConstructionSiteRoleModel>) => this.renderSiteContractDetails(cell.model),
            editable: false,
        },
        {
            header: `${Localizer.genericConstructionSiteLanguageItemName}`,
            accessor: "constructionSiteName",
            maxWidth: this.mobile ? 25: 50,
            transform: (cell: CellModel<ConstructionSiteRoleModel>) => this.renderSiteDetails(cell.model),
            editable: false,
        },
        {
            header: `${Localizer.genericRoleLanguageItemName}`,
            accessor: "roleName",
            maxWidth: this.mobile ? 10 : 25,
            transform: (cell: CellModel<ConstructionSiteRoleModel>) => Localizer.get(cell.model.roleName),
            editable: false,
        },
        {
            header: `${Localizer.genericPermissionsLanguageItemName}`,
            accessor: "constructionSiteRole.permissions",
            minWidth: 0,
            maxWidth: this.mobile ? 10 : 25,
            className: styles.permissionsCell,
            actions: [
                {
                    render: (cell: CellModel<ConstructionSiteRoleModel>) => this.renderPermissionsControls(cell),
                }
            ],
            editable: false,
        },
        {
            header: `${Localizer.formDeleteLanguageItemName}`,
            minWidth: this.mobile ? 10 : 25,
            visible: this.props.canManageUsers,
            actions: [
                {
                    name: "delete",
                    title: Localizer.genericDeleteRoleLanguageItemName,
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    confirm: (cell: CellModel<ConstructionSiteRoleModel>) => Localizer.get(Localizer.constructionSiteDetailsUsersTableConfirmRoleDeletion, cell.model.userEmail),
                    callback: async (cell, action) => await this.processUserOperationAsync(cell, action)
                }
            ]
        },
    ];

    private async processContractOperationAsync(cell: CellModel<OrganizationContractRoleModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "delete") {

            const model: OrganizationContractRoleModel = cell.model;

            const roleId: string | null = model.id;

            const request: DeleteRoleRequest = {
                roleId,
                contractId: model.contractId,
                email: model.userEmail,
                roleName: model.roleName,
                constructionSiteId: null

            };

            this._userContractRolesGrid!.current!.rows.remove(cell.row);

            const response: AlertModel = await this.postAsync("/api/Companies/DeleteRole", request);

            await this._userContractRolesGrid.current!.reloadAsync();

            await this.props.onRoleDeleteAsync?.(response);

        }
    }

    private async processUserOperationAsync(cell: CellModel<ConstructionSiteRoleModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "delete") {

            const model: ConstructionSiteRoleModel = cell.model;

            const roleId: string | null = model.id;

            const request: DeleteRoleRequest = {
                roleId,
                contractId: null,
                email: model.userEmail,
                roleName: model.roleName,
                constructionSiteId: model.constructionSiteId

            };

            this._userSiteRolesGrid!.current!.rows.remove(cell.row);

            const response: AlertModel = await this.postAsync("/api/Companies/DeleteRole", request);

            await this._userSiteRolesGrid.current!.reloadAsync();

            await this.props.onRoleDeleteAsync?.(response);

        }
    }

    private async changeSitePermissionAsync(cellModel: CellModel<ConstructionSiteRoleModel>, constructionSiteRole: ConstructionSiteRoleModel | null, value: number, hasAccess: boolean): Promise<void> {
        const request: SetConstructionSitePermissionsRequest = {
            permission: value,
            constructionSiteRoleId: constructionSiteRole!.id,
            hasAccess
        };

        if (constructionSiteRole!.permissions.includes(value)) {
            cellModel.model!.permissions.remove(value);
        }
        else {
            constructionSiteRole!.permissions.push(value);
        }

        const url: string = "/api/Companies/SetConstructionSitePermission";

        await this.postAsync(url, request);

        await cellModel.reRenderAsync();
    }

    private renderPermissionsControls(cellModel: CellModel<ConstructionSiteRoleModel>): React.ReactNode {
        const allPermissions: number[] = EnumProvider.getValues(ConstructionSitePermission);
        const constructionSiteRole: ConstructionSiteRoleModel = cellModel.model;

        if ((constructionSiteRole?.permissions?.length ?? 0) > 0) {
            return (
                <React.Fragment key={constructionSiteRole.id}>
                    {
                        allPermissions.map((permission, index) => {

                            const hasPermission: boolean = constructionSiteRole!.permissions.includes(permission);

                            const key: string = `${permission} ${index}`;
                            const userDisplayName: string = constructionSiteRole.userEmail!;
                            const displayValue: string = EnumProvider.getConstructionSitePermissionText(permission);
                            const className: string = (hasPermission)
                                ? styles.permissionsContainer
                                : this.css(styles.permissionsContainer, styles.disabledActionContainer);
                            const iconName: string = (hasPermission)
                                ? "fa-minus"
                                : "fa-plus";
                            const iconConfirm: string = (hasPermission)
                                ? Localizer.get(Localizer.confirmationDeletePermission, displayValue, userDisplayName)
                                : Localizer.get(Localizer.confirmationAddPermission, displayValue, userDisplayName);

                            const toolTip: string = (permission === ConstructionSitePermission.DirectOrders)
                                ? Localizer.inviteUserRightsToRentDirectlyDescriptionContent
                                : (permission === ConstructionSitePermission.PricesVisible)
                                    ? Localizer.inviteUserPriceInformationDisplayedDescriptionContent
                                    : "";

                            if (this.props.canManageUsers) {
                                return (
                                    <div key={key}
                                         className={className}
                                    >
                                        <div title={toolTip}>
                                            {displayValue}
                                        </div>

                                        <Icon name={iconName}
                                              className={styles.roleAddRemoveIcon}
                                              confirm={iconConfirm}
                                              onClick={async () => await this.changeSitePermissionAsync(cellModel, constructionSiteRole!, permission, !hasPermission)}
                                        />
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div key={key}
                                         className={className}
                                    >
                                        <div title={toolTip}>
                                            {displayValue}
                                        </div>
                                    </div>
                                );
                            }

                        })
                    }

                </React.Fragment>

            );
        }
    }

    private renderContractDetails(model: OrganizationContractRoleModel): string {
        if (model != null) {
            return TransformProvider.organizationContractToString(model.contract!);
        }
        return "";
    }

    private renderSiteContractDetails(model: ConstructionSiteRoleModel): string {
        if (model != null) {
            return TransformProvider.organizationContractToString(model.contract!);
        }
        return "";
    }

    private renderSiteDetails(model: ConstructionSiteRoleModel): string {
        if (model != null) {
            return TransformProvider.constructionSiteRoleToString(model);
        }
        return "";
    } 
    
    private filterOutAvailableUserRolesToChange(selectedRoleToChange: string): SelectListItem[] {
        const currentUserRole = UserRolesHelper.getCurrentUserRole();
        let permittedUserRoles = UserRolesHelper.getAvailableUserRolesByUserRoleName(currentUserRole);
        
        if (permittedUserRoles.indexOf(selectedRoleToChange) === -1) {
            permittedUserRoles.push(selectedRoleToChange);
        } 
        
        if (this.props.userRoles && this.props.userRoles.length > 0) {
            return this.props.userRoles.filter((item) => (permittedUserRoles.find((value) => value === item.value)));
        }
        return [];
    }
    
    public render(): React.ReactNode {

        return (
            <div className={this.css(styles.detailsContainer, this.props.className)}>
                {
                    (this.props.organizationRoles.length > 0) && (
                        <>
                            <div>{Localizer.companies}</div>

                            <Grid responsive
                                  version2Styles
                                  ref={this._userContractRolesGrid}
                                  columns={this._userContractRoleColumns}
                                  data={this.props.organizationRoles}
                            />
                        </>
                    )
                }
                {
                    (this.props.organizationRoles.length > 0 && this.props.constructionSiteRoles.length > 0) && (
                        <hr/>
                    )
                }

                {
                    (this.props.constructionSiteRoles.length > 0) && (
                        <>
                            <div>{Localizer.workSiteText}</div>
                            
                            <Grid responsive
                                  version2Styles
                                  ref={this._userSiteRolesGrid}
                                  columns={this._userSiteRoleColumns}
                                  data={this.props.constructionSiteRoles}
                            />
                        </>
                    )
                }

                {
                    <ChangeUserRoleModal isOpen={this.state.isUserRoleEditionModalOpen}
                                         item={this.state.userRoleEditionModel}
                                         items={this.state.filteredUserRoles}
                                         onUserRoleChanged={(isUserRoleChanged: boolean, selectedUserRoleName: string) => this.handleUserRoleChange(isUserRoleChanged, selectedUserRoleName)}
                    />
                }
            </div>
        )
    }

}