import React from "react";
import OrdersGrid from "@/components/OrdersGrid/OrdersGrid";
import AdminDeposLiteModel from "@/models/server/AdminDeposLiteModel";
import {OrderStatus} from "@/models/Enums";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import EnumProvider from "@/providers/EnumProvider";
import AdminPage from "@/models/base/AdminPage";
import DepotModel from "@/models/server/DepotModel";
import {getDepots} from "@/services/DepotsService";

/**
 * Should not be used in new code. Remove in the future.
 * @deprecated
 */
export interface IAdminOrdersReturnsLegacyParams {

    /**
     * For legacy bookmarks. Use {@link IAdminOrdersReturnsParams.depotId} in new code.
     * @deprecated
     */
    id?: string;
}

export interface IAdminOrdersReturnsParams extends IAdminOrdersReturnsLegacyParams, BasePageParameters {
    /**
     * Id of the rental-location the order is for.
     */
    depotId: string;

    /**
     * Status of the order.
     */
    state?: string;
}

interface IAdminOrdersReturnsState {
    locations: DepotModel[],
    currentLocation: DepotModel | null,
    orderStatus: OrderStatus | null
}

export default class AdminOrders extends AdminPage<IAdminOrdersReturnsParams, IAdminOrdersReturnsState> {

    public state: IAdminOrdersReturnsState = {
        locations: [],
        currentLocation: null,
        orderStatus: null
    };

    public get isInitialized() {
        return (this.state.locations && this.state.currentLocation && this.state.orderStatus);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const params: IAdminOrdersReturnsParams | null = this.typedParameters;

        const id: string | undefined = (params?.id)
            ? params.id
            : params?.depotId;

        if (!id) {
            await this.toFrontPageAsync();
        }

        //Done like this to keep the order status text in the url instead of a number that means nothing to the user, i.e. the "old" way
        const orderStatusItems = EnumProvider.getOrderStatusItems();

        const orderStatus: OrderStatus = (typeof params?.state === "string")
            ? orderStatusItems.find(item => item.text === `Enum.OrderStatus.${params.state}`)!.value as unknown as OrderStatus
            : OrderStatus.Unconfirmed;

        const locations: DepotModel[] = await getDepots();

        const currentLocation: DepotModel = locations
                .find(
                    location =>
                        location.id === id)
            ?? locations[0];

        await this.setState({
            locations,
            currentLocation,
            orderStatus,
        });
    }

    protected get title(): string {
        return Localizer.ordersPageOrders;
    }

    protected get isReturnsPage(): boolean {
        return false;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />

                {
                    (this.isInitialized) &&
                    (
                        <OrdersGrid adminView
                                    isReturns={this.isReturnsPage}
                                    locations={this.state.locations}
                                    currentDepot={this.state.currentLocation}
                                    orderStatus={this.state.orderStatus ?? OrderStatus.Unconfirmed}
                        />
                    )
                }
            </PageContainer>
        );
    }
}