import React from "react";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import Localizer from "@/localization/Localizer";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";
import {TextArea} from "@renta-apps/renta-react-components";

type AdditionalInformationProps = {
    additionalInformation: string | null;
    onChange: (additionalInformation: string) => void;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({additionalInformation, onChange}) => {
    return (
        <ContentBox title={Localizer.returnRequestModalAdditionalInformationHeader} subTitle={Localizer.returnRequestModalAdditionalInformationSubheader}>
            <div className={styles.textAreaContainer} data-cy="return-modal-additional-information">
                <TextArea label={Localizer.returnRequestModalInformation}
                          value={additionalInformation ?? ''}
                          className={styles.inputField}
                          onChange={(value: string) => onChange(value)}
                          disabled={additionalInformation === null}
                          inputProperties={{maxLength: 250}}
                          guideText={`${additionalInformation?.length ?? 0}/250`}
                />
            </div>
        </ContentBox>
    );
}

export default AdditionalInformation;