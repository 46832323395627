import CatalogRequest from "@/models/server/CatalogRequest";
import CatalogDataModel from "@/models/CatalogDataModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import ProductModel from "@/models/server/ProductModel";
import CreateProductRequest from "@/models/server/CreateProductRequest";
import EditCategoryRequest from "@/models/server/EditCategoryRequest";
import CategoryModel from "@/models/server/CategoryModel";
import ChangeCategoryImageRequest from "@/models/server/ChangeCategoryImageRequest";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";
import DeleteCategoryKeyRequest from "@/models/server/Requests/DeleteCategoryKeyRequest";
import AddCategoryKeyRequest from "@/models/server/Requests/AddCategoryKeyRequest";
import {ChangePriorityRequest} from "@/models/server/ChangePriorityRequest";
import MoveCategoryRequest from "@/models/server/MoveCategoryRequest";
import {ChangeVisibilityRequest} from "@/models/server/ChangeVisibilityRequest";
import {SelectListItem} from "@renta-apps/athenaeum-react-components";
import {ChangeProductCategoryRequest} from "@/models/server/ChangeProductCategoryRequest";

export const fetchFavoriteProductsAsync = async (editMode: boolean, discountId?: string): Promise<CatalogDataModel> => {
    const request: CatalogRequest = {
        identifier: "",
        searchTerm: "",
        favorites: true,
        isRentaFuture: false,
        editMode: editMode,
        pricingToolMode: false,
        discountId: discountId
    };

    return ApiProvider.postAsync<CatalogDataModel>("/api/Catalog/CategoriesData", request, this);
}

export const fetchRentaFutureProductsAsync = async (editMode: boolean, discountId?: string): Promise<CatalogDataModel> => {
    const request: CatalogRequest = {
        identifier: "",
        searchTerm: "",
        favorites: false,
        isRentaFuture: true,
        editMode: editMode,
        pricingToolMode: false,
        discountId: discountId
    };
    return ApiProvider.postAsync<CatalogDataModel>("/api/Catalog/CategoriesData", request, this);
}

export const fetchSearchProductsAsync = async (searchTerm: string, pricingToolMode: boolean, ignoreSalesProducts?: boolean, ignoreHidden?: boolean, discountId?: string): Promise<CatalogDataModel> => {
    const request = new CatalogRequest();
    request.searchTerm = searchTerm.trim();
    request.pricingToolMode = pricingToolMode;
    request.ignoreSalesProducts = pricingToolMode && ignoreSalesProducts;
    request.ignoreHidden = pricingToolMode && ignoreHidden;
    request.discountId = discountId;

    if (request.searchTerm && request.searchTerm.length < 3) {
        return Promise.resolve(new CatalogDataModel());
    }
    return ApiProvider.postAsync<CatalogDataModel>("/api/Catalog/CategoriesData", request, this);
}

export const fetchCategoryDataAsync = async (
        item: string | null,
        editMode: boolean,
        pricingToolMode: boolean,
        ignoreSalesProducts?: boolean,
        ignoreHidden?: boolean,
        discountId?: string
): Promise<CatalogDataModel> => {
    const identifier = (item) ? item : "";

    const request: CatalogRequest = {
        identifier: (item) ? item : "",
        searchTerm: "",
        favorites: (identifier === RentaEasyConstants.favoritePageId),
        isRentaFuture: (identifier === RentaEasyConstants.rentaFuturePageId),
        editMode: editMode,
        pricingToolMode: pricingToolMode,
        ignoreSalesProducts: pricingToolMode && ignoreSalesProducts,
        ignoreHidden: pricingToolMode && ignoreHidden,
        discountId: discountId
    };
    return ApiProvider.postAsync<CatalogDataModel>("/api/Catalog/CategoriesData", request, null);
}

export const fetchCategoriesToMoveToAsync = async (parentCategoryId?: string): Promise<SelectListItem[]> => {
    const parentId: string | null = parentCategoryId ? parentCategoryId : RentaEasyConstants.emptyGuid;
    return ApiProvider.postAsync<SelectListItem[]>("/api/Admin/CategoriesMoveSelectList", parentId, null);
}

export const fetchCategoriesForProductToMoveToAsync = async (parentCategoryId: string): Promise<SelectListItem[]> => {
    return ApiProvider.postAsync<SelectListItem[]>("/api/Admin/CategoriesSelectList", parentCategoryId, null);
}

export const fetchDataAsync = (request: CatalogRequest): Promise<CatalogDataModel> => {
    return ApiProvider.postAsync<CatalogDataModel>("/api/Catalog/CategoriesData", request, null);
}

export const fetchCategoryAttributeKeysAsync = async (categoryId: string): Promise<CategoryAttributeKeyModel[]> => {
    return ApiProvider.postAsync<CategoryAttributeKeyModel[]>("/api/Admin/GetCategoryKeys", categoryId, null);
}

export const addCategoryAttributeKeyAsync = async (categoryId: string, key: string): Promise<ProductModel> => {
    const request: AddCategoryKeyRequest = {
        key,
        categoryId
    };
    return ApiProvider.postAsync<ProductModel>("/api/Admin/AddCategoryKey", request, null);
}

export const deleteCategoryAttributeKeyAsync = async (categoryId: string, attributeId: string): Promise<ProductModel> => {
    const request: DeleteCategoryKeyRequest = {
        id: attributeId,
        categoryId
    };
    return ApiProvider.postAsync<ProductModel>("/api/Admin/DeleteCategoryKey", request, null);
}


export const createProductAsync = async (request: CreateProductRequest): Promise<ProductModel> => {
    return ApiProvider.postAsync<ProductModel>("/api/Admin/NewProduct", request, null);
}

export const createCategoryAsync = async (name: string, parentCategoryId: string): Promise<CategoryModel> => {
    const request: EditCategoryRequest = {
        name: name,
        categoryId: parentCategoryId
    };
    return ApiProvider.postAsync<CategoryModel>("/api/Admin/CreateCategory", request, null);
}

export const uploadCategoryImageAsync = async (request: ChangeCategoryImageRequest): Promise<any> => {
    await ApiProvider.postAsync<any>("/api/Admin/UploadPhoto", request, null);
    return true;
}

export const moveProductPriorityUpAsync = async (itemId: string): Promise<any> => {
    const request: ChangePriorityRequest = {
        entityId: itemId,
        increase: true
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeProductPriority", request, null);
}

export const moveProductPriorityDownAsync = async (itemId: string): Promise<any> => {
    const request: ChangePriorityRequest = {
        entityId: itemId,
        increase: false
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeProductPriority", request, null);
}

export const moveCategoryAttributePriorityUpAsync = async (attributeId: string): Promise<any> => {
    const request: ChangePriorityRequest = {
        entityId: attributeId,
        increase: false
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeCategoryAttributePriority", request, null);
}

export const moveCategoryAttributePriorityDownAsync = async (attributeId: string): Promise<any> => {
    const request: ChangePriorityRequest = {
        entityId: attributeId,
        increase: true
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeCategoryAttributePriority", request, null);
}

export const moveCategoryPriorityUpAsync = async (itemId: string): Promise<any> => {
    const request: ChangePriorityRequest = {
        entityId: itemId,
        increase: true
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeCategoryPriority", request, null);
}

export const moveCategoryPriorityDownAsync = async (itemId: string): Promise<any> => {
    const request: ChangePriorityRequest = {
        entityId: itemId,
        increase: false
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeCategoryPriority", request, null);
}

export const updateCategoryNameAsync = async (categoryId: string, categoryName: string): Promise<any> => {
    const request: EditCategoryRequest = {
        name: categoryName,
        categoryId: categoryId
    };
    return ApiProvider.postAsync<any>("/api/Admin/EditCategory", request, null);
}

export const updateProductVisibilityAsync = async (productId: string, productHidden: boolean): Promise<any> => {
    const request: ChangeVisibilityRequest = {
        entityId: productId,
        hidden: productHidden
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeProductVisibility", request, null);
}

export const updateCategoryVisibilityAsync = async (categoryId: string, categoryHidden: boolean): Promise<any> => {
    const request: ChangeVisibilityRequest = {
        entityId: categoryId!,
        hidden: categoryHidden
    };
    return ApiProvider.postAsync<any>("/api/Admin/ChangeCategoryVisibility", request, null);
}

export const updateCategoryParentAsync = async (categoryId: string, newParentCategoryId?: string | null): Promise<any> => {
    const request: MoveCategoryRequest = {
        categoryId: categoryId,
        newParentCategoryId: newParentCategoryId || null,
    };
    await ApiProvider.postAsync<any>("/api/Admin/MoveCategory", request, null);
    
    return true;
}

export const updateProductCategoryAsync = async (productId: string, categoryId: string): Promise<any> => {
    const request: ChangeProductCategoryRequest = {
        categoryId: categoryId,
        productId: productId
    };
    await ApiProvider.postAsync<any>("/api/Admin/ChangeProductCategory", request, null);

    return true;
}

export const deleteEmptyCategoryAsync = async (categoryId: string): Promise<any> => {
    return ApiProvider.postAsync<any>("/api/Admin/DeleteEmptyCategory", categoryId, null);
}