import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import Schedules from "@/components/Schedules/Schedules";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";

export interface IAdminSchedulesParams extends BasePageParameters {
}

/**
 * Page for admins to see existing schedules.
 */
export default class AdminSchedules extends AdminPage<IAdminSchedulesParams> {

    protected get title(): string {
        return "";
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={"Schedules"}/>

                <Schedules/>
            </PageContainer>

        );
    }
}