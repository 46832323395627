import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import SaveProductToCartRequest from "@/models/server/SaveProductToCartRequest";
import ProductConfirmationModal, {ProductConfirmationResult} from "@/components/ProductConfirmationModal/ProductConfirmationModal";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import PageDefinitions from "@/providers/PageDefinitions";
import PriceHelper from "@/helpers/PriceHelper";
import {
    AddressHelper,
    Button,
    ButtonType,
    CarouselNavigation,
    GoogleMap,
    Icon,
    IconSize,
    IconStyle,
    PageContainer,
    PageHeader,
    SelectListItem,
    Spinner,
    Tab,
    TabContainer,
    TabContainerHeaderStyleType,
    TabRenderType,
} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";
import ImageProvider from "@/providers/ImageProvider";
import ProductAvailabilityDetails from "@/models/server/ProductAvailabilityDetails";
import AddToCartButton from "@/components/AddToCartButton/AddToCartButton";
import ProductOrganizationContractPriceModel from "@/models/server/ProductOrganizationContractPriceModel";
import ProductInfoModel from "@/models/server/ProductInfoModel";
import {assert, FileModel} from "@renta-apps/athenaeum-toolkit";
import Cloner from "@/helpers/Cloner";
import {BasePageParameters, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ProductAttributeModel from "@/models/server/ProductAttributeModel";
import FileApiModel from "@/models/server/FileApiModel";
import DepotModel from "@/models/server/DepotModel";

import FavoriteProductRequest from "@/models/server/FavoriteProductRequest";
import ProductAccessories from "@/components/ProductConfirmationModal/ProductAccessories/ProductAccessories";
import ProductAvailability from "@/pages/ProductDetails/ProductAvailability/ProductAvailability";
import {ProductBreadCrumb} from "@/pages/ProductDetails/ProductBreadCrumb";
import RentaEasyController from "@/pages/RentaEasyController";
import Localizer from "@/localization/Localizer";
import styles from "./ProductDetails.module.scss";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import ProductDetailsPrices from "@/pages/ProductDetails/ProductDetailsPrices/ProductDetailsPrices";
import FullScreenImageGallery from "@/components/FullScreenImageGallery/FullScreenImageGallery";
import AttachedProducts from "@/components/AttachedProducts/AttachedProducts";
import {CustomHtmlHeaders} from "@/helpers/HtmlHeadHelper";

import CreatePreSignedUrlRequest from "../../models/server/Requests/CreatePreSignedUrlRequest";

import ProductDetailsLayout from "@/pages/ProductDetails/components/ProductDetailsLayout";
import ProductDetailsDescription from "@/pages/ProductDetails/components/ProductDetailsDescription";
import ProductDetailsAvailabilityDetails from "@/pages/ProductDetails/components/ProductDetailsAvailabilityDetails";
import {buildAvailabilityMapMarkers, getAvailabilityMapInitialCenter} from "@/helpers/MapHelper";
import ProductDetailsAdminControls from "@/pages/ProductDetails/components/ProductDetailsAdminControls";
import ProductDetailsImages from "@/pages/ProductDetails/components/ProductDetailsImages";
import ProductDetailsVideos from "@/pages/ProductDetails/components/ProductDetailsVideos";
import ProductDetailsTechnicalInformation from "@/pages/ProductDetails/components/ProductDetailsTechnicalInformation";
import productDetailsServices from "@/services/ProductDetailsService";
import GetRelatedProductsRequest from "@/models/server/GetRelatedProductsRequest";
import RelatedProductsResponse from "@/models/server/Responses/RelatedProductsResponse";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";

export interface IProductDetailsParams extends BasePageParameters {
}

interface IProductDetailsState {

    /**
     * Products availabilities in Renta depots.
     */
    availabilities: ProductAvailabilityDetails[];

    isAvailabilitiesLoaded: boolean;

    /**
     * Products discount.
     */
    discount: ProductOrganizationContractPriceModel | null;

    editedProduct: ProductModel | null;

    /**
     * Images which are displayed in the full-screen Carousel.
     */
    fullScreenImages: FileApiModel[];

    /**
     *  Index of the first image to display in the full-screen Carousel.
     */
    fullScreenImagesIndex: number;

    /**
     * Renta depots.
     */
    depots: DepotModel[];

    /**
     * Specific Product model which the user wants to order.
     */
    preferredProductModel: string | null;

    /**
     * Human readable specific Product model name which the user wants to order.
     */
    preferredProductModelName: string | null;

    /**
     * Product the page is displaying.
     */
    product: ShoppingCartProductModel | null;

    /**
     * Keys defined for the Products category.
     */
    categoryKeys: CategoryAttributeKeyModel[];

    /**
     * Names of specific Product models which the user can select as a preferred product they want to order.
     * NOTE: comes from ERP, has nothing to do with the product info models specified by Admin.
     */
    selectableProductModels: string[];

    /**
     * Ids of specific Product models which the user can select as a preferred product they want to order.
     * NOTE: comes from ERP, has nothing to do with the product info models specified by Admin.
     */
    selectableProductModelIds: (number | undefined)[];

    /**
     * Currently selected VAT.
     */
    vat: number;

    /**
     * Product page for google - data in application/ld+json.
     * https://developers.google.com/search/docs/advanced/structured-data/product
     */
    productJson: string | null;

    isUploading: boolean;
}

export default class ProductDetails extends AnonymousPage<IProductDetailsParams, IProductDetailsState> {

    // Fields
    public state: IProductDetailsState = {
        availabilities: [],
        isAvailabilitiesLoaded: false,
        discount: null,
        editedProduct: null,
        fullScreenImages: [],
        fullScreenImagesIndex: 0,
        depots: [],
        product: null,
        categoryKeys: [],
        preferredProductModel: null,
        preferredProductModelName: null,
        selectableProductModels: [],
        selectableProductModelIds: [],
        vat: PriceHelper.userVat,
        productJson: null,
        isUploading: false,
    };
    private readonly _productConfirmationModalRef: React.RefObject<ProductConfirmationModal> = React.createRef();
    // Properties

    private get isFavorite(): boolean {
        return this.product.favorite;
    }

    private get hasShoppingCartProduct(): boolean {
        return !!this.state.product;
    }

    private get availabilities(): ProductAvailabilityDetails[] {
        if (!this.state.preferredProductModel) {
            return this.state.availabilities;
        }

        let availabilities: ProductAvailabilityDetails[] = new Array<ProductAvailabilityDetails>();
        this.state.availabilities.forEach(availability => {
            let filteredAvailability = {
                ...availability,
                // Norway uses productModelId, Sweden uses productModel/productName
                available: availability.available.filter(
                    a => a && (a.productModel === this.state.preferredProductModel ||
                        a.productName === this.state.preferredProductModel ||
                        a.productModelId === this.state.preferredProductModel)),
                rented: availability.rented.filter(
                    a => a && (a.productModel === this.state.preferredProductModel ||
                        a.productName === this.state.preferredProductModel ||
                        a.productModelId === this.state.preferredProductModel)),
                inMaintenance: availability.inMaintenance.filter(
                    a => a && (a.productModel === this.state.preferredProductModel ||
                        a.productName === this.state.preferredProductModel ||
                        a.productModelId === this.state.preferredProductModel)),
                waitingForReturnInspection: availability.waitingForReturnInspection.filter(
                    a => a && (a.productModel === this.state.preferredProductModel ||
                        a.productName === this.state.preferredProductModel ||
                        a.productModelId === this.state.preferredProductModel)),
            };
            filteredAvailability.availableCount = filteredAvailability.available.length;
            filteredAvailability.totalCount = filteredAvailability.availableCount +
                filteredAvailability.rented.length +
                filteredAvailability.inMaintenance.length +
                filteredAvailability.waitingForReturnInspection.length;
            availabilities.push(filteredAvailability);
        });
        return availabilities;
    }

    private get count(): number {
        return this.shoppingCartProduct.count;
    }

    private get productCategories(): string[] {
        const path = [];
        path.push(this.product.category?.name ?? "")
        let category = this.product.category;
        while (category?.parent) {
            category = category.parent;
            path.push(category.name ?? "");
        }
        return path;
    }

    private get description(): string {
        return this.product.description ?? "";
    }

    private get keywords(): string {
        const keywords = [];
        keywords.push(...this.productCategories);
        keywords.push(this.product.name ?? "");
        return keywords.join(", ");
    }

    private get productJson(): string | null {
        return this.state.productJson;
    }

    private get customHeaders(): CustomHtmlHeaders {
        const headers = {} as CustomHtmlHeaders;

        if (this.product.url) {
            const productDetailsRoute = Localizer.getValue(ch.getContext().country, Localizer.pageRoutesProductDetailsLanguageItemName);
            headers["canonical"] = {type: "link", value: `${window.location.origin}/${productDetailsRoute}/${this.product.url}`};
        }

        if (this.productJson) {
            headers["structured-data-list"] = {type: "script", value: this.productJson};
        }

        return headers;
    }

    private get discount(): ProductOrganizationContractPriceModel | null {
        return this.state.discount;
    }

    private get displayingFullScreenImages(): boolean {
        return (this.state.fullScreenImages.length > 0);
    }

    private get editing(): boolean {
        return !!this.state.editedProduct;
    }

    private get editedProduct(): ProductModel {
        return this.state.editedProduct!;
    }

    private get images(): FileApiModel[] {
        return this.product.externalImages ?? [];
    }

    private get isLoggedIn(): boolean {
        return (typeof this.userContext.user?.email === "string");
    }

    private get productConfirmationModal(): ProductConfirmationModal {
        return this._productConfirmationModalRef.current!;
    }

    private get shoppingCartProduct(): ShoppingCartProductModel {
        return this.state.product!;
    }

    private get product(): ProductModel {
        return this.state.product?.product!;
    }

    private  async getRelatedProductsAsync(): Promise<void>{

        // Empty the related products and fetch them with discounted prices.
        this.setState(prev => ({product: { ...prev.product!, relatedProducts: [] }}));
        const getRelatedProductsRequest: GetRelatedProductsRequest = {
            productId: this.shoppingCartProduct.product?.id ?? "",
            contractId: null // Filled in controller level
        };

        const response: RelatedProductsResponse = await RentaEasyController.getRelatedProductsAsync(getRelatedProductsRequest);

        this.setState(prev => ({product: { ...prev.product!, relatedProducts: RelatedProductsResponse.Transform(response.products) }}));
    }


    private get relatedProducts(): ShoppingCartProductModel[] {
        return (Array.isArray(this.shoppingCartProduct.relatedProducts))
            ? this.shoppingCartProduct.relatedProducts
            : [];
    }

    private get selectableProductModelsSelectListItems(): SelectListItem[] {
        const items: SelectListItem[] = [];

        const anyModelItem: SelectListItem = new SelectListItem(null, Localizer.productDetailsAnyModel);

        anyModelItem.selected = true;

        items.push(anyModelItem);

        for (let i = 0; i < this.state.selectableProductModels.length; i++) {
            let name: string = this.state.selectableProductModels[i];
            let id: number | undefined = this.state.selectableProductModelIds[i];
            let value: string = id ? id.toString() : name;

            const item: SelectListItem = new SelectListItem(value, name);
            items.push(item);
        }

        return items;
    }

    private get vat(): number {
        return (this.discount)
            ? 0
            : this.state.vat;
    }

    private get emptyProductInfoModel(): ProductInfoModel {
        const infoModel: ProductInfoModel = new ProductInfoModel();
        this.assignCategoryKeys(infoModel);
        infoModel.attributes.sort((a, b) => a.key!.localeCompare(b.key!, Localizer.language));
        return infoModel;
    }

    // Methods

    private assignCategoryKeys(infoModel: ProductInfoModel): void {
        this.state.categoryKeys.forEach((categoryKey) => {
            if (!infoModel.attributes.find((attribute) => (attribute.key === categoryKey.name))) {
                const newAttribute: ProductAttributeModel = new ProductAttributeModel();
                newAttribute.key = categoryKey.name;
                infoModel.attributes.push(newAttribute);
            }
        });
    }

    private convertCLImagesToEasyImages(images: FileModel[]): FileApiModel[] {
        return images.map((fileModel) => {

            // Old images store their Id in FileModel.Description, which is unused otherwise. Get the old image with it if it exists.
            if (fileModel.description) {
                return this.images.find((image) => (image.id === fileModel.description))!;
            }

            return ImageProvider.convertCLImageToEasyImage(fileModel);
        });
    }

    private async changeShoppingCartProductCountAsync(productId: string, newCount: number, productName: string | null): Promise<void> {
        if (this.isSpinning()) {
            throw new Error("Cannot call onUpdateShoppingCart when another update is already in process");
        }

        const request: SaveProductToCartRequest = {
            productId,
            productName: productName,
            count: newCount,
            preferredModel: this.state.preferredProductModel ?? "",
            preferredModelName: this.state.preferredProductModelName
        };

        await RentaEasyController.saveProductToShoppingCartAsync(request, this);

        await this.updateProductCountsAsync(productId, newCount);
    }

    private async onChange(): Promise<void> {
        await this.getProductData(this.routeId!);

        await this.setEditingAsync(this.editing);

        await this.reRenderAsync();
    }

    private async saveChangesAsync(): Promise<void> {

        const allInfosWithValuesHaveNames: boolean = this
                .editedProduct
                .infos?.where((info) =>
                    (info
                        .attributes
                        .some((attribute) =>
                            assert(attribute.value)
                                .isString
                                .isNotEmpty
                                .isNotWhitespace
                                .getIsSuccess))
                    || info.files?.length > 0)
                .every((info) =>
                    assert(info.name)
                        .isString
                        .isNotEmpty
                        .isNotWhitespace
                        .getIsSuccess)
            ?? true;

        const allInfosWithNamesHaveValues: boolean = this
                .editedProduct
                .infos?.where((info) =>
                    assert(info.name)
                        .isString
                        .isNotEmpty
                        .isNotWhitespace
                        .getIsSuccess)
                .every((info) =>
                    info
                        .attributes
                        .some((attribute) =>
                            assert(attribute.value)
                                .isString
                                .isNotEmpty
                                .isNotWhitespace
                                .getIsSuccess))
            ?? true;

        if (!allInfosWithValuesHaveNames) {
            await this.alertErrorAsync(Localizer.productDetailsErrorInfoWithValuesMustHaveName, true, true);
            return;
        }

        if (!allInfosWithNamesHaveValues) {
            await this.alertErrorAsync(Localizer.productDetailsErrorInfoMustHaveValues, true, true);
            return;
        }

        this.editedProduct.infos = this
                .editedProduct
                .infos?.where((info) =>
                    assert(info.name)
                        .isString
                        .isNotEmpty
                        .isNotWhitespace
                        .getIsSuccess
                    && info
                        .attributes
                        .some((attribute) =>
                            assert(attribute.value)
                                .isString
                                .isNotEmpty
                                .isNotWhitespace
                                .getIsSuccess))
            ?? [];

        await this.uploadImagesAsync(this.editedProduct.externalImages);

        await productDetailsServices.saveProductAsync(this.editedProduct);

        await this.getProductData(this.routeId!);

        await this.setEditingAsync(false);
    }

    private async uploadImagesAsync(images: FileApiModel[] | null): Promise<void> {

        if (images === null) {
            return;
        }

        const uploadPromises = images
            .filter(image => image.src)
            .map(async image => {
                const uploadRequest: CreatePreSignedUrlRequest = {
                    productId: this.product.id,
                    contentType: image.type,
                };
                const uploadResult = await RentaEasyController.uploadImageAsync(image.src, uploadRequest);

                if (uploadResult) {
                    image.src = `/files/images/${uploadResult.fileName}`;
                    image.reference = uploadResult.reference;
                }
            });

        await Promise.all(uploadPromises);
    }

    private async setAlwaysAvailableAsync(alwaysAvailable: boolean): Promise<void> {
        this.editedProduct.showAsAvailable = alwaysAvailable;
        await this.reRenderAsync();
    }

    private async changeVisibilityAsync(checked: boolean): Promise<void> {
        this.editedProduct.hidden = checked;
        await this.reRenderAsync();
    }

    private async setIsRentaFutureAsync(checked: boolean): Promise<void> {
        this.editedProduct.isRentaFuture = checked;
        await this.reRenderAsync();
    }

    private async setDescriptionAsync(description: string | null): Promise<void> {
        this.editedProduct.description = description;
    }

    private async setEditingAsync(editing: boolean): Promise<void> {

        if (!editing) {
            this.setState({editedProduct: null,});
            return;
        }

        const editedProduct: ProductModel = Cloner.clone(this.product);

        if (!Array.isArray(editedProduct.infos)) {
            editedProduct.infos = [];
        } else {
            editedProduct.infos.forEach((info) => {
                info.attributes.sort((attribute1, attribute2) =>
                    attribute1.key!.localeCompare(attribute2.key!, Localizer.language));
                this.assignCategoryKeys(info);
            });
        }

        editedProduct.infos.push(this.emptyProductInfoModel);

        this.setState({
            editedProduct,
        });
    }

    private async toggleFavoriteAsync(oldStatus: boolean): Promise<void> {

        const endpoint = RentaEasyController.getFavoriteOperationEndpoint(oldStatus);

        const request: FavoriteProductRequest = {
            productId: this.product.id,
            userEmail: this.userContext.username
        };

        await this.postAsync(endpoint, request);

        this.product.favorite = !oldStatus;

        await this.reRenderAsync();
    }

    private async setFullScreenImagesAsync(images: FileApiModel[], index: number): Promise<void> {
        await this.setState({
            fullScreenImages: images,
            fullScreenImagesIndex: index
        });
    }

    private async setImagesAsync(images: FileModel[]): Promise<void> {

        const converted: FileApiModel[] = this.convertCLImagesToEasyImages(images);

        this.editedProduct.infos?.forEach((info) => {
            info.files = info.files.filter(file =>
                converted.some((convertedFile) => (JSON.stringify(file) === JSON.stringify(convertedFile))));
        });

        this.editedProduct.externalImages = converted;

        await this.reRenderAsync();
    }

    private async setPreventModelSelection(preventModelSelection: boolean): Promise<void> {
        this.editedProduct.preventModelSelection = preventModelSelection;
        await this.reRenderAsync();
    }

    private async setShowDescriptionAsync(showDescription: boolean): Promise<void> {
        this.editedProduct.showDescription = showDescription;
        await this.reRenderAsync();
    }

    private async setVatAsync(vat: number): Promise<void> {
        this.setState({
            vat,
        });
    }

    private async showProductConfirmationModal(): Promise<void> {
        const result: ProductConfirmationResult = await this.productConfirmationModal.confirmAsync(this.shoppingCartProduct, 1);

        if (result === ProductConfirmationResult.GoToShoppingCart) {
            await PageRouteProvider.redirectAsync(PageDefinitions.shoppingCart.route());
        }
    }

    private async updateProductCountsAsync(productId: string, newCount: number): Promise<void> {

        if (this.product.id === productId) {
            this.shoppingCartProduct.count = newCount;
        }

        if (this.relatedProducts.length > 0) {

            const relatedProduct: ShoppingCartProductModel | undefined = this
                .relatedProducts
                .find((product: ShoppingCartProductModel) =>
                    product.product?.id === productId);

            if (relatedProduct) {
                relatedProduct.count = newCount;
            }
        }

        await this.reRenderAsync();
    }

    private async setPreferredModel(item: SelectListItem | null) {
        this.setState({preferredProductModel: item?.value || null});
        this.setState({preferredProductModelName: item?.text || null});
    }

    protected get title(): string {
        // TODO: update window title after product has loaded
        return this.state.product?.product?.name ?? Localizer.genericProduct;
    }

    private async shareLinkAsync(): Promise<void> {

        if (navigator.share) {
            await navigator.share({url: window.location.href});
        }
        else {
            await navigator.clipboard.writeText(window.location.href);
            await ch.alertMessageAsync(Localizer.productDetailsShareLinkUrlCopied, true, true);
        }
    }

    private renderShareLink(): React.ReactNode {

        return (
            <div className={styles.shareButton}>
                <Button id={"share-link-button"}
                        className={styles.share}
                        icon={{name: "fa-share-alt", style: IconStyle.Regular}}
                        label={Localizer.productDetailsShareLink}
                        type={ButtonType.Orange}
                        onClick={async () => await this.shareLinkAsync()}
                />
            </div>
        );
    }

    private renderPrices(): React.ReactNode {
        const favoriteButtonLabel: string = (this.isFavorite)
            ? Localizer.genericRemoveFromFavorites
            : Localizer.genericAddToFavorites;

        return (
            <>
                <ProductDetailsPrices
                    vat={this.vat}
                    discount={this.discount}
                    product={this.product}
                    selectableProductModels={this.state.selectableProductModels}
                    selectableProductModelsSelectListItems={this.selectableProductModelsSelectListItems}
                    setPreferredModel={async (item) => await this.setPreferredModel(item)}
                    setVatAsync={(vat: number) => this.setVatAsync(vat)}
                    isAuthenticated={this.isAuthenticated}
                />

                <AddToCartButton className={styles.addToCartButton}
                                 count={this.count}
                                 disabled={this.isSpinning()}
                                 onFirstClick={async () => await this.showProductConfirmationModal()}
                                 onCountChange={async (newCount: number, isFirstClick) => {
                                     (!isFirstClick) && await this.changeShoppingCartProductCountAsync(this.product.id, newCount, this.product.name);
                                 }}
                />

                {this.renderShareLink()}

                {(this.isLoggedIn) && (
                    <Button className={styles.favoriteButton}
                            label={favoriteButtonLabel}
                            type={ButtonType.Secondary}
                            icon={{name: "star"}}
                            disabled={this.isSpinning()}
                            onClick={async () => await this.toggleFavoriteAsync(this.isFavorite)}
                    />
                )}
            </>
        );
    }

    private static renderTitleAndFavoriteIcon(title: string, showFavoriteIcon: boolean, isFavorite: boolean, onClick: () => Promise<void>): React.ReactNode {
        const favoriteButtonLabel: string = (isFavorite)
            ? Localizer.genericRemoveFromFavorites
            : Localizer.genericAddToFavorites;
        return (
            <>
                <h3>{title}</h3>

                <div>
                    {(showFavoriteIcon) && (
                        <InlineTooltip
                            text={favoriteButtonLabel}
                            icon={
                                <Icon
                                    id="favorite_product"
                                    name="fa-star"
                                    className="cursor-pointer"
                                    customStyle={{color: isFavorite ? "#ffc040" : "black", fontWeight: isFavorite ? 800 : 400}}
                                    size={IconSize.Large}
                                    style={isFavorite ? IconStyle.Solid : IconStyle.Regular}
                                    onClick={async () => {
                                        await onClick();
                                    }}
                                />
                            }
                        />
                    )}
                </div>
            </>
        );
    }

    private async addVideo(value: string) {
        const videoId = this.extractVideoId(value);
        if (!videoId) {
            return;
        }

        if (this.editedProduct.videos?.every(x => x !== videoId)) {
            this.editedProduct.videos = [videoId].concat(...(this.editedProduct.videos ?? []));
        }
        await this.reRenderAsync();
    }

    private extractVideoId(text: string): string | null {
        const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
        if (!text.match(regex)) {
            return text;
        }

        const result = regex.exec(text);

        return (result && result.length > 3) ? result[3] : null;
    }

    private async deleteVideo(id: string) {
        this.editedProduct.videos = this.editedProduct.videos!.filter(x => x !== id);
        await this.reRenderAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.routeId) {
            await this.toFrontPageAsync();
        }

        const productUrl: string = this.routeId!;

        await this.getProductData(productUrl);

        document.title = this.getTitle();

        await this.getProductAvailability(productUrl);

        await this.getRelatedProductsAsync();
    }

    private async getProductData(productUrl: string): Promise<void> {

        const data = await productDetailsServices.fetchProductDataAsync(productUrl);

        if (!data) {
            await this.toFrontPageAsync(); // TODO: front page or better 404, or even better dedicated product-missing page?
        }

        //Fetch related products in a separate API call
        if (data?.product?.relatedProducts) {
            data.product.relatedProducts = [];
        }
        const discounts = data.discounts;

        const discount = discounts?.productSpecificPrices?.find(
                productPrice =>
                    productPrice.externalId === data.product.product!.externalId
            ) ?? null;

        this.setState({
            categoryKeys: data.categoryKeys,
            discount,
            product: data.product,
        });
    }

    private async getProductAvailability(productUrl: string): Promise<void> {

        const data = await productDetailsServices.fetchProductAvailabilityAsync(productUrl);

        if (!data) {
            await this.toFrontPageAsync(); // TODO: front page or better 404, or even better dedicated product-missing page?
        }

        const availabilities = data.availability || [];

        availabilities.sort(
            (availability1, availability2) => {
                function toPoints(details: ProductAvailabilityDetails): number {
                    return (details.availableCount > 0) ? 2 : ((details.totalCount > 0) ? 1 : 0);
                }

                const point1: number = toPoints(availability1);
                const point2: number = toPoints(availability2);
                return point2 - point1;
            });

        this.setState({
            availabilities,
            isAvailabilitiesLoaded: true,
            depots: data.depots,
            selectableProductModels: data.selectableModels,
            selectableProductModelIds: data.selectableModelIds,
            productJson: (data.offer)
                ? JSON.stringify(data.offer)
                : null
        });
    }

    private static renderInTwoColumnForDesktopAndOneColumnForMobile(isMobile: boolean, mainEl: JSX.Element, sideEl: JSX.Element) {
        return <ProductDetailsLayout isMobile={isMobile} mainEl={mainEl} sideEl={sideEl} />;
    }

    public render(): React.ReactNode {
        if (!this.hasShoppingCartProduct) {
            return <Spinner global/>;
        }

        return (
            <PageContainer className={styles.productDetailsPageContainer} hasWideHeader>
                <PageHeader title={this.title} className={styles.productDetailsPageHeader} wideHeader wideHeaderBackgroundImage="/images/renta-kuva-10-scaled.jpg"/>
                {this.renderHead(this.title, this.keywords, this.description, this.customHeaders)}

                {(this.displayingFullScreenImages) && (
                    <FullScreenImageGallery
                        images={this.state.fullScreenImages}
                        imagesIndex={this.state.fullScreenImagesIndex}
                        imagesAlternativeText={this.product.name}
                        onClose={async () => await this.setFullScreenImagesAsync([], this.state.fullScreenImagesIndex)}
                    />
                )}

                <ProductConfirmationModal displayRelatedProducts displayNumberInput
                                          ref={this._productConfirmationModalRef}
                                          vat={PriceHelper.userVat}
                                          readonly={this.isSpinning()}
                                          onUpdateProductCount={(productId, newCount, productName) => this.changeShoppingCartProductCountAsync(productId, newCount, productName)}
                />

                {/* Static rows on top which takes one row */}
                <div className="d-flex flex-column gap-2 py-2">
                    <div className="row m-0 p-0">
                        <ProductBreadCrumb
                            pricingTool={false}
                            currentProduct={this.state.product?.product ?? null}
                            isFavorites={false}
                            isRentaFuture={false}
                            onBreadCrumbClick={async (item) => {
                                window.history.pushState(null, item.name, item.path);
                                await PageRouteProvider.redirectAsync(PageDefinitions.rent.route({
                                    id: item.id
                                }));
                            }}
                        />
                    </div>
                    <div>
                        {this.renderContractPricesAlert()}
                    </div>

                    <div className="row m-0 p-0">
                        {(this.isAdminWithAdminRole) &&
                            <ProductDetailsAdminControls
                                product={this.product}
                                editedProduct={this.editedProduct}
                                isEditing={this.editing}
                                isLoading={this.isSpinning()}
                                saveChangesAsync={() => this.saveChangesAsync()}
                                setEditingAsync={(value) => this.setEditingAsync(value)}
                                setShowDescriptionAsync={(value) => this.setShowDescriptionAsync(value)}
                                setAlwaysAvailableAsync={(value) => this.setAlwaysAvailableAsync(value)}
                                setPreventModelSelection={(value) => this.setPreventModelSelection(value)}
                                setIsRentaFutureAsync={(value) => this.setIsRentaFutureAsync(value)}
                                changeVisibilityAsync={(value) => this.changeVisibilityAsync(value)}
                            />
                        }
                    </div>
                </div>

                {ProductDetails.renderInTwoColumnForDesktopAndOneColumnForMobile(
                    this.mobile,
                    <>
                        <div className={this.css(styles.pageContentImages)}>
                            <ProductDetailsImages
                                product={this.product}
                                editedProduct={this.editedProduct}
                                images={this.images}
                                isEditing={this.editing}
                                isLoading={this.isSpinning()}
                                setImagesAsync={(images) => this.setImagesAsync(images)}
                                setFullScreenImagesAsync={(images, index) => this.setFullScreenImagesAsync(images, index)}
                            />

                        </div>

                        <div className={styles.pageContentTabContainer}>
                            <TabContainer
                                classNames={{
                                    tabContainer: styles.tabContainerTabContainer,
                                    headerTab: styles.tabContainerHeaderTab,
                                    navTabs: styles.tabContainerNavTabs,
                                    scrollableContainer: styles.tabContainerScrollableContainer
                                }}
                                renderType={TabRenderType.Once}
                                headerStyleType={TabContainerHeaderStyleType.Underline}
                            >
                                <Tab
                                    id="productDetailsTechnicalInfoTab"
                                    title={Localizer.productDetailsTechnicalInfo}
                                >
                                    {(this.state.product?.product) &&
                                        <ProductDetailsTechnicalInformation
                                            product={this.state.product.product}
                                            editedProduct={this.state.editedProduct}
                                            categoryKeys={this.state.categoryKeys}
                                            description={this.description}
                                            isMobile={this.mobile}
                                            isEditing={this.editing}
                                            isLoading={this.isSpinning()}
                                            setDescriptionAsync={(value: string) => this.setDescriptionAsync(value)}
                                            onChange={() => this.onChange()}
                                            alertErrorAsync={(error: string, showCloseButton: boolean, showCancelButton: boolean) => this.alertErrorAsync(error)}
                                            emptyProductInfoModel={() => this.emptyProductInfoModel}
                                            setFullScreenImagesAsync={(images, index) => this.setFullScreenImagesAsync(images, index)}
                                        />
                                    }
                                </Tab>
                                <Tab
                                    id="productDetailsAvailabilityTab"
                                    title={Localizer.productDetailsAvailability}
                                >
                                    <div className="col-12 row m-0 p-0 gap-2">
                                        {!this.state.isAvailabilitiesLoaded &&
                                            <div className={styles.availabilityPlaceholder}>
                                                <Spinner />
                                            </div>
                                        }
                                        {(this.state.isAvailabilitiesLoaded && AddressHelper.isGoogleApiRegistered) && (
                                            <GoogleMap autoCloseInfoWindows
                                                       className={this.css("col-12 p-0", styles.availabilityMap)}
                                                       height={500}
                                                       initialCenter={getAvailabilityMapInitialCenter(this.state.depots)}
                                                       initialZoom={5}
                                                       markers={buildAvailabilityMapMarkers(this.state.depots, this.state.availabilities, this.isAdmin)}
                                            />
                                        )}

                                        {this.state.isAvailabilitiesLoaded && (
                                            <>
                                                <ProductAvailability isAdmin={this.isAdmin}
                                                                     availabilities={this.availabilities}
                                                                     className="col-12 p-0"
                                                />

                                                {(this.isAdmin) && <ProductDetailsAvailabilityDetails availabilities={this.availabilities} externalId={this.product.externalId!}/>}
                                            </>
                                        )}
                                    </div>
                                </Tab>
                                <Tab
                                    id="productDetailsVideosTab"
                                    title={Localizer.productDetailsVideos}
                                >
                                    <ProductDetailsVideos
                                        product={this.product}
                                        editedProduct={this.editedProduct}
                                        isMobile={this.mobile}
                                        isEditing={this.editing}
                                        isLoading={this.isSpinning()}
                                        addVideo={(value: string) => this.addVideo(value)}
                                        deleteVideo={(id: string) => this.deleteVideo(id)}
                                    />
                                </Tab>
                            </TabContainer>
                        </div>
                    </>,
                    <>
                        <div className={this.css(styles.pageContentTitleAndFavoriteIconWrapper)}>
                            {ProductDetails.renderTitleAndFavoriteIcon(this.title, this.isLoggedIn, this.isFavorite, async () => {
                                    await this.toggleFavoriteAsync(this.isFavorite);
                            })}
                        </div>
                        <div className={this.css(styles.pageContentDescription)}>
                            <ProductDetailsDescription
                                product={this.product}
                                editedProduct={this.editedProduct}
                                description={this.description}
                                isEditing={this.editing}
                                isLoading={this.isSpinning()}
                                setDescriptionAsync={(value) => this.setDescriptionAsync(value)}
                            />
                        </div>
                        <div className={this.css(styles.pageContentPrices)}>
                            {this.renderPrices()}
                        </div>
                    </>
                )}

                <div id={"attached_products_container"}>
                    {(this.product.attachedProducts?.length) ?
                        <AttachedProducts
                            attachedProducts={this.product.attachedProducts!}
                            mainProductCount={this.shoppingCartProduct.count || 1}
                        /> : null
                    }
                </div>

                <div id={"product_accessories_container"} className={styles.pageContentAccessories}>
                    {(this.relatedProducts?.length) ? (
                        <>
                            <h2 className={styles.accessoriesTitle}>
                                {Localizer.productDetailsAccessories}
                            </h2>

                            <ProductAccessories relatedProducts={this.relatedProducts}
                                                navigation={CarouselNavigation.Outside}
                                                vat={PriceHelper.userVat}
                                                readonly={this.isSpinning()}
                                                onUpdateProductCount={async (productId, newCount, productName) =>
                                                    this.changeShoppingCartProductCountAsync(productId, newCount, productName)}
                            />
                        </>
                    ) : null}
                </div>
            </PageContainer>
        );
    }
}