import Localizer from "../../../../localization/Localizer";

describe('Desktop - Admin construction site details equipments test', () => {

    const contractId = '31eaa6c0-42fa-40c2-a891-bcd16c367409';

    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

        cy.visit(`/${Localizer.pageRoutesContractDetails}?id=${contractId}`);
    });

    it('should display equipment list that has undefined construction site', () => {

        cy.intercept('POST', '/api/Product/Equipment', { fixture: 'companyDetailsEquipmentList.json' }).as('equipmentRequest');

        cy.intercept('POST', '/api/ConstructionSites/GetSchedules').as('schedulesRequest');

        cy.get('button')
            .eq(0)
            .click();

        cy.wait('@equipmentRequest').then(({ request }) => {
            expect(request.body).to.have.property('customerId', contractId);
            expect(request.body).to.have.property('equipmentOnly', true);
        });

        // Add product to return

        cy.get("#return_items")
            .eq(0)
            .click();

        cy.get("#returnProductsAccordion")
            .click();

        // Fill return form and submit

        cy.get("#input_address")
            .type("Address field");

        cy.get("#city")
            .type("Great city");

        cy.get("#postalCode")
            .type("12345");

        cy.get("#comment")
            .type("Information field");

        cy.intercept('POST', '/api/ConstructionSites/ReturnProduct').as('returnProduct');

        cy.get(".athenaeum-layout-main")
            .find("form")
            .eq(2)
            .submit();

        cy.get('.athenaeum-confirmation-dialog-opened')
            .find('[id^=confirmation-dialog-confirm-]')
            .click();

        cy.wait('@returnProduct').then(({ request }) => {
            expect(request.body).to.have.property('organizationId', contractId);
            expect(request.body).to.have.property('constructionSiteId', null);
        });

    });

});