import FileInfoModel from "@/models/server/FileInfoModel";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import GetMachineModelDocumentRequest from "@/models/server/Requests/GetMachineModelDocumentRequest";
import {ApiProvider} from "@renta-apps/athenaeum-react-common";

export const downloadAsBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};


export const getFileModel = async (fileInfoModel: FileInfoModel): Promise<FileModel> =>  {
    let fileModel = new FileModel(null, fileInfoModel.type);
    if (fileInfoModel.url && fileInfoModel.url.startsWith('http')) {
        fileModel.src = await downloadAsBase64(fileInfoModel.url);
    }
    else {
        const request = new GetMachineModelDocumentRequest();
        request.documentId = fileInfoModel.documentId;

        fileModel = await ApiProvider.postAsync(`/api/Product/GetDocumentStream`, request);
    }

    return fileModel;
};