import Localizer from "../../../localization/Localizer";

describe('Desktop - Logged in company user unconfirmed order tests', () => {

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('Open order details page and verify proper widgets are visible', () => {

        cy.visit('/orderdetails/F9BB2B4A-4CB7-4B30-9DCD-6DFF5E033F83?orderConfirmation=true');

        cy.get('div.athenaeum-alert-alert').contains(Localizer.shoppingCartSentForApproval);

        cy.get('#editModeButton').should('not.exist');

        cy.get('#saveButton').should('not.exist');

        cy.get('h3').contains(Localizer.orderInfoText);

        cy.get('span[data-status="Unconfirmed"]').contains(Localizer.enumOrderStatusUnconfirmed)
    });

});
