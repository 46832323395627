import PlanDefinitionInput from "@/models/server/PlanDefinitionInput";

/**
 * Used for grouping inputs to separate "pages"
 */
export default class PlanInputGroup {

    public groupHeader: string = "";

    public groupId: number = 0;

    public inputs: PlanDefinitionInput[] = [];

}