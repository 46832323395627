import PageDefinitions from "../../providers/PageDefinitions";
import UserModel from "@/models/server/UserModel";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";

export default abstract class AdminPage<TParams extends BasePageParameters = {}, TState = {}>
    extends AuthorizedPage<TParams, TState> {

    protected get user(): UserModel {
        return this.userContext.user!;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.userContext.isAdmin) {

            await PageRouteProvider.redirectAsync(PageDefinitions.login.route({params: {redirectTo: this.getPage().route}}),
                true,
                true);
        }
    }
}