import React from "react";
import { BasePageParameters, PageRouteProvider } from "@renta-apps/athenaeum-react-common";
import { PageContainer, PageHeader, PageRow } from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import EasyPlus from "./EasyPlus";

export interface IEasyPlusPageParams extends BasePageParameters {
    contractId: string;
    userId: string;
}

interface IEasyPlusPageState {
}

export default class EasyPlusPage extends AuthorizedPage<IEasyPlusPageParams, IEasyPlusPageState> {
    protected get title(): string {
        return Localizer.fleetMonitoringPageTitle;
    }

    private async handleConfirmationModalClose() {
        await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
    }

    public render(): React.ReactNode {

        return (
            <PageContainer hasWideHeader>
                <PageHeader wideHeader title={""}>
                    <PageRow>
                        {
                            this.typedParameters && this.typedParameters.contractId && this.typedParameters.userId && (
                                <EasyPlus contractId={this.typedParameters?.contractId}
                                    userId={this.typedParameters?.userId}
                                    onConfirmationModalClose={this.handleConfirmationModalClose}
                                />
                            )
                        }
                    </PageRow>
                </PageHeader>
            </PageContainer>
        );
    }
}