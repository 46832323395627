import React from "react";
import {AddressHelper, GoogleMap, IGoogleMapMarker} from "@renta-apps/athenaeum-react-components";
import {GeoCoordinate, GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

import TrackUnitDevice from "@/models/ProductLocation/Models/TrackUnitDevice";

import RentaEasyConstants from "@/helpers/RentaEasyConstants";

interface IDeviceMapProps {
    trackUnitDevice: TrackUnitDevice;
}

interface IDeviceMapState {
}

export default class DeviceMap extends BaseComponent<IDeviceMapProps, IDeviceMapState> {

    public state: IDeviceMapState = {
        data: null,
        isLoading: false
    };

    private get markers(): IGoogleMapMarker[] {
        let marker = {
            position: {
                lat: this.location!.lat!,
                lng: this.location!.lon!
            }
        } as IGoogleMapMarker;

        if (this.trackUnitDetails.accuracyRadius) {
            marker.circle = {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                radius: this.trackUnitDetails.accuracyRadius,
                center: marker.position
            };
        }

        return [
            marker
        ];
    }

    private get trackUnitDetails(): TrackUnitDevice {
        return this.props.trackUnitDevice;
    }

    private get location(): GeoCoordinate | undefined {
        if (this.trackUnitDetails.location) {
            return new GeoLocation(this.trackUnitDetails.location.latitude, this.trackUnitDetails.location.longitude);
        }

        return {
            lat: RentaEasyConstants.defaultLatitude,
            lon: RentaEasyConstants.defaultLongitude,
            isGeoCoordinate: true
        };
    }

    private get initialZoom(): number {
        const defaultZoom = 17;

        if (this.trackUnitDetails.accuracyRadius) {
            const maxZoom = this.mobile ? 19 : 20;
            const initialZoomValue = this.mobile ? 15 : 16;

            let radiusElevated = this.trackUnitDetails.accuracyRadius + this.trackUnitDetails.accuracyRadius / 2;
            let scale = radiusElevated / 500;
            let zoom = Math.floor(initialZoomValue - Math.log(scale) / Math.log(2));

            return Math.min(zoom, maxZoom);
        }
        return defaultZoom;
    }

    public render(): React.ReactNode {
        return (AddressHelper.isGoogleApiRegistered) && (
            <GoogleMap height={500}
                       initialCenter={{lat: this.location!.lat!, lng: this.location!.lon!}}
                       initialZoom={this.initialZoom}
                       markers={this.markers}
            />
        );
    }
}