import React from "react";
import {Button, ButtonType, IconSize, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import styles from "./ProductLocation.module.scss";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ProductLocationModel from "@/models/ProductLocation/Models/ProductLocationModel";

import {BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";
import FluidLevel from "@/pages/ProductLocation/FluidLevel/FluidLevel";
import FluidLevelModel from "@/models/server/FluidLevelModel";
import ProductModel from "@/models/server/ProductModel";
import RentaEasyController from "@/pages/RentaEasyController";
import DeviceMap from "@/pages/ProductLocation/TrackUnitDetails/DeviceMap/DeviceMap";
import TrackUnitDevice from "@/models/ProductLocation/Models/TrackUnitDevice";
import DeviceUsageChart from "@/pages/ProductLocation/TrackUnitDetails/DeviceUsageChart/DeviceUsageChart";
import Signal from "@/pages/ProductLocation/TrackUnitDetails/Signal/Signal";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import LoadingOrDataNotFound from "@/components/LoadingOrDataNotFound/LoadingOrDataNotFound";
import NoAccess from "@/components/NoAccess/NoAccess";
import {DeviceUsage} from "@/models/ProductLocation/Models/DeviceUsage";

export interface IGetDeviceFluidLevelRequest {
    rentaId: string;
    rentalObjectId?: string;
}

export interface IProductLocationParams extends BasePageParameters {

    /**
     * TrackUnit serial number of the device.
     * This is the main id used for tracking device usage.
     */
    trackingSerialNumber: string | null;

    /**
     * ERP Id of the devices Product Group.
     * Only used in assessing whether the user has access to the device.
     */
    rentalObjectId: string;

    /**
     * ERP Id ("external id") of the device.
     * Used to get fuel level of device and in assessing whether the user has access to the device.
     */
    rentaId: string | null;
}

interface IProductLocationState {
    productModel: ProductModel | null,
    trackUnitDevice: TrackUnitDevice | null,
    fluidLevelModel: FluidLevelModel | null,
    hasAccess: boolean | null,
    isLoading: boolean,
    usageData?: DeviceUsage[] | null;
}

export default class ProductLocation extends AuthorizedPage<IProductLocationParams, IProductLocationState> {

    // Fields

    public state: IProductLocationState = {
        productModel: null,
        trackUnitDevice: null,
        fluidLevelModel: null,
        hasAccess: null,
        isLoading: false,
        usageData: null
    };

    // Properties

    private get hasAccess(): boolean | null {
        return this.state.hasAccess;
    }

    private get hasProductDetails(): boolean {
        return (this.state.productModel != null);
    }

    private get hasFluidLevel(): boolean {
        return this.state.fluidLevelModel?.containerState?.fillPercentage !== undefined &&
            this.state.fluidLevelModel?.containerState?.fillPercentage !== null;
    }

    private get fluidLevel(): number {
        return this.hasFluidLevel
            ? this.state.fluidLevelModel!.containerState!.fillPercentage!
            : 0;
    }

    protected get title(): string {
        return (this.hasProductDetails) && (this.state.productModel?.name)
            ? this.state.productModel?.name
            : "";
    }

    protected get subTitle(): string {
        return "";
    }

    private async hasAccessToProductAsync(): Promise<boolean> {

        const parameters: IProductLocationParams | null = this.typedParameters;

        return await this.postAsync(`/api/Product/HasAccessToProduct`, parameters);
    }

    private async fetchDeviceLocationAsync(): Promise<ProductLocationModel> {

        const parameters: IProductLocationParams | null = this.typedParameters;

        return await this.postAsync(`/api/Locations/GetDeviceLocation`, parameters);
    }

    private async fetchDeviceUsageDataAsync(): Promise<DeviceUsage[] | null> {

        const parameters: IProductLocationParams | null = this.typedParameters;

        return await this.postAsync(`/api/Locations/GetDeviceUsage`, parameters);
    }

    private async fetchDeviceFuelLevelAsync(): Promise<FluidLevelModel> {

        const parameters: IGetDeviceFluidLevelRequest = {
            rentaId: this.typedParameters?.rentaId ?? "",
            rentalObjectId: this.typedParameters?.rentalObjectId ?? ""
        };

        return await this.postAsync(`/api/Locations/GetDeviceFuelLevel`, parameters);
    }

    public async initializeAsync(): Promise<void> {

        await this.setState({ isLoading: true });

        const hasAccess: boolean = await this.hasAccessToProductAsync();

        await this.setState({
            hasAccess: hasAccess,
        });

        if (!hasAccess) {
            // Should not load any data as the user has no access.
            await this.setState({
                isLoading: false,
            });

            return;
        }

        const productModel: ProductModel = await RentaEasyController.getProductDetailsAsync(this.typedParameters!.rentalObjectId);

        const trackUnitBasicData: ProductLocationModel = await this.fetchTrackUnitBasicDataAsync(this.typedParameters!);

        await this.setState({productModel});

        if (trackUnitBasicData?.trackUnitDetails) {
            await this.setState({trackUnitDevice: trackUnitBasicData.trackUnitDetails});
        }

        const productFuelLevelModel = await this.fetchDeviceFuelLevelAsync();

        await this.setState({
            fluidLevelModel: productFuelLevelModel,
            isLoading: false
        });

        const productUsageModel: DeviceUsage[] | null = await this.fetchDeviceUsageDataAsync();

        await this.setState({
            usageData: productUsageModel,
        });
    }

    private async downloadExcelFile(): Promise<void> {
        const parameters: IGetDeviceFluidLevelRequest = {
            rentaId: this.typedParameters?.rentaId ?? "",
            rentalObjectId: this.typedParameters?.rentalObjectId ?? ""
        };

        const data: FileModel = await this.postAsync(`/api/Locations/GetDeviceFuelLevelHistory`, parameters);

        ch.download(data);
    }

    protected async fetchTrackUnitBasicDataAsync(request: IProductLocationParams): Promise<ProductLocationModel> {
        const endpoint: string = this.getEndpoint();

        return await this.postAsync(endpoint, request);
    }

    protected getEndpoint(): string {
        return "/api/Locations/GetDeviceLocation";
    }

    private get hasTrackUnitDetails(): boolean {
        return ((this.state.trackUnitDevice != null));
    }

    private get trackUnitDevice(): TrackUnitDevice {
        return (this.state.trackUnitDevice!);
    }

    private get gpsSignalQuality(): string | undefined {
        if (this.hasTrackUnitDetails && this.state.trackUnitDevice?.gpsSignalQuality) {
            return this.state.trackUnitDevice.gpsSignalQuality;
        }

        return undefined;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        if (this.hasAccess === false) {
            return <NoAccess type="deviceTracking" />
        }

        return (
            <PageContainer className={styles.productLocation}>
                <PageHeader title={this.title}
                            subtitle={this.subTitle}/>
                <PageRow>
                    {
                        (!this.hasTrackUnitDetails && !this.hasFluidLevel) && (
                            <div className={"col-md-12"}>
                                {
                                    <LoadingOrDataNotFound isLoading={this.state.isLoading}
                                                           noDataText={Localizer.equipmentNoAdditionalDetails}
                                    />
                                }
                                <div>
                                    {
                                        (this.state.productModel?.url) && (
                                            <Button label={Localizer.equipmentVisitProductPage}
                                                    type={ButtonType.Orange}
                                                    route={PageDefinitions.productDetails.route({id: this.state.productModel.url})}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        )
                    }
                </PageRow>
                <PageRow>
                    <div className={"col-md-12"}>
                        {
                            (this.hasTrackUnitDetails) && (

                                <DeviceMap trackUnitDevice={this.trackUnitDevice}/>
                            )
                        }
                    </div>
                </PageRow>
                <PageRow>
                    {
                        (this.hasTrackUnitDetails) && (
                            <>
                                <div className={this.css(styles.margin, "col-md-4")}>
                                    {
                                        (!!this.gpsSignalQuality) &&
                                        (
                                            <Signal gpsSignalQuality={this.gpsSignalQuality}/>
                                        )
                                    }
                                    {
                                        (this.hasFluidLevel) &&
                                        (
                                            <FluidLevel fluidLevel={this.fluidLevel}/>
                                        )
                                    }

                                </div>
                                <div className={"col-md-8"}>
                                    <DeviceUsageChart usageData={this.state.usageData} />
                                </div>
                            </>
                        )
                    }
                </PageRow>
                <PageRow>
                    {
                        (!this.hasTrackUnitDetails && this.hasFluidLevel) && (
                            <div className={"col-md-12"}>
                                <FluidLevel fluidLevel={this.fluidLevel}/>
                                <Button id={"downloadExcelButton"}
                                        label={Localizer.emissionsDownload}
                                        icon={{name: "fa fa-download", size: IconSize.Large}}
                                        type={ButtonType.Orange}
                                        onClick={async () => await this.downloadExcelFile()}
                                />
                            </div>
                        )
                    }

                </PageRow>

            </PageContainer>
        );

    }
}