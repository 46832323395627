import React from "react";
import DiscountStatusLog from "@/models/server/Models/PricingTool/DiscountStatusLog";
import styles from "@/components/PricingTool/PricingToolStatusLog/PricingToolStatusLog.module.scss";
import {PricingToolDiscountStatus} from "@/models/Enums";

type PricingToolStatusLogProps = {
    statusLog: DiscountStatusLog[];
};

const PricingToolStatusLog: React.FC<PricingToolStatusLogProps> = ({ statusLog }) => {
    return (
        <div id="statusLogContainer">
            {statusLog.map((statusLog: DiscountStatusLog, index: number) => {
                return (
                    <div className={styles.row} key={index} >
                        <div className={styles.labelColumn} >
                            {PricingToolDiscountStatus[statusLog.status]}
                        </div>
                        <div className={styles.dateColumn} title={statusLog.changedAt && statusLog.changedAt.toLocaleString()}>
                            {statusLog.changedAt ? statusLog.changedAt.toLocaleDateString() : "-"}
                        </div>
                        <div className={styles.valueColumn} >
                            {statusLog.changedByUser ? statusLog.changedByUser.email : "-"}
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default PricingToolStatusLog;