import ProductAttributeModel from "@/models/server/ProductAttributeModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import FileApiModel from "@/models/server/FileApiModel";

export default class ProductInfoModel {
    public id: string = RentaEasyConstants.emptyGuid;

    public name: string | null = null;

    public attributes: ProductAttributeModel[] = [];

    public files: FileApiModel[] = [];
}