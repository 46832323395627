import React from "react";
import styles from "@/pages/ProductDetails/ProductDetails.module.scss";
import Localizer from "@/localization/Localizer";
import {Button, ButtonType, TextInput} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";
import YouTube, {YouTubeProps} from "react-youtube";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";

type ProductDetailsVideosProps = {
    product: ProductModel | null;
    editedProduct: ProductModel | null;
    isMobile: boolean;
    isEditing: boolean;
    isLoading: boolean;

    addVideo: (videoId: string) => Promise<void>;
    deleteVideo: (videoId: string) => Promise<void>;
}

const ProductDetailsVideos: React.FC<ProductDetailsVideosProps> = (props: ProductDetailsVideosProps) => {

    const opts: YouTubeProps['opts'] = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            playsinline: 1,
            fs: props.isMobile ? 0 : 1,
        },
    };

    return (
        <>
            {(props.isEditing && props.editedProduct) ? (
                <div className={`col-12 d-flex flex-column m-0 p-0 gap-2 ${styles.videosContainer}`}>
                    <div className="col-12 row m-0 p-0 gap-2">
                        <TextInput
                            label={Localizer.productDetailsVideoId}
                            inline
                            onBlur={async (input) => await props.addVideo(input.value)}
                            className={styles.videoInput}
                        />
                        <InlineTooltip text={Localizer.productDetailsAddVideoTooltip} className={styles.videoTooltip}/>
                    </div>
                    {
                        (props.editedProduct?.videos ?? []).map(videoId => (
                            <div key={videoId} className="col-12 d-flex flex-column m-0 p-0 gap-2">
                                <div className="col-12 row m-0 p-0 gap-2">
                                    <TextInput label="VideoId" value={videoId} inline readonly className={styles.videoInput}/>
                                    <Button icon={{name: "trash"}} type={ButtonType.Orange} onClick={async () => await props.deleteVideo(videoId)}/>
                                </div>
                                <YouTube videoId={videoId} className={styles.videoContainer} opts={opts}/>
                            </div>
                        ))
                    }
                </div>
                ) : (
                <div className={`col-12 d-flex flex-column m-0 p-0 gap-2 ${styles.videosContainer}`}>
                    {(props.product?.videos ?? []).map(videoId => 
                        <YouTube key={videoId} videoId={videoId} className={styles.videoContainer} opts={opts}/>
                    )}
                </div>
            )}
        </>
    );
}

export default ProductDetailsVideos;