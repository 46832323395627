import React from "react";
import {Button, ButtonType, Checkbox, ConfirmationDialog, Dropdown, EmailInput, Form, IconSize, Inline, InlineType, Modal, ModalSize, OneColumn, PageContainer, PageHeader, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import PageDefinitions from "@/providers/PageDefinitions";
import DetailsPanel from "@/components/UsersGrid/DetailsPanel/DetailsPanel";
import {OrganizationContractRoleModel} from "@/models/server/OrganizationContractRoleModel";
import {ConstructionSiteRoleModel} from "@/models/server/ConstructionSiteRoleModel";
import {AlertModel, ApiProvider, BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import RentaEasyController from "@/pages/RentaEasyController";
import DepotModel from "@/models/server/DepotModel";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import Localizer from "../../localization/Localizer";

import styles from './MyAccount.module.scss';
import UnleashHelper from "@/helpers/UnleashHelper";
import {validatePhoneNumber} from "@/helpers/Validators";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export interface IMyAccountParams extends BasePageParameters {
}

interface IMyAccountState {
    rentalOffices: DepotModel[],
    alertModel: AlertModel | null,
}

export default class MyAccount extends AuthorizedPage<IMyAccountParams, IMyAccountState> {
    state: IMyAccountState = {
        rentalOffices: [],
        alertModel: null,
    };

    private readonly _agreementRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _registrationRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _confirmationRef: React.RefObject<ConfirmationDialog> = React.createRef();
    private readonly _marketingListRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _adminDebugRef: React.RefObject<Checkbox> = React.createRef();

    private get agreementCheckbox(): Checkbox {
        return this._agreementRef.current!;
    }

    private get registrationCheckbox(): Checkbox {
        return this._registrationRef.current!;
    }

    protected get title(): string {
        return Localizer.myAccountTitle;
    }

    private get email(): string {
        return this.user?.email ?? "";
    }

    private get vatId(): string {
        return this.user?.vatId ?? "";
    }

    private get phoneNumber(): string {
        return this.user?.phoneNumber ?? "";
    }

    private get firstName(): string {
        return this.user?.firstName ?? "";
    }

    private get lastName(): string {
        return this.user?.lastName ?? "";
    }

    private get address(): string {
        return this.user?.address ?? "";
    }

    private get city(): string {
        return this.user?.city ?? "";
    }

    private get postalCode(): string {
        return this.user?.postalCode ?? "";
    }

    private get rentalOffices(): DepotModel[] {
        return this.state.rentalOffices;
    }

    private get organizationRoles(): OrganizationContractRoleModel[] {
        return this.user?.organizationRoles ?? [];
    }

    private get constructionsiteRoles(): ConstructionSiteRoleModel[] {
        return this.user?.constructionSiteRoles ?? [];
    }

    private get hasRoles(): boolean {
        return (this.organizationRoles.length > 0) || (this.constructionsiteRoles.length > 0);
    }

    public async handleSaveAdminDebug(data: Dictionary<string, any>): Promise<any> {
        const value: boolean = data.getValue("adminDebug");
        await this.postAsync("/api/Users/SaveUserShowDebugInfo", value);
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {

        if (!this.agreementCheckbox.checked) {
            await this.alertErrorAsync(Localizer.registerAgreementNeedsToBeAccepted, true, false);
        }
        else if (!this.registrationCheckbox.checked) {
            await this.alertErrorAsync(Localizer.registerRegisterNeedsToBeAccepted, true, false);
        }
        else {
            this.user.favoriteDepotId = data.getValue("selectedRentaOffice");
            this.user.vatId = data.getValue("vatId");
            this.user.email = data.getValue("email");
            this.user.address = data.getValue("address");
            this.user.city = data.getValue("city");
            this.user.firstName = data.getValue("firstname");
            this.user.lastName = data.getValue("lastName");
            this.user.phoneNumber = data.getValue("phone");
            this.user.postalCode = data.getValue("postalCode");
            this.user.registerAccepted = data.getValue("registrationAccepted");
            this.user.agreementAccepted = data.getValue("agreementAccepted");
            this.user.approveMarketing = data.getValue("marketingAccepted");
            await this.postAsync("/api/Users/SaveUser", this.user);
        }
    }

    private async confirm(message: string): Promise<boolean> {
        const dialog: ConfirmationDialog = this._confirmationRef.current!;
        return await dialog.confirmAsync(message);
    }

    private get vatIdForUsersRequired(): boolean {
        return UnleashHelper.isEnabled(RentaEasyConstants.featureFlagVatIdForUsers);
    }

    public async initializeAsync(): Promise<void> {

        await super.initializeAsync();

        const rentalOffices: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        await this.setState({
            rentalOffices
        });
    }

    private async deleteAccountAsync(): Promise<void> {
        const confirmed = await this.confirm(Localizer.myAccountDeleteAccountConfirmation);

        if (confirmed) {
            await this.postAsync("/api/Users/ConfirmDeleteUser", this.email);
        }
    }

    private async redirectToPasswordPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.changePassword.route());
    }

    private onSignicatClickAsync(): Promise<void> {
        return this.onSsoClickAsync("GetSignicatSsoLogin");
    }

    private async onSsoClickAsync(action: string): Promise<void> {
        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => this.getAsync<string>(`/api/Application/${action}`), true);
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.myAccount} hasWideHeader>
                <PageHeader title={this.title} wideHeader wideHeaderBackgroundImage="/images/renta-kuva-8-scaled.jpg"/>
                <div className="d-flex flex-column gap-4 pb-5">
                    {
                        (this.user) &&
                        (
                            <Form id="form"
                                  onSubmit={async (_, data) => await this.handleSubmitAsync(data)}
                            >
                                <TwoColumns className={this.css(styles.lessMargin)}>
                                    <EmailInput required
                                                id="email"
                                                label={Localizer.genericEmail}
                                                value={this.email}
                                                readonly={!!this.email}/>

                                    {this.vatIdForUsersRequired &&
                                        <TextInput required
                                                   id="vatId"
                                                   maxLength={200}
                                                   label={Localizer.genericVatId}
                                                   value={this.vatId ?? ""}
                                        />
                                    }
                                </TwoColumns>

                                <TwoColumns className={this.css(styles.lessMargin)}>
                                    <TextInput required
                                               id="firstname"
                                               label={Localizer.genericFirstName}
                                               value={this.firstName}
                                    />

                                    <TextInput required
                                               id="lastName"
                                               label={Localizer.genericLastName}
                                               value={this.lastName}
                                    />
                                </TwoColumns>

                                <OneColumn className={this.css(styles.lessMargin)}>
                                    <TextInput required
                                               id="address"
                                               label={Localizer.genericAddress}
                                               value={this.address}
                                    />

                                </OneColumn>

                                <TwoColumns className={this.css(styles.lessMargin)}>
                                    <TextInput required
                                               id="postalCode"
                                               label={Localizer.genericPostalCode}
                                               value={this.postalCode}
                                    />

                                    <TextInput required
                                               id="city"
                                               label={Localizer.genericCity}
                                               value={this.city}
                                    />
                                </TwoColumns>

                                <TwoColumns className={this.css(styles.lessMargin)}>
                                    <TextInput required
                                               id="phone"
                                               label={Localizer.genericPhoneNumber}
                                               value={this.phoneNumber}
                                               validators={[validatePhoneNumber]}
                                               placeholder={Localizer.genericPlaceholderPhoneNumber}
                                    />

                                    <Dropdown required
                                              id="selectedRentaOffice"
                                              label={Localizer.myAccountClosestRentaOffice}
                                              items={this.rentalOffices}
                                              selectedItem={this.rentalOffices.find(item => item.id === this.user?.favoriteDepotId)}
                                    />
                                </TwoColumns>

                                <OneColumn className="mb-3">
                                    <Inline>
                                        <Checkbox inline
                                                  ref={this._registrationRef}
                                                  id="registrationAccepted"
                                                  label={Localizer.myAccountIHaveRedAndAgree}
                                                  inlineType={InlineType.Right}
                                                  value={this.user.registerAccepted && !this.user.isRegisterAcceptedObsolete}
                                                  readonly={this.user.registerAccepted && !this.user.isRegisterAcceptedObsolete}
                                        />

                                        <Button toggleModal
                                                className={this.css(styles.textbutton)}
                                                label={Localizer.myAccountPrivacyNotice}
                                                type={ButtonType.Text}
                                                dataTarget="privacyPolicyModal"
                                        />
                                    </Inline>

                                    <Inline>
                                        <Checkbox inline
                                                  ref={this._agreementRef}
                                                  id="agreementAccepted"
                                                  label={Localizer.myAccountIHaveRedAndAgree}
                                                  inlineType={InlineType.Right}
                                                  value={this.user.agreementAccepted && !this.user.isAgreementAcceptedObsolete}
                                                  readonly={this.user.agreementAccepted && !this.user.isAgreementAcceptedObsolete}
                                        />

                                        <Button toggleModal
                                                className={this.css(styles.textbutton)}
                                                label={Localizer.myAccountRegisterTitle}
                                                type={ButtonType.Text}
                                                dataTarget="agreementModal"
                                        />
                                    </Inline>

                                    <Inline>
                                        <Checkbox inline
                                                  ref={this._marketingListRef}
                                                  id="marketingAccepted"
                                                  label={Localizer.myAccountApproveMarketing}
                                                  inlineType={InlineType.Right}
                                                  value={this.user.approveMarketing}
                                        />
                                    </Inline>
                                </OneColumn>

                                <div className="d-flex gap-2 flex-column flex-md-row">

                                    <Button type={ButtonType.Blue}
                                            label={Localizer.myAccountChangePassword}
                                            disabled={this.isSpinning()}
                                            onClick={() => this.redirectToPasswordPage()}
                                    />

                                    <Button submit
                                            type={ButtonType.Orange}
                                            label={Localizer.formSave}
                                            disabled={this.isSpinning()}
                                            icon={{name: "far save"}}
                                    />

                                    <Button type={ButtonType.Danger}
                                            label={Localizer.myAccountDeleteAccount}
                                            icon={{name: "trash", size: IconSize.Normal}}
                                            disabled={this.isSpinning()}
                                            className={this.css(styles.textbutton)}
                                            onClick={async () => await this.deleteAccountAsync()}
                                    />

                                    {
                                        (!this.user.strongAuthBoundToUser) &&
                                        (
                                            <FeatureSwitch flagName={RentaEasyConstants.featureFlagSsoLogin}>
                                                <Button type={ButtonType.Light}
                                                        label={Localizer.loginBankLogin}
                                                        onClick={() => this.onSignicatClickAsync()}
                                                />
                                            </FeatureSwitch>

                                        )
                                    }

                                </div>
                            </Form>
                        )
                    }
                    <div className="border-top my-3"></div>

                    {
                        (this.hasRoles) &&
                        (
                            <div className="d-flex flex-column gap-2">
                                <h2 className={styles.rolesTitle}>{Localizer.myAccountMyAccess}</h2>

                                <DetailsPanel organizationRoles={this.organizationRoles}
                                              constructionSiteRoles={this.constructionsiteRoles}
                                              canManageUsers={false}
                                              className="m-0"
                                />
                            </div>
                        )
                    }

                    {
                        (this.isAdminWithAdminRole) && (

                            <div className="d-flex flex-column gap-2">
                                <h2 className={styles.rolesTitle}>{Localizer.myAccountAdminOptions}</h2>
                                <Form id="adminDebugForm"
                                      onSubmit={async (_, data) => await this.handleSaveAdminDebug(data)}>
                                    <Checkbox inline
                                              ref={this._adminDebugRef}
                                              id="adminDebug"
                                              label={Localizer.myAccountAdminOptionsShowDebugInfo}
                                              inlineType={InlineType.Right}
                                              value={this.user.showDebugInfo}
                                    />
                                    <Button submit
                                            style={{marginTop: "10px"}}
                                            id={"save_user_debug"}
                                            type={ButtonType.Orange}
                                            label={Localizer.formSave}
                                            disabled={this.isSpinning()}
                                            icon={{name: "far save"}}
                                    />
                                </Form>
                            </div>

                        )
                    }

                    <Modal info keepTextFormatting isBodyScrollable
                           id="agreementModal"
                           title={Localizer.userAgreementTitle}
                           content={Localizer.userAgreementText}
                           size={ModalSize.ExtraLarge}
                    />

                    <ConfirmationDialog ref={this._confirmationRef}/>
                </div>
            </PageContainer>
        );
    }
}