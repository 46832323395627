import ConstructionSiteReturn from "./ConstructionSiteReturn";

export default class ReturnModel {
    public products: ConstructionSiteReturn[] = [];

    public organizationId: string | null = null;

    public constructionSiteId: string | null = null;

    public pickupTime: Date = new Date();

    public address: string = "";

    public city: string = "";

    public postalCode: string = "";

    public comment: string = "";

    public internalNotes: string = "";

    public contactEmail: string = "";

    public contactPhone: string = "";

    public nonUserFirstName: string | null = null;

    public nonUserLastName: string | null = null;

    public nonUserEmail: string | null = null;

    public nonUserPhone: string | null = null;

    public availableForPickup: boolean = false;
    /**
     * Easy Id of the user who the return was made for.
     */
    public returnedOnBehalf: string | null = null;

    /**
     * Invited user info while returning the products
     */
    public invitedUserFirstName: string | null = null;

    public invitedUserLastName: string | null = null;

    public invitedUserEmail: string | null = null;

    public invitedUserTelephone: string | null = null;
}