import React from "react";
import {Button, IButtonProps} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import styles from "./ButtonWithSpinner.module.scss";

interface ButtonWithSpinnerProps extends IButtonProps {
    spinning: boolean;
}

const ButtonWithSpinner: React.FC<ButtonWithSpinnerProps> = ({spinning, icon, disabled, className, ...restProps}: ButtonWithSpinnerProps) => {
    return (
        <Button disabled={spinning || disabled}
                icon={spinning ? (icon ?? {className: 'fa-spin', name: 'spinner'}) : undefined}
                className={Utility.css(styles.buttonWithSpinner, className)}
                {...restProps}
        />
    );
}

export default ButtonWithSpinner;