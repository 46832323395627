import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import InviteUserPostRequest from "@/models/server/InviteUserPostRequest";
import Localizer from "@/localization/Localizer";
import InviteUserRequest from "@/models/server/Requests/InviteUserRequest";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";

import styles from "@/pages/InviteUser/InviteUser.module.scss";
import InviteUserForm from "@/components/InviteUserForm/InviteUserForm";
import InviteUserPageDataResponse from "@/models/server/Responses/InviteUserPageDataResponse";
import RentaEasyController from "@/pages/RentaEasyController";

export interface IInviteUserLegacyParams extends BasePageParameters {

    /**
     * Id of the Contract to invite to.
     * @deprecated Use {@link IInviteUserParams.contractId} in new code.
     */
    id?: string;
}

interface IResponse {
    alertMessage: string,
    errorMessage: string
}

export interface IInviteUserParams extends BasePageParameters, IInviteUserLegacyParams {

    /**
     * Id of the Contract to invite to.
     */
    contractId: string;

    /**
     * Id of the Construction Site to invite to.
     */
    constructionSiteId?: string | null;
}

interface IInviteUserState {
    data: InviteUserPageDataResponse | null;
    sending: boolean
}

export default class InviteUser extends AuthorizedPage<IInviteUserParams, IInviteUserState> {
    // Fields

    public state: IInviteUserState = {
        data: null,
        sending: false
    };

    // Properties

    private get data(): InviteUserPageDataResponse {
        return this.state.data!;
    }

    protected get title(): string {
        return Localizer.inviteUserNewInviteText;
    }

    // Methods

    private async sendInvitesAsync(request: InviteUserPostRequest): Promise<void> {
        this.setState({ sending: true });

        const result: IResponse = await this.postAsync(`/api/Users/InviteUser`, request);

        this.setState({ sending: false });

        if (result.alertMessage === "") {
            await this.alertErrorAsync(result.errorMessage);
        }
        else {
            await this.alertMessageAsync(result.alertMessage);
        }
    }

    public async initializeAsync(): Promise<void> {

        const contractId: string | undefined = this.typedParameters?.contractId ?? this.typedParameters?.id;

        if (!contractId || (this.userContext.isConstructionSiteUser && this.userContext.isSingleConstructionSiteMainUserUser)) {
            await this.toFrontPageAsync();
            return;
        }

        const request: InviteUserRequest = {
            contractId,
            constructionSiteId: this.typedParameters?.constructionSiteId ?? null,
        };

        const data: InviteUserPageDataResponse = await RentaEasyController.getInvitationPageData(request);

        this.setState({ data });
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer id={styles.inviteUser}
                           className={this.css(styles.inviteUser)}
            >
                <PageHeader title={this.title}
                            subtitle={`${this.data?.organizationContract?.name ?? ""} ${this.data?.organizationContract?.vatId ?? ""}`}
                            className={this.css(styles.inviteUserHeader)}
                />

                {
                    (this.data) &&
                    (
                        <InviteUserForm data={this.data}
                                        contractId={this.data.organizationContract!.contractId!}
                                        saveButtonText={Localizer.inviteUserSendInvitation}
                                        onSave={async (request) => await this.sendInvitesAsync(request)}
                                        constructionSiteId={this.typedParameters?.constructionSiteId}
                                        multiple
                                        displaySaveButtonIcon
                                        sending={this.state.sending}
                        />
                    )
                }

            </PageContainer>
        );
    }
}