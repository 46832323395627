import { FC } from 'react';
import {Pagination} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import styles from "@/components/ConstructionSitesList/ConstructionSitesList.module.scss";

type ConstructionSitesListPaginationProps = {
    pageSize: number,
    pageNumber: number,
    itemCount: number,
    totalItemCount: number,
    allSitesCount: number,
    changePageAsync: (pageNumber: number, pageSize: number) => Promise<void>,
}
const ConstructionSitesListPagination: FC<ConstructionSitesListPaginationProps> = (props: ConstructionSitesListPaginationProps) => {
    
    return (
        <div>
            {(props.itemCount > 0) && (
                <Pagination
                    pageSize={props.pageSize}
                    itemCount={props.itemCount}
                    totalItemCount={props.totalItemCount}
                    pageNumber={props.pageNumber}
                    classNames={{pageDropdownWrap: styles.paginationPageDropdownWrap}}
                    onChange={async (sender, pageNumber, pageSize) => await props.changePageAsync(pageNumber, pageSize)}
                />
            )}
            
            {(props.itemCount === 0 && props.allSitesCount > 0) && (
                <div>
                    {Localizer.get(Localizer.constructionSitesPageCountOfSites, props.itemCount, props.allSitesCount)}
                </div>
            )}
            
            {(props.itemCount === 0 && props.allSitesCount === 0) && (
                <div>{Localizer.constructionSitesPageNoSites}</div>
            )}
        </div>
    );
}

export default ConstructionSitesListPagination;