import React from "react";
import {
    Button,
    ButtonType,
    Checkbox,
    InlineType,
    PageContainer,
    PageHeader,
    PageRow,
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import Localizer from "@/localization/Localizer";
import styles from "./PricingToolCatalog.module.scss";
import SingleDiscountRequest from "@/models/server/Requests/PricingTool/SingleDiscountRequest";
import PricingToolDiscountModel from "@/models/server/Models/PricingTool/PricingToolDiscountModel";
import Catalog from "@/components/Catalog/Catalog";
import SaveCategoryDiscountRequest from "@/models/server/Requests/PricingTool/SaveCategoryDiscountRequest";
import SaveProductDiscountRequest from "@/models/server/Requests/PricingTool/SaveProductDiscountRequest";
import CategoryModel from "@/models/server/CategoryModel";
import ProductModel from "@/models/server/ProductModel";
import PageDefinitions, {IPageRouteParams} from "@/providers/PageDefinitions";
import {IPricingToolDiscountParams} from "@/pages/PricingToolDiscount/PricingToolDiscount";
import {IPricingToolTemplateParams} from "@/pages/PricingToolTemplate/PricingToolTemplate";

export interface IPricingToolCatalogParams extends BasePageParameters {
    discountId: string;
    discountType: 'discount' | 'template';
    categoryId?: string;
}

interface IDiscountCatalogState {
    selectedDiscount: PricingToolDiscountModel | null;
    ignoreHidden: boolean;
    ignoreSalesProducts: boolean;
    categoryId: string;
}

export default class PricingToolCatalog extends AdminPage<IPricingToolCatalogParams, IDiscountCatalogState> {
    public state: IDiscountCatalogState = {
        selectedDiscount: null,
        ignoreHidden: true,
        ignoreSalesProducts: true,
        categoryId: "",
    };

    protected get title(): string {
        return Localizer.admin;
    }
    
    private readonly _catalogRef: React.RefObject<Catalog> = React.createRef();

    public async initializeAsync(): Promise<void> {
        await this.getDiscountInfoAsync();
        await super.initializeAsync();
        this.setState({categoryId: this.props.parameters?.categoryId ?? ""});
    }

    public async onBackToDiscountClickAsync(): Promise<void> {
        const route = this.props.parameters?.discountType === 'discount'
            ? PageDefinitions.pricingToolDiscount.route({params: {discountId: this.state.selectedDiscount?.discountId}} as IPageRouteParams<IPricingToolDiscountParams>)
            : PageDefinitions.pricingToolTemplate.route({params: {discountId: this.state.selectedDiscount?.discountId}} as IPageRouteParams<IPricingToolTemplateParams>);
        await PageRouteProvider.redirectAsync(route);
    }
    
    public async onCategoryDiscountChangeAsync(category: CategoryModel): Promise<void> {
        if (this.props.parameters?.discountId) {
            const request: SaveCategoryDiscountRequest = new SaveCategoryDiscountRequest();
            request.discountId = this.props.parameters?.discountId;
            request.categoryId = category.id;
            request.discountPercentage = category.discountPercentage;
            await this.postAsync("/api/PricingTool/UpdateCategoryDiscount", request);
        }
    }

    public async onProductDiscountChangeAsync(product: ProductModel): Promise<void> {
        if (this.props.parameters?.discountId) {
            const request: SaveProductDiscountRequest = new SaveProductDiscountRequest();
            request.discountId = this.props.parameters?.discountId;
            request.categoryId = product.category?.id ?? "";
            request.productId = product.id;
            request.discountPercentage = product.discountPercentage;
            await this.postAsync("/api/PricingTool/UpdateProductDiscount", request);
        }
    }
    
    private async getDiscountInfoAsync(): Promise<void> {
        const request: SingleDiscountRequest = new SingleDiscountRequest();
        request.discountId = this.props.parameters?.discountId;
        if (!request.discountId) {
            return;
        }
        const discount: PricingToolDiscountModel = await this.postAsync("/api/PricingTool/GetContractDiscount", request);
        if (discount) {
            this.setState({
                selectedDiscount: discount,
            });
        }
    }

    public renderComponents(): React.ReactNode {
        return (
            <div>
                <div className={styles.topRow}>
                    <Button id="overallPreviousButton"
                            label={Localizer.pricingToolBackToSettingsButtonLabel}
                            icon={{name: "angle-left"}}
                            type={ButtonType.Orange}
                            onClick={async () => await this.onBackToDiscountClickAsync()}
                    />
                    <div className={styles.checkBoxesContainer}>
                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  value={this.state.ignoreHidden}
                                  label="Ignore hidden"
                                  onChange={async (_, checked) => this.setState({ ignoreHidden: checked })}
                        />
                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  value={this.state.ignoreSalesProducts}
                                  label="Ignore sales products"
                                  onChange={async (_, checked) => this.setState({ ignoreSalesProducts: checked })}
                        />
                    </div>
                </div>
                <Catalog pricingToolMode
                         onBreadCrumbChange={async (items) => undefined}
                         discountId={this.props.parameters?.discountId}
                         discountType={this.props.parameters?.discountType}
                         categoryId={this.props.parameters?.categoryId}
                         onCategoryDiscountChangeAsync={async (category) => await this.onCategoryDiscountChangeAsync(category)}
                         onProductDiscountChangeAsync={async (product) => await this.onProductDiscountChangeAsync(product)}
                         ignoreHidden={this.state.ignoreHidden}
                         ignoreSalesProducts={this.state.ignoreSalesProducts}
                />
            </div>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.pricingTool}>
                <PageHeader title={Localizer.pricingToolEditDiscountHeader} className={styles.header} />
                <PageRow>
                    <div className="col">
                        <div>
                            {(this.renderComponents())}
                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}