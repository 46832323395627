import React, {useMemo} from "react";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import EnvironmentControlHelper from "@/helpers/EnvironmentControlHelper";
import {Button, ButtonType} from "@renta-apps/athenaeum-react-components";
import styles from "./SensorsTable.module.scss";
import Localizer from "@/localization/Localizer";
import {Utility} from "@renta-apps/athenaeum-toolkit";

type SensorsTableProps = {
    devices: EnvironmentDevice[],
    language: string,
    selectedSensor: {deviceId: string, sensorId?: number} | null,
    onSensorClick?: (deviceId: string, sensors: number[]) => Promise<void>,
    onDeviceSelected: (deviceId: string, sensorId: number) => void,
    onDeviceUnselected: () => void,
};

type SensorTableRow = {
    deviceId: string | null,
    sensorId: number,
    name: string,
    type: string,
    value: string | null,
    min: string,
    max: string,
    updated: string,
};

const SensorsTable: React.FC<SensorsTableProps> = (props: SensorsTableProps) => {
    const sensors: SensorTableRow[] = useMemo(() => {
        return props.devices
            .filter(item => item.sensors.length > 0)
            .sort((deviceA, deviceB) => (deviceA.name ?? '').localeCompare(deviceB.name ?? ''))
            .flatMap(device => EnvironmentControlHelper.orderSensors(device, [1, 5, 0, 3]).map(sensor => ({
                deviceId: device.id,
                sensorId: sensor.id,
                name: device.name ?? '',
                // 0 - structure temperature, 3 - structure humidity
                type: `${[0, 3].includes(sensor.id)
                    ? Localizer.environmentControlSensorGridStructure
                    : Localizer.environmentControlSensorGridAir} (${EnvironmentControlHelper.getSensorUnit(sensor)})`,
                value: sensor.latestValue?.toFixed(1) ?? null,
                min: sensor.min.toFixed(1),
                max: sensor.max.toFixed(1),
                updated: EnvironmentControlHelper.getSensorRefreshTime(sensor, props.language, 'narrow')
            })));
    }, [props.devices, props.language]);
    
    const isSelected = (sensor: SensorTableRow) => {
        const {deviceId, sensorId} = props.selectedSensor ?? {deviceId: null, sensorId: null};
        return deviceId === sensor.deviceId && (!sensorId || sensorId === sensor.sensorId);
    }

    return (
        <table className={Utility.css(styles.sensorsTable, "table table-hover table-borderless")} id="sensorDataTable">
            <thead>
                <tr>
                    <th>{Localizer.environmentControlSensorGridSensorName}</th>
                    <th>{Localizer.environmentControlSensorGridUnit}</th>
                    <th>{Localizer.environmentControlSensorGridCurrent}</th>
                    <th>{Localizer.environmentControlSensorGridMin}</th>
                    <th>{Localizer.environmentControlSensorGridMax}</th>
                    <th>{Localizer.environmentControlSensorGridUpdated}</th>
                    {props.onSensorClick && <th className={styles.iconColumn}></th>}
                </tr>
            </thead>
            <tbody>
                {sensors.map((sensor, index) => (
                    <tr key={`sensor-${index}`} 
                        className={isSelected(sensor) ? styles.highlighted : undefined}
                        onMouseEnter={() => props.onDeviceSelected(sensor.deviceId!, sensor.sensorId)}
                        onMouseLeave={() => props.onDeviceUnselected()}
                    >
                        <td>{sensor.name}</td>
                        <td>{sensor.type}</td>
                        <td>{sensor.value}</td>
                        <td>{sensor.min}</td>
                        <td>{sensor.max}</td>
                        <td>{sensor.updated}</td>
                        {props.onSensorClick && (
                            <td className={styles.iconColumn}>
                                <Button type={ButtonType.Light}
                                        icon={{name: "far chart-line-up"}}
                                        onClick={async () => await props.onSensorClick!(sensor.deviceId!, [sensor.sensorId])}
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default SensorsTable;