import React from "react";
import {Button, ButtonType, DateRangeInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, SelectListItem, TwoColumns, DateRangeInputValue, IconSize, Accordion} from "@renta-apps/athenaeum-react-components";

import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {IEquipmentFiltersData} from "../Equipments/types";
import Localizer from "@/localization/Localizer";

import styles from "./EquipmentToolbar.module.scss";
import EquipmentToolbarModel from "@/pages/ConstructionSiteDetails/EquipmentToolbar/EquipmentToolbarModel";
import {TogglerPosition} from "@/models/Enums";

interface IEquipmentToolbarProps {
    model: EquipmentToolbarModel;
    equipmentFiltersData?: IEquipmentFiltersData;

    onChange(filters: EquipmentToolbarModel): Promise<void>;
}

interface IToolbarState {
}

export default class EquipmentToolbar extends BaseComponent<IEquipmentToolbarProps, IToolbarState> {

    private readonly _categoriesDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _statusDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _contractDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _typeDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _productDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _rentedByDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _rentedOrReturnedDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _officeDropdownref: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _isRentaFutureDropdownRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();

    private async processOnChangeAsync(): Promise<void> {
        await this.props.onChange(this.model);
    }

    private async clearFiltersAsync(): Promise<void> {
        //Kind of "forcing" the inputs to refresh by using their own methods :)
        if (this._categoriesDropdownref.current) {
            await this._categoriesDropdownref.current.unselectAllAsync();
        }
        if (this._statusDropdownref.current) {
            await this._statusDropdownref.current.unselectAllAsync();
        }
        if (this._contractDropdownref.current) {
            await this._contractDropdownref.current.unselectAllAsync();
        }
        if (this._typeDropdownref.current) {
            await this._typeDropdownref.current.unselectAllAsync();
        }
        if (this._productDropdownref.current) {
            await this._productDropdownref.current.unselectAllAsync();
        }
        if (this._rentedByDropdownref.current) {
            await this._rentedByDropdownref.current.unselectAllAsync();
        }
        if (this._rentedOrReturnedDropdownref.current) {
            await this._rentedOrReturnedDropdownref.current.unselectAllAsync();
        }
        if (this._officeDropdownref.current) {
            await this._officeDropdownref.current.unselectAllAsync();
        }
        if (this._isRentaFutureDropdownRef.current) {
            await this._isRentaFutureDropdownRef.current.unselectAllAsync();
        }
        //DateRangeInput doesn't have method for "clearing" the input...
        this.model.rentedOrReturnedAt = null;
        this.model.rentedOrReturnedStart = null;
        //...which is why this rerender is needed
        await this.reRenderAsync();
        await this.processOnChangeAsync();
    }

    private async onCategoriesChangeHandlerAsync(items: SelectListItem[]): Promise<void> {
        this.model.categories = items.flatMap((item => item.value.split("|")));
        await this.processOnChangeAsync();
    }

    private async setRentedFromToAsync(startDate: Date | null, endDate: Date | null): Promise<void> {

        this.model.rentedOrReturnedStart = startDate;
        this.model.rentedOrReturnedEnd = endDate;

        await this.processOnChangeAsync();
    }

    private async onRentedOrReturnedAtChangeHandlerAsync(item: SelectListItem | null): Promise<void> {
        this.model.rentedOrReturnedAt = item ? Number.parseInt(item.value) : null;

        await this.processOnChangeAsync();
    }

    private async onProductsChangeHandlerAsync(items: SelectListItem[]): Promise<void> {
        this.model.products = items.map(item => (item.value));

        await this.processOnChangeAsync();
    }

    private async onRentersChangeHandlerAsync(items: SelectListItem[]): Promise<void> {
        this.model.renters = items.map(item => item.value);

        await this.processOnChangeAsync();
    }

    private async onDeposChangeHandlerAsync(items: SelectListItem[]): Promise<void> {
        this.model.depos = items.map(item => item.value);

        await this.processOnChangeAsync();
    }

    private async onIsRentaFutureChangeHandlerAsync(item: SelectListItem | null): Promise<void> {
        this.model.rentaFuture = item ? true.toString() === item.value : null;

        await this.processOnChangeAsync();
    }

    private async onContractsChangeHandlerAsync(items: SelectListItem[]): Promise<void> {
        this.model.contracts = items.map(item => item.value);

        await this.processOnChangeAsync();
    }

    private async onStatusChangeHandlerAsync(item: SelectListItem | null): Promise<void> {
        this.model.status = item ? Number.parseInt(item.value) : null;

        await this.processOnChangeAsync();
    }

    private async onRentTypeChangeHandlerAsync(sender: Dropdown<SelectListItem>): Promise<void> {
        this.model.rentType = (sender.selectedItems.length === 1) ? Number.parseInt(sender.selectedItems[0].value) : null;

        await this.processOnChangeAsync();
    }

    private get equipmentFiltersData(): IEquipmentFiltersData | null {
        return this.props.equipmentFiltersData || null;
    }

    private get categoryItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.categories : [];
    }

    private get selectedCategoryItems(): SelectListItem[] | undefined {
        if (this.equipmentFiltersData && this.model.categories.length) {
            return this
                .equipmentFiltersData
                .categories
                .filter((item: SelectListItem) => this
                    .model
                    .categories
                    .some((value: string) => item.value.split("|").includes(value)))
                .map((item: SelectListItem) => ({...item, selected: true} as SelectListItem));
        }

        return undefined;
    }

    private get rentedAtItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.rentedOrReturnedItems : [];
    }

    private get depotItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.depotItems : [];
    }

    private get productItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.products : [];
    }

    private get contractItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.contracts : [];
    }

    private get rentTypeItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.rentTypes : [];
    }

    private get statusItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.statuses : [];
    }

    private get rentersItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.renters : [];
    }

    private get rentaFutureItems(): SelectListItem[] {
        return this.equipmentFiltersData ? this.equipmentFiltersData.rentaFuture : [];
    }

    public get model(): EquipmentToolbarModel {
        return this.props.model;
    }

    public get dateRangeInputModel(): DateRangeInputValue {
        return [this.model.rentedOrReturnedStart, this.model.rentedOrReturnedEnd];
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.equipmentToolbar}>

                <Accordion className="new-accordion-style-v2-with-overflow new-accordion-style-v2-expansionInfo"
                           autoCollapse={false}
                           header={Localizer.constructionSiteDetailsFilterResults}
                           togglerPosition={TogglerPosition.Header}
                           togglerIcon={"angle-down"}
                           togglerSize={IconSize.X3}
                           classNames={{contentContainer: styles.toolbarContentContainer}}
                >

                    <Form>
                        <TwoColumns className={"mb-0"}>
                            <Dropdown noSubtext noWrap multiple
                                      ref={this._categoriesDropdownref}
                                      align={DropdownAlign.Left} autoCollapse={false}
                                      id={"equipment_category_dropdown"}
                                      label={Localizer.constructionSiteDetailsCategoryFilterLabel}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.categoryItems}
                                      selectedItems={this.selectedCategoryItems}
                                      nothingSelectedText={Localizer.constructionSiteDetailsCategoryFilterFilterNothingSelectedText}
                                      onChange={async (sender) => await this.onCategoriesChangeHandlerAsync(sender.selectedItems)}
                            />

                            <Dropdown noSubtext noWrap
                                      align={DropdownAlign.Left}
                                      ref={this._statusDropdownref}
                                      id={"equipment_status_dropdown"}
                                      label={Localizer.constructionSiteDetailsStateFilterLabel}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.statusItems}
                                      selectedItem={(this.model.status !== null) ? this.model.status.toString() : undefined}
                                      nothingSelectedText={Localizer.constructionSiteDetailsStateFilterNothingSelectedText}
                                      onChange={async (sender, item) => await this.onStatusChangeHandlerAsync(item)}
                            />

                        </TwoColumns>

                        <TwoColumns className={"mb-0"}>

                            <Dropdown noSubtext noWrap multiple
                                      ref={this._contractDropdownref}
                                      align={DropdownAlign.Left} autoCollapse={false}
                                      label={Localizer.constructionSiteDetailsContractFilterLabel}
                                      id="equipment_contracts_dropdown"
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.contractItems}
                                      selectedItems={this.model.contracts}
                                      nothingSelectedText={Localizer.constructionSiteDetailsContractFilterNothingSelectedText}
                                      onChange={async (sender) => await this.onContractsChangeHandlerAsync(sender.selectedItems)}
                            />

                            <Dropdown noSubtext noWrap multiple selectedTextFormat
                                      ref={this._typeDropdownref}
                                      align={DropdownAlign.Left}
                                      label={Localizer.constructionSiteDetailsRentTypeFilterLabel}
                                      id={"equipment_rent_type_dropdown"}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.rentTypeItems}
                                      selectedItem={(this.model.rentType !== null) ? this.model.rentType.toString() : undefined}
                                      nothingSelectedText={Localizer.constructionSiteDetailsRentTypeFilterNothingSelectedText}
                                      onChange={async (sender) => await this.onRentTypeChangeHandlerAsync(sender)}
                            />

                        </TwoColumns>

                        <TwoColumns className={"mb-0"}>
                            <Dropdown noSubtext noWrap multiple
                                      ref={this._productDropdownref}
                                      align={DropdownAlign.Left} autoCollapse={false}
                                      label={Localizer.constructionSiteDetailsProductFilterLabel}
                                      id="equipment_products_dropdown"
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.productItems}
                                      selectedItems={this.model.products}
                                      nothingSelectedText={Localizer.constructionSiteDetailsProductFilterNothingSelectedText}
                                      onChange={async (sender) => await this.onProductsChangeHandlerAsync(sender.selectedItems)}
                            />

                            <Dropdown noSubtext noWrap multiple
                                      ref={this._rentedByDropdownref}
                                      align={DropdownAlign.Left} autoCollapse={false}
                                      label={Localizer.constructionSiteDetailsRentedByFilterLabel}
                                      id={"equipment_rented_by_dropdown"}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.rentersItems}
                                      selectedItems={this.model.renters}
                                      nothingSelectedText={Localizer.constructionSiteDetailsRentedByFilterNothingSelectedText}
                                      onChange={async (sender) => await this.onRentersChangeHandlerAsync(sender.selectedItems)}
                            />
                        </TwoColumns>

                        <TwoColumns>
                            <DateRangeInput sameDay
                                            label={Localizer.constructionSiteFilterRentedOrReturnedDateRangeLabel}
                                            model={{value: this.dateRangeInputModel}}
                                            onChange={async ([start, end]: DateRangeInputValue) => await this.setRentedFromToAsync(start, end)}
                            />

                            <Dropdown noSubtext noWrap
                                      ref={this._rentedOrReturnedDropdownref}
                                      align={DropdownAlign.Left}
                                      label={Localizer.constructionSiteFilterRentedOrReturnedLabel}
                                      id="equipment_rented_dropdown"
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.rentedAtItems}
                                      selectedItem={(this.model.rentedOrReturnedAt !== null) ? this.model.rentedOrReturnedAt.toString() : undefined}
                                      nothingSelectedText={Localizer.constructionSiteFilterRentedOrReturnedNoSelection}
                                      onChange={async (sender, item) => await this.onRentedOrReturnedAtChangeHandlerAsync(item)}
                            />

                        </TwoColumns>
                        <TwoColumns>
                            <Dropdown noSubtext noWrap multiple
                                      ref={this._officeDropdownref}
                                      align={DropdownAlign.Left}
                                      id="selectedRentaOffice"
                                      label={Localizer.shoppingCartPageLocation}
                                      items={this.depotItems}
                                      selectedItems={this.model.depos}
                                      onChange={async (sender) => await this.onDeposChangeHandlerAsync(sender.selectedItems)}
                            />
                            <Dropdown noSubtext noWrap selectedTextFormat
                                      ref={this._isRentaFutureDropdownRef}
                                      align={DropdownAlign.Left}
                                      label={Localizer.catalogRentaFuture}
                                      id={"equipment_is_renta_future_dropdown"}
                                      minWidth="170px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.rentaFutureItems}
                                      selectedItem={(this.model.rentaFuture !== null) ? this.model.rentaFuture.toString() : undefined}
                                      nothingSelectedText={Localizer.genericAll}
                                      onChange={async (sender) => await this.onIsRentaFutureChangeHandlerAsync(sender.selectedItem)}
                            />
                        </TwoColumns>

                        <TwoColumns>
                            <Button id="equipment_clear_filters"
                                    type={ButtonType.Orange}
                                    label={Localizer.constructionSiteDetailsClearFilters}
                                    onClick={async () => await this.clearFiltersAsync()}/>
                        </TwoColumns>

                    </Form>

                </Accordion>

            </div>
        );
    }
};