import React from "react";
import styles from './ProductDetailsTechnicalInformation.module.scss';

interface ProductDetailsTechnicalInformationProps {
    className?: string;
    items: {
        key: string;
        value: string;
        isLink?: boolean;
    }[];
}

const ProductDetailsTechnicalInformation = ({items, className}: ProductDetailsTechnicalInformationProps): JSX.Element => {
    return (
        <table className={["table table-striped", className].join(' ')}>
            <tbody>
            {
                items.map((item, index) => (
                    <tr key={item.key + index}>
                        <td className={["p-2 border-0", styles.titleCase].join(' ')}>{item.key}</td>
                        <td className="p-2 border-0 font-weight-bold">
                            {
                                (item.isLink) ? (
                                    <a href={item.value as string}>{item.key}</a>
                                ) : (item.value)
                            }
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    );
};

export default ProductDetailsTechnicalInformation;