
export default class GetMachineModelDocumentRequest {
    
    public productId?: string | null = null;

    public productInfoId?: string | null = null;

    public fileId?: string | null = null;

    public documentId?: number | null = null;
}