import {onRedirect, pageData} from "@/helpers/CypressHelper";
import {Method} from "cypress/types/net-stubbing";

// @ts-ignore
describe('Desktop - Anonymous - Environment control info screen tests', () => {

    context('Simple floorplan', () => {
        beforeEach(() => {
            const {getInfoScreenData, getEnvironmentControlData} = pageData().environmentControlInfoScreen.routes;
            cy.intercept(getInfoScreenData.method as Method, getInfoScreenData.path, {fixture: 'environmentControlInfoScreenData.json'});
            cy.intercept(getEnvironmentControlData.method as Method, getEnvironmentControlData.path, {fixture: 'environmentControlSiteData.json'})
                .as('getEnvironmentControlSiteData');
            onRedirect(() => cy.visit('infoscreen/f74224619c'));
        });

        it('Verify wide design with floorplan and one grid is rendered', () => {
            cy.viewport(1280, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas();
            wideContainer();
            sensorDataTable().should('not.exist');
            sensorsGrid().should('have.length', 1);
        });

        it('Verify vertical design with floorplan and table is rendered', () => {
            cy.viewport(1080, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas();
            sensorDataTable();
            wideContainer().should('not.exist');
            sensorsGrid().should('not.exist');
        });

        it('Verify vertical large design with floorplan and table is rendered', () => {
            cy.viewport(1600, 1800);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas();
            sensorDataTable();
            wideContainer().should('not.exist');
            sensorsGrid().should('not.exist');
        });

        it('Verify vertical design with floorplan and grid is rendered', () => {
            cy.viewport(767, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas();
            sensorDataTable().should('not.exist');
            sensorsGrid().should('have.length', 1);
        });

        it('Verify vertical design with grid and without floorplan is rendered', () => {
            cy.viewport(575, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas().should('not.exist');
            sensorsGrid().should('have.length', 1);
        });
    });

    context('Floorplan with multiple devices', () => {
        beforeEach(() => {
            const {getInfoScreenData, getEnvironmentControlData} = pageData().environmentControlInfoScreen.routes;
            cy.intercept(getInfoScreenData.method as Method, getInfoScreenData.path, {fixture: 'environmentControlInfoScreenData.json'});
            cy.intercept(getEnvironmentControlData.method as Method, getEnvironmentControlData.path, {fixture: 'environmentControlSiteMultipleDevicesData.json'})
                .as('getEnvironmentControlSiteData');
            onRedirect(() => cy.visit('infoscreen/f74224619c'));
        });

        it('Verify vertical design with floorplan and table is rendered', () => {
            cy.viewport(1280, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer} = pageData().environmentControlInfoScreen;
            canvas();
            sensorDataTable();
            wideContainer().should('not.exist');
        });

        it('Verify wide design with floorplan and two grids is rendered', () => {
            cy.viewport(1500, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas();
            wideContainer();
            sensorDataTable().should('not.exist');
            sensorsGrid().should('have.length', 2);
        });
    });

    context('Environment without floorplan', () => {
        beforeEach(() => {
            const {getInfoScreenData, getEnvironmentControlData} = pageData().environmentControlInfoScreen.routes;
            cy.intercept(getInfoScreenData.method as Method, getInfoScreenData.path, {fixture: 'environmentControlInfoScreenData.json'});
            cy.intercept(getEnvironmentControlData.method as Method, getEnvironmentControlData.path, {fixture: 'environmentControlSiteWithoutFloorPlanData.json'})
                .as('getEnvironmentControlSiteData');
            onRedirect(() => cy.visit('infoscreen/f74224619c'));
        });

        it('Verify vertical design without floorplan and with table is rendered', () => {
            cy.viewport(1180, 1080);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer} = pageData().environmentControlInfoScreen;
            canvas().should('not.exist');
            sensorDataTable();
            wideContainer().should('not.exist');
        });

        it('Verify wide design without floorplan and with three grids is rendered', () => {
            cy.viewport(1500, 1180);
            cy.wait('@getEnvironmentControlSiteData', {timeout: 25000});

            const {canvas, sensorDataTable, wideContainer, sensorsGrid} = pageData().environmentControlInfoScreen;
            canvas().should('not.exist');
            wideContainer();
            sensorDataTable().should('not.exist');
            sensorsGrid().should('have.length', 3);
        });
    });

});