import React from "react";
import styles from "./UploadingOrDone.module.scss";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface UploadingOrDoneProps {
    isUploading: boolean;
    
    UploadingText: string;

    className?: string;
}

const UploadingOrDone = ({isUploading, UploadingText, className}: UploadingOrDoneProps): JSX.Element | null => {
    
    return isUploading ? 
        (
            <>
                <div className={Utility.css(styles.loadingDotsContainer)}>
                    <div className={styles.dotElastic}></div>
                </div>
                <div style={{marginBottom: "5px"}}>
                    {
                        UploadingText
                    }
                </div>
            </>
        )
        : null
    
};

export default UploadingOrDone;