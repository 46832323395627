import {Button, ButtonType, Dropdown, DropdownOrderBy, Form, Modal, OneColumn, SelectListItem} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect} from "react";
import {Dictionary} from "typescript-collections";
import {OrganizationContractRoleModel} from "@/models/server/OrganizationContractRoleModel";
import styles from "./ChangeUserRoleModal.module.scss";

type ChangeUserRoleModalProps = {
    isOpen: boolean;
    item: OrganizationContractRoleModel | null;
    items: SelectListItem[] | null;
    onUserRoleChanged(isUserRoleChanged: boolean, selectedUserRoleName?: string): void;
}

const ChangeUserRoleModal: React.FC<ChangeUserRoleModalProps> = (props: ChangeUserRoleModalProps) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();
    
    const changeUserRoleAsync = async (data: Dictionary<string, any>) => {
        _modalRef.current!.closeAsync().then(() => {
                const selectedUserRole = data.values()[0];
                
                if (selectedUserRole === null || selectedUserRole === undefined) {
                    props.onUserRoleChanged(false);
                } else {
                    props.onUserRoleChanged(true, selectedUserRole);
                }
            }
        )
    }
    
    const closeUserRoleModalAsync = async () => {
        _modalRef.current!.closeAsync().then(() => {
                props.onUserRoleChanged(false);
            }
        )
    }
    
    const cleanup = (): void => {}

    useEffect(() => {
        if (props.isOpen) {
            _modalRef.current?.openAsync();
        } else {
            cleanup();
            _modalRef.current?.closeAsync();
        }
    }, [props.isOpen, _modalRef]);

    return (
        props.isOpen ? (
            <Modal ref={_modalRef}
                   title={Localizer.changeUserRoleModalHeader}
                   onClose={async () => closeUserRoleModalAsync()}
            >
                <Form id="changeUserForm" onSubmit={async (_, data) => changeUserRoleAsync(data)}>
                    <OneColumn>
                        <Dropdown items={props.items!}
                                  id="changeUserRoleDropdown"
                                  orderBy={DropdownOrderBy.Value}
                                  selectedItem={props.item?.roleName!}
                                  label={Localizer.changeUserRoleModalContent}
                        />

                        <div className={styles.modalButtons}>
                            <Button className={styles.cancelButton}
                                label={Localizer.formCancel}
                                type={ButtonType.Light}
                                onClick={async () => closeUserRoleModalAsync() }
                            />
                            <Button id="changeRoleSaveButton"
                                    label={Localizer.formSave}
                                    type={ButtonType.Orange}
                                    submit
                            />
                        </div>
                    </OneColumn>
                </Form>
            </Modal>
        ) : null
    );
}

export default ChangeUserRoleModal;