import Localizer from "../../../localization/Localizer";
import GridHelper from "@/helpers/CypressGridHelper";

// @ts-ignore
describe('Desktop - Admin user, admin locations page tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('Visits admin locations page and verifies the page loads and orders open', () => {

        cy.intercept('POST', 'api/Admin/GetLocationsDetails', {fixture: 'adminLocations.json'}).as('locationDetails');

        cy.visit(Localizer.pageRoutesAdminLocations);

        cy.waitSuccess('@locationDetails');

        cy.intercept('POST', 'api/Admin/GetLiteLocations', {fixture: 'listLocations.json'});

        cy.intercept('POST', 'api/Product/GetOrdersPagedList', {fixture: 'ordersList.json'});

        cy.intercept('POST', 'api/Product/GetOrdersPagedList').as('getOrdersPagedList');

        cy.get("#table_adminLocations")
            .find('.athenaeum-link-link')
            .eq(0)
            .click();

        cy.waitSuccess('@getOrdersPagedList');
        cy.go("back");

        GridHelper.getGridVisibleRows("#table_adminLocations")
            .should("have.length", 54);
    });
});