import {RouteData, executeWithIntercept, pageData} from "../../../helpers/CypressHelper";
import Localizer from "../../../localization/Localizer";

describe('Desktop - Logged in private user delete account', () => {

    beforeEach(() => {
        cy.session('private_user_session', () => cy.loginSpecificUser("unlocked.user@domain.fi", "Onet.xml1"), {});
    });

    it('Open frontPage, delete account and confirm it by the token link', () => {

        cy.visit('/');

        executeWithIntercept(() =>
                cy.get('a.athenaeum-link-link').contains(Localizer.accountText).click(),
            [pageData().common.routes.depots as RouteData]);

        cy.get('button.athenaeum-button-button').contains(Localizer.myAccountDeleteAccount).trigger('click');

        cy.intercept("POST", "/api/Users/ConfirmDeleteUser").as("confirmDeleteUser");
        cy.get('.athenaeum-confirmation-dialog-opened')
            .find('[id^=confirmation-dialog-confirm-]')
            .click();

        cy.get('.alert-success')
            .should('contain', Localizer.myAccountDeleteAccountConfirmationEmail);

        cy.wait("@confirmDeleteUser").then(({response}) => {
            expect(response?.statusCode, 'status code').to.eq(200);

            const tokenUrl = response?.body?.value;

            expect(tokenUrl, 'to.not.be.null');

            cy.intercept(pageData().common.routes.tokenLogin.method, pageData().common.routes.tokenLogin.path).as('tokenLogin');
            cy.visit(tokenUrl);
            cy.url().should('include', 'TokenLogin');
            cy.waitSuccess('@tokenLogin')

            cy.get('.alert-success')
                .should('contain', Localizer.myAccountDeleteDone);
        });
    });

});