import {onRedirect} from "@/helpers/CypressHelper";

describe('Desktop - Anonymous product not found test', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
    });


    it('Open product with existing id', () => {
        Cypress.on('uncaught:exception', (err, runnable) => {
            return false
        })
        cy.intercept('POST', 'api/Product/ProductData', {fixture: 'product'}).as('productData');
        onRedirect(() =>   cy.visit('tuote/mock-url'));

        cy.waitSuccess("@productData");

        cy.url().should('not.contain', 'NotFound');
        cy.url().should('include', 'tuote/mock-url');
        cy.get('.athenaeum-page-container-container').should('contain', 'AJETTAVA NIVELPUOMINOSTIN');
    });

    it('Open product with non-existing id', () => {
        Cypress.on('uncaught:exception', (err, runnable) => {
            return false
        })
        cy.intercept('POST', 'api/Product/ProductData').as('productData');
        onRedirect(() =>   cy.visit('product/non-existing-product'));

        cy.waitSuccess("@productData");

        cy.url().should('include', 'NotFound');
        cy.get('.athenaeum-page-container-container')
            .find("svg")
            .find('image').eq(0).should('have.attr', 'xlink:href', '/images/404-top-no-truck.svg');
        cy.get('.athenaeum-page-container-container')
            .find('img').eq(0)
            .should('have.attr', 'src', '/images/Oops-sign.svg');
    });
});