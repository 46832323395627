import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Carousel, CarouselNavigation, CarouselPagination, Icon, IconSize, IconStyle} from "@renta-apps/athenaeum-react-components";
import ImageProvider from "@/providers/ImageProvider";
import FileApiModel from "@/models/server/FileApiModel";

import styles from "@/components/FullScreenImageGallery/FullScreenImageGallery.module.scss";

interface IFullScreenImageGalleryProps {
    images: FileApiModel[];
    imagesIndex: number;
    imagesAlternativeText?: string | null | undefined;
    onClose: () => Promise<void>;
}

interface IFullScreenImageGalleryState {
}

export default class FullScreenImageGallery extends BaseComponent<IFullScreenImageGalleryProps, IFullScreenImageGalleryState> {
    public state: IFullScreenImageGalleryState = {};

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.fullScreenImages)}
                 onClick={async () => await this.props.onClose()}
            >
                <Icon className={styles.fullScreenImagesIcon}
                      name="times"
                      size={IconSize.X4}
                      style={IconStyle.Solid}
                />

                <div className={styles.fullScreenImagesCarouselContainer}>
                    <Carousel className={this.css(styles.fullScreenImagesCarouselContainerImageCarousel)}
                              navigation={CarouselNavigation.Outside}
                              pagination={CarouselPagination.BottomOutside}
                              initialSlideIndex={this.props.imagesIndex}
                              onClick={async (event) => event.stopPropagation()}
                    >
                        {
                            this.props.images.map((image, index) => (
                                <a key={index + image.id}
                                   onClick={async (event) => {
                                       event.stopPropagation();
                                       await this.props.onClose();
                                   }}
                                >
                                    <img className={styles.fullScreenImagesCarouselImage}
                                         src={ImageProvider.getImageAddress(image)}
                                         alt={this.props.imagesAlternativeText ?? ""}
                                    />
                                </a>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        );
    }
}