import React from "react";
import Localizer from "@/localization/Localizer";
import {OneColumn} from "@renta-apps/athenaeum-react-components";
import ProductDetailsAvailabilityInfo from "@/pages/ProductDetails/components/ProductDetailsAvailabilityInfo";
import ProductAvailabilityDetails from "@/models/server/ProductAvailabilityDetails";

type ProductDetailsAvailabilityDetailsProps = {
    availabilities: ProductAvailabilityDetails[];
    externalId: string;
}

const ProductDetailsAvailabilityDetails: React.FC<ProductDetailsAvailabilityDetailsProps> = (props: ProductDetailsAvailabilityDetailsProps) => {

    return (
        <>
            {props.availabilities.map((availability, index) => (
                <div key={availability.location.toString() + index}
                     id={`collapse_${availability.location}`}
                     className="col-12 p-0 collapse"
                >
                    <OneColumn>
                        <table className="table table-striped table-hover table-borderless">
                            <thead className="thead-dark">
                                <tr>
                                    <th>
                                        {availability.locationName}
                                    </th>
                                    <th>
                                        <a role="button"
                                           style={{float: "right"}}
                                           data-toggle="collapse"
                                           href={`#collapse_${availability.location}`}
                                           aria-expanded="false"
                                           aria-controls={`collapse_${availability.location}`}
                                        >
                                            { Localizer.componentModalClose}
                                        </a>
                                    </th>
                                </tr>
                            </thead>
        
                            <ProductDetailsAvailabilityInfo availability={availability.available} text={Localizer.productDetailsFree} externalId={props.externalId!} />
        
                            <ProductDetailsAvailabilityInfo availability={availability.waitingForReturnInspection} text={Localizer.productDetailsAvailabilityWaitingInspection} externalId={props.externalId!} />
        
                            <ProductDetailsAvailabilityInfo availability={availability.inMaintenance} text={Localizer.productDetailsAvailabilityInMaintenance} externalId={props.externalId!} />
        
                            <ProductDetailsAvailabilityInfo availability={availability.rented} text={Localizer.rentingText} externalId={props.externalId!} />
                        </table>
                    </OneColumn>
                </div>
            ))}
        </>
    ); 
}

export default ProductDetailsAvailabilityDetails;