import {PasswordFormType} from "@renta-apps/athenaeum-react-components";
import BasePasswordPage from "@/components/BasePasswordPage/BasePasswordPage";
import Localizer from "@/localization/Localizer";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";

export interface IChangePasswordParams extends BasePageParameters {
}

export default class ChangePassword extends BasePasswordPage<IChangePasswordParams> {

    protected get title(): string {
        return Localizer.resetPasswordPageTitle;
    }

    protected get passwordType(): PasswordFormType {
        return PasswordFormType.SetPassword;
    }
}