import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, IconSize,} from "@renta-apps/athenaeum-react-components";
import PlanModel from "@/models/server/PlanModel";

import styles from "./PlansCatalog.module.scss";
import Localizer from "@/localization/Localizer";

interface IPlanCatalogProps {
    planModels: PlanModel[];

    openDefinitionAsync(model: PlanModel): Promise<void>;

    canEdit?: boolean;

    /**
     * Required if {@link canEdit} is true.
     */
    openEditorAsync?(model: PlanModel): Promise<void>;

    canCreateNew?: boolean;

    /**
     * Required if {@link canCreateNew} is true.
     */
    createNewPlanDefinition?(): Promise<void>;
}

interface IPlanCatalogState {
}

export default class PlanCatalog extends BaseComponent<IPlanCatalogProps, IPlanCatalogState> {

    public state: IPlanCatalogState = {};

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.plansList}>
                {
                    (this.props.canEdit && this.props.canCreateNew) &&
                    (
                        <Button id="createNewPlanDefinitionButton"
                                label={Localizer.plansCreateNew}
                                onClick={async () => await this.props.createNewPlanDefinition!()}
                        />
                    )
                }
                {
                    (this.props.planModels) &&
                    (
                        this.props.planModels.map((plan, index) => (
                            <span key={(plan.name ?? "") + index} data-test-id={`plan_${plan.name}`}>

                                <Button className={styles.planButton}
                                        label={plan?.name ?? ""}
                                        onClick={async () => await this.props.openDefinitionAsync(plan)}
                                        icon={{name: plan?.icon ?? "", size: IconSize.X3}}
                                        type={ButtonType.Orange}
                                />

                                {
                                    (this.props.canEdit) &&
                                    (
                                        <Button id={"planDefinitionsEdit-" + plan?.id}
                                                label={Localizer.serviceDefinitionsEdit}
                                                onClick={async () => await this.props.openEditorAsync!(plan)}
                                                icon={{name: "pen"}}
                                                type={ButtonType.Orange}
                                        />
                                    )
                                }

                            </span>)
                        )
                    )
                }
            </div>
        );
    }
}