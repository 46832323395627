import { pageData } from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Authenticated admin user categories attributes priority tests', () => {
    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });
    

    it('adds a new attribute, when the populated attribute name is unique; blocks the form submition when the attribute name does exist; removes the attribute once trash icon is clicked', () => {
        const randomHash: string = Date.now().getHashCode().toString();
        const attributeName = 'Attr 1 ' + randomHash;

        pageData().rent.visitWithIntercept('emptycategory');
        pageData().common.waitTopNavRender();

        pageData().rent.editButton().click();
        pageData().rent.categoryAttributes.editAttributesButton().click();

        // populate a new attribute name
        pageData().rent.categoryAttributes.newAttributeInput().type(attributeName);
        pageData().rent.categoryAttributes.newAttributeSubmitButton().should('not.be.disabled');
        pageData().rent.categoryAttributes.newAttributeSubmitButton().click();
        cy.get('.athenaeum-alert-message')
            .should('contain', Localizer.genericSaveSuccess);
        
        pageData().rent.categoryAttributes.editAttributesButton().click();
        
        pageData().rent.categoryAttributes.newAttributeInput().type(attributeName, {force: true});
        pageData().rent.categoryAttributes.newAttributeSubmitButton().should('be.disabled');

        // find first remove attribute icon
        pageData().rent.categoryAttributes.removeAttributeButton().first().click();
    });

    it('changes attribute priority, when the change priority icon is clicked', () => {
        pageData().rent.visitWithIntercept('SubCategoryWithKeysSubCategories');
        pageData().common.waitTopNavRender();

        pageData().rent.editButton().click();
        pageData().rent.categoryAttributes.editAttributesButton().click();

        // decrease the priority of the first attribute
        pageData().rent.categoryAttributes.priorityDecreaseButton().first().click();

        // verify whether the second attribute has higher priority
        pageData().rent.categoryAttributes.attributeNames().first().should('have.text', 'SubCategoryWithKeysSubCategoriesKey2');

        // increase the priority of the second attribute
        pageData().rent.categoryAttributes.priorityIncreaseButton().last().click();

        // verify whether the second attribute has higher priority
        pageData().rent.categoryAttributes.attributeNames().first().should('have.text', 'SubCategoryWithKeysSubCategoriesKey1');
    });
});