import React from "react";
import {Button, ButtonType, FileInput, Icon, IconSize, IconStyle} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import FileApiModel from "@/models/server/FileApiModel";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ProductInfoModel from "@/models/server/ProductInfoModel";
import {ch} from "@renta-apps/athenaeum-react-common";
import UploadingOrDone from "@/components/UploadingOrDone/UploadingOrDone";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

interface ProductModelDocumentsProps {
    index: number;
    isSpinning: boolean;
    editing: boolean;
    productInfo: ProductInfoModel;
    pdfs: FileApiModel[];
    newProductModelFile: FileModel | null;
    classNames?: IProductModelDocumentsClassNames;
    isUploading: boolean;
    isMobile: boolean;
    
    viewProductModelDocumentAsync: (fileApiModel: FileApiModel, productInfoModel: ProductInfoModel) => Promise<void>;
    downLoadProductModelDocumentAsync: (fileApiModel: FileApiModel, productInfoModelId: string) => Promise<void>;
    deleteProductModelDocumentAsync: (productInfo: ProductInfoModel, fileApiModel: FileApiModel) => Promise<void>;
    uploadMachineModelDocumentsAsync: (productInfoModel: ProductInfoModel) => Promise<void>;
    onFileChangeAsync: (value: FileModel, productInfoModel: ProductInfoModel) => Promise<void>;
}

export interface IProductModelDocumentsClassNames {
    hover?: string;
    uploadButton?: string;
    documentTableClass?: string;
}

const ProductModelDocuments = (props: ProductModelDocumentsProps): JSX.Element => {
    const { isSpinning, productInfo, pdfs, editing, newProductModelFile, classNames, isUploading, isMobile} = props;
    const {downLoadProductModelDocumentAsync, viewProductModelDocumentAsync, deleteProductModelDocumentAsync, uploadMachineModelDocumentsAsync, onFileChangeAsync} = props;

    return (
        <React.Fragment key={ch.getId()}>
            {
                (pdfs?.length > 0) &&
                <>
                <div className={classNames?.documentTableClass}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{Localizer.constructionsiteEquipmentDocumentsFileName}</th>
                            {
                                (!isMobile) &&
                                <th style={{textAlign: "center"}}>{Localizer.constructionsiteEquipmentDocumentsView}</th>
                            }
                            <th style={{textAlign: "center"}}>{Localizer.constructionsiteEquipmentDocumentsDownload}</th>
                            {
                                (editing) &&
                                (<th style={{textAlign: "center"}}>{Localizer.formDelete}</th>)
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            pdfs.map((item, index) => (
                                <tr key={item.id + index}>
                                    <td>
                                        {
                                            item.name
                                        }
                                    </td>
                                    {
                                        (!isMobile) &&
                                        <td style={{textAlign: "center"}}>
                                            {
                                                <Icon id={"viewModelDocument"}
                                                      name="far search"
                                                      size={IconSize.Large}
                                                      className={classNames?.hover}
                                                      style={IconStyle.Regular}
                                                      onClick={async () => await viewProductModelDocumentAsync(item, productInfo)}
                                                />
                                            }
                                        </td>
                                    }
                                    <td style={{textAlign: "center"}}>
                                        {
                                            <Icon id={"downloadModelDocument"}
                                                  name="far download"
                                                  className={classNames?.hover}
                                                  size={IconSize.Large}
                                                  style={IconStyle.Regular}
                                                  onClick={async () => await downLoadProductModelDocumentAsync(item, productInfo.id)}
                                            />
                                        }
                                    </td>
                                    {
                                        // documentId indicates the api file which not to be deleted.
                                        (editing) && (!item.documentId) &&
                                        (
                                            <td style={{textAlign: "center"}}>
                                                {
                                                    <Icon id={"deleteModelDocument"}
                                                          className={classNames?.hover}
                                                          name="far trash-alt"
                                                          size={IconSize.Large}
                                                          style={IconStyle.Regular}
                                                          disabled={isSpinning}
                                                          onClick={async () => await deleteProductModelDocumentAsync(productInfo, item)}
                                                    />
                                                }
                                            </td>
                                        )
                                    }
                                    {
                                        (
                                            (editing) && (item.documentId != null) &&
                                            <td style={{textAlign: "center"}}>
                                                {
                                                    <Icon id={"deleteModelDocument"}
                                                          name="far ban"
                                                          size={IconSize.Large}
                                                          style={IconStyle.Regular}
                                                    />
                                                }
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    </div>
                </>
            }
            {
                (editing && productInfo.name) &&
                (
                    <>
                        {
                            isUploading &&
                            (
                                <UploadingOrDone isUploading={isUploading}
                                                 UploadingText={Localizer.productDetailsFileUploadingText}
                                                 className={classNames?.documentTableClass}
                                />
                            )
                        }
                        
                        <>
                            <FileInput dropZone
                                       id={"model_document_input"}
                                       fileTypes={["application/pdf"]}
                                       label={Localizer.productDetailsUploadModelDocuments}
                                       value={newProductModelFile}
                                       maxSize={RentaEasyConstants.maxMachineDocumentUploadSizeinBytes}
                                       maxTotalSize={RentaEasyConstants.maxMachineDocumentUploadSizeinBytes}
                                       onChange={async (_, fileModel: FileModel) => await onFileChangeAsync(fileModel, productInfo)}
                            />
                        </>

                        <>
                            <Button id="uploadProductModelFile"
                                    className={classNames?.uploadButton}
                                    type={ButtonType.Orange}
                                    disabled={!newProductModelFile}
                                    label={Localizer.genericSave}
                                    icon={{name: "upload"}}
                                    onClick={async () => await uploadMachineModelDocumentsAsync(productInfo)}
                            />
                        </>
                    </>
                )
            }
        </React.Fragment>
    )
};

export default ProductModelDocuments;