import ProductModel from "@/models/server/ProductModel";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";

export default class RelatedProductsResponse {

    public products: ProductModel[] = [];

    public static Transform(products: ProductModel[]): ShoppingCartProductModel[] {
        return products.map(relatedProduct => {
            return {
                product: relatedProduct,
                count: 0,
                endDate: null,
                preferredModel: null,
                preferredModelName: null,
                relatedProducts: []
            };
        });
    }
}