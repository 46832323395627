import {Button, ButtonContainer, ButtonType, Dropdown, Form, Modal, OneColumn, SelectListItem} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect, useState} from "react";
import {Dictionary} from "typescript-collections";
import {fetchCategoriesForProductToMoveToAsync, updateProductCategoryAsync} from "@/services/CatalogService";

type ChangeProductParentCategoryModalProps = {
    isOpen: boolean;
    productId: string;
    currentCategoryId: string;
    onClose(success: boolean): void;
}

const ChangeProductParentCategoryModal: React.FC<ChangeProductParentCategoryModalProps> = (props: ChangeProductParentCategoryModalProps) => {

    const _modalRef: React.RefObject<Modal> = React.createRef();
    
    const [selectionList, setSelectionList] = useState<SelectListItem[]>([]);

    const fetchCategories = async () => {
        const selectList = await fetchCategoriesForProductToMoveToAsync(props.currentCategoryId);
        setSelectionList(selectList);
    }
    
    const moveCategoryAsync = async (data: Dictionary<string, any>) => {

        let newParentId: string | null = data.getValue("newParentCategoryId");

        if (newParentId && newParentId !== props.currentCategoryId) {
            const moved = await updateProductCategoryAsync(props.productId, newParentId);

            props.onClose(!!moved);
        }
    }
    
    useEffect(() => {
        if (props.isOpen) {
            fetchCategories();
            if (!_modalRef.current?.isOpen) {
                _modalRef.current?.openAsync();
            }
        } else {
            if (_modalRef.current?.isOpen) {
                _modalRef.current?.closeAsync();
            }
        }
    }, [props.isOpen]);

    return (
        props.isOpen ? (
            <Modal ref={_modalRef}
                   title={Localizer.catalogEditProductMove}
                   onOpen={() => fetchCategories()}
                   onClose={async () => props.onClose(false)}
            >
                <Form id="moveCategory" onSubmit={async (_, data) => await moveCategoryAsync(data)}>
                    <OneColumn>
                        {(selectionList) && (
                            <Dropdown required
                                      id={"newParentCategoryId"}
                                      label={Localizer.catalogEditProductNewCategory}
                                      items={selectionList}
                            />
                        )}
                    </OneColumn>
                    <ButtonContainer>
                        <Button submit block
                                type={ButtonType.Orange}
                                label={Localizer.genericSave}
                        />
                    </ButtonContainer>
                </Form>
            </Modal>
        ) : null
    );
}

export default ChangeProductParentCategoryModal;