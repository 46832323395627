import React, {useEffect, useMemo, useState} from "react";
import EnvironmentControlInfoScreenData from "@/models/server/EnvironmentControlInfoScreenData";
import GetEnvironmentSiteDetailsResponse from "@/models/server/Responses/GetEnvironmentSiteDetailsResponse";
import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import EnvironmentFloorPlan from "@/models/server/EnvironmentFloorPlan";
import EnvironmentControlSensorValues from "@/components/EnvironmentControl/EnvironmentControlSensorValues";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import {useResize} from "@/helpers/Hooks";
import WideScreenWithFloorPlan from "./WideScreenWithFloorPlan";
import styles from "./EnvironmentControlInfoScreen.module.scss";
import WideScreenWithoutFloorPlan from "@/pages/InfoScreen/EnvironmentControl/WideScreenWithoutFloorPlan";

type EnvironmentControlInfoScreenProps = {
    data: EnvironmentControlInfoScreenData;
    constructionSiteId: string;
};

type EnvironmentControlInfoScreenDesign = {
    sensorsPerGrid?: number;
    floorPlanContainerHeight?: number;
    gridsCount?: number;
    useWideDesign?: boolean;
    containerWidth?: number;
};

const sensorGridRowHeight = 116;
const oneGridMinWidth = 1200;
const twoGridsMinWidth = 1500;

const EnvironmentControlInfoScreen: React.FC<EnvironmentControlInfoScreenProps> = (props: EnvironmentControlInfoScreenProps) => {
    const {windowWidth, windowHeight} = useResize();
    const [floorPlan, setFloorPlan] = useState<EnvironmentFloorPlan | undefined>(undefined);
    const [environmentDevices, setEnvironmentDevices] = useState<EnvironmentDevice[]>([]);
    const [environmentName, setEnvironmentName] = useState<string>('');
    const [{sensorsPerGrid, gridsCount, useWideDesign, floorPlanContainerHeight, containerWidth}, setDesign] = useState<EnvironmentControlInfoScreenDesign>({});
    const [scale, setScale] = useState(1);
    const [pagingInfo, setPagingInfo] = useState<string | undefined>(undefined);

    const sensorsCount = useMemo(() => {
        if (!floorPlan?.floorPlanDevices?.length && !environmentDevices.length) {
            return undefined;
        }
        let sensorsCount = 0;
        (floorPlan?.floorPlanDevices ?? environmentDevices).forEach((device: EnvironmentDevice) => {
            sensorsCount += device.sensors.length;
        });
            
        return sensorsCount;
    }, [floorPlan, environmentDevices]);

    useEffect(() => {
        const loadData = async () => {
            const response: GetEnvironmentSiteDetailsResponse = await ApiProvider.postAsync("/api/EnvironmentControl/GetSiteData", props.constructionSiteId);
            if (response) {
                if (props.data.floorPlanId && response.floorPlans?.length) {
                    setFloorPlan(response.floorPlans.find(x => x.id === props.data.floorPlanId));
                } else {
                    setEnvironmentDevices(response.devices ?? []);
                    setEnvironmentName(response.name ?? '');
                }
            }
        };

        loadData().catch(() => {});
    }, [props.data, props.constructionSiteId]);
    
    useEffect(() => {
        if (sensorsCount === undefined) {
            return;
        }

        if (windowWidth <= oneGridMinWidth) {
            setDesign({useWideDesign: false});
            return;
        }
        
        let width = windowWidth;
        let newScale = 1;
        if (windowWidth > 2800) {
            width = 2800;
            newScale = windowWidth / 2800;
        }

        let height = windowHeight / newScale;
        if (height > 2000) {
            height = 2000;
            newScale = windowHeight / 2000;
            width = windowWidth / newScale;
        }

        setScale(newScale);
        if (height > width) {
            setDesign({useWideDesign: false});
            return;
        }
        
        // 189 = 108 (2 * 20 (div padding) + 2 * 16 (div margin) + 36 (div header height)) + 81 (page margins and paddings, page header)
        const containerHeight = height - 189;
        const rowsPerGrid = Math.floor(containerHeight / sensorGridRowHeight);
        const numberOfGrids = Math.ceil(sensorsCount! / rowsPerGrid);
        setDesign({
            useWideDesign: !floorPlan || width >= twoGridsMinWidth || (width > oneGridMinWidth && numberOfGrids < 2),
            sensorsPerGrid: rowsPerGrid,
            floorPlanContainerHeight: containerHeight,
            gridsCount: numberOfGrids,
            containerWidth: width - 30, // 30 - padding
        });
    }, [windowWidth, windowHeight, sensorsCount]);
    
    const onGridPagingChanged = (page: number, gridsPerScreen: number, hideInfo: boolean) => {
        if (hideInfo) {
            // we don't want to show paging info if paging is not used
            setPagingInfo(undefined);
            return;
        }
        const sensorsPerScreen = sensorsPerGrid! * gridsPerScreen;
        const first = 1 + page * sensorsPerScreen;
        const last = Math.min(first + sensorsPerScreen - 1, sensorsCount!);
        setPagingInfo(`${first}-${last}/${sensorsCount!}`);
    };

    return (
        <div className={styles.container} style={{'--scale': scale} as React.CSSProperties}>
            {useWideDesign !== undefined && (
                <>
                    <div className={styles.floorPlanName}>
                        <div>{`Floorplan ${floorPlan?.name ?? environmentName ?? ''}`}</div>
                        <div>{pagingInfo}</div>
                    </div>
                    {useWideDesign ? 
                        !!floorPlan ? (
                            <WideScreenWithFloorPlan floorPlan={floorPlan}
                                                     floorPlanContainerHeight={floorPlanContainerHeight!}
                                                     scale={scale}
                                                     sensorsCount={sensorsCount}
                                                     sensorsPerGrid={sensorsPerGrid!}
                                                     gridsCount={gridsCount!}
                                                     onGridPagingChanged={onGridPagingChanged}
                            />
                        ) : (
                            <WideScreenWithoutFloorPlan devices={environmentDevices}
                                                        sensorsCount={sensorsCount!}
                                                        sensorsPerGrid={sensorsPerGrid!}
                                                        containerWidth={containerWidth!}
                                                        gridsCount={gridsCount!}
                                                        onGridPagingChanged={onGridPagingChanged}
                            />
                        )
                    : (
                        <EnvironmentControlSensorValues floorPlan={floorPlan} dynamicHeight scale={scale} devices={environmentDevices} />
                    )}
                </>
            )}
        </div>
    );
}

export default EnvironmentControlInfoScreen;