import {ApplicationContext, ch} from "@renta-apps/athenaeum-react-common";
import {UnleashClient} from "unleash-proxy-client";
import UserContext from "@/models/server/UserContext";
import LocalFeatureToggleClient from "@/helpers/LocalFeatureToggleClient";

class UnleashHelper {

    private _initialized: boolean = false;

    private _client: UnleashClient = new UnleashClient({
        // Add anything formally valid to make constructor happy.
        url: "https://example.com",
        appName: "name",
        clientKey: "key"
    });

    private _localClient: LocalFeatureToggleClient = new LocalFeatureToggleClient();

    public async initialize(): Promise<void> {
        if (!this._initialized) {
            // Parse settings from key
            const context: ApplicationContext = ch.getContext();
            const userContext = (context as UserContext);

            if (userContext.isLocalDevelopment || userContext.isPullRequest) {
                await this._localClient.start();
            } else {
                const parts: string[] = context.settings.unleashUrl.match("(.*)[:](.*)[.].*");
                const appName: string = parts[1];
                const environment: string = parts[2];

                this._client = new UnleashClient({
                    clientKey: context.settings.unleashKey,
                    url: context.settings.unleashUrl,
                    appName: appName,
                    context: {userId: userContext.user?.username?.toLowerCase() ?? undefined},
                    environment: environment,
                });

                await this.client.start();
            }

            this._initialized = true;
        }
    }

    /**
     * Set the `userId` used when fetching feature flags.
     */
    public async setEmailAsUserId(email: string | undefined): Promise<void> {
        await this.client.updateContext({userId: email?.toLowerCase()});
    }

    public get client(): UnleashClient {
        return this._client;
    }

    /** Returns true if the flag is configured and enable for the current environment and country. */
    public isEnabled(flagName: string): boolean {
        const context = ch.getContext() as UserContext;

        if (context.isLocalDevelopment || context.isPullRequest) {
            return this._localClient.isEnabled(flagName);
        }

        const getCountry = function (): string {
            if (ch.isSweden) {
                return "SE";
            }
            if (ch.isFinland) {
                return "FI";
            }
            if (ch.isDenmark) {
                return "DK";
            }
            if (ch.isPoland) {
                return "PL";
            }
            if (ch.isNorway) {
                return "NO";
            }

            throw new Error("Not supported country: " + ch.country);
        };

        //To make sure NOR turns into => NO
        const fullFlagName: string = `EASY_${flagName}_${getCountry()}`;
        return this.client.isEnabled(fullFlagName);
    }

    public update(flagName: string, enabled: boolean): void {
        this._localClient.update(flagName, enabled);
    }
}

/** {@link UnleashHelper} singleton. */
export default new UnleashHelper();
