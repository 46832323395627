import React, {useMemo} from "react";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import EnvironmentControlHelper from "@/helpers/EnvironmentControlHelper";
import Localizer from "@/localization/Localizer";
import {Button, ButtonType, Icon} from "@renta-apps/athenaeum-react-components";
import styles from "./SensorsGrid.module.scss";

type SensorsGridProps = {
    devices: EnvironmentDevice[],
    language: string,
    onSensorClick?: (deviceId: string, sensors: number[]) => Promise<void>,
    transform?: (sensors: SensorGridRow[]) => SensorGridRow[],
};

type SensorGridRow = {
    deviceId: string | null,
    sensorId: number,
    name: string,
    unit: string,
    icon: string,
    value: string | null,
    min: string,
    max: string,
    updated: string,
};

const SensorsGrid: React.FC<SensorsGridProps> = (props: SensorsGridProps) => {
    const sensors: SensorGridRow[] = useMemo(() => {
        const deviceSensors = props.devices
            .filter(item => item.sensors.length > 0)
            .sort((deviceA, deviceB) => (deviceA.name ?? '').localeCompare(deviceB.name ?? ''))
            .flatMap(device => EnvironmentControlHelper.orderSensors(device, [1, 5, 0, 3]).map(sensor => ({
                deviceId: device.id,
                sensorId: sensor.id,
                name: device.name ?? '',
                unit: EnvironmentControlHelper.getSensorUnit(sensor),
                // 0 - structure temperature, 3 - structure humidity
                icon: [0, 3].includes(sensor.id) ? 'fas fa-block-brick' : 'fas fa-wind',
                value: sensor.latestValue?.toFixed(1) ?? null,
                min: sensor.min.toFixed(1),
                max: sensor.max.toFixed(1),
                updated: EnvironmentControlHelper.getSensorRefreshTime(sensor, props.language, 'narrow')
            })));
        
        return props.transform ? props.transform(deviceSensors) : deviceSensors;
    }, [props.devices, props.language, props.transform]);

    return (
        <div className="sensors-grid">
            {sensors.map(sensor => (
                <div className={styles.container} key={`${sensor.deviceId}_${sensor.sensorId}`}>
                    <div className={styles.header}>
                        <div className={styles.sensorName}>
                            {sensor.name}
                        </div>
                        {props.onSensorClick && (
                            <Button className={styles.iconButton}
                                    type={ButtonType.Light}
                                    icon={{name: "far chart-line-up"}}
                                    onClick={async () => await props.onSensorClick!(sensor.deviceId!, [sensor.sensorId])}
                            />
                        )}
                    </div>
                    <div className={styles.values}>
                        <div className={styles.label}>{Localizer.environmentControlSensorGridUpdated}</div>
                        <div className={styles.value}>{sensor.updated}</div>
                        <div className={styles.label}>{Localizer.environmentControlSensorGridMin}</div>
                        <div className={styles.value}>{sensor.min}</div>
                        <div className={styles.label}>{Localizer.environmentControlSensorGridMax}</div>
                        <div className={styles.value}>{sensor.max}</div>
                    </div>
                    <div className={styles.current}>
                        <div className={styles.currentValue}>{sensor.value}</div>
                        <div className={styles.icon}><Icon name={sensor.icon}/></div>
                        <div className={styles.unit}>{sensor.unit}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SensorsGrid;