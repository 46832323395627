import { pageData } from "@/helpers/CypressHelper";

describe('Desktop - Admin Depot tests', () => {

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.visit('depots');
    });

    it('Opens Depot and tries to save an image exceeding 4MB in size', () => {

        pageData().depot.buttons.edit().click();

        pageData().depot.inputs.file()
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_large.png',
                fileName: 'test_picture_large.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, { force: true });

        cy.get('.athenaeum-alert-message');
    });

    it('Opens Depot and tries to save small image and verifies image is rendered', () => {

        pageData().depot.buttons.create().click();

        pageData().depot.inputs.file()
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_small.png',
                fileName: 'test_picture_small.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, { force: true });

        cy.get('[alt="test_picture_small.png"]');
    });

});