import Localizer from "@/localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";

describe('Desktop - Company user contract details tests', () => {

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

        cy.visit('/');
    });

    it('Should display page with company access friendly error', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesContractDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee592`));

        cy.get('h1')
            .should('contain', Localizer.companyDetailsCannotBeAccessedTitle);

        cy.get('div')
            .should('contain', Localizer.companyDetailsCannotBeAccessedDescription);

        cy.get('div')
            .should('contain', Localizer.companyDetailsCannotBeAccessedWhatNext);

        cy.get('li')
            .should('contain', Localizer.genericTryLoggingInAgain);

        cy.get('a')
            .should('contain', Localizer.genericContactSupport);

    });

    it('Should display page with contract details', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`));

        pageData().company.contractName()
            .should('contain', 'TMI JUKKA WILSKA');

        cy.get('#contractVatId')
            .should('contain', '2798958-4');

        cy.get('#customerId')
            .should('contain', '29276');

        cy.get('#ContractDetailsContainer')
            .should('exist');
    });

    it('Should add a user to Easy Plus and remove it later', () => {

        cy.intercept('POST', '/api/EasyPlus/TermsReadingExists*', { fixture: 'getTermsReadingExists.json' });

        pageData().company.easyPlus.tab().find('a').click();

        cy.url().should('contain', `selectedTab=easyplus`);

        cy.get('div[data-cy="easyPlusInfoPanelLine1"]').should('contain', Localizer.easyPlusInfoPanelLine1);

        cy.get('div[data-cy="easyPlusInfoPanelLine2"]').should('contain', Localizer.easyPlusInfoPanelLine2);

        cy.get('div[data-cy="easyPlusInfoPanelLine3"]').should('contain', Localizer.easyPlusInfoPanelLine3);

        const gridRow = 8;

        // Add a user to EasyPlus.
        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', Localizer.easyPlusManagementUsersGridNotActive);

        executeWithIntercept(() => {
            GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 3)
                .find(pageData().company.easyPlus.switchToggler)
                .first()
                .click();

            pageData().company.easyPlus.confirmationDialog().should('exist');
            pageData().company.easyPlus.acceptCheckboxBox().should('have.attr', 'disabled');
            pageData().company.easyPlus.termsContainer().scrollTo('bottom');
            pageData().company.easyPlus.acceptCheckboxBox().should('not.have.attr', 'disabled');
            pageData().company.easyPlus.acceptCheckbox().click();
            pageData().company.easyPlus.activateButton().click();
        },
            [
                pageData().company.routes.usersPagedList as RouteData,
            ]
        );

        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', Localizer.easyPlusManagementUsersGridActive);

        // Remove the user from EasyPlus.
        executeWithIntercept(() => {
            GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 3)
                .find(pageData().company.easyPlus.switchToggler)
                .first()
                .click();
        },
            [
                pageData().company.routes.usersPagedList as RouteData,
            ]
        );

        const date = new Date();
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', Localizer.easyPlusManagementUsersGridActiveUntil);

        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', lastDayOfMonth.toLocaleDateString());
    });
});