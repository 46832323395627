import React from "react";
import {Button, ButtonType, Form, TextInput} from "@renta-apps/athenaeum-react-components";
import styles from './ProductDetailsEditTechnicalInformation.module.scss';
import Localizer from "@/localization/Localizer";
import FileApiModel from "@/models/server/FileApiModel";
import ProductAttributeModel from "@/models/server/ProductAttributeModel";

interface ProductDetailsEditTechnicalInformationProps {
    className?: string;
    index: number;
    isSpinning: boolean;
    modelName: string;
    images: FileApiModel[];
    attributes: ProductAttributeModel[];
    isLastElement: () => boolean;
    isImageSelected: (image: FileApiModel) => boolean;
    getImageSource: (image: FileApiModel) => string;
    onModelInfoNameChangeAsync: (name: string) => Promise<void>;
    onModelInfoFieldChangeAsync: (attr: ProductAttributeModel, value: string) => Promise<void>;
    onSaveChangesForSingleInfoModelClickAsync: () => Promise<void>;
    onDeleteModelInfoClickAsync: () => Promise<void>;
    onAddEmptyInfoClickAsync: () => Promise<void>;
    onImageClick: (image: FileApiModel, isSelected: boolean) => void;
}

/**
 * Stateless UI component
 */
const ProductDetailsEditTechnicalInformation = (props: ProductDetailsEditTechnicalInformationProps): JSX.Element => {
    const {className, isSpinning, modelName, images, attributes} = props;
    const {isImageSelected, isLastElement, getImageSource} = props;
    const {onImageClick, onModelInfoFieldChangeAsync, onModelInfoNameChangeAsync, onSaveChangesForSingleInfoModelClickAsync, onDeleteModelInfoClickAsync, onAddEmptyInfoClickAsync} = props;

    return (
        <div className={[className].join(' ')}>
            <TextInput required
                       label={Localizer.genericModel}
                       value={modelName}
                       readonly={isSpinning}
                       onChange={async (_, value) => await onModelInfoNameChangeAsync(value)}
            />

            <div className={styles.infosImageSelector}>
                {
                    images.map((image, index) => {
                        return (
                            <img key={image.id + index}
                                 src={getImageSource(image)}
                                 data-is-selected={String(isImageSelected(image))}
                                 alt={""}
                                 onClick={() => onImageClick(image, isImageSelected(image))}
                                 onError={({currentTarget}: any) => {
                                     currentTarget.onerror = null;
                                     currentTarget.src = "/images/placeholder.jpg";
                                 }}
                            />
                        );
                    })
                }
            </div>

            <Form>
                {
                    attributes.map((attribute, index) => (
                        <TextInput key={attribute.key! + index}
                                   label={attribute.key!}
                                   value={attribute.value ?? ""}
                                   readonly={isSpinning}
                                   onChange={async (_, value) => await onModelInfoFieldChangeAsync(attribute, value)}
                        />
                    ))
                }

                <Button label={Localizer.formSave}
                        type={ButtonType.Default}
                        onClick={async () => await onSaveChangesForSingleInfoModelClickAsync()}
                />

                <Button label={Localizer.formDelete}
                        type={ButtonType.Danger}
                        onClick={async () => await onDeleteModelInfoClickAsync()}
                />

                {
                    (isLastElement()) &&
                    (
                        <Button label={Localizer.productDetailsAddNewModel}
                                type={ButtonType.Orange}
                                onClick={async () => await onAddEmptyInfoClickAsync()}
                        />
                    )
                }

            </Form>
        </div>
    );
};

export default ProductDetailsEditTechnicalInformation;