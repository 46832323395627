import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ProductModel from "@/models/server/ProductModel";
import CategoryModel from "@/models/server/CategoryModel";
import FileApiModel from "@/models/server/FileApiModel";
export default class ImageProvider {

    public static readonly imageBasePath: string = "/files/images";

    public static readonly thumbnailBasePath: string = ImageProvider.imageBasePath + "/thumbnail";

    public static getImageAddress(image: FileApiModel): string {
        return (image.src)
            ? image.src
            : `${this.imageBasePath}/${image.reference}.png`;
    }

    public static getImageThumbnailAddress(image: FileApiModel): string {
        return `${this.thumbnailBasePath}/${image.reference}.png`;
    }

    public static getProductThumbnailSrc(product: ProductModel): string {
        return (product.imageReference) && (product.imageReference.reference)
            ? `${this.thumbnailBasePath}/${product.imageReference.reference}.png`
            : (product.category) && (product.category.imageReference)
                ? `${this.thumbnailBasePath}/${product.category.imageReference}.png`
                : "/images/placeholder.jpg";
    }

    public static getCategoryImageSrc(category: CategoryModel): string {
        return this.getCategoryImage(this.imageBasePath, category.imageReference);
    }

    public static getCategoryThumbnailSrc(category: CategoryModel): string {
        return this.getCategoryImage(this.thumbnailBasePath, category.imageReference);
    }

    public static convertCLImageToEasyImage(fileModel: FileModel): FileApiModel {
        const fileApiModel: FileApiModel = new FileApiModel();

        // Old images store their Id in the unused Description, get it from there.
        if (fileModel.description) {
            fileApiModel.id = fileModel.description;
        }
        else {
            // New images bytes are stoder in Src, human-readable filename in Name.
            fileApiModel.src = fileModel.src;
            fileApiModel.name = fileModel.name;
            fileApiModel.type = fileModel.type;
        }

        return fileApiModel;
    }

    public static convertEasyImageToCLImage(fileApiModel: FileApiModel): FileModel {
        const fileModel: FileModel = new FileModel();

        // FileModel.Id Cannot be set, as it overrides the Src. Preserve Id of old image by storing it in the unused FileModel.Description instead.
        if (fileApiModel.id) {
            fileModel.description = fileApiModel.id;
        }

        // Name: human readable text identifying the image. Old images do not have it, use Reference instead.
        fileModel.name = fileApiModel.name ?? fileApiModel.reference;

        fileModel.type = fileApiModel.type;

        // Src: source of the image. Old image do not have it, use Reference or Id instead.
        fileModel.src = fileApiModel.src ?? `${ImageProvider.imageBasePath}/${fileApiModel.reference}.png`;

        return fileModel;
    }

    public static onError(src: string = "/images/placeholder.jpg") {
        return ({currentTarget}: any) => {
            currentTarget.onerror = null;
            currentTarget.src = src;
        };
    }

    public static getCategoryImage(basePath: string, imageReference: string): string {
        return (imageReference)
            ? `${basePath}/${imageReference}.png`
            : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; // single pixel
    }
}