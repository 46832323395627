import React, {FC} from "react";
import {Icon, Tooltip, TooltipPosition} from "@renta-apps/renta-react-components";
import styles from "@/components/InlineTooltip/InlineTooltip.module.scss";

interface InlineTooltipProps {
    id?: string;
    text: string;
    icon?: JSX.Element;
    className?: string;
    tooltipClassName?: string;
    noOpacity?: boolean;
    onClick?(event: React.MouseEvent): void;
}

const InlineTooltip: FC<InlineTooltipProps> = (props: InlineTooltipProps) => {
    return (
        <Tooltip text={props.text} position={TooltipPosition.TOP} 
                 tooltipClassName={props.tooltipClassName || styles.defaultTooltip}
                 className={props.className}>
            <div className={`t-tip ${styles.tooltip} ${props.noOpacity ? styles.noOpacity : ''} ${props.className}`}>
                {(props.icon) ? props.icon : (
                    <Icon name={"far fa-question-circle"}/>
                )}
            </div>
        </Tooltip>
    );
}

export default InlineTooltip;