import Localizer from "@/localization/Localizer";
import GridHelper from "@/helpers/CypressGridHelper";
import { executeWithIntercept, onRedirect, pageData, RouteData } from "@/helpers/CypressHelper";

describe('Desktop - EasyPlus - terms and conditions tests', () => {

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
        cy.visitPage('frontpage');
        pageData().common.waitTopNavRender();
    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false
    })

    it("End user must complete the registration process to use explore the application", () => {

        const gridRow = 10;
        const contractId = '31eaa6c0-42fa-40c2-a891-bcd16c367409';
        const userId = '889506d7-03d2-4cba-9810-51063a294038';

        cy.intercept("POST", "/api/EasyPlus/InviteUser").as("inviteUser");

        // Visit company page.
        onRedirect(() => cy.visit(`${Localizer.pageRoutesContractDetails}?id=${contractId}`));

        pageData().company.easyPlus.tab().find('a').click();

        cy.url().should('contain', `selectedTab=easyplus`);

        // Invite a user to EasyPlus.
        executeWithIntercept(() => {
            GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 3)
                .find(pageData().company.easyPlus.switchToggler)
                .first()
                .click();

            pageData().company.easyPlus.confirmationDialog().should('not.exist');
        },
            [
                pageData().company.routes.usersPagedList as RouteData,
            ]
        );

        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', Localizer.easyPlusManagementUsersGridInvited);

        cy.wait("@inviteUser").then(({ response }) => {
            if (response?.body?.value?.tokenUrls && response?.body?.value?.tokenUrls.length > 0) {
                const tokenUrl = response?.body?.value?.tokenUrls[0];

                // logout admin user
                cy.visit("/Logout");

                // navigate to captured tokenUrl
                // waiting for the applicationContext
                cy.intercept(pageData().common.routes.tokenLogin.method, pageData().common.routes.tokenLogin.path).as('tokenLogin');
                cy.visit(tokenUrl);
                cy.waitSuccess('@tokenLogin')

                // verify whether the end user will be redirected to the EasyPlus page
                cy.location().should((loc) => {
                    expect(loc.pathname).to.eq(`/EasyPlusPage`);
                });

                cy.url().should('include', `EasyPlusPage?contractId=${contractId}&userId=${userId}`);

                pageData().company.easyPlus.confirmationDialog().should('exist');
                pageData().company.easyPlus.acceptCheckboxBox().should('have.attr', 'disabled');
                pageData().company.easyPlus.termsContainer().scrollTo('bottom');
                pageData().company.easyPlus.acceptCheckboxBox().should('not.have.attr', 'disabled');
                pageData().company.easyPlus.acceptCheckbox().click();
                pageData().company.easyPlus.activateButton().click();
            }
        });
    });

});