import React, {useEffect, useRef, useState} from "react";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import {Checkbox, Dropdown, IDropdownItem, Input} from "@renta-apps/renta-react-components";
import {CustomerContactInformationModel} from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";
import ConstructionSiteUserModel from "@/models/server/ConstructionSiteUserModel";
import Localizer from "@/localization/Localizer";
import {UserRolesHelper} from "@/helpers/UserRolesHelper";

type CustomerInformationProps = {
    onUserDetailsChange: (contactInformationModel: CustomerContactInformationModel) => void;
    users: ConstructionSiteUserModel[]
}

const CustomerInformation: React.FC<CustomerInformationProps> = ({users, onUserDetailsChange}) => {
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [contactUserEmail, setContactUserEmail] = useState<string | null>(null);
    const [contactUserPhone, setContactUserPhone] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [usersDropDownItems, setUsersDropDownItems] = useState<IDropdownItem[]>([]);

    const [useFreeTextUser, setUseFreeTextUser] = useState<boolean>(false);

    // Store previous values to prevent redundant updates
    const prevValues = useRef({
        useFreeTextUser,
        firstName,
        lastName,
        contactUserEmail,
        contactUserPhone,
    });

    useEffect(() => {
        const dropDownItems: IDropdownItem[] = users.map(item => (
            {
                name: `${item.firstName} ${item.lastName}`,
                value: item.userId!,
                description: UserRolesHelper.translate(item.roleName ?? ""),
                group: item.invited ? Localizer.easyPlusManagementUsersGridInvited : null,
            }
        ));
        setUsersDropDownItems(dropDownItems);

    }, [users]);

    useEffect(() => {
        const hasChanged =
            prevValues.current.useFreeTextUser !== useFreeTextUser ||
            prevValues.current.firstName !== firstName ||
            prevValues.current.lastName !== lastName ||
            prevValues.current.contactUserEmail !== contactUserEmail ||
            prevValues.current.contactUserPhone !== contactUserPhone;

        if (hasChanged) {
            onUserDetailsChange({
                contactPersonEmail: contactUserEmail || "",
                contactPersonPhone: contactUserPhone || "",
                contactPersonFirstName: firstName,
                contactPersonLastName: lastName,
                useFreeTextUser: useFreeTextUser,
                userId: userId,
            });
            // Update previous values
            prevValues.current = {useFreeTextUser, firstName, lastName, contactUserEmail, contactUserPhone};
        }
    }, [useFreeTextUser, firstName, lastName, contactUserEmail, contactUserPhone, onUserDetailsChange]);

    const handleContactFilterChange = (userId: string | undefined) => {
        let userModel: ConstructionSiteUserModel | undefined = users.find(item => item.userId === userId);
        if (userModel) {
            setUserId(userId!);
            setContactUserEmail(userModel.email!);
            setContactUserPhone(userModel.phone!);
            setFirstName(userModel.firstName!);
            setLastName(userModel.lastName!);
        } else {
            setUserId(null);
            setContactUserEmail(null);
            setContactUserPhone(null);
        }
    };

    const handleUserTypeChange = (useFreeTextUser: boolean) => {
        setUseFreeTextUser(useFreeTextUser);

        // Clear values when radio value changed
        setUserId(null);
        setContactUserEmail(null);
        setContactUserPhone(null);
        setFirstName("");
        setLastName("");
    };

    return (
        <ContentBox title={Localizer.returnRequestModalCustomerInfoTitle} subTitle={Localizer.returnRequestModalCustomerInfoSubTitle}>
            <div className={`${styles.customerInformationContainer} ${useFreeTextUser ? styles.freeTextUser : ''}`}
                 data-cy="return-modal-customer-information"
            >
                <div className={styles.radioButtons} data-cy="return-modal-customer-info-radio-buttons">
                    <Checkbox checked={!useFreeTextUser}
                              onChange={() => handleUserTypeChange(false)}
                              label={Localizer.returnRequestModalCustomerInfoExistingEasyUser}
                              className={styles.radioCheckbox}
                    />
                    <Checkbox checked={useFreeTextUser}
                              onChange={() => handleUserTypeChange(true)}
                              label={Localizer.returnRequestModalCustomerInfoNotAnEasyUser}
                              className={styles.radioCheckbox}
                    />
                </div>
                {(useFreeTextUser) ? (
                    <>
                        <Input type="text"
                               id={"customer_info_free_text_first_name"}
                               label={Localizer.genericFirstName}
                               value={firstName}
                               className={`${styles.freeTextFirstName} ${styles.inputField}`}
                               onChange={(value: string) => setFirstName(value)}
                               inputProperties={{
                                   required: true,
                                   name: "firstName",
                               }}
                        />
                        <Input type="text"
                               id={"customer_info_free_text_last_name"}
                               label={Localizer.genericLastName}
                               value={lastName}
                               className={`${styles.freeTextLastName} ${styles.inputField}`}
                               onChange={(value: string) => setLastName(value)}
                               inputProperties={{
                                   required: true,
                                   name: "lastName",
                               }}
                        />
                        <Input type="email"
                               id={"customer_info_free_text_email"}
                               label={Localizer.returnRequestModalCustomerInfoEmailOptional}
                               value={contactUserEmail}
                               onChange={(value: string) => setContactUserEmail(value)}
                               className={`${styles.freeTextEmail} ${styles.inputField}`}
                               inputProperties={{
                                   required: false,
                                   name: "contactUserEmail",
                                   maxLength: 200,
                               }}
                        />
                        <Input type="tel"
                               id={"customer_info_free_text_phone"}
                               label={Localizer.returnRequestModalCustomerInfoPhoneOptional}
                               value={contactUserPhone}
                               className={`${styles.freeTextPhone} ${styles.inputField}`}
                               onChange={(value: string) => setContactUserPhone(value)}
                               inputProperties={{
                                   required: false,
                                   pattern: "\\+([0-9]{6,})",
                                   name: "contactUserPhone",
                                   maxLength: 20,
                               }}
                        />
                    </>
                ) : (
                    <Dropdown allowEmpty searchable
                              id={`customer-contact-info`}
                              className={`${styles.contactUser} ${styles.customerInformationDropdown} ${userId ? '' : styles.empty}`}
                              items={usersDropDownItems}
                              label={Localizer.returnRequestModalCustomerInfoDropDownLabel}
                              selectedItem={userId ?? Localizer.returnRequestModalCustomerInfoDropDownPlaceHolder}
                              onChange={(item: IDropdownItem | undefined) => handleContactFilterChange(item?.value?.toString())}
                    />
                )}
            </div>
        </ContentBox>
    );
};

export default CustomerInformation;
