import React from "react";
import styles from "./LoadingOrDataNotFound.module.scss";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface LoadingDotsProps {
    isLoading: boolean;

    noDataText: string;

    className?: string;

}

const LoadingOrDataNotFound = ({isLoading, noDataText, className}: LoadingDotsProps): JSX.Element => {
    return isLoading ? (
            <div className={Utility.css(className, styles.loadingDotsContainer)}>
                <div className={styles.dotElastic}></div>
            </div>
        )
        : (
            <div id={"data_not_found"} className={Utility.css(className, styles.loadingDotsContainer)}>
                {
                    noDataText
                }
            </div>
        );
};

export default LoadingOrDataNotFound;