import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import ToolbarModel from "@/models/ToolbarModel";
import React from "react";
import {Button, ButtonType, DateRangeInput, Dropdown, DropdownOrderBy, IconSize} from "@renta-apps/athenaeum-react-components";
import EnumProvider from "@/providers/EnumProvider";
import {ServiceRequestStatus} from "@/models/Enums";
import styles from "./Toolbar.module.scss";
import Localizer from "@/localization/Localizer";

interface IToolbarProps {
    model: ToolbarModel;

    onChange?(model: ToolbarModel, clear: boolean): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel;
}

export default class Toolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    public state: IToolbarState = {
        model: this.props.model
    };

    private async processOnChangeAsync(invoke: boolean = false, clear: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model, clear);
        }
    }

    private async setStatusAsync(status: ServiceRequestStatus | number | null) {
        this.state.model.status = status;
        await this.processOnChangeAsync(true);
    }

    private async setFromToAsync(from: Date | null, to: Date | null) {
        this.state.model.to = to;
        this.state.model.from = from;
        await this.processOnChangeAsync(true);
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();

        await this.processOnChangeAsync(true);
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.toolBarContainer}>
                <Dropdown className={styles.statusDropdown}
                          label={Localizer.servicesGridStatus}
                          minWidth="170px"
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.state.model.status}
                          items={EnumProvider.getServiceRequestStatusItems()}
                          onChange={async (sender, item) => await this.setStatusAsync(item?.value as any)}
                />

                <DateRangeInput sameDay
                                className={styles.dateRangeInput}
                                label={Localizer.servicesFiltersDate}
                                model={{value: [this.state.model.from, this.state.model.to]}}
                                onChange={async ([start, end]) => await this.setFromToAsync(start, end)}
                />
                <div className={styles.buttonContainer}>
                    <Button small title={Localizer.componentSignatureWidgetClear}
                            icon={{name: "far history", size: IconSize.Large}}
                            type={ButtonType.Info}
                            onClick={async () => await this.clearAsync()}
                    />
                </div>
            </div>
        );
    }

}