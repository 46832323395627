import {Button, ButtonType, Form, IStringInputModel, Modal, OneColumn, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect} from "react";
import {Dictionary} from "typescript-collections";
import {createCategoryAsync} from "@/services/CatalogService";

type NewCategoryModalProps = {
    isOpen: boolean;
    currentCategoryId: string;
    onClose(success: boolean): void;
}

const NewCategoryModal: React.FC<NewCategoryModalProps> = (props: NewCategoryModalProps) => {

    const _modalRef: React.RefObject<Modal> = React.createRef();

    const _newCategoryName: IStringInputModel = {value: ""};

    const createCategory = async (data: Dictionary<string, any>): Promise<void> => {
        const name: string = data.getValue("newCategoryName");
        const parentCategoryId: string = props.currentCategoryId!;

        const createdCategory = await createCategoryAsync(name, parentCategoryId);
        
        props.onClose(!!createdCategory);
    }
    
    const cleanup = (): void => {
        _newCategoryName.value = "";
    }

    useEffect(() => {
        if (props.isOpen) {
            _modalRef.current?.openAsync();
        } else {
            cleanup();
            _modalRef.current?.closeAsync();
        }
    }, [props.isOpen, _modalRef]);

    return (
        props.isOpen ? (
            <Modal ref={_modalRef}
                   title={Localizer.catalogEditCategoryNewCategory}
                   onClose={async () => props.onClose(false)}
            >
                <Form id="createCategory" onSubmit={async (_, data) => createCategory(data)}>
                    <OneColumn>
                        <TextInput required
                                   id="newCategoryName"
                                   label={Localizer.catalogEditProductProductName}
                                   model={_newCategoryName}
                        />
    
                        <Button submit block
                                id={"createCategorySubmit"}
                                type={ButtonType.Orange}
                                label={Localizer.genericSave}
                        />
                    </OneColumn>
                </Form>
            </Modal>
        ) : null
    );
}

export default NewCategoryModal;