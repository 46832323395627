import React from "react";
import Localizer from "@/localization/Localizer";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AdminPage from "@/models/base/AdminPage";
import AlarmsGrid from "@/components/AlarmsGrid/AlarmsGrid";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import AlarmModel from "@/models/server/AlarmModel";
import RentaEasyController from "@/pages/RentaEasyController";

export interface IAdminAlarmsParams extends BasePageParameters {
}

interface IAdminAlarmsState {
}

export default class AdminAlarms extends AdminPage<IAdminAlarmsParams, IAdminAlarmsState> {
    public state: IAdminAlarmsState = {};

    protected get title(): string {
        return Localizer.alarmsPageTitle;
    }

    private async fetchAlarms(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<AlarmModel>> {
        return RentaEasyController.fetchAlarms({pageNumber: pageNumber, pageSize: pageSize, sortColumnName: sortColumnName, sortDirection: sortDirection});
    }

    private async deleteAlarmAsync(id: string): Promise<void> {
        await RentaEasyController.deleteAlarmAsync(id);
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title} />

                <PageRow>
                    <AlarmsGrid key={"admin_alarms_grid"}
                                fetchAlarms={async (pageNumber, pageSize, sortColumnName, sortDirection) =>
                                    await this.fetchAlarms(pageNumber, pageSize, sortColumnName, sortDirection)}
                                deleteAlarm={async (id: string) => await this.deleteAlarmAsync(id)}
                    />
                </PageRow>
            </PageContainer>
        );
    }


}