import ServiceRequestDefinitionInput from "@/models/server/ServiceRequestDefinitionInput";

/**
 * Used for grouping inputs to separate "pages"
 */
export default class InputGroup {

    public groupHeader: string = "";

    public groupId: number = 0;

    public inputs: ServiceRequestDefinitionInput[] = [];

}