import {onRedirect, pageData} from "@/helpers/CypressHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe('Desktop - Anonymous frontpage tests', () => {

    it('should display cookie consent modal and open privacy statement', () => {

        onRedirect(() => cy.visit('/'));

        pageData().common.cookieBanner.cookieBannerModal().should('exist');
        pageData().common.cookieBanner.privacyPolicyButton().should('exist');
        pageData().common.cookieBanner.acceptMandatoryCookiesButton().should('exist');
        pageData().common.cookieBanner.acceptAllCookiesButton().should('exist');

        pageData().common.cookieBanner.privacyPolicyButton().click();

        pageData().common.cookieBanner.cookieBannerModal().should('exist').and('not.be.visible');
        pageData().common.cookieBanner.privacyPolicyModal().should('exist').and('be.visible');
        pageData().common.cookieBanner.privacyPolicyModalCloseButton().should('exist');

        pageData().common.cookieBanner.privacyPolicyModalCloseButton().click();

        pageData().common.cookieBanner.privacyPolicyModal().should('exist').should('not.be.visible');

    });

    it('should not display cookie consent modal when cookiebot is enabled', () => {
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagCookiebot);

        onRedirect(() => cy.visit('/'));

        pageData().common.cookieBanner.cookieBannerModal().should('not.exist');

        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagCookiebot);
        cy.reload();
        pageData().common.waitTopNavRender();
    });

    it('Open frontPage and verify correct widgets in place', () => {

        cy.session(['accept_cookie'], cy.acceptCookies);
        onRedirect(() => cy.visit('/'));

        pageData().frontPage.widgets.rent().should('exist');
        pageData().frontPage.widgets.fleetMonitoring().should('not.exist');
        pageData().frontPage.widgets.depot().should('exist');
        pageData().frontPage.widgets.login().should('exist');
        pageData().frontPage.widgets.register().should('exist');

        cy.get('#roleDropdown').should('not.exist');
        cy.get('#apply_company_access_widget').should('not.exist');
        cy.get('#inventory_management_widget').should('not.exist');
        cy.get('#private_orders_widget').should('not.exist');
        cy.get('#service_widget').should('not.exist');
    });

});