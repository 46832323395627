import {FC} from "react";
import styles from "@/pages/ShoppingCart/ShoppingCart.module.scss";
import Localizer from "@/localization/Localizer";
import OrganizationContractDiscounts from "@/models/server/OrganizationContractDiscounts";
import PriceHelper, {IProductPrice, ProductPriceType} from "@/helpers/PriceHelper";

type ShoppingCartSummaryProps = {
    rentalProducts: any[];
    salesProducts: any[];
    attachedRentalProducts: any[];
    attachedSalesProducts: any[];
    hasRentalProducts: boolean;
    hasSalesProducts: boolean;
    contractDiscounts: OrganizationContractDiscounts | null;
    vat: number;
}

const ShoppingCartSummary: FC<ShoppingCartSummaryProps> = (props: ShoppingCartSummaryProps) => {
    
    const discounts: OrganizationContractDiscounts | null = props.contractDiscounts;

    const allRentalProducts = props.rentalProducts.concat(props.attachedRentalProducts);
    const rentalDailyPrice: IProductPrice = PriceHelper.sum(allRentalProducts, props.vat, ProductPriceType.Daily, discounts);
    const rentalMonthlyPrice: IProductPrice = PriceHelper.sum(allRentalProducts, props.vat, ProductPriceType.Monthly, discounts);
    const salesPrice: IProductPrice = PriceHelper.sum(props.salesProducts.concat(props.attachedSalesProducts), props.vat, ProductPriceType.Unit, discounts);

    return (
        <div className={styles.summary}>
            {(props.hasRentalProducts) && (
                <div className={styles.priceTypeContainer}>
                    <span>
                        {Localizer.shoppingCartPageRentalProductsTotalCost.toUpperCase()}:
                    </span>

                    <div>
                        <div className={styles.priceRow}>
                            <span className={styles.price}>
                                {PriceHelper.toLocalizedValue(rentalDailyPrice)}
                            </span>

                            <span className={styles.unit}>
                                {(rentalDailyPrice.value !== 0) && (
                                    PriceHelper.units.daily(props.vat)
                                )}
                            </span>
                        </div>

                        {(!!rentalMonthlyPrice.value && rentalMonthlyPrice.value > 0) && (
                            <div className={styles.priceRow}>
                                <span className={styles.price}>
                                    {PriceHelper.toLocalizedValue(rentalMonthlyPrice)}
                                </span>

                                <span className={styles.unit}>
                                    {PriceHelper.units.monthly(props.vat)}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {(props.hasSalesProducts) && (
                <div className={styles.priceTypeContainer}>
                    <span>
                        {Localizer.shoppingCartPageSalesProductsTotalCost.toUpperCase()}:
                    </span>

                    <div className={styles.priceRow}>
                        <span className={styles.price}>
                            {PriceHelper.toLocalizedValue(salesPrice)}
                        </span>

                        <span className={styles.unit}>
                            {(salesPrice && salesPrice.value !== 0) && (
                                    PriceHelper.getVatString(props.vat)
                            )}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ShoppingCartSummary;