import {PlanStatus} from "@/models/Enums";

export default class PlansToolbarModel {

    public from: Date | null = null;

    public to: Date | null = null;

    public status: PlanStatus | null = null;

    public completedBy: string | null = "";

    public depotUserEmail: string | null = "";
}