import Localizer from "@/localization/Localizer";

export const validatePhoneNumber = (phoneNumber: string | null): string | null => {
  let phoneRegex = /^\+([0-9]{6,})$/;
  return (!phoneNumber || !phoneNumber.match(phoneRegex)) ? Localizer.genericValidationPhoneNumber : null;
};

export const validateMultipleEmails = (email: string | null): string | null => {
  let multipleEmailRegex = /^([\w+-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(?:[,;]\s*([\w+-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+))*$/;
  return (!email || !email.match(multipleEmailRegex)) ? Localizer.validatorsEmail : null;
}