export class ContractConstructionSiteUserModel {
    public userId: string = "";

    public constructionSiteId: string = "";

    public email: string | null = null;

    public phoneNumber: string | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public roleName: string | null = null;

    public isContractUserModel: boolean = true;

    public hasPendingInvitation: boolean | null = null;
}