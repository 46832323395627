import React from "react";
import Localizer from "@/localization/Localizer";
import ProductVatButtons from "@/components/ProductVatButtons/ProductVatButtons";
import PriceHelper from "@/helpers/PriceHelper";
import Prices from "@/components/Prices/Prices";
import {Dropdown, DropdownOrderBy, SelectListItem} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";
import ProductOrganizationContractPriceModel from "@/models/server/ProductOrganizationContractPriceModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";

interface ProductDetailsPricesProps {
    vat: number;
    discount: ProductOrganizationContractPriceModel | null;
    product: ProductModel;
    selectableProductModels: string[];
    selectableProductModelsSelectListItems: SelectListItem[];
    setPreferredModel: (item: SelectListItem | null) => Promise<void>;
    setVatAsync: (value: number) => Promise<void>;
    isAuthenticated: boolean;
}


const ProductDetailsPrices = ({vat, discount, product, selectableProductModels, selectableProductModelsSelectListItems, setPreferredModel, setVatAsync, isAuthenticated}: ProductDetailsPricesProps): JSX.Element => {

    const authenticatedUsersOnly = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceForAuthenticatedUsersOnly);
    const priceIsInformative = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceIsInformative);

    return (
        <>
            {(!isAuthenticated && authenticatedUsersOnly) &&
                <div>{Localizer.productDetailsPriceForAuthenticatedUsersOnly}</div>
            }
            {(isAuthenticated || !authenticatedUsersOnly) &&
                <>
                    {
                        !PriceHelper.hasDiscount(product) && (
                            <ProductVatButtons
                                environmentVatValue={PriceHelper.environmentVat}
                                isZeroVatSelected={vat === 0}
                                isEnvVatSelected={vat === PriceHelper.environmentVat}
                                onVatChangeAsync={async (value) => await setVatAsync(value)}
                            />
                        )
                    }

                    <Prices displayDiscountText displayWeeklyPriceTooltip inlinePriceRows
                            product={product}
                            vat={vat}
                            contractDiscount={discount ?? undefined}
                            maxRows={priceIsInformative ? 1 : undefined}
                    />

                    {priceIsInformative &&
                        <div>{Localizer.productDetailsPriceIsOnlyInformative}</div>
                    }

                    {
                        (!product.preventModelSelection) && (selectableProductModels.length > 0) &&
                        (
                            <Dropdown label={Localizer.productModelPreferredModel}
                                      items={selectableProductModelsSelectListItems}
                                      orderBy={DropdownOrderBy.None}
                                      onChange={async (_, item) => {
                                          await setPreferredModel(item);
                                      }}
                            />
                        )
                    }
                </>
            }
        </>
    );
};

export default ProductDetailsPrices;