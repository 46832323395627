import React from "react";
import styles from "@/pages/ProductDetails/ProductDetails.module.scss";
import {Carousel, CarouselNavigation, CarouselPagination, IIMageInputToolbar, ImageInput} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";
import {FileType} from "@/models/Enums";
import ImageProvider from "@/providers/ImageProvider";
import FileApiModel from "@/models/server/FileApiModel";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

type ProductDetailsImagesProps = {
    product: ProductModel | null;
    editedProduct: ProductModel | null;
    images: FileApiModel[];
    isEditing: boolean;
    isLoading: boolean;

    setImagesAsync: (files: FileModel[]) => Promise<void>;
    setFullScreenImagesAsync: (images: FileApiModel[], index: number) => Promise<void>;
}

const ProductDetailsImages: React.FC<ProductDetailsImagesProps> = (props: ProductDetailsImagesProps) => {
    
    const imageCarouselRefs: React.RefObject<Carousel>[] = props.product?.infos?.map(
            () =>
                React.createRef())
        ?? [];

    imageCarouselRefs.push(React.createRef());
    
    const carouselRef: React.RefObject<Carousel> = imageCarouselRefs[0];
    
    const selectionToolbar: IIMageInputToolbar = {
        moveToTopButton: true,
        moveUpButton: true,
        moveDownButton: true,
        previewButton: true,
        uploadButton: true,
        takePictureButton: true,
        deleteButton: true,
    };

    const previewToolbar: IIMageInputToolbar = {
        deleteButton: true,
    };
    
    const images: { src: string, alt: string; }[] = props.images.length > 0
        ? props.images.map(image => ({src: ImageProvider.getImageAddress(image), alt: props.product?.name ?? ""}))
        : [{src: "/images/placeholder.jpg", alt: ""}];
    
    return (
        <>
            {(props.isEditing && props.editedProduct) ? (
                <ImageInput multi
                            convertImage={async (file) => file}
                            maxImageRequestSizeInBytes={RentaEasyConstants.maxImageUploadSizeinBytes}
                            pictures={props.editedProduct?.externalImages
                                ?.where(file => file.fileType !== FileType.Pdf)
                                .map((file) => ImageProvider.convertEasyImageToCLImage(file)) ?? []}
                            selectionToolbar={selectionToolbar}
                            previewToolbar={previewToolbar}
                            onChange={async (_, files) => await props.setImagesAsync(files)}
                />
            ) : (
                <Carousel ref={carouselRef}
                          className={`${styles.pageContentImagesImageCarousel} ${styles.pageContentImagesCarousel}`}
                          navigation={CarouselNavigation.Outside}
                          pagination={CarouselPagination.BottomOutside}
                >
                    {images.map(({src, alt}, index) => (
                        <a key={index}
                           onClick={async () => await props.setFullScreenImagesAsync(props.images, index)}
                        >
                            <img src={src} alt={alt}
                                 onError={ImageProvider.onError()}
                            />
                        </a>
                    ))}
                </Carousel>
            )}
        </>
    );
}

export default ProductDetailsImages;