import React from "react";
import {BorderType, CellAction, CellModel, ColumnDefinition, ColumnType, Grid, GridModel, Modal} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";

import Localizer from "@/localization/Localizer";
import CustomServiceRequestModel from "@/models/server/CustomServiceRequestModel";
import {ActionType} from "@renta-apps/athenaeum-react-common";
import ServicePreview from "@/components/Service/ServicePreview/ServicePreview";
import Toolbar from "@/components/Service/ServiceGrid/Toolbar/Toolbar";
import ToolbarModel from "@/models/ToolbarModel";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import UserContext from "@/models/server/UserContext";
import styles from "./ServiceGrid.module.scss";
import GetServicesRequest from "@/models/server/GetServicesRequest";

interface IServiceGridProps {
    organizationContractId: string | null;
    userId: string | null;
}

interface IServiceGridState {
    filters: ToolbarModel;
    selectedServiceRequest: CustomServiceRequestModel | null,
}

class ServiceGrid extends BaseComponent<IServiceGridProps, IServiceGridState> {

    private readonly _servicePreviewModal: React.RefObject<Modal> = React.createRef();
    private readonly _servicesGrid: React.RefObject<Grid<CustomServiceRequestModel>> = React.createRef();

    public state: IServiceGridState = {
        selectedServiceRequest: null,
        filters: new ToolbarModel()
    };

    public get servicesGrid(): GridModel<CustomServiceRequestModel> {
        return this._servicesGrid.current!.model;
    }

    private readonly _servicesColumns: ColumnDefinition[] = [
        {
            header: Localizer.servicesGridNameLanguageItemName,
            accessor: "name",
            editable: false,
            maxWidth: 100,
            init: (cell) => this.initServiceColumn(cell)
        },
        {
            header: Localizer.servicesGridOrdererLanguageItemName,
            accessor: "ordererEmail",
            editable: false,
            maxWidth: 100,
            init: (cell) => this.initServiceColumn(cell)
        },
        {
            header: Localizer.servicesGridSentAtLanguageItemName,
            accessor: "date",
            editable: false,
            type: ColumnType.Date,
            sorting: true,
            format: "D",
            maxWidth: 40,
            init: (cell) => this.initServiceColumn(cell),
        },
        {
            header: Localizer.servicesGridStatusLanguageItemName,
            accessor: "status",
            sorting: true,
            type: ColumnType.Enum,
            format: "ServiceRequestStatus",
            maxWidth: 40,
            init: (cell) => this.initServiceColumn(cell),
            editable: false
        },
        {
            header: Localizer.servicesGridViewServiceLanguageItemName,
            minWidth: 25,
            type: ColumnType.Icon,
            className: styles.halfWidth,
            actions: [
                {
                    name: "preview",
                    icon: {name: "far search", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Blue,
                    callback: async (cell) => await this.openServicePreviewAsync(cell)
                },
            ]
        },
        {
            minWidth: 25,
            type: ColumnType.Icon,
            className: styles.halfWidth,
            init: (cell) => this.initActions(cell),
            actions: [
                {
                    name: "delete",
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    callback: async (cell) => await this.deleteServicePreviewAsync(cell)
                }
            ]
        }
    ];

    private initActions(cell: CellModel<CustomServiceRequestModel>) {
        const deleteAction: CellAction<CustomServiceRequestModel> = cell.actions[0];

        deleteAction.visible = (this.userContext.isAdmin);
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private initServiceColumn(cell: CellModel<CustomServiceRequestModel>): void {
        cell.readonly = true;
    }

    private toUtcSafeDate(date: Date): Date {
        const utcDate: Date = new Date();
        utcDate.setUTCFullYear(date.getFullYear());
        utcDate.setUTCMonth(date.getMonth());
        utcDate.setUTCDate(date.getDate());
        utcDate.setUTCHours(0, 0, 0, 0);
        return utcDate;
    }

    private async getServicesAsync(filters: ToolbarModel | null, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null)
        : Promise<IPagedList<CustomServiceRequestModel>> {

        let request: GetServicesRequest = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            organizationContractId: this.props.organizationContractId,
            constructionSiteId: null,
            status: filters!.status,
            userId: this.props.userId,
            sortDirection: sortDirection,
            sortColumnName: sortColumnName,
            from: filters!.from !== null ? this.toUtcSafeDate(filters!.from!) : null,
            to: filters!.to !== null ? this.toUtcSafeDate(filters!.to!) : null
        };
        return await this.postAsync("/api/Services/GetCustomServiceRequest", request);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private async onFiltersChange(filters: ToolbarModel, clear: boolean): Promise<void> {
        if (clear) {
            await this.setState({filters});
        }
        else {
            await this.setState({filters});
        }

        await this.servicesGrid.reloadAsync();

    }

    private async deleteServicePreviewAsync(cell: CellModel<CustomServiceRequestModel>) {
        await this.postAsync("/api/Services/DeleteServiceRequests", cell.model.id);

        await this._servicesGrid.current!.rows.remove(cell.row);

        await this._servicesGrid.current!.reRenderAsync();
    }

    private async openServicePreviewAsync(cell: CellModel<CustomServiceRequestModel>) {
        await this.setState({selectedServiceRequest: cell.model});

        if (this._servicePreviewModal.current) {
            await this._servicePreviewModal.current.openAsync();

        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className={this.css(styles.toolbarContainer, "row")}>
                    <Toolbar model={this.state.filters}
                             onChange={async (model, clear) => await this.onFiltersChange(model, clear)}/>
                </div>

                <Grid responsive
                      version2Styles
                      ref={this._servicesGrid}
                      id={"services_grid"}
                      pagination={10}
                      columns={this._servicesColumns}
                      borderType={BorderType.Default}
                      noDataText={Localizer.servicesGridNoData}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.getServicesAsync(this.state.filters, pageNumber, pageSize, sortColumnName, sortDirection)}

                />

                {
                    this.state.selectedServiceRequest && (
                        <Modal ref={this._servicePreviewModal}
                               id={"servicePreviewModal"}
                               title={` `} // Empty space by purpose. `` as title will render "..." (Default title in Modal component)
                               subtitle={``}
                        >
                            {
                                <ServicePreview model={this.state.selectedServiceRequest!}
                                                editMode={false}
                                                previewMode={true}
                                />
                            }
                        </Modal>
                    )
                }

            </>
        );
    }
}

export default ServiceGrid;