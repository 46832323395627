import CategoryModel from "@/models/server/CategoryModel";
import { PriceType, ProductType, MinimumRentalDays } from "@/models/Enums";
import ProductTagModel from "@/models/server/ProductTagModel";
import ProductInfoModel from "@/models/server/ProductInfoModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import FileApiModel from "@/models/server/FileApiModel";

export default class ProductModel {
    public id: string = RentaEasyConstants.emptyGuid;

    public externalId: string | null = null;

    public rentalObjectNumber: string | null = null;

    public rentalObjectId: string | null = null;

    public trackingId: string | null = null;

    public name: string | null = null;

    public description: string | null = null;

    public url: string | null = null;

    /**
     * Unit of the Products {@link unitPrice}, {@link unitPriceWithTax} and {@link customerUnitPrice}.
     * @example Meter, m2, Km, Pound, lb, Piece
     */
    public unitType: string | null = null;

    public unitPrice: number | null = null;

    public unitPriceWithTax: number | null = null;

    public customerUnitPrice: number | null = null;

    public dailyPrice: number | null = null;

    public dailyPriceWithTax: number | null = null;

    public customerDailyPrice: number | null = null;

    public weeklyPrice: number | null = null;

    public weeklyPriceWithTax: number | null = null;

    public customerWeeklyPrice: number | null = null;

    public customerDailyPriceType: PriceType = PriceType.Default;

    public customerMonthlyPriceType: PriceType = PriceType.Default;

    /**
     * How many days should be considered as a week for weekly rent.
     */
    public rentBasis: number = 0;

    public monthlyPrice: number | null = null;

    public monthlyPriceWithTax: number | null = null;

    public customerMonthlyPrice: number | null = null;

    /**
     * Is the Product hidden from non-Admin users.
     */
    public hidden: boolean = false;

    public thumbPath: string | null = null;

    public priority: number = 0;

    /**
     * Should the user NOT be capable of selecting a specific model of the Product they want.
     * The list of models the user can select comes from ERP along with availability data, and has nothing to do with the model {@link infos} the Admin can fill-in.
     */
    public preventModelSelection: boolean = false;

    public showDescription: boolean = false;

    /**
     * Is the Product a user favorite.
     * This is set per-user, there is no global value.
     */
    public favorite: boolean = false;

    /**
     * Should the Product be displayed as always available in all Renta depots for non-Admin users.
     */
    public showAsAvailable: boolean = false;

    public imageReference: FileApiModel | null = null;

    /**
     * All images attached to the Product.
     */
    public externalImages: FileApiModel[] | null = null;

    public relatedProducts: ProductModel[] | null = null;

    public attachedProducts: ProductModel[] | null = null;

    public productTags: ProductTagModel[] | null = null;

    /**
     * Infos of the Products specific models.
     * Has nothing to do with the user-selectable Product models which are displayed if {@link preventModelSelection} is false.
     */
    public infos: ProductInfoModel[] | null = null;

    public category: CategoryModel | null = null;

    public productType: ProductType = ProductType.Rental;

    public files: FileApiModel[] | null = null;

    public isSalesItem: boolean = false;

    public isProductModel: boolean = true;

    public editDiscount: boolean = false;

    public discountPercentage: number = 0;

    public isRentaFuture: boolean = false;

    public dailyPriceType: PriceType = PriceType.Default;

    public monthlyPriceType: PriceType = PriceType.Default;

    public unitPriceType: PriceType = PriceType.Default;

    public minimumRentalDays?: MinimumRentalDays;

    public videos: string[] | null = null;
    
    public quantity: number | null = null;
}