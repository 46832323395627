import { FC } from 'react';
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Checkbox,  InlineType} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import UserContext from "@/models/server/UserContext";
import styles from "@/components/ConstructionSitesList/ConstructionSitesList.module.scss";
import RentaEasyController from "@/pages/RentaEasyController";
import ConstructionSitesPageParameters from "@/models/ConstructionSitesPageParameters";

type ConstructionSitesListItemProps = {
    constructionSiteModel: ConstructionSiteModel, 
    index: number,
    userContext: UserContext,
    constructionSiteSelection?: ConstructionSitesPageParameters,
    contractId: string | null,
    changeSiteVisibilityAsync: (constructionSiteModel: ConstructionSiteModel, checked: boolean) => Promise<void>,
    sendSummaryAsync: (constructionSiteId: string) => Promise<void>,
}
const ConstructionSitesListItem: FC<ConstructionSitesListItemProps> = (props: ConstructionSitesListItemProps) => {

    const renderDailyPrice = (value: ConstructionSiteModel): string => {
        if (value.dailyPrice !== null) {
            return `${Utility.formatValue(value.dailyPrice, "0.00")} ${props.userContext.settings.moneySymbol} / ${Localizer.contractDetailsModelDay}`;
        }
        return "";
    }

    const renderMonthlyPrice = (value: ConstructionSiteModel): string => {
        if (RentaEasyController.equipmentListSupportsMonthlyPrices() && value.monthlyPrice !== null) {
            return `${Utility.formatValue(value.monthlyPrice, "0.00")} ${props.userContext.settings.moneySymbol} / ${Localizer.contractDetailsModelMonth}`;
        }
        return "";
    }
    const renderLocationName = (value: ConstructionSiteModel): string => {
        if (value.depot !== null) {
            return value.depot.name!;
        }
        return "";
    }

    const onShowConstructionSitePrices = async (constructionSiteId: string): Promise<void> => {
        await RentaEasyController.setOperatingForConstructionSite(constructionSiteId);
        await PageRouteProvider.redirectAsync(PageDefinitions.rent.route());
    }
    
    const openDetailsPageAsync = async (constructionSiteId: string): Promise<void> => {
        await PageRouteProvider.redirectAsync(PageDefinitions.constructionSite.route({params: {id: constructionSiteId}}));
    }

    const openInviteUserPageAsync = async (constructionSiteId: string, ownerId: string | null): Promise<void> => {
        let constructionSiteParams: ConstructionSitesPageParameters = props.constructionSiteSelection!;
    
        let contractId: string | null = ownerId ?? constructionSiteParams?.contractId ?? props.contractId;
    
        if (contractId) {
            await PageRouteProvider.redirectAsync(PageDefinitions.inviteUser.route({
                params: {
                    contractId: contractId,
                    constructionSiteId: constructionSiteId,
                },
            }));
        }
    }
    
    return (
        <div className={styles.constructionSiteInfoBox} key={"siteInfoBox" + props.index}>
            
            {/*top row*/}
            <div>
                <div className={"row"}>
                    <div className={`${styles.titleContainer} col-md-10`}>
                        <h3 onClick={async () => await openDetailsPageAsync(props.constructionSiteModel.id)}>
                            {props.constructionSiteModel.displayName}
                        </h3>
                    </div>
                    <div className={`${styles.checkBoxContainer} col-md-2`}>
                        <Checkbox className={"pull-right"}
                                  inline
                                  value={props.constructionSiteModel.hiddenFromUser}
                                  inlineType={InlineType.Right}
                                  label={Localizer.contractDetailsHide}
                                  onChange={async (sender, checked) => 
                                      await props.changeSiteVisibilityAsync(props.constructionSiteModel, checked)}
                        />
                    </div>

                </div>
            </div>
            
            {/*content row*/}
            <div>
                <div className={styles.infosContainer}>
                    <div className={`${styles.infoContainer} col-md-4`}>
                        <div>{props.constructionSiteModel.products} {Localizer.contractDetailsModelProducts}</div>
                        <div className={"light"}>{renderDailyPrice(props.constructionSiteModel)}</div>
                        <div className={"light"}>{renderMonthlyPrice(props.constructionSiteModel)}</div>
                    </div>
                    <div className={`${styles.infoContainer} col-md-4`}>
                        <div>{Localizer.contractDetailsModelCreated}</div>
                        <div className={"light"}>
                            {Utility.formatValue(Utility.restoreDate(props.constructionSiteModel.opened), "D")}
                        </div>
                        <div>{renderLocationName(props.constructionSiteModel)}</div>
                    </div>
                </div>
            </div>
            
            {/*button row*/}
            <div className={styles.actionButtonContainer}>
                <Button type={ButtonType.Orange}
                        minWidth={200}
                        label={Localizer.contractDetailsModelCheckInventory}
                        onClick={async () => await openDetailsPageAsync(props.constructionSiteModel.id)}
                />
                <Button type={ButtonType.Orange}
                        minWidth={200}
                        label={Localizer.genericSendSummary}
                        onClick={async () => await props.sendSummaryAsync(props.constructionSiteModel.id)}

                />
                <Button type={ButtonType.Orange}
                        minWidth={200}
                        label={Localizer.contractDetailsModelInviteUser}
                        onClick={async () => await openInviteUserPageAsync(props.constructionSiteModel.id, props.constructionSiteModel.ownerId)}
                />
                
                <FeatureSwitch flagName={RentaEasyConstants.featureFlagProjectPrices}>
                    {(props.userContext.isAdmin) && (
                        <Button label={Localizer.constructionSiteDetailsViewPrices}
                                type={ButtonType.Orange}
                                onClick={async () => await onShowConstructionSitePrices(props.constructionSiteModel.id)}
                        />
                    )}
                </FeatureSwitch>

                <FeatureSwitch flagName={RentaEasyConstants.featureFlagProjectPrices}>
                    {(props.userContext.isCompanyMainUser) && (
                        <Button label={Localizer.constructionSiteDetailsViewPrices}
                                type={ButtonType.Orange}
                                onClick={async () => await onShowConstructionSitePrices(props.constructionSiteModel.id)}
                        />
                    )}
                </FeatureSwitch>
            </div>
        </div>
    );
}

export default ConstructionSitesListItem;