// Change the string to capital case. First letter of every word is uppercase and other letters lowercase.
// The words that are transformed contain only letters.
// The regular expression matches any kind of letter from any language. This includes accented characters and other non-ASCII letters.
export const capitalizeWords = (str: string | null): string => {
    if (!str || !str.trim()) {
        return '';
    }

    return str
        .split(/\s+/)
        .map(word => {
            if (word.length === 2) {
                return word;
            }
            if (/^\p{L}+$/u.test(word)) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word;
        })
        .join(' ');
};