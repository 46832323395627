import {BasePageDefinitions, BasePageParameters, PageRoute} from "@renta-apps/athenaeum-react-common";
import ForgotPassword, {IForgotPasswordParams} from "@/pages/ForgotPassword/ForgotPassword";
import ChangePassword, {IChangePasswordParams} from "@/pages/ChangePassword/ChangePassword";
import Frontpage, {IFrontpageParams} from "@/pages/Frontpage/Frontpage";
import MyAccount, {IMyAccountParams} from "@/pages/MyAccount/MyAccount";
import Login, {ILoginParams} from "@/pages/Login/Login";
import Depots, {IDepotsParams} from "@/pages/Depots/Depots";
import Register, {IRegisterParams} from "@/pages/Register/Register";
import Admin, {IAdminParams} from "@/pages/Admin/Admin";
import AdminSiteManagement, {IAdminSiteManagementParams} from "@/pages/AdminSiteManagement/AdminSiteManagement";
import ShoppingCart, {IShoppingCartParams} from "@/pages/ShoppingCart/ShoppingCart";
import ContactSupport, {IContactSupportParams} from "@/pages/ContactSupport/ContactSupport";
import PrivateOrders, {IPrivateOrdersParams} from "@/pages/PrivateOrders/PrivateOrders";
import AdminUserManagement, {IAdminUserManagementParams} from "@/pages/AdminUserManagement/AdminUserManagement";
import AdminCompaniesOverview, {IAdminCompaniesOverviewParams} from "@/pages/AdminCompaniesOverview/AdminCompaniesOverview";
import AdminConstructionSitesOverview, {IAdminConstructionSitesOverviewParams} from "@/pages/AdminConstructionSitesOverview/AdminConstructionSitesOverview";
import AdminDeviceLocations, {IAdminDeviceLocationsParams} from "@/pages/AdminDeviceLocations/AdminDeviceLocations";
import AdminProducts, {IAdminProductsParams} from "@/pages/AdminProducts/AdminProducts";
import ConstructionSites, {IConstructionSitesParams} from "@/pages/ConstructionSites/ConstructionSites";
import ConstructionSiteDetails, {IConstructionSiteDetailsParams} from "@/pages/ConstructionSiteDetails/ConstructionSiteDetails";
import ProductDetails, {IProductDetailsParams} from "@/pages/ProductDetails/ProductDetails";
import ContractDetails, {IContractDetailsParams} from "@/pages/ContractDetails/ContractDetails";
import InviteUser, {IInviteUserParams} from "@/pages/InviteUser/InviteUser";
import AdminReturnDetails, {IAdminReturnDetailsParams} from "@/pages/AdminReturnDetails/AdminReturnDetails";
import OrderDetails, {IOrderParams} from "@/pages/OrderDetails/OrderDetails";
import ProductLocation, {IProductLocationParams} from "@/pages/ProductLocation/ProductLocation";
import Rent, {IRentParams} from "@/pages/Rent/Rent";
import AdminLocations, {IAdminLocationsParams} from "@/pages/AdminLocations/AdminLocations";
import Orders, {IOrdersParams} from "@/pages/Orders/Orders";
import AdminOrders, {IAdminOrdersReturnsParams} from "@/pages/AdminOrders/AdminOrders";
import Services, {IServicesParams} from "@/pages/Services/Services";
import ApplyCompanyAccount, {IApplyCompanyAccountParams} from "@/pages/ApplyCompanyAccount/ApplyCompanyAccount";
import AdminNewsEditor, {IAdminNewsEditorParams} from "@/pages/AdminNewsEditor/AdminNewsEditor";
import {Dictionary} from "typescript-collections";
import SelectCompany, {ISelectCompanyParams} from "@/pages/SelectCompany/SelectCompany";
import Equipment, {IEquipmentParams} from "@/pages/Equipment/Equipment";
import AdminSchedules, {IAdminSchedulesParams} from "@/pages/AdminSchedules/AdminSchedules";
import Localizer from "@/localization/Localizer";
import {assert} from "@renta-apps/athenaeum-toolkit";
import AdminReturns from "@/pages/AdminReturns/AdminReturns";
import FillBasicInfo, {IFillBasicInfoParams} from "@/pages/FillBasicInfo/FillBasicInfo";
import AdminConstructionSitesMap, {IAdminConstructionSitesMapParams} from "@/pages/AdminConstructionSitesMap/AdminConstructionSitesMap";
import Plans, {IPlansParams} from "@/pages/Plans/Plans";
import SalesTools, {ISalesToolsParams} from "@/pages/SalesTools/SalesTools";
import AdminAddSupportRequestTypes, {IAdminAddSupportRequestTypesParams} from "@/pages/AdminAddSupportRequestTypes/AdminAddSupportRequestTypes";
import Welcome, {IWelcomeParams} from "@/pages/Welcome/Welcome";
import EquipmentDocuments, {IEquipmentDocumentsParams} from "@/pages/EquipmentDocuments/EquipmentDocuments";
import NotFound from "@/pages/NotFound/NotFound";
import PricingTool, {IPricingToolParams} from "@/pages/PricingTool/PricingTool";
import PricingToolTemplates, {IPricingToolTemplatesParams} from "@/pages/PricingToolTemplates/PricingToolTemplates";
import PricingToolTemplate, {IPricingToolTemplateParams} from "@/pages/PricingToolTemplate/PricingToolTemplate";
import PricingToolDiscounts, {IPricingToolDiscountsParams} from "@/pages/PricingToolDiscounts/PricingToolDiscounts";
import PricingToolDiscount, {IPricingToolDiscountParams} from "@/pages/PricingToolDiscount/PricingToolDiscount";
import PricingToolCatalog, {IPricingToolCatalogParams} from "@/pages/PricingToolCatalog/PricingToolCatalog";
import GlobalSearch, {IGlobalSearchParams} from "@/pages/GlobalSearch/GlobalSearch";
import AdminEasyPlusManagement from "@/pages/AdminEasyPlusManagement/AdminEasyPlusManagement";
import RentaEasyController from "@/pages/RentaEasyController";
import InfoScreen from "@/pages/InfoScreen/InfoScreen";
import AdminAlarms, {IAdminAlarmsParams} from "@/pages/AdminAlarms/AdminAlarms";
import TokenLogin from "@/pages/TokenLogin/TokenLogin";
import FleetMonitoring, { IFleetMonitoringParams } from "../pages/FleetMonitoring/FleetMonitoring";
import DeviceDetailsPage, {IDeviceDetailsPageParams} from "@/pages/DeviceDetailsPage/DeviceDetailsPage";
import EasyPlusPage, { IEasyPlusPageParams } from "@/pages/EasyPlusPage/EasyPlusPage";


/**
 * Parameters used in creating a {@link PageRoute}.
 */
export interface IPageRouteParams<TParams extends BasePageParameters> {
    index?: number;
    id?: string;
    params?: TParams | null;
    urlParams?: string[];
}

/**
 * Class for binding together all routing-related data of a page.
 */
export class PageRouteCollection<TParams extends BasePageParameters> {

    /**
     * Name of the page.
     */
    public readonly pageName: string;

    /**
     * URL's which are resolved to the page.
     */
    public readonly aliasUrls: readonly string[];

    /**
     * @param pageName Name of the page.
     * @param customAliasUrls Custom URL's which are resolved to the page. Mainly useful for adding legacy URL's to preserve backwards compatibility.
     *                        Should not contain the name of the page or any localized routes (PageRoutes.X), as they are automatically included.
     */
    public constructor(pageName: string, customAliasUrls?: string[]) {

        const aliasUrls: string[] = [
            pageName,
            ...(customAliasUrls ?? [])
        ];

        const localizerKey: string = "PageRoutes." + pageName;

        Localizer.supportedLanguageCodes
            .forEach(
                languageCode => {
                    const localizedRoute: string = Localizer.getValue(languageCode, localizerKey);

                    if (assert(localizedRoute).isString.isNotEmpty.isNotWhitespace.getIsSuccess && (localizedRoute !== localizerKey)) {
                        aliasUrls.push(localizedRoute);
                    }
                });

        this.pageName = pageName;

        this.aliasUrls = [
            ...aliasUrls,
            ...aliasUrls.map(aliasUrl => aliasUrl.toUpperCase()),
            ...aliasUrls.map(aliasUrl => aliasUrl.toLowerCase())
        ];
    }

    /**
     * Route to the page.
     */
    public route(routeParams?: IPageRouteParams<TParams>): PageRoute {
        return new PageRoute(this.pageName, routeParams?.index, routeParams?.id, routeParams?.params, routeParams?.urlParams);
    }
}

export default class PageDefinitions extends BasePageDefinitions {

    // Fields

    public static readonly admin: PageRouteCollection<IAdminParams> = new PageRouteCollection(
        nameof(Admin),
    );

    public static readonly adminCompanyManagement: PageRouteCollection<IAdminCompaniesOverviewParams> = new PageRouteCollection(
        nameof(AdminCompaniesOverview),
        ["Admin/CompaniesOverview"]
    );

    public static readonly adminConstructionSiteManagement: PageRouteCollection<IAdminConstructionSitesOverviewParams> = new PageRouteCollection(
        nameof(AdminConstructionSitesOverview),
        ["Admin/ConstructionsitesOverview"]
    );

    public static readonly adminDeviceTracking: PageRouteCollection<IAdminDeviceLocationsParams> = new PageRouteCollection(
        nameof(AdminDeviceLocations),
        ["Admin/DeviceTracking"]
    );

    public static readonly adminConstructionSitesMap: PageRouteCollection<IAdminConstructionSitesMapParams> = new PageRouteCollection(
        nameof(AdminConstructionSitesMap),
        ["Admin/ConstructionSitesMap"]
    );

    public static readonly adminLocations: PageRouteCollection<IAdminLocationsParams> = new PageRouteCollection(
        nameof(AdminLocations),
        ["Admin/AdminLocations"]
    );

    public static readonly adminNewsEditor: PageRouteCollection<IAdminNewsEditorParams> = new PageRouteCollection(
        nameof(AdminNewsEditor),
        ["Admin/NewsEditor"]
    );

    public static readonly adminOrders: PageRouteCollection<IAdminOrdersReturnsParams> = new PageRouteCollection(
        nameof(AdminOrders),
        ["Admin/Orders"]
    );

    public static readonly adminEasyPlusManagement: PageRouteCollection<IAdminProductsParams> = new PageRouteCollection(
        nameof(AdminEasyPlusManagement),
        ["Admin/EasyPlusManagement"]
    );

    public static readonly adminProductManagement: PageRouteCollection<IAdminProductsParams> = new PageRouteCollection(
        nameof(AdminProducts),
        ["Admin/CategoryManagement"]
    );

    public static readonly adminReturnDetails: PageRouteCollection<IAdminReturnDetailsParams> = new PageRouteCollection(
        nameof(AdminReturnDetails),
    );

    public static readonly adminReturns: PageRouteCollection<IAdminOrdersReturnsParams> = new PageRouteCollection(
        nameof(AdminReturns),
    );

    /** Page for admins to see existing schedules. */
    public static readonly adminSchedules: PageRouteCollection<IAdminSchedulesParams> = new PageRouteCollection(
        nameof(AdminSchedules),
    );

    /** An unlisted ("secret") super-admin site/password management page. */
    public static readonly adminSiteManagement: PageRouteCollection<IAdminSiteManagementParams> = new PageRouteCollection(
        nameof(AdminSiteManagement),
        ["Admin/ChangePassword"]
    );

    public static readonly adminUserManagement: PageRouteCollection<IAdminUserManagementParams> = new PageRouteCollection(
        nameof(AdminUserManagement),
        ["Admin/UserManagement"]
    );

    /** Page for admins to to see all alarms configured in easy. */
    public static readonly adminAlarms: PageRouteCollection<IAdminAlarmsParams> = new PageRouteCollection(
        nameof(AdminAlarms),
    );

    public static readonly applyCompanyAccount: PageRouteCollection<IApplyCompanyAccountParams> = new PageRouteCollection(
        nameof(ApplyCompanyAccount),
    );

    public static readonly changePassword: PageRouteCollection<IChangePasswordParams> = new PageRouteCollection(
        nameof(ChangePassword),
    );

    public static readonly constructionSite: PageRouteCollection<IConstructionSiteDetailsParams> = new PageRouteCollection(
        nameof(ConstructionSiteDetails),
    );

    public static readonly constructionSites: PageRouteCollection<IConstructionSitesParams> = new PageRouteCollection(
        nameof(ConstructionSites),
    );

    public static readonly contactSupport: PageRouteCollection<IContactSupportParams> = new PageRouteCollection(
        nameof(ContactSupport),
        ["contactpage"]
    );

    public static readonly contractDetails: PageRouteCollection<IContractDetailsParams> = new PageRouteCollection(
        nameof(ContractDetails),
        ["CompanyDetails"]
    );

    public static readonly easyPlus: PageRouteCollection<IEasyPlusPageParams> = new PageRouteCollection(
        nameof(EasyPlusPage),
    );

    public static readonly equipment: PageRouteCollection<IEquipmentParams> = new PageRouteCollection(
        nameof(Equipment),
    );

    public static readonly fillBasicInfo: PageRouteCollection<IFillBasicInfoParams> = new PageRouteCollection(
        nameof(FillBasicInfo),
    );

    public static readonly forgotPassword: PageRouteCollection<IForgotPasswordParams> = new PageRouteCollection(
        nameof(ForgotPassword),
    );

    public static readonly fleetMonitoringPage: PageRouteCollection<IFleetMonitoringParams> = new PageRouteCollection(
        nameof(FleetMonitoring),
    );

    public static readonly deviceDetailsPage: PageRouteCollection<IDeviceDetailsPageParams> = new PageRouteCollection(
        nameof(DeviceDetailsPage),
    );

    public static readonly frontPage: PageRouteCollection<IFrontpageParams> = new PageRouteCollection(
        nameof(Frontpage),
    );

    /** Page to invite users to a Contract or to Contracts Construction Sites. */
    public static readonly inviteUser: PageRouteCollection<IInviteUserParams> = new PageRouteCollection(
        nameof(InviteUser),
    );

    /** Page displaying details about Rentas various rental-locations. */
    public static readonly depots: PageRouteCollection<IDepotsParams> = new PageRouteCollection(
        nameof(Depots),
    );

    public static readonly login: PageRouteCollection<ILoginParams> = new PageRouteCollection(
        nameof(Login),
    );

    public static readonly myAccount: PageRouteCollection<IMyAccountParams> = new PageRouteCollection(
        nameof(MyAccount),
    );

    public static readonly notFound: PageRouteCollection<IMyAccountParams> = new PageRouteCollection(
        nameof(NotFound),
        ["404"]
    );

    public static readonly order: PageRouteCollection<IOrderParams> = new PageRouteCollection(
        nameof(OrderDetails),
    );

    public static readonly orders: PageRouteCollection<IOrdersParams> = new PageRouteCollection(
        nameof(Orders),
    );

    public static readonly privateOrders: PageRouteCollection<IPrivateOrdersParams> = new PageRouteCollection(
        nameof(PrivateOrders),
    );

    public static readonly productDetails: PageRouteCollection<IProductDetailsParams> = new PageRouteCollection(
        nameof(ProductDetails),
    );

    /** Page for tracking a single TrackUnit-enabled device. */
    public static readonly productLocation: PageRouteCollection<IProductLocationParams> = new PageRouteCollection(
        nameof(ProductLocation),
    );

    public static readonly register: PageRouteCollection<IRegisterParams> = new PageRouteCollection(
        nameof(Register),
        ["tożsamość/konto/rejestr"]
    );

    /** Page for displaying navigable lists of categories, sub-categories and products. */
    public static readonly rent: PageRouteCollection<IRentParams> = new PageRouteCollection(
        nameof(Rent),
    );

    /** Page where after login a User with multiple roles selects the active one. */
    public static readonly selectCompany: PageRouteCollection<ISelectCompanyParams> = new PageRouteCollection(
        nameof(SelectCompany),
    );

    /** Page for viewing, sending, and for Admins editing Service Requests. */
    public static readonly services: PageRouteCollection<IServicesParams> = new PageRouteCollection(
        nameof(Services),
    );

    public static readonly shoppingCart: PageRouteCollection<IShoppingCartParams> = new PageRouteCollection(
        nameof(ShoppingCart),
    );

    public static readonly plans: PageRouteCollection<IPlansParams> = new PageRouteCollection(
        nameof(Plans),
    );

    public static readonly pricingToolTemplates: PageRouteCollection<IPricingToolTemplatesParams> = new PageRouteCollection(
        nameof(PricingToolTemplates),
    );

    public static readonly pricingToolTemplate: PageRouteCollection<IPricingToolTemplateParams> = new PageRouteCollection(
        nameof(PricingToolTemplate),
    );

    public static readonly pricingToolDiscounts: PageRouteCollection<IPricingToolDiscountsParams> = new PageRouteCollection(
        nameof(PricingToolDiscounts),
    );

    public static readonly pricingToolDiscount: PageRouteCollection<IPricingToolDiscountParams> = new PageRouteCollection(
        nameof(PricingToolDiscount),
    );

    public static readonly pricingToolCatalog: PageRouteCollection<IPricingToolCatalogParams> = new PageRouteCollection(
        nameof(PricingToolCatalog),
    );

    public static readonly pricingTool: PageRouteCollection<IPricingToolParams> = new PageRouteCollection(
        nameof(PricingTool),
    );

    public static readonly salesTools: PageRouteCollection<ISalesToolsParams> = new PageRouteCollection(
        nameof(SalesTools)
    );

    public static readonly adminAddSupportRequestTypes: PageRouteCollection<IAdminAddSupportRequestTypesParams> = new PageRouteCollection(
        nameof(AdminAddSupportRequestTypes),
    );

    public static readonly welcome: PageRouteCollection<IWelcomeParams> = new PageRouteCollection(
        nameof(Welcome),
    );

    /** Page for rented device documents */
    public static readonly equipmentDocuments: PageRouteCollection<IEquipmentDocumentsParams> = new PageRouteCollection(
        nameof(EquipmentDocuments),
    );

    public static readonly globalSearch: PageRouteCollection<IGlobalSearchParams> = new PageRouteCollection(
        nameof(GlobalSearch),
    );

    public static readonly infoScreen: PageRouteCollection<IGlobalSearchParams> = new PageRouteCollection(
        nameof(InfoScreen),
    );

    public static readonly tokenLogin: PageRouteCollection<IGlobalSearchParams> = new PageRouteCollection(
        nameof(TokenLogin),
    );

    private readonly routes: Dictionary<string, PageRoute>;

    // Constructor

    constructor() {
        super();

        this.routes = new Dictionary<string, PageRoute>();

        const setValues = <TParams extends BasePageParameters>(pageData: PageRouteCollection<TParams>) =>
            pageData
                .aliasUrls
                .map(
                    aliasUrl =>
                        this.routes.setValue(aliasUrl, pageData.route()));

        // Admin

        setValues(PageDefinitions.admin);
        setValues(PageDefinitions.adminCompanyManagement);
        setValues(PageDefinitions.adminConstructionSiteManagement);
        setValues(PageDefinitions.adminDeviceTracking);
        setValues(PageDefinitions.adminConstructionSitesMap);

        setValues(PageDefinitions.adminLocations);
        setValues(PageDefinitions.adminNewsEditor);
        setValues(PageDefinitions.adminOrders);
        setValues(PageDefinitions.adminProductManagement);
        setValues(PageDefinitions.adminEasyPlusManagement);
        setValues(PageDefinitions.adminReturnDetails);
        setValues(PageDefinitions.adminReturns);
        setValues(PageDefinitions.adminSchedules);
        setValues(PageDefinitions.adminSiteManagement);
        setValues(PageDefinitions.adminUserManagement);
        setValues(PageDefinitions.adminAlarms);

        // Other

        setValues(PageDefinitions.applyCompanyAccount);
        setValues(PageDefinitions.changePassword);
        setValues(PageDefinitions.constructionSite);
        setValues(PageDefinitions.constructionSites);
        setValues(PageDefinitions.contactSupport);
        setValues(PageDefinitions.contractDetails);
        setValues(PageDefinitions.equipment);
        setValues(PageDefinitions.fillBasicInfo);
        setValues(PageDefinitions.fleetMonitoringPage);
        setValues(PageDefinitions.deviceDetailsPage);
        setValues(PageDefinitions.forgotPassword);
        setValues(PageDefinitions.frontPage);
        // Home is missing? (Might be on purpose though, as it is only used in mobile apps)
        setValues(PageDefinitions.inviteUser);
        setValues(PageDefinitions.depots);
        setValues(PageDefinitions.login);
        setValues(PageDefinitions.myAccount);
        setValues(PageDefinitions.notFound);
        setValues(PageDefinitions.order);
        setValues(PageDefinitions.orders);
        setValues(PageDefinitions.privateOrders);
        setValues(PageDefinitions.productDetails);
        setValues(PageDefinitions.productLocation);
        setValues(PageDefinitions.register);
        setValues(PageDefinitions.rent);
        setValues(PageDefinitions.selectCompany);
        setValues(PageDefinitions.services);
        setValues(PageDefinitions.shoppingCart);
        setValues(PageDefinitions.plans);
        setValues(PageDefinitions.pricingToolDiscounts);
        setValues(PageDefinitions.pricingToolDiscount);
        setValues(PageDefinitions.pricingToolTemplates);
        setValues(PageDefinitions.pricingToolTemplate);
        setValues(PageDefinitions.pricingToolCatalog);
        setValues(PageDefinitions.pricingTool);
        setValues(PageDefinitions.salesTools);
        setValues(PageDefinitions.adminAddSupportRequestTypes);
        setValues(PageDefinitions.welcome);
        setValues(PageDefinitions.equipmentDocuments);
        setValues(PageDefinitions.globalSearch);
        setValues(PageDefinitions.infoScreen);
        setValues(PageDefinitions.tokenLogin);

        Object.freeze(this.routes);
        Object.freeze(this);
    }

    // Methods

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }

    public getRoutes(): Dictionary<string, PageRoute> {
        return this.routes;
    }

    public canRedirect?(route: PageRoute): boolean | null {
        return RentaEasyController.isRegistrationCompleted(route);
    }
}

new PageDefinitions();