import Localizer from "../../../localization/Localizer";

function mockOffline() {
    return {
        onBeforeLoad(win: Window) {
            cy.stub(win.navigator, 'onLine').value(false);
        },
    };
}

describe('Desktop - Anonymous offline', () => {

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
    });

    it('Redirects to Offline page when offline', () => {

        cy.visit('/', mockOffline());

        cy.url().should('include', 'Offline');

        cy.get('h1').should('contain', Localizer.offlinePageTitle);

        cy.get('div').should('contain', Localizer.offlinePageMessage);

    });
});
