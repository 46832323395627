import React from "react";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import Localizer from "@/localization/Localizer";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";
import {RetrievalAddressModel} from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal";
import {Input} from "@renta-apps/renta-react-components";

type RetrievalAddressProps = {
    loading: boolean;
    address: RetrievalAddressModel | null;
    onChange: (key: keyof RetrievalAddressModel, value: string) => void;
    onFocus?: () => void;
}

const RetrievalAddress: React.FC<RetrievalAddressProps> = ({address, onChange, onFocus, loading}) => {
    return (
        <ContentBox title={Localizer.returnRequestModalAddressHeader} subTitle={Localizer.returnRequestModalAddressSubheader}>
            <div className={styles.addressContainer} data-cy="return-modal-retrieval-address">
                <Input type="text"
                       label={Localizer.returnRequestModalStreetAddress}
                       loading={loading}
                       value={address?.streetAddress ?? ''}
                       className={`${styles.address} ${styles.inputField}`}
                       onChange={(value: string) => onChange('streetAddress', value)}
                       onFocus={onFocus}
                       inputProperties={{
                           required: true,
                           name: "streetAddress",
                           placeholder: loading ? Localizer.returnRequestModalStreetAddressPlaceholder : undefined,
                       }}
                />
                <Input type="text"
                       label={Localizer.genericPostalCode}
                       loading={loading}
                       value={address?.postalCode ?? ''}
                       className={`${styles.postalCode} ${styles.inputField}`}
                       onChange={(value: string) => onChange('postalCode', value)}
                       onFocus={onFocus}
                       inputProperties={{
                           required: true,
                           name: "postalCode",
                       }}
                />
                <Input type="text"
                       label={Localizer.genericCity}
                       loading={loading}
                       value={address?.city ?? ''}
                       className={`${styles.city} ${styles.inputField}`}
                       onChange={(value: string) => onChange('city', value)}
                       onFocus={onFocus}
                       inputProperties={{
                           required: true,
                           name: "city",
                       }}
                />
            </div>
        </ContentBox>
    );
}

export default RetrievalAddress;