import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import styles from "./FluidLevel.module.scss";
import Localizer from "@/localization/Localizer";

interface IFluidLevelProps {
    fluidLevel: number;
}

const FluidLevel: React.FC<IFluidLevelProps> = ({fluidLevel}): React.ReactElement => {

    const fluidLevelClass: any = (fluidLevel === 0)
        ? styles.fluidLevel0
        : (fluidLevel > 0 && fluidLevel <= 20)
            ? styles.fluidLevel20
            : (fluidLevel > 20 && fluidLevel <= 40)
                ? styles.fluidLevel40
                : (fluidLevel > 40 && fluidLevel <= 60)
                    ? styles.fluidLevel60
                    : (fluidLevel > 60 && fluidLevel <= 80)
                        ? styles.fluidLevel80
                        : styles.fluidLevel100;

    return (

        <div className={styles.container}>
            {
                (
                    <div className="flex flex-direction-column align-items-center">

                        <div className={styles.levelText}>
                            {Localizer.productLocationFluidLevel.toUpperCase()}
                        </div>

                        <div className={styles.levelValue}>
                            {fluidLevel + " %"}
                        </div>

                        <div className={Utility.css(styles.fluidLevel, fluidLevelClass)}>
                        </div>

                    </div>
                )
            }
        </div>
    );
};

export default FluidLevel;