
export default class ConstructionSitesListToolbarModel {
    
    public contractId: string | null = null;

    public keyword: string = "";

    public constructionSiteOwnerId: string | null = null;

    public showAllSites: boolean = true;

    public showHiddenSites: boolean = false;
}