import {ChartData} from "@/components/Charts/Models/IDoughnutData";
import {Utility} from "@renta-apps/athenaeum-toolkit";

export const checkCategoryNameConsistency = (data: ChartData[]): boolean => {
    if (data.length === 0) {
        return false; // No items in the array
    }
    const firstCategoryName = data[0].name;

    if (!firstCategoryName) {
        return false; // No productData in the first item
    }

    for (const chartItem of data) {
        if (chartItem.name !== firstCategoryName) {
            return false; // productGroupNumber inconsistency found
        }
    }

    return true; // All productGroupNumbers are consistent
};

const mapToProductData = (data: ChartData): ChartData[] => {
    return data.emissionData.groupBy(item => item.rentaId).map(item => {
        const emissionData: ChartData = {
            id: Utility.getId().toString(),
            value: item.sum(a => a.emissionValues.sum(item => item.value)),
            name: item[0].rentaName,
            productCount: item.distinct(a => a.rentaId).length,
            children: [],
            parentName: data.name,
            categoryName: data.categoryName,
            emissionData: item,
            isTopLevel: false,
        };
        return emissionData;
    });
};

export const getNextData = (data: ChartData): ChartData[] => {
    if (!checkCategoryNameConsistency(data.children) && data.children && data.children.length > 0) {
        return data.children;
    }

    return mapToProductData(data);
};