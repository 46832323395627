import React from "react";
import {BaseComponent, ch, TextAlign, VerticalAlign} from "@renta-apps/athenaeum-react-common";
import {ActionType} from "@renta-apps/athenaeum-react-common";
import UserRoleModel from "@/models/server/UserRoleModel";
import {IPagedList, SortDirection, TimeSpan, Utility} from "@renta-apps/athenaeum-toolkit";
import {CellAction, CellModel, ColumnDefinition, Grid} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import ConstructionSiteUserModel from "@/models/server/ConstructionSiteUserModel";
import InvitationsGridToolbar from "./Toolbar/InvitationsGridToolbar";
import InvitationsGridToolbarModel from "./Toolbar/InvitationsGridToolbarModel";
import PendingInvitationOverviewModel from "@/models/server/PendingInvitationOverviewModel";
import TransformProvider from "@/providers/TransformProvider";

import UserContext from "@/models/server/UserContext";

interface IInvitationsGridProps {
    users?: UserRoleModel[],

    getPendingInvitationsAsync(filters: InvitationsGridToolbarModel, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<PendingInvitationOverviewModel>>

    onDeletePendingInvitationAsync(dataItem: PendingInvitationOverviewModel): Promise<void>

    onResendUserInvitationAsync(invitationId: string): Promise<void>
}

interface IInvitationsGridState {
    users: IPagedList<UserRoleModel> | null;
    filters: InvitationsGridToolbarModel | null;
}

export default class InvitationsGrid extends BaseComponent<IInvitationsGridProps, IInvitationsGridState> {

    public state: IInvitationsGridState = {
        users: null,
        filters: new InvitationsGridToolbarModel()
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private readonly _pendingInvitationsGrid: React.RefObject<Grid<PendingInvitationOverviewModel>> = React.createRef();

    private readonly _pendingInvitationsColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericEmailLanguageItemName,
            accessor: "email",
            editable: false,
            sorting: true,
            minWidth: 90,
        },
        {
            minWidth: 300,
            header: Localizer.genericRoleLanguageItemName,
            accessor: "contractRole.roleName",
            transform: (cell: CellModel<PendingInvitationOverviewModel>) => InvitationsGrid.renderContractDetails(cell.model),
            settings: {
                infoAccessor: "contractRole.roleName",
                infoTransform: (cell: CellModel<PendingInvitationOverviewModel>) => Localizer.get(cell.value),
            },
            editable: false,
            sorting: true,
        },
        {
            header: Localizer.adminUserManagementInviterLanguageItemName,
            accessor: "inviterEmail",
            editable: false,
            sorting: true,
            minWidth: 150,
        },
        {
            header: Localizer.invitationsGridCreatedAtLanguageItemName,
            accessor: "createdAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
            verticalAlign: VerticalAlign.Middle
        },
        {
            header: Localizer.invitationsGridExpiresLanguageItemName,
            accessor: "validTill",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
            init: (cell) => this.initValidTill(cell)
        },
        {
            header: Localizer.invitationsGridProcessedAtLanguageItemName,
            accessor: "processedAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90
        },
        {
            minWidth: 150,
            visible: true,
            init: (cell) => this.initActions(cell),
            actions: [

                {
                    name: "send",
                    title: Localizer.adminUserManagementResendInvitationLanguageItemName,
                    icon: {name: "far mail-bulk", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Blue,
                    confirm: (cell: CellModel<ConstructionSiteUserModel>) => Localizer.get(Localizer.constructionSiteDetailsUsersTableConfirmInvitationResend, cell.model.email),
                    callback: async (cell) => await this.resendUserInvitationAsync(cell)
                },
                {
                    name: "delete",
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    confirm: (cell: CellModel<ConstructionSiteUserModel>) => Localizer.get(Localizer.constructionSiteDetailsUsersTableConfirmInvitationDeletion, cell.model.email),
                    callback: async (cell, action) => await this.deletePendingUserInvitationAsync(cell, action)
                },
                {
                    name: "link",
                    title: Localizer.productDetailsShareLink,
                    icon: {name: "far link", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Blue,
                    callback: async (cell) => await this.copyInviteLink(cell)
                },
            ]
        },

    ];

    private initValidTill(cell: CellModel<PendingInvitationOverviewModel>): void {
        const model: PendingInvitationOverviewModel = cell.row.model;
        const diff: TimeSpan = Utility.diff(model.validTill, Utility.utcNow());
        const expired: boolean = (diff.totalMilliseconds < 0);
        if (expired) {
            cell.className = "danger";
        }
    }

    private static renderContractDetails(model: PendingInvitationOverviewModel): string {
        if (model) {
            if (model.contractRole) {
                return TransformProvider.organizationContractToString(model.contractRole.contract!);
            }

            if (model.constructionSiteRoles && model.constructionSiteRoles.length > 0) {
                return model.constructionSiteRoles.map(siteRole => TransformProvider.constructionSiteRoleToString(siteRole)).join(", ");
            }
        }
        return "";
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private initActions(cell: CellModel<PendingInvitationOverviewModel>) {
        const model: PendingInvitationOverviewModel = cell.row.model;

        const linkAction: CellAction<PendingInvitationOverviewModel> = cell.actions[2];

        linkAction.visible = (model.link !== null) && this.userContext.isAdminWithAdminRole;
    }

    private async deletePendingUserInvitationAsync(cell: CellModel<PendingInvitationOverviewModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "delete") {

            await this.onDeletePendingInvitationAsync(cell.model);

            await cell.grid.deleteAsync(cell.row.index);
        }
    }

    private async onDeletePendingInvitationAsync(dataItem: PendingInvitationOverviewModel): Promise<void> {
        await this.props.onDeletePendingInvitationAsync(dataItem);

        await this.initializeAsync();
    }

    private get filters(): InvitationsGridToolbarModel {
        return this.state.filters!;
    }

    private async copyInviteLink(cell: CellModel<PendingInvitationOverviewModel>): Promise<void> {
        if (cell.model.link) {
            await navigator.clipboard.writeText(cell.model.link);
            await ch.alertMessageAsync(Localizer.productDetailsShareLinkUrlCopied, true, true);
        }
    }

    private async resendUserInvitationAsync(cell: CellModel<PendingInvitationOverviewModel>): Promise<void> {
        await this.props.onResendUserInvitationAsync(cell.model.invitationId);

        cell.model.createdAt = new Date();

        await this.reloadAsync();
    }

    private async getPendingInvitationsAsync(pageNumber: number,
                                             pageSize: number,
                                             sortColumnName: string | null,
                                             sortDirection: SortDirection | null
    ): Promise<IPagedList<PendingInvitationOverviewModel>> {

        return await this.props.getPendingInvitationsAsync(this.filters, pageNumber, pageSize, sortColumnName, sortDirection);
    }

    public async reloadAsync(): Promise<void> {
        await this._pendingInvitationsGrid.current!.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <div>

                <InvitationsGridToolbar model={this.filters}
                                        onChange={async () => await this.reloadAsync()}
                />

                <Grid responsive
                      version2Styles
                      pagination={10}
                      ref={this._pendingInvitationsGrid}
                      columns={this._pendingInvitationsColumns}
                      noDataText={Localizer.componentDropdownNoDataLanguageItemName}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) => await this.getPendingInvitationsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                />
            </div>

        );
    }
}