import React from "react";
import {Button, ButtonType, CellModel, Checkbox, ColumnDefinition, Dropdown, Form, Grid, Link, PageContainer, PageHeader, PageRow, SelectListItem, TextInput, ThreeColumns} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import ProductModel from "@/models/server/ProductModel";
import EnumProvider from "@/providers/EnumProvider";
import PageDefinitions from "@/providers/PageDefinitions";
import {BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";

import styles from "./AdminProducts.module.scss";
import AdminPage from "@/models/base/AdminPage";
import ListAllProductsRequest from "@/models/server/Requests/ListAllProductsRequest";

export interface IAdminProductsParams extends BasePageParameters {
}

interface IAdminProductsState {
    keyword: string | null;
    productsWithoutImages: boolean;
    productRentType: number | null;
    allProducts: ProductModel[];
}

export default class AdminProducts extends AdminPage<IAdminProductsParams, IAdminProductsState> {

    public state: IAdminProductsState = {
        keyword: null,
        productsWithoutImages: false,
        productRentType: null,
        allProducts: []
    };

    private readonly _productsGrid: React.RefObject<Grid<ProductModel>> = React.createRef();

    private readonly _productsColumns: ColumnDefinition[] = [
        {
            header: Localizer.catalogEditProductProductNameLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.name)),
            editable: false,
            sorting: true,
            className: styles.linkCellStyle,
            actions: [
                {
                    render: (cell: CellModel<ProductModel>) => this.renderProductLink(cell.model),
                }
            ],
            minWidth: 90
        },
        {
            header: Localizer.enumEquipmentSortByTypeCategoryLanguageItemName,
            accessor: "category.name",
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.catalogEditProductExternalIdLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.externalId)),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: "Id",
            accessor: nameof<ProductModel>(d => (d.rentalObjectId)),
            editable: false,
            sorting: true,
            visible: !ch.isFinland,
            minWidth: 90,
        },
        {
            header: Localizer.catalogEditProductDailyPriceLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.dailyPrice)),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.catalogEditProductWeeklyPriceLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.weeklyPrice)),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.adminProductsGridHiddenLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.hidden)),
            editable: false,
            transform: (cell: CellModel<ProductModel>) => cell.model.hidden ? "✓" : "",
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.adminProductsGridHasImageLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.imageReference)),
            editable: false,
            transform: (cell: CellModel<ProductModel>) => cell.model.imageReference?.id !== null ? "✓" : "",
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.catalogEditProductSalesItemLanguageItemName,
            accessor: nameof<ProductModel>(d => (d.isSalesItem)),
            editable: false,
            transform: (cell: CellModel<ProductModel>) => cell.model.isSalesItem ? "✓" : "",
            sorting: true,
            minWidth: 90
        },
    ];

    private renderProductLink(model: ProductModel): React.ReactNode {
        return (
            <Link key={model.id}
                  className={styles.productLink}
                  route={PageDefinitions.productDetails.route({id: model.url!})}
            >
                {model.name}
            </Link>
        );
    }

    private async getProductsAsync(pageNumber: number,
                                   pageSize: number,
                                   sortColumnName: string | null,
                                   sortDirection: SortDirection | null
    ): Promise<IPagedList<ProductModel>> {

        const request: ListAllProductsRequest = {
            keyword: this.state.keyword,
            pageSize: pageSize,
            pageNumber: pageNumber,
            sortColumnName: sortColumnName,
            sortDirection: sortDirection,
            productType: this.state.productRentType,
            productsWithoutImages: this.state.productsWithoutImages

        };

        return await this.postAsync("/api/Admin/GetAllProducts", request);
    }

    private async setProductTypeFilterAsync(item: SelectListItem | null): Promise<void> {
        const productType: number | null = (item)
            ? Number.parseInt(item.value)
            : null;

        this.setState({productRentType: productType});

        await this.reloadGridAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this._productsGrid.current) {
            this._productsGrid.current.model.sortDirection = SortDirection.Asc;
        }
    }

    private async setProductsWithoutImagesFilterAsync(value: boolean): Promise<void> {
        await this.setState({productsWithoutImages: value});
        await this.reloadGridAsync();
    }

    private async searchProductsAsync(): Promise<void> {
        await this.reloadGridAsync();
    }

    private async setKeyWordAsync(value: string): Promise<void> {
        await this.setState({keyword: value});
    }

    private async reloadGridAsync(): Promise<void> {
        await this._productsGrid.current!.reloadAsync();
    }

    protected get title(): string {
        return Localizer.adminProductsGridTitle;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.adminProducts}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />
                <PageRow className={styles.toolbarContainer}>
                    <ThreeColumns>
                        <Form submitOnEnter
                              className={styles.searchForm}
                              onSubmit={async (sender, data) => await this.searchProductsAsync()}>
                            <TextInput inline
                                       id={"admin_products_keyword"}
                                       className={styles.searchBar}
                                       width={"200px"}
                                       title={Localizer.companiesOverviewFindByNameOrCustomerNumber}
                                       value={this.state.keyword!}
                                       onChange={async (sender, value) => await this.setKeyWordAsync(value)}

                            />
                            <Button id={"admin_products_search_button"}
                                    label={Localizer.ordersPageSearch}
                                    type={ButtonType.Orange}
                                    onClick={async (sender, data) => await this.searchProductsAsync()}
                            />
                        </Form>
                        <Checkbox inline
                                  className={styles.productsWithoutImages}
                                  value={this.state.productsWithoutImages}
                                  label={Localizer.adminPageProductsWithoutImages}
                                  onChange={async (sender, value) => await this.setProductsWithoutImagesFilterAsync(value)}
                        />
                        <Dropdown inline
                                  id={"admin_products_type_filter"}
                                  items={EnumProvider.getProductTypeItems()}
                                  label={Localizer.adminProductsGridProductType}
                                  onChange={async (sender, item) => await this.setProductTypeFilterAsync(item)}
                        />
                    </ThreeColumns>

                </PageRow>
                <PageRow>
                    <Grid responsive
                          version2Styles
                          id={"admin_products"}
                          pagination={25}
                          ref={this._productsGrid}
                          columns={this._productsColumns}
                          noDataText={Localizer.componentDropdownNoDataLanguageItemName}
                          fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                              await this.getProductsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                    />
                </PageRow>

            </PageContainer>
        );
    }
}