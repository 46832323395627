import Chainable = Cypress.Chainable;

export default class DropdownHelper {
    public static openAndSelectDropdownItemByIndex(dropdownId: string, row: number): void {
        cy.get(dropdownId).click();
        this.selectDropdownItemByIndex(dropdownId, row);
    }

    public static selectDropdownItemByIndex(dropdownId: string, row: number): void {
        this.getDropdownItemByIndex(dropdownId, row).click();
    }

    public static validateDropdownOptions(dropdownId: string, options: string[]): void {
        cy.get(dropdownId).click();

        for (let option of options) {
            this.getDropdownItemByIndex(dropdownId, options.indexOf(option))
                .find("span")
                .contains(option);
        }
    }

    public static validateNumberOfDropdownOptions(dropdownId: string, expected: number): void {
        cy.get(dropdownId).click();
        cy.get(dropdownId)
            .find(".athenaeum-dropdown-item")
            .should('have.length', expected);
    }

    private static getDropdownItemByIndex(dropdownId: string, row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get(dropdownId)
            .find(".athenaeum-dropdown-item")
            .eq(row);
    }

    /**
     * Define a custom function to get selected value from the renta-react-components dropdown.
     * @param element
     * @returns
     */
    public static getSelectedItem(element: Cypress.Chainable<JQuery<HTMLElement>>): Chainable<JQuery<HTMLElement>> {
        return element
            .find('[data-cy="dropdownTitle"]')
    }

    /**
     * Define a custom function to select an option from the renta-react-components dropdown.
     * @param element
     * @param optionIndex
     */
    public static selectDropdownOption(element: Cypress.Chainable<JQuery<HTMLElement>>, optionIndex: number): void {
        element
            .find('[data-cy="dropdownInput"]')
            .first()
            .click();
        element
            .find('[data-cy="dropdownItem"]')
            .eq(optionIndex)
            .click();
    }

    /**
     * Define a custom function to open or close the renta-react-components dropdown.
     * @param element
     */
    public static toggleDropdown(element: Cypress.Chainable<JQuery<HTMLElement>>): void {
        element
            .find('[data-cy="dropdownInput"]')
            .first()
            .click();
    }

}