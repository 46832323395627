import React, {FC} from "react";
import CategoryModel from "@/models/server/CategoryModel";
import {Icon, IconSize, IconStyle, Link} from "@renta-apps/athenaeum-react-components";
import styles from "./../CategoryItem/CategoryItem.module.scss";

export interface CategoryItemProps {
    item: CategoryModel;
    className?: string;
}

const FavoriteCategoryItem: FC<CategoryItemProps> = (props: CategoryItemProps) => {

    return (
        <Link>
            <div className={styles.item}>
                <div className={`${styles.categoryItem} ${props.className}`}>
                    <div className={styles.categoryImg}>
                        <div className={styles.icon}>
                            <Icon name="far fa-star"
                                  size={IconSize.X3}
                                  style={IconStyle.Solid}
                            />
                        </div>
                    </div>
        
                    <div className={styles.content}>
                            <h2>{props.item.name}</h2>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default FavoriteCategoryItem;