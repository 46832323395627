import React from "react";
import {PlanStatus, PlanType} from "@/models/Enums";
import {ITabContainerClassNames, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType} from "@renta-apps/athenaeum-react-components";
import queryString, {ParsedQuery} from "query-string";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import { BasePageParameters, PageRouteProvider } from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import DeletedPlans from "@/pages/Plans/DeletedPlans/DeletedPlans";
import styles from "./Plans.module.scss";
import Localizer from "@/localization/Localizer";
import PlanModel from "@/models/server/PlanModel";
import PlanCatalog from "@/components/Plan/PlanCatalog/PlanCatalog";
import PlanEditor from "@/components/Plan/PlanEditor/PlanEditor";
import PlanDefinitionInput from "@/models/server/PlanDefinitionInput";
import PlanGrid from "@/components/Plan/PlanGrid/PlanGrid";

export interface IPlansParams extends BasePageParameters {
    /**
     * Id of the currently selected Plan.
     */
    planId?: string;
}

interface IPlansState {

    /**
     * Definition of the plan under inspection/editing.
     */
    selectedPlan: PlanModel | null;

    selectedPlanIsDeleted: boolean;

    /**
     * All existing non-deleted plan definitions.
     */
    existingDefinitions: PlanModel[];

    editMode: boolean;

    /**
     * Input under editing (displayed in right panel).
     */
    currentInput: PlanDefinitionInput | null;
}

export default class Plans extends AuthorizedPage<IPlansParams, IPlansState> {

    public state: IPlansState = {
        selectedPlan: null,
        selectedPlanIsDeleted: false,
        currentInput: null,
        editMode: false,
        existingDefinitions: []
    };

    // Boolean getters

    private get organizationContractId(): string | null {
        const parsed: ParsedQuery = queryString.parse(window.location.search);
        return (typeof parsed?.id === "string")
            ? parsed.id as string
            : null;
    };

    // Getters

    private get selectedPlan(): PlanModel {
        return this.state.selectedPlan!;
    }

    private get currentInput(): PlanDefinitionInput | null {
        return this.state.currentInput;
    }

    private get editMode(): boolean {
        return this.state.editMode;
    }

    public get hasSelectedPlan(): boolean {
        return (!!this.state.selectedPlan);
    }

    protected get title(): string {
        return Localizer.breadCrumbPlans;
    }

    // Async-methods

    private async closeEditorAsync(): Promise<void> {
        await this.setState({
            selectedPlan: null,
            editMode: false,
            currentInput: null
        });

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(PageDefinitions.services.route({
            params: null,
        }));

        await this.fetchDataAsync();
    }

    private async openEditorAsync(selectedPlan: PlanModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedPlan,
            selectedPlanIsDeleted: isDeleted,
            editMode: true
        });
    }

    private async openDefinitionAsync(selectedPlan: PlanModel, isDeleted: boolean): Promise<void> {
        await this.setState({
            selectedPlan,
            selectedPlanIsDeleted: isDeleted,
            editMode: false
        });

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(PageDefinitions.services.route({
            params: {
                serviceRequestId: selectedPlan.id,
            }
        }));
    }

    private async createNewPlanDefinitionAsync(): Promise<void> {
        const newPlanDefinition: PlanModel = {
            id: "",
            planStatus: PlanStatus.Received,
            orderNumber: 0,
            date: new Date(),
            ordererEmail: "",
            receivers: "",
            sendToUsersFavoriteDepot: false,
            name: "",
            planType: PlanType.Custom,
            inputGroups: [],
            icon: "",
            customer: null
        };

        await this.setState({
            selectedPlan: newPlanDefinition,
            editMode: true
        });
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.fetchDataAsync();
    }

    public async fetchDataAsync(): Promise<void> {

        const existingDefinitions: PlanModel[] = await this.postAsync(`/api/Plans/GetPlanDefinitions`, null);

        const planDefinitionId = this.typedParameters?.planId;

        if (planDefinitionId !== undefined) {
            const planDefinition = existingDefinitions.find(existingDefinition => existingDefinition.id === planDefinitionId);
            if (planDefinition !== undefined) {
                await this.openDefinitionAsync(planDefinition, false);
            }
        }

        await this.setState({
            existingDefinitions
        });
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: styles.navTabs,
        };
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer id={styles.plans}
                           className={styles.plans}
            >
                <PageHeader title={this.title}/>

                {
                    (this.hasSelectedPlan) &&
                    (
                        <PlanEditor closeEditorAsync={async () => await this.closeEditorAsync()}
                                    model={this.selectedPlan!}
                                    currentInput={this.currentInput}
                                    editMode={this.editMode}
                                    deleted={this.state.selectedPlanIsDeleted}
                        />
                    )
                }
                {
                    (!this.hasSelectedPlan) &&
                    (
                        <TabContainer id="constructionSiteDetailsContainer"
                                      renderType={TabRenderType.ActiveOnly}
                                      headerStyleType={TabContainerHeaderStyleType.Underline}
                                      className={styles.plans}
                                      classNames={this.tabContainerClassNames}

                        >

                            <Tab id="planCatalog"
                                 title={this.title}
                                 className={styles.navTabs}
                            >
                                <>
                                    <PlanCatalog canEdit={this.isAdminWithAdminRole}
                                                 canCreateNew={this.isAdminWithAdminRole}
                                                 planModels={this.state.existingDefinitions}
                                                 openDefinitionAsync={async (model) => await this.openDefinitionAsync(model, false)}
                                                 openEditorAsync={async (model) => await this.openEditorAsync(model, false)}
                                                 createNewPlanDefinition={async () => await this.createNewPlanDefinitionAsync()}
                                    />
                                </>
                            </Tab>

                            <Tab id="completedPlans"
                                 title={Localizer.plansTabCompletedPlans}
                                 className={styles.navTabs}
                            >
                                <PageRow>
                                    <PlanGrid organizationContractId={this.organizationContractId}
                                              userId={null}
                                    />
                                </PageRow>
                            </Tab>

                            {
                                (this.isAdminWithAdminRole) &&

                                <Tab id="deletedRequests"
                                     title={Localizer.plansTabDeletedPlans}
                                     className={styles.navTabs}
                                >
                                    <>
                                        <DeletedPlans isAdmin={this.isAdminWithAdminRole}/>
                                    </>
                                </Tab>
                            }

                        </TabContainer>
                    )}
            </PageContainer>
        );
    }
}