import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Desktop - Authenticated private user catalog tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session('private_user_session', cy.login, {});
    });

    it('Open catalog page and navigate back from breadcrumbs', () => {
        pageData().rent.visitWithIntercept();

        //Should have home -> rent = 2 items
        cy.assertBreadCrumbLength(2);

        pageData().rent.firstCategoryItem()
            .then(($btn: any) => {
                cy.wrap($btn)
                    .invoke('text')
                    .then((parentCategoryText: string) => {
                        //Open main category

                        executeWithIntercept(() =>
                                $btn.click(),
                            [pageData().rent.routes.categoriesData as RouteData]
                        );

                        //Should have home -> rent -> parent = 3 items
                        cy.assertBreadCrumbLength(3);

                        cy.assertBreadCrumbsContainText(parentCategoryText);

                        cy.clickBreadCrumb(1);

                        cy.assertBreadCrumbLength(2);

                        cy.clickBreadCrumb(0);
                        //Back on frontpage
                        cy.get('#rent_widget');
                    });
            });

    });

    it('Open catalog page and navigate back from browser', () => {
        pageData().rent.visitWithIntercept();

        cy.assertBreadCrumbLength(2);

        pageData().rent.firstCategoryItem()
            .then(($btn: any) => {
                cy.wrap($btn)
                    .invoke('text')
                    .then((parentCategoryText: string) => {
                        executeWithIntercept(() =>
                                $btn.click(),
                            [pageData().rent.routes.categoriesData as RouteData]
                        );
                        //Should have home -> rent -> parent = 3 items
                        cy.assertBreadCrumbLength(3);

                        cy.assertBreadCrumbsContainText(parentCategoryText);
                        cy.go('back');
                        cy.assertBreadCrumbLength(2);

                    });
            });

    });

    it('Open catalog page and navigate back from breadcrumbs', () => {
        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.assertBreadCrumbLength(4);

        cy.clickBreadCrumb(2);

        cy.assertBreadCrumbLength(3);

        cy.clickBreadCrumb(0);
        //Back on frontpage
        cy.get('#rent_widget');
    });

    it('Open catalog page and navigate back from breadcrumbs (skip parent)', () => {
        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.assertBreadCrumbLength(4);
        cy.clickBreadCrumb(1);
        cy.assertBreadCrumbLength(2);
        cy.clickBreadCrumb(0);
        //Back on frontpage
        cy.get('#rent_widget');
    });

    it('Open product page and verify prices', () => {

        pageData().rent.visitWithIntercept(Cypress.env('scissor_boom_lift_name'))

        cy.intercept(
            {
                method: 'POST',
                path: '/api/ShoppingCart/SaveProductToCart',
            },
            {fixture: 'addToCartResponse.json'}
        );

        cy.assertBreadCrumbLength(4);

        cy.assertProductInfoModal('83a700be-2e3b-45cc-ac8a-570a551760d4');


        const product2_daily_price = Cypress.env("product2_daily_price_vat_24");
        const product2_monthly_price = Cypress.env("product2_monthly_price_vat_24");

        const product2_daily_price_vat0 = Cypress.env("product2_daily_price_vat_0")
        const product2_monthly_price_vat0 = Cypress.env("product2_monthly_price_vat_0");

        //Assert vat 24 prices
        pageData().rent.product.dailyPrice('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .should('contain.text', product2_daily_price);
        pageData().rent.product.dailyPrice('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .should('contain.text', product2_monthly_price);

        pageData().rent.product.vat0Link('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .click();

        //Assert vat 0 prices
        pageData().rent.product.dailyPrice('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .should('contain.text', product2_daily_price_vat0);
        pageData().rent.product.monthlyPrice('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .should('contain.text', product2_monthly_price_vat0);

        // Show vat 24 prices again
        pageData().rent.product.vat24Link('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .click();

        //Assert vat 24 prices
        pageData().rent.product.dailyPrice('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .should('contain.text', product2_daily_price);
        pageData().rent.product.monthlyPrice('83a700be-2e3b-45cc-ac8a-570a551760d4')
            .should('contain.text', product2_monthly_price);


        onRedirect(() => {
            executeWithIntercept(() => pageData().rent.productLink('83a700be-2e3b-45cc-ac8a-570a551760d4')
                    .click(),
                [pageData().rent.routes.productsData as RouteData]);
        });

        //Assert vat 24 prices
        cy.get('#prices-container').invoke('text').then((text: string) => {
            if (text.includes(product2_daily_price)
                || text.includes(product2_daily_price.replace('.', ','))) {
            }
            else {
                throw new Error("Cannot find correct price daily 24");
            }

        });

        cy.get('#vat_0_price').click();

        //Assert vat 0 prices
        cy.get('#prices-container')
            .invoke('text')
            .then((text: string) => {
                if (text.includes(product2_daily_price_vat0)
                    || text.includes(product2_daily_price_vat0.replace('.', ','))) {
                }
                else {
                    throw new Error("Cannot find correct vat-0 dailyPrice " + product2_daily_price_vat0);
                }
            });
    });
});