import React from "react";
import {ActionType, AlertModel, BaseAsyncComponent, ch, IBaseAsyncComponentState} from "@renta-apps/athenaeum-react-common";
import {FileModel, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {CellAction, CellActionComponent, CellModel, ColumnDefinition, Grid, RowModel, SelectListItem} from "@renta-apps/athenaeum-react-components";
import UsersGridToolbar from "./Toolbar/UsersGridToolbar";
import UsersGridToolbarModel from "@/components/UsersGrid/Toolbar/UsersGridToolbarModel";
import UserModel from "@/models/server/UserModel";
import DetailsPanel from "@/components/UsersGrid/DetailsPanel/DetailsPanel";
import ConstructionSiteUserModel from "@/models/server/ConstructionSiteUserModel";
import GetUsersCsvFileRequest from "@/models/server/Requests/GetUsersCsvFileRequest";
import UserContext from "@/models/server/UserContext";
import Localizer from "@/localization/Localizer";

interface IUsersGridProps {
    canManageUsers: boolean;
    contractId?: string;
    showPrivateUsers?: boolean;
    showDepotColumn?: boolean;
    showDeleteUserButton?: boolean;

    fetchUsers(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null, filters: UsersGridToolbarModel | null): Promise<IPagedList<UserModel>>;

    onUserDeleteAsync?(email: string): Promise<void>;

    onUserAuthenticationRemoveAsync?(email: string): Promise<void>;

    getUserDebugDetails?(email: string): Promise<void>
}

interface IUsersGridState extends IBaseAsyncComponentState<{}> {
    users: IPagedList<UserModel> | null;
    alertModel: AlertModel | null;
    filters: UsersGridToolbarModel | null;
    isLoading: boolean;
}

export default class UsersGrid extends BaseAsyncComponent<IUsersGridProps, IUsersGridState, {}> {

    public state: IUsersGridState = {
        data: null,
        users: null,
        alertModel: null,
        isLoading: false,
        filters: {
            keyword: "",
            roleNamesDropdown: !this.props.showPrivateUsers ? [
                new SelectListItem("OrganizationMainUser", Localizer.organizationMainUser),
                new SelectListItem("ConstructionSiteMainUser", Localizer.constructionSiteMainUser),
                new SelectListItem("ConstructionSiteUser", Localizer.constructionSiteUser)
            ] : [
                new SelectListItem("Admin", Localizer.admin),
                new SelectListItem("OfficeUser", Localizer.officeUser),
                new SelectListItem("OrganizationMainUser", Localizer.organizationMainUser),
                new SelectListItem("ConstructionSiteMainUser", Localizer.constructionSiteMainUser),
                new SelectListItem("ConstructionSiteUser", Localizer.constructionSiteUser),
                new SelectListItem("Yksityinen", Localizer.privatePerson)

            ],
            selectedRoleName: null,
            selectedDepotId: null,
            notProcessedUsers: false,
        }
    };

    protected getEndpoint(): string {
        return "";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private async fetchUsersAsync(pageNumber: number,
                                  pageSize: number,
                                  sortColumnName: string | null,
                                  sortDirection: SortDirection | null,
                                  filters: UsersGridToolbarModel | null): Promise<IPagedList<UserModel>> {

        return await this.props.fetchUsers(pageNumber, pageSize, sortColumnName, sortDirection, filters);

    }

    private readonly _usersGrid: React.RefObject<Grid<UserModel>> = React.createRef();

    private readonly _usersColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericRoleLanguageItemName,
            minWidth: 25,
            init: async (cell: CellModel<any>) => await this.initDetailsToggler(cell),
            actions: [
                {
                    title: Localizer.showMoreInfoTextLanguageItemName,
                    icon: {name: "far fa-user-friends", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Info,
                    callback: async (cell) => await this.toggleDetailsAsync(cell)
                }
            ]
        },
        {
            header: Localizer.usersGridNameHeaderLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            accessor: "name",
            minWidth: 150,
            transform: (cell: CellModel<UserModel>) => cell.model.informationFilled ? `${cell.model!.firstName} ${cell.model!.lastName}` : cell.model.email!,
            settings: {
                infoHideEqual: true,
                infoAccessor: (a: UserModel) => a.email,
            },
            editable: false,
        },
        {
            header: Localizer.myAccountClosestRentaOfficeLanguageItemName,
            visible: this.props.showDepotColumn ?? false,
            sorting: true,
            accessor: nameof<UserModel>(user => user.favoriteDepotName),
            minWidth: 150,
            editable: false,
        },
        {
            header: Localizer.genericPhoneNumberLanguageItemName,
            sorting: false,
            accessor: nameof<UserModel>(user => user.phoneNumber),
            minWidth: 150,
            editable: false,
        },
        {
            header: Localizer.formDeleteLanguageItemName,
            visible: this.canDeleteUser,
            minWidth: 25,
            actions:  [
                  {
                      name: "delete",
                      title: Localizer.genericDeleteRoleLanguageItemName,
                      icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                      type: ActionType.Delete,
                      confirm: (cell: CellModel<UserModel>) => Localizer.get(Localizer.confirmationDeleteUser, cell.model.email!),
                      callback: async (cell, action) => await this.processUserOperationAsync(cell, action)
                  },
              ]
        },
        {
            header: Localizer.usersGridUnbindHeaderLanguageItemName,
            minWidth: 25,
            visible: this.canDeleteUser,
            actions: [
                {
                    name: "unbind",
                    title: Localizer.genericDeleteStrongAuthenticationLanguageItemName,
                    icon: {name: "far user-minus", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Blue,
                    render: (cell: CellModel<UserModel>, action: CellAction<UserModel>) => (cell.model.strongAuthBoundToUser) ? this.renderStrongAuthUnbindingButton(cell, action) : false,
                    confirm: (cell: CellModel<UserModel>) => Localizer.get(Localizer.confirmationDeleteUserStrongAuthentication),
                    callback: async (cell, action) => await this.processUserOperationAsync(cell, action)
                }
            ]
        },
        {
            header: "Debug",
            minWidth: 25,
            visible: this.canDeleteUser,
            actions: [
                {
                    name: "debug",
                    title: "Debug",
                    icon: {name: "far bug", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Grey,
                    callback: async (cell, action) => await this.processUserOperationAsync(cell, action)
                }
            ]
        }
    ];

    private renderStrongAuthUnbindingButton(cell: CellModel<UserModel>, cellAction: CellAction<UserModel>) {
        return (
            <CellActionComponent key={`${cell.model.id}_${cellAction.action.name}`} cell={cell} cellAction={cellAction}/>
        );
    }

    private async processUserOperationAsync(cell: CellModel<ConstructionSiteUserModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "delete") {

            if (this.props.onUserDeleteAsync) {
                await this.props.onUserDeleteAsync(cell.model.email!);
            }
            await cell.grid.deleteAsync(cell.row.index);
        }

        if (action.action.name === "unbind") {
            if (this.props.onUserAuthenticationRemoveAsync) {
                await this.props.onUserAuthenticationRemoveAsync(cell.model.email!);
            }
        }

        if (action.action.name === "debug") {
            if (this.props.getUserDebugDetails) {
                await this.props.getUserDebugDetails(cell.model.email!);
            }
        }
    }

    private get canDeleteUser(): boolean {
        return (!!this.props.showDeleteUserButton) && ((ch.getContext() as UserContext).isAdminWithAdminRole);
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private async initDetailsToggler(cell: CellModel<UserModel>) {
        if (cell.model.organizationRoles.length === 0 && cell.model.constructionSiteRoles.length === 0) {
            cell.visible = false;
        }
    }

    private async toggleDetailsAsync(cell: CellModel<UserModel>): Promise<void> {
        const spannedRows: RowModel<UserModel>[] = cell.spannedRows;
        const rowToExpand: RowModel<UserModel> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    private get filters(): UsersGridToolbarModel {
        return this.state.filters!;
    }

    private async downloadUsersCsvAsync(model: UsersGridToolbarModel, selectedContractId: string): Promise<void> {
        let request: GetUsersCsvFileRequest = {
            selectedDepotId: model.selectedDepotId,
            selectedRoleName: model.selectedRoleName,
            contractId: selectedContractId,
            notProcessedUsers: this.filters.notProcessedUsers,
        };

        let response: FileModel = await this.postAsync("/api/Companies/DownloadCsvFile", request);

        ch.download(response);
    }

    private async filterUsersAsync(): Promise<void> {
        await this._usersGrid.current!.reloadAsync();
    }

    private renderDetailsContent(row: RowModel<UserModel>) {
        const model: UserModel = row.model;
        return (
                <DetailsPanel constructionSiteRoles={model.constructionSiteRoles}
                              organizationRoles={model.organizationRoles}
                              canManageUsers={this.props.canManageUsers}
                              userRoles={this.state!.filters!.roleNamesDropdown}
                              onRoleDeleteAsync={async (alert: AlertModel) => await this.onRoleDeleteAsync(row)}
                />
        );
    }

    private async onRoleDeleteAsync(row: RowModel<UserModel>): Promise<void> {

        if ((row.model.constructionSiteRoles.length + row.model.organizationRoles.length) === 1) {
            this._usersGrid.current!.rows.remove(row);
            await this._usersGrid.current!.reloadAsync();
        }
    }

    public async reloadAsync(): Promise<void> {
        await this._usersGrid.current!.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <UsersGridToolbar model={this.filters}
                                  contractId={this.props.contractId}
                                  showDepotFilter={this.props.showDepotColumn ?? false}
                                  downloadUsersCsv={async (model, selectedContractId) => await this.downloadUsersCsvAsync(model, selectedContractId)}
                                  onChange={async () => await this.filterUsersAsync()}
                />

                <Grid responsive
                      version2Styles
                      id={"users_grid"}
                      pagination={10}
                      renderDetails={(row) => this.renderDetailsContent(row)}
                      ref={this._usersGrid}
                      columns={this._usersColumns}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.fetchUsersAsync(pageNumber, pageSize, sortColumnName, sortDirection, this.filters)}
                />

            </React.Fragment>
        );
    }

}