export enum FileType {
    MainPicture,

    Picture,

    Pdf,

    Other
}

export default class FileApiModel {
    constructor(src: string | null = null) {
        this.src = src || "";
    }

    public fileType: FileType = FileType.MainPicture;

    public id: string = "";

    public name: string = "";

    public size: number = 0;

    public type: string = "";

    public src: string = "";

    public reference: string = "";

    public readonly isApiFileModel: true = true;

    public documentId: number | null = null;

    public isSelected: boolean = false;
}