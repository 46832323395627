import React from "react";
import {CellAction, CellModel, ColumnDefinition, ColumnType, Grid, Link} from "@renta-apps/athenaeum-react-components";
import {ActionType, BaseComponent, PageRoute} from "@renta-apps/athenaeum-react-common";

import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import Localizer from "@/localization/Localizer";
import {ArrayUtility, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";

import styles from "./Contracts.module.scss";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaEasyController from "@/pages/RentaEasyController";

interface IContractsState {
    isLoading: boolean;
}

interface IContractsProps {
    allowEdit: boolean;
    contracts: OrganizationContractModel[];
}

class Contracts extends BaseComponent<IContractsProps, IContractsState> {

    public state: IContractsState = {
        isLoading: true
    };

    private readonly _contractsColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: "name",
            editable: this.props.allowEdit,
            type: ColumnType.Text,
            sorting: true
        },
        {
            header: Localizer.companyExtraReferenceLanguageItemName,
            accessor: "additionalName",
            editable: false,
            sorting: true
        },
        {
            header: Localizer.companyDetailsCustomerIdLanguageItemName,
            accessor: "customerNumber",
            className: styles.linkCellStyle,
            editable: false,
            sorting: true,
            actions: [
                {
                    render: (cell: CellModel<OrganizationContractModel>) => this.renderContractDetailsLink(cell.model.contractId, cell.model.customerNumber!),
                }
            ],
        },
        {
            header: Localizer.companyDetailsUserManagement,
            editable: false,
            accessor: "isDeleted",
            className: styles.linkCellStyle,
            maxWidth: 0,
            actions: [
                {
                    render: (cell: CellModel<OrganizationContractModel>) => this.renderInviteUserLink(cell.model.contractId),
                }
            ],
        },
        {
            header: Localizer.companyDetailsListOrders,
            editable: false,
            accessor: "vatId",
            className: styles.linkCellStyle,
            maxWidth: 0,
            actions: [
                {
                    render: (cell: CellModel<OrganizationContractModel>) => this.renderListOrdersLink(cell),
                }
            ],
        },
        {
            visible: this.props.allowEdit,
            minWidth: 25,
            type: ColumnType.Icon,
            className: styles.halfWidth,
            init: (cell) => this.initContractOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSave,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processSaveOperationAsync(cell, action)
                }
            ]
        }
    ];

    private async processSaveOperationAsync(cell: CellModel<OrganizationContractModel>, action: CellAction<OrganizationContractModel>): Promise<void> {

        const model: OrganizationContractModel = cell.model;

        if (action.action.name === "save" && model.name) {
            const response: OrganizationContractModel = await RentaEasyController.saveContractName({contractId: model.contractId, name: model.name});
            cell.row.model = response!;
        }
        await cell.row.bindAsync();
    }


    private async initContractOperationsAsync(cell: CellModel<OrganizationContractModel>): Promise<void> {

        const model: OrganizationContractModel = cell.row.model;

        const modified: boolean = cell.row.modified;
        const isValid: boolean = this.isValid(model);

        const saveAction: CellAction<OrganizationContractModel> = cell.actions[0];

        saveAction.visible = (modified) && (isValid);
    }

    private isValid(contract: OrganizationContractModel): boolean {
        return (!!contract.name);
    }

    private renderListOrdersLink(cell: CellModel<OrganizationContractModel>) {
        return (
            <Link route={this.listOrdersLink(cell.model.contractId)}
                  className={styles.pageLink}
                  key={`orders_${cell.model.contractId}`}
            >
                {Localizer.companyDetailsListOrders}
            </Link>
        );
    }

    private listOrdersLink(contractId: string) {
        return PageDefinitions.orders.route({params: {contractId}});
    }

    private inviteUserLink(contractId: string): PageRoute {
        return PageDefinitions.inviteUser.route({params: {contractId: contractId}});
    }

    private contractDetailsLink(contractId: string): PageRoute {
        return PageDefinitions.contractDetails.route({params: {id: contractId}});
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private async getContractsAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<OrganizationContractModel>> {
        let data: OrganizationContractModel[] = this.props.contracts;
        if (sortColumnName) {
            data.sort(ArrayUtility.sortByProperty(sortColumnName, sortDirection));
        }
        return Promise.resolve(data.toPagedList(pageNumber, pageSize));
    }

    private renderContractDetailsLink(contractId: string, customerNumber: string): React.ReactNode {
        return (
            <Link route={this.contractDetailsLink(contractId)}
                  className={styles.pageLink}
                  key={`details_${contractId}`}
            >
                {customerNumber}
            </Link>
        );
    }

    private renderInviteUserLink(contractId: string): React.ReactNode {
        return (
            <Link route={this.inviteUserLink(contractId)}
                  className={styles.pageLink}
                  key={`invite_${contractId}`}
            >
                {Localizer.companyDetailsInviteUser}
            </Link>
        );
    }

    render(): React.ReactNode {
        return (
            <Grid version2Styles
                  pagination={10}
                  fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                      await this.getContractsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                  columns={this._contractsColumns}
            />
        );
    }
}

export default Contracts;