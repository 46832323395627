import React from "react";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import Localizer from "@/localization/Localizer";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";
import {FetchTimeModel} from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal";
import {DateInput} from "@renta-apps/athenaeum-react-components";
import {Checkbox} from "@renta-apps/renta-react-components";

type EarliestFetchTimeProps = {
    model: FetchTimeModel | null;
    onDateTimeChange: (datetime: Date) => void;
    onAvailableNowChange: (value: boolean) => void;
}

const EarliestFetchTime: React.FC<EarliestFetchTimeProps> = ({model, onDateTimeChange, onAvailableNowChange}) => {
    return (
        <ContentBox title={Localizer.returnRequestModalFetchTimeHeader} subTitle={Localizer.returnRequestModalFetchTimeSubheader}>
            <div className={styles.fetchTimeContainer} data-cy="return-modal-fetch-time">
                <div className={styles.datetime} data-cy="return-modal-fetch-date-time">
                    <DateInput showTime
                               popup
                               value={model?.datetime}
                               label={Localizer.returnRequestModalDateAndTime}
                               onChange={async (date) => onDateTimeChange(date)}
                               className={styles.inputField}
                    />
                </div>
                <div className={styles.radioButtons} data-cy="return-modal-fetch-time-radio-buttons">
                    <Checkbox checked={model?.availableNow === true}
                              onChange={(checked: boolean) => {
                                  // already checked, don't do anything
                                  if (!checked && model?.availableNow === true) {
                                      return;
                                  }
                                  onAvailableNowChange(checked);
                              }}
                              label={Localizer.returnRequestModalFetchTimeNow}
                              disabled={model === null}
                              className={styles.radioCheckbox}
                    />
                    <Checkbox checked={model?.availableNow === false}
                              onChange={(checked: boolean) => {
                                  // already checked, don't do anything
                                  if (!checked && model?.availableNow === false) {
                                      return;
                                  }
                                  onAvailableNowChange(!checked);
                              }}
                              label={Localizer.returnRequestModalFetchTimeAfter}
                              disabled={model === null}
                              className={styles.radioCheckbox}
                    />
                </div>
            </div>
        </ContentBox>
    );
}

export default EarliestFetchTime;