import React from "react";
import {ch} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";

interface EasyPlusLinkProps  {
    children: React.ReactNode;
}

const EasyPlusLink: React.FC<EasyPlusLinkProps> = ({children}) => {

    const easyPlusLink = React.useMemo(() => {
        return Localizer.getValue(ch.country, Localizer.easyPlusInfoLinkLanguageItemName);
    }, []);

    const isValidUrl = (url: string) => {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    };

    if (!easyPlusLink || !isValidUrl(easyPlusLink)) {
        return null;
    }

    return (
        <a href={easyPlusLink} data-cy="easy_plus_link">
            {children}
        </a>
    );
};

export default EasyPlusLink;