import {ch} from "@renta-apps/athenaeum-react-common";

export const getCountry = function (): string {
    if (ch.isSweden) {
        return "SE";
    }
    if (ch.isFinland) {
        return "FI";
    }
    if (ch.isDenmark) {
        return "DK";
    }
    if (ch.isPoland) {
        return "PL";
    }
    if (ch.isNorway) {
        return "NO";
    }
    throw new Error("Not supported country: " + ch.country);
};

// returns geographical center of the current country
export const getGeographicalCenter = function(): google.maps.LatLngLiteral {
    const countryCode = getCountry();
    
    switch (countryCode) {
        case "SE":
            return {
                lat: 62.3875,
                lng: 16.325555555555557,
            };
        case "DK":
            return {
                lat: 55.96666666666667,
                lng: 11.766666666666667,
            };
        case "PL":
            return {
                lat: 52.19109722222222,
                lng: 19.355405555555556,
            };
        case "NO":
            return {
                lat: 63.99055555555556,
                lng: 12.307777777777778,
            };
        default:    // FI
                return {
                    lat: 64.27150712,
                    lng: 25.8665698,
                };
    }
}
