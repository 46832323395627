import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import queryString, {ParsedQuery} from "query-string";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import LoadingOrDataNotFound from "../../components/LoadingOrDataNotFound/LoadingOrDataNotFound";
import Localizer from "@/localization/Localizer";
import welcomePageStyles from "@/pages/Welcome/Welcome.module.scss";
import styles from "./TokenLogin.module.scss";

interface ITokenLoginProps {
}

interface ITokenLoginState {
}

export default class TokenLogin extends AnonymousPage <ITokenLoginProps, ITokenLoginState> {

    state: ITokenLoginState = {};

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const parsed: ParsedQuery = queryString.parse(window.location.search);

        let token = parsed.jwt as string;
        if (!token) {
            const pathname: string = window.location.pathname.substr(1);
            if ((pathname) && (pathname.length === 32) && (/^[0-9a-fA-F]+$/.test(pathname))) {
                token = pathname;
            }
        }

        if (token) {
            await this.postAsync("/api/Application/TokenLogin", token);
            if (this.useRouting) {
                await PageRouteProvider.changeUrlWithoutReload();
            }
        }
    }

    protected get title(): string {
        return "";
    }

    public render(): React.ReactNode {
        return (
            <PageContainer hasWideHeader
                           className={this.css("text-align-center", welcomePageStyles.welcomePage, styles.tokenLoginPage)}>
                <PageHeader wideHeader
                            title={""}
                            className={this.css(welcomePageStyles.topBanner)}>
                    <header className={welcomePageStyles.header}>
                        <img className={welcomePageStyles.headerImage}
                             src={'../images/renta-worker.png'} alt={"Reta worker"}
                        />
                        <div>
                            <div className={welcomePageStyles.headerText}></div>
                            <img className={welcomePageStyles.headerLogo}
                                 src={'../images/renta_new.png'} alt={"Renta Easy"}/>
                        </div>
                    </header>
                </PageHeader>
                <PageRow>
                    <div className={styles.loadingContainer}>
                            <LoadingOrDataNotFound isLoading={true} noDataText={""} className={styles.loader}/>
                        <p>{Localizer.componentListLoading}</p>
                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}