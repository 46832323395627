import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {
    AddressHelper,
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    DateInput,
    DateRangeInput,
    Dropdown,
    FileInput,
    Form,
    Icon,
    IconSize,
    ImageModal, InlineType,
    LocationPicker,
    ModalSize,
    NoSwipeNavigation,
    NumberInput,
    OneColumn,
    TextAreaInput,
    TextInput,
} from "@renta-apps/athenaeum-react-components";
import CustomServiceRequestModel from "@/models/server/CustomServiceRequestModel";
import ServiceRequestDefinitionInput from "@/models/server/ServiceRequestDefinitionInput";
import {InputType, ServiceType} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import InputGroup from "@/models/server/InputGroup";
import CreateCustomServiceRequestRequest from "@/models/server/Requests/CreateCustomServiceRequestRequest";
import queryString, {ParsedQuery} from "query-string";
import Localizer from "@/localization/Localizer";

import styles from "./ServicePreview.module.scss";
import DisplayCondition from "@/models/server/DisplayCondition";
import {FileModel, GeoLocation, Utility} from "@renta-apps/athenaeum-toolkit";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import FileApiModel from "@/models/server/FileApiModel";
import ImageProvider from "@/providers/ImageProvider";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import LocalizationHelper from "@/helpers/LocalizationHelper";

interface IServicePreviewProps {
    /**
     * See {@link IServicesState.selectedServiceRequest}
     */
    model: CustomServiceRequestModel,

    deleted?: boolean;
    editMode?: boolean,
    previewMode?: boolean,
    groupingEnabled?: boolean,
    /**
     * See {@link IServicesEditorState.currentInputGroup}
     */
    selectedGroup?: InputGroup | null

    setSelectedInputGroupAsync?(group: InputGroup): Promise<void>;

    closePreviewAsync?(): Promise<void>;

    openInputEditorAsync?(value: ServiceRequestDefinitionInput, group: InputGroup | null): Promise<void>;

    closeInputEditorAsync?(deletedInputIds: string[]): Promise<void>;
}

interface IServicePreviewState {
    /**
     * All InputGroups to be rendered (in preview mode)
     * These are the ones that have at least one input of which displayconditions are met
     */
    groupsToDisplay: InputGroup[];
    /**
     * Currently displayed InputGroup (in preview mode)
     */
    selectedGroupToDisplay: InputGroup | null;
    /**
     * Count of {@link groupsToDisplay}
     */
    totalGroupsWithInputsCount: number;
}

/**
 * This component displays what the current definition looks like
 */
export default class ServicePreview extends BaseComponent<IServicePreviewProps, IServicePreviewState> {

    public state: IServicePreviewState = {
        groupsToDisplay: [],
        selectedGroupToDisplay: null,
        totalGroupsWithInputsCount: this.model?.inputGroups?.length ?? 0,
    };

    private readonly _previewCustomServiceRequestImageRef: React.RefObject<ImageModal> = React.createRef();

    // Getters

    private get model(): CustomServiceRequestModel {
        return this.props.model;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private get organizationContractId(): string {
        const parsed: ParsedQuery = queryString.parse(window.location.search);
        return (parsed.id as string) ?? this.userContext.selectedContractId;
    };

    private get deleted(): boolean {
        return (!!this.props.deleted);
    }

    private get editMode(): boolean {
        return (!!this.props.editMode);
    }

    private get previewMode(): boolean {
        return (!!this.props.previewMode);
    }

    private get inputGroups(): InputGroup[] {
        return this.model.inputGroups ?? [];
    }

    private get allInputs(): ServiceRequestDefinitionInput[] {
        return this.inputGroups.map(group => group.inputs).flat();
    }

    private get allDisplayedInputs(): ServiceRequestDefinitionInput[] {
        return this.groupsToDisplay.map(group => group.inputs).flat();
    }

    private get defaultGroup(): InputGroup | null {
        return this.model?.inputGroups?.[0] ?? null;
    }

    private get defaultGroupInputs(): ServiceRequestDefinitionInput[] {
        return this.defaultGroup?.inputs ?? [];
    }

    private get inputs(): ServiceRequestDefinitionInput[] {
        return (!this.groupingEnabled)
            ? (this.previewMode)
                ? this.allInputs
                : this.defaultGroupInputs
            : this.selectedGroupInputs ?? [];
    }

    private get groupingEnabled(): boolean {
        return !!this.props.groupingEnabled && !this.previewMode;
    }

    private get totalGroups(): number {
        return this.state.totalGroupsWithInputsCount;
    }

    private get selectedGroup(): InputGroup | null {
        return this.props.selectedGroup ?? null;
    }

    private get selectedGroupToDisplay(): InputGroup {
        return this.state.selectedGroupToDisplay ?? this.groupsToDisplay[0] ?? new InputGroup();
    }

    private get selectedGroupInputs(): ServiceRequestDefinitionInput[] | null {
        return this.selectedGroup?.inputs ?? null;
    }

    private get groupsToDisplay(): InputGroup[] {
        return this.state.groupsToDisplay;
    }

    private get currentlyDisplayedRequiredInputHasNoValue(): boolean {
        return this.selectedGroupToDisplay.inputs.some(input => input.isMandatory && (
            (!input.selectedValue || (input.inputType === InputType.Checkbox && input.selectedValue === "false")) &&
            !input.selectedValues.length && !input.selectedImage && !input.selectedImages?.length
        ));
    }

    private get requiredInputHasNoValue(): boolean {
        return this.allDisplayedInputs.some(input => input.isMandatory && (
            (!input.selectedValue || (input.inputType === InputType.Checkbox && input.selectedValue === "false")) &&
            !input.selectedValues.length && !input.selectedImage && !input.selectedImages?.length
        ));
    }

    // Sync-methods

    private formatDateValue(value: Date | (Date | null)[]): string {
        if (value instanceof Date) {
            return value.format("D");
        }
        if (value[0] === null) {
            return "";
        }

        return value[0]!.format("D");
    }

    private formatStringAsDate(value: string | null): (Date | null) {
        if (!value) {
            return null;
        }
        // dd.MM.yyyy
        const valueParts = value.split(".");
        if (valueParts.length !== 3) {
            return null;
        }
        // Months start from index 0 in new Date(year, month, day)
        return new Date(parseInt(valueParts[2]), parseInt(valueParts[1]) - 1, parseInt(valueParts[0]));
    }

    private formatStringAsDateRange(value: string | null, index: number): (Date | null) {
        if (!value) {
            return null;
        }
        // startDate - endDate
        const valueParts = value.split(" - ");
        if (valueParts.length > index) {
            return this.formatStringAsDate(valueParts[index]);
        }
        return null;
    }

    private toLocation(value: string | null): (GeoLocation | undefined) {
        if (!value) {
            return undefined;
        }

        return AddressHelper.toLocation(value) ?? undefined;
    }

    // Async-methods

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.setDisplayGroupsAsync();
    }

    private async setCurrentInputGroupAsync(group: InputGroup): Promise<void> {
        if (this.props.setSelectedInputGroupAsync) {
            await this.props.setSelectedInputGroupAsync(group);
        }
    }

    private async upAsync(item: ServiceRequestDefinitionInput, index: number, group: InputGroup | null = null): Promise<void> {

        if (index > 0) {
            const inputs = (group) ? group.inputs : this.inputs;
            const previous: ServiceRequestDefinitionInput = inputs[index - 1];
            inputs[index - 1] = item;
            inputs[index] = previous;

            await this.reRenderAsync();
        }
    }

    private async previousGroupAsync(currentGroupIndex: number): Promise<void> {
        await this.setDisplayGroupsAsync(currentGroupIndex - 1);
    }

    private async nextGroupAsync(currentGroupIndex: number): Promise<void> {
        await this.setDisplayGroupsAsync(currentGroupIndex + 1);
    }

    private async groupUpAsync(group: InputGroup, index: number): Promise<void> {

        if (index > 0) {
            const previousGroup: InputGroup = this.inputGroups[index - 1];
            this.inputGroups[index - 1] = group;
            this.inputGroups[index] = previousGroup;

            await this.reRenderAsync();
        }
    }

    private async downAsync(item: ServiceRequestDefinitionInput, index: number, group: InputGroup | null = null): Promise<void> {
        const inputs = (group) ? group.inputs : this.inputs;

        if (index < inputs.length - 1) {
            const next: ServiceRequestDefinitionInput = inputs[index + 1];
            inputs[index + 1] = item;
            inputs[index] = next;

            await this.reRenderAsync();
        }
    }

    private async groupDownAsync(group: InputGroup, index: number): Promise<void> {
        if (index < this.inputGroups.length - 1) {

            const nextGroup: InputGroup = this.inputGroups[index + 1];
            this.inputGroups[index + 1] = group;
            this.inputGroups[index] = nextGroup;

            await this.reRenderAsync();
        }
    }

    private async deleteAsync(index: number, group: InputGroup | null): Promise<void> {

        const deletedInputIds: string[] = [];
        if (group) {
            deletedInputIds.push(group.inputs[index].id!);
            group.inputs.splice(index, 1);
        }
        else {
            deletedInputIds.push(this.inputs[index].id!);
            this.inputs.splice(index, 1);
        }

        if (this.props.closeInputEditorAsync) {
            await this.props.closeInputEditorAsync(deletedInputIds);
        }

        await this.reRenderAsync();
    }

    private async deleteGroupAsync(group: InputGroup): Promise<void> {

        const deletedInputIds: string[] = [];
        group.inputs.forEach(input => deletedInputIds.push(input.id!));
        this.inputGroups.remove(group);

        if (this.props.setSelectedInputGroupAsync) {
            await this.setCurrentInputGroupAsync(this.inputGroups[this.inputGroups.length - 1]);
        }

        if (this.props.closeInputEditorAsync) {
            await this.props.closeInputEditorAsync(deletedInputIds);
        }

        await this.reRenderAsync();
    }

    private async openInputEditorAsync(_index: number, value: ServiceRequestDefinitionInput, group: InputGroup | null): Promise<void> {
        if (this.props.openInputEditorAsync) {
            await this.props.openInputEditorAsync(value, group);
        }
    }

    private async sendServiceRequestAsync(): Promise<void> {
        let inputGroups: InputGroup[];
        let inputs: ServiceRequestDefinitionInput[];

        if (this.groupingEnabled) {
            inputGroups = this.groupsToDisplay;
            inputs = this.allDisplayedInputs;
        }
        else {
            inputGroups = this.defaultGroup ? [this.defaultGroup] : [];
            inputs = this.defaultGroupInputs.filter(i => this.displayConditionsMet(i.displayConditions));
        }

        const createRequest: CreateCustomServiceRequestRequest = {
            inputGroups: inputGroups ?? [],
            inputs: inputs ?? [],
            organizationContractId: this.organizationContractId,
            serviceRequestDefinitionId: this.model.id,
            type: ServiceType.Custom
        };

        await this.postAsync("/api/Services/CreateCustomServiceRequest", createRequest);

        if (this.props.closePreviewAsync) {
            await this.props.closePreviewAsync();
        }
    }

    private async setDisplayGroupsAsync(index: number | null = null): Promise<void> {
        let groups: InputGroup[] = Utility.clone(this.props.model.inputGroups);

        // Groups not fulfilling the display conditions should not be visible.
        groups?.forEach(group => group.inputs = group?.inputs?.filter(input => this.displayConditionsMet(input.displayConditions)));
        groups = groups?.filter(group => group?.inputs?.length > 0) ?? [];

        this.setState({totalGroupsWithInputsCount: groups.length ?? 0});
        if (groups[index ?? 0]?.inputs?.length > 0) {
            this.setState({groupsToDisplay: groups, selectedGroupToDisplay: groups[index ?? 0]});
        }
    }

    private async setInputValueAsync(input: ServiceRequestDefinitionInput, value: string | string[], groupIndex: number | null = null): Promise<void> {
        if (this.editMode) {
            return Promise.resolve();
        }

        const inputToChange: ServiceRequestDefinitionInput = this.allInputs.find(i => i.id === input.id)!;
        if (typeof value === "string") {
            inputToChange.selectedValue = value;
        }
        if (Array.isArray(value)) {
            inputToChange.selectedValues = value;
        }

        await this.setDisplayGroupsAsync(groupIndex);
        await this.reRenderAsync();
    }

    private displayConditionsMet(conditions: (DisplayCondition | null)[]) {
        if (this.editMode || !conditions) {
            return true;
        }
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i]!;
            const input = this.allInputs.find(i => i.id === condition?.inputId);
            if (!input) {
                return false;
            }
            if (condition.inputValue && !this.inputValueConditionMatches(condition, input)) {
                return false;
            }
            else if (condition.inputValueId && !this.inputValueIdConditionMatches(condition, input)) {
                return false;
            }
        }
        // All conditions match
        return true;
    }

    private async previewPictureDocumentAsync(imageReference: string | null): Promise<void> {

        if (imageReference) {
            const file: FileApiModel = await this.postAsync("/api/Services/GetServiceRequestImageAttachment", imageReference);
            const fileModel: FileModel = ImageProvider.convertEasyImageToCLImage(file);

            if (fileModel && RentaEasyConstants.imageFileTypes.includes(fileModel.type)) {
                await this._previewCustomServiceRequestImageRef.current!.openAsync(fileModel);
            }
        }
    }

    private inputValueConditionMatches(condition: DisplayCondition, input: ServiceRequestDefinitionInput): boolean {
        return (condition.inputValue === input.selectedValue ||
            (condition.inputValue === "false" && input.selectedValue === "") ||
            input.selectedValues.includes(condition.inputValue));
    }

    private inputValueIdConditionMatches(condition: DisplayCondition, input: ServiceRequestDefinitionInput): boolean {
        if (input.multiselect) {
            return input.selectedValues.some(value => {
                const index = input.inputValues?.indexOf(value);
                return condition.inputValueId === index?.toString();
            });
        }
        const index = input.inputValues?.indexOf(input.selectedValue);
        if (index === null || index === -1) {
            return false;
        }
        return condition.inputValueId === index.toString();
    }

    private async setInputFileAsync(input: ServiceRequestDefinitionInput, file: FileModel | FileModel[] | null, groupIndex: number | null = null): Promise<void> {

        const inputToChange: ServiceRequestDefinitionInput = this.allInputs.find(i => i.id === input.id)!;
        if (this.editMode) {
            return Promise.resolve();
        }

        if (file instanceof FileModel) {
            inputToChange.selectedImage = file;
            inputToChange.hasSingleImageInput = true;
        }

        if (Array.isArray(file)) {
            inputToChange.selectedImages = file;
            inputToChange.hasMultipleImageInput = true;
        }

        await this.setDisplayGroupsAsync(groupIndex);
        await this.reRenderAsync();
    }

    private async removeInputFileAsync(input: ServiceRequestDefinitionInput, file: FileModel, groupIndex: number | null = null): Promise<void> {

        const inputToChange: ServiceRequestDefinitionInput = this.allInputs.find(i => i.id === input.id)!;
        if (this.editMode) {
            return Promise.resolve();
        }

        if (inputToChange.selectedImage?.name === file.name) {
            inputToChange.selectedImage = null;
            inputToChange.hasSingleImageInput = false;
        }

        if (inputToChange.selectedImages?.length) {
            const fileInSelected = inputToChange.selectedImages?.find(f => f.name === file.name);
            if (fileInSelected) {
                inputToChange.selectedImages?.remove(fileInSelected);
                inputToChange.hasMultipleImageInput = inputToChange.selectedImages?.length > 0;
            }
        }

        await this.setDisplayGroupsAsync(groupIndex);
        await this.reRenderAsync();
    }

    public async setGroupHeader(value: string, group: InputGroup): Promise<void> {
        group.groupHeader = value;
    }

    // Renders

    private renderPreview(definitionInput: ServiceRequestDefinitionInput): React.ReactNode {

        if (definitionInput.inputType === InputType.Checkbox) {
            return (
                <Checkbox inline inlineType={InlineType.Right}
                          readonly
                          value={definitionInput.selectedValue === "true"}
                          label={definitionInput.label ?? ""}
                />
            );
        }

        if (definitionInput.inputType === InputType.FileInput) {

            if (definitionInput.hasSingleImageInput && definitionInput.singleSavedImageReference) {
                return (
                    <div className={this.css(styles.hoverPointer, styles.imageWrapper)}>
                        <Icon key={`${this.id}_customServicePreviewIcon`}
                              name={"fa-solid fa-image"}
                              size={IconSize.Large}
                              onClick={async () => await this.previewPictureDocumentAsync(definitionInput.singleSavedImageReference)}
                        />
                    </div>
                );
            }

            if (definitionInput.hasMultipleImageInput && definitionInput.multipleSavedImageReference.length > 0) {
                return (
                    <div className={styles.imageWrapper}>
                        {
                            definitionInput.multipleSavedImageReference?.map((imageReference) => (
                                    <div className={styles.hoverPointer}>
                                        <Icon key={`${this.id}_customServicePreviewIcon`}
                                              name={"fa-solid fa-image"}
                                              size={IconSize.Large}
                                              onClick={async () => await this.previewPictureDocumentAsync(imageReference)}
                                        />
                                    </div>
                                )
                            )
                        }
                    </div>
                );
            }
        }

        return (
            <TextInput readonly={true}
                       value={(definitionInput.selectedValues && definitionInput.selectedValues.length > 0)
                           ? definitionInput.selectedValues.join(", ")
                           : definitionInput.selectedValue!
                       }
                       label={definitionInput.label ?? ""}
            />
        );
    }

    private renderInput(definitionInput: ServiceRequestDefinitionInput, groupIndex: number | null = null): React.ReactNode {
        const label: string = definitionInput.label ?? "";

        if (this.previewMode) {
            return this.renderPreview(definitionInput);
        }

        if (definitionInput.inputType === InputType.Text
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <TextInput
                    id={definitionInput.id!}
                    value={definitionInput.selectedValue}
                    label={label}
                    required={definitionInput.isMandatory}
                    onChange={async (_sender, value) =>
                        await this.setInputValueAsync(definitionInput, value, groupIndex)}
                />
            );
        }

        if (definitionInput.inputType === InputType.TextArea
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <TextAreaInput
                    id={definitionInput.id!}
                    value={definitionInput.selectedValue}
                    label={label}
                    required={definitionInput.isMandatory}
                    onChange={async (_sender, value) =>
                        await this.setInputValueAsync(definitionInput, value, groupIndex)}
                />
            );
        }
        if (definitionInput.inputType === InputType.Date
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <DateInput
                    id={definitionInput.id!}
                    value={this.formatStringAsDate(definitionInput.selectedValue)}
                    label={label}
                    required={definitionInput.isMandatory}
                    onChange={async (date) =>
                        await this.setInputValueAsync(definitionInput, this.formatDateValue(date), groupIndex)}
                />
            );
        }
        if (definitionInput.inputType === InputType.Checkbox
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <Checkbox inline inlineType={InlineType.Right}
                          value={"true" === definitionInput.selectedValue}
                          id={definitionInput.id!}
                          label={label}
                          required={definitionInput.isMandatory}
                          onChange={async (_cb, value) =>
                              await this.setInputValueAsync(definitionInput, value ? "true" : "false", groupIndex)}

                />
            );
        }
        if (definitionInput.inputType === InputType.Number
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <NumberInput id={definitionInput.id!}
                             value={Number(definitionInput.selectedValue)}
                             label={label}
                             required={definitionInput.isMandatory}
                             onChange={async (_sender, value) =>
                                 await this.setInputValueAsync(definitionInput, value.toString(), groupIndex)}
                />
            );
        }
        if (definitionInput.inputType === InputType.DateRange
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <DateRangeInput
                    id={definitionInput.id!}
                    label={label}
                    model={{
                        value: [
                            this.formatStringAsDateRange(definitionInput.selectedValue, 0),
                            this.formatStringAsDateRange(definitionInput.selectedValue, 1)
                        ]
                    }}
                    required={definitionInput.isMandatory}
                    onChange={async ([start, end]) =>
                        await this.setInputValueAsync(definitionInput, `${this.formatDateValue([start])} - ${this.formatDateValue([end])}`, groupIndex)}
                />
            );
        }
        if (definitionInput.inputType === InputType.Dropdown
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <Dropdown id={definitionInput.id!}
                          multiple={definitionInput.multiselect}
                          items={definitionInput!.inputValues}
                          selectedItem={(definitionInput.multiselect) ? undefined : definitionInput.selectedValue}
                          selectedItems={(definitionInput.multiselect) ? definitionInput.selectedValues : undefined}
                          label={label}
                          required={definitionInput.isMandatory}
                          onChange={async (sender, item: string) =>
                              await this.setInputValueAsync(definitionInput, definitionInput.multiselect
                                      ? sender.selectedValues
                                      : item,
                                  groupIndex)}
                />
            );
        }
        if (definitionInput.inputType === InputType.LocationPicker
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <div className={styles.marginTopBottom}>
                	<NoSwipeNavigation>
	                    <LocationPicker fullWidth={this.editMode}
	                                    id={definitionInput.id!}
	                                    location={this.toLocation(definitionInput.selectedValue)}
	                                    label={label}
	                                    required={definitionInput.isMandatory}
	                                    zoomLevel={10}
	                                    countries={[LocalizationHelper.currentCountryCode]}
	                                    onChange={async (_sender, location) =>
	                                        await this.setInputValueAsync(definitionInput, AddressHelper.removeLatLon(location.formattedAddress), groupIndex)}
	                    />
	                </NoSwipeNavigation>
                </div>
            );
        }
        if (definitionInput.inputType === InputType.FileInput
            && this.displayConditionsMet(definitionInput.displayConditions)) {
            return (
                <div className={styles.tooltipFlex}>
                    <InlineTooltip className={this.css("ml-1")}
                                   text={Localizer.servicesFileInputTooltip}
                    />
                    <FileInput id={definitionInput.id!}
                               className={styles.marginTopBottom}
                               value={definitionInput.multiselect ?
                                   definitionInput.selectedImages :
                                   definitionInput.selectedImage}
                               label={label}
                               required={definitionInput.isMandatory}
                               multiple={definitionInput.multiselect}
                               fileTypes={RentaEasyConstants.imageFileTypes}
                               onChange={async (_sender, value) =>
                                   await this.setInputFileAsync(definitionInput, value, groupIndex)}
                               onRemove={async (value) => await this.removeInputFileAsync(definitionInput, value, groupIndex)}
                    />
                </div>
            );
        }

        return (
            <></>
        );
    }

    private renderUpDownArrows(value: ServiceRequestDefinitionInput, index: number, group: InputGroup | null = null): React.ReactNode {
        return (
            <div className={styles.inputButtons}>
                <Button icon={{name: "arrow-up"}} type={ButtonType.Orange}
                        onClick={async () => this.upAsync(value, index, group)}
                        disabled={(index === 0)}
                />
                <Button icon={{name: "arrow-down"}}
                        type={ButtonType.Orange}
                        onClick={async () => this.downAsync(value, index, group)}
                        disabled={(group && index === group.inputs.length - 1) || (!group && index === this.inputs.length - 1)}
                />
            </div>
        );
    }

    private renderGroupUpDownArrows(group: InputGroup, groupIndex: number): React.ReactNode {
        return (
            <div className={styles.inputButtons}>
                <Button icon={{name: "arrow-up"}} type={ButtonType.Blue}
                        onClick={async () => this.groupUpAsync(group, groupIndex)}
                        disabled={(groupIndex === 0)}
                />
                <Button icon={{name: "arrow-down"}}
                        type={ButtonType.Blue}
                        onClick={async () => this.groupDownAsync(group, groupIndex)}
                        disabled={(groupIndex === this.inputGroups.length - 1)}
                />
            </div>
        );
    }

    private renderEditDeleteButtons(value: ServiceRequestDefinitionInput, index: number, group: InputGroup | null = null): React.ReactNode {
        return (
            <div className={styles.inputButtons} data-cy={"EditInputButtons"}>
                <Button id={"editInputButton-" + (value.id ?? "")}
                        icon={{name: "pen"}} label={(this.mobile) ? "" : Localizer.serviceDefinitionsEdit}
                        type={ButtonType.Orange}
                        onClick={async () => await this.openInputEditorAsync(index, value, group)}
                        data-cy={"EditInputButton"}
                />

                <Button id={"deleteInputButton-" + (value.id ?? "")}
                        icon={{name: "trash"}}
                        type={ButtonType.Orange}
                        onClick={async () => await this.deleteAsync(index, group)}
                        data-cy={"DeleteInputButton"}
                />
            </div>
        );
    }

    private renderGroupEditDeleteButtons(group: InputGroup): React.ReactNode {
        return (
            <div className={styles.inputButtons}>
                <Button icon={{name: "pen"}} label={(this.mobile) ? "" : Localizer.generalActivate}
                        type={ButtonType.Blue}
                        disabled={(!!this.selectedGroup && this.selectedGroup === group)}
                        onClick={async () => await this.setCurrentInputGroupAsync(group)}/>

                <Button icon={{name: "trash"}}
                        type={ButtonType.Blue}
                        onClick={async () => await this.deleteGroupAsync(group)}
                />
            </div>
        );
    }

    private renderRow(leftElement: React.ReactNode, inputElement: React.ReactNode, rightElement: React.ReactNode, value?: ServiceRequestDefinitionInput,
                      index?: number, displayConditionsMet: boolean = true): React.ReactNode {

        if (!displayConditionsMet) {
            return (
                <></>
            );
        }

        if (this.editMode) {
            return (
                <div className={(this.editMode) ? styles.inputDefinitionContainer : ""}
                     key={(value?.label ?? "") + index}
                >
                    {leftElement}
                    {
                        <div className={this.css(this.editMode ? styles.inputPreview : styles.inputFillMode, styles.marginTopBottom)} data-cy={"InputContainer"}>
                            {inputElement}
                        </div>
                    }
                    {rightElement}
                </div>
            );
        }

        return (
            <OneColumn className={this.css((this.editMode) ? styles.inputDefinitionContainer : "", styles.marginTopBottom)} key={(value?.label ?? "") + index}>
                <div data-cy={"InputContainer"}>
                    {inputElement}
                </div>
            </OneColumn>
        );
    }

    private renderGroupNumberRow(group: InputGroup, groupIndex: number): React.ReactNode {
        const inputGroups = (this.editMode) ? this.inputGroups : this.groupsToDisplay;

        return (
            <div className={this.css(styles.groupNumberRow, (!this.editMode ? (styles.halfWidth) : ""))}>
                <Button id="previousGroupButton"
                        icon={{name: "arrow-left"}} type={ButtonType.Blue}
                        onClick={async () => await this.previousGroupAsync(groupIndex)}
                        disabled={this.editMode || groupIndex === 0}
                />
                {inputGroups.indexOf(group) + 1}/{this.totalGroups}
                <Button id="nextGroupButton"
                        icon={{name: "arrow-right"}}
                        type={ButtonType.Blue}
                        onClick={async () => await this.nextGroupAsync(groupIndex)}
                        disabled={this.editMode || this.currentlyDisplayedRequiredInputHasNoValue || groupIndex === this.state.totalGroupsWithInputsCount - 1}
                />
            </div>
        );
    }

    private renderGroup(group: InputGroup, groupIndex: number): React.ReactNode {

        const isSelected: boolean = ((this.editMode && this.inputGroups.length > 1) && (group.groupId === this.selectedGroup?.groupId));

        return (
            <div key={`InputGroup_${group.groupId}`} className={(isSelected) ? styles.selectedGroup : ""} data-cy={"InputGroup"} >
                {
                    (this.groupingEnabled && this.editMode) &&
                    <TextInput id={"groupHeaderTextInput"}
                               label={Localizer.servicePreviewPageName}
                               width={"300px"}
                               value={group.groupHeader}
                               className={styles.marginTopBottom}
                               onChange={async (_input, value) => await this.setGroupHeader(value, group)}
                    />
                }
                {
                    (!this.editMode) &&
                    <p>{group.groupHeader}</p>
                }
                {
                    group.inputs.map((value, index) => {
                        return this.renderRow(
                            this.renderUpDownArrows(value, index, group),
                            this.renderInput(value, groupIndex),
                            this.renderEditDeleteButtons(value, index, group),
                            value, index, this.displayConditionsMet(value.displayConditions)
                        );
                    })
                }
                {
                    this.renderRow(
                        this.renderGroupUpDownArrows(group, groupIndex),
                        this.renderGroupNumberRow(group, groupIndex),
                        this.renderGroupEditDeleteButtons(group))
                }
                {
                    (this.inputGroups.length > 1 && this.editMode) &&
                    (
                        <hr className={this.css(styles.pageSeparator, (isSelected) ? styles.pageSeparatorSelected : "", (this.editMode) ? styles.pageSeparatorMargin : "")}/>
                    )
                }
            </div>
        );
    }

    public renderGroups(): React.ReactNode {
        if (this.editMode) {
            return (
                this.inputGroups?.map((group, groupIndex) => (
                        this.renderGroup(group, groupIndex)
                    )
                )
            );
        }

        const index = this.groupsToDisplay.indexOf(this.selectedGroupToDisplay);
        return (
            this.renderGroup(this.selectedGroupToDisplay, index)
        );
    }

    public render(): React.ReactNode {
        return (
            <div>
                {
                    (this.model) &&
                    (
                        <>

                            {
                                (this.editMode) &&
                                (
                                    <div className={styles.serviceButtonPreviewContainer}>
                                        <Button className={styles.serviceButton}
                                                label={this.model.name ?? ""}
                                                type={ButtonType.Orange}
                                                icon={this.model.icon ? {name: this.model.icon ?? "", size: IconSize.X3} : undefined}
                                        />
                                    </div>
                                )
                            }

                            <h2 className={styles.serviceName}>
                                {
                                    this.model.name
                                }
                            </h2>

                        </>
                    )
                }

                {
                    <Form id="form"
                          onSubmit={async (_, _data) => await this.sendServiceRequestAsync()}
                    >
                        {
                            (!this.groupingEnabled && this.inputs) &&
                            (
                                this.inputs.map((value, index) =>
                                    (
                                        this.renderRow(
                                            this.renderUpDownArrows(value, index),
                                            this.renderInput(value),
                                            this.renderEditDeleteButtons(value, index),
                                            value, index, this.displayConditionsMet(value.displayConditions)
                                        )
                                    ))
                            )

                        }
                        {
                            (this.groupingEnabled) &&
                            (
                                (this.renderGroups())
                            )

                        }
                        {
                            (!this.previewMode) &&
                            (
                                <ButtonContainer>

                                    <Button label={Localizer.formCancel}
                                            onClick={async () => (this.props.closePreviewAsync)
                                                ? await this.props.closePreviewAsync()
                                                : Promise.resolve()}
                                    />

                                    {
                                        (!this.deleted) &&
                                        (
                                            <Button id="submitServiceDefinitionButton"
                                                    submit
                                                    type={ButtonType.Orange}
                                                    label={Localizer.genericSend}
                                                    disabled={this.editMode || this.requiredInputHasNoValue}
                                            />
                                        )
                                    }

                                </ButtonContainer>
                            )
                        }
                    </Form>
                }

                <div>
                    <ImageModal download
                                id={`${this.id}_previewCustomServiceRequestImageRef`}
                                ref={this._previewCustomServiceRequestImageRef}
                                size={ModalSize.Large}
                                title={Localizer.formInputFilePreview}
                    >
                    </ImageModal>
                </div>
            </div>

        )
    }
}