import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Desktop - Anonymous catalog tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);

        pageData().rent.visitWithIntercept();

    });

    it('Open catalog page and navigate back from breadcrumbs', () => {
        //Should have home -> rent = 2 items
        cy.assertBreadCrumbLength(2);

        pageData().rent.firstCategoryItem()
            .then(($btn: any) => {
                cy.wrap($btn)
                    .invoke('text')
                    .then((parentCategoryText: string) => {
                        //Open main category
                        cy.assertBreadCrumbLength(2);

                        executeWithIntercept(() => $btn.click(),
                            [pageData().rent.routes.categoriesData as RouteData]);

                        //Should have home -> rent -> parent = 3 items
                        cy.assertBreadCrumbLength(3);
                        cy.assertBreadCrumbsContainText(parentCategoryText);

                        cy.clickBreadCrumb(1);

                        cy.assertBreadCrumbLength(2);
                        cy.clickBreadCrumb(0);
                        //Back on frontpage
                        pageData().frontPage.widgets.rent();
                        cy.get('#rent_widget');
                    });
            });

    });

    it('Open catalog page and navigate back from browser', () => {

        //Should have home -> rent = 2 items
        cy.assertBreadCrumbLength(2);

        pageData().rent.firstCategoryItem()
            .then(($btn: any) => {
                cy.wrap($btn)
                    .invoke('text')
                    .then((parentCategoryText: string) => {

                        executeWithIntercept(() => $btn.click(),
                            [pageData().rent.routes.categoriesData as RouteData]);

                        //Should have home -> rent -> parent = 3 items
                        cy.assertBreadCrumbLength(3);

                        cy.assertBreadCrumbsContainText(parentCategoryText);

                        cy.go('back');

                        cy.assertBreadCrumbLength(2);

                    });
            });

    });

    it('Open catalog page, navigate to a product and go to initial page', () => {

        cy.assertBreadCrumbLength(2);

        pageData().rent.firstCategoryItem().click();

        cy.assertBreadCrumbLength(3);

        pageData().rent.firstCategoryItem().click();

        onRedirect(() => {
            executeWithIntercept(() => pageData().rent.productLink('2de6cbf1-d427-a83f-9e0b-fd9574f967bf')
                .click(),
                [pageData().rent.routes.productsData as RouteData]);
        });

        onRedirect(() => {
            executeWithIntercept(() => pageData().rent.visitInternal(),
                [pageData().rent.routes.categoriesData as RouteData]);
        });

        cy.assertBreadCrumbLength(2);

        cy.get('#categories-list').children().should('have.length', 5);
    });

});